import * as React from "react";
import Capitalize from "./Capitalize";
import { Config } from "common";
import moment from "moment";
import pMinDelay from "p-min-delay";
const CryptoJS = require("crypto-js");
const secret = "lifewidgets-life-connected-app";


const _calculateAge = birthday => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const imageConfig = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? ""
    : "/Resize?Resize:geometry=640x480&set:Quality=95";
};

const componentDelay = compoenet => {
  return pMinDelay(compoenet, 0);
};

const sendPushNotification = (token, title, body, data) => {
  const message = {
    to: token,
    sound: "default",
    title: title,
    body: body,
    data: data,
  };
  let response = fetch("https://exp.host/--/api/v2/push/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-encoding": "gzip, deflate",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
};

const MinMaxAge = (array = []) => {
  let dates = [],
    maximumDate = null,
    minimumDate = null,
    maxAge = null,
    minAge = null;

  if (array.length > 0) {
    try {
      dates = array.map(item => new Date(item.user.date_of_birth));
      minimumDate = new Date(Math.max.apply(null, dates));
      maximumDate = new Date(Math.min.apply(null, dates));
      maxAge = _calculateAge(maximumDate);
      minAge = _calculateAge(minimumDate);
    } catch (e) { }
  }
  if (maxAge && minAge) {
    return "Age: " + minAge + " - " + maxAge;
  }
  return "";
};

const PrivacyStatusName = status => {
  if (status === 1) {
    return "Public";
  } else if (status === 2) {
    return "Friend";
  } else if (status === 3) {
    return "Private";
  }
  return "Select Privacy";
};

const OptimizeImage = (attachment_url, type = "image") => {
  let config = type == "video" ? "" : imageConfig();

  return Config.lifeWidget.image_url + "/" + attachment_url + config;
};

const getRandomString = length => {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

const now = () => {
  let now = new Date();
  return `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}-${now.getMilliseconds()}`;
};

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

const encryptId = id => {
  return encodeURIComponent(CryptoJS.AES.encrypt(id.toString(), secret));
};

const decryptId = encrypted => {
  return decodeURIComponent(CryptoJS.AES.decrypt(encrypted, secret));
};

const generalTimeList = () => {
  const day = moment().startOf('day');
  const data = [{ id: null, name: "Closed" }];
  for (var i = 0; i <= (12 * 4 - 1); i++) {
    var time = day.add(i == 0 ? 0 : 15, 'minutes');
    //data.push({ id: time.format("HH:mm"), name: time.format("hh:mm a") });
    data.push({ id: time.format("hh:mm"), name: time.format("hh:mm") });
  }
  return data;
}

function generateRandomColor() {
  let maxVal = 0xFFFFFF; // 16777215
  let randomNumber = Math.random() * maxVal;
  randomNumber = Math.floor(randomNumber);
  randomNumber = randomNumber.toString(16);
  let randColor = randomNumber.padStart(6, 0);
  return `${randColor.toUpperCase()}`
}


const generateLevelBlock = (item, id) => {
  var position = document.getElementById('container-level-parent').getBoundingClientRect();
  var centerX = (position.left + position.width) / 2;
  var centerY = (position.top + position.height) / 2;
  const data = {
    block_name: item.id,
    block_color: generateRandomColor(),
    block_type: item.id,
    bar_level_id: id,
    width: 100,
    height: 100,
    x: 0,
    y: 0
  }

  return data;
}

export {
  Capitalize,
  MinMaxAge,
  PrivacyStatusName,
  OptimizeImage,
  getRandomString,
  now,
  humanFileSize,
  sendPushNotification,
  componentDelay,
  encryptId,
  decryptId,
  generalTimeList,
  generateLevelBlock
};
