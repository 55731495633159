import React, { Component } from "react";
import "./styles.css";
import ReactPlayer from "react-player";
import { OptimizeImage } from "helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";

class Groupcard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let attaches = [];
    let attachments = this.props.item.attachments ?? [];
    let count =
      attachments.length > 5 ? attachments.length : attachments.length;
    for (var i = 0; i < count; i++) {
      attaches[i] = {
        ...attachments[i],
        url: OptimizeImage(attachments[i].attachment_url, attachments[i].type),
      };
    }
    return (
      <div className="mt-3 -mx-5 rounded-md">
        <div className="flex flex-wrap">
          {attaches &&
            attaches.map((item, key) =>
              item.type === "image" ? (
                <div key={key} className="flex-grow w-2/4 gap-2 mx-auto">
                  <LazyLoadImage
                    src={item.url}
                    className="object-cover w-full h-full"
                  />
                </div>
              ) : (
                <div
                  key={key}
                  className="flex-grow w-2/4 mx-auto"
                  style={{ padding: 1 }}>
                  <ReactPlayer
                    className="object-cover rounded-md important:w-full"
                    controls
                    url={item.url}
                    config={{
                      file: {
                        attributes: {
                          preload: "metadata",
                        },
                      },
                    }}
                  />
                </div>
              )
            )}
          {/* {attachments && attachments.length>5 &&
              <div className='flex items-center justify-center w-40 h-40 bg-gray-300 cursor-pointer rounded-xl'>
                <FiPlus color={colors.black} size={30}/>
                {attachments.length - 5}
              </div>
              } */}
        </div>
      </div>
    );
  }
}

export default Groupcard;
