import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import colors from "config/color/color.js";
import { ImLocation2 } from "react-icons/im";
import { BsThreeDots } from "react-icons/bs";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { Dropdown } from "semantic-ui-react";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { Selectprivacypopup } from "components";
import { connect } from "react-redux";
class Hometown extends Component {
  constructor(props) {
    super(props);
    this.state = { homeTown: false };
    this.selectPrivacyRef = React.createRef();
  }

  render() {
    let { user } = this.props;
    return (
      <div>
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <div className="mr-3 ">
              <ImLocation2 size={22} className="darkGray" />
            </div>
            <p className="darkGray">
              From{" "}
              <p className="black hover:underline">
                {" "}
                {user.street} {user.city} {user.cca2}
              </p>
            </p>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => this.selectPrivacyRef.current.selectPriavcyOpen()}
              className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer hover:bg-gray-200">
              <GiEarthAsiaOceania
                size={20}
                style={{ color: colors.darkGray }}
              />
            </div>

            <Dropdown
              icon={false}
              wrapSelection={true}
              direction="left"
              pointing="top"
              text={
                <div>
                  <div className="flex items-center justify-center w-10 h-10 ml-3 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300">
                    <BsThreeDots size={18} />
                  </div>
                </div>
              }>
              <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-80">
                <div className="p-2">
                  <div
                    onClick={() => this.setState({ homeTown: true })}
                    className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-200">
                    <div className="mr-3">
                      <FiEdit2 size={18} />
                    </div>
                    <p>Edit hometown</p>
                  </div>
                  <div
                    onClick={() => this.props.deleteWorkplacesPopupOpen()}
                    className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-200">
                    <div className="mr-3">
                      <IoTrashOutline size={18} />
                    </div>
                    <p>Delete Workpalce</p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {this.state.homeTown ? (
          <div className="mt-4">
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Home Town"
                className="h-16 border shadow-none rounded--lg"
              />
            </div>
            <div className="my-3 divider" />
            <div className="flex items-center justify-between">
              <Button
                onClick={() =>
                  this.selectPrivacyRef.current.selectPriavcyOpen()
                }
                style={{ color: colors.black }}
                className="flex items-center px-4 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray important:rounded--lg hover:scale-90">
                <GiEarthAsiaOceania
                  size={14}
                  className="mr-2"
                  style={{ color: colors.darkGray }}
                />
                Public
              </Button>
              <div className="flex items-center ">
                <Button
                  onClick={() => this.setState({ homeTown: false })}
                  style={{ color: colors.black }}
                  className="flex items-center px-4 mr-3 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray important:rounded--lg hover:scale-90">
                  Cancel
                </Button>
                <Button
                  style={{ color: colors.white }}
                  className="flex items-center px-4 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary important:rounded--lg hover:scale-90">
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : null}{" "}
        <Selectprivacypopup ref={this.selectPrivacyRef} {...this.props} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.User.user,
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
    // deletePost: (data) => {
    //   actions.deletePostFeed(dispatch, data);
    // },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(React.memo(Hometown));
