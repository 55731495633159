import React, { Component } from "react";
import { Image } from "react-bootstrap";
import avatar from "assets/images/avatar.webp";
import { FaCoins } from "react-icons/fa";

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-4 bg-white shadow-md rounded-xl">
        <div className="flex items-center justify-between px-3 py-3 mb-2 bg--gold rounded-xl">
          <div className="relative flex items-center">
            <Image
              src={avatar}
              className="object-cover w-12 h-12 mr-3 rounded-full"
            />
            <div className="absolute flex items-center justify-center w-4 h-4 bg-white rounded-full  -top-1">
              <p className="gold fs-10">1</p>
            </div>
            <p>Micheal Murphy</p>
          </div>
          <div className="flex items-center ">
            <FaCoins />
            <p className="ml-3">5</p>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#D9DACA" }}
          className="flex items-center justify-between px-3 py-3 mb-2 rounded-xl">
          <div className="relative flex items-center">
            <Image
              src={avatar}
              className="object-cover w-12 h-12 mr-3 rounded-full"
            />
            <div className="absolute flex items-center justify-center w-4 h-4 bg-white rounded-full  -top-1">
              <p className="gray fs-10">2</p>
            </div>
            <p>Micheal Murphy</p>
          </div>
          <div className="flex items-center ">
            <FaCoins />
            <p className="ml-3">5</p>
          </div>
        </div>
        <div className="flex items-center justify-between px-3 py-3 mb-2 bg--warning rounded-xl">
          <div className="relative flex items-center">
            <Image
              src={avatar}
              className="object-cover w-12 h-12 mr-3 rounded-full"
            />
            <div className="absolute flex items-center justify-center w-4 h-4 bg-white rounded-full  -top-1">
              <p className="warning fs-10">3</p>
            </div>
            <p>Micheal Murphy</p>
          </div>
          <div className="flex items-center ">
            <FaCoins />
            <p className="ml-3">5</p>
          </div>
        </div>
        <div className="flex items-center justify-between px-3 py-3 mb-2 bg--lightGray rounded-xl">
          <div className="relative flex items-center">
            <Image
              src={avatar}
              className="object-cover w-12 h-12 mr-3 rounded-full"
            />
            <div className="absolute flex items-center justify-center w-4 h-4 rounded-full -top-1 bg--primary">
              <p className="white fs-10">4</p>
            </div>
            <p>Micheal Murphy</p>
          </div>
          <div className="flex items-center ">
            <FaCoins />
            <p className="ml-3">5</p>
          </div>
        </div>
        <div className="flex items-center justify-between px-3 py-3 mb-2 bg--lightGray rounded-xl">
          <div className="relative flex items-center">
            <Image
              src={avatar}
              className="object-cover w-12 h-12 mr-3 rounded-full"
            />
            <div className="absolute flex items-center justify-center w-4 h-4 rounded-full -top-1 bg--primary">
              <p className="white fs-10">5</p>
            </div>
            <p>Micheal Murphy</p>
          </div>
          <div className="flex items-center ">
            <FaCoins />
            <p className="ml-3">5</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Leads;
