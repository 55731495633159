import React, { Component } from "react";
import "./styles.css";
import colors from "config/color/color.js";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineGlobal } from "react-icons/ai";
import { BsGift } from "react-icons/bs";
import { GiUsaFlag } from "react-icons/gi";
import { HiOutlineArrowLeft } from "react-icons/hi";

class Leaderboardleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="fixed bg-white shadow-md FriendsLeftAside">
        <div className="px-4 pt-4">
          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Main Menu
                </Link>
                <h2 className="text-2xl">Leaderboard</h2>
              </div>
            </div>
          </div>
          <div className="my-3 divider" />
          <div className="p-3 mt-4 LinearGradient rounded-xl">
            <div className="flex">
              <div className="">
                <h3>Two $1000 Winners </h3>
                <p className="my-2 fs-12">
                  One US Winner and One International Winners
                </p>
                <p className="fs-12">Ends on July 31st</p>
              </div>
              <div className="flex items-end justify-end -mb-4 ">
                <BsGift size={70} color={colors.gold} />
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 divider" />
        <div className="px-2 mt-3">
          <NavLink
            to="/leaderboard/usa"
            activeClassName="bg--lightPrimary important:primary"
            className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200 black">
            <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
              <GiUsaFlag size={18} style={{ color: colors.white }} />
            </div>
            USA
          </NavLink>
          <NavLink
            to="/leaderboard/international"
            activeClassName="bg--lightPrimary important:primary"
            className="flex items-center p-2 mb-2 rounded-lg black hover:bg-gray-200">
            <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
              <AiOutlineGlobal size={22} style={{ color: colors.white }} />
            </div>
            INTERNATIONAL
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Leaderboardleftaside;
