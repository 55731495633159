import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { OrdersAside } from "Modules";
import moment from "moment";

const { actions } = require("redux/BarEnhancement");

export default function NightlyTotal() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const data = useSelector((state) => Object.assign({}, state.enhancement.nightly?.[id]));

  React.useEffect(() => {
    actions.fetchNightlyTotals(dispatch, { bar_id: id, date: moment().local().format("YYYY-MM-DD") });

  }, [id]);

  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <OrdersAside title="Nightly Totals" vip={false} />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="text-center mb-4">
          <h2> Nightly Totals</h2>
        </div>
        <div className="overflow-y-auto" id="scrollbar">
          <div className="grid grid-cols-4 gap-4">
            <div />
            <div className="overflow-x-auto relative col-span-2">
              <div className="bg-white shadow-lg py-8 overflow-hidden m-5 rounded-xl">
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td className="py-4 px-6 black text-right text-[20px] ">
                        Total Orders Fulfilled:
                      </td>
                      <td className="py-4 px-6 black text-left  text-[20px]">
                        {(data?.total || 0)}
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <td className="py-4 px-6 black text-right text-[20px] ">
                        Total Revenue:
                      </td>
                      <td className="py-4 px-6 black text-left  text-[20px]">
                        ${(data?.revenue || 0)}
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <td className="py-4 px-6 black text-right text-[20px] ">
                        Total Tips:
                      </td>
                      <td className="py-4 px-6 black text-left  text-[20px]">
                        ${(data?.tip || 0)}
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <td className="py-4 px-6 black text-right text-[20px] ">
                        Average Tip % Per Order:
                      </td>
                      <td className="py-4 px-6 black text-left  text-[20px]">
                        {parseFloat((data?.tip_percentage || 0)).toFixed(2)}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
