import React, { Component } from "react";
import { NavDropdown, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import { BsDot } from "react-icons/bs";
import { Popup, Loader } from "semantic-ui-react";
import {
  TimeAgo,
  PostCommentBox,
  PostCommentReply,
  RenderMention,
} from "components";
import { BsThreeDots } from "react-icons/bs";
import { connect } from "react-redux";
import { avatar } from "assets";
import { OptimizeImage, Capitalize } from "helpers";
import * as Scroll from "react-scroll";
import { Element } from "react-scroll";
import { IoTrashOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
var scroller = Scroll.scroller;

class PostComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likeColor: false,
      reply: false,
    };
    this.per_page = 10;
    this.page = 1;
    this.postCommentBoxRef = React.createRef();
  }

  commentToggleLike = () => {
    this.props.commentToggleLike(this.props.comment);
  };

  loadMoreReply = () => {
    const comment_id = Math.min.apply(Math, this.props.replayIds);
    const data = { parent_comment_id: this.props.comment.id, id: comment_id };
    this.props.replyLoadMoreByCommentId(this.per_page, this.page, data);
  };

  deleteCommentByPostId = () => {
    this.props.deleteCommentByPostId();
    this.props.updateCommentCounter();
  };

  _openCommentBox = () => {
    const { comment } = this.props;
    this.setState({ reply: true });
    scroller.scrollTo("postCommentBox-" + comment.id, {
      offset: -200,
    });
  };

  editCommentByPostId = () => {
    const { comment } = this.props;
    let data = {};
    data[comment.post_id] = comment.comment;
    let commentData = {};
    commentData[comment.post_id] = comment;
    this.props.editCommentByPostId(commentData, data);
    this.props.openCommentBox();
  };

  render() {
    const { comment, replayIds, isMoreFetching, user } = this.props;
    if (!comment) {
      return null;
    }

    return (
      <div className="table mt-2">
        <div className="table-cell w-11 h-11">
          <Image
            src={
              comment.user.profile_photo
                ? OptimizeImage(comment.user.profile_photo)
                : avatar
            }
            className="block object-cover w-full h-full rounded-full"
          />
        </div>
        <div className="table-cell">
          <div className="flex-grow ml-3 comment-box-container hover_show_box">
            <div className="relative inline-block px-3 py-2 mr-10 comment-box rounded-2xl">
              <p className="Medium fs-13">
                {Capitalize(comment.user.first_name)}{" "}
                {Capitalize(comment.user.last_name)}
              </p>
              <p className="fs-13">
                <RenderMention
                  value={comment.comment}
                  partTypes={[{ trigger: "@" }]}
                />
                {comment.attachments && (
                  <Image
                    className="object-cover w-32 mr-2 rounded"
                    src={OptimizeImage(comment.attachments.attachment_url)}
                  />
                )}
              </p>
              {user.id === comment.user_id && (
                <div className="z-10 hover_show_drpdwn -right-10 top-3">
                  <NavDropdown
                    title={
                      <div className="flex items-center justify-center w-8 h-8 m-auto rounded-full hover:bg-gray-200 hover_dots_drpdwn">
                        <BsThreeDots size={18} className="darkGray" />
                      </div>
                    }
                    className="bg-transparent custom-dropdown">
                    <div className="absolute right-0 z-50 w-screen max-w-xs mt-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative p-2 bg-white">
                          <NavDropdown.Item
                            onClick={this.deleteCommentByPostId}
                            className="flex items-center px-2 py-2 hover:bg-gray-100 focus:bg--lightGray rounded--xl">
                            <div className="flex items-center justify-center w-8 h-8 mr-3 bg-gray-300 rounded-full">
                              <IoTrashOutline size={15} className="black" />
                            </div>
                            <p>Delete comment</p>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={this.editCommentByPostId}
                            className="flex items-center px-2 py-2 hover:bg-gray-100 focus:bg--lightGray rounded--xl">
                            <div className="flex items-center justify-center w-8 h-8 mr-3 bg-gray-300 rounded-full">
                              <FiEdit size={12} className="black" />
                            </div>
                            <div>
                              <p>Edit</p>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      </div>
                    </div>
                  </NavDropdown>
                </div>
              )}
            </div>

            <div className="flex items-center mt-1 ml-3">
              <p
                className="text-sm cursor-pointer darkGray hover:primary--text"
                style={{
                  color: comment.isLiked ? colors.primary : null,
                }}
                onClick={this.commentToggleLike}>
                Like
              </p>
              <BsDot size={10} className="darkGray" />
              <p
                className="text-sm cursor-pointer darkGray"
                onClick={() => this.setState({ reply: !this.state.reply })}>
                Reply
              </p>
              <BsDot size={10} className="darkGray" />
              <Popup
                inverted
                className="p-2"
                position="bottom center"
                content={
                  <TimeAgo
                    className="text-sm cursor-pointer white Light"
                    created_at={comment.created_at}
                    format="dddd, MMMM Do YYYY, h:mm:ss a"
                  />
                }
                trigger={
                  <TimeAgo
                    className="text-sm cursor-pointer darkGray Light"
                    created_at={comment.created_at}
                  />
                }
              />
            </div>
          </div>
          <Element name={`postCommentBox-${comment.id}`}>
            <div className={this.state.reply ? "" : "hidden"}>
              <PostCommentBox comment={comment} post_id={comment.post_id} />
            </div>
          </Element>
          {replayIds &&
            replayIds.length > 0 &&
            replayIds.map((reply, k) => (
              <PostCommentReply
                user={user}
                replyId={reply}
                key={k}
                openCommentBox={this._openCommentBox}
              />
            ))}
          {replayIds && replayIds.length > 0 && (
            <>
              {isMoreFetching ? (
                <Loader
                  active={isMoreFetching}
                  inline="centered"
                  size="small"
                />
              ) : (
                comment.replies_count > 1 &&
                replayIds.length < comment.replies_count && (
                  <p
                    className="cursor-pointer primary hover:underline fs-12"
                    onClick={this.loadMoreReply}>
                    more...
                  </p>
                )
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { commentId, user, post_id } = ownProps;
  const comment = state.Comment.commentsById[post_id][commentId];
  const replayIds = state.Comment.commentReplayIds[commentId];
  const isMoreFetching = state.Comment.isMoreFetching[commentId];
  return { comment, user, replayIds, isMoreFetching };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CommentRedux");
  const { actions: feedActions } = require("redux/FeedRedux");
  const { commentId, post_id } = ownProps;
  return {
    ...ownProps,
    ...stateProps,
    deleteCommentByPostId: () => {
      actions.deleteCommentByPostId(dispatch, post_id, commentId);
    },
    editCommentByPostId: (comment, params) => {
      dispatch(actions.editCommentByPostId(comment, params));
    },
    replyComment: (comment) => {
      dispatch(actions.replyComment(comment));
    },
    replyLoadMoreByCommentId: (per_page, page, data) => {
      actions.replyLoadMoreByCommentId(dispatch, per_page, page, data);
    },
    commentToggleLike: (data) => {
      actions.commentToggleLike(dispatch, data);
    },
    updateCommentCounter: () => {
      dispatch(
        feedActions.updateCommentCounter({ post_id: post_id, plus: false })
      );
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(PostComment);
