import React from "react";
import { OrdersAside, TableForm } from "Modules";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { Buttonused } from "components";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import TablePositions from "./TablePositions";
import UpdateLevel from "./UpdateLevel"

import { generateLevelBlock } from "helpers";
import { BiEdit, BiLoaderAlt } from "react-icons/bi";

const { actions } = require("redux/BarEnhancement");


const blocksType = [
  { id: "bar", name: "Insert Bar", color: "bg-green-500" },
  { id: "stage", name: "Insert Dj or Stage", color: "bg--primary" },
  { id: "dance", name: "Insert dance floor", color: "bg-black" },
  { id: "table", name: "Insert VIP table", color: "bg-blue-300" },
  { id: "free", name: "Insert free table", color: "bg-gray-500" },
]

export default function InsertTables() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const level = useSelector((state) => Object.assign({}, state.enhancement.level));
  const blocks = useSelector((state) => Object.assign({}, state.enhancement.blocks));
  const activeBlock = useSelector((state) => state.enhancement.activeBlock);
  const settingData = useSelector((state) => Object.assign({}, state.enhancement.setting.data));
  const setting = (settingData?.[id] || {});

  const popupModal = React.useRef(null);
  const popupLevelModal = React.useRef(null);


  const form = level.form;
  const data = level.data
  const isSubmitting = level.isSubmitting

  React.useEffect(() => {
    actions.fetchLevel(dispatch, { bar_id: id });
  }, []);


  const addForm = (key, value) => {
    if (value > 30) {
      return;
    }
    const data = form;
    data[key] = value;
    data["bar_id"] = id;
    dispatch(actions.addLevelForm(data));
  }

  const handleOutSideChange = (e, key) => {
    let data = form;
    data = {
      ...data,
      outside: {
        ...data.outside,
        [key]: e.target.checked
      }
    }
    dispatch(actions.addLevelForm(data));
  }

  const renderOutside = () => {
    let data = [];
    if (form.level > 0) {
      for (var i = 1; i <= form.level; i++) {
        data.push(i);
      }
    }
    return data;
  }

  const submitLevel = () => {
    actions.submitLevel(dispatch, form);
  }

  const addLevelBlock = (id) => {
    const data = (blocks?.[id] || []);
    dispatch(actions.addLevelBlock(id, data));
  }

  const addBlock = (level, type) => {
    if (type.id == 'table') {
      popupModal.current.open(id, level);
    } else {
      const data = (blocks?.[level] || []);
      data.push(generateLevelBlock(type, level));
      dispatch(actions.addLevelBlock(level, data));
      const subbmit = { data: data, bar_level_id: level };
      actions.submitBlock(dispatch, subbmit);
    }
  }

  const onDragStop = (e, level, id) => {
    let data = (blocks?.[level] || []);
    const newData = [...data];
    newData[id].x = e.x;
    newData[id].y = e.y;
    dispatch(actions.addLevelBlock(level, newData));
    const subbmit = { data: newData };
    actions.submitBlock(dispatch, subbmit)

  }

  const onResizeStop = (e, position, level, id) => {
    let data = (blocks?.[level] || []);
    const newData = [...data];
    newData[id].width = parseInt(e.style.width);
    newData[id].height = parseInt(e.style.height);
    newData[id].x = position.x;
    newData[id].y = position.y;
    dispatch(actions.addLevelBlock(level, newData));
    const subbmit = { data: newData };
    actions.submitBlock(dispatch, subbmit)
  }

  const onEdit = (level, id) => {
    let data = (blocks?.[level] || []);
    const newData = [...data];
    newData[id].edit = true;
    dispatch(actions.addLevelBlock(level, newData));
    const subbmit = { data: newData };
    actions.submitBlock(dispatch, subbmit);
  }

  const onChange = (level, id, e) => {
    let data = (blocks?.[level] || []);
    const newData = [...data];
    newData[id].block_name = e.target.value;
    if (e.key == "Enter" && e.target.value != "") {
      newData[id].edit = false;
    }
    dispatch(actions.addLevelBlock(level, newData));
    if (e.key == "Enter" && e.target.value != "") {
      const subbmit = { data: newData };
      actions.submitBlock(dispatch, subbmit);
    }

  }

  const onBlockRemove = (level, id) => {
    console.log(id)
    let data = (blocks?.[level] || []).filter(i => i.id !== id);
    const newData = [...data];
    dispatch(actions.addLevelBlock(level, newData));
    const subbmit = { data: newData, deleted_id: id };
    actions.submitBlock(dispatch, subbmit);
  }

  const onChangeSetting = (field, value) => {
    const data = {
      field: field,
      value: value,
      bar_id: id
    }
    actions.submitBarSetting(dispatch, data);
  }

  const openEditLevel = (level_id) => {
    popupLevelModal.current.open(id, level_id);
  }

  return (
    <div className="flex justify-between pb-10">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <OrdersAside title="Bar Layout" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="m-auto w-full space-y-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-10 mx-4 mb-10">
              <h1>Bar Layout</h1>
              <Tooltip
                arrow={true}
                title={setting?.enabled_vip_table == 1 ? "Disable this feature" : "Enable this feature"}>
                <Checkbox
                  onChange={(e) => onChangeSetting('enabled_vip_table', e.target.checked ? 1 : 0)}
                  checked={setting?.enabled_vip_table == 1 ? true : false}
                  color="primary"
                />
              </Tooltip>
            </div>
          </div>
          {data.length == 0 &&
            <>
              {isSubmitting ? <BiLoaderAlt size={18} className="animate-spin flex justify-center" /> :
                <div className=" bg-white shadow-sm max-w-sm rounded-lg p-4">

                  <div>
                    <p className="mb-1 text-[14px] tracking-wider">
                      How many levels Have VIP Tables:
                    </p>
                    <input
                      type="number"
                      placeholder="Enter Level"
                      min={0}
                      max={30}
                      value={form.level}
                      onChange={(e) => addForm("level", e.target.value)}
                      className="shadow-none w-full rounded-lg h-12 px-5 important:bg--lightGray"
                    />
                  </div>

                </div>
              }

              {(form.level || 0) > 0 &&
                <>
                  <div className="grid grid-cols-3 gap-4 mt-5">
                    {renderOutside().map((item, key) => (
                      <div className="flex items-center justify-center gap-x-2 bg-white shadow-sm rounded-lg px-10 py-3.5" key={key}>
                        <p className="text-[14px] tracking-wider">Level {item} outside:</p>
                        <Checkbox
                          checked={form?.['outside']?.[item] ? true : false}
                          onChange={(e) => handleOutSideChange(e, item)}
                          color="primary"
                        />
                      </div>
                    ))}

                  </div>
                  <Buttonused
                    disabled={isSubmitting}
                    Icon={isSubmitting && <BiLoaderAlt size={18} className="animate-spin" />}
                    onClick={submitLevel}
                    Text="Submit"
                    className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 p-4 mx-auto rounded--lg"
                  />
                </>
              }
            </>
          }

          {data && data.length > 0 &&
            <div className="flex gap-4 my-5">
              {data.map((item, key) => (
                <>
                  <div
                    key={key}
                    className={`${activeBlock == item.id ? "bg--primary" : "bg-gray-400"} w-full cursor-pointer text-white hover:text-white flex items-center justify-center hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none mx-auto rounded--lg mb-3`}
                  >
                    <div
                      key={key}
                      onClick={() => addLevelBlock(item.id)}
                      className='bg-red border-r  w-full  h-12 flex items-center justify-center'
                    >
                      {`${item.level_name} ${item.level_type == "outside" ? "Outside" : ""}`}
                    </div>
                    <div className="cursor-pointer px-3 h-12 flex items-center justify-center" onClick={() => openEditLevel(item.id)}><BiEdit size={17} /></div>
                  </div>
                </>


              ))}
            </div>
          }
          {data.map((item, key) => (
            (blocks?.[item.id] && activeBlock == item.id) &&
            <div key={key}>
              <h2 className="text-center mt-20">Table layout for {`${item.level_name} ${item.level_type == "outside" ? "Outside" : ""}`}</h2>

              <div className="grid grid-cols-5 gap-4 my-5">
                {blocksType.map((type, key) => (
                  <Buttonused
                    disabled={blocks?.[item.id].filter(i => (i.block_type == type.id && i.block_type !== "table")).length > 0 ? true : false}
                    key={key}
                    onClick={() => addBlock(item.id, type)}
                    Text={type.name}
                    className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
                  />
                ))}
              </div>
              <div id="container-level-parent" className="box relative border bg-white shadow-lg min-h-[600px]  rounded-lg flex flex-wrap items-center gap-x-2 gap-y-2">
                {blocks?.[item.id].map((block, key) => (
                  <TablePositions key={key} level_id={item.id} id={key} item={block} onDrag={onDragStop} onResize={onResizeStop} onEdit={onEdit} onChange={onChange} onRemove={onBlockRemove} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <TableForm ref={popupModal} />
      <UpdateLevel ref={popupLevelModal} />
    </div>
  );
}
