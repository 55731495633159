import React, { Component } from "react";
import colors from "config/color/color.js";
import { Image } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import hundred from "assets/images/reaction/100.png";
import angry from "assets/images/reaction/angry.gif";
import haha from "assets/images/reaction/haha.gif";
import love from "assets/images/reaction/love.gif";
import rolling from "assets/images/reaction/rolling.gif";
import sad from "assets/images/reaction/sad.gif";
import wow from "assets/images/reaction/wow.gif";
import { Emitter } from "common";
import ReactionTab from "./ReactionTab";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
class PostReaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reactions: false,
      item: {},
    };
    this.per_page = 10;
    this.page = 1;
  }

  componentDidMount() {
    Emitter.on("POST_REACTION_MODAL", (item) => {
      this.setState({ reactions: true, item: item });
    });
  }

  componentWillUnmount() {
    Emitter.off("POST_REACTION_MODAL");
  }

  reactionsPopupOpen = () => {
    this.setState({ reactions: true });
  };

  reactionsPopupClose = () => {
    this.setState({ reactions: false });
  };

  render() {
    let { item } = this.state;
    if (Object.keys(item).length === 0) {
      return null;
    }
    return (
      <Dialog
        open={this.state.reactions}
        onClose={this.reactionsPopupClose}
        scroll="body"
        className="custom-backdrop"
        fullWidth="sm">
        <div className="p-0 " id="scrollbar">
          <div className="relative access-info-tabs">
            <Tab.Group>
              <div className="flex items-center bg-gray-200 rounded-xl">
                <Tab.List className="flex flex-grow p-1 space-x-1">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    All
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image
                      src={hundred}
                      className="object-cover w-6 h-6 mx-auto"
                    />
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image
                      src={love}
                      className="object-cover w-6 h-6 mx-auto"
                    />
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image
                      src={haha}
                      className="object-cover w-6 h-6 mx-auto"
                    />
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image src={wow} className="object-cover w-6 h-6 mx-auto" />
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image src={sad} className="object-cover w-6 h-6 mx-auto" />
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image
                      src={rolling}
                      className="object-cover w-6 h-6 mx-auto"
                    />
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full py-2.5 text-sm rounded-lg",
                        selected
                          ? "bg-white shadow primary"
                          : "black hover:bg-gray-100"
                      )
                    }>
                    <Image
                      src={angry}
                      className="object-cover w-6 h-6 mx-auto"
                    />
                  </Tab>
                </Tab.List>
                <div
                  onClick={this.reactionsPopupClose}
                  className="flex items-center justify-center w-10 h-10 mr-2 bg-gray-300 rounded-full cursor-pointer">
                  <FiX size={24} style={{ color: colors.darkGray }} />
                </div>
              </div>
              <Tab.Panels className="mt-2">
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="all" reaction_id={null} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="100" reaction_id={1} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="love" reaction_id={2} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="haha" reaction_id={3} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="wow" reaction_id={4} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="sad" reaction_id={5} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="sarcasm" reaction_id={6} />
                </Tab.Panel>
                <Tab.Panel className="p-5 bg-white rounded-xl">
                  <ReactionTab item={item} type="angry" reaction_id={7} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default PostReaction;
