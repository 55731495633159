import React from "react";
import { Rnd } from 'react-rnd';
import { IoMdTrash } from 'react-icons/io';



const TablePositions = ({ id, level_id, item, onDrag, onResize, onEdit, onChange, onRemove }) => {

  const onDragStop = (e, d) => {
    onDrag(d, level_id, id);
  };

  const onResizeStop = (e, direction, ref, delta, position) => {
    onResize(ref, position, level_id, id)
  };

  const onDoubleClick = (e) => {
    onEdit(level_id, id);
  }

  const onInputChange = (e) => {
    onChange(level_id, id, e);
  }

  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      onChange(level_id, id, e);
    }

  }

  const onRemoveBlock = () => {
    onRemove(level_id, item.id);
  }

  const dragHandlers = { onDragStop: onDragStop, onResizeStop: onResizeStop };
  return (
    <Rnd
      bounds="parent"
      size={{ width: item.width, height: item.height }}
      position={{ x: item.x, y: item.y }}
      minWidth={100}
      minHeight={100}
      {...dragHandlers}
    >
      <div
        onDoubleClick={onDoubleClick}
        style={{ backgroundColor: '#' + item.block_color }}
        className={`box h-full w-full  text-white rounded cursor-move flex items-center text-center justify-center`}
      >

        <span style={{ position: "absolute", right: 0, top: 2 }} class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{item.block_type}</span>
        {item.block_type != "table" &&
          <span style={{ position: "absolute", left: 0, top: 2 }} class="cursor-pointer bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300" onClick={onRemoveBlock}><IoMdTrash size={16} /></span>
        }
        {item.edit ?
          <input onChange={onInputChange} onKeyUp={onKeyUp} type={"text"} value={item.block_name} className="shadow-none text-black rounded-lg h-12 px-5 important:bg--lightGray" autoFocus />
          :
          item.block_name
        }

      </div>

    </Rnd>
  );

}

export default TablePositions;
