import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { BsDot } from "react-icons/bs";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { RiCupFill } from "react-icons/ri";
import { ImGlass } from "react-icons/im";
import { FaWineBottle } from "react-icons/fa";
import { IoFastFood } from "react-icons/io5";
import { OptimizeImage } from "helpers";
import { Config } from "common";
import { Avatar } from "@material-ui/core";
import wine from "assets/images/vectors/wine.png";
import Buttonused from "components/allcomponents/buttonused";

class Bardetailheader extends Component {
  render() {
    const { item } = this.props;
    let banner =
      "https://images.all-free-download.com/images/graphiclarge/bar_wines_514430.jpg";
    let bannerObject = Object.assign({}, item.banner);
    if (bannerObject.attachment_url) {
      banner = Config.lifeWidget.url + "/" + bannerObject.attachment_url;
    }

    let logo =
      "https://images.all-free-download.com/images/graphicthumb/innocent_girl_608907.jpg";
    let logoObject = Object.assign({}, item.logo);
    if (logoObject.attachment_url) {
      logo = Config.lifeWidget.url + "/" + logoObject.attachment_url;
    }
    const photos = [];
    for (var i = 0; i < item.photos.length; i++) {
      photos[i] = OptimizeImage(
        item.photos[i].attachment_url,
        item.photos[i].type
      );
    }

    var attachments = [];
    if (item.photos && item.photos.length > 0) {
      item.photos.map((item, key) => {
        attachments.push({
          ...item,
          url: OptimizeImage(item.attachment_url),
        });
      });
    }

    let live = item.bar_services.some(
      (service) => service.service_code === "dot-circle"
    );
    return (
      <div className="bg-white">
        <div className="m-auto container--small xs:px-4 sm:px-4 ">
          <div>
            <div className="overflow-hidden rounded-b-lg">
              <div className="relative rounded-b-lg shadow-sm h-96">
                <div className="absolute z-10 w-full h-full overlay:bg--gray-400" />
                <Avatar
                  variant="square"
                  src={banner}
                  style={{ height: 336 }}
                  className="object-contain rounded-t-xl h-52 important:w-full">
                  <Avatar
                    variant="square"
                    src={wine}
                    style={{ height: 336, objectFit: "cover" }}
                    className="important:w-full"
                  />
                </Avatar>
                <div className="absolute top-0 left-0 z-10 w-full h-full p-6">
                  <div className="h-full">
                    <Image
                      src={logo}
                      className="object-cover w-16 h-16 mr-2 rounded-full"
                    />
                    <h1 className="mt-16 text-2xl text-center white Medium">
                      {item.name}
                    </h1>
                    <p className="tracking-wide text-center fs-15 white opacity-80 Regular">
                      {item.city && <>{item.city}</>} {item.address},{" "}
                      {item.city}, {item.state} {item.postal_code},{" "}
                      {item.country}
                    </p>
                  </div>
                </div>{" "}
              </div>
              <div>
                <div className="flex items-center justify-start h-12 px-4 rounded-b-lg bg--success">
                  {item.owner && (
                    <>
                      <p className="white opacity-80">Owner:</p>
                      <p className="ml-2 underline white hover:color-white hover:opacity-80 hover:underline">
                        {item.owner && item.owner.first_name}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="py-4 xl:px-8 md:px-5 sm:px-4 xs:px-4">
              <div>
                {this.props.item.bar_services.length > 0 && (
                  <h2 className="text-xl Black Medium">Servies</h2>
                )}
                <div className="flex items-center py-3">
                  <div className="flex items-center ">
                    {this.props.item.bar_services.length > 0 &&
                      this.props.item.bar_services.map((item, key) => (
                        <>
                          {/* <p>{item.service_code}</p> */}
                          {item.service_code === "hamburger" ? (
                            <>
                              <div className="flex flex-col items-center">
                                <IoFastFood className="primary" size={22} />
                                <p className="px-3 mt-1 primary fs-13">
                                  {item.service_name}
                                </p>
                              </div>
                              <BsDot className="gray" />
                            </>
                          ) : null}
                          {item.service_code === "wine-bottle" ? (
                            <>
                              <div className="flex flex-col items-center">
                                <FaWineBottle className="primary" size={22} />
                                <p className="px-3 mt-1 primary fs-13">
                                  {item.service_name}
                                </p>
                              </div>
                              <BsDot className="gray" />
                            </>
                          ) : null}
                          {item.service_code === "wine-glass-alt" ? (
                            <>
                              <div className="flex flex-col items-center">
                                <ImGlass className="primary" size={22} />
                                <p className="px-3 mt-1 primary fs-13">
                                  {item.service_name}
                                </p>
                              </div>
                              <BsDot className="gray" />
                            </>
                          ) : null}
                          {item.service_code === "music" ? (
                            <>
                              <div className="flex flex-col items-center">
                                <BsMusicNoteBeamed
                                  className="primary"
                                  size={22}
                                />
                                <p className="px-3 mt-1 primary fs-13">
                                  {item.service_name}
                                </p>
                              </div>
                              <BsDot className="gray" />
                            </>
                          ) : null}
                          {item.service_code === "female" ? (
                            <>
                              <div className="flex flex-col items-center">
                                <RiCupFill className="primary" size={22} />
                                <p className="px-3 mt-1 primary fs-13">
                                  {item.service_name}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div className="items-center justify-end hidden gap-x-2">
                <div className="w-56">
                  <Buttonused
                    className="flex items-center justify-center w-full h-12 border-0 rounded-lg shadow-none white hover:text-white bg--primary hover:bg--primary foucs:bg--primary hover:opacity-80 Regular"
                    to="/bar/online-ordering"
                    rippleContainer="rounded--xl w-full"
                    Text={"Online Ordering"}
                  />
                </div>
                <div className="w-56">
                  <Buttonused
                    className="flex items-center justify-center w-full h-12 border-0 rounded-lg shadow-none white hover:text-white bg--primary hover:bg--primary foucs:bg--primary hover:opacity-80 Regular"
                    to="/bar/skip-line"
                    rippleContainer="rounded--xl w-full"
                    Text={"VIP and Skip The Line"}
                  />
                </div>
                <div className="w-56">
                  <Buttonused
                    className="flex items-center justify-center w-full h-12 border-0 rounded-lg shadow-none white hover:text-white bg--primary hover:bg--primary foucs:bg--primary hover:opacity-80 Regular"
                    to="/bar/orders"
                    rippleContainer="rounded--xl w-full"
                    Text={"My Bar Orders"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Bardetailheader;
