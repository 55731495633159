import * as React from "react";
import { connect } from "react-redux";
import AuthService from "../services/auth-service";
import { DataProvider } from "recyclerlistview/web";
import ChatService from "../services/chat-service";
import UIService from "../services/ui-service";

class Dialog extends React.Component {
  scrollWidth = 100;
  scrollHeight = 100;
  listenerWindowSize = null;
  timer = null;
  allDialog = [];

  constructor(props) {
    super(props);
    this.state = {
      height: false,
      newChatHeight: true,
      newChat: false,
      expanded: false,
      threadHeight: true,

      isAlredy: false,
      dataProvider: new DataProvider((r1, r2) => {
        return (
          r1 !== r2 || r1.unread_messages_count !== r2.unread_messages_count
        );
      }),
      layoutProvider: 0,
      count: 20,
      isLoader: true,
      search: "",
    };
    this.chat = React.createRef();
  }

  async componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      await AuthService.init();
      UIService.init();
      // ChatService.updateChatDialog();
      // this.fetchDialog();
      ChatService.setUpListeners();
    }
    //window.addEventListener("resize", this.handleResize);
  }

  // componentDidUpdate(prevProps) {
  //   const { dialogs } = this.props;
  //   if (this.props.dialogs !== prevProps.dialogs) {
  //     this.allDialog = dialogs;
  //     this.setState({
  //       layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(
  //         this.scrollWidth
  //       ),
  //       dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
  //     });
  //   }
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.handleResize);
  // }

  // dialogToggle = () => {
  //   this.setState({ height: !this.state.height });
  // };

  // fetchDialog = async () => {
  //   ChatService.fetchDialogsFromServer().then(dialogs => {
  //     this.allDialog = dialogs;
  //     this.setState({
  //       isAlredy: true,
  //       isLoader: false,
  //       layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(
  //         this.scrollWidth
  //       ),
  //       dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
  //     });
  //   });
  // };

  // handleResize = () => {
  //   if (!this.timer) {
  //     this.timer = setTimeout(() => {
  //       clearTimeout(this.timer);
  //       this.timer = null;
  //       this.setState({
  //         isAlredy: true,
  //         layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(
  //           this.scrollWidth
  //         ),
  //       });
  //     }, 500);
  //   }
  // };

  // changeSearch = event => {
  //   let dialogs = [];
  //   if (event.target.value === "") {
  //     dialogs = this.allDialog;
  //   } else {
  //     this.allDialog.forEach(elem => {
  //       const str = elem.name
  //         .toUpperCase()
  //         .includes(event.target.value.toUpperCase());
  //       str && dialogs.push(elem);
  //     });
  //   }
  //   this.setState({
  //     search: event.target.value,
  //     dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
  //   });
  // };

  // goToChat = item => {
  //   ChatService.setSelectDialog(item);
  //   this.chat.current.open();
  // };

  // createNewDialog = async user => {
  //   // False - Private dialog
  //   ChatService.createPrivateDialog(user.cube_user_id)
  //     .then(async newDialog => {
  //       //this.fetchDialog();
  //       ChatService.setSelectDialog(newDialog);
  //       this.chat.current.open();
  //       this.modalRef.close();
  //     })
  //     .catch(error => {
  //       this.modalRef.close();
  //       this.setState({ isLoader: false });
  //     });
  // };

  // createNewGroup = async (occupants_ids, groupName, img) => {
  //   return new Promise((resolve, reject) => {
  //     ChatService.createPublicDialog(occupants_ids, groupName, img)
  //       .then(async newDialog => {
  //         ChatService.setSelectDialog(newDialog);
  //         this.chat.current.open();
  //         this.modalRef.close();
  //         resolve(true);
  //       })
  //       .catch(error => {
  //         this.modalRef.close();
  //         this.setState({ isLoader: false });
  //         resolve(true);
  //       });
  //   });
  // };

  // deleteChatDialog = dialog => {
  //   ChatService.deleteChatDialog(dialog);
  // };

  // _renderDialog = item => {
  //   return (
  //     <Item
  //       item={item}
  //       goToChat={this.goToChat}
  //       deleteChatDialog={this.deleteChatDialog}
  //     />
  //   );
  // };

  render() {
    const { user } = this.props;
    if (!user.id) {
      return null;
    }
    return null;
    // return (
    //   <>
    //     <Message ref={this.chat} />
    //     <div
    //       className="chatwindow max-w-md w-full shadow-sm border rounded-t-xl"
    //       style={{ zIndex: 99 }}
    //     >
    //       <div className="bg-white rounded-t-xl">
    //         <div className="flex items-center justify-between hover:bg-gray-100 py-2 px-3 rounded-t-xl">
    //           <div
    //             onClick={this.dialogToggle}
    //             className="flex items-center cursor-pointer flex-grow mr-3"
    //           >
    //             <div className="relative">
    //               <Image
    //                 className="h-11 w-11 rounded-full object-cover mr-3"
    //                 src={
    //                   user.profile_photo
    //                     ? OptimizeImage(user.profile_photo)
    //                     : avatar
    //                 }
    //               />
    //               <div className="absolute right-0">
    //                 <OnlineStatusXS />
    //               </div>
    //             </div>
    //             <p>Messaging</p>
    //           </div>
    //           <div className="flex items-center">
    //             {/* <Dropdown
    //               icon={false}
    //               wrapSelection={true}
    //               direction="left"
    //               pointing="top left"
    //               text={
    //                 <div>
    //                   <div className="hover:bg-gray-300 rounded-full flex items-center justify-center mr-3 h-8 w-8 cursor-pointer">
    //                     <BsThreeDots size={16} color={colors.darkGray} />
    //                   </div>
    //                 </div>
    //               }
    //             >
    //               <Dropdown.Menu className="rounded--lg bg-white shadow-lg overflow-show border-0 w-96">
    //                 <div className="p-2">
    //                   <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
    //                     <p>Manage conversations</p>
    //                   </div>
    //                   <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
    //                     <p>Messaging settings</p>
    //                   </div>
    //                   <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
    //                     <p>Message request inbox</p>
    //                   </div>
    //                   <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
    //                     <p>Set away message</p>
    //                   </div>
    //                 </div>
    //               </Dropdown.Menu>
    //             </Dropdown> */}
    //             <div
    //               onClick={() => this.modalRef.open()}
    //               className="cursor-pointer hover:bg-gray-300 w-8 h-8 mr-2 flex items-center justify-center rounded-full"
    //             >
    //               <FiEdit size={16} color={colors.darkGray} />
    //             </div>
    //             <div
    //               onClick={() => this.setState({ height: !this.state.height })}
    //               className="cursor-pointer hover:bg-gray-300 w-8 h-8 flex items-center justify-center rounded-full"
    //             >
    //               {this.state.height ? (
    //                 <FiChevronUp size={16} color={colors.darkGray} />
    //               ) : (
    //                 <FiChevronDown size={16} color={colors.darkGray} />
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="chatwindow-all-threads "
    //           style={{
    //             height: this.state.height === true ? "calc(100vh - 25vh)" : 0,
    //           }}
    //         >
    //           <div
    //             className="pl-1 pr-2 chatwindow-all-threads h-full overflow-auto"
    //             style={{ minWidth: 1, minHeight: 1 }}
    //             id="scrollbar"
    //           >
    //             {isLoader ? (
    //               <div className="h-full items-center flex justify-center">
    //                 <Loader className="inline active" />
    //               </div>
    //             ) : dataProvider._data.length === 0 ? (
    //               <div className="h-full items-center flex justify-center">
    //                 <p>No chats yet</p>
    //               </div>
    //             ) : this.state.isAlredy ? (
    //               dataProvider._data.length > 0 ? (
    //                 <div>
    //                   {dataProvider._data.map(item => this._renderDialog(item))}
    //                 </div>
    //               ) : (
    //                 <div className="h-full items-center flex justify-center">
    //                   <p>No results</p>
    //                 </div>
    //               )
    //             ) : null}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <FriendModal
    //       ref={ref => (this.modalRef = ref)}
    //       createNewDialog={this.createNewDialog}
    //       createNewGroup={this.createNewGroup}
    //     />
    //   </>
    // );
  }
}
const mapStateToProps = state => {
  return {
    user: state.User.user,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(Dialog);
