import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import "./styles.css";

class Feedtabs extends Component {
  constructor(props) {
    super(props);
    this.state = { feedTopTabs: false };
  }

  render() {
    return (
      <div
        style={{
          position: "sticky",
          overflow: "hidden",
          top: 53,
          zIndex: 1,
        }}
        className="relative mb-3 shadow-sm access-info-tabs feedtabs">
        <Tab.Container defaultActiveKey="Home">
          <Nav
            variant="pills"
            className="sticky top-0 z-10 flex flex-row items-center w-full px-5 py-3 bg-white h-14 gap-x-10">
            <Nav.Item>
              <Nav.Link
                onClick={() => this.props.switchTab("home")}
                style={{ paddingBottom: 12 }}
                eventKey="Home"
                className="hover:primary--text">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => this.props.switchTab("friends")}
                style={{ paddingBottom: 12 }}
                eventKey="Friends"
                className="hover:primary--text">
                Friends
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/saved" className="p-2 darkGray">
                Saved
              </NavLink>
            </Nav.Item>

            {/* <div className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-2 top-2 hover:bg-gray-300">
              <FiSliders size={18} style={{ color: colors.primary }} />
            </div> */}
          </Nav>
        </Tab.Container>
      </div>
    );
  }
}

export default Feedtabs;
