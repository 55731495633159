import * as React from "react";
import { parseValue, isMentionPartType } from "utils";

const renderPart = (part, index) => {
  if (!part.partType) {
    return (
      <p className="fs-13" key={index}>
        {part.text}
      </p>
    );
  }
  if (isMentionPartType(part.partType)) {
    return (
      <p
        className="black Medium fs-14 px-1 hover:underline hover:black"
        key={`${index}-${part.data?.trigger}`}
        //style={part.partType.textStyle}
        //onPress={() => goToProfile(part.data.id)}
      >
        {part.text}
      </p>
    );
  }
  return (
    <span
      key={`${index}-pattern`}
      //style={part.partType.textStyle}
    >
      {part.text}
    </span>
  );
};

const RenderMention = props => {
  const { parts } = parseValue(props.value, props.partTypes);
  return (
    <div style={props.style} className="flex items-center">
      {parts.map(renderPart)}
    </div>
  );
};

export default RenderMention;
