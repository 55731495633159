import * as React from "react";
import logo from "assets/images/weeee/icon.png";
import { Image } from "react-bootstrap";

export default function Authaside() {
  return (
    <>
      <div className="w-full max-w-lg mb-3 rounded-full ">
        <Image src={logo} className="w-40 m-auto" />
      </div>
      <h3 className="text-5xl italic text-white lg:text-6xl Medium">
        Welcome back
      </h3>{" "}
      <p className="mt-2 text-lg italic white">
        Life connected with tha app that had it all...
      </p>
    </>
  );
}
