import colors from "config/color/color.js";

const styles = {
  userName: {
    color: colors.black,
    fontSize: 16,
  },
};

export default styles;
