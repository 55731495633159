import React from "react";
import {
  BrowserRouter as BrowserRouter,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import colors from "config/color/color.js";
import { LifeWidget, Config } from "common";
import { Header } from "components";
import { Dialog } from "chat";
import ScrollToTop from "react-scroll-to-top";
import {
  LoginScreen,
  RegisterScreen,
  ForgotpswdScreen,
  FriendsScreen,
  NotificationsScreen,
  FeedScreen,
  ProfileScreen,
  AboutScreen,
  WorkAndEducationScreen,
  PlaceLivedScreen,
  ContactAndBasicInfoScreen,
  FamilyAndRelationshipsScreen,
  ProfileAllFriendsScreen,
  FriendBirthdaysScreen,
  ProfileFamilyAndRelativesScreen,
  ProfileBusinessAssociateScreen,
  ProfileCoWorkersScreen,
  ProfilCustomersScreen,
  ProfileFollowersScreen,
  ProfileBlockFriendsScreen,
  ProfilePhotosScreen,
  ProfileVideosScreen,
  GroupFeedScreen,
  GroupDiscoverScreen,
  GroupCreateScreen,
  GroupDetailsScreen,
  GroupAboutScreen,
  GroupMembersScreen,
  GroupMediaScreen,
  BarsScreen,
  BarCreateScreen,
  BarDetailsScreen,
  RestaurantsScreen,
  SavedScreen,
  SavedSettingsScreen,
  SavedDetailScreen,
  AvailableFlirtScreen,
  FlirtSettingsScreen,
  ActiveFlirtScreen,
  RequestedFlirtScreen,
  PendingFlirtScreen,
  FlirtDetailScreen,
  LeaderboardInterScreen,
  LeaderboardScreen,
  BillofRightScreen,
  ShareUsScreen,
  HowToScreen,
  AccessInfoScreen,
  CommsScreen,
  MyCompaniesScreen,
  CompanyDetailScreen,
  LogoBannerScreen,
  MyKeywordScreen,
  PostJobStartedScreen,
  PostJobDetailScreen,
  PostJobDescriptionScreen,
  PostJobSettingScreen,
  PostJobQualificationsScreen,
  PostJobPreviewScreen,
  MyJobsScreen,
  MyJobsStatusInActiveScreen,
  MyJobsStatusActiveScreen,
  CandidatesScreen,
  CandidatesAwaitingScreen,
  CandidatesReviewedScreen,
  CandidatesContactingScreen,
  CandidatesHiredScreen,
  CandidatesRejectedScreen,
  PublicJobsScreen,
  PublicJobsDetailScreen,
  ContactInfoFormScreen,
  DocumentScreen,
  QuestionsScreen,
  ResumeScreen,
  NearMeScreen,
  GoingScreen,
  PendingScreen,
  MyBandScreen,
  InterestedScreen,
  MyEventsScreen,
  CalendarScreen,
  EventDetailsScreen,
  FeedbackScreen,
  MeScreen,
  SearchScreen,
  MessengerScreen,
  LiveMusicScreen,
  BookingRequestScreen,
} from "navigation";
import { PostReaction } from "components";
import Tailheader from "./components/Header/Tailheader";
import {
  SkipLineScreen,
  BeerScreen,
  ChampagneScreen,
  CustomDrinkScreen,
  ProductScreen,
  InsertTablesScreen,
  MixersScreen,
  NightlyTotalScreen,
  OrdersScreen,
  ShotsScreen,
  ViewOrderScreen,
  WineScreen,
  BarInviteScreen,
  EnhancementSettingScreen,
  BarTableScreen,
  AdminToolScreen
} from "Modules";

const CustomRoutes = (props) => {
  let currentPath = window.location.pathname;
  const authStyle = props.User.token ? "sticky -top-1 z-10" : "hidden";
  // const dashboardStyle = props.User.token ? "pt-16" : null;

  LifeWidget.init({
    url: Config.lifeWidget.endpoint,
  });

  if (props.User.token) {
    LifeWidget.setClientToken(props.User.token);
  }

  React.useEffect(() => { }, [currentPath]);

  return (
    <BrowserRouter>
      <div className={authStyle}>
        {props.User.token && <Tailheader />}
        {!props.User.token &&
          currentPath !== "/login" &&
          currentPath !== "/registration" &&
          currentPath !== "/forgot-password" && <Redirect to="/login" />}
      </div>
      {/* <Header /> */}
      <div style={{ maxWidth: 1500, margin: "auto" }}>
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/registration" component={RegisterScreen} />
          <Route exact path="/forgot-password" component={ForgotpswdScreen} />
          <Route exact path="/" component={FeedScreen} />

          <Route exact path="/notifications" component={NotificationsScreen} />
          <Route
            exact
            path="/profile/about_work_and_education"
            component={WorkAndEducationScreen}
          />
          <Route
            exact
            path="/profile/about_place_lived"
            component={PlaceLivedScreen}
          />

          <Route
            exact
            path="/profile/about_family_and_relationships"
            component={FamilyAndRelationshipsScreen}
          />

          <Route
            exact
            path="/profile/friends_with_upcoming_birthdays"
            component={FriendBirthdaysScreen}
          />
          <Route
            exact
            path="/profile/friends_family_and_relatives"
            component={ProfileFamilyAndRelativesScreen}
          />
          <Route
            exact
            path="/profile/friends_business_associate"
            component={ProfileBusinessAssociateScreen}
          />
          <Route
            exact
            path="/profile/friends_coworkers"
            component={ProfileCoWorkersScreen}
          />
          <Route
            exact
            path="/profile/friends_customers"
            component={ProfilCustomersScreen}
          />
          <Route
            exact
            path="/profile/friends_followers"
            component={ProfileFollowersScreen}
          />
          <Route
            exact
            path="/profile/friends_block"
            component={ProfileBlockFriendsScreen}
          />

          <Route exact path="/groups" component={GroupFeedScreen} />
          <Route
            exact
            path="/groups/discover"
            component={GroupDiscoverScreen}
          />
          <Route exact path="/groups/create" component={GroupCreateScreen} />

          <Route
            exact
            path="/groups/details/about"
            component={GroupAboutScreen}
          />
          <Route
            exact
            path="/groups/details/members/:id"
            component={GroupMembersScreen}
          />
          <Route
            exact
            path="/groups/details/media"
            component={GroupMediaScreen}
          />
          <Route path="/groups/:id" component={GroupDetailsScreen} />
          <Route exact path="/bars" component={BarsScreen} />
          <Route
            exact
            path="/bars/bar-create/:business_type"
            component={BarCreateScreen}
          />
          <Route exact path="/bars/bar-edit/:id" component={BarCreateScreen} />
          <Route
            exact
            path="/bars/bar-details/:id"
            component={BarDetailsScreen}
          />
          <Route exact path="/restaurants" component={RestaurantsScreen} />
          <Route exact path="/saved" component={SavedScreen} />
          <Route exact path="/saved/settings" component={SavedSettingsScreen} />
          <Route exact path="/saved/:id" component={SavedDetailScreen} />
          <Route
            exact
            path="/flirts/available-flirts"
            component={AvailableFlirtScreen}
          />
          <Route exact path="/flirt/settings" component={FlirtSettingsScreen} />
          <Route
            exact
            path="/flirts/active-flirts"
            component={ActiveFlirtScreen}
          />
          <Route
            exact
            path="/flirts/requested-flirts"
            component={RequestedFlirtScreen}
          />
          <Route
            exact
            path="/flirts/pending-flirts"
            component={PendingFlirtScreen}
          />
          <Route
            exact
            path="/flirts/flirt-detail/:id"
            component={FlirtDetailScreen}
          />
          <Route exact path="/leaderboard/usa" component={LeaderboardScreen} />
          <Route
            exact
            path="/leaderboard/international"
            component={LeaderboardInterScreen}
          />
          <Route exact path="/bill_of_rights" component={BillofRightScreen} />
          <Route exact path="/share_us" component={ShareUsScreen} />
          <Route exact path="/tutorial" component={HowToScreen} />
          <Route exact path="/your_information" component={AccessInfoScreen} />
          <Route exact path="/communication" component={CommsScreen} />

          <Route exact path="/my_companies" component={MyCompaniesScreen} />
          <Route
            exact
            path="/my_company_details/:id"
            component={CompanyDetailScreen}
          />
          <Route
            exact
            path="/my_company_details/logo_banner/:id"
            component={LogoBannerScreen}
          />
          <Route
            exact
            path="/my_company_details/my_keywords/:id"
            component={MyKeywordScreen}
          />
          <Route
            exact
            path="/post_job/getting_started"
            component={PostJobStartedScreen}
          />
          <Route
            exact
            path="/post_job/job_detail"
            component={PostJobDetailScreen}
          />
          <Route
            exact
            path="/post_job/job_description"
            component={PostJobDescriptionScreen}
          />
          <Route
            exact
            path="/post_job/applications_settings"
            component={PostJobSettingScreen}
          />
          <Route
            exact
            path="/post_job/unified_qualifications"
            component={PostJobQualificationsScreen}
          />
          <Route
            exact
            path="/post_job/preview_job"
            component={PostJobPreviewScreen}
          />
          <Route exact path="/jobs" component={MyJobsScreen} />
          <Route
            exact
            path="/jobs/status_inactive"
            component={MyJobsStatusInActiveScreen}
          />
          <Route
            exact
            path="/jobs/status_active"
            component={MyJobsStatusActiveScreen}
          />
          <Route exact path="/candidates" component={CandidatesScreen} />
          <Route
            exact
            path="/candidates/awaiting_review"
            component={CandidatesAwaitingScreen}
          />
          <Route
            exact
            path="/candidates/reviewed"
            component={CandidatesReviewedScreen}
          />
          <Route
            exact
            path="/candidates/contacting"
            component={CandidatesContactingScreen}
          />
          <Route
            exact
            path="/candidates/hired"
            component={CandidatesHiredScreen}
          />
          <Route
            exact
            path="/candidates/rejected"
            component={CandidatesRejectedScreen}
          />
          <Route exact path="/public/jobs" component={PublicJobsScreen} />
          <Route
            exact
            path="/job/details/:id"
            component={PublicJobsDetailScreen}
          />
          <Route
            exact
            path="/lifewidgetsapply/form/contact-info"
            component={ContactInfoFormScreen}
          />
          <Route
            exact
            path="/lifewidgetsapply/form/resume"
            component={ResumeScreen}
          />
          <Route
            exact
            path="/lifewidgetsapply/form/questions"
            component={QuestionsScreen}
          />
          <Route
            exact
            path="/lifewidgetsapply/form/documents"
            component={DocumentScreen}
          />

          <Route path="/events/nearme" component={NearMeScreen} />
          <Route path="/events/going" component={GoingScreen} />

          <Route path="/events/pending" component={PendingScreen} />
          <Route path="/events/interested" component={InterestedScreen} />
          <Route path="/events/my_events" component={MyEventsScreen} />
          <Route path="/events/calendar" component={CalendarScreen} />
          <Route path="/events/:id" component={EventDetailsScreen} />
          <Route path="/feedback" component={FeedbackScreen} />
          <Route path="/messenger" component={MessengerScreen} />
          <Route path="/search/:type" component={SearchScreen} />

          <Route path="/bands/my-bands" component={MyBandScreen} />
          <Route path="/live-music/:type" component={LiveMusicScreen} />

          <Route path="/bands/my_bands" component={MyBandScreen} />
          <Route path="/bands/request" component={BookingRequestScreen} />

          <Route exact path="/friends" component={FriendsScreen} />
          <Route exact path="/friends/:request" component={FriendsScreen} />
          <Route exact path="/me" component={MeScreen} />

          {/* bar modules start */}
          <Route exact path="/bar/custom-drink" component={CustomDrinkScreen} />
          <Route exact path="/bar/wine" component={WineScreen} />
          <Route exact path="/bar/champagne" component={ChampagneScreen} />
          <Route exact path="/bar/beer" component={BeerScreen} />
          <Route exact path="/bar/shots" component={ShotsScreen} />
          <Route exact path="/bar/mixers" component={MixersScreen} />
          <Route exact path="/bar/orders" component={OrdersScreen} />
          <Route exact path="/bar/orders/view" component={ViewOrderScreen} />

          <Route exact path="/invite/:id" component={BarInviteScreen} />
          <Route exact path="/bar/skip-line" component={SkipLineScreen} />
          <Route exact path="/bar/tables" component={InsertTablesScreen} />

          <Route exact path="/bar/:id/logo" component={LogoBannerScreen} />
          <Route exact path="/bar/:id/schedule" component={SkipLineScreen} />
          <Route exact path="/bar/:id/layout" component={InsertTablesScreen} />
          <Route exact path="/bar/:id/table" component={BarTableScreen} />
          <Route exact path="/bar/:id/settings" component={EnhancementSettingScreen} />
          <Route exact path="/bar/:id/tools" component={AdminToolScreen} />
          <Route exact path="/bar/:id/total" component={NightlyTotalScreen}
          />

          <Route
            exact path="/bar/:id/:category_id?" component={ProductScreen}
          />
          <Route exact path="/bar/:id/reports/:slug" component={OrdersScreen} />
          {/* bar modules end */}

          <Route path="/:username/about" component={AboutScreen} />
          <Route
            path="/:username/friends"
            component={ProfileAllFriendsScreen}
          />
          <Route
            exact
            path="/:username/photos"
            component={ProfilePhotosScreen}
          />
          <Route path="/:username/videos" component={ProfileVideosScreen} />
          <Route path="/:username/edit" component={ContactAndBasicInfoScreen} />
          <Route exact path="/:username" component={ProfileScreen} />
        </Switch>
      </div>
      <ScrollToTop
        top={500}
        smooth
        style={{ padding: 8, backgroundColor: colors.primary }}
        color={colors.white}
      />
      <PostReaction />
      {props.User.token && <Dialog />}
    </BrowserRouter>
  );
};

const mapStateToProps = ({ User }) => ({ User });

export default connect(mapStateToProps)(CustomRoutes);
