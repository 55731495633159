import React, { Component } from "react";
import colors from "config/color/color.js";
import { Link, NavLink } from "react-router-dom";
import { FiSliders } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeartCircle } from "react-icons/io5";
import { HiOutlineArrowLeft } from "react-icons/hi";

class Availableflirtaside extends Component {
  constructor(props) {
    super(props);
    this.state = { collection: false };
  }

  collectionPopupOpen = () => {
    this.setState({ collection: true });
  };

  collectionPopupClose = () => {
    this.setState({ collection: false });
  };
  render() {
    return (
      <div className="fixed flex flex-col p-5 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Main Menu
                </Link>
                <h2 className="text-2xl font-medium Medium">Flirts</h2>
              </div>
            </div>
            <NavLink
              to="/flirt/settings"
              activeClassName="important:bg--lightPrimary"
              className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
              <FiSliders color={colors.primary} size={18} />
            </NavLink>
          </div>
          <div className="my-3 divider" />

          <div className="mt-5">
            <NavLink
              to="/flirts/available-flirts"
              activeClassName="important:bg--lightPrimary important:primary"
              className="flex items-center p-2 mb-3 black hover:bg--lightPrimary bg--lightGray rounded-xl hover:primary-only-text">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <IoHeartCircle size={30} style={{ color: colors.white }} />
              </div>
              Available Flirts
            </NavLink>
            <NavLink
              to="/flirts/active-flirts"
              activeClassName="important:bg--lightPrimary important:primary"
              className="flex items-center p-2 mb-3 black hover:bg--lightPrimary bg--lightGray rounded-xl hover:primary-only-text">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <FaHeart size={22} style={{ color: colors.white }} />
              </div>
              Active Flirts
            </NavLink>

            <NavLink
              to="/flirts/requested-flirts"
              activeClassName="important:bg--lightPrimary important:primary"
              className="flex items-center p-2 mb-3 black hover:bg--lightPrimary bg--lightGray rounded-xl hover:primary-only-text">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <IoMdHeartEmpty size={22} style={{ color: colors.white }} />
              </div>
              Request Flirts
            </NavLink>
            <NavLink
              to="/flirts/pending-flirts"
              activeClassName="important:bg--lightPrimary important:primary"
              className="flex items-center p-2 mb-3 black hover:bg--lightPrimary bg--lightGray rounded-xl hover:primary-only-text">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <IoMdHeartEmpty size={22} style={{ color: colors.white }} />
              </div>
              Pending Flirts
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Availableflirtaside;
