import React, { Component } from "react";
import { Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import colors from "config/color/color";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

class Postjobstarted extends Component {
  render() {
    const countryOptions = [
      { key: "af", value: "af", flag: "af", text: "Afghanistan" },
      { key: "ax", value: "ax", flag: "ax", text: "Aland Islands" },
      { key: "al", value: "al", flag: "al", text: "Albania" },
      { key: "dz", value: "dz", flag: "dz", text: "Algeria" },
      { key: "as", value: "as", flag: "as", text: "American Samoa" },
      { key: "ad", value: "ad", flag: "ad", text: "Andorra" },
      { key: "ao", value: "ao", flag: "ao", text: "Angola" },
      { key: "ai", value: "ai", flag: "ai", text: "Anguilla" },
      { key: "ag", value: "ag", flag: "ag", text: "Antigua" },
      { key: "ar", value: "ar", flag: "ar", text: "Argentina" },
      { key: "am", value: "am", flag: "am", text: "Armenia" },
      { key: "aw", value: "aw", flag: "aw", text: "Aruba" },
      { key: "au", value: "au", flag: "au", text: "Australia" },
      { key: "at", value: "at", flag: "at", text: "Austria" },
      { key: "az", value: "az", flag: "az", text: "Azerbaijan" },
      { key: "bs", value: "bs", flag: "bs", text: "Bahamas" },
      { key: "bh", value: "bh", flag: "bh", text: "Bahrain" },
      { key: "bd", value: "bd", flag: "bd", text: "Bangladesh" },
      { key: "bb", value: "bb", flag: "bb", text: "Barbados" },
      { key: "by", value: "by", flag: "by", text: "Belarus" },
      { key: "be", value: "be", flag: "be", text: "Belgium" },
      { key: "bz", value: "bz", flag: "bz", text: "Belize" },
      { key: "bj", value: "bj", flag: "bj", text: "Benin" },
    ];
    return (
      <div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <p className="mb-1 fs-16 Medium">Job title</p>
          <Form.Control className="h-12 border shadow-none rounded--xl" />
        </div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <p className="mb-1 fs-16 Medium">
            Can this job be performed remotely, meaning primarily from home? If
            so, we’ll add a “Remote” tag to your post
          </p>

          <RadioGroup>
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              className="mt-2 border important:Regular-all rounded-xl"
            />
            <FormControlLabel
              value="covid"
              control={<Radio />}
              label="Temporarily due to COVID-19"
              className="mt-2 border important:Regular-all rounded-xl"
            />
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              className="mt-2 border important:Regular-all rounded-xl"
            />
          </RadioGroup>
        </div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <div className="mb-2">
            <p className="fs-16 Medium">
              What is the street address for this job?(optional)
            </p>
            <p className="darkGray fs-13">
              Providing a street address can help you find relevant candidates
            </p>
          </div>
          <Dropdown
            className="mb-2 border gray rounded--xl"
            placeholder="Select Country"
            fluid
            search
            selection
            options={countryOptions}
          />
          <Form.Control className="h-12 mb-2 border shadow-none rounded--xl" />
          <Form.Control
            placeholder="City"
            className="h-12 mb-2 border shadow-none rounded--xl"
          />
          <Form.Control
            placeholder="Street"
            className="h-12 mb-2 border shadow-none rounded--xl"
          />
          <Form.Control
            placeholder="Zip Code"
            className="h-12 mb-2 border shadow-none rounded--xl"
          />
        </div>
        <div className="flex items-center justify-between mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <div />
          <Link
            to="/post_job/job_detail"
            style={{ color: colors.white }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary hover:opacity-80 focus:bg--primary white Regular rounded--xl hover:scale-90">
            Save and continue
          </Link>
        </div>
      </div>
    );
  }
}

export default Postjobstarted;
