import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { Buttonused, DeleteBandPopup } from "components";
import { OptimizeImage } from "helpers";
import { avatar } from "assets";
import { Emitter } from "common";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Bandcard extends Component {
  constructor(props) {
    super(props);
    this.deleteBandRef = React.createRef();
    this.state = {
      redirect: false,
    };
  }
  handleInvitaion = (value) => {};
  deleteBand = (item) => {
    this.deleteBandRef.current.deleteBandPopupOpen(item);
  };
  removeBand = (item) => {
    // alert(JSON.stringify(item));
    this.props.removeBand(item.id);
    this.deleteBandRef.current.deleteBandPopupClose();
    this.setState({ redirect: true });
  };
  render() {
    if (this.state.redirect) return <Redirect to="/bands/my-bands" />;
    const { item, index, route } = this.props;

    return (
      <div key={index} className="bg-white shadow-sm rounded-xl">
        <>
          {item.attachments && item.attachments.length > 0 ? (
            <div
              onClick={() => Emitter.emit("OPEN_BAND_MODAL", item)}
              className="w-full">
              <Image
                src={OptimizeImage(item.attachments[0].attachment_url)}
                className="object-cover w-full h-52 rounded-t-xl"
              />
            </div>
          ) : (
            <div
              onClick={() => Emitter.emit("OPEN_BAND_MODAL", item)}
              className="w-full">
              <Image
                src={avatar}
                className="object-cover w-full h-52 rounded-t-xl"
              />
            </div>
          )}
        </>

        <div className="p-4">
          <div className="flex items-center">
            <p className="fs-12 darkGray">min ${item.band_min_price} - </p>
            {!!item.band_max_price && (
              <p className="my-2 fs-12 darkGray">max ${item.band_max_price}/</p>
            )}
            <p className="fs-12 darkGray">{item.band_rate}</p>
          </div>
          <div className="flex">
            <p className="my-2 fs-12 darkGray">{item.band_description}</p>
          </div>

          <h3 className="mt-2 text-xl Medium">{item.band_name}</h3>

          <div className="flex w-full mt-3">
            <Buttonused
              className="items-center justify-center w-full h-10 px-2 rounded-md bg--lightPrimary primary d-flex"
              onClick={() => this.props.open(item)}
              rippleContainer="flex-grow rounded-md "
              Text="Create Event"
            />
          </div>
          <div className="flex w-full mt-3">
            <Buttonused
              className="items-center justify-center w-full h-10 px-2 bg-red-100 rounded-md danger d-flex"
              onClick={() => this.deleteBand(item)}
              rippleContainer="flex-grow rounded-md "
              Text="Delete Band"
            />
          </div>
        </div>
        <DeleteBandPopup
          ref={this.deleteBandRef}
          {...this.props}
          item={item}
          callbackAction={this.removeBand}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.Band.data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/BandRedux");
  return {
    ...ownProps,
    ...stateProps,
    removeBand: (bandId) => {
      actions.deleteBand(dispatch, bandId);
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Bandcard);
