import * as React from "react";
import { Button } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import colors from "config/color/color.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class DeletePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteWorkplaces: false };
    this.deleteWorkplacesRef = React.createRef();
  }

  deleteWorkplacesPopupOpen = () => {
    this.setState({ deleteWorkplaces: true });
  };

  deleteWorkplacesPopupClose = () => {
    this.setState({ deleteWorkplaces: false });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.state.deleteWorkplaces}
          onClose={this.deleteWorkplacesPopupClose}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            {" "}
            <div className="flex items-center px-3 py-3 border-b ">
              <p className="text-2xl Medium">Are you sure?</p>
              <div
                onClick={this.deleteWorkplacesPopupClose}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="p-4">
              <p>Are you sure you want to remove this from your profile?</p>
            </div>
          </DialogContent>
          <DialogActions className="p-3">
            <Button
              onClick={this.deleteWorkplacesPopupClose}
              style={{ color: colors.black }}
              className="px-5 transition duration-300 transform border-0 shadow-none bg--lightGray rounded--lg hover:bg--lightGray focus:bg--lightGray black hover:scale-90">
              Cancel
            </Button>
            <Button
              style={{ color: colors.white }}
              className="px-5 transition duration-300 transform border-0 shadow-none bg--primary rounded--lg hover:bg--primary focus:bg--primary black hover:scale-90">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DeletePopup;
