import colors from "config/color/color.js";

const styles = {
  textInput: {
    backgroundColor: colors.lightGray,
    border: 0,
    borderRadius: 40,
  },
};

export default styles;
