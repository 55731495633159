import React from "react";
import { OrdersAside } from "Modules";
import { Buttonused } from "components";
import { IoRefreshOutline } from "react-icons/io5";

const people = [{ name: "Jerry Thibeau", tips: 15, location: "Bar", id: 1 }];

export default function ViewOrder() {
  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <OrdersAside title="Orders View" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="m-auto w-full space-y-5">
          <div className="overflow-y-auto" id="scrollbar">
            <div className="flex items-center justify-between mx-4">
              <div className="flex items-center lg:max-w-md lg:mr-auto justify-center gap-x-2 bg-white shadow-sm rounded-lg px-10 py-3.5">
                <p className=" text-[14px] tracking-wider">
                  {" "}
                  Nightly Tip Jar:{" "}
                </p>
                <p className="primary Medium tracking-widest"> $345.75</p>
              </div>
              <div className="w-20">
                <Buttonused
                  Icon={<IoRefreshOutline size={20} />}
                  onClick={() => alert("refresh")}
                  className="bg-white primary hover:text-white focus:text-white flex items-center justify-center hover:bg--primary focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
                />
              </div>
            </div>
            <div className="bg-white shadow-lg overflow-hidden m-4 rounded-lg">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-black uppercase text-sm leading-normal">
                    <th className="py-4 px-6 capitalize text-[12px] text-center w-[150px]">
                      Order Number
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-left">
                      Name
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Location
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Tips
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {people.map((item, index) => (
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-4 px-6 text-left whitespace-nowrap w-[150px]">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">{item.id}</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-left">
                        <div className="flex items-center gap-x-2">
                          <img
                            className="w-8 h-8 rounded-full"
                            src="https://images.unsplash.com/photo-1547595628-c61a29f496f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8d2luZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
                          />
                          <p className="black">{item.name}</p>
                        </div>
                      </td>
                      <td className="py-4 text-center">
                        <p className="black">{item.location}</p>
                      </td>
                      <td className="py-4 text-center">
                        <p className="black">{item.tips}%</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div />
              <div className="overflow-x-auto relative col-span-2">
                <div className="bg-white shadow-lg overflow-hidden m-4 rounded-lg">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-black uppercase bg-gray-200 rounded-none">
                      <tr>
                        <th className="py-4 px-6 text-[13px]">Qty</th>
                        <th className="py-4 px-6 text-[13px]">Drink</th>
                        <th className="py-4 px-6 text-[13px]">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white dark:bg-gray-800">
                        <th
                          scope="row"
                          className="py-4 px-6 Light text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          1
                        </th>
                        <td className="py-4 px-6 Light">Vodka, Red Bull, Cubes</td>
                        <td className="py-4 px-6 Light">$14</td>
                      </tr>
                      <tr className="bg-white dark:bg-gray-800">
                        <th
                          scope="row"
                          className="py-4 px-6 Light text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          1
                        </th>
                        <td className="py-4 px-6 Light">
                          Vodka, Soda, Lime, Cubes
                        </td>
                        <td className="py-4 px-6 Light">$14</td>
                      </tr>
                      <tr className="bg-white dark:bg-gray-800">
                        <th
                          scope="row"
                          className="py-4 px-6 Light text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          3
                        </th>
                        <td className="py-4 px-6 Light">Shots, Jaeger Bombs</td>
                        <td className="py-4 px-6 Light">$124</td>
                      </tr>
                    </tbody>
                    <tfoot className="border-t">
                      <tr className="text-black">
                        <th scope="row" className="py-4 px-6 text-base"></th>
                        <td className="py-4 text-right">
                          <p className="Light">Total:</p>
                        </td>
                        <td className="py-4 px-6">
                          <p>$ 21,00</p>
                        </td>
                      </tr>
                      <tr className="text-black">
                        <th scope="row" className="py-4 px-6 text-base"></th>
                        <td className="py-4  text-right">
                          <p className="Light">Tip:</p>
                        </td>
                        <td className="py-4 px-6">
                          <p>$ 1,00</p>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
