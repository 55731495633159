import * as React from "react";
import { Button } from "react-bootstrap";
import { GrAttachment } from "react-icons/gr";

class MessageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageText: "",
    };
  }

  changeMessage = event => this.setState({ messageText: event.target.value });

  sendMessage = e => {
    e.preventDefault();
    this.props
      .sendMessageCallback(this.state.messageText)
      .then(() => this.setState({ messageText: "" }))
      .catch(() => this.setState({ messageText: "" }));
  };

  onImageChange = e => {
    let image = e.target.files[0];

    const newFile = new File([image], "filename.jpg", { type: "image/jpeg" });
    const imageInfo = {
      file: {
        size: image.size,
        type: image.type,
        file: newFile,
        name: "samo-name.jpg",
        public: true,
      },
      link: {
        url: URL.createObjectURL(image),
      },
    };

    this.props
      .sendMessageCallback(this.state.messageText, imageInfo.file)
      .then(() => this.setState({ messageText: "" }))
      .catch(() => this.setState({ messageText: "" }));
  };

  render() {
    const { messageText } = this.state;
    return (
      <form onSubmit={this.sendMessage}>
        <div id="scrollbar" className="max-h-64 overflow-auto">
          <input
            aria-label="empty textarea"
            placeholder="Write a message..."
            className="p-2.5 shadow-none resize-none Light rounded-lg outline-none bg-white w-full"
            value={messageText}
            onChange={this.changeMessage}
          />
        </div>
        <div className="bg-gray-200 flex items-center justify-between pl-3">
          <div
            onClick={() => this.inputFileRef.click()}
            className="h-9 w-9 rounded-full hover:bg-gray-300 flex items-center justify-center cursor-pointer"
          >
            <GrAttachment />
            <input
              ref={ref => (this.inputFileRef = ref)}
              id="file-upload"
              type="file"
              onChange={this.onImageChange}
              className="hidden"
            />
          </div>
          <Button
            onClick={this.sendMessage}
            className="bg--primary white hover:bg--primary focus:bg--primary border-0 shadow-none py-4 px-10"
          >
            Send
          </Button>
        </div>
      </form>
    );
  }
}

export default MessageInput;
