import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { connect } from "react-redux";
import { DataProvider } from "recyclerlistview/web";
import AuthService from "../../../chat/services/auth-service";
import ChatService from "../../../chat/services/chat-service";
import FriendModal from "../../../chat/FriendModal";
import Message from "../../../chat/Message";
import { DialogLayoutUtil } from "../../../chat/helpers/LayoutUtil";
import Item from "../../../chat/Dialog/Item";
import { Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { HiOutlineArrowLeft } from "react-icons/hi";

class ConversationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: false,
      newChatHeight: true,
      newChat: false,
      expanded: false,
      threadHeight: true,

      isAlredy: false,
      dataProvider: new DataProvider((r1, r2) => {
        return (
          r1 !== r2 || r1.unread_messages_count !== r2.unread_messages_count
        );
      }),
      layoutProvider: 0,
      count: 20,
      isLoader: true,
      search: "",
    };
    this.chat = React.createRef();
  }
  async componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      await AuthService.init();
      ChatService.updateChatDialog();
      this.fetchDialog();
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { dialogs } = this.props;
    if (this.props.dialogs !== prevProps.dialogs) {
      this.allDialog = dialogs;
      this.setState({
        layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(
          this.scrollWidth
        ),
        dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
      });
    }
  }

  componentWillUnmount() {
    ChatService.setSelectDialog(null);
    window.removeEventListener("resize", this.handleResize);
  }

  fetchDialog = async () => {
    ChatService.fetchDialogsFromServer().then((dialogs) => {
      this.allDialog = dialogs;
      this.setState({
        isAlredy: true,
        isLoader: false,
        layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(
          this.scrollWidth
        ),
        dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
      });
    });
  };

  handleResize = () => {
    if (!this.timer) {
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.timer = null;
        this.setState({
          isAlredy: true,
          layoutProvider: DialogLayoutUtil.getDialogLayoutProvider(
            this.scrollWidth
          ),
        });
      }, 500);
    }
  };

  changeSearch = (event) => {
    let dialogs = [];
    if (event.target.value === "") {
      dialogs = this.allDialog;
    } else {
      this.allDialog.forEach((elem) => {
        const str = elem.name
          .toUpperCase()
          .includes(event.target.value.toUpperCase());
        str && dialogs.push(elem);
      });
    }
    this.setState({
      search: event.target.value,
      dataProvider: this.state.dataProvider.cloneWithRows(dialogs),
    });
  };

  goToChat = (item) => {
    ChatService.setSelectDialog(item);
    this.chat.current.open();
  };

  createNewDialog = async (user) => {
    // False - Private dialog
    ChatService.createPrivateDialog(user.cube_user_id)
      .then(async (newDialog) => {
        //this.fetchDialog();
        ChatService.setSelectDialog(newDialog);
        this.chat.current.open();
        this.modalRef.close();
      })
      .catch((error) => {
        this.modalRef.close();
        this.setState({ isLoader: false });
      });
  };

  createNewGroup = async (occupants_ids, groupName, img) => {
    return new Promise((resolve, reject) => {
      ChatService.createPublicDialog(occupants_ids, groupName, img)
        .then(async (newDialog) => {
          ChatService.setSelectDialog(newDialog);
          this.chat.current.open();
          this.modalRef.close();
          resolve(true);
        })
        .catch((error) => {
          this.modalRef.close();
          this.setState({ isLoader: false });
          resolve(true);
        });
    });
  };

  deleteChatDialog = (dialog) => {
    ChatService.deleteChatDialog(dialog);
  };

  _renderDialog = (item) => {
    return (
      <Item
        item={item}
        goToChat={this.goToChat}
        deleteChatDialog={this.deleteChatDialog}
      />
    );
  };

  render() {
    let { isLoader, dataProvider } = this.state;
    let data =
      dataProvider &&
      dataProvider._data &&
      dataProvider._data.filter((i) =>
        i.name.toUpperCase().includes(this.state.search.toUpperCase())
      );
    return (
      <>
        <div className="overflow-auto sidebar" id="scrollbar">
          <div className="flex items-center justify-between h-12 px-2 bg-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Link
                  to="/"
                  className="flex items-center justify-center w-8 h-8 text-sm bg-gray-400 rounded-full darkGray">
                  <HiOutlineArrowLeft size={15} className="white" />
                </Link>
              </div>
            </div>
            <h4 className="font-medium Medium">Messenger</h4>
            <div className="flex items-center justify-between">
              <div
                onClick={() => this.modalRef.open()}
                className="flex items-center justify-center rounded-full cursor-pointer h-9 w-9">
                <FiPlusCircle className="primary" size={20} />
              </div>
            </div>
          </div>
          <div className="flex px-3 py-2">
            <input
              type="search"
              className="w-full px-3 bg-gray-200 rounded-xl h-11 Regular"
              placeholder="Search Messages"
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </div>
          {isLoader ? (
            <div className="flex items-center justify-center h-full">
              <Loader className="inline active" />
            </div>
          ) : dataProvider._data.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <p>No chats yet</p>
            </div>
          ) : this.state.isAlredy ? (
            dataProvider._data.length > 0 ? (
              <div>{data.map((item) => this._renderDialog(item))}</div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p>No results</p>
              </div>
            )
          ) : null}

          {/* {dataProvider._data.length > 0 && dataProvider._data.map((item, key) => (
          <Item item={item} key={key} goToChat={()=>this.goToChat(item)} />
        ))} */}
        </div>
        <div className="content">
          <Message ref={this.chat} />
        </div>
        <FriendModal
          ref={(ref) => (this.modalRef = ref)}
          createNewDialog={this.createNewDialog}
          createNewGroup={this.createNewGroup}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.User.user,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(ConversationList);
