import React, { Component } from "react";
import "./styles.css";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { Popup, Dropdown } from "semantic-ui-react";
import { FiBookmark, FiUserMinus, FiUserX } from "react-icons/fi";
import { VscCloseAll } from "react-icons/vsc";
import { BsThreeDots, BsClock } from "react-icons/bs";
import { Peopleimage, Peoplenamepopup } from "components";
import { Capitalize } from "helpers";

class AllFriendLeftAside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  followFriend = () => {
    this.props.followFriend(this.props.item.id);
  };

  unfollowFriend = () => {
    this.props.unfollowFriend(this.props.item.id);
  };

  snoozeFriend = () => {
    this.props.snoozeFriend(this.props.item.id);
  };

  unsnoozeFriend = () => {
    this.props.unsnoozeFriend(this.props.item.id);
  };

  cancelFriendRequest = () => {
    this.props.cancelFriendRequest(this.props.item.id);
    this.modalizeRef.current?.close();
  };

  blockToggle = () => {
    this.props.blockToggle(this.props.item.id);
  };
  render() {
    return (
      <>
        <div className="flex p-2 rounded-lg hover:bg-gray-200">
          <Link>
            <Peopleimage
              item={this.props.item}
              {...this.props}
              imageStyle="h-16 w-16 rounded-full mr-3"
            />
          </Link>

          <div className="flex items-center justify-between flex-grow">
            <Link>
              <Peoplenamepopup item={this.props.item} />
              {this.props.item.mutual_friends_count > 0 && (
                <Popup
                  inverted
                  position="bottom center"
                  content={
                    <div>
                      <p className="text-sm cursor-pointer white Light">
                        User Name one
                      </p>
                      <p className="text-sm cursor-pointer white Light">
                        User Name one
                      </p>
                    </div>
                  }
                  trigger={
                    <p className="inline-block text-sm cursor-pointer darkGray Light">
                      {this.props.item.mutual_friends_count} mutual friend
                    </p>
                  }
                />
              )}
            </Link>
            <div className="items-center mt-2">
              <Dropdown
                icon={false}
                direction="left"
                pointing="top"
                text={
                  <div className="flex items-center justify-center w-8 h-8 m-auto rounded-full hover_dots_drpdwn hover:bg-gray-200">
                    <BsThreeDots
                      size={18}
                      style={{ color: colors.darkGray }}
                      className="m-auto"
                    />
                  </div>
                }>
                <Dropdown.Menu className="min-w-full bg-white border-0 shadow-lg rounded--lg overflow-show w-80">
                  <div className="p-2">
                    <div>
                      <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                        <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                          <FiBookmark size={18} />
                        </div>
                        <div>
                          <p>Add To List</p>
                          <p className="text-sm Light darkGray">
                            add this to your list.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="my-1 divider" />
                    <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                        <BsClock size={18} />
                      </div>
                      <div>
                        <p>Unfollow for 30 days</p>
                        <p className="text-sm break-all Light darkGray">
                          You won't see posts from
                          {Capitalize(this.props.item.first_name)} in yours Feed
                          for 30 days
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={this.unfollowFriend}
                      className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                        <VscCloseAll size={18} />
                      </div>
                      <div>
                        <p>Unfollow</p>
                        <p className="text-sm Light darkGray">
                          Stop seeing posts but stay friend.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                        <FiUserMinus size={18} />
                      </div>
                      <div>
                        <p>Unfriend</p>
                        <p className="text-sm Light darkGray">
                          Remove as a friend.
                        </p>
                      </div>
                    </div>
                    <div className="my-1 divider" />
                    <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                        <FiUserX size={18} />
                      </div>
                      <div>
                        <p>Block</p>
                        <p className="text-sm Light darkGray">
                          You won't be able to see or contact each other.
                        </p>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AllFriendLeftAside;
