import { LifeWidget } from "common";

const types = {
  ADD_EVENT_FORM: "ADD_EVENT_FORM",

  RESET_DATA: "RESET_DATA",

  FETCH_EVENT_PENDING: "FETCH_EVENT_PENDING",
  FETCH_EVENT_FAILURE: "FETCH_EVENT_FAILURE",
  FETCH_EVENT_SUCCESS: "FETCH_EVENT_SUCCESS",
  FETCH_EVENT_MORE: "FETCH_EVENT_MORE",

  FETCH_LIVE_EVENT_PENDING: "FETCH_LIVE_EVENT_PENDING",
  FETCH_LIVE_EVENT_FAILURE: "FETCH_LIVE_EVENT_FAILURE",
  FETCH_LIVE_EVENT_SUCCESS: "FETCH_LIVE_EVENT_SUCCESS",
  FETCH_LIVE_EVENT_MORE: "FETCH_LIVE_EVENT_MORE",

  FETCH_GOING_EVENT_SUCCESS: "FETCH_GOING_EVENT_SUCCESS",
  FETCH_GOING_EVENT_MORE: "FETCH_GOING_EVENT_MORE",

  FETCH_PENDING_EVENT_SUCCESS: "FETCH_PENDING_EVENT_SUCCESS",
  FETCH_PENDING_EVENT_MORE: "FETCH_PENDING_EVENT_MORE",

  FETCH_CALENDAR_EVENT_SUCCESS: "FETCH_CALENDAR_EVENT_SUCCESS",
  FETCH_CALENDAR_EVENT_MORE: "FETCH_CALENDAR_EVENT_MORE",

  FETCH_MY_EVENT_SUCCESS: "FETCH_MY_EVENT_SUCCESS",
  FETCH_MY_EVENT_MORE: "FETCH_MY_EVENT_MORE",

  FETCH_NEAR_EVENT_SUCCESS: "FETCH_NEAR_EVENT_SUCCESS",
  FETCH_NEAR_EVENT_MORE: "FETCH_NEAR_EVENT_MORE",

  FETCH_INTERESTED_EVENT_SUCCESS: "FETCH_INTERESTED_EVENT_SUCCESS",
  FETCH_INTERESTED_EVENT_MORE: "FETCH_INTERESTED_EVENT_MORE",

  FETCH_SINGLE_EVENT_PENDING: "FETCH_SINGLE_EVENT_PENDING",
  FETCH_SINGLE_EVENT_FAILURE: "FETCH_SINGLE_EVENT_FAILURE",
  FETCH_SINGLE_EVENT_SUCCESS: "FETCH_SINGLE_EVENT_SUCCESS",

  SUBMIT_EVENT_PENDING: "SUBMIT_EVENT_PENDING",
  SUBMIT_EVENT_SUCCESS: "SUBMIT_EVENT_SUCCESS",
  SUBMIT_EVENT_FAILURE: "SUBMIT_EVENT_FAILURE",

  SUBMIT_RESPONSE_PENDING: "SUBMIT_RESPONSE_PENDING",
  SUBMIT_RESPONSE_SUCCESS: "SUBMIT_RESPONSE_SUCCESS",
  SUBMIT_RESPONSE_FAILURE: "SUBMIT_RESPONSE_FAILURE",

  REMOVE_EVENT: "REMOVE_EVENT",

  ADD_RESPONSE_DATA: "ADD_RESPONSE_DATA",
  ADD_ADDRESS_DATA: "ADD_ADDRESS_DATA",

  ADD_EVENT_FAVOURITE: "ADD_EVENT_FAVOURITE",

  ADD_EVENT_BOOKING: "ADD_EVENT_BOOKING",
  SUBMIT_EVENT_BOOKING: "SUBMIT_EVENT_BOOKING",

  FETCH_EVENT_BOOKING_PENDING: "FETCH_EVENT_BOOKING_PENDING",
  FETCH_EVENT_BOOKING_FAILURE: "FETCH_EVENT_BOOKING_FAILURE",
  FETCH_EVENT_BOOKING_SUCCESS: "FETCH_EVENT_BOOKING_SUCCESS",
  FETCH_EVENT_BOOKING_MORE: "FETCH_EVENT_BOOKING_MORE",

  ACCEPT_BOOKING_EVENT: "ACCEPT_BOOKING_EVENT",
  CANCEL_BOOKING_EVENT: "CANCEL_BOOKING_EVENT",

  CURRENT_LAT_LNG_LOCATION:"CURRENT_LAT_LNG_LOCATION",
};
export const actions = {
  addEventForm: (data) => ({
    type: types.ADD_EVENT_FORM,
    data,
  }),

  setCurrentLocation: (data) => ({
    type: types.CURRENT_LAT_LNG_LOCATION,
    data,
  }),

  addEventBooking: (data) => ({
    type: types.ADD_EVENT_BOOKING,
    data,
  }),

  saveResponse: (data) => ({
    type: types.ADD_RESPONSE_DATA,
    data,
  }),

  saveAddress: (data) => ({
    type: types.ADD_ADDRESS_DATA,
    data,
  }),

  fetchEvents: async (dispatch, per_page, page, params = [], type) => {
    dispatch({ type: types.FETCH_EVENT_PENDING });
    const json = await LifeWidget.fetchEvents(per_page, page, params);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_EVENT_FAILURE,
        message: "Can't get data from server",
      });
    } else if (json.status) {
      dispatch({
        type: types.FETCH_EVENT_FAILURE,
        message: json.data.message,
      });
    } else {
    
      if (page > 1) {
        if (type === "all") {
          dispatch({ type: types.FETCH_EVENT_MORE, page: page, data: json });
        } else if (type === "going") {
          dispatch({
            type: types.FETCH_GOING_EVENT_MORE,
            page: page,
            data: json,
          });
        } else if (type === "pending") {
          dispatch({
            type: types.FETCH_PENDING_EVENT_MORE,
            page: page,
            data: json,
          });
        } else if (type === "calendar") {
          dispatch({
            type: types.FETCH_CALENDAR_EVENT_MORE,
            page: page,
            data: json,
          });
        } else if (type === "my") {
          dispatch({
            type: types.FETCH_MY_EVENT_MORE,
            page: page,
            data: json,
          });
        } else if (type === "near") {
          dispatch({
            type: types.FETCH_NEAR_EVENT_MORE,
            page: page,
            data: json,
          });
        } else if (type === "interested") {
          dispatch({
            type: types.FETCH_INTERESTED_EVENT_MORE,
            page: page,
            data: json,
          });
        }
      } else {
        if (type === "all") {
          dispatch({
            type: types.FETCH_EVENT_SUCCESS,
            page: page,
            data: json,
            pending: json.pending,
          });
        } else if (type === "going") {
          dispatch({
            type: types.FETCH_GOING_EVENT_SUCCESS,
            page: page,
            data: json,
          });
        } else if (type === "pending") {
          dispatch({
            type: types.FETCH_PENDING_EVENT_SUCCESS,
            page: page,
            data: json,
          });
        } else if (type === "calendar") {
          dispatch({
            type: types.FETCH_CALENDAR_EVENT_SUCCESS,
            page: page,
            data: json,
          });
        } else if (type === "my") {
          dispatch({
            type: types.FETCH_MY_EVENT_SUCCESS,
            page: page,
            data: json,
          });
        } else if (type === "near") {
          dispatch({
            type: types.FETCH_NEAR_EVENT_SUCCESS,
            page: page,
            data: json,
          });
        } else if (type === "interested") {
          dispatch({
            type: types.FETCH_INTERESTED_EVENT_SUCCESS,
            page: page,
            data: json,
          });
        }
      }
    }
  },

  fetchLiveEvent: async (dispatch, per_page, page, params = [], type) => {
    dispatch({ type: types.FETCH_LIVE_EVENT_PENDING });
    const json = await LifeWidget.fetchEvents(per_page, page, params);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_LIVE_EVENT_FAILURE,
        message: "Can't get data from server",
      });
    } else if (json.status) {
      dispatch({
        type: types.FETCH_LIVE_EVENT_FAILURE,
        message: json.data.message,
      });
    } else {
      if (page > 1) {
        dispatch({ type: types.FETCH_LIVE_EVENT_MORE, data: json, id: type });
      } else {
        dispatch({
          type: types.FETCH_LIVE_EVENT_SUCCESS,
          data: json,
          id: type,
        });
      }
    }
  },

  fetchEvent: async (dispatch, group_id) => {
    dispatch({ type: types.FETCH_SINGLE_EVENT_PENDING });
    const json = await LifeWidget.fetchEvent(group_id);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_SINGLE_EVENT_FAILURE,
        message: "Can't get data from server",
      });
    } else if (json.status) {
      dispatch({
        type: types.FETCH_SINGLE_EVENT_FAILURE,
        message: json.data.message,
      });
    } else {
      dispatch({ type: types.FETCH_SINGLE_EVENT_SUCCESS, data: json });
    }
  },

  submitEvent: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_EVENT_PENDING });
    const json = await LifeWidget.submitEvents(data);
    if (json === undefined) {
      dispatch({
        type: types.SUBMIT_EVENT_FAILURE,
        message: "Can't get data from server",
      });
    } else if (json.data.success) {
      dispatch({
        type: types.SUBMIT_EVENT_SUCCESS,
        data: json.data,
        message: "Submited successfully!",
      });
    } else {
      dispatch({
        type: types.SUBMIT_EVENT_FAILURE,
        message: "Submission failed!",
        error: json.data.message,
      });
    }
  },

  submitInviteResponse: async (dispatch, data, id, type) => {
    if (type === "shared") {
    } else {
      if (id) {
        dispatch({
          type: types.REMOVE_EVENT,
          data: id,
        });
      }
    }

    dispatch({ type: types.SUBMIT_RESPONSE_PENDING });
    const json = await LifeWidget.submitResponse(data);
    if (json === undefined) {
      dispatch({
        type: types.SUBMIT_EVENT_FAILURE,
        message: "Can't get data from server",
      });
    } else if (json.data.success) {
    
      dispatch({
        type: types.SUBMIT_RESPONSE_SUCCESS,
        data: json.data.data,
        message: "Submited successfully!",
      });
    } else {
      dispatch({
        type: types.SUBMIT_RESPONSE_FAILURE,
        message: "Submission failed!",
        error: json.data.message,
      });
    }
  },
  deleteEvent: async (dispatch, id) => {
    if (id) {
      dispatch({
        type: types.REMOVE_EVENT,
        data: id,
      });
    }

    const json = await LifeWidget.deleteEvent(id);
    if (json !== undefined) {
    }
  },
  addEventFavourite: async (dispatch, id, tab) => {
    dispatch({ type: types.ADD_EVENT_FAVOURITE, id: id, data: tab });
    const json = await LifeWidget.addEventFavourite({ id: id });
  },
  submitEventBooking: async (dispatch, data, tab) => {
    dispatch({ type: types.SUBMIT_EVENT_BOOKING, data:data, tab:tab });
    const json = await LifeWidget.submitEventBooking(data);
  },

  fetchEventBooking: async (dispatch, per_page, page, params = []) => {
    dispatch({ type: types.FETCH_EVENT_BOOKING_PENDING });
    const json = await LifeWidget.fetchEventBooking(per_page, page, params);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_EVENT_BOOKING_FAILURE,
        message: "Can't get data from server",
      });
    } else if (json.status) {
      dispatch({
        type: types.FETCH_EVENT_BOOKING_FAILURE,
        message: json.data.message,
      });
    } else {
      if (page > 1) {
        dispatch({ type: types.FETCH_EVENT_BOOKING_MORE, data: json });
      } else {
        dispatch({
          type: types.FETCH_EVENT_BOOKING_SUCCESS,
          data: json,
        });
      }
    }
  },
  acceptBookingRequest: async (dispatch, id) => {
    dispatch({ type: types.ACCEPT_BOOKING_EVENT, id });
    const json = await LifeWidget.acceptBookingRequest({id:id});
  },
  cancelBookingRequest: async (dispatch, id) => {
    dispatch({ type: types.CANCEL_BOOKING_EVENT, id });
    const json = await LifeWidget.cancelBookingRequest({id:id});
  },
};

const initialState = {
  isFetching: false,
  isProcessing: false,
  error: null,
  message: "",
  data: [],
  goingData: [],
  pendingData: [],
  pendingCount: 0,
  calendarData: [],
  myEventData: [],
  nearEventData: [],
  interestedEventData: [],
  page: 1,
  form: {},
  responseData: {},
  types: [],
  selectedAddress: "",
  total: 0,
  singleEvent: {},
  user: {},
  isLiveFetching: false,
  live: {},
  eventBooking:[],
  isFetchBooking:false,
  eventBookingTotal: 0,
  currentLocation:{}
};

export const reducer = (state = initialState, action) => {
  const { type, error, page, data, message, pending, id, tab } = action;
  switch (type) {
    case types.ADD_EVENT_FORM: {
      return { ...state, form: data, isProcessing: false };
    }

    case types.ADD_RESPONSE_DATA: {
      return { ...state, responseData: data, isProcessing: false };
    }

    case types.ADD_ADDRESS_DATA: {
      return { ...state, selectedAddress: data, isProcessing: false };
    }

    case types.FETCH_EVENT_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: null,
        message: "",
      };
    }

    case types.FETCH_EVENT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: null,
        message: "",
      };
    }

    case types.FETCH_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        data: data.data.data,
        pendingCount: pending,
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_EVENT_MORE: {
      return Object.assign({}, state, {
        isFetching: false,
        data: state.data.concat(data.data.data),
        total: data.data.total,
        error: null,
        page,
      });
    }
    case types.FETCH_SINGLE_EVENT_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: null,
        message: "",
      };
    }

    case types.FETCH_SINGLE_EVENT_FAILURE: {
      return {
        ...state,
        isFetching: true,
        error: null,
        message: "",
      };
    }
    case types.FETCH_SINGLE_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        singleEvent: data,
      });
    }

    case types.FETCH_GOING_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        goingData: data.data.data,
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_GOING_EVENT_MORE: {
      return Object.assign({}, state, {
        isFetching: false,
        goingData: state.goingData.concat(data.data.data),
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_PENDING_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        pendingData: data.data.data,
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_PENDING_EVENT_MORE: {
      return Object.assign({}, state, {
        isFetching: false,
        pendingData: state.pendingData.concat(data.data.data),
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_CALENDAR_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        calendarData: data.data,
        total: data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_CALENDAR_EVENT_MORE: {
      return Object.assign({}, state, {
        isFetching: false,
        calendarData: state.calendarData.concat(data.data),
        total: data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_MY_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        myEventData: data.data.data,
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_MY_EVENT_MORE: {
      return Object.assign({}, state, {
        isFetching: false,
        myEventData: state.myEventData.concat(data.data.data),
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_NEAR_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        nearEventData: data.data.data,
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_NEAR_EVENT_MORE: {
      return Object.assign({}, state, {
        isFetching: false,
        nearEventData: state.nearEventData.concat(data.data.data),
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_INTERESTED_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        interestedEventData: data.data.data,
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.FETCH_INTERESTED_EVENT_More: {
      return Object.assign({}, state, {
        isFetching: false,
        interestedEventData: state.interestedEventData.concat(data.data.data),
        total: data.data.total,
        error: null,
        page,
      });
    }

    case types.SUBMIT_EVENT_PENDING: {
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: "",
      };
    }

    case types.SUBMIT_EVENT_FAILURE: {
      return {
        ...state,
        isProcessing: false,
        error: error,
        message: message,
      };
    }

    case types.SUBMIT_EVENT_SUCCESS: {
      let newArr = [...state.data];
      let newMyArr = [...state.myEventData];

      let Index = newArr.findIndex((event) => event.id === data.data.id);
      let myIndex = newMyArr.findIndex((event) => event.id === data.data.id);

      if (data.data.privacy === "public") {
        if (Index <= -1) {
          newArr.unshift(data.data);
        }
      } else if (data.data.privacy === "private") {
        if (myIndex <= -1) {
          newMyArr.unshift(data.data);
        }
      }

      return Object.assign({}, state, {
        isProcessing: false,
        message: message,
        data: newArr,
        myEventData: newMyArr,
        form: {},
        selectedAddress: "",
        error: null,
      });
    }

    case types.SUBMIT_RESPONSE_PENDING: {
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: "",
      };
    }

    case types.SUBMIT_RESPONSE_FAILURE: {
      return {
        ...state,
        isProcessing: false,
        error: error,
        message: message,
      };
    }

    case types.SUBMIT_RESPONSE_SUCCESS: {
      let dataIndex = state.data.findIndex((d) => d.id === data.id);
      let nearIndex = state.nearEventData.findIndex((n) => n.id === data.id);
      let goingIndex = state.goingData.findIndex((g) => g.id === data.id);
      let pendingIndex = state.pendingData.findIndex((p) => p.id === data.id);

      let newDataArray = [...state.data];
      let nearArray = [...state.nearEventData];
      let goingArray = [...state.goingData];
      let pendingArray = [...state.pendingData];

      if (dataIndex > -1) {
        newDataArray[dataIndex].is_interested = data.is_interested;
        newDataArray[dataIndex].is_going = data.is_going;
        newDataArray[dataIndex].is_rejected = data.is_rejected;
      }

      if (nearIndex > -1) {
        nearArray[nearIndex].is_interested = data.is_interested;
        nearArray[nearIndex].is_going = data.is_going;
        nearArray[nearIndex].is_rejected = data.is_rejected;
      }

      if (goingIndex > -1) {
        goingArray[goingIndex].is_interested = data.is_interested;
        goingArray[goingIndex].is_going = data.is_going;
        goingArray[goingIndex].is_rejected = data.is_rejected;
      }

      if (pendingIndex > -1) {
        pendingArray[pendingIndex].is_interested = data.is_interested;
        pendingArray[pendingIndex].is_going = data.is_going;
        pendingArray[pendingIndex].is_rejected = data.is_rejected;
      }

      return Object.assign({}, state, {
        data: newDataArray,
        nearEventData: nearArray,
        goingData: goingArray,
        pendingData: pendingArray,
      });
    }

    case types.REMOVE_EVENT: {
      let Index = state.data.findIndex((p) => p.id === data);
      let pendingIndex = state.pendingData.findIndex((p) => p.id === data);
      let goingIndex = state.goingData.findIndex((p) => p.id === data);
      let interestedIndex = state.interestedEventData.findIndex(
        (p) => p.id === data
      );
      let myIndex = state.myEventData.findIndex((p) => p.id === data);
      let nearIndex = state.nearEventData.findIndex((p) => p.id === data);

      let allArray = [...state.data];
      let goingArray = [...state.goingData];
      let pendingArray = [...state.pendingData];
      let interestedArray = [...state.interestedEventData];
      let myEventArray = [...state.myEventData];
      let nearEventArray = [...state.nearEventData];

      let allDeletedArray = [];
      let goingDeletedArray = [];
      let pendingDeletedArray = [];
      let interestedDeletedArray = [];
      let myDeletedArray = [];
      let nearDeletedArray = [];

      if (Index > -1) {
        allDeletedArray = allArray.filter((e) => e.id !== data);
      } else {
        allDeletedArray = allArray;
      }
      if (goingIndex > -1) {
        goingDeletedArray = goingArray.filter((e) => e.id !== data);
      } else {
        goingDeletedArray = goingArray;
      }
      if (pendingIndex > -1) {
        pendingDeletedArray = pendingArray.filter((e) => e.id !== data);
      } else {
        pendingDeletedArray = pendingArray;
      }
      if (interestedIndex > -1) {
        interestedDeletedArray = interestedArray.filter((e) => e.id !== data);
      } else {
        interestedDeletedArray = interestedArray;
      }
      if (myIndex > -1) {
        myDeletedArray = myEventArray.filter((e) => e.id !== data);
      } else {
        myDeletedArray = myEventArray;
      }
      if (nearIndex > -1) {
        nearDeletedArray = nearEventArray.filter((e) => e.id !== data);
      } else {
        nearDeletedArray = nearEventArray;
      }

      return {
        ...state,
        goingData: goingDeletedArray,
        pendingData: pendingDeletedArray,
        interestedEventData: interestedDeletedArray,
        myEventData: myDeletedArray,
        data: allDeletedArray,
        nearEventData: nearDeletedArray,
      };
    }

    case types.FETCH_LIVE_EVENT_PENDING: {
      return {
        ...state,
        isLiveFetching: true,
        error: null,
        message: "",
      };
    }

    case types.FETCH_LIVE_EVENT_FAILURE: {
      return {
        ...state,
        isLiveFetching: false,
        error: null,
        message: "",
      };
    }

    case types.FETCH_LIVE_EVENT_SUCCESS: {
      return {
        ...state,
        isLiveFetching: false,
        live: {
          ...state.live,
          [id]: {
            ... typeof state.live[id] !=="undefined"?state.live[id]:{},
            data: data.data.data,
            is_more: data.data.to < data.data.total ? true : false,
          },
        },
      };
    }

    case types.FETCH_LIVE_EVENT_MORE: {
      return {
        ...state,
        isLiveFetching: false,
        live: {
          ...state.live,
          [id]: {
            ...state.live[id],
            data: state.live[id].data.concat(data.data.data),
            is_more: data.data.to===null?false:data.data.to < data.data.total ? true : false,
          },
        },
      };
    }

    case types.ADD_EVENT_FAVOURITE: {
      let newArray = { ...state.live };
      let newPosts = newArray[data].data;
      let index = newPosts.findIndex((item) => item.id == id);
      let postArry = [];
      if (index > -1) {
        postArry = newPosts[index];
        postArry.is_favourite = !postArry.is_favourite;
        newPosts[index] = postArry;
      }
      newArray[data].data = newPosts;
      return Object.assign({}, state, {
        booking: {},
        live: newArray,
        random: Math.random(),
      });
    }
    case types.ADD_EVENT_BOOKING: {
      return {
        ...state,
        booking: data,
      };
    }

    case types.SUBMIT_EVENT_BOOKING: {
      let newEvent = { ...state.singleEvent };
      if (newEvent.id === data.id) {
        newEvent.is_booked = true;
      }

      return Object.assign({}, state, {
        booking: {},
        singleEvent: newEvent,
      });
    }
      
        case types.FETCH_EVENT_BOOKING_PENDING: {
      return {
        ...state,
        isFetchBooking: true,
        error: null,
        message: "",
      };
    }

    case types.FETCH_EVENT_BOOKING_FAILURE: {
      return {
        ...state,
        isFetchBooking: false,
        error: null,
        message: "",
      };
    }

    case types.FETCH_EVENT_BOOKING_SUCCESS: {
      return Object.assign({}, state, {
        isFetchBooking: false,
        eventBooking: data.data,
        eventBookingTotal: data.total,
      });
    }

    case types.FETCH_EVENT_BOOKING_MORE: {
      return Object.assign({}, state, {
        isFetchBooking: false,
        eventBooking: state.data.concat(data.data),
        eventBookingTotal: data.total,
      });
    }
      
    case types.ACCEPT_BOOKING_EVENT: {
      let newArray = [...state.eventBooking];
      let postArry = [];
      const postIndex = newArray.findIndex((post) => post.id === id);
      if (postIndex > -1) {
        postArry = newArray[postIndex];
        postArry.is_confirmed = true;
        newArray[postIndex] = postArry;
      }
      return Object.assign({}, state, {
        eventBooking: newArray,
      });
    }
      
    case types.CANCEL_BOOKING_EVENT: {
      let newArray = [...state.eventBooking];
      let postArry = [];
      postArry = newArray.filter((post) => post.id !== id);
      return Object.assign({}, state, {
        eventBooking: postArry,
        eventBookingTotal:state.eventBookingTotal-1
      });
    }
    
    case types.CURRENT_LAT_LNG_LOCATION: {
      return {
        ...state,
        currentLocation:data
      }
    }

    default: {
      return state;
    }
  }
};