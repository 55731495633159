import React, { Component } from "react";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { Image } from "react-bootstrap";
import BizHours from "assets/images/BizHours.webp";
import LogoPhotos from "assets/images/LogoPhotos.webp";
import { HiOutlineArrowLeft } from "react-icons/hi";

class CompanyDetailleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business_type: "Bar",
    };
  }
  render() {
    const { id } = this.props;
    return (
      <div className="fixed flex flex-col p-3 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center">
              <Link
                to="/my_companies"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link
                  to="/my_companies"
                  className="text-sm darkGray hover:underline">
                  My Companies
                </Link>
                <h2 className="text-2xl">My Company</h2>
              </div>
            </div>
          </div>

          <div className="my-3 divider" />
          <div>
            <Link
              to={{
                pathname: `/bar/${id}/logo`,
                state: { id: id },
              }}
              className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200">
              <Image
                src={LogoPhotos}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>Logo & Banner</p>
            </Link>
            <Link
              to={{
                pathname: `/bar/${id}/schedule`,
                state: { id: id },
              }}
              className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200 bg--lightPrimary">
              <Image
                src={BizHours}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>Schedule</p>
            </Link>

            {/* <Link
              to="/post_job/getting_started"
              className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200"
            >
              <Image
                src={PostJob}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>Post a Job</p>
            </Link> */}
            {/* <Link
              to="/jobs"
              className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200"
            >
              <Image
                src={MyJobsPosting}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>My Job Postings</p>
            </Link> */}
            {/* <Link to='/' className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200">
              <Image
                src={MyJobsPosting}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>Advertising</p>
            </Link> */}
            {/* <Link to='/' className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200">
              <Image
                src={my_employees}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>My Employees</p>
            </Link> */}
            {/* <Link
               to={`/my_company_details/my_keywords/${id}`}
              className="flex items-center p-2 mb-2 rounded-lg hover:bg-gray-200">
              <Image
                src={my_keywords}
                className="flex items-center justify-center object-cover w-10 h-10 mr-3 rounded-lg"
              />
              <p>My Keywords</p>
            </Link> */}
          </div>
        </div>
        <div className="my-3 divider" />
        <Link
          to={{
            pathname: `/bars/bar-edit/${id}`,
            state: { id: id },
          }}
          className="flex items-center justify-center w-full mb-3 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
          <FiEdit color={colors.primary} size={15} />
          <p className="ml-2 primary">Edit Company</p>
        </Link>
      </div>
    );
  }
}

export default CompanyDetailleftaside;
