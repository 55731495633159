import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import { VscCalendar } from "react-icons/vsc";
import Avatar from "@material-ui/core/Avatar";
import { OptimizeImage } from "helpers";
import { event_image } from "assets";
import moment from "moment";
import { IoLockClosed, IoLockOpen } from "react-icons/io5";
import { BsDash } from "react-icons/bs";
import { Eventsbooking } from "components";

class Eventdetailheader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.bookingRef = React.createRef();
  }

  openBooking = () => {
    const { eventDetail } = this.props;
    this.bookingRef.current.open(eventDetail);
  };
  render() {
    const { eventDetail } = this.props;
    let url = "";

    if (eventDetail.photos) {
      if (eventDetail.photos.length > 0) {
        url = OptimizeImage(eventDetail.photos[0].attachment_url);
      }
    }

    if (
      eventDetail.band &&
      eventDetail.band.attachments &&
      eventDetail.band.attachments.length > 0
    ) {
      url = OptimizeImage(eventDetail.band.attachments[0].attachment_url);
    }
    return (
      <div className="bg-white ">
        <div className="m-auto container--small xs:px-4 sm:px-4 ">
          <div>
            <div className="overflow-hidden rounded-b-lg">
              <div className="relative rounded-b-lg shadow-sm h-96">
                <div className="absolute w-full h-full overlay:bg--gray-400" />
                <Avatar
                  variant="rounded"
                  src={url}
                  alt="Avatar"
                  className="object-cover object-center important:w-full important:h-full">
                  <Image
                    src={event_image}
                    className="object-cover object-center w-full h-full"
                  />
                </Avatar>
              </div>
            </div>
            <div className="py-4 xl:px-8 md:px-5 sm:px-4 xs:px-4">
              <div className="flex items-center">
                <h1 className="text-2xl Medium">{eventDetail.name}</h1>
                {eventDetail.band && (
                  <>
                    <BsDash className="mx-2 darkGray" />
                    <p className="darkGray fs-12">Live Music Event</p>
                    <p className="ml-2 cursor-pointer primary">
                      {eventDetail.band.band_name}
                    </p>
                  </>
                )}
              </div>
              <div className="flex mt-1 mb-3">
                {eventDetail.privacy === "public" ? (
                  <div className="flex items-center">
                    <div className="mr-2">
                      <IoLockOpen size={15} color={colors.darkGray} />
                    </div>
                    <p className="darkGray fs-13"> {eventDetail.privacy}</p>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="mr-2">
                      <IoLockClosed size={15} color={colors.darkGray} />
                    </div>
                    <p className="darkGray fs-13"> {eventDetail.privacy}</p>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center mt-2">
                    <div className="flex items-center mt-1">
                      <VscCalendar
                        className="mr-3"
                        size={15}
                        color={colors.darkGray}
                      />
                      <p className="darkGray fs-14">
                        Start:{" "}
                        {moment(eventDetail.start_date).format("YYYY-MM-DD")}
                      </p>
                      {!eventDetail.all_day && (
                        <p className="ml-2 darkGray fs-14">
                          at {moment(eventDetail.start_date).format("HH:MM:SS")}
                          {/* {moment(eventDetail.start_date).format('YYYY-MM-DD HH:MM:SS');} */}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center mt-2">
                    <div className="flex items-center mt-1 ">
                      <VscCalendar
                        className="mr-3"
                        size={15}
                        color={colors.darkGray}
                      />
                      <p className="darkGray fs-14">
                        End: {moment(eventDetail.end_date).format("YYYY-MM-DD")}
                      </p>
                      {!eventDetail.all_day && (
                        <p className="ml-2 darkGray fs-14">
                          at {moment(eventDetail.end_date).format("HH:MM:SS")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {eventDetail.band &&
                  eventDetail.user_id !== this.props.user.id && (
                    <>
                      {eventDetail.is_booked ? (
                        <Button
                          disabled={true}
                          className="flex items-center justify-center transition duration-300 transform border-0 shadow-none bg--gray hover:bg--gray focus:bg--gray white h-11 w-36 rounded--lg hover:scale-90">
                          Booked
                        </Button>
                      ) : (
                        <Button
                          onClick={this.openBooking}
                          className="flex items-center justify-center transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary white h-11 w-36 rounded--lg hover:scale-90">
                          Book Now
                        </Button>
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
          <Eventsbooking ref={this.bookingRef} />
        </div>
      </div>
    );
  }
}
export default Eventdetailheader;
