import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
class Map extends Component {
  render() {
    const { coordinates } = this.props;
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: coordinates.lat, lng: coordinates.lng },
        map,
      });
      return marker;
    };
    return (
      <>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCe4WVS0w0eJ9cYaNn93RSFaozztIT76B4" }}
          //defaultCenter={coordinates}
          center={coordinates}
          defaultZoom={14}
          margin={[50, 50, 50, 50]}
          options={""}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) =>
            renderMarkers(map, maps)
          }></GoogleMapReact>
      </>
    );
  }
}
export default Map;
