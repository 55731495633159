import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Social from "assets/images/weeee/social.png";
import Friends from "assets/images/weeee/friends.png";
import Chats from "assets/images/weeee/chats.png";
import Flirts from "assets/images/weeee/flirts.png";
import Bars from "assets/images/weeee/bars.png";
import my_bars from "assets/images/weeee/my_bars.png";
import groups from "assets/images/weeee/groups.png";
import events from "assets/images/weeee/events.png";
import calendar from "assets/images/weeee/calendar.png";
import live_music from "assets/images/weeee/live_music.png";
import my_band from "assets/images/weeee/my_band.png";
import restaurant from "assets/images/weeee/restaurant.png";
// import MyFriends from "assets/images/new/friends.webp";
// import groups from "assets/images/new/groups.webp";
// import SocialFeed from "assets/images/new/social.webp";
// import Mymessages from "assets/images/new/messages.webp";
// import Flirt from "assets/images/new/flirts.webp";
// import Bars from "assets/images/new/bars.webp";
// import my_bar from "assets/images/new/my-bar.webp";
// import Events from "assets/images/new/events.webp";
// import Calendar from "assets/images/new/calendar.webp";
// import Band from "assets/images/new/my-band.webp";
// import LiveMusic from "assets/images/new/live-music.webp";
import { connect } from "react-redux";

class Gridmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { user } = this.props.User;
    const { menu, unreadCount } = this.props;
    return (
      <div className="pr-0">
        <div className="grid grid-cols-2 gap-3 ">
          <div className="overflow-hidden shadow-sm rounded-xl">
            <Link to="/" className="flex flex-col items-center">
              <Image src={Social} className="object-cover w-full h-24" />
              {/* <p className="py-1">Social Feed</p> */}
            </Link>
          </div>
          <div className="overflow-hidden shadow-sm rounded-xl">
            <Link to="/friends" className="flex flex-col items-center">
              <Image src={Friends} className="object-cover w-full h-24" />
              {/* <p className="py-1">Friends</p> */}
            </Link>
          </div>
          {menu.comms && (
            <div className="relative overflow-hidden bg-white shadow-sm rounded-xl">
              <Link to="/messenger" className="flex flex-col items-center">
                <Image src={Chats} className="object-cover w-full h-24" />
                {unreadCount > 0 && (
                  <div className="absolute flex items-center justify-center w-5 h-5 rounded-full bg--danger right-3 top-2">
                    <p className="fs-10 white">{unreadCount}</p>
                  </div>
                )}
              </Link>
            </div>
          )}
          {menu.bars && (
            <div className="overflow-hidden bg-white shadow-sm rounded-xl">
              <Link to="/bars" className="flex flex-col items-center">
                <Image src={Bars} className="object-cover w-full h-24" />
                {/* <p className="py-1">Bars</p> */}
              </Link>
            </div>
          )}
          {menu.restaurant && (
            <div className="overflow-hidden bg-white shadow-sm rounded-xl">
              <Link to="/restaurants" className="flex flex-col items-center">
                <Image src={restaurant} className="object-cover w-full h-24" />
                {/* <p className="py-1">Bars</p> */}
              </Link>
            </div>
          )}
          {menu.flirt && (
            <div className="overflow-hidden bg-white shadow-sm rounded-xl">
              <Link
                to="/flirts/available-flirts"
                className="flex flex-col items-center">
                <Image src={Flirts} className="object-cover w-full h-24" />
                {/* <p className="py-1">Flirts</p> */}
              </Link>
            </div>
          )}
          <div className="overflow-hidden bg-white shadow-sm rounded-xl">
            <Link to="/live-music/near" className="flex flex-col items-center">
              <Image src={live_music} className="object-cover w-full h-24" />
            </Link>
          </div>
          {menu.groups && (
            <div className="overflow-hidden bg-white shadow-sm rounded-xl">
              <Link to="/groups" className="flex flex-col items-center">
                <Image src={groups} className="object-cover w-full h-24" />
                {/* <p className="py-1">Groups</p> */}
              </Link>
            </div>
          )}

          <div className="overflow-hidden bg-white shadow-sm rounded-xl">
            <Link to="/events/nearme" className="flex flex-col items-center">
              <Image src={events} className="object-cover w-full h-24" />
              {/* <p className="py-1">Events</p> */}
            </Link>
          </div>
          <div className="overflow-hidden bg-white shadow-sm rounded-xl">
            <Link to="/events/calendar" className="flex flex-col items-center">
              <Image src={calendar} className="object-cover w-full h-24" />
            </Link>
          </div>

          <div className="overflow-hidden bg-white shadow-sm rounded-xl">
            <Link to="/my_companies" className="flex flex-col items-center">
              <Image src={my_bars} className="object-cover w-full h-24" />
              {/* <p className="py-1">Bars</p> */}
            </Link>
          </div>

          <div className="overflow-hidden bg-white shadow-sm rounded-xl">
            <Link to="/bands/my-bands" className="flex flex-col items-center">
              <Image src={my_band} className="object-cover w-full h-24" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ User, unreadCount }) => ({ User, unreadCount });
export default connect(mapStateToProps, null)(Gridmenu);
