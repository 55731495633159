import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import colors from "config/color/color.js";

class Friendsnavigation extends Component {
  render() {
    return (
      <div className="h-16 z-10">
        <div className="py-1 flex items-center justify-between relative">
          <div className="flex items-center justify-between w-full flex-row navbar-nav transform transition duration-100">
            <NavLink
              to="/profile/all_friends"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              All Friends
            </NavLink>
            <NavLink
              to="/profile/friends_with_upcoming_birthdays"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Birthdays
            </NavLink>
            <NavLink
              to="/profile/friends_family_and_relatives"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Family & Relatives
            </NavLink>
            <NavLink
              to="/profile/friends_business_associate"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Business Associate
            </NavLink>
            <NavLink
              to="/profile/friends_coworkers"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Co-Workers
            </NavLink>
            <NavLink
              to="/profile/friends_customers"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Customers
            </NavLink>
            <NavLink
              to="/profile/friends_followers"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Followers
            </NavLink>
            <NavLink
              to="/profile/friends_block"
              activeClassName="active--link"
              activeStyle={{ color: colors.primary }}
              className="px-2 flex justify-center black hover:bg-gray-200 rounded-lg py-3 relative"
            >
              Block
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Friendsnavigation;
