import React, { useRef, useState } from "react";
import { OrdersAside } from "Modules";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Buttonused } from "components";
import Item from "./Item";
import OrderView from "./OrderView";
import { BiLoaderAlt } from "react-icons/bi";
import { IoRefreshOutline } from "react-icons/io5";
import moment from "moment";

const { actions } = require("redux/BarEnhancement");
const per_page = 50;

export default function Order() {

    const { id, slug } = useParams();

    const modalRef = useRef(null);

    const type = slug;

    const dispatch = useDispatch();

    const [date, setDate] = useState(moment().local().format("YYYY-MM-DD"));

    const orderData = useSelector((state) => Object.assign({}, state.enhancement.order.ids));
    const orderTotal = useSelector((state) => Object.assign({}, state.enhancement.order.total));
    const orderPage = useSelector((state) => Object.assign({}, state.enhancement.order.page));
    const orderIsMore = useSelector((state) => Object.assign({}, state.enhancement.order.isMore));
    const isFetching = useSelector((state) => state.enhancement.order.isFetching);



    const data = (orderData?.[id]?.[type] || []);
    const total = (orderTotal?.[id]?.[type] || 0);
    const page = (orderPage?.[id]?.[type] || 1);
    const is_more = (orderIsMore?.[id]?.[type] || false);

    const fetchOrder = () => {
        actions.fetchOrder(dispatch, { bar_id: id, type: type, page: 1, per_page: per_page, date: date });
    }

    React.useEffect(() => {
        if (page == 1) {
            fetchOrder();
        }


    }, [id, slug]);



    const fetchMore = () => {
        if (is_more) {
            actions.fetchOrder(dispatch, { bar_id: id, type: type, page: page, per_page: per_page, date: date });
        }

    }

    const onView = (order_id) => {
        modalRef?.current?.open(id, order_id);
    }

    const onSetDate = (e) => {
        let dateString = moment(e.target.value).local().format("YYYY-MM-DD");
        setDate(dateString);
        actions.fetchOrder(dispatch, { bar_id: id, type: type, page: 1, per_page: per_page, date: dateString });
    }


    return (
        <div className="flex justify-between">
            <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
                <OrdersAside title="Orders" vip={false} />

            </div>
            <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8 mb-8">
                <div className="flex items-center justify-between mb-4">
                    <h2>{type == "tickets" ? "Tickets" : "Orders"}</h2>
                    <div className="w-20">
                        <Buttonused
                            Icon={isFetching ? <BiLoaderAlt size={21} className="animate-spin text-center " /> : <IoRefreshOutline size={20} />}
                            onClick={fetchOrder}
                            className="bg-white primary hover:text-white focus:text-white flex items-center justify-center hover:bg--primary focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
                        />
                    </div>
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                        <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
                        <input onChange={onSetDate} type="date" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={date} />
                    </div>
                </div>
                <div className="mb-3 bg-white shadow-xl rounded-lg ">

                    <div className="rounded-lg overflow-y-auto" id="scrollbar">
                        <div className="bg-gray-200 text-black capitalize leading-normal grid grid-cols-5 gap-5">
                            <div className="py-4 px-6 text-left text-[13px]">Order#</div>
                            <div className="py-4 px-6 text-center text-[13px]">
                                Customer
                            </div>
                            <div className="py-4 px-6 text-center text-[13px]">
                                Total Amount
                            </div>
                            <div className="py-4 px-6 text-center text-[13px]">
                                {type == "orders" ? "Status" : "Scanned"}
                            </div>
                            <div className="py-4 px-6 text-center text-[13px]">
                                Actions
                            </div>
                        </div>
                        {data && data.length > 0 && data.map((item, key) => (
                            <Item key={key} id={item} bar_id={id} type={type} onView={onView} />
                        ))}
                    </div>
                </div>


                {isFetching &&
                    <div className="flex items-center justify-center">
                        <BiLoaderAlt size={21} className="animate-spin text-center " />
                    </div>
                }
                {is_more &&
                    <Buttonused
                        onClick={fetchMore}
                        Text={"load more"}
                        className="bg--primary grow text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg mb-4"
                    />
                }

            </div>

            <OrderView ref={modalRef} type={type} />

        </div>
    );
}
