import React from "react";
import { Link } from "react-router-dom";

function Buttonused(props) {
  const {
    className,
    Icon,
    Text,
    to,
    onClick,
    style,
    RIcon,
    asButton,
    disabled,
  } = props;
  return (
    <>
      {to && (
        <Link to={to} className={className}>
          {Icon}
          {Text}
        </Link>
      )}
      {onClick && (
        <button
          onClick={onClick}
          style={style}
          className={className}
          disabled={disabled}>
          {Icon}
          {Text}
          {RIcon}
        </button>
      )}
      {asButton && (
        <button style={style} className={className} disabled={disabled}>
          {Icon}
          {Text}
          {RIcon}
        </button>
      )}
    </>
  );
}

export default Buttonused;
