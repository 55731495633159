import loadable from "@loadable/component";
import { Withasideskeleton } from "components";
import { componentDelay } from "helpers";

// componenets
import Category from "./bar/components/Category";
import OrdersAside from "./bar/components/OrdersAside";
import Enabled from "./bar/components/Enabled";
import AddSubCategory from "./bar/components/AddSubCategory";
import TableForm from "./bar/components/TableForm";
import SlideForm from "./bar/components/SlideForm";

// container
import Product from "./bar/container/Product";
import CustomDrink from "./bar/container/CustomDrink";
import Wine from "./bar/container/Wine";
import Beer from "./bar/container/Beer";
import Shots from "./bar/container/Shots";
import Mixers from "./bar/container/Mixers";
import Orders from "./bar/container/Orders";
import ViewOrder from "./bar/container/ViewOrder";
import NightlyTotal from "./bar/container/NightlyTotal";
import SkipLine from "./bar/container/SkipLine";
import InsertTables from "./bar/container/InsertTables";
import EnhancementSetting from "./bar/container/Setting";
import BarTable from "./bar/container/BarTable";
import AdminTool from "./bar/container/AdminTool";
import BarInvite from "./bar/container/BarInvite";


// navigation

const ProductScreen = loadable(
  () => componentDelay(import("./bar/navigation/ProductScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const CustomDrinkScreen = loadable(
  () => componentDelay(import("./bar/navigation/CustomDrink")),
  {
    fallback: <Withasideskeleton />,
  }
);
const WineScreen = loadable(
  () => componentDelay(import("./bar/navigation/Wine")),
  {
    fallback: <Withasideskeleton />,
  }
);
const ChampagneScreen = loadable(
  () => componentDelay(import("./bar/navigation/Champagne")),
  {
    fallback: <Withasideskeleton />,
  }
);
const BeerScreen = loadable(
  () => componentDelay(import("./bar/navigation/Beer")),
  {
    fallback: <Withasideskeleton />,
  }
);
const ShotsScreen = loadable(
  () => componentDelay(import("./bar/navigation/Shots")),
  {
    fallback: <Withasideskeleton />,
  }
);
const MixersScreen = loadable(
  () => componentDelay(import("./bar/navigation/Mixers")),
  {
    fallback: <Withasideskeleton />,
  }
);
const OrdersScreen = loadable(
  () => componentDelay(import("./bar/navigation/Orders")),
  {
    fallback: <Withasideskeleton />,
  }
);
const ViewOrderScreen = loadable(
  () => componentDelay(import("./bar/navigation/ViewOrder")),
  {
    fallback: <Withasideskeleton />,
  }
);
const NightlyTotalScreen = loadable(
  () => componentDelay(import("./bar/navigation/NightlyTotal")),
  {
    fallback: <Withasideskeleton />,
  }
);

const SkipLineScreen = loadable(
  () => componentDelay(import("./bar/navigation/SkipLine")),
  {
    fallback: <Withasideskeleton />,
  }
);

const InsertTablesScreen = loadable(
  () => componentDelay(import("./bar/navigation/InsertTables")),
  {
    fallback: <Withasideskeleton />,
  }
);

const EnhancementSettingScreen = loadable(
  () => componentDelay(import("./bar/navigation/SettingScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const BarTableScreen = loadable(
  () => componentDelay(import("./bar/navigation/BarTableScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const AdminToolScreen = loadable(
  () => componentDelay(import("./bar/navigation/AdminToolScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const BarInviteScreen = loadable(
  () => componentDelay(import("./bar/navigation/BarInviteScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

export {
  // componenets
  Category,
  OrdersAside,
  Enabled,
  AddSubCategory,
  TableForm,
  SlideForm,

  // container
  Product,
  CustomDrink,
  Wine,
  Beer,
  Shots,
  Mixers,
  Orders,
  ViewOrder,
  NightlyTotal,
  SkipLine,
  InsertTables,
  EnhancementSetting,
  BarTable,
  AdminTool,
  BarInvite,
  // navigation

  ProductScreen,
  CustomDrinkScreen,
  WineScreen,
  ChampagneScreen,
  BeerScreen,
  ShotsScreen,
  MixersScreen,
  OrdersScreen,
  ViewOrderScreen,
  NightlyTotalScreen,
  SkipLineScreen,
  InsertTablesScreen,
  EnhancementSettingScreen,
  BarTableScreen,
  AdminToolScreen,
  BarInviteScreen,
};
