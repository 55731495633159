import * as React from "react";
import { OptimizeImage } from "helpers";
import { Avatar } from "@material-ui/core";

function Thumbnailsimage(props) {
  if (props.item.gender === "female") {
    return (
      <div>
        <Avatar
          variant="square"
          src={OptimizeImage(props.item.profile_banner)}
          style={props.imageSize}>
          <Avatar
            variant="square"
            alt={props.item.first_name}
            style={props.imageSize}
          />
        </Avatar>
      </div>
    );
  } else {
    return (
      <div>
        <Avatar
          variant="square"
          src={OptimizeImage(props.item.profile_banner)}
          style={props.imageSize}>
          <Avatar
            variant="square"
            alt={props.item.first_name}
            style={props.imageSize}></Avatar>
        </Avatar>
      </div>
    );
  }
}
export default Thumbnailsimage;
