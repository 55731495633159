import React, { Component } from "react";
import { Aboutnavigation, DeletePopup } from "components";
import { HiHome, HiOutlineMail } from "react-icons/hi";
import moment from "moment";
import { BiTime } from "react-icons/bi";
import { RiUserFollowFill } from "react-icons/ri";
import { ImUser } from "react-icons/im";
import { Capitalize } from "helpers";
import { MdLanguage } from "react-icons/md";
import { FaBirthdayCake, FaHeart } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = { deleteWorkplaces: false };
    this.deleteWorkplaceRef = React.createRef();
  }

  deleteWorkplacesPopupOpen = () => {
    this.deleteWorkplaceRef.current.deleteWorkplacesPopupOpen();
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <div className="bg-white shadow-sm rounded-lg">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-0">
            <div className="border-r p-3">
              <Aboutnavigation data={data} />
            </div>
            <div className="col-span-2 p-5">
              <div className="mt-4">
                <div className="flex flex-col gap-5">
                  {!!data.city && data.city != "null" && (
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <HiHome size={20} className="darkGray" />
                      </div>
                      <p className="darkGray">
                        Lives in{" "}
                        <p className="black hover:underline">
                          {data.city}, {data.cca2}
                        </p>
                      </p>
                    </div>
                  )}

                  <div className="flex items-center">
                    <div className="mr-3 ">
                      <BiTime size={20} className="darkGray" />
                    </div>
                    <p className="darkGray">
                      Joined{" "}
                      {moment.utc(data.created_at).local().format("MMMM Y")}
                    </p>
                  </div>

                  <div className="flex items-center">
                    <div className="mr-3 ">
                      <RiUserFollowFill size={20} className="darkGray" />
                    </div>
                    <p className="darkGray">
                      Followed by {data.total_followers} people
                    </p>
                  </div>

                  <div className="flex items-center">
                    <div className="flex">
                      <div className="mr-3 ">
                        <ImUser size={20} className="darkGray" />
                      </div>
                      <div>
                        <p className="darkGray">{Capitalize(data.gender)}</p>
                        <p className="darkGray Light text-xs">Gender</p>
                      </div>
                    </div>
                  </div>

                  {!!data.languages && data.languages != "null" && (
                    <div className="flex items-center">
                      <div className="flex">
                        <div className="mr-3 ">
                          <MdLanguage size={20} className="darkGray" />
                        </div>
                        <div>
                          <p className="darkGray">{data.languages}</p>
                          <p className="darkGray Light text-xs">Language</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    <div className="flex">
                      <div className="mr-3 ">
                        <FaBirthdayCake size={20} className="darkGray" />
                      </div>
                      <div>
                        <p className="darkGray">
                          {data.date_of_birth
                            ? moment
                                .utc(data.date_of_birth)
                                .local()
                                .format("MMMM DD, Y")
                            : "Change your birthday"}
                        </p>
                        <p className="darkGray Light text-xs">Birthday</p>
                      </div>
                    </div>
                  </div>

                  {!!data.phone_number && data.phone_number != "null" && (
                    <div className="flex items-center">
                      <div className="flex">
                        <div className="mr-3 ">
                          <IoCallSharp size={20} className="darkGray" />
                        </div>
                        <div>
                          <p className="darkGray">{data.phone_number}</p>
                          <p className="darkGray Light text-xs">Mobile</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    <div className="flex">
                      <div className="mr-3 ">
                        <HiOutlineMail size={20} className="darkGray" />
                      </div>
                      <div>
                        <p className="darkGray">{data.email}</p>
                        <p className="darkGray Light text-xs">Email</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="mr-3 ">
                      <FaHeart size={20} className="darkGray" />
                    </div>
                    <p className="darkGray">
                      {Capitalize(data.marital_status)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeletePopup ref={this.deleteWorkplaceRef} {...this.props} />
      </div>
    );
  }
}

export default Overview;
