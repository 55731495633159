import React, { Component } from "react";
import colors from "config/color/color.js";
import { NavLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { FaUserFriends, FaUsers } from "react-icons/fa";
import { HiOutlineArrowLeft } from "react-icons/hi";

class Resultsaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="FriendsLeftAside fixed bg-white shadow-md p-6">
        <div className="flex items-center">
          <NavLink
            to="/"
            className="text-sm darkGray hover:bg-gray-200 rounded-full flex items-center justify-center -ml-3 mr-3 h-10 w-10"
          >
            <HiOutlineArrowLeft size={18} style={{ color: colors.darkGray }} />
          </NavLink>
          <div>
            <NavLink to="/" className="text-sm darkGray hover:underline active">
              Main Menu
            </NavLink>
            <h2 className="text-2xl Medium">Search Results</h2>
          </div>
        </div>
        <div className="border-t my-2" />
        <div className="mt-3 -mx-2">
          <NavLink
            exact
            to="/search/people"
            className="flex black items-center hover:bg-gray-200 p-2 rounded-lg mt-2 justify-between"
            activeClassName="bg--lightPrimary"
            activeStyle={{ color: colors.primary }}
          >
            <div className="flex items-center">
              <div className="h-10 w-10 rounded-full bg--primary items-center justify-center flex mr-3">
                <FaUserFriends size={20} style={{ color: colors.white }} />
              </div>
              People
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/search/groups"
            className="flex black items-center hover:bg-gray-200 p-2 rounded-lg mt-2 justify-between"
            activeClassName="bg--lightPrimary"
            activeStyle={{ color: colors.primary }}
          >
            <div className="flex items-center">
              <div className="h-10 w-10 rounded-full bg--primary items-center justify-center flex mr-3">
                <FaUsers size={20} style={{ color: colors.white }} />
              </div>
              Groups
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Resultsaside;
