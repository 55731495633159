import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import colors from "config/color/color.js";
import { BsDash, BsThreeDots } from "react-icons/bs";
import Restaurant from "assets/images/Restaurant.webp";
import { Dropdown } from "semantic-ui-react";
import { IoTrashOutline } from "react-icons/io5";
import { OptimizeImage } from "helpers";
import { connect } from "react-redux";

class Companiescard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  removeCompany = (companyId) => {
    this.props.removeCompany(companyId);
  };

  render() {
    const { item } = this.props;
    let banner =
      "https://corvisio.com/wp-content/uploads/2020/03/Media-300x200.png";
    if (this.props.item.type === "bar") {
      banner =
        "https://images.all-free-download.com/images/graphiclarge/bar_wines_514430.jpg";
    }

    let bannerObject = Object.assign({}, this.props.item.banner);
    if (bannerObject.attachment_url) {
      banner = OptimizeImage(bannerObject.attachment_url);
    }
    return (
      <div className="block overflow-hidden bg-white shadow-md rounded-xl">
        <Link to={`/bar/${item.id}/logo`}>
          <div className="relative">
            <Image
              src={banner}
              className="object-cover w-full rounded-t-xl h-60"
            />
            <div className="absolute top-0 left-0 z-10">
              <p className="flex items-center justify-center px-4 py-1 rounded-r-lg bg--primary white fs-13">
                {typeof this.props.item.business_type !== "undefined"
                  ? this.props.item.business_type.name
                  : "Bar"}
              </p>
            </div>
          </div>
        </Link>
        <div className="px-3 py-2">
          <div className="flex items-center justify-between">
            <Link
              to={{
                pathname: `/my_company_details/logo_banner/${item.id}`,
                state: { item: item },
              }}
              className="flex items-center">
              <Image
                src={Restaurant}
                className="object-cover w-12 h-12 mr-3 rounded-xl"
              />
              <Link
                to={{
                  pathname: `/my_company_details/logo_banner/${item.id}`,
                  state: { item: item },
                }}
                className="flex items-center black Medium">
                {this.props.item.name} <BsDash color={colors.gray} />
                {this.props.item.city}
              </Link>
            </Link>

            <Dropdown
              icon={false}
              defaultUpward={true}
              direction="left"
              pointing="bottom right"
              text={
                <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                  <BsThreeDots
                    size={18}
                    style={{ color: colors.darkGray }}
                    className="m-auto"
                  />
                </div>
              }>
              <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                <div className="p-1">
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center mr-3 bg-gray-200 rounded-full h-9 w-9">
                      <IoTrashOutline
                        size={16}
                        style={{ color: colors.danger }}
                      />
                    </div>
                    {/* onClick={(e) => this.deleteRow(id, e)} */}
                    <div onClick={(e) => this.props.showAlert(item.id, e)}>
                      <p className="danger fs-14">Delete</p>
                      <p className="fs-11 darkGray">
                        Delete company permanently
                      </p>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Bar, User }) => {
  return {
    bars: Bar.items,
    isFetching: Bar.isFetching,
    services: Bar.services,
    total: Bar.total,
    getStarted: Bar.getStarted,
    user: typeof User.user !== "undefined" ? User.user : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CompanyRedux");
  return {
    ...ownProps,
    ...stateProps,
    removeCompany: (data) => {
      actions.deleteCompany(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Companiescard);
