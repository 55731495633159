import React, { useState } from "react";
import { Disclosure } from '@headlessui/react'
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Buttonused } from "components";
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDownCircle } from "react-icons/ai";

export default function Item({ id, bar_id, type, onView }) {

    const [open, setOpen] = useState(false);

    const data = useSelector((state) => Object.assign({}, state.enhancement.order?.data?.[bar_id]?.[type]?.[id]));
    if (!data.id) {
        return null;
    }
    return (
        <div>
            <div className="border-b border-gray-200 hover:bg-gray-100  grid grid-cols-5 gap-5">
                <div className="py-4 px-6 text-left whitespace-nowrap">

                    <div className="flex items-center">
                        <p className="black">{data.order_type == "drinks" ? data.order_number : data.id}</p>
                    </div>
                </div>
                <div className="py-4 px-6 flex justify-center items-center">
                    {data?.user?.first_name} {data?.user?.last_name}
                </div>
                <div className="py-4 px-6 text-center max-w-[200px]">
                    <div className="flex items-center justify-center">
                        <p className="text-gray-600 font-light text-[12px] line-clamp">
                            ${data.total}
                        </p>
                    </div>
                </div>
                <div className="py-4 px-6 text-center">
                    {type == "orders" ?
                        data?.order_status == "pending" ? <span class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-800">Pending</span> : data?.order_status == "complete" ? <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800">Complete</span> : <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">Processing</span> :
                        <span>{data?.scanned}/{data?.passes_quantity}</span>
                    }

                </div>
                <div className="py-4 px-6 text-center">
                    <div className="flex item-center justify-center">
                        <Buttonused
                            onClick={() => onView(data?.id)}
                            Text="View"
                            className="hover:primary Light text-[14px] primary hover:opacity-80 w-full mx-auto"
                        />
                    </div>
                </div>
            </div >
        </div>
    );
}
