import * as React from "react";
import { OptimizeImage } from "helpers";
import { Avatar } from "@material-ui/core";

function Avatarimage(props) {
  if (props.item.gender === "female") {
    return (
      <Avatar
        alt={props.item.first_name}
        src={OptimizeImage(props.item.profile_photo)}
        style={props.imageSize}
        {...props}
      />
    );
  } else {
    return (
      <Avatar
        alt={props.item.first_name}
        src={OptimizeImage(props.item.profile_photo)}
        style={props.imageSize}
        {...props}
      />
    );
  }
}
export default Avatarimage;
