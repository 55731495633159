import React, { Component } from "react";
import colors from "config/color/color.js";
import { FaHeart } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { Select } from "semantic-ui-react";
import { Form, Button } from "react-bootstrap";
import { Selectprivacypopup } from "components";
import { connect } from "react-redux";
class Relationship extends Component {
  constructor(props) {
    super(props);
    this.state = { relationship: false };
    this.selectPrivacyRef = React.createRef();
  }

  render() {
    let { user } = this.props;
    return (
      <div>
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <div className="mr-3 ">
              <FaHeart size={20} className="darkGray" />
            </div>
            <p className="darkGray">
              {user.marital_status ? "Married" : "single"}
            </p>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => this.selectPrivacyRef.current.selectPriavcyOpen()}
              className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer hover:bg-gray-200">
              <GiEarthAsiaOceania
                size={20}
                style={{ color: colors.darkGray }}
              />
            </div>
            <div
              onClick={() => this.setState({ relationship: true })}
              className="flex items-center justify-center w-10 h-10 ml-3 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300">
              <FiEdit2 size={18} />
            </div>
          </div>
        </div>
        {this.state.relationship ? (
          <div className="mt-4">
            <div className="w-full mb-3">
              <Select
                className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                placeholder="Married"
                options={[
                  { value: "21", text: "2021" },
                  { value: "20", text: "2020" },
                  { value: "19", text: "2019" },
                  { value: "18", text: "2018" },
                  { value: "17", text: "2017" },
                  { value: "16", text: "2016" },
                  { value: "15", text: "2015" },
                  { value: "14", text: "2014" },
                  { value: "13", text: "2013" },
                ]}
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Partner"
                className="h-16 border shadow-none rounded--lg"
              />
            </div>
            <div className="flex items-center">
              <p>Since</p>
              <div className="w-32 ml-3">
                <Select
                  className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                  placeholder="Year"
                  options={[
                    { value: "21", text: "2021" },
                    { value: "20", text: "2020" },
                    { value: "19", text: "2019" },
                    { value: "18", text: "2018" },
                    { value: "17", text: "2017" },
                    { value: "16", text: "2016" },
                    { value: "15", text: "2015" },
                    { value: "14", text: "2014" },
                    { value: "13", text: "2013" },
                  ]}
                />
              </div>
              <div className="w-32 ml-3">
                <Select
                  className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                  placeholder="Year"
                  options={[
                    { value: "21", text: "2021" },
                    { value: "20", text: "2020" },
                    { value: "19", text: "2019" },
                    { value: "18", text: "2018" },
                    { value: "17", text: "2017" },
                    { value: "16", text: "2016" },
                    { value: "15", text: "2015" },
                    { value: "14", text: "2014" },
                    { value: "13", text: "2013" },
                  ]}
                />
              </div>
              <div className="w-32 ml-3">
                <Select
                  className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                  placeholder="Year"
                  options={[
                    { value: "21", text: "2021" },
                    { value: "20", text: "2020" },
                    { value: "19", text: "2019" },
                    { value: "18", text: "2018" },
                    { value: "17", text: "2017" },
                    { value: "16", text: "2016" },
                    { value: "15", text: "2015" },
                    { value: "14", text: "2014" },
                    { value: "13", text: "2013" },
                  ]}
                />
              </div>
            </div>
            <div className="my-3 divider" />
            <div className="flex items-center justify-between">
              <Button
                onClick={() =>
                  this.selectPrivacyRef.current.selectPriavcyOpen()
                }
                style={{ color: colors.black }}
                className="flex items-center px-4 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray important:rounded--lg hover:scale-90">
                <GiEarthAsiaOceania
                  size={14}
                  className="mr-2"
                  style={{ color: colors.darkGray }}
                />
                Public
              </Button>
              <div className="flex items-center ">
                <Button
                  onClick={() => this.setState({ relationship: false })}
                  style={{ color: colors.black }}
                  className="flex items-center px-4 mr-3 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray important:rounded--lg hover:scale-90">
                  Cancel
                </Button>
                <Button
                  style={{ color: colors.white }}
                  className="flex items-center px-4 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary important:rounded--lg hover:scale-90">
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : null}{" "}
        <Selectprivacypopup ref={this.selectPrivacyRef} {...this.props} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.User.user,
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(React.memo(Relationship));
