import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiUserX, FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { VscCloseAll } from "react-icons/vsc";
import { DialogActions } from "@material-ui/core";
import { LifeWidget } from "common";
import { Loader } from "semantic-ui-react";
import { Buttonused } from "components";

class Reportpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportPopup: false,
      problem: "",
      block: false,
      unfollow: false,
      loading: false,
    };
  }

  reportPopupOpen = () => {
    this.setState({ reportPopup: true });
  };

  reportPopupClose = () => {
    this.setState({ reportPopup: false });
  };

  selectedProblem = (problem) => {
    this.setState({ problem });
  };

  blockToggle = async () => {
    this.setState({ block: !this.state.block });
  };

  unfollowToggle = async () => {
    const { unfollow } = this.state;
    const { item } = this.props;

    this.setState({ unfollow: !this.state.unfollow });
    if (unfollow) {
      const json = await LifeWidget.followFriend(item.user.id);
    } else {
      const json = await LifeWidget.unfollowFriend(item.user.id);
    }
  };

  sendReport = async () => {
    const { item } = this.props;
    const { problem } = this.state;
    if (problem) {
      this.setState({ loading: true });
      let type = "post";
      let post_id = item.id;
      if (typeof item.title !== "undefined") {
        type = "group";
      }
      if (!item.id) {
        type = "profile";
        post_id = item.user.id;
      }
      let data = { message: problem, post_id: post_id, type: type };
      const json = await LifeWidget.submitReports(data);
      this.setState({ loading: false });
      this.reportPopupClose();
    }
  };

  render() {
    const { item } = this.props;
    const { problem, loading } = this.state;
    return (
      <>
        <Dialog
          open={this.state.reportPopup}
          onClose={this.reportPopupClose}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            {" "}
            <div className="flex items-center justify-center pb-4 border-b">
              <p className="text-2xl Medium">Report</p>
              <div
                onClick={this.reportPopupClose}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div>
              <h3>Please select a problem to continue</h3>
              <p className="darkGray fs-14">
                You can report the post after selecting a problem.
              </p>
              <div className="flex flex-wrap items-center gap-3 my-3">
                <div
                  onClick={() => this.selectedProblem("Nudity")}
                  className="relative overflow-hidden select-bar-services">
                  <label
                    className={`bg-gray-200 ${
                      problem === "Nudity" && "bg--primary important:white-all"
                    } hover:bg--primary hover:color-white-all focus:color-white-all rounded-full px-3 py-1.5 cursor-pointer inline-block`}>
                    <p className="fs-14">Nudity</p>
                  </label>
                </div>
                <div
                  onClick={() => this.selectedProblem("Harassment")}
                  className="relative overflow-hidden select-bar-services">
                  <label
                    htmlFor="Harassment"
                    className={`bg-gray-200 ${
                      problem === "Harassment" &&
                      "bg--primary important:white-all"
                    } hover:bg--primary hover:color-white-all rounded-full px-3 py-1.5 cursor-pointer inline-block`}>
                    <p className="fs-14">Harassment</p>
                  </label>
                </div>
                <div
                  onClick={() => this.selectedProblem("Threat")}
                  className="relative overflow-hidden select-bar-services">
                  <label
                    htmlFor="Threat"
                    className={`bg-gray-200 ${
                      problem === "Threat" && "bg--primary important:white-all"
                    } hover:bg--primary hover:color-white-all rounded-full px-3 py-1.5 cursor-pointer inline-block`}>
                    <p className="fs-14">Threat</p>
                  </label>
                </div>
              </div>
              <div className="my-3 divider" />
              <h5>Please select a problem to continue</h5>
              <div
                onClick={this.blockToggle}
                className="flex items-center justify-between px-2 py-2 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center mr-3 bg-gray-100 rounded-full h-11 w-11">
                    <FiUserX color={colors.black} size={20} />
                  </div>
                  <div>
                    <p className="text-xl">
                      {this.state.block ? "Unblock" : "Block"}{" "}
                      {item.user.first_name}
                    </p>
                    <p className="gray Light fs-14">
                      You won't be able to see or contact each other.
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={this.unfollowToggle}
                className="flex items-center justify-between px-2 py-2 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center mr-3 bg-gray-100 rounded-full h-11 w-11">
                    <VscCloseAll color={colors.black} size={28} />
                  </div>
                  <div>
                    <p className="text-xl">
                      {this.state.unfollow ? "Follow" : "Unfollow"}{" "}
                      {item.user.first_name}
                    </p>
                    <p className="gray Light fs-14">
                      You won't be able to see or contact each other.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="px-5 py-3 border-t">
            {!loading ? (
              <>
                <Buttonused
                  Text="Report"
                  className="w-full px-5 transition duration-300 transform border-0 shadow-none h-11 white bg--primary rounded--lg hover:bg--primary focus:bg--primary black hover:scale-90"
                  onClick={this.sendReport}
                />
              </>
            ) : (
              <div className="flex items-center justify-center flex-grow">
                <Loader active={loading} inline />
              </div>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Reportpopup;
