import React, { Component } from "react";
import colors from "config/color/color.js";
import Slider from "react-slick";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Image } from "react-bootstrap";
import { OptimizeImage } from "helpers";
import { Thumbnailsimage } from "components";
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <div
        className="flex items-center justify-center w-8 h-8 -ml-10 bg-white border rounded-full shadow-sm"
        onClick={onClick}>
        <FiChevronRight color={colors.darkGray} size={22} />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ zIndex: 1 }}>
      <div
        className="flex items-center justify-center w-8 h-8 bg-white border rounded-full shadow-sm ml-7"
        onClick={onClick}>
        <FiChevronLeft color={colors.darkGray} size={22} />
      </div>
    </div>
  );
}
class Flirtthumbnails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item } = this.props;
    const { flirt_setting } = item;
    const photos = Object.assign([], flirt_setting.photos);
    let attaches = [];
    for (var i = 0; i < photos.length; i++) {
      attaches[i] = {
        ...photos[i],
        url: OptimizeImage(photos[i].attachment_url, photos[i].type),
      };
    }
    attaches = attaches.filter((attach) => attach.type === "image");
    return (
      <>
        {attaches.length > 0 ? (
          <Slider
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            speed={500}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}>
            {attaches &&
              attaches.map((item, key) => (
                <div className="text-center bg-gray-300 h-96" key={key}>
                  <Image
                    src={item.url}
                    className="object-fill h-full m-auto rounded-b-xl"
                  />
                </div>
              ))}
          </Slider>
        ) : (
          <div className="flex items-center justify-center text-center bg-gray-300 h-96">
            <Thumbnailsimage
              item={item}
              imageSize={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default Flirtthumbnails;
