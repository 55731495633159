import { LifeWidget } from "common";

const sortable = (data) => {
    let array = [];
    const pendingArray = Object.values(data).filter(i => i.order_status == "pending");
    const processingArray = Object.values(data).filter(i => i.order_status == "processing");
    const completeArray = Object.values(data).filter(i => i.order_status == "complete");
    pendingArray.map((item) => {
        array.push(item.id);
    });
    processingArray.map((item) => {
        array.push(item.id);
    });
    completeArray.map((item) => {
        array.push(item.id);
    });
    return array;
}

const types = {
    FETCH_ENHANCEMENT_SCHEDULE_PENDING: "FETCH_ENHANCEMENT_SCHEDULE_PENDING",
    FETCH_ENHANCEMENT_SCHEDULE_SUCCESS: "FETCH_ENHANCEMENT_SCHEDULE_SUCCESS",
    FETCH_ENHANCEMENT_SCHEDULE_FAILURE: "FETCH_ENHANCEMENT_SCHEDULE_FAILURE",

    ADD_ENHANCEMENT_SCHEDULE_FORM: "ADD_ENHANCEMENT_SCHEDULE_FORM",

    ADD_ENHANCEMENT_LEVEL_FORM: "ADD_ENHANCEMENT_LEVEL_FORM",

    SUBMIT_ENHANCEMENT_LEVEL_PENDING: "SUBMIT_ENHANCEMENT_LEVEL_PENDING",
    SUBMIT_ENHANCEMENT_LEVEL_SUCCESS: "SUBMIT_ENHANCEMENT_LEVEL_SUCCESS",
    SUBMIT_ENHANCEMENT_LEVEL_FAILURE: "SUBMIT_ENHANCEMENT_LEVEL_FAILURE",

    ADD_ENHANCEMENT_LEVEL_BLOCK: "ADD_ENHANCEMENT_LEVEL_BLOCK",

    SUBMIT_ENHANCEMENT_LEVEL_BLOCK_PENDING: "SUBMIT_ENHANCEMENT_LEVEL_BLOCK_PENDING",
    SUBMIT_ENHANCEMENT_LEVEL_BLOCK_SUCCESS: "SUBMIT_ENHANCEMENT_LEVEL_BLOCK_SUCCESS",
    SUBMIT_ENHANCEMENT_LEVEL_BLOCK_FAILURE: "SUBMIT_ENHANCEMENT_LEVEL_BLOCK_FAILURE",

    ADD_ENHANCEMENT_TABLE_FORM: "ADD_ENHANCEMENT_TABLE_FORM",

    SUBMIT_ENHANCEMENT_BAR_TABLE_PENDING: "SUBMIT_ENHANCEMENT_BAR_TABLE_PENDING",
    SUBMIT_ENHANCEMENT_BAR_TABLE_SUCCESS: "SUBMIT_ENHANCEMENT_BAR_TABLE_SUCCESS",
    SUBMIT_ENHANCEMENT_BAR_TABLE_FAILURE: "SUBMIT_ENHANCEMENT_BAR_TABLE_FAILURE",


    FETCH_ENHANCEMENT_CATEGORY_PENDING: "FETCH_ENHANCEMENT_CATEGORY_PENDING",
    FETCH_ENHANCEMENT_CATEGORY_SUCCESS: "FETCH_ENHANCEMENT_CATEGORY_SUCCESS",
    FETCH_ENHANCEMENT_CATEGORY_FAILURE: "FETCH_ENHANCEMENT_CATEGORY_FAILURE",

    SUBMIT_ENHANCEMENT_CATEGORY_PENDING: "SUBMIT_ENHANCEMENT_CATEGORY_PENDING",
    SUBMIT_ENHANCEMENT_CATEGORY_SUCCESS: "SUBMIT_ENHANCEMENT_CATEGORY_SUCCESS",
    SUBMIT_ENHANCEMENT_CATEGORY_FAILURE: "SUBMIT_ENHANCEMENT_CATEGORY_FAILURE",

    DELETE_ENHANCEMENT_CATEGORY_PENDING: "DELETE_ENHANCEMENT_CATEGORY_PENDING",
    DELETE_ENHANCEMENT_CATEGORY_SUCCESS: "DELETE_ENHANCEMENT_CATEGORY_SUCCESS",
    DELETE_ENHANCEMENT_CATEGORY_FAILURE: "DELETE_ENHANCEMENT_CATEGORY_FAILURE",

    UPDATE_ENHANCEMENT_CATEGORY_PENDING: "UPDATE_ENHANCEMENT_CATEGORY_PENDING",
    UPDATE_ENHANCEMENT_CATEGORY_SUCCESS: "UPDATE_ENHANCEMENT_CATEGORY_SUCCESS",
    UPDATE_ENHANCEMENT_CATEGORY_FAILURE: "UPDATE_ENHANCEMENT_CATEGORY_FAILURE",

    UPDATE_ENHANCEMENT_CATEGORY_STATUS: "UPDATE_ENHANCEMENT_CATEGORY_STATUS",

    ADD_ENHANCEMENT_CATEGORY_FORM: "ADD_ENHANCEMENT_CATEGORY_FORM",

    EDIT_ENHANCEMENT_CATEGORY_FORM: "EDIT_ENHANCEMENT_CATEGORY_FORM",

    ADD_ENHANCEMENT_PRODUCT_FORM: "ADD_ENHANCEMENT_PRODUCT_FORM",

    SUBMIT_ENHANCEMENT_PRODUCT_PENDING: "SUBMIT_ENHANCEMENT_PRODUCT_PENDING",
    SUBMIT_ENHANCEMENT_PRODUCT_SUCCESS: "SUBMIT_ENHANCEMENT_PRODUCT_SUCCESS",
    SUBMIT_ENHANCEMENT_PRODUCT_UPDATE: "SUBMIT_ENHANCEMENT_PRODUCT_UPDATE",
    SUBMIT_ENHANCEMENT_PRODUCT_FAILURE: "SUBMIT_ENHANCEMENT_PRODUCT_FAILURE",

    FETCH_ENHANCEMENT_PRODUCT_PENDING: "FETCH_ENHANCEMENT_PRODUCT_PENDING",
    FETCH_ENHANCEMENT_PRODUCT_SUCCESS: "FETCH_ENHANCEMENT_PRODUCT_SUCCESS",
    FETCH_ENHANCEMENT_PRODUCT_FAILURE: "FETCH_ENHANCEMENT_PRODUCT_FAILURE",
    FETCH_ENHANCEMENT_PRODUCT_MORE: "FETCH_ENHANCEMENT_PRODUCT_MORE",

    UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_PENDING: "UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_PENDING",
    UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_SUCCESS: "UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_SUCCESS",
    UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_FAILURE: "UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_FAILURE",

    SORT_ENHANCEMENT_CATEGORY: "SORT_ENHANCEMENT_CATEGORY",

    DELETE_ENHANCEMENT_PRODUCT_PENDING: "DELETE_ENHANCEMENT_PRODUCT_PENDING",
    DELETE_ENHANCEMENT_PRODUCT_SUCCESS: "DELETE_ENHANCEMENT_PRODUCT_SUCCESS",
    DELETE_ENHANCEMENT_PRODUCT_FAILURE: "DELETE_ENHANCEMENT_PRODUCT_FAILURE",

    FETCH_ENHANCEMENT_SETTING_PENDING: "FETCH_ENHANCEMENT_SETTING_PENDING",
    FETCH_ENHANCEMENT_SETTING_SUCCESS: "FETCH_ENHANCEMENT_SETTING_SUCCESS",
    FETCH_ENHANCEMENT_SETTING_FAILURE: "FETCH_ENHANCEMENT_SETTING_FAILURE",

    SUBMIT_ENHANCEMENT_SETTING_PENDING: "SUBMIT_ENHANCEMENT_SETTING_PENDING",
    SUBMIT_ENHANCEMENT_SETTING_SUCCESS: "SUBMIT_ENHANCEMENT_SETTING_SUCCESS",
    SUBMIT_ENHANCEMENT_SETTING_FAILURE: "SUBMIT_ENHANCEMENT_SETTING_FAILURE",

    ENABLED_ENHANCEMENT_PRODUCT_SUCCESS: "ENABLED_ENHANCEMENT_PRODUCT_SUCCESS",

    FETCH_ENHANCEMENT_TABLE_PENDING: "FETCH_ENHANCEMENT_TABLE_PENDING",
    FETCH_ENHANCEMENT_TABLE_SUCCESS: "FETCH_ENHANCEMENT_TABLE_SUCCESS",
    FETCH_ENHANCEMENT_TABLE_FAILURE: "FETCH_ENHANCEMENT_TABLE_FAILURE",
    FETCH_ENHANCEMENT_TABLE_MORE: "FETCH_ENHANCEMENT_TABLE_MORE",

    UPDATE_ENHANCEMENT_BAR_TABLE: "UPDATE_ENHANCEMENT_BAR_TABLE",

    INSERT_ENHANCEMENT_BAR_TABLE: "INSERT_ENHANCEMENT_BAR_TABLE",

    DELETE_ENHANCEMENT_TABLE_PENDING: "DELETE_ENHANCEMENT_TABLE_PENDING",
    DELETE_ENHANCEMENT_TABLE_SUCCESS: "DELETE_ENHANCEMENT_TABLE_SUCCESS",
    DELETE_ENHANCEMENT_TABLE_FAILURE: "DELETE_ENHANCEMENT_TABLE_FAILURE",

    CHANGE_ENHANCEMENT_LEVEL_SUCCESS: "CHANGE_ENHANCEMENT_LEVEL_SUCCESS",

    UPDATE_ENHANCEMENT_LEVEL_PENDING: "UPDATE_ENHANCEMENT_LEVEL_PENDING",
    UPDATE_ENHANCEMENT_LEVEL_SUCCESS: "UPDATE_ENHANCEMENT_LEVEL_SUCCESS",
    UPDATE_ENHANCEMENT_LEVEL_FAILURE: "UPDATE_ENHANCEMENT_LEVEL_FAILURE",

    FETCH_ENHANCEMENT_ROLE_PENDING: "FETCH_ENHANCEMENT_ROLE_PENDING",
    FETCH_ENHANCEMENT_ROLE_SUCCESS: "FETCH_ENHANCEMENT_ROLE_SUCCESS",
    FETCH_ENHANCEMENT_ROLE_FAILURE: "FETCH_ENHANCEMENT_ROLE_FAILURE",
    FETCH_ENHANCEMENT_ROLE_MORE: "FETCH_ENHANCEMENT_ROLE_MORE",

    SUBMIT_ENHANCEMENT_ROLE_PENDING: "SUBMIT_ENHANCEMENT_ROLE_PENDING",
    SUBMIT_ENHANCEMENT_ROLE_SUCCESS: "SUBMIT_ENHANCEMENT_ROLE_SUCCESS",
    SUBMIT_ENHANCEMENT_ROLE_FAILURE: "SUBMIT_ENHANCEMENT_ROLE_FAILURE",

    DELETE_ENHANCEMENT_ROLE_PENDING: "DELETE_ENHANCEMENT_ROLE_PENDING",
    DELETE_ENHANCEMENT_ROLE_SUCCESS: "DELETE_ENHANCEMENT_ROLE_SUCCESS",
    DELETE_ENHANCEMENT_ROLE_FAILURE: "DELETE_ENHANCEMENT_ROLE_FAILURE",

    ADD_ENHANCEMENT_ROLE_FORM: "ADD_ENHANCEMENT_ROLE_FORM",


    FETCH_ENHANCEMENT_PAYMENT_PENDING: "FETCH_ENHANCEMENT_PAYMENT_PENDING",
    FETCH_ENHANCEMENT_PAYMENT_SUCCESS: "FETCH_ENHANCEMENT_PAYMENT_SUCCESS",
    FETCH_ENHANCEMENT_PAYMENT_FAILURE: "FETCH_ENHANCEMENT_PAYMENT_FAILURE",

    SUBMIT_ENHANCEMENT_PAYMENT_PENDING: "SUBMIT_ENHANCEMENT_PAYMENT_PENDING",
    SUBMIT_ENHANCEMENT_PAYMENT_SUCCESS: "SUBMIT_ENHANCEMENT_PAYMENT_SUCCESS",
    SUBMIT_ENHANCEMENT_PAYMENT_FAILURE: "SUBMIT_ENHANCEMENT_PAYMENT_FAILURE",

    DELETE_ENHANCEMENT_PAYMENT_PENDING: "DELETE_ENHANCEMENT_PAYMENT_PENDING",
    DELETE_ENHANCEMENT_PAYMENT_SUCCESS: "DELETE_ENHANCEMENT_PAYMENT_SUCCESS",
    DELETE_ENHANCEMENT_PAYMENT_FAILURE: "DELETE_ENHANCEMENT_PAYMENT_FAILURE",

    ADD_ENHANCEMENT_PAYMENT_FORM: "ADD_ENHANCEMENT_PAYMENT_FORM",

    CHANGE_ENHANCEMENT_PAYMENT_STATUS: "CHANGE_ENHANCEMENT_PAYMENT_STATUS",

    /** Orders */
    FETCH_ENHANCEMENT_ORDER_PENDING: "FETCH_ENHANCEMENT_ORDER_PENDING",
    FETCH_ENHANCEMENT_ORDER_SUCCESS: "FETCH_ENHANCEMENT_ORDER_SUCCESS",
    FETCH_ENHANCEMENT_ORDER_FAILURE: "FETCH_ENHANCEMENT_ORDER_FAILURE",
    FETCH_ENHANCEMENT_ORDER_MORE: "FETCH_ENHANCEMENT_ORDER_MORE",

    CHANGE_ENHANCEMENT_ORDER_STATUS: "CHANGE_ENHANCEMENT_ORDER_STATUS",
    CHANGE_ENHANCEMENT_ORDER_STATUS_PENDING: "CHANGE_ENHANCEMENT_ORDER_STATUS_PENDING",
    CHANGE_ENHANCEMENT_ORDER_STATUS_SUCCESS: "CHANGE_ENHANCEMENT_ORDER_STATUS_SUCCESS",

    FETCH_NNIGHTLY_TOTALS: "FETCH_NNIGHTLY_TOTALS"



};

const initialState = {
    // Bar Enhancement Schedule
    schedule: {
        isFetching: false,
        data: [],
        total: 0
    },
    scheduleForm: {},
    /* Enhancement level */
    level: { form: {}, error: {}, data: [], isFetching: false, isSubmitting: false, total: 0 },
    blocks: {},
    activeBlock: 0,

    /* Table Form */
    table: { form: {}, error: {}, data: [], isFetching: false, isSubmitting: false, total: 0 },

    category: { form: {}, error: {}, data: {}, isFetching: false, isSubmitting: false, isDeleting: false, total: 0, ids: [] },

    product: { form: {}, error: {}, data: [], isFetching: false, isSubmitting: false, isDeleting: false, total: {}, ids: {}, page: {}, isMore: {}, isUploading: false },

    setting: { data: {} },

    management: { form: {}, error: {}, data: [], isFetching: false, isSubmitting: false, isDeleting: false, total: {}, ids: {}, page: {}, isMore: {} },

    role: { form: {}, error: {}, data: {}, isFetching: false, isSubmitting: false, isDeleting: false, total: {}, ids: {}, page: {}, isMore: {} },

    payment: { form: {}, error: {}, data: {}, isFetching: false, isSubmitting: false, isDeleting: false, total: {}, ids: {}, page: {}, isMore: {} },

    order: { form: {}, error: {}, data: [], isFetching: false, isSubmitting: false, isDeleting: false, total: {}, ids: {}, page: {}, isMore: {}, isUploading: false },

    nightly: {}



};

export const actions = {
    fetchBarSchedule: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_SCHEDULE_PENDING });
        const json = await LifeWidget.fetcheEhancementSchedule(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_SCHEDULE_FAILURE });
        } else if (json.status === 200) {
            dispatch({ type: types.FETCH_ENHANCEMENT_SCHEDULE_SUCCESS, data: json.data });
        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_SCHEDULE_FAILURE });
        }
        return json;
    },

    submitLevel: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_PENDING });
        const json = await LifeWidget.submitEhancementLevel(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_FAILURE });
        } else if (json.status === 200) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_SUCCESS, data: json.data });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_FAILURE });
        }
    },

    submitBlock: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_PENDING });
        const json = await LifeWidget.submitEhancementLevelBlock(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_FAILURE });
        } else if (json.status === 200 && json.data.bar_level_id) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_SUCCESS, data: json.data });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_FAILURE });
        }
    },

    fetchLevel: async (dispatch, data, active) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_PENDING });
        const json = await LifeWidget.fetchEhancementLevel(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_FAILURE });
        } else if (json.status === 200) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_SUCCESS, data: json.data, active: active });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_LEVEL_FAILURE });
        }
    },

    submitBarTable: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_BAR_TABLE_PENDING, data });
        const json = await LifeWidget.submitBarTableInfo(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_BAR_TABLE_FAILURE, data: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_BAR_TABLE_SUCCESS, data: json.data });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_BAR_TABLE_FAILURE, data: data, error: json.data });
        }

        return json;
    },

    fetchCategory: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_CATEGORY_PENDING, params: data });
        const json = await LifeWidget.fetchEhancementCategory(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_CATEGORY_FAILURE, params: data });
        } else if (json.status === 200) {
            dispatch({ type: types.FETCH_ENHANCEMENT_CATEGORY_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_CATEGORY_FAILURE, params: data });
        }
    },

    submitBarCategory: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_CATEGORY_PENDING, params: data });
        const json = await LifeWidget.submitEnhancementCategory(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_CATEGORY_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_CATEGORY_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_CATEGORY_FAILURE, params: data, error: json.data });
        }
        return json;

    },

    updateBarCategory: async (dispatch, data) => {
        dispatch({ type: types.UPDATE_ENHANCEMENT_CATEGORY_PENDING, params: data });
        const json = await LifeWidget.updateEnhancementCategory(data);
        if (json === undefined) {
            dispatch({ type: types.UPDATE_ENHANCEMENT_CATEGORY_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.UPDATE_ENHANCEMENT_CATEGORY_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.UPDATE_ENHANCEMENT_CATEGORY_FAILURE, params: data, error: json.data });
        }

    },

    updateBarCategoryStatus: async (dispatch, data) => {
        dispatch({ type: types.UPDATE_ENHANCEMENT_CATEGORY_STATUS, params: data });
        const json = await LifeWidget.updateEnhancementCategoryStatus(data);
    },

    deleteBarCategory: async (dispatch, data) => {
        dispatch({ type: types.DELETE_ENHANCEMENT_CATEGORY_PENDING, params: data });
        const json = await LifeWidget.deleteEnhancementCategory(data.id);
        if (json === undefined) {
            dispatch({ type: types.DELETE_ENHANCEMENT_CATEGORY_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.DELETE_ENHANCEMENT_CATEGORY_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.DELETE_ENHANCEMENT_CATEGORY_FAILURE, params: data, error: json.data });
        }
    },

    submitBarProduct: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_PRODUCT_PENDING, params: data });
        const json = await LifeWidget.submitEnhancementProduct(data);
        console.log(json)
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_PRODUCT_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            if (data.id) {
                dispatch({ type: types.SUBMIT_ENHANCEMENT_PRODUCT_UPDATE, data: json.data, params: data });
            } else {
                dispatch({ type: types.SUBMIT_ENHANCEMENT_PRODUCT_SUCCESS, data: json.data, params: data });
            }

        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_PRODUCT_FAILURE, params: data, error: json.data });
        }
        return json;
    },

    fetchProduct: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_PRODUCT_PENDING, params: data });
        const json = await LifeWidget.fetchEnhancementProduct(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_PRODUCT_FAILURE, params: data });
        } else if (json.status === 200) {
            if (data.page > 1) {
                dispatch({ type: types.FETCH_ENHANCEMENT_PRODUCT_MORE, data: json.data, params: data });
            } else {
                dispatch({ type: types.FETCH_ENHANCEMENT_PRODUCT_SUCCESS, data: json.data, params: data });
            }

        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_PRODUCT_FAILURE, params: data });
        }
    },

    deleteBarProduct: async (dispatch, data) => {
        dispatch({ type: types.DELETE_ENHANCEMENT_PRODUCT_PENDING, params: data });
        const json = await LifeWidget.deleteEnhancementProduct(data.id);
        if (json === undefined) {
            dispatch({ type: types.DELETE_ENHANCEMENT_PRODUCT_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.DELETE_ENHANCEMENT_PRODUCT_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.DELETE_ENHANCEMENT_PRODUCT_FAILURE, params: data, error: json.data });
        }
        return json;
    },

    uploadProductImage: async (dispatch, params, data) => {
        dispatch({ type: types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_PENDING, params: params });
        const json = await LifeWidget.uploadEnhancementProductImage(data);
        if (json === undefined) {
            dispatch({ type: types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_FAILURE, params: params, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_SUCCESS, data: json.data, params: params });
        } else {
            dispatch({ type: types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_FAILURE, params: params, error: json.data });
        }

    },

    fetchBarSetting: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_SETTING_PENDING, params: data });
        const json = await LifeWidget.fetchEnhancementSettings(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_SETTING_FAILURE, params: data });
        } else if (json.status === 200) {
            dispatch({ type: types.FETCH_ENHANCEMENT_SETTING_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_SETTING_FAILURE, params: data });
        }
    },

    fetchTable: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_TABLE_PENDING, params: data });
        const json = await LifeWidget.fetchEnhancementTable(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_TABLE_FAILURE, params: data });
        } else if (json.status === 200) {
            if (data.page > 1) {
                dispatch({ type: types.FETCH_ENHANCEMENT_TABLE_MORE, data: json.data, params: data });
            } else {
                dispatch({ type: types.FETCH_ENHANCEMENT_TABLE_SUCCESS, data: json.data, params: data });
            }

        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_TABLE_FAILURE, params: data });
        }
    },

    deleteBarTable: async (dispatch, data) => {
        dispatch({ type: types.DELETE_ENHANCEMENT_TABLE_PENDING, params: data });
        const json = await LifeWidget.deleteEnhancementTable(data.id);
        if (json === undefined) {
            dispatch({ type: types.DELETE_ENHANCEMENT_TABLE_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.DELETE_ENHANCEMENT_TABLE_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.DELETE_ENHANCEMENT_TABLE_FAILURE, params: data, error: json.data });
        }
        return json;
    },

    submitBarSetting: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_SETTING_SUCCESS, params: data });
        const json = await LifeWidget.submitEnhancementSetting(data);
    },

    enabledBarProduct: async (dispatch, data) => {
        dispatch({ type: types.ENABLED_ENHANCEMENT_PRODUCT_SUCCESS, params: data });
        const json = await LifeWidget.updateEnhancementProductStatus(data);
    },

    updateSortCategory: async (dispatch, data) => {
        const json = await LifeWidget.sortEnhancementCategory(data);
        if (json !== undefined) {
            if (json.status === 200) {
                dispatch({ type: types.SORT_ENHANCEMENT_CATEGORY, params: data, data: json.data });

            }
        }
    },

    updateBarLevel: async (dispatch, data) => {
        dispatch({ type: types.UPDATE_ENHANCEMENT_LEVEL_PENDING, params: data });
        const json = await LifeWidget.updateEnhancementLevel(data);
        if (json === undefined) {
            dispatch({ type: types.UPDATE_ENHANCEMENT_LEVEL_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.UPDATE_ENHANCEMENT_LEVEL_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.UPDATE_ENHANCEMENT_LEVEL_FAILURE, params: data, error: json.data });
        }
        return json;

    },

    fetchRole: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_ROLE_PENDING, params: data });
        const json = await LifeWidget.fetchEnhancementRole(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_ROLE_FAILURE, params: data });
        } else if (json.status === 200) {
            if (data.page > 1) {
                dispatch({ type: types.FETCH_ENHANCEMENT_ROLE_MORE, data: json.data, params: data });
            } else {
                dispatch({ type: types.FETCH_ENHANCEMENT_ROLE_SUCCESS, data: json.data, params: data });
            }

        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_ROLE_FAILURE, params: data });
        }
    },

    submitBarRole: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_ROLE_PENDING, params: data });
        const json = await LifeWidget.submitEnhancementRole(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_ROLE_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_ROLE_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_ROLE_FAILURE, params: data, error: json.data });
        }
        return json;

    },

    deleteBarRole: async (dispatch, data) => {
        dispatch({ type: types.DELETE_ENHANCEMENT_ROLE_PENDING, params: data });
        const json = await LifeWidget.deleteEnhancementRole(data.id);
        if (json === undefined) {
            dispatch({ type: types.DELETE_ENHANCEMENT_ROLE_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.DELETE_ENHANCEMENT_ROLE_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.DELETE_ENHANCEMENT_ROLE_FAILURE, params: data, error: json.data });
        }
        return json;
    },

    createBarCattegory: async (data) => {
        return await LifeWidget.createEnhancementCategory(data);
    },

    /** Payment Module */

    fetchPayment: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_PAYMENT_PENDING, params: data });
        const json = await LifeWidget.fetchEnhancementPayment(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_PAYMENT_FAILURE, params: data });
        } else if (json.status === 200) {
            dispatch({ type: types.FETCH_ENHANCEMENT_PAYMENT_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_PAYMENT_FAILURE, params: data });
        }
    },

    submitPaymentModule: async (dispatch, data) => {
        dispatch({ type: types.SUBMIT_ENHANCEMENT_PAYMENT_PENDING, params: data });
        const json = await LifeWidget.submitEnhancementPayment(data);
        if (json === undefined) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_PAYMENT_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_PAYMENT_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.SUBMIT_ENHANCEMENT_PAYMENT_FAILURE, params: data, error: json.data });
        }
        return json;

    },

    deletePaymentModule: async (dispatch, data) => {
        dispatch({ type: types.DELETE_ENHANCEMENT_PAYMENT_PENDING, params: data });
        const json = await LifeWidget.deleteEnhancementPayment(data.id);
        if (json === undefined) {
            dispatch({ type: types.DELETE_ENHANCEMENT_PAYMENT_FAILURE, params: data, error: {} });
        } else if (json.status === 200) {
            dispatch({ type: types.DELETE_ENHANCEMENT_PAYMENT_SUCCESS, data: json.data, params: data });
        } else {
            dispatch({ type: types.DELETE_ENHANCEMENT_PAYMENT_FAILURE, params: data, error: json.data });
        }
        return json;
    },

    fetchOrder: async (dispatch, data) => {
        dispatch({ type: types.FETCH_ENHANCEMENT_ORDER_PENDING, params: data });
        const json = await LifeWidget.fetchEnhancementOrder(data);
        if (json === undefined) {
            dispatch({ type: types.FETCH_ENHANCEMENT_ORDER_FAILURE, params: data });
        } else if (json.status === 200) {
            if (data.page > 1) {
                dispatch({ type: types.FETCH_ENHANCEMENT_ORDER_MORE, data: json.data, params: data });
            } else {
                dispatch({ type: types.FETCH_ENHANCEMENT_ORDER_SUCCESS, data: json.data, params: data });
            }
        } else {
            dispatch({ type: types.FETCH_ENHANCEMENT_ORDER_FAILURE, params: data });
        }
    },

    changePaymentStatus: async (dispatch, params, data, post) => {
        dispatch({ type: types.CHANGE_ENHANCEMENT_PAYMENT_STATUS, params, data });
        LifeWidget.updateEnhancementPayment(post);
    },

    orderStatusChange: async (dispatch, data) => {
        dispatch({ type: types.CHANGE_ENHANCEMENT_ORDER_STATUS_PENDING });
        const json = await LifeWidget.changeOrderStatus(data);
        dispatch({ type: types.CHANGE_ENHANCEMENT_ORDER_STATUS_SUCCESS });
        return json;
    },

    fetchNightlyTotals: async (dispatch, data) => {
        const json = await LifeWidget.nightlyTotals(data);
        if (json != undefined) {
            if (json.status == 200) {
                dispatch({ type: types.FETCH_NNIGHTLY_TOTALS, data: json.data });
            }
        }
        return json;
    },
    /** End payment module */



    addScheduleForm: (data) => ({
        type: types.ADD_ENHANCEMENT_SCHEDULE_FORM,
        data,
    }),

    addLevelForm: (data) => ({
        type: types.ADD_ENHANCEMENT_LEVEL_FORM,
        data,
    }),

    addTableForm: (data, bar_id, level_id) => ({
        type: types.ADD_ENHANCEMENT_TABLE_FORM,
        data: data,
        id: bar_id,
        level_id: level_id
    }),

    addLevelBlock: (id, data) => ({
        type: types.ADD_ENHANCEMENT_LEVEL_BLOCK,
        data,
        id
    }),

    addCategoryForm: (params, data) => ({
        type: types.ADD_ENHANCEMENT_CATEGORY_FORM,
        params,
        data
    }),

    editCategoryForm: (params) => ({
        type: types.EDIT_ENHANCEMENT_CATEGORY_FORM,
        params
    }),

    addProductForm: (params, data) => ({
        type: types.ADD_ENHANCEMENT_PRODUCT_FORM,
        params,
        data
    }),

    addRoleForm: (params, data) => ({
        type: types.ADD_ENHANCEMENT_ROLE_FORM,
        params,
        data
    }),

    addPaymentForm: (params, data) => ({
        type: types.ADD_ENHANCEMENT_PAYMENT_FORM,
        params,
        data
    }),

    sortCategory: (params, data) => ({
        type: types.SORT_ENHANCEMENT_CATEGORY,
        params,
        data
    }),

    updateBarTable: (params) => ({
        type: types.UPDATE_ENHANCEMENT_BAR_TABLE,
        params,
    }),

    insertBarTable: (params) => ({
        type: types.INSERT_ENHANCEMENT_BAR_TABLE,
        params,
    }),

    changeBarLevel: (params) => ({
        type: types.CHANGE_ENHANCEMENT_LEVEL_SUCCESS,
        params,
    }),
    changeOrderStatus: (params) => ({
        type: types.CHANGE_ENHANCEMENT_ORDER_STATUS,
        params,
    }),




};

export const reducer = (state = initialState, action) => {
    const { type, data, id, level_id, error, active, params } = action;
    switch (type) {
        case types.FETCH_ENHANCEMENT_SCHEDULE_PENDING: {
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    isFetching: true
                }
            };
        }
        case types.FETCH_ENHANCEMENT_SCHEDULE_FAILURE: {
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    isFetching: false
                }
            };
        }
        case types.FETCH_ENHANCEMENT_SCHEDULE_SUCCESS: {
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    isFetching: false,
                    data: data,
                    total: data.length
                }
            };
        }

        case types.ADD_ENHANCEMENT_SCHEDULE_FORM: {
            return {
                ...state,
                scheduleForm: data
            };
        }

        case types.ADD_ENHANCEMENT_LEVEL_FORM: {
            return {
                ...state,
                level: {
                    ...state.level,
                    form: data
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_LEVEL_PENDING: {
            return {
                ...state,
                level: {
                    ...state.level,
                    isSubmitting: true
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_LEVEL_SUCCESS: {
            const blocks = {};
            if (data && data.map((item) => {
                blocks[item.id] = item.blocks;
            }));
            const activeBlock = (data && data.length > 0) ? data[0].id : 0;
            return {
                ...state,
                level: {
                    ...state.level,
                    isSubmitting: false,
                    data: data,
                },
                activeBlock: active ? active : activeBlock,
                blocks: blocks
            };
        }

        case types.SUBMIT_ENHANCEMENT_LEVEL_FAILURE: {
            return {
                ...state,
                level: {
                    ...state.level,
                    isSubmitting: false,
                }
            };
        }

        // case types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_PENDING: {
        //     return {
        //         ...state,
        //         level: {
        //             ...state.level,
        //             isSubmitting: true
        //         }
        //     };
        // }

        case types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_SUCCESS: {
            return {
                ...state,
                blocks: {
                    ...state.blocks,
                    [data.bar_level_id]: data.data,
                }
            };
        }

        // case types.SUBMIT_ENHANCEMENT_LEVEL_BLOCK_FAILURE: {
        //     return {
        //         ...state,
        //         level: {
        //             ...state.level,
        //             isSubmitting: false,
        //         }
        //     };
        // }

        case types.ADD_ENHANCEMENT_LEVEL_BLOCK: {
            return {
                ...state,
                activeBlock: id,
                blocks: {
                    ...state.blocks,
                    [id]: data,
                }
            };
        }

        case types.ADD_ENHANCEMENT_TABLE_FORM: {
            return {
                ...state,
                table: {
                    ...state.table,
                    form: {
                        ...state.table.form,
                        [id]: {
                            ...state.table.form[id],
                            [level_id]: data
                        }
                    }

                }
            };
        }


        case types.SUBMIT_ENHANCEMENT_BAR_TABLE_PENDING: {
            return {
                ...state,
                table: {
                    ...state.table,
                    error: {
                        ...state.table.error,
                        [data.bar_id]: {
                            ...state.table.error[data.bar_id],
                            [data.level_id]: {}
                        }
                    },
                    isSubmitting: true
                }
            }
        }

        case types.SUBMIT_ENHANCEMENT_BAR_TABLE_FAILURE: {
            return {
                ...state,
                table: {
                    ...state.table,
                    error: {
                        ...state.table.error,
                        [data.bar_id]: {
                            ...state.table.error[data.bar_id],
                            [data.level_id]: error
                        }
                    },
                    isSubmitting: false

                }
            }
        }

        case types.SUBMIT_ENHANCEMENT_BAR_TABLE_SUCCESS: {
            return {
                ...state,
                table: {
                    ...state.table,
                    form: {
                        ...state.table.form,
                        [id]: {
                            ...state.table.form[id],
                            [level_id]: {}
                        }
                    },
                    isSubmitting: false

                }
            };
        }

        case types.FETCH_ENHANCEMENT_CATEGORY_PENDING: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isFetching: true
                }
            };
        }

        case types.FETCH_ENHANCEMENT_CATEGORY_SUCCESS: {
            return {
                ...state,
                category: {
                    ...state.category,
                    ids: {
                        ...state.category.ids,
                        [params.bar_id]: data.ids
                    },
                    data: {
                        ...state.category.data,
                        [params.bar_id]: data.data
                    }

                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_CATEGORY_PENDING: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isSubmitting: true,
                },
                error: {
                    ...state.category.error,
                    [params.bar_id]: {
                        ...state.category.error[params.bar_id],
                        [params.category_id]: {}
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_CATEGORY_SUCCESS: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isSubmitting: false,
                    ids: {
                        ...state.category.ids,
                        [params.bar_id]: data.ids
                    },
                    data: {
                        ...state.category.data,
                        [params.bar_id]: { ...state.category.data?.[params.bar_id], ...{ [data.data.id]: data.data } }
                    },
                    form: {
                        ...state.category.form,
                        [params.bar_id]: {
                            ...state.category.form[params.bar_id],
                            [params.category_id]: {}
                        }
                    },
                    error: {
                        ...state.category.error,
                        [params.bar_id]: {
                            ...state.category.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_CATEGORY_FAILURE: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isSubmitting: false,
                    error: {
                        ...state.category.error,
                        [params.bar_id]: {
                            ...state.category.error[params.bar_id],
                            [params.category_id]: error
                        }
                    }
                }
            };
        }

        case types.ADD_ENHANCEMENT_CATEGORY_FORM: {
            return {
                ...state,
                category: {
                    ...state.category,
                    form: {
                        ...state.category.form,
                        [params.bar_id]: {
                            ...state.category.form[params.bar_id],
                            [params.category_id]: data
                        }
                    }

                }
            };
        }

        /****** Category Deleting */

        case types.DELETE_ENHANCEMENT_CATEGORY_PENDING: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: true,
                },
                error: {
                    ...state.category.error,
                    [params.bar_id]: {
                        ...state.category.error[params.bar_id],
                        [params.category_id]: {}
                    }
                }
            };
        }

        case types.DELETE_ENHANCEMENT_CATEGORY_SUCCESS: {

            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: false,
                    data: {
                        ...state.category.data,
                        [params.bar_id]: {
                            ...state.category.data?.[params.bar_id],
                            [params.id]: {}
                        }
                    },
                    ids: {
                        ...state.category.ids,
                        [params.bar_id]: data.ids
                    },
                    error: {
                        ...state.category.error,
                        [params.bar_id]: {
                            ...state.category.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    }
                }
            };
        }

        case types.DELETE_ENHANCEMENT_CATEGORY_FAILURE: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: false,
                    error: {
                        ...state.category.error,
                        [params.bar_id]: {
                            ...state.category.error[params.bar_id],
                            [params.category_id]: error
                        }
                    }
                }
            };
        }

        /****** Category Updating */

        case types.UPDATE_ENHANCEMENT_CATEGORY_PENDING: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: true,
                },
                error: {
                    ...state.category.error,
                    [params.bar_id]: {
                        ...state.category.error[params.bar_id],
                        [params.parent_id]: {}
                    }
                }
            };
        }

        case types.UPDATE_ENHANCEMENT_CATEGORY_SUCCESS: {

            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: false,
                    data: {
                        ...state.category.data,
                        [params.bar_id]: {
                            ...state.category.data?.[params.bar_id],
                            [params.id]: params
                        }
                    },
                    error: {
                        ...state.category.error,
                        [params.bar_id]: {
                            ...state.category.error[params.bar_id],
                            [params.parent_id]: {}
                        }
                    }
                }
            };
        }

        case types.UPDATE_ENHANCEMENT_CATEGORY_FAILURE: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: false,
                    error: {
                        ...state.category.error,
                        [params.bar_id]: {
                            ...state.category.error[params.bar_id],
                            [params.parent_id]: error
                        }
                    }
                }
            };
        }

        case types.UPDATE_ENHANCEMENT_CATEGORY_STATUS: {

            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: false,
                    data: {
                        ...state.category.data,
                        [params.bar_id]: {
                            ...state.category.data?.[params.bar_id],
                            [params.category_id]: {
                                ...state.category.data?.[params.bar_id]?.[params.category_id],
                                status: params.enabled ? 1 : 0
                            }
                        }
                    },
                }
            };

        }

        /** Ediit category toggle */
        case types.EDIT_ENHANCEMENT_CATEGORY_FORM: {
            return {
                ...state,
                category: {
                    ...state.category,
                    isDeleting: false,
                    data: {
                        ...state.category.data,
                        [params.bar_id]: {
                            ...state.category.data?.[params.bar_id],
                            [params.id]: params
                        }
                    },
                }
            };
        }

        /** Product form */

        case types.ADD_ENHANCEMENT_PRODUCT_FORM: {
            return {
                ...state,
                product: {
                    ...state.product,
                    form: {
                        ...state.product.form,
                        [params.bar_id]: {
                            ...state.product.form[params.bar_id],
                            [params.category_id]: data
                        }
                    }

                }
            };
        }

        case types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_PENDING: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isUploading: true,

                }
            };
        }

        case types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_SUCCESS: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isUploading: false,
                    form: {
                        ...state.product.form,
                        [params.bar_id]: {
                            ...state.product.form[params.bar_id],
                            [params.category_id]: {
                                ...state.product.form[params.bar_id][params.category_id],
                                photo: data
                            }
                        }
                    }

                }
            };
        }

        case types.UPLOAD_ENHANCEMENT_PRODUCT_IMAGE_FAILURE: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isUploading: false,

                }
            };
        }

        /** Submit product */

        case types.SUBMIT_ENHANCEMENT_PRODUCT_PENDING: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isSubmitting: true,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: {
                            ...state.product.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_PRODUCT_SUCCESS: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isSubmitting: false,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: {
                            ...state.product.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    },
                    data: {
                        ...state.product.data,
                        [params.bar_id]: {
                            ...state.product.data[params.bar_id],
                            [params.category_id]: (state.product.data?.[params.bar_id]?.[params.category_id]) ? { ...state.product.data[params.bar_id][params.category_id], ...data } : data
                        }
                    },
                    ids: {
                        ...state.product.ids,
                        [params.bar_id]: {
                            ...state.product.ids[params.bar_id],
                            [params.category_id]: (state.product.ids?.[params.bar_id]?.[params.category_id]) ? [...state.product.ids[params.bar_id][params.category_id], ...Object.keys(data)] : Object.keys(data),
                        }
                    },
                    total: {
                        ...state.product.total,
                        [params.bar_id]: {
                            ...state.product.total[params.bar_id],
                            [params.category_id]: state.product.total[params.bar_id][params.category_id] + 1
                        }
                    },
                    form: {
                        ...state.product.form,
                        [params.bar_id]: {
                            ...state.product.form[params.bar_id],
                            [params.category_id]: {}
                        }
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_PRODUCT_UPDATE: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isSubmitting: false,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: {
                            ...state.product.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    },
                    data: {
                        ...state.product.data,
                        [params.bar_id]: {
                            ...state.product.data[params.bar_id],
                            [params.category_id]: {
                                ...state.product.data[params.bar_id][params.category_id],
                                ...data
                            }
                        }
                    },
                    form: {
                        ...state.product.form,
                        [params.bar_id]: {
                            ...state.product.form[params.bar_id],
                            [params.category_id]: {}
                        }
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_PRODUCT_FAILURE: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isSubmitting: false,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: {
                            ...state.product.error[params.bar_id],
                            [params.category_id]: error
                        }
                    }
                }
            };
        }

        case types.ENABLED_ENHANCEMENT_PRODUCT_SUCCESS: {
            return {
                ...state,
                product: {
                    ...state.product,
                    data: {
                        ...state.product.data,
                        [params.bar_id]: {
                            ...state.product.data[params.bar_id],
                            [params.category_id]: {
                                ...state.product.data[params.bar_id][params.category_id],
                                [params.product_id]: {
                                    ...state.product.data[params.bar_id][params.category_id][params.product_id],
                                    status: params.enabled ? 1 : 0
                                }
                            }
                        }
                    },
                }
            };
        }

        /** Fetch bar productts */

        case types.FETCH_ENHANCEMENT_PRODUCT_PENDING: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isFetching: true
                }
            };
        }

        case types.FETCH_ENHANCEMENT_PRODUCT_SUCCESS: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isFetching: false,
                    data: {
                        ...state.product.data,
                        [params.bar_id]: {
                            ...state.product.data[params.bar_id],
                            [data.category_id]: data.data
                        }
                    },
                    ids: {
                        ...state.product.ids,
                        [params.bar_id]: {
                            ...state.product.ids[params.bar_id],
                            [data.category_id]: Object.keys(data.data),
                        }
                    },
                    total: {
                        ...state.product.total,
                        [params.bar_id]: {
                            ...state.product.total[params.bar_id],
                            [data.category_id]: data.total
                        }
                    },
                    page: {
                        ...state.product.page,
                        [params.bar_id]: {
                            ...state.product.page[params.bar_id],
                            [data.category_id]: data.page
                        }
                    },
                    isMore: {
                        ...state.product.isMore,
                        [params.bar_id]: {
                            ...state.product.isMore[params.bar_id],
                            [data.category_id]: data.is_more
                        }
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_PRODUCT_MORE: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isFetching: false,
                    data: {
                        ...state.product.data,
                        [params.bar_id]: {
                            ...state.product.data[params.bar_id],
                            [data.category_id]: (state.product.data?.[params.bar_id]?.[data.category_id]) ? { ...state.product.data[params.bar_id][data.category_id], ...data.data } : data.data
                        }
                    },
                    ids: {
                        ...state.product.ids,
                        [params.bar_id]: {
                            ...state.product.ids[params.bar_id],
                            [data.category_id]: (state.product.ids?.[params.bar_id]?.[data.category_id]) ? [...state.product.ids[params.bar_id][data.category_id], ...Object.keys(data.data)] : Object.keys(data.data),
                        }
                    },
                    total: {
                        ...state.product.total,
                        [params.bar_id]: {
                            ...state.product.total[params.bar_id],
                            [data.category_id]: data.total
                        }
                    },
                    page: {
                        ...state.product.page,
                        [params.bar_id]: {
                            ...state.product.page[params.bar_id],
                            [data.category_id]: data.page
                        }
                    },
                    isMore: {
                        ...state.product.isMore,
                        [params.bar_id]: {
                            ...state.product.isMore[params.bar_id],
                            [data.category_id]: data.is_more
                        }
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_PRODUCT_FAILURE: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isFetching: false,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: error
                    }

                }
            };
        }

        /** delete product */
        case types.DELETE_ENHANCEMENT_PRODUCT_PENDING: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isDeleting: true,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: {
                            ...state.product.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    },
                }
            };
        }

        case types.DELETE_ENHANCEMENT_PRODUCT_SUCCESS: {
            return {
                ...state,
                product: {
                    ...state.product,
                    isDeleting: false,
                    error: {
                        ...state.product.error,
                        [params.bar_id]: {
                            ...state.product.error[params.bar_id],
                            [params.category_id]: {}
                        }
                    },
                    data: {
                        ...state.product.data,
                        [params.bar_id]: {
                            ...state.product.data[params.bar_id],
                            [params.category_id]: {
                                ...state.product.data[params.bar_id][params.category_id],
                                [params.id]: {}
                            }
                        }
                    },
                }
            };
        }

        case types.SORT_ENHANCEMENT_CATEGORY: {

            return {
                ...state,
                category: {
                    ...state.category,
                    ids: {
                        ...state.category.ids,
                        [params.bar_id]: data.ids
                    },
                }
            };
        }

        case types.FETCH_ENHANCEMENT_SETTING_SUCCESS: {
            return {
                ...state,
                setting: {
                    ...state.setting,
                    data: {
                        ...state.setting.data,
                        [params.bar_id]: data.data
                    },
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_SETTING_SUCCESS: {
            return {
                ...state,
                setting: {
                    ...state.setting,
                    data: {
                        ...state.setting.data,
                        [params.bar_id]: {
                            ...state.setting.data[params.bar_id],
                            [params.field]: params.value
                        }
                    },
                }
            };
        }

        /** Table management */

        case types.FETCH_ENHANCEMENT_TABLE_PENDING: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isFetching: true
                }
            };
        }

        case types.FETCH_ENHANCEMENT_TABLE_SUCCESS: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isFetching: false,
                    data: {
                        ...state.management.data,
                        [params.bar_id]: data.data
                    },
                    ids: {
                        ...state.management.ids,
                        [params.bar_id]: Object.keys(data.data)
                    },
                    total: {
                        ...state.management.total,
                        [params.bar_id]: data.total
                    },
                    page: {
                        ...state.management.page,
                        [params.bar_id]: data.page
                    },
                    isMore: {
                        ...state.management.isMore,
                        [params.bar_id]: data.is_more
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_TABLE_MORE: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isFetching: false,
                    data: {
                        ...state.management.data,
                        [params.bar_id]: { ...state.management.data[params.bar_id], ...data.data }
                    },
                    ids: {
                        ...state.management.ids,
                        [params.bar_id]: [...state.management.ids[params.bar_id], ...Object.keys(data.data)]
                    },
                    total: {
                        ...state.management.total,
                        [params.bar_id]: data.total
                    },
                    page: {
                        ...state.management.page,
                        [params.bar_id]: data.page
                    },
                    isMore: {
                        ...state.management.isMore,
                        [params.bar_id]: data.is_more
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_TABLE_FAILURE: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isFetching: false,
                    error: {
                        ...state.management.error,
                        [params.bar_id]: error
                    }

                }
            };
        }

        case types.UPDATE_ENHANCEMENT_BAR_TABLE: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isFetching: false,
                    data: {
                        ...state.management.data,
                        [params.bar_id]: {
                            ...state.management.data[params.bar_id],
                            [params.id]: params
                        }
                    },

                }
            };
        }

        case types.INSERT_ENHANCEMENT_BAR_TABLE: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isFetching: false,
                    data: {
                        ...state.management.data,
                        [params.bar_id]: { ...state.management.data[params.bar_id], [params.id]: params }
                    },
                    ids: {
                        ...state.management.ids,
                        [params.bar_id]: [...(state.management.ids[params.bar_id] || []), (params.id || "").toString()]
                    },

                }
            };
        }

        case types.DELETE_ENHANCEMENT_TABLE_PENDING: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isDeleting: true,
                    error: {
                        ...state.management.error,
                        [params.bar_id]: {}
                    }
                }
            };
        }

        case types.DELETE_ENHANCEMENT_TABLE_SUCCESS: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isDeleting: false,
                    error: {
                        ...state.management.error,
                        [params.bar_id]: {}
                    },
                    data: {
                        ...state.management.data,
                        [params.bar_id]: {
                            ...state.management.data[params.bar_id],
                            [params.id]: {}
                        }
                    },
                }
            };
        }

        case types.DELETE_ENHANCEMENT_TABLE_FAILURE: {
            return {
                ...state,
                management: {
                    ...state.management,
                    isDeleting: false,
                    error: {
                        ...state.management.error,
                        [params.bar_id]: error
                    }
                }
            };
        }

        case types.CHANGE_ENHANCEMENT_LEVEL_SUCCESS: {
            const level = [...state.level.data];
            const index = level.findIndex(i => i.id === params.id);
            if (index > -1) {
                let newLevel = level[index];
                newLevel.level_name = params.level_name;
                level[index] = newLevel;
            }
            return {
                ...state,
                level: {
                    ...state.level,
                    data: level,
                },
            };
        }

        case types.UPDATE_ENHANCEMENT_LEVEL_PENDING: {
            return {
                ...state,
                level: {
                    ...state.level,
                    error: {},
                    isSubmitting: true,
                },
            };
        }

        case types.UPDATE_ENHANCEMENT_LEVEL_SUCCESS: {
            return {
                ...state,
                level: {
                    ...state.level,
                    error: {},
                    isSubmitting: false,
                },
            };
        }

        case types.UPDATE_ENHANCEMENT_LEVEL_FAILURE: {
            return {
                ...state,
                level: {
                    ...state.level,
                    error: error,
                    isSubmitting: false,
                },
            };
        }

        /** Bar Role */

        case types.FETCH_ENHANCEMENT_ROLE_PENDING: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isFetching: true
                }
            };
        }

        case types.FETCH_ENHANCEMENT_ROLE_SUCCESS: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isFetching: false,
                    data: {
                        ...state.role.data,
                        [params.bar_id]: data.data
                    },
                    ids: {
                        ...state.role.ids,
                        [params.bar_id]: Object.keys(data.data)
                    },
                    total: {
                        ...state.role.total,
                        [params.bar_id]: data.total
                    },
                    page: {
                        ...state.role.page,
                        [params.bar_id]: data.page
                    },
                    isMore: {
                        ...state.role.isMore,
                        [params.bar_id]: data.is_more
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_ROLE_MORE: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isFetching: false,
                    data: {
                        ...state.role.data,
                        [params.bar_id]: (state.role.data?.[params.bar_id]) ? { ...state.role.data[params.bar_id], ...data.data } : data.data
                    },
                    ids: {
                        ...state.role.ids,
                        [params.bar_id]: (state.role.ids?.[params.bar_id]) ? [...state.role.ids[params.bar_id], ...Object.keys(data.data)] : Object.keys(data.data),
                    },
                    total: {
                        ...state.role.total,
                        [params.bar_id]: data.total
                    },
                    page: {
                        ...state.role.page,
                        [params.bar_id]: data.page
                    },
                    isMore: {
                        ...state.role.isMore,
                        [params.bar_id]: data.is_more
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_ROLE_FAILURE: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isFetching: false,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: error
                    }

                }
            };
        }

        /** Submit bar role */

        case types.SUBMIT_ENHANCEMENT_ROLE_PENDING: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isSubmitting: true,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: {}
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_ROLE_SUCCESS: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isSubmitting: false,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: {}
                    },
                    data: {
                        ...state.role.data,
                        [params.bar_id]: (state.role.data?.[params.bar_id]) ? { ...state.role.data[params.bar_id], ...data } : data
                    },
                    ids: {
                        ...state.role.ids,
                        [params.bar_id]: (state.role.ids?.[params.bar_id]) ? [...state.role.ids[params.bar_id], ...Object.keys(data)] : Object.keys(data),
                    },
                    total: {
                        ...state.role.total,
                        [params.bar_id]: state.role.total[params.bar_id] + 1
                    },
                    form: {
                        ...state.role.form,
                        [params.bar_id]: {}
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_ROLE_FAILURE: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isSubmitting: false,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: error
                    }
                }
            };
        }

        case types.ADD_ENHANCEMENT_ROLE_FORM: {
            return {
                ...state,
                role: {
                    ...state.role,
                    form: {
                        ...state.role.form,
                        [params.bar_id]: data
                    }

                }
            };
        }

        /** Delete bar role */

        case types.DELETE_ENHANCEMENT_ROLE_PENDING: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isDeleting: true,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: {}
                    },
                }
            };
        }

        case types.DELETE_ENHANCEMENT_ROLE_SUCCESS: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isDeleting: false,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: {}
                    },
                    data: {
                        ...state.role.data,
                        [params.bar_id]: {
                            ...state.role.data[params.bar_id],
                            [params.id]: {}
                        }
                    },
                    ids: {
                        ...state.role.ids,
                        [params.bar_id]: (state.role.ids?.[params.bar_id] || []).filter(i => i != params.id)
                    },
                    total: {
                        ...state.role.total,
                        [params.bar_id]: (state.role.total?.[params.bar_id] || 0) - 1
                    },
                }
            };
        }

        case types.DELETE_ENHANCEMENT_ROLE_FAILURE: {
            return {
                ...state,
                role: {
                    ...state.role,
                    isDeleting: false,
                    error: {
                        ...state.role.error,
                        [params.bar_id]: {}
                    },
                }
            };
        }

        /** Payment Modules */

        case types.ADD_ENHANCEMENT_PAYMENT_FORM: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    form: {
                        ...state.payment.form,
                        [params.bar_id]: data
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_PAYMENT_PENDING: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isFetching: true
                }
            };
        }

        case types.FETCH_ENHANCEMENT_PAYMENT_SUCCESS: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isFetching: false,
                    data: {
                        ...state.payment.data,
                        [params.bar_id]: data.data
                    },
                    ids: {
                        ...state.payment.ids,
                        [params.bar_id]: Object.keys(data.data)
                    },
                    total: {
                        ...state.payment.total,
                        [params.bar_id]: data.total
                    },
                    page: {
                        ...state.payment.page,
                        [params.bar_id]: data.page
                    },
                }
            };
        }

        case types.FETCH_ENHANCEMENT_PAYMENT_FAILURE: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isFetching: false,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: error
                    }

                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_PAYMENT_PENDING: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isSubmitting: true,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: {}
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_PAYMENT_SUCCESS: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isSubmitting: false,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: {}
                    },
                    data: {
                        ...state.payment.data,
                        [params.bar_id]: (state.payment.data?.[params.bar_id]) ? { ...state.payment.data[params.bar_id], ...data } : data
                    },
                    ids: {
                        ...state.payment.ids,
                        [params.bar_id]: (state.payment.ids?.[params.bar_id]) ? [...state.payment.ids[params.bar_id], ...Object.keys(data)] : Object.keys(data),
                    },
                    total: {
                        ...state.payment.total,
                        [params.bar_id]: state.payment.total[params.bar_id] + 1
                    },
                    form: {
                        ...state.payment.form,
                        [params.bar_id]: {}
                    }
                }
            };
        }

        case types.SUBMIT_ENHANCEMENT_PAYMENT_FAILURE: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isSubmitting: false,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: error
                    }
                }
            };
        }

        case types.CHANGE_ENHANCEMENT_PAYMENT_STATUS: {
            const paymentObj = {};
            const payments = state.payment.data?.[params.bar_id];
            Object.keys(payments).map((item) => {
                paymentObj[item] = { ...payments[item], status: payments[item]?.id == data ? 1 : 0 };
            })
            return {
                ...state,
                payment: {
                    ...state.payment,
                    data: {
                        ...state.payment.data,
                        [params.bar_id]: paymentObj
                    },
                }
            };
        }

        /** Delete payment module */

        case types.DELETE_ENHANCEMENT_PAYMENT_PENDING: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isDeleting: true,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: {}
                    },
                }
            };
        }

        case types.DELETE_ENHANCEMENT_PAYMENT_SUCCESS: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isDeleting: false,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: {}
                    },
                    data: {
                        ...state.payment.data,
                        [params.bar_id]: {
                            ...state.payment.data[params.bar_id],
                            [params.id]: {}
                        }
                    },
                    ids: {
                        ...state.payment.ids,
                        [params.bar_id]: (state.payment.ids?.[params.bar_id] || []).filter(i => i != params.id)
                    },
                    total: {
                        ...state.payment.total,
                        [params.bar_id]: (state.payment.total?.[params.bar_id] || 0) - 1
                    },
                }
            };
        }

        case types.DELETE_ENHANCEMENT_PAYMENT_FAILURE: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isDeleting: false,
                    error: {
                        ...state.payment.error,
                        [params.bar_id]: {}
                    },
                }
            };
        }

        /** Fetch bar orders */

        case types.FETCH_ENHANCEMENT_ORDER_PENDING: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isFetching: true
                }
            };
        }

        case types.FETCH_ENHANCEMENT_ORDER_SUCCESS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isFetching: false,
                    data: {
                        ...state.order.data,
                        [params.bar_id]: {
                            ...state.order.data[params.bar_id],
                            [params.type]: data.data
                        }
                    },
                    ids: {
                        ...state.order.ids,
                        [params.bar_id]: {
                            ...state.order.ids[params.bar_id],
                            [params.type]: sortable(data.data),
                        }
                    },
                    total: {
                        ...state.order.total,
                        [params.bar_id]: {
                            ...state.order.total[params.bar_id],
                            [params.type]: data.total
                        }
                    },
                    page: {
                        ...state.order.page,
                        [params.bar_id]: {
                            ...state.order.page[params.bar_id],
                            [params.type]: data.page
                        }
                    },
                    isMore: {
                        ...state.order.isMore,
                        [params.bar_id]: {
                            ...state.order.isMore[params.bar_id],
                            [params.type]: data.is_more
                        }
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_ORDER_MORE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isFetching: false,
                    data: {
                        ...state.order.data,
                        [params.bar_id]: {
                            ...state.order.data[params.bar_id],
                            [params.type]: (state.order.data?.[params.bar_id]?.[params.type]) ? { ...state.order.data[params.bar_id][params.type], ...data.data } : data.data
                        }
                    },
                    ids: {
                        ...state.order.ids,
                        [params.bar_id]: {
                            ...state.order.ids[params.bar_id],
                            [params.type]: (state.order.ids?.[params.bar_id]?.[params.type]) ? [...state.order.ids[params.bar_id][params.type], ...sortable(data.data)] : sortable(data.data),
                        }
                    },
                    total: {
                        ...state.order.total,
                        [params.bar_id]: {
                            ...state.order.total[params.bar_id],
                            [params.type]: data.total
                        }
                    },
                    page: {
                        ...state.order.page,
                        [params.bar_id]: {
                            ...state.order.page[params.bar_id],
                            [params.type]: data.page
                        }
                    },
                    isMore: {
                        ...state.order.isMore,
                        [params.bar_id]: {
                            ...state.order.isMore[params.bar_id],
                            [params.type]: data.is_more
                        }
                    }

                }
            };
        }

        case types.FETCH_ENHANCEMENT_ORDER_FAILURE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isFetching: false,
                    error: {
                        ...state.order.error,
                        [params.bar_id]: error
                    }

                }
            };
        }

        case types.CHANGE_ENHANCEMENT_ORDER_STATUS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    data: {
                        ...state.order.data,
                        [params.bar_id]: {
                            ...state.order.data[params.bar_id],
                            [params.type]: {
                                ...state.order.data[params.bar_id][params.type],
                                [params.id]: {
                                    ...state.order.data[params.bar_id][params.type][params.id],
                                    ...params

                                }
                            }
                        }
                    },

                }
            };
        }

        case types.CHANGE_ENHANCEMENT_ORDER_STATUS_PENDING: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isSubmitting: true
                }
            };
        }

        case types.CHANGE_ENHANCEMENT_ORDER_STATUS_SUCCESS: {
            return {
                ...state,
                order: {
                    ...state.order,
                    isSubmitting: false
                }
            };
        }

        /** nightly totals */
        case types.FETCH_NNIGHTLY_TOTALS: {
            return {
                ...state,
                nightly: {
                    ...state.nightly,
                    [data.bar_id]: {
                        ...state.nightly[data.bar_id],
                        ...data
                    }
                }
            };
        }

        default: {
            return state;
        }
    }
};
