import React, { Component } from "react";

class Restaurantsuggested extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="mb-3 bg-white sticky top-24 shadow-sm rounded-lg ">
        <div className="p-3">
          <h4>Suggested for You</h4>
          <p className="darkGray fs-13">
            Restaurants you might be interested in.
          </p>
        </div>
        {/* <div>
          <Slider
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            speed={500}
            nextArrow={false}
            prevArrow={false}
          >
            <div className="p-4">
              <Restaurantscard />
            </div>
            <div className="p-4">
              <Restaurantscard />
            </div>
          </Slider>
        </div> */}
      </div>
    );
  }
}

export default Restaurantsuggested;
