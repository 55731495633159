import React, { Component } from "react";
import { Link } from "react-router-dom";
import colors from "config/color/color.js";
import { BsDot, BsDash, BsMusicNoteBeamed } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FaStar, FaHeart } from "react-icons/fa";
import { RiCupFill } from "react-icons/ri";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Dropdown } from "semantic-ui-react";
import { ImGlass } from "react-icons/im";
import { FaWineBottle } from "react-icons/fa";
import { IoFastFood } from "react-icons/io5";
import { MinMaxAge } from "helpers";
import { connect } from "react-redux";
import { Config } from "common";
import { Avatar } from "@material-ui/core";
import wine from "assets/images/vectors/wine.png";
class Barcard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logoRef = React.createRef();
    this.bannerRef = React.createRef();
  }
  addFavourite = () => {
    if (this.props.item.is_favourite) {
      this.props.item.is_favourite = false;
      this.props.removeBarFavourite(this.props.item);
    } else {
      this.props.item.is_favourite = true;
      this.props.addBarFavourite(this.props.item);
    }
    this.forceUpdate();
  };
  render() {
    const { item } = this.props;
    const favoriteColor = !this.props.item.is_favourite
      ? colors.gray
      : colors.primary;

    let banner =
      "https://images.all-free-download.com/images/graphiclarge/bar_wines_514430.jpg";
    let bannerObject = Object.assign({}, this.props.item.banner);
    if (bannerObject.attachment_url) {
      banner = Config.lifeWidget.url + "/" + bannerObject.attachment_url;
    }

    return (
      <div className="block h-full overflow-hidden bg-white shadow-md rounded-xl">
        <div className="relative">
          <Link
            to={{
              pathname: `/bars/bar-details/${item.id}`,
              state: { item: item },
            }}
            className="flex items-center black Medium">
            <Avatar
              variant="square"
              src={banner}
              style={{ height: 180 }}
              className="object-contain rounded-t-xl h-52 important:w-full">
              <Avatar
                variant="square"
                src={wine}
                style={{ height: 180 }}
                className="object-cover opacity-30 important:w-full"
              />
            </Avatar>
          </Link>
          <div className="absolute top-0 z-10 flex items-center justify-between w-full p-3">
            <div className="flex items-center">
              <div className="cursor-pointer">
                {this.props.item.user_id === this.props.user.id && (
                  <Dropdown
                    icon={false}
                    wrapSelection={true}
                    direction="right"
                    pointing="top left"
                    text={
                      <div>
                        <div className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer hover:bg-gray-300">
                          <BiDotsVerticalRounded size={22} />
                        </div>
                      </div>
                    }>
                    <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-96">
                      <div className="p-2">
                        <Link
                          to={{
                            pathname: `/my_company_details/logo_banner/${this.props.item.id}`,
                            state: { id: item.id },
                          }}
                          className="flex items-center black Medium">
                          <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                            <div className="mr-3">
                              <FaRegEdit size={20} />
                            </div>
                            <p>Edit</p>
                          </div>
                        </Link>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div>
                {this.props.item.live && (
                  <p className="w-24 -ml-4 text-center rounded-r bg--primary white">
                    Live Music
                  </p>
                )}
              </div>
            </div>
            <div className="cursor-pointer" onClick={this.addFavourite}>
              <FaHeart color={favoriteColor} size={22} />
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="flex items-center justify-between">
            <Link
              to={{
                pathname: `/bars/bar-details/${item.id}`,
                state: { item: item },
              }}
              className="flex items-center black Medium">
              {this.props.item.name}
              {this.props.item.city && (
                <>
                  <BsDash color={colors.gray} />
                  {this.props.item.city}
                </>
              )}
            </Link>
            <div className="flex items-center gap-x-1">
              <FaStar color={colors.primary} />
              <p className="text-sm">3</p>
              <p className="text-sm darkGray">(22k)</p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex-grow ">
              <div className="flex items-center">
                {this.props.item.checkins.length > 0 && (
                  <p className="flex items-center ml-1 text-sm darkGray">
                    {MinMaxAge(this.props.item.checkins)}
                  </p>
                )}
                {this.props.item.opposite_rate && (
                  <>
                    <BsDot style={{ color: colors.darkGray }} />
                    <p className="flex items-center ml-1 text-sm darkGray">
                      {this.props.item.opposite_rate}
                    </p>
                  </>
                )}
              </div>
              <div className="flex items-center">
                <p className="text-sm darkGray">Distance:</p>
                <p className="ml-1 text-sm darkGray">
                  {parseFloat(this.props.item.distance).toFixed(2)}{" "}
                </p>
              </div>
            </div>
            <div className="flex items-center ">
              {this.props.item.bar_services.length > 0 && (
                <div className="flex items-center ">
                  {this.props.item.bar_services.map((item, key) => (
                    <>
                      {/* <p>{item.service_code}</p> */}
                      {item.service_code === "hamburger" ? (
                        <IoFastFood color={colors.primary} />
                      ) : null}
                      {item.service_code === "wine-bottle" ? (
                        <>
                          <BsDot style={{ color: colors.gray }} />
                          <FaWineBottle color={colors.primary} />
                        </>
                      ) : null}
                      {item.service_code === "wine-glass-alt" ? (
                        <>
                          <BsDot style={{ color: colors.gray }} />
                          <ImGlass color={colors.primary} />
                        </>
                      ) : null}
                      {item.service_code === "music" ? (
                        <>
                          <BsDot style={{ color: colors.gray }} />
                          <BsMusicNoteBeamed color={colors.primary} />
                        </>
                      ) : null}
                      {item.service_code === "female" ? (
                        <>
                          <BsDot style={{ color: colors.gray }} />
                          <RiCupFill color={colors.primary} />
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/BarRedux");
  return {
    ...ownProps,
    ...stateProps,
    addBarFavourite: (item) => {
      actions.addBarFavourite(dispatch, item);
    },
    removeBarFavourite: (item) => {
      actions.removeBarFavourite(dispatch, item);
    },
  };
};
export default connect(undefined, undefined, mergeProps)(Barcard);
