import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import { FaUserFriends } from "react-icons/fa";
import { BsFillLockFill } from "react-icons/bs";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { GiEarthAsiaOceania } from "react-icons/gi";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class GeneralPrivacyStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPriavcy: false,
      privacy: 1,
      key: "",
    };
  }

  open = (key, privacy) => {
    this.setState({ key: key, privacy: privacy, selectPriavcy: true });
  };

  close = () => {
    this.setState({ selectPriavcy: false });
  };

  setGeneralPrivacy = (privacy) => {
    let { key } = this.state;
    this.props.setProfileData(key, privacy);
    this.setState({ privacy });
    this.close();
  };

  render() {
    const { privacy } = this.state;
    return (
      <>
        <Dialog
          open={this.state.selectPriavcy}
          onClose={this.close}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            {" "}
            <div className="flex items-center justify-center pb-3 border-b">
              <p className="text-2xl Medium">Select Audience</p>
              <div
                onClick={this.close}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="p-2">
              <div
                onClick={() => this.setGeneralPrivacy(1)}
                className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-16 h-16 mr-3 bg-gray-100 rounded-full">
                    <GiEarthAsiaOceania color={colors.black} size={28} />
                  </div>
                  <div>
                    <p className="text-xl">Public</p>
                    <p className="gray Light fs-14">
                      Anyone on or off LifeWidgets
                    </p>
                  </div>
                </div>

                <FormControlLabel
                  control={
                    <Radio
                      checked={privacy === 1 ? true : false}
                      color="primary"
                    />
                  }
                />
              </div>
              <div
                onClick={() => this.setGeneralPrivacy(2)}
                className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-16 h-16 mr-3 bg-gray-100 rounded-full">
                    <FaUserFriends color={colors.black} size={28} />
                  </div>
                  <div>
                    <p className="text-xl">Friends</p>
                    <p className="gray Light fs-14">
                      Your friends on LifeWidgets
                    </p>
                  </div>
                </div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={privacy === 2 ? true : false}
                      color="primary"
                    />
                  }
                />
              </div>
              <div
                onClick={() => this.setGeneralPrivacy(3)}
                className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-16 h-16 mr-3 bg-gray-100 rounded-full">
                    <BsFillLockFill color={colors.black} size={28} />
                  </div>
                  <p className="text-xl">Only me</p>
                </div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={privacy === 3 ? true : false}
                      color="primary"
                    />
                  }
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default GeneralPrivacyStatus;
