import React, { Component } from "react";
import { Image, Form } from "react-bootstrap";
import styles from "./styles.js";
import Restaurant from "assets/images/Restaurant.webp";
import { Groupcommentshierarchy } from "components";

class Grouppostcomment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="border-t">
        <div className="mt-2">
          <div className="flex items-center pb-2 mt-1">
            <Image
              src={Restaurant}
              className="object-cover w-12 h-12 border rounded-full"
            />
            <Form className="flex-grow ml-2">
              <Form.Control
                rows={2}
                style={styles.textInput}
                placeholder="Write a comment"
                className="px-3 py-0 pt-2 shadow-none resize-none textInput"
                as="textarea"
              />
            </Form>
          </div>
          <div className="pb-3">
            <Groupcommentshierarchy />
          </div>
        </div>
      </div>
    );
  }
}

export default Grouppostcomment;
