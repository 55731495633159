import React, { Component } from "react";
import colors from "config/color/color.js";
import { NavLink, Redirect } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { FaRegCalendarPlus } from "react-icons/fa";
import { GiShakingHands } from "react-icons/gi";
import { MdNearMe } from "react-icons/md";
import {
  HiOutlineArrowLeft,
  HiPresentationChartBar,
  HiClipboardList,
} from "react-icons/hi";
import { IoCalendarOutline } from "react-icons/io5";
import { BiCalendarHeart } from "react-icons/bi";
import { Buttonused, Createeventpopup } from "components";
import { connect } from "react-redux";

class LiveMusicAside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
    };
    this.createEventRef = React.createRef();
  }
  componentDidMount() {}
  addPhotos = async () => {
    // const { status } = await ImagePicker.requestCameraPermissionsAsync();

    // if (status !== "granted") {
    //   alert("Permission to access camera roll is required!");
    //   return;
    // }
    let photos = Object.assign([], this.props.form.photos);

    // if (photos.length >= 10) {
    //   Alert.alert("Photos limit", "You have to upload upto 10 pictures", [
    //     { text: "OK", onPress: () => console.log("OK Pressed") },
    //   ]);
    //   return;
    // }

    // let pickerResult = await ImagePicker.launchImageLibraryAsync({
    //   mediaTypes: ImagePicker.MediaTypeOptions.Images,
    // });
    // if (!pickerResult.cancelled) {
    //   photos.push(pickerResult);
    // }
    this.addEventForm("photos", photos);
  };
  removePhoto = (index) => {
    let photos = [...this.props.form.photos];
    photos.splice(index, 1);
    this.addEventForm("photos", photos);
  };
  removeTime = () => {
    this.addEventForm("start_time", "");
    this.addEventForm("end_time", "");
  };
  addEventForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addEventForm(form);
  };
  validation = () => {
    let flag = true;
    let array = [
      "name",
      "lat_lng",
      "start_date",
      "end_date",
      // "photos"
    ];
    let form = Object.assign({}, this.props.form);
    // array.map((item, key) => {
    //   if (!Object.keys(form).some((index) => index === item)) {
    //     this.setState({ [item]: true });
    //     flag = false;
    //   }
    // });
    Object.keys(form).map((item) => {
      if (array.some((index) => index === item)) {
        if (
          form[item] === null ||
          form[item] === "null" ||
          form[item] === "" ||
          form[item].length === 0
        ) {
          this.setState({ [item]: true });
          flag = false;
        } else {
          this.setState({ [item]: false });
        }
      }
    });

    let formData = new FormData();

    if (flag) {
      let form = this.props.form;
      Object.keys(form).map(function (key) {
        if (key === "photos" && form[key]) {
          form[key].forEach((item, i) => {
            // var uri =
            // Platform.OS === "android" ? item.uri : item.uri.replace("", "");
            formData.append("photos[" + i + "]", item);
          });
        } else if (key === "lat_lng" && form[key]) {
          formData.append("latitude", form[key].lat);
          formData.append("longitude", form[key].lng);
        } else {
          formData.append(key, form[key]);
        }

        if (key === "friends" && form[key]) {
          form[key].forEach((item, i) => {
            formData.append("friends[" + i + "]", item);
          });
        }

        if (key === "groups" && form[key]) {
          form[key].forEach((item, i) => {
            formData.append("groups[" + i + "]", item.id);
          });
        }
      });

      this.props.submitEvent(formData);
      this.setState({ flag: true });
    }
  };

  render() {
    if (this.state.flag) {
      return <Redirect to={"/events/my_events"} />;
    }
    return (
      <div className="fixed flex flex-col p-6 bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow ">
          <div className="flex items-center">
            <NavLink
              to="/"
              className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
              <HiOutlineArrowLeft
                size={18}
                style={{ color: colors.darkGray }}
              />
            </NavLink>
            <div>
              <NavLink to="/" className="text-sm darkGray hover:underline">
                Main Menu
              </NavLink>
              <h2 className="text-2xl font-medium Medium">Live Music</h2>
            </div>
          </div>
          <div className="my-2 border-t" />
          <div className="mt-3 -mx-2">
            <NavLink
              exact
              to="/events/nearme"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <MdNearMe size={20} style={{ color: colors.white }} />
              </div>
              Near Me
            </NavLink>
            <NavLink
              exact
              to="/events/calendar"
              className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <IoCalendarOutline
                    size={20}
                    style={{ color: colors.white }}
                  />
                </div>
                Calendar
              </div>
              <FiChevronRight
                size={24}
                style={{ color: colors.darkGray }}
                className="svg"
              />
            </NavLink>
            <NavLink
              exact
              to="/events/going"
              className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary fill:primary-svg"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <HiPresentationChartBar
                    size={20}
                    style={{ color: colors.white }}
                  />
                </div>
                Going
              </div>
              <FiChevronRight
                size={24}
                style={{ color: colors.darkGray }}
                className="svg"
              />
            </NavLink>
            <NavLink
              exact
              to="/events/pending"
              className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary fill:primary-svg"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <HiClipboardList size={20} style={{ color: colors.white }} />
                </div>
                Pending
              </div>
              <FiChevronRight
                size={24}
                style={{ color: colors.darkGray }}
                className="svg"
              />
            </NavLink>

            <NavLink
              exact
              to="/events/interested"
              className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary fill:primary-svg"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <GiShakingHands size={20} style={{ color: colors.white }} />
                </div>
                Interested In
              </div>
              <FiChevronRight
                size={24}
                style={{ color: colors.darkGray }}
                className="svg"
              />
            </NavLink>
            <NavLink
              exact
              to="/events/my_events"
              className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary fill:primary-svg"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <BiCalendarHeart size={18} style={{ color: colors.white }} />
                </div>
                My Events
              </div>
              <FiChevronRight
                size={24}
                style={{ color: colors.darkGray }}
                className="svg"
              />
            </NavLink>
          </div>
        </div>
        <div className="my-3 -mx-5 divider" />
        <Buttonused
          Icon={<FaRegCalendarPlus className="mr-2" />}
          Text="Create Event"
          className="flex items-center justify-center w-full bg--lightPrimary h-11 primary rounded-xl"
          onClick={() => this.createEventRef.current.eventOpen()}
        />
        <Createeventpopup
          validation={this.validation}
          eventForm={this.addEventForm}
          form={this.props.form}
          removeTime={this.removeTime}
          ref={this.createEventRef}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    form: state.Event.form,
    isProcessing: state.Event.isProcessing,
    error: state.Event.error,
    message: state.Event.message,
    groupData: state.Group.data,
    selectedAddress: state.Event.selectedAddress,
    friends: state.People.friends,
    isFetching: state.People.isFriendFetching,
    total: state.People.totalFriends,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/EventRedux");
  const { actions: groupActions } = require("redux/GroupRedux");
  const { actions: friendsActions } = require("redux/PeopleRedux");
  return {
    ...ownProps,
    ...stateProps,
    addEventForm: (data) => {
      dispatch(actions.addEventForm(data));
    },
    submitEvent: (data) => {
      actions.submitEvent(dispatch, data);
    },
    fetchGroups: (per_page, page) => {
      groupActions.fetchGroups(dispatch, per_page, page);
    },
    fetchFriends: (per_page, page, params = []) => {
      friendsActions.fetchFriends(dispatch, per_page, page, params);
    },
    saveAddress: (data) => {
      dispatch(actions.saveAddress(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(LiveMusicAside);
