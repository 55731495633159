import React, { Component } from "react";
import { Aboutnavigation, Basicinfo } from "components";

class Contactandbasicinfo extends Component {
  render() {
    const { data } = this.props;
    return (
      <div>
        <div className="bg-white shadow-sm rounded-xl">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-0">
            <div className="border-r p-4">
              <Aboutnavigation data={data} />
            </div>

            <div className="col-span-2">
              {/* <div className="bg-gray-200 p-2.5 px-4 rounded-tr-xl">
                <p className="Medium text-xl text-center">Edit Profile</p>
              </div> */}
              <div className="mb-3 p-4">
                <Basicinfo data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contactandbasicinfo;
