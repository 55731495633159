import React, { Component } from "react";
import colors from "config/color/color.js";
import Slider from "react-slick";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { OptimizeImage } from "helpers";
import wine from "assets/images/vectors/wine.png";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <div
        className="flex items-center justify-center w-8 h-8 -ml-10 bg-white border rounded-full shadow-sm"
        onClick={onClick}>
        <FiChevronRight color={colors.darkGray} size={22} />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ zIndex: 1 }}>
      <div
        className="flex items-center justify-center w-8 h-8 bg-white border rounded-full shadow-sm ml-7"
        onClick={onClick}>
        <FiChevronLeft color={colors.darkGray} size={22} />
      </div>
    </div>
  );
}
class Restaurantsthumbnails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let banner = null;
    let logo = null;
    let bannerObject = Object.assign({}, this.props.item.banner);
    if (bannerObject.attachment_url) {
      banner = bannerObject;
    }

    let logoObject = Object.assign({}, this.props.item.logo);
    if (logoObject.attachment_url) {
      logo = OptimizeImage(logoObject.attachment_url);
    }

    let attaches = [];
    let photos = Object.assign([], this.props.item.photos);
    if (banner) {
      photos.unshift(banner);
    }
    for (var i = 0; i < photos.length; i++) {
      attaches[i] = {
        ...photos[i],
        url: OptimizeImage(photos[i].attachment_url, photos[i].type),
      };
    }
    return (
      <>
        {attaches.length > 0 ? (
          <Slider
            infinite={false}
            slidesToShow={1}
            slidesToScroll={1}
            speed={500}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}>
            <div className="overflow-hidden h-60">
              {attaches &&
                attaches.map((item, key) => (
                  <>
                    <img
                      src={item.url}
                      className="object-contain w-full h-full"
                    />
                  </>
                ))}
            </div>
            <div className="overflow-hidden h-60">
              {attaches &&
                attaches.map((item, key) => (
                  <img
                    src={item.url}
                    className="object-contain w-full h-full"
                  />
                ))}
            </div>
          </Slider>
        ) : (
          <div className="overflow-hidden h-60">
            <img
              src={wine}
              className="object-contain w-full h-full opacity-70"
            />
          </div>
        )}
      </>
    );
  }
}

export default Restaurantsthumbnails;
