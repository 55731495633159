import React, { Component } from "react";
import { Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import colors from "config/color/color";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

class Postjobdetail extends Component {
  render() {
    return (
      <div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <p className="mb-1 fs-16 Medium">Which type of employment is it?</p>
          <RadioGroup>
            <FormControlLabel
              value="Full"
              control={<Radio />}
              label="Full-time"
              className="mt-2 border important:Regular-all rounded-xl"
            />
            <FormControlLabel
              value="part"
              control={<Radio />}
              label="Part-time"
              className="mt-2 border important:Regular-all rounded-xl"
            />
            <FormControlLabel
              value="Either"
              control={<Radio />}
              label="Either full-time or part-time"
              className="mt-2 border important:Regular-all rounded-xl"
            />
          </RadioGroup>
          <div className="mt-5">
            <p className="mb-1 fs-16 Medium">What contract type is it?</p>
            <RadioGroup>
              <FormControlLabel
                value="Temporary"
                control={<Radio />}
                label="Temporary"
                className="mt-2 border important:Regular-all rounded-xl"
              />
              <FormControlLabel
                value="Contract"
                control={<Radio />}
                label="Contract"
                className="mt-2 border important:Regular-all rounded-xl"
              />
              <FormControlLabel
                value="Internship"
                control={<Radio />}
                label="Internship"
                className="mt-2 border important:Regular-all rounded-xl"
              />
              <FormControlLabel
                value="Fresher"
                control={<Radio />}
                label="Fresher"
                className="mt-2 border important:Regular-all rounded-xl"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <div className="mb-2">
            <p className="fs-16 Medium">
              How many hires do you want to make for this position?
            </p>
            <p className="darkGray fs-13">
              More hires will require more candidates.
            </p>
          </div>
          <Dropdown
            className="border gray rounded--lg"
            placeholder="No. of positions"
            fluid
            selection
            options={[
              {
                key: "1",
                text: "1",
                value: "1",
              },
              {
                key: "2",
                text: "2",
                value: "2",
              },
              {
                key: "3",
                text: "3",
                value: "3",
              },
              {
                key: "4",
                text: "4",
                value: "4",
              },
              {
                key: "5",
                text: "5",
                value: "5",
              },
              {
                key: "6",
                text: "6",
                value: "6",
              },
              {
                key: "7",
                text: "7",
                value: "7",
              },
              {
                key: "8",
                text: "8",
                value: "8",
              },
              {
                key: "9",
                text: "9",
                value: "9",
              },
              {
                key: "10 + hires",
                text: "10 + hires",
                value: "10 + hires",
              },
            ]}
          />
          <div className="mt-5">
            <div className="mb-2">
              <p className="fs-16 Medium">
                How urgently do you need to make a hire?
              </p>
              <p className="darkGray fs-13">
                Help us help you hit your deadline.
              </p>
            </div>
            <Dropdown
              className="border gray rounded--lg"
              placeholder="No. of positions"
              fluid
              selection
              options={[
                {
                  key: "Less then week",
                  text: "Less then week",
                  value: "Less then week",
                },
                {
                  key: "1 to 2 weeks",
                  text: "1 to 2 weeks",
                  value: "1 to 2 weeks",
                },
                {
                  key: "More than 3 weeks",
                  text: "More than 3 weeks",
                  value: "More than 3 weeks",
                },
              ]}
            />
          </div>
        </div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <div className="mb-2">
            <p className="fs-16 Medium">What is the pay for this job?</p>
            <p className="darkGray fs-13">Compensation</p>
          </div>
          <div className="grid grid-cols-3 gap-2 mb-3">
            <Dropdown
              className="border gray rounded--xl"
              placeholder="Select"
              fluid
              selection
              options={[
                {
                  key: "Range",
                  text: "Range",
                  value: "Range",
                },
                {
                  key: "Starting at",
                  text: "Starting at",
                  value: "Starting at",
                },
                {
                  key: "Up to",
                  text: "Up to",
                  value: "Up to",
                },
                {
                  key: "Exact rate",
                  text: "Exact rate",
                  value: "Exact rate",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div className="flex items-center pl-3 border rounded--xl">
              <p>PKR</p>
              <Form.Control
                placeholder="Example: 15.00"
                className="h-12 border-0 shadow-none rounded--xl"
              />
            </div>
            <div className="flex items-center">
              <p className="mr-2">to</p>
              <div className="flex items-center pl-3 border rounded--xl">
                <p>PKR</p>
                <Form.Control
                  placeholder="Example: 15.00"
                  className="h-12 border-0 shadow-none rounded--xl"
                />
              </div>
            </div>
            <div className="w-52">
              <Dropdown
                className="border gray rounded--xl"
                placeholder="Select"
                fluid
                selection
                options={[
                  {
                    key: "per hour",
                    text: "per hour",
                    value: "per hour",
                  },
                  {
                    key: "per day",
                    text: "per day",
                    value: "per day",
                  },
                  {
                    key: "per week",
                    text: "per week",
                    value: "per week",
                  },
                  {
                    key: "per month",
                    text: "per month",
                    value: "per month",
                  },
                  {
                    key: "per year",
                    text: "per year",
                    value: "per year",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <Link
            to="/post_job/getting_started"
            style={{ color: colors.black }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black Regular rounded--xl hover:scale-90">
            Back
          </Link>
          <Link
            to="/post_job/job_description"
            style={{ color: colors.white }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary hover:opacity-80 focus:bg--primary white Regular rounded--xl hover:scale-90">
            Save and continue
          </Link>
        </div>
      </div>
    );
  }
}

export default Postjobdetail;
