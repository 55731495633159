import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";

class Barskeleton extends Component {
  render() {
    return (
      <div className="px-3 pb-0 overflow-hidden bg-white rounded-lg shadow-sm">
        <div className="-mx-4">
          <Skeleton variant="rect" height={170} />
        </div>
        <div className="items-center my-3">
          <div className="flex items-center justify-between flex-grow">
            <div>
              <Skeleton
                animation="pulse"
                variant="rect"
                height={8}
                className="rounded-full"
                width={200}
              />
            </div>
            <div className="ml-3">
              <Skeleton
                animation="pulse"
                variant="rect"
                height={8}
                className="rounded-full"
                width={40}
              />
            </div>
          </div>
          <div className="flex items-center justify-between flex-grow mt-2">
            <div>
              <Skeleton
                variant="rect"
                height={8}
                className="rounded-full"
                width={150}
              />
              <Skeleton
                variant="rect"
                height={8}
                className="mt-2 rounded-full"
                width={100}
              />
            </div>
            <div className="flex items-center justify-between gap-x-2">
              <Skeleton
                variant="rect"
                height={8}
                className="rounded-full"
                width={20}
              />
              <Skeleton
                variant="rect"
                height={8}
                className="rounded-full"
                width={20}
              />
              <Skeleton
                animation="pulse"
                variant="rect"
                height={8}
                className="rounded-full"
                width={20}
              />
              <Skeleton
                animation="pulse"
                variant="rect"
                height={8}
                className="rounded-full"
                width={20}
              />
              <Skeleton
                animation="pulse"
                variant="rect"
                height={8}
                className="rounded-full"
                width={20}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Barskeleton;
