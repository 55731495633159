import React from "react";
import "./styles.css";

export default class Compose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageText: "",
    };
  }

  changeMessage = (event) => this.setState({ messageText: event.target.value });

  sendMessage = (e) => {
    e.preventDefault();
    this.props
      .sendMessageCallback(this.state.messageText)
      .then(() => this.setState({ messageText: "" }))
      .catch(() => this.setState({ messageText: "" }));
  };

  getImage = (image) => {
    this.props
      .sendMessageCallback(this.state.messageText, image)
      .then(() => this.setState({ messageText: "" }))
      .catch(() => this.setState({ messageText: "" }));
  };

  render() {
    const { messageText } = this.state;
    return (
      <form onSubmit={this.sendMessage}>
        <div className="compose">
          <input
            type="text"
            className="compose-input"
            placeholder="Type a message"
            value={messageText}
            onChange={this.changeMessage}
          />
          {this.props.rightItems}
        </div>
      </form>
    );
  }
}
