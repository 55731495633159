import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import {
  AiOutlineZoomOut,
  AiOutlineZoomIn,
  AiOutlineShareAlt,
} from "react-icons/ai";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Image } from "react-bootstrap";
import logo from "assets/images/logo.webp";
import Slider from "react-slick";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Navbar, Button } from "react-bootstrap";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <div
        className="bg-gray-400 shadow-sm h-12 w-12 flex items-center justify-center rounded-full -ml-20"
        onClick={onClick}
      >
        <FiChevronRight color={colors.black} size={22} />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ zIndex: 1 }}>
      <div
        className="bg-gray-400 shadow-sm h-12 w-12 flex items-center justify-center rounded-full ml-10"
        onClick={onClick}
      >
        <FiChevronLeft color={colors.black} size={22} />
      </div>
    </div>
  );
}

class Shareuspopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUs: false,
      zoomIn: false,
      scaleXoom: 1,
      activeSlide: 0,
    };
  }

  shareUsOpen = () => {
    this.setState({ shareUs: true });
  };

  shareUsClose = () => {
    this.setState({ shareUs: false });
  };

  zoomIn = () => {
    this.setState({ scaleXoom: this.state.scaleXoom + 0.5 });
  };

  zoomOut = () => {
    this.setState({ scaleXoom: this.state.scaleXoom - 0.5 });
  };

  render() {
    const settings = {
      beforeChange: next => this.setState({ activeSlide: next }),
    };
    return (
      <>
        <Dialog
          fullScreen
          open={this.state.shareUs}
          onClose={this.shareUsClose}
          className="custom-backdrop  rounded-0 fullscreenpopup"
          direction="up"
        >
          <DialogContent className="p-0">
            <div className="h-screen overflow-hidden">
              <div className="bg--fullBlack h-screen relative">
                <div className="px-3 py-2 flex items-center justify-between absolute w-full z-10">
                  <div className="flex items-center justify-center">
                    <div
                      onClick={this.shareUsClose}
                      className="cursor-pointer h-10 w-10 flex items-center justify-center"
                    >
                      <FiX size={24} style={{ color: colors.white }} />
                    </div>
                    <Navbar.Brand href="/" className="p-0 ml-4">
                      <Image
                        className="h-11 w-11"
                        src={logo}
                        alt="logo"
                        roundedCircle
                      />
                    </Navbar.Brand>
                  </div>
                  <div className="flex items-center">
                    <div className="cursor-pointer  h-10 mr-5 flex items-center justify-center">
                      <p className="white ml-2">
                        {this.state.activeSlide + 1} /{this.props.item.length}
                      </p>
                    </div>
                    <Button
                      onClick={this.zoomIn}
                      className={`h-10 w-10 flex items-center justify-center bg-transparent border-0 p-0 shadow-none ${
                        this.state.scaleXoom === 2 ? "disabled " : null
                      }`}
                    >
                      <AiOutlineZoomIn
                        size={24}
                        style={{ color: colors.white }}
                      />
                    </Button>
                    <Button
                      onClick={this.zoomOut}
                      className={`h-10 w-10 flex items-center justify-center mx-3 bg-transparent border-0 p-0 shadow-none ${
                        this.state.scaleXoom === 1 ? "disabled " : null
                      }`}
                    >
                      <AiOutlineZoomOut
                        size={24}
                        style={{ color: colors.white }}
                      />
                    </Button>
                    <div className="cursor-pointer bg--primary rounded-lg h-10 w-32 flex items-center justify-center">
                      <AiOutlineShareAlt
                        size={22}
                        style={{ color: colors.white }}
                      />
                      <p className="fs-13 white ml-2">SHARE</p>
                    </div>
                  </div>
                </div>
                <Slider
                  {...settings}
                  infinite={false}
                  slidesToShow={1}
                  slidesToScroll={1}
                  speed={500}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                >
                  {this.props.item.map((item, index) => (
                    <div className="h-full">
                      <Image
                        className="h-screen m-auto object-contain"
                        style={{
                          transform: `scale(${this.state.scaleXoom})`,
                        }}
                        src={item.src}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default Shareuspopup;
