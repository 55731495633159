import React, { Component } from "react";
import { NavDropdown, Image, Form } from "react-bootstrap";
import "./styles.css";
import colors from "config/color/color.js";
import { BsDot } from "react-icons/bs";
import { Popup } from "semantic-ui-react";
import avatar from "assets/images/avatar.webp";
import avatar2 from "assets/images/avatar.webp";
import { BsThreeDots } from "react-icons/bs";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
class commentshierarchy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likeColor: false,
      reply: false,
    };
  }
  render() {
    return (
      <div className="flex mt-2">
        <Image
          src={avatar2}
          className="object-cover w-12 h-12 mr-2 border rounded-full"
        />
        <div className="flex-grow">
          <div className="flex-grow comment-box-container hover_show_box">
            <div className="relative inline-block px-3 py-2 comment-box rounded-2xl">
              <p className="Medium fs-13">John Doe</p>
              <p className="fs-13">
                Pellentesque in ipsum id sssorci porta dapibus.
              </p>
              <div className="hover_show_drpdwn -right-10 top-3">
                <NavDropdown
                  title={
                    <div className="flex items-center justify-center w-8 h-8 m-auto hover_dots_drpdwn">
                      <BsThreeDots
                        size={18}
                        style={{ color: colors.darkGray }}
                      />
                    </div>
                  }
                  className="bg-transparent custom-dropdown">
                  <div className="absolute right-0 z-10 w-screen max-w-sm mt-2 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative p-2 bg-white">
                        <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                          <p>Hide comment</p>
                        </div>
                        <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                          <p>Give feedback or report this comment</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavDropdown>
              </div>
            </div>

            <div className="flex items-center mt-1 ml-3">
              <p
                className="text-sm cursor-pointer darkGray"
                style={{
                  color: this.state.likeColor
                    ? colors.primary
                    : colors.darkGray,
                }}
                onClick={() =>
                  this.setState({ likeColor: !this.state.likeColor })
                }>
                Like
              </p>
              <BsDot size={10} className="darkGray" />
              <p
                className="text-sm cursor-pointer darkGray"
                onClick={() => this.setState({ reply: !this.state.reply })}>
                Reply
              </p>
              <BsDot size={10} className="darkGray" />
              <Popup
                inverted
                className="p-2"
                position="bottom center"
                content={
                  <p className="text-sm cursor-pointer white Light">
                    Moday, July 19, 2021 at 2:20pm
                  </p>
                }
                trigger={
                  <p className="text-sm cursor-pointer darkGray Light">6h</p>
                }
              />
            </div>
          </div>
          <div className="flex mt-1">
            <Image
              src={avatar}
              className="object-cover w-8 h-8 mr-2 border rounded-full"
            />
            <div className="flex-grow">
              <div className="flex-grow comment-box-container hover_show_box">
                <div className="relative inline-block px-3 py-2 comment-box rounded-2xl">
                  <p className="Medium fs-13">Sam Cali</p>
                  <p className="fs-13">
                    Pellentesque in ipsum id sssorci porta dapibus.
                  </p>
                  <div className="hover_show_drpdwn -right-10 top-3">
                    <NavDropdown
                      title={
                        <div className="flex items-center justify-center w-8 h-8 m-auto hover_dots_drpdwn">
                          <BsThreeDots
                            size={18}
                            style={{ color: colors.darkGray }}
                          />
                        </div>
                      }
                      className="bg-transparent custom-dropdown">
                      <div className="absolute right-0 z-10 w-screen max-w-sm mt-2 sm:px-0 ">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative p-2 bg-white">
                            <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                              <p>Hide comment</p>
                            </div>
                            <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                              <p>Give feedback or report this comment</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavDropdown>
                  </div>
                </div>
                <div className="flex items-center mt-1 ml-3">
                  <p
                    className="text-sm cursor-pointer darkGray"
                    style={{
                      color: this.state.likeColor
                        ? colors.primary
                        : colors.darkGray,
                    }}
                    onClick={() =>
                      this.setState({ likeColor: !this.state.likeColor })
                    }>
                    Like
                  </p>
                  <BsDot size={10} className="darkGray" />
                  <p
                    className="text-sm cursor-pointer darkGray"
                    onClick={() => this.setState({ reply: !this.state.reply })}>
                    Reply
                  </p>
                  <BsDot size={10} className="darkGray" />
                  <Popup
                    inverted
                    className="p-2"
                    position="bottom center"
                    content={
                      <p className="text-sm cursor-pointer white Light">
                        Moday, July 19, 2021 at 2:20pm
                      </p>
                    }
                    trigger={
                      <p className="text-sm cursor-pointer darkGray Light">
                        6h
                      </p>
                    }
                  />
                </div>
              </div>
              {this.state.reply ? (
                <div className="flex items-center pb-2 mt-1">
                  <Image
                    src={avatar2}
                    className="object-cover w-8 h-8 border rounded-full"
                  />
                  <Form className="flex-grow ml-2">
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Write a comment..."
                      className="w-full p-3 shadow-none resize-none post-commnet-textInput bg--lightGray"
                    />
                  </Form>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default commentshierarchy;
