import React, { Component } from "react";
import colors from "config/color/color.js";
import { FaUserFriends } from "react-icons/fa";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { Dropdown, Select } from "semantic-ui-react";
import { GeneralPrivacyStatus } from "components";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { connect } from "react-redux";
import { CgLock } from "react-icons/cg";
class Basicinfo extends Component {
  constructor(props) {
    super(props);
    this.state = { gender: false, birthday: false, phone: false };
    this.selectPrivacyRef = React.createRef();
  }

  setProfileData = (key, value) => {
    let form = {};
    form[key] = value;
    this.props.userProfileUpdate(form);
    clearTimeout(this.delayTyping);
    this.delayTyping = setTimeout(() => {
      this.props.sendRequestToServer(form);
    }, 2000);
  };

  privacyStatusIcon = (privacy) => {
    if (privacy === 2) {
      return <FaUserFriends size={20} style={{ color: colors.darkGray }} />;
    } else if (privacy === 3) {
      return <CgLock size={20} style={{ color: colors.darkGray }} />;
    }
    return <GiEarthAsiaOceania size={20} style={{ color: colors.darkGray }} />;
  };

  render() {
    const { data } = this.props;
    const countryOptions = [
      { text: "Afghanistan", value: "AF" },
      { text: "Åland Islands", value: "AX" },
      { text: "Albania", value: "AL" },
      { text: "Algeria", value: "DZ" },
      { text: "American Samoa", value: "AS" },
      { text: "Andorra", value: "AD" },
      { text: "Angola", value: "AO" },
      { text: "Anguilla", value: "AI" },
      { text: "Antarctica", value: "AQ" },
      { text: "Antigua and Barbuda", value: "AG" },
      { text: "Argentina", value: "AR" },
      { text: "Armenia", value: "AM" },
      { text: "Aruba", value: "AW" },
      { text: "Australia", value: "AU" },
      { text: "Austria", value: "AT" },
      { text: "Azerbaijan", value: "AZ" },
      { text: "Bahamas (the)", value: "BS" },
      { text: "Bahrain", value: "BH" },
      { text: "Bangladesh", value: "BD" },
      { text: "Barbados", value: "BB" },
      { text: "Belarus", value: "BY" },
      { text: "Belgium", value: "BE" },
      { text: "Belize", value: "BZ" },
      { text: "Benin", value: "BJ" },
      { text: "Bermuda", value: "BM" },
      { text: "Bhutan", value: "BT" },
      { text: "Bolivia (Plurinational State of)", value: "BO" },
      { text: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
      { text: "Bosnia and Herzegovina", value: "BA" },
      { text: "Botswana", value: "BW" },
      { text: "Bouvet Island", value: "BV" },
      { text: "Brazil", value: "BR" },
      { text: "British Indian Ocean Territory (the)", value: "IO" },
      { text: "Brunei Darussalam", value: "BN" },
      { text: "Bulgaria", value: "BG" },
      { text: "Burkina Faso", value: "BF" },
      { text: "Burundi", value: "BI" },
      { text: "Cabo Verde", value: "CV" },
      { text: "Cambodia", value: "KH" },
      { text: "Cameroon", value: "CM" },
      { text: "Canada", value: "CA" },
      { text: "Cayman Islands (the)", value: "KY" },
      { text: "Central African Republic (the)", value: "CF" },
      { text: "Chad", value: "TD" },
      { text: "Chile", value: "CL" },
      { text: "China", value: "CN" },
      { text: "Christmas Island", value: "CX" },
      { text: "Cocos (Keeling) Islands (the)", value: "CC" },
      { text: "Colombia", value: "CO" },
      { text: "Comoros (the)", value: "KM" },
      { text: "Congo (the Democratic Republic of the)", value: "CD" },
      { text: "Congo (the)", value: "CG" },
      { text: "Cook Islands (the)", value: "CK" },
      { text: "Costa Rica", value: "CR" },
      { text: "Croatia", value: "HR" },
      { text: "Cuba", value: "CU" },
      { text: "Curaçao", value: "CW" },
      { text: "Cyprus", value: "CY" },
      { text: "Czechia", value: "CZ" },
      { text: "Côte d'Ivoire", value: "CI" },
      { text: "Denmark", value: "DK" },
      { text: "Djibouti", value: "DJ" },
      { text: "Dominica", value: "DM" },
      { text: "Dominican Republic (the)", value: "DO" },
      { text: "Ecuador", value: "EC" },
      { text: "Egypt", value: "EG" },
      { text: "El Salvador", value: "SV" },
      { text: "Equatorial Guinea", value: "GQ" },
      { text: "Eritrea", value: "ER" },
      { text: "Estonia", value: "EE" },
      { text: "Eswatini", value: "SZ" },
      { text: "Ethiopia", value: "ET" },
      { text: "Falkland Islands (the) [Malvinas]", value: "FK" },
      { text: "Faroe Islands (the)", value: "FO" },
      { text: "Fiji", value: "FJ" },
      { text: "Finland", value: "FI" },
      { text: "France", value: "FR" },
      { text: "French Guiana", value: "GF" },
      { text: "French Polynesia", value: "PF" },
      { text: "French Southern Territories (the)", value: "TF" },
      { text: "Gabon", value: "GA" },
      { text: "Gambia (the)", value: "GM" },
      { text: "Georgia", value: "GE" },
      { text: "Germany", value: "DE" },
      { text: "Ghana", value: "GH" },
      { text: "Gibraltar", value: "GI" },
      { text: "Greece", value: "GR" },
      { text: "Greenland", value: "GL" },
      { text: "Grenada", value: "GD" },
      { text: "Guadeloupe", value: "GP" },
      { text: "Guam", value: "GU" },
      { text: "Guatemala", value: "GT" },
      { text: "Guernsey", value: "GG" },
      { text: "Guinea", value: "GN" },
      { text: "Guinea-Bissau", value: "GW" },
      { text: "Guyana", value: "GY" },
      { text: "Haiti", value: "HT" },
      { text: "Heard Island and McDonald Islands", value: "HM" },
      { text: "Holy See (the)", value: "VA" },
      { text: "Honduras", value: "HN" },
      { text: "Hong Kong", value: "HK" },
      { text: "Hungary", value: "HU" },
      { text: "Iceland", value: "IS" },
      { text: "India", value: "IN" },
      { text: "Indonesia", value: "ID" },
      { text: "Iran (Islamic Republic of)", value: "IR" },
      { text: "Iraq", value: "IQ" },
      { text: "Ireland", value: "IE" },
      { text: "Isle of Man", value: "IM" },
      { text: "Israel", value: "IL" },
      { text: "Italy", value: "IT" },
      { text: "Jamaica", value: "JM" },
      { text: "Japan", value: "JP" },
      { text: "Jersey", value: "JE" },
      { text: "Jordan", value: "JO" },
      { text: "Kazakhstan", value: "KZ" },
      { text: "Kenya", value: "KE" },
      { text: "Kiribati", value: "KI" },
      { text: "Korea (the Democratic People's Republic of)", value: "KP" },
      { text: "Korea (the Republic of)", value: "KR" },
      { text: "Kuwait", value: "KW" },
      { text: "Kyrgyzstan", value: "KG" },
      { text: "Lao People's Democratic Republic (the)", value: "LA" },
      { text: "Latvia", value: "LV" },
      { text: "Lebanon", value: "LB" },
      { text: "Lesotho", value: "LS" },
      { text: "Liberia", value: "LR" },
      { text: "Libya", value: "LY" },
      { text: "Liechtenstein", value: "LI" },
      { text: "Lithuania", value: "LT" },
      { text: "Luxembourg", value: "LU" },
      { text: "Macao", value: "MO" },
      { text: "Madagascar", value: "MG" },
      { text: "Malawi", value: "MW" },
      { text: "Malaysia", value: "MY" },
      { text: "Maldives", value: "MV" },
      { text: "Mali", value: "ML" },
      { text: "Malta", value: "MT" },
      { text: "Marshall Islands (the)", value: "MH" },
      { text: "Martinique", value: "MQ" },
      { text: "Mauritania", value: "MR" },
      { text: "Mauritius", value: "MU" },
      { text: "Mayotte", value: "YT" },
      { text: "Mexico", value: "MX" },
      { text: "Micronesia (Federated States of)", value: "FM" },
      { text: "Moldova (the Republic of)", value: "MD" },
      { text: "Monaco", value: "MC" },
      { text: "Mongolia", value: "MN" },
      { text: "Montenegro", value: "ME" },
      { text: "Montserrat", value: "MS" },
      { text: "Morocco", value: "MA" },
      { text: "Mozambique", value: "MZ" },
      { text: "Myanmar", value: "MM" },
      { text: "Namibia", value: "NA" },
      { text: "Nauru", value: "NR" },
      { text: "Nepal", value: "NP" },
      { text: "Netherlands (the)", value: "NL" },
      { text: "New Caledonia", value: "NC" },
      { text: "New Zealand", value: "NZ" },
      { text: "Nicaragua", value: "NI" },
      { text: "Niger (the)", value: "NE" },
      { text: "Nigeria", value: "NG" },
      { text: "Niue", value: "NU" },
      { text: "Norfolk Island", value: "NF" },
      { text: "Northern Mariana Islands (the)", value: "MP" },
      { text: "Norway", value: "NO" },
      { text: "Oman", value: "OM" },
      { text: "Pakistan", value: "PK" },
      { text: "Palau", value: "PW" },
      { text: "Palestine, State of", value: "PS" },
      { text: "Panama", value: "PA" },
      { text: "Papua New Guinea", value: "PG" },
      { text: "Paraguay", value: "PY" },
      { text: "Peru", value: "PE" },
      { text: "Philippines (the)", value: "PH" },
      { text: "Pitcairn", value: "PN" },
      { text: "Poland", value: "PL" },
      { text: "Portugal", value: "PT" },
      { text: "Puerto Rico", value: "PR" },
      { text: "Qatar", value: "QA" },
      { text: "Republic of North Macedonia", value: "MK" },
      { text: "Romania", value: "RO" },
      { text: "Russian Federation (the)", value: "RU" },
      { text: "Rwanda", value: "RW" },
      { text: "Réunion", value: "RE" },
      { text: "Saint Barthélemy", value: "BL" },
      { text: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
      { text: "Saint Kitts and Nevis", value: "KN" },
      { text: "Saint Lucia", value: "LC" },
      { text: "Saint Martin (French part)", value: "MF" },
      { text: "Saint Pierre and Miquelon", value: "PM" },
      { text: "Saint Vincent and the Grenadines", value: "VC" },
      { text: "Samoa", value: "WS" },
      { text: "San Marino", value: "SM" },
      { text: "Sao Tome and Principe", value: "ST" },
      { text: "Saudi Arabia", value: "SA" },
      { text: "Senegal", value: "SN" },
      { text: "Serbia", value: "RS" },
      { text: "Seychelles", value: "SC" },
      { text: "Sierra Leone", value: "SL" },
      { text: "Singapore", value: "SG" },
      { text: "Sint Maarten (Dutch part)", value: "SX" },
      { text: "Slovakia", value: "SK" },
      { text: "Slovenia", value: "SI" },
      { text: "Solomon Islands", value: "SB" },
      { text: "Somalia", value: "SO" },
      { text: "South Africa", value: "ZA" },
      { text: "South Georgia and the South Sandwich Islands", value: "GS" },
      { text: "South Sudan", value: "SS" },
      { text: "Spain", value: "ES" },
      { text: "Sri Lanka", value: "LK" },
      { text: "Sudan (the)", value: "SD" },
      { text: "Suriname", value: "SR" },
      { text: "Svalbard and Jan Mayen", value: "SJ" },
      { text: "Sweden", value: "SE" },
      { text: "Switzerland", value: "CH" },
      { text: "Syrian Arab Republic", value: "SY" },
      { text: "Taiwan (Province of China)", value: "TW" },
      { text: "Tajikistan", value: "TJ" },
      { text: "Tanzania, United Republic of", value: "TZ" },
      { text: "Thailand", value: "TH" },
      { text: "Timor-Leste", value: "TL" },
      { text: "Togo", value: "TG" },
      { text: "Tokelau", value: "TK" },
      { text: "Tonga", value: "TO" },
      { text: "Trinidad and Tobago", value: "TT" },
      { text: "Tunisia", value: "TN" },
      { text: "Turkey", value: "TR" },
      { text: "Turkmenistan", value: "TM" },
      { text: "Turks and Caicos Islands (the)", value: "TC" },
      { text: "Tuvalu", value: "TV" },
      { text: "Uganda", value: "UG" },
      { text: "Ukraine", value: "UA" },
      { text: "United Arab Emirates (the)", value: "AE" },
      {
        text: "United Kingdom of Great Britain and Northern Ireland (the)",
        value: "GB",
      },
      { text: "United States Minor Outlying Islands (the)", value: "UM" },
      { text: "United States of America (the)", value: "US" },
      { text: "Uruguay", value: "UY" },
      { text: "Uzbekistan", value: "UZ" },
      { text: "Vanuatu", value: "VU" },
      { text: "Venezuela (Bolivarian Republic of)", value: "VE" },
      { text: "Viet Nam", value: "VN" },
      { text: "Virgin Islands (British)", value: "VG" },
      { text: "Virgin Islands (U.S.)", value: "VI" },
      { text: "Wallis and Futuna", value: "WF" },
      { text: "Western Sahara", value: "EH" },
      { text: "Yemen", value: "YE" },
      { text: "Zambia", value: "ZM" },
      { text: "Zimbabwe", value: "ZW" },
    ];
    return (
      <div>
        <p className="Medium ">Personal info</p>
        <div className="mt-1 mb-3 divider" />
        <div className="mb-3">
          <input
            type="text"
            placeholder="First name"
            className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
            value={data.first_name}
            onChange={(e) => this.setProfileData("first_name", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            placeholder="Last name"
            className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
            value={data.last_name}
            onChange={(e) => this.setProfileData("last_name", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            placeholder="Email"
            value={data.email}
            className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
            readOnly
          />
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Friends</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "friends_privacy",
                  data.friends_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.friends_privacy)}
            </div>
          </div>
          <div className="mt-1 mb-3 divider" />
          <p className="text-center">My friends visibility to others people</p>
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Gender</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "gender_privacy",
                  data.gender_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.gender_privacy)}
            </div>
          </div>
          <div className="mt-1 mb-3 divider" />
          <div className="w-full mb-3">
            <Select
              className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
              placeholder="Gender"
              value={data.gender}
              options={[
                { value: "female", text: "Female" },
                { value: "male", text: "Male" },
                { value: "transgender", text: "Transgender" },
              ]}
              onChange={(_, { value }) => this.setProfileData("gender", value)}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Birthday</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "date_of_birth_privacy",
                  data.date_of_birth_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.date_of_birth_privacy)}
            </div>
          </div>
          <div className="mt-1 mb-3 divider" />
          <div className="w-full mb-3">
            <div className="flex items-center">
              <p className="mr-2 text-nowrap whitespace-nowrap">
                Select Birthday:
              </p>
              <div className="w-full cursor-pointer rounded--lg bg--lightGray">
                <div className="w-full px-5 py-1 custom-picker">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    className="w-full border-0">
                    <DatePicker
                      maxDate={moment().subtract(12, "years")}
                      minDate={moment().subtract(110, "years")}
                      value={moment.utc(data.date_of_birth).local()}
                      onChange={(e) =>
                        this.setProfileData("date_of_birth", e.toISOString())
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Languagues</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "languages_privacy",
                  data.languages_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.languages_privacy)}
            </div>
          </div>

          <div className="mt-1 mb-3 divider" />
          <div className="mb-3">
            <input
              type="text"
              placeholder="add languagues"
              className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
              value={data.languages}
              onChange={(e) => this.setProfileData("languages", e.target.value)}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Current Phone Number</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "phone_number_privacy",
                  data.phone_number_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.phone_number_privacy)}
            </div>
          </div>

          <div className="mt-1 mb-3 divider" />
          <div className="mb-3">
            <input
              type="text"
              className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
              value={data.phone_number}
              readOnly
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Address</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "address_privacy",
                  data.address_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.address_privacy)}
            </div>
          </div>

          <div className="mt-1 mb-3 divider" />
          <div className="mb-3">
            <Dropdown
              className="mb-2 border-0 important:bg--lightGray gray rounded--xl"
              placeholder="Select Country"
              fluid
              search
              selection
              options={countryOptions}
              value={data.country_code}
              onChange={(_, { value }) =>
                this.setProfileData("country_code", value)
              }
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Street"
              className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
              value={data.street}
              onChange={(e) => this.setProfileData("street", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="City"
              className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
              value={data.city}
              onChange={(e) => this.setProfileData("city", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Zip Code"
              className="w-full px-5 border-0 shadow-none rounded--xl h-11 important:bg--lightGray"
              value={data.zip_code}
              onChange={(e) => this.setProfileData("zip_code", e.target.value)}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-between">
            <p className="Medium ">Relationship</p>
            <div
              onClick={() =>
                this.selectPrivacyRef.current.open(
                  "marital_status_privacy",
                  data.marital_status_privacy
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
              {this.privacyStatusIcon(data.marital_status_privacy)}
            </div>
          </div>
          <div className="mt-1 mb-3 divider" />
          <div className="w-full mb-3">
            <Select
              className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
              placeholder="Relationship status"
              options={[
                { value: "single", text: "Single" },
                { value: "married", text: "Married" },
                { value: "engaged", text: "Engaged" },
                { value: "flirt", text: "Flirt Mode" },
                { value: "divorced", text: "Divorced" },
                { value: "separated", text: "Separated" },
                { value: "in_relationship", text: "In relationship" },
                { value: "happy", text: "Happy" },
                { value: "sad", text: "Sad" },
                { value: "widowed", text: "Widowed" },
              ]}
              value={data.marital_status}
              onChange={(_, { value }) => {
                // if (value === "in_relationship" && Object.keys(Object.assign({}, data.relationship)).length === 0) {
                //   return;
                // }
                this.setProfileData("marital_status", value);
              }}
            />
          </div>
        </div>
        <GeneralPrivacyStatus
          setProfileData={this.setProfileData}
          ref={this.selectPrivacyRef}
          {...data}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Profile }) => {
  return {
    data: Profile.data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
    userProfileUpdate: (data) => {
      actions.userProfileUpdate(dispatch, data);
    },
    sendRequestToServer: (data) => {
      actions.sendRequestToServer(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Basicinfo);
