import React, { Component } from "react";
import { Link } from "react-router-dom";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import { Avatarimage, Usernamepopup, Thumbnailsimage } from "components";
import { OptimizeImage } from "helpers";

class Flirtscard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { flirt_setting } = this.props.item;
    const { item } = this.props;
    let banner =
      "https://png.pngtree.com/thumb_back/fh260/back_pic/04/48/50/00585a3568a0a7d.jpg";
    if (this.props.item.profile_banner) {
      banner = OptimizeImage(this.props.item.profile_banner);
    }
    return (
      <div className="relative">
        <div
          onClick={() => this.props.removeActionFlirt(this.props.item)}
          className="absolute z-10 flex items-center justify-center w-6 h-6 bg-white rounded-full cursor-pointer right-2 top-2">
          <FiX color={colors.black} />
        </div>
        <Link
          to={{
            pathname: `/flirts/flirt-detail/${item.id}`,
            state: { item: item },
          }}
          className="flex flex-col items-center overflow-hidden bg-white shadow-md rounded-xl">
          <div className="w-full">
            <Thumbnailsimage
              item={item}
              imageSize={{ width: "100%", height: 102 }}
            />
          </div>
          <div className="z-10 -mt-9">
            <Avatarimage item={item} imageSize={{ width: 68, height: 68 }} />
          </div>
          <div className="my-2">
            <Usernamepopup item={item} flirt_setting={flirt_setting} />
          </div>
        </Link>
      </div>
    );
  }
}

export default Flirtscard;
