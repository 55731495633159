import React, { Component } from "react";
import { connect } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { FriendSuggestion } from "components";
import { Capitalize } from "helpers";

class CommentSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mentions: [],
    };
    this.page = 1;
    this.per_page = 10;
    this.params = [];
  }

  onChangeText = (e, newValue, plainText, mentions) => {
    const { d_post_id } = this.props;
    let data = {};
    data[d_post_id] = e.target.value;
    this.props.typeComment(data);
    this.setState({ mentions });
  };

  renderUserSuggestion = (suggestion, search, highlightedDisplay) => {
    return <FriendSuggestion item={suggestion} keyword={search} />;
  };

  doSearch = async (search, callback) => {
    this.params["f"] = true;
    this.params["s"] = search;
    this.params["exclude"] = this.state.mentions.map((i) => i.id);
    const res = await this.props.fetchFriends(
      this.per_page,
      this.page,
      this.params
    );
    let data = [];
    if (res) {
      for (var i = 0; i < res.length; i++) {
        data[i] = {
          ...res[i],
          display: Capitalize(res[i].first_name),
        };
      }
    }

    return callback(data);
  };

  render() {
    const { commentText, friends } = this.props;

    return (
      <div className="w-full pl-5 py-2.5">
        <MentionsInput
          placeholder="Write a comment..."
          onKeyPress={this.props.submitComment}
          className="w-full shadow-none outline-none"
          value={commentText}
          onChange={this.onChangeText}>
          <Mention
            trigger="@"
            data={this.doSearch}
            appendSpaceOnAdd={true}
            renderSuggestion={this.renderUserSuggestion}
            style={{
              backgroundColor: "#daf4fa",
            }}
          />
        </MentionsInput>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { d_post_id } = ownProps;
  return {
    commentText:
      typeof state.Comment.commentText[d_post_id] !== "undefined"
        ? state.Comment.commentText[d_post_id]
        : "",
    friends: state.People.friends,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CommentRedux");
  const { actions: actionPeople } = require("redux/PeopleRedux");

  return {
    ...ownProps,
    ...stateProps,
    typeComment: (text) => {
      dispatch(actions.typeComment(text));
    },
    fetchFriends: (per_page, page, params = []) => {
      return actionPeople.fetchFriends(dispatch, per_page, page, params);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(CommentSuggestion);
