import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDispatch, useSelector } from 'react-redux'
import { BiEdit, BiMoveVertical } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';

export default function Item({ bar_id, item, editCategory, onEditCategory, toggleCategory, deleteCategory, onDragStart, onDrop }) {

    const category = useSelector((state) => Object.assign({}, state.enhancement.category.data?.[bar_id]?.[item.id]));


    const dragStart = (e) => {
        onDragStart(e, item);
    };

    const handleDrop = (e) => {
        onDrop(e, item);
    }

    if (!category.id) {
        return null;
    }

    return (
        <div draggable="true"
            id={item.id}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={dragStart}
            onDrop={handleDrop}
        >
            <div className="cursor-move flex items-center min-h-[63px] justify-between black hover:bg-primary-200 bg-gray-100 p-3 rounded-lg  bg--lightPrimary mb-2" >

                <div className="grow mr-2">
                    {category.is_edit ?
                        <input
                            type="text"
                            value={(category.name || "")}
                            placeholder="Edit Category"
                            className="shadow-sm w-full border-0 rounded-lg h-12 px-5 bg-white"
                            onChange={(e) => editCategory(category, e.target.value)}
                            onKeyUp={(e) => onEditCategory(e, category)}
                        />
                        :
                        category.name
                    }
                </div>

                <div className="flex items-center " >
                    <div className="cursor-pointer primary" onClick={() => toggleCategory(category)}>
                        <BiEdit size={22} />
                    </div>
                    <div className="cursor-pointer text-red-400 pl-2" onClick={() => deleteCategory(category.id)}>
                        <MdDeleteForever size={22} />
                    </div>

                </div>
            </div>

        </div>
    );
}