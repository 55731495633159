import * as React from "react";
import "./styles.css";
import { Button, Tab, Nav } from "react-bootstrap";
import { FiClock, FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import colors from "config/color/color.js";
import { AiFillCalendar } from "react-icons/ai";
import { Datepicker } from "components";
import { RiQuestionnaireFill } from "react-icons/ri";
import { Dropdown } from "semantic-ui-react";

class Candidatedetailpopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, interview: false };
  }

  candidatePopupOpen = () => {
    this.setState({ open: true });
  };

  candidatePopupClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.candidatePopupClose}
          scroll="body"
          className="custom-backdrop candidate-popup"
          fullWidth={true}
          maxWidth="md">
          <div className="flex items-center justify-center px-3 py-3 border-b ">
            <p className="text-2xl Medium">Candidate</p>
            <div
              onClick={this.candidatePopupClose}
              className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
              <FiX size={24} style={{ color: colors.darkGray }} />
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h1>Candidate Title</h1>
                <p className="mt-3 mb-2">laravel developer</p>
                <p className="darkGray fs-12">
                  abdulwaheed3338_tyn@indeedemail.com - Lahore
                </p>
                <p className="darkGray fs-12">
                  Applied to Laravel / Laminas(Zend) Expert - Lahore
                </p>
              </div>
              <Button
                onClick={() =>
                  this.setState({ interview: !this.state.interview })
                }
                className="flex items-center justify-center px-5 py-2 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary rounded--xl Regular">
                <AiFillCalendar
                  color={colors.white}
                  className="mr-2"
                  size={16}
                />
                Set up interview
              </Button>
            </div>
            <div className="mb-4">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav
                  variant="pills"
                  className="flex-row px-4 mb-5 -mx-6 border-b">
                  <Nav.Item>
                    <Nav.Link
                      className="bg-transparent rounded-0 black border--primary"
                      eventKey="first">
                      Application
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="bg-transparent rounded-0 black border--primary"
                      eventKey="second">
                      Interview
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div>
                      <div className="flex items-center justify-between">
                        <h3>Screener questions</h3>
                      </div>
                      <div>
                        <div className="flex mt-4">
                          <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-200 rounded-full">
                            <RiQuestionnaireFill size={15} className="black" />
                          </div>
                          <div>
                            <p>
                              How many years of Laravel / Zend experience do you
                              have?
                            </p>
                            <p className="darkGray fs-13">
                              Your requirement:1 year
                            </p>
                          </div>
                        </div>
                        <div className="flex mt-4">
                          <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-200 rounded-full">
                            <RiQuestionnaireFill size={15} className="black" />
                          </div>
                          <div>
                            <p>
                              How many years of Laravel / Zend experience do you
                              have?
                            </p>
                            <p className="darkGray fs-13">
                              Your requirement:1 year
                            </p>
                          </div>
                        </div>
                        <div className="flex mt-4">
                          <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-200 rounded-full">
                            <RiQuestionnaireFill size={15} className="black" />
                          </div>
                          <div>
                            <p>
                              How many years of Laravel / Zend experience do you
                              have?
                            </p>
                            <p className="darkGray fs-13">
                              Your requirement:1 year
                            </p>
                          </div>
                        </div>
                        <div className="flex mt-4">
                          <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-200 rounded-full">
                            <RiQuestionnaireFill size={15} className="black" />
                          </div>
                          <div>
                            <p>
                              How many years of Laravel / Zend experience do you
                              have?
                            </p>
                            <p className="darkGray fs-13">
                              Your requirement:1 year
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {this.state.interview === true ? (
                      <div
                        className={`${
                          this.state.interview === true ? "mb-5" : null
                        } grid grid-cols-4 gap-2`}>
                        <div>
                          <p className="ml-2 Medium">Date</p>
                          <div className="flex items-center border rounded--xl">
                            <Datepicker />
                          </div>
                        </div>
                        <div>
                          <p className="ml-2 Medium">Start time</p>

                          <Dropdown
                            className="border gray rounded--xl"
                            placeholder="08:00"
                            fluid
                            upward={true}
                            selection
                            options={[
                              {
                                key: "7:00 AM",
                                text: "7:00 AM",
                                value: "7:00 AM",
                              },
                              {
                                key: "8:00 AM",
                                text: "8:00 AM",
                                value: "8:00 AM",
                              },
                              {
                                key: "9:00 AM",
                                text: "9:00 AM",
                                value: "9:00 AM",
                              },
                              {
                                key: "10:00 AM",
                                text: "10:00 AM",
                                value: "10:00 AM",
                              },
                              {
                                key: "11:00 AM",
                                text: "11:00 AM",
                                value: "11:00 AM",
                              },
                              {
                                key: "12:00 AM",
                                text: "12:00 AM",
                                value: "12:00 AM",
                              },
                              {
                                key: "1:00 PM",
                                text: "1:00 PM",
                                value: "1:00 PM",
                              },
                              {
                                key: "2:00 PM",
                                text: "2:00 PM",
                                value: "2:00 PM",
                              },
                              {
                                key: "3:00 PM",
                                text: "3:00 PM",
                                value: "3:00 PM",
                              },
                              {
                                key: "4:00 PM",
                                text: "4:00 PM",
                                value: "4:00 PM",
                              },
                              {
                                key: "5:00 PM",
                                text: "5:00 PM",
                                value: "5:00 PM",
                              },
                              {
                                key: "6:00 PM",
                                text: "6:00 PM",
                                value: "6:00 PM",
                              },
                              {
                                key: "7:00 PM",
                                text: "7:00 PM",
                                value: "7:00 PM",
                              },
                              {
                                key: "8:00 PM",
                                text: "8:00 PM",
                                value: "8:00 PM",
                              },
                              {
                                key: "9:00 PM",
                                text: "9:00 PM",
                                value: "9:00 PM",
                              },
                              {
                                key: "10:00 PM",
                                text: "10:00 PM",
                                value: "10:00 PM",
                              },
                              {
                                key: "11:00 PM",
                                text: "11:00 PM",
                                value: "11:00 PM",
                              },
                              {
                                key: "12:00 PM",
                                text: "12:00 PM",
                                value: "12:00 PM",
                              },
                            ]}
                          />
                        </div>
                        <div>
                          <p className="ml-7 Medium">End time</p>
                          <div className="flex items-center">
                            <p className="mr-2">to</p>
                            <Dropdown
                              className="border gray rounded--xl"
                              placeholder="08:00"
                              fluid
                              upward={true}
                              selection
                              options={[
                                {
                                  key: "7:00 AM",
                                  text: "7:00 AM",
                                  value: "7:00 AM",
                                },
                                {
                                  key: "8:00 AM",
                                  text: "8:00 AM",
                                  value: "8:00 AM",
                                },
                                {
                                  key: "9:00 AM",
                                  text: "9:00 AM",
                                  value: "9:00 AM",
                                },
                                {
                                  key: "10:00 AM",
                                  text: "10:00 AM",
                                  value: "10:00 AM",
                                },
                                {
                                  key: "11:00 AM",
                                  text: "11:00 AM",
                                  value: "11:00 AM",
                                },
                                {
                                  key: "12:00 AM",
                                  text: "12:00 AM",
                                  value: "12:00 AM",
                                },
                                {
                                  key: "1:00 PM",
                                  text: "1:00 PM",
                                  value: "1:00 PM",
                                },
                                {
                                  key: "2:00 PM",
                                  text: "2:00 PM",
                                  value: "2:00 PM",
                                },
                                {
                                  key: "3:00 PM",
                                  text: "3:00 PM",
                                  value: "3:00 PM",
                                },
                                {
                                  key: "4:00 PM",
                                  text: "4:00 PM",
                                  value: "4:00 PM",
                                },
                                {
                                  key: "5:00 PM",
                                  text: "5:00 PM",
                                  value: "5:00 PM",
                                },
                                {
                                  key: "6:00 PM",
                                  text: "6:00 PM",
                                  value: "6:00 PM",
                                },
                                {
                                  key: "7:00 PM",
                                  text: "7:00 PM",
                                  value: "7:00 PM",
                                },
                                {
                                  key: "8:00 PM",
                                  text: "8:00 PM",
                                  value: "8:00 PM",
                                },
                                {
                                  key: "9:00 PM",
                                  text: "9:00 PM",
                                  value: "9:00 PM",
                                },
                                {
                                  key: "10:00 PM",
                                  text: "10:00 PM",
                                  value: "10:00 PM",
                                },
                                {
                                  key: "11:00 PM",
                                  text: "11:00 PM",
                                  value: "11:00 PM",
                                },
                                {
                                  key: "12:00 PM",
                                  text: "12:00 PM",
                                  value: "12:00 PM",
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          <p className="ml-7 white">.</p>
                          <Button className="flex items-center justify-center h-12 px-4 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary rounded--xl Regular">
                            <FiClock
                              color={colors.white}
                              className="mr-2"
                              size={16}
                            />
                            Schedule interview
                          </Button>
                        </div>
                      </div>
                    ) : null}
                    <div className="grid gap-2 p-3 bg-gray-200 lg:grid-cols-4 rounded-xl">
                      <div className="flex items-center justify-center">
                        <h4>Date</h4>
                      </div>
                      <div className="flex items-center justify-center">
                        <h4>Time</h4>
                      </div>
                      <div className="flex items-center justify-center">
                        <h4>Interview Status </h4>
                      </div>
                      <div className="flex items-center justify-center">
                        <h4>Type</h4>
                      </div>
                    </div>
                    <div className="grid gap-2 px-2 py-4 lg:grid-cols-4">
                      <div className="flex items-center justify-center">
                        <p>Mar 15, 2021</p>
                      </div>
                      <div className="flex items-center justify-center">
                        <p>7:00 PM - 7:30 PM PKT</p>
                      </div>
                      <div className="flex items-center justify-center">
                        <p>Expired</p>
                      </div>
                      <div className="flex items-center justify-center">
                        <p>In person</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Candidatedetailpopup;
