import React, { useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { FiChevronRight, FiPlus } from "react-icons/fi";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Buttonused } from "components";
import colors from "config/color/color.js";
import { BiDrink } from "react-icons/bi";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import SubItem from './SubItem';

import "./styles.css";
import { IoMdAddCircleOutline } from "react-icons/io";

const { actions } = require("redux/BarEnhancement");

export default function Sub({ categories, addCategory }) {

    const { id } = useParams();

    const [open, setOpen] = useState(false);

    return (

        <div>
            {categories.map((item, key) => (
                item.id ?
                    <>
                        <div className="flex justify-between ">
                            <NavLink to={`/bar/${id}/${item.id}`} >
                                <button onClick={() => item.sub.length > 0 ? setOpen(!open) : null} className="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-collapse-two">
                                    <div className="flex justify-center items-center">
                                        {item.sub.length > 0 &&
                                            <>
                                                <svg className={`hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 ${!open ? "block" : "hidden"} w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400`} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                                    <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                                </svg>
                                                <svg className={`hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 ${open ? "block" : "hidden"} w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400`} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                                </svg>
                                            </>
                                        }
                                        <SubItem item={item} />

                                    </div>

                                </button>

                            </NavLink>
                            <div className="cursor-pointer" onClick={() => addCategory(item.id)}><IoMdAddCircleOutline size={21} /></div>
                        </div>
                        {item.sub.length > 0 &&
                            <div className="hs-accordion-group pl-6">
                                <div class={`hs-accordion ${open ? "block" : "hidden"}`} id={`hs-basic-nested-heading-${item.id}`} >
                                    <Sub categories={item.sub} addCategory={addCategory} />
                                </div>
                            </div>
                        }
                    </> :
                    null

            ))}

        </div>
    );
}