import { LifeWidget } from "common";
import moment from "moment";
import { Capitalize } from "helpers";

const types = {
  SUBMIT_JOB_PENDING: "SUBMIT_JOB_PENDING",
  SUBMIT_JOB_FAILURE: "SUBMIT_JOB_FAILURE",
  SUBMIT_JOB_SUCCESS: "SUBMIT_JOB_SUCCESS",

  FETCH_JOB_PENDING: "FETCH_JOB_PENDING",
  FETCH_JOB_FAILURE: "FETCH_JOB_FAILURE",
  FETCH_JOB_SUCCESS: "FETCH_JOB_SUCCESS",

  FETCH_SINGLE_JOB_SUCCESS: "FETCH_SINGLE_JOB_SUCCESS",

  FETCH_JOB_MORE: "FETCH_JOB_MORE",

  FETCH_JOB_RESOURCE: "FETCH_JOB_RESOURCE",

  FETCH_CANDIDATE_PENDING: "FETCH_CANDIDATE_PENDING",
  FETCH_CANDIDATE_FAILURE: "FETCH_CANDIDATE_FAILURE",
  FETCH_CANDIDATE_SUCCESS: "FETCH_CANDIDATE_SUCCESS",
  FETCH_CANDIDATE_MORE: "FETCH_CANDIDATE_MORE",
  JOB_CANDIDATE_ACTION: "JOB_CANDIDATE_ACTION",
  JOB_CANDIDATE_APPLY: "JOB_CANDIDATE_APPLY",

  FETCH_JOB_MODULE: "FETCH_JOB_MODULE",

  ADD_JOB_FORM: "ADD_JOB_FORM",

  ADD_APPLY_FORM: "ADD_APPLY_FORM",

  APPLY_JOB_FILTER: "APPLY_JOB_FILTER",

  JOB_STATUS_TOGGLE: "JOB_STATUS_TOGGLE",

  SET_CANDIDATE_INTERVIEW: "SET_CANDIDATE_INTERVIEW",

  DELETE_CANDIDATE: "DELETE_CANDIDATE",
};

const initialState = {
  data: {},
  dataIds: [],
  total: 0,
  is_fetching: false,
  is_more: false,
  resources: {},
  form: {},
  apply: {},
  candidates: {},
  candidateIds: {},
  is_candidate_fetching: {},
  is_candidate_more: {},
  candidate_total: {},
  filters: {},
  modules: [],
};

export const actions = {
  fetchResource: async (dispatch, model) => {
    const json = await LifeWidget.fetchJobResource(model);
    if (json !== undefined) {
      if (json.status) {
        dispatch({
          type: types.FETCH_JOB_RESOURCE,
          data: json.data,
          id: model,
        });
      }
    }
  },
  fetchJobModules: async (dispatch) => {
    const json = await LifeWidget.fetchJobModules();
    if (json !== undefined) {
      if (json.status) {
        dispatch({
          type: types.FETCH_JOB_MODULE,
          data: json.data,
        });
      }
    }
  },
  fetchJobs: async (dispatch, per_page, page, params = []) => {
    dispatch({ type: types.FETCH_JOB_PENDING });
    const json = await LifeWidget.fetchJobs(per_page, page, params);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_JOB_FAILURE,
        message: "Can't get data from server",
      });
    } else if (200 !== json.status) {
      dispatch({ type: types.FETCH_JOB_FAILURE, message: json.data.message });
    } else {
      if (page > 1) {
        dispatch({ type: types.FETCH_JOB_MORE, data: json.data });
      } else {
        dispatch({ type: types.FETCH_JOB_SUCCESS, data: json.data });
      }
    }
  },
  fetchJob: async (dispatch, id) => {
    const json = await LifeWidget.fetchJob(id);
    if (json === undefined) {
    } else if (200 !== json.status) {
    } else {
      dispatch({
        type: types.FETCH_SINGLE_JOB_SUCCESS,
        data: json.data,
        id: id,
      });
    }
  },
  fetchJobCandidates: async (dispatch, per_page, page, params = [], type) => {
    dispatch({ type: types.FETCH_CANDIDATE_PENDING, id: type });
    const json = await LifeWidget.fetchJobCandidates(
      per_page,
      page,
      params,
      type
    );

    if (json === undefined) {
      dispatch({
        type: types.FETCH_CANDIDATE_FAILURE,
        message: "Can't get data from server",
        id: type,
      });
    } else if (200 !== json.status) {
      dispatch({
        type: types.FETCH_CANDIDATE_FAILURE,
        message: json.data.message,
        id: type,
      });
    } else {
      if (page > 1) {
        dispatch({
          type: types.FETCH_CANDIDATE_MORE,
          data: json.data,
          id: type,
        });
      } else {
        dispatch({
          type: types.FETCH_CANDIDATE_SUCCESS,
          data: json.data,
          id: type,
        });
      }
    }
  },
  jobCandidateAction: async (dispatch, data) => {
    dispatch({ type: types.JOB_CANDIDATE_ACTION, data: data });
    const json = await LifeWidget.jobCandidateAction(data);
    if (json !== undefined) {
      if (json.data) {
        const status = Capitalize(
          data.type.replace("is_", "").replace("ed", "")
        ).replace("Hir", "Hire");
        const response = json.data;
        const title = `Application status`;
        const body = `Your application status ${status} for the post ${response.job.job_post_title}.`;
        const token = response.user.device_token;
        const extra = {
          type: "Configuration",
          url: "SingleJob",
          params: { id: response.job_post_id },
        };
        if (token) {
          // sendPushNotification(token, title, body, extra);
        }
      }
    }
  },
  jobCandidateApply: async (dispatch, data, id) => {
    dispatch({ type: types.JOB_CANDIDATE_APPLY, data: id });
    const json = await LifeWidget.jobCandidateAction(data);
    if (json !== undefined) {
      if (json.data) {
        const response = json.data;
        const title = `New application request`;
        const body = `${response.interview.first_name} ${response.interview.last_name} apply for the post ${response.job_post_title} and waiting for your review.`;
        const token = response.user.device_token;
        const extra = {
          type: "Configuration",
          url: "CandidateList",
          params: {},
        };
        if (token) {
          // sendPushNotification(token, title, body, extra);
        }
      }
    }
  },
  submitJob: async (dispatch, data) => {
    const json = await LifeWidget.submitJob(data);
    if (json.status === 200) {
      dispatch({ type: types.SUBMIT_JOB_SUCCESS, data: json.data });
    }
  },
  jobStatusToggle: async (dispatch, data) => {
    dispatch({ type: types.JOB_STATUS_TOGGLE, data: data });
    const json = await LifeWidget.saveJobMetaData(data);
  },
  setCandidateInterviewTime: async (dispatch, data, id) => {
    const json = await LifeWidget.setCandidateInterviewTime(data);
    if (json !== undefined) {
      if (json.data) {
        dispatch({
          type: types.SET_CANDIDATE_INTERVIEW,
          data: json.data,
          id: id,
        });
        const response = json.data;
        const interview_time = moment
          .utc(response.interview_time)
          .local()
          .format("MMM DD, YYYY h:mm a");
        const title = `Interview schedule`;
        const body = `You interview has been schedule at ${interview_time} for the post ${response.job.job_post_title}.`;
        const token = response.user.device_token;
        const extra = {
          type: "Configuration",
          url: "SingleJob",
          params: { id: response.job_post_id },
        };
        if (token) {
          // sendPushNotification(token, title, body, extra);
        }
      }
    }
  },
  deleteCandidate: async (dispatch, data, id) => {
    dispatch({ type: types.DELETE_CANDIDATE, data, id });
    const json = await LifeWidget.deleteCandidate(data);
  },
  addJobForm: (data) => ({
    type: types.ADD_JOB_FORM,
    data,
  }),
  addApplyForm: (data) => ({
    type: types.ADD_APPLY_FORM,
    data,
  }),
  applyFilters: (data) => ({
    type: types.APPLY_JOB_FILTER,
    data,
  }),
};

export const reducer = (state = initialState, action) => {
  const { type, message, data, id } = action;
  switch (type) {
    case types.ADD_JOB_FORM: {
      return {
        ...state,
        form: data,
      };
    }
    case types.ADD_APPLY_FORM: {
      return {
        ...state,
        apply: data,
      };
    }
    case types.FETCH_JOB_PENDING: {
      return {
        ...state,
        is_fetching: true,
        error: false,
        message: "",
      };
    }
    case types.FETCH_JOB_FAILURE: {
      return {
        ...state,
        is_fetching: false,
        error: true,
        message: message,
      };
    }
    case types.FETCH_JOB_SUCCESS: {
      return {
        ...state,
        is_fetching: false,
        data: data.data,
        dataIds: Object.keys(data.data),
        is_more: data.is_more,
        total: data.total,
      };
    }
    case types.FETCH_JOB_MORE: {
      return {
        ...state,
        is_fetching: false,
        data: { ...state.data, ...data.data },
        dataIds: [...state.dataIds, ...Object.keys(data.data)],
        is_more: data.is_more,
        total: data.total,
      };
    }

    case types.FETCH_SINGLE_JOB_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [id.toString()]: data,
        },
        dataIds: state.dataIds.some((item) => id == item)
          ? state.dataIds
          : [...state.dataIds, id],
      };
    }

    case types.SUBMIT_JOB_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [data.id]: data,
        },
      };
    }

    case types.FETCH_CANDIDATE_PENDING: {
      return {
        ...state,
        is_candidate_fetching: {
          ...state.is_candidate_fetching,
          [id]: true,
        },
        error: false,
        message: "",
      };
    }
    case types.FETCH_CANDIDATE_FAILURE: {
      return {
        ...state,
        is_candidate_fetching: {
          ...state.is_candidate_fetching,
          [id]: false,
        },
        error: true,
        message: message,
      };
    }
    case types.FETCH_CANDIDATE_SUCCESS: {
      return {
        ...state,
        is_candidate_fetching: {
          ...state.is_candidate_fetching,
          [id]: false,
        },
        candidates: {
          ...state.candidates,
          [id]: data.data,
        },
        candidateIds: {
          ...state.candidateIds,
          [id]: Object.keys(data.data),
        },
        is_candidate_more: {
          ...state.is_candidate_more,
          [id]: data.is_more,
        },
        candidate_total: {
          ...state.candidate_total,
          [id]: data.total,
        },
      };
    }
    case types.FETCH_CANDIDATE_MORE: {
      return {
        ...state,
        is_candidate_fetching: {
          ...state.is_candidate_fetching,
          [id]: false,
        },
        candidates: {
          ...state.candidates,
          [id]: { ...state.candidates[id], ...data.data },
        },
        candidateIds: {
          ...state.candidateIds,
          [id]: [...state.candidateIds[id], ...Object.keys(data.data)],
        },
        is_candidate_more: {
          ...state.is_candidate_more,
          [id]: data.is_more,
        },
        candidate_total: {
          ...state.candidate_total,
          [id]: data.total,
        },
      };
    }

    case types.JOB_CANDIDATE_ACTION: {
      const type = data.type.replace("is_", "");
      const action = data.action;
      const addCandidateIds = state.candidateIds[type];
      const removeCandidateIds = state.candidateIds[action];
      const addCandidate = state.candidates[type];
      const removeCandidate = state.candidates[action];
      const newCandidateIdsArray = [
        ...[data.id],
        ...(typeof addCandidateIds !== "undefined" ? addCandidateIds : []),
      ];
      const removeCandidateIdsArray = [
        ...(typeof removeCandidateIds !== "undefined"
          ? removeCandidateIds
          : []),
      ].filter((id) => id != data.id);
      const newCandidateArray = {
        ...(typeof addCandidate !== "undefined" ? addCandidate : {}),
        ...{
          [data.id]: { ...removeCandidate[data.id], ...{ [data.type]: 1 } },
        },
      };
      return {
        ...state,
        candidateIds: {
          ...state.candidateIds,
          [action]: removeCandidateIdsArray,
          [type]: newCandidateIdsArray,
        },
        candidates: {
          ...state.candidates,
          [type]: newCandidateArray,
        },
      };
    }

    case types.SET_CANDIDATE_INTERVIEW: {
      return {
        ...state,
        candidates: {
          ...state.candidates,
          [id]: {
            ...state.candidates[id],
            [data.id]: {
              ...state.candidates[id][data.id],
              interview_time: data.interview_time,
              note: data.note,
            },
          },
        },
      };
    }

    case types.DELETE_CANDIDATE: {
      return {
        ...state,
        candidates: {
          ...state.candidates,
          [id]: {
            ...state.candidates[id],
            [data]: {},
          },
        },
      };
    }

    case types.JOB_CANDIDATE_APPLY: {
      return {
        ...state,
        data: {
          ...state.data,
          [data]: {
            ...state.data[data],
            is_applied: true,
          },
        },
      };
    }

    case types.JOB_STATUS_TOGGLE: {
      return {
        ...state,
        data: {
          ...state.data,
          [data.id]: {
            ...state.data[data.id],
            [data.key]: data.value,
          },
        },
      };
    }

    case types.FETCH_JOB_RESOURCE: {
      return {
        ...state,
        resources: {
          ...state.resources,
          [id]: data,
        },
      };
    }

    case types.FETCH_JOB_MODULE: {
      return {
        ...state,
        modules: data,
      };
    }

    case types.APPLY_JOB_FILTER: {
      return {
        ...state,
        filters: data,
      };
    }

    default: {
      return state;
    }
  }
};
