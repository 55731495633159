import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import avatar from "assets/images/avatar.webp";
import SocialFeed from "assets/images/SocialFeed.webp";
import MyFriends from "assets/images/MyFriends.webp";
import groups from "assets/images/groups.webp";
import saved from "assets/images/saved.webp";
import Mymessages from "assets/images/Mymessages.webp";
import Flirt from "assets/images/Flirt.webp";
import Bars from "assets/images/Bars.webp";
import Restaurant from "assets/images/Restaurant.webp";
import MyCompany from "assets/images/MyCompany.webp";
import WhoWantstoJoin from "assets/images/WhoWantstoJoin.webp";
import feedback from "assets/images/feedback.webp";
import billofRights from "assets/images/billofRights.webp";
import Howto from "assets/images/Howto.webp";
import Menu1000Photos from "assets/images/Menu1000Photos.webp";
import Vacation from "assets/images/Vacation.webp";
import Social_Seller from "assets/images/Social_Seller.webp";
import MyCRM from "assets/images/MyCRM.webp";
import Jobs from "assets/images/Jobs.webp";
import Events from "assets/images/Events.webp";
import Bank from "assets/images/bank.webp";
import Cars from "assets/images/Cars.webp";
import Calendar from "assets/images/Calendar.webp";
import Homes from "assets/images/Homes.webp";
import Apartments from "assets/images/Apartments.webp";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

class Listmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seemore: false,
    };
  }
  render() {
    return (
      <div
        className="fixed py-3 ml-3 overflow-y-auto leftsiderbar"
        id="scrollbar">
        <Link
          to="/"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={SocialFeed}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Social Feed</p>
        </Link>
        <Link
          to="/profile"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={avatar}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Profile</p>
        </Link>
        <Link
          to="/friends"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={MyFriends}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Friends</p>
        </Link>
        <Link
          to="/groups"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={groups}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Groups</p>
        </Link>
        <Link
          to="/saved"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={saved}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Saved</p>
        </Link>
        <Link
          // to="/communication"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={Mymessages}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Comms</p>
        </Link>
        <Link
          to="/flirts/available-flirts"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={Flirt}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Flirts</p>
        </Link>
        <Link
          to="/bars"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={Bars}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Bars</p>
        </Link>
        <Link
          to="/restaurants"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={Restaurant}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">Restaurants</p>
        </Link>
        <Link
          to="/my_companies"
          className="flex items-center p-2 rounded-lg hover:bg-gray-200">
          <Image
            src={MyCompany}
            className="object-cover w-10 h-10 mr-2 border rounded-full"
          />
          <p className="">My Company</p>
        </Link>
        {this.state.seemore === false ? (
          <div
            className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-200"
            onClick={() => this.setState({ seemore: true })}>
            <div className="flex items-center justify-center w-10 h-10 mr-2 bg-gray-300 rounded-full ">
              <FiChevronDown size={20} />
            </div>
            <p className="">See More</p>
          </div>
        ) : null}
        {this.state.seemore ? (
          <>
            <Link
              to="/share-us"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200">
              <Image
                src={WhoWantstoJoin}
                className="object-cover w-10 h-10 mr-2 border rounded-full"
              />
              <p className="">Share Us</p>
            </Link>
            <Link
              to="/"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200">
              <Image
                src={feedback}
                className="object-cover w-10 h-10 mr-2 border rounded-full"
              />
              <p className="">Feedback</p>
            </Link>
            <Link
              to="/bill-of-rights"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200">
              <Image
                src={billofRights}
                className="object-cover w-10 h-10 mr-2 border rounded-full"
              />
              <p className="">Bill of Rights</p>
            </Link>
            {/* <Link
              to="/tutorial"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200"
            >
              <Image
                src={Howto}
                className="object-cover w-10 h-10 mr-2 border rounded-full"
              />
              <p className="">How To</p>
            </Link> */}
            <Link
              to="/leaderboard/usa"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200">
              <Image
                src={Menu1000Photos}
                className="object-cover w-10 h-10 mr-2 border rounded-full"
              />
              <p className="">Win $1000</p>
            </Link>
            <div
              className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-200"
              onClick={() => this.setState({ seemore: false })}>
              <div className="flex items-center justify-center w-10 h-10 mr-2 bg-gray-300 rounded-full ">
                <FiChevronUp size={20} className="-mt-1" />
              </div>
              <p className="">See Less</p>
            </div>
          </>
        ) : null}

        <div className="my-3 border" />
        <p className="text-2xl text-center">Coming Soon</p>
        <div className="mt-2">
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Bank}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Bank</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Cars}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Cars</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Apartments}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Apartments</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Homes}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Homes</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Vacation}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Vacation</p>
          </Link>
          <Link
            to="/public/jobs"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Jobs}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Jobs</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Events}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Events</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Calendar}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Calendar</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={Social_Seller}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">Social Seller</p>
          </Link>
          <Link
            to="/"
            className="flex items-center p-2 rounded-lg hover:bg-gray-200">
            <Image
              src={MyCRM}
              className="object-cover w-10 h-10 mr-2 border rounded-full"
            />
            <p className="">My CRM</p>
          </Link>
        </div>
      </div>
    );
  }
}

export default Listmenu;
