import { useDispatch, useSelector } from "react-redux";
import Time from "./time";
const { actions } = require("redux/BarEnhancement");

export default function Edit({ timeList }) {
  const dispatch = useDispatch();

  const form = useSelector((state) =>
    Object.assign({}, state.enhancement.scheduleForm)
  );

  const addForm = (key, value) => {
    const data = form;
    data[key] = value;
    dispatch(actions.addScheduleForm(data));
  };

  return (
    <div className="mb-10">
      <div className="m-4">
        <h2 className="m-0">{form.day}:</h2>
      </div>
      <div className="space-y-5">
        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">Open:</p>
            <div className="w-full min-w-[220px]">
              <Time timeList={timeList} field={"open_time"} />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">Closes:</p>
            <div className="w-full min-w-[220px]">
              <Time timeList={timeList} field={"close_time"} />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">Skip the Line Price:</p>
            <div className="w-full min-w-[220px]">
              <input
                type="text"
                placeholder="Enter Price"
                value={form.skip_line}
                onChange={(e) => addForm("skip_line", e.target.value)}
                className="w-full h-12 px-5 text-black bg-white border-0 shadow-md rounded--xl"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">Notification time:</p>
            <div className="w-full min-w-[220px] flex flex-row">
              <input
                type="number"
                placeholder="Enter number"
                value={form.notification_time}
                onChange={(e) => addForm("notification_time", e.target.value)}
                className="w-full h-12 px-5 text-black bg-white border-0 shadow-md rounded--xl"
              />
              <select value={form.notification_unit} onChange={(e) => addForm("notification_unit", e.target.value)} class="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value="minutes">Minute</option>
                <option value="hours">Hour</option>
              </select>
            </div>
          </div>
        </div>
        <p className="text-black-400 fs-14">After subscribe the skip the line notification the user able to get notification with in the above time period</p>


        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">How Many VIP Spots:</p>
            <div className="w-full min-w-[220px]">
              <input
                type="number"
                value={form.number_of_vip_spots}
                onChange={(e) => addForm("number_of_vip_spots", e.target.value)}
                className="w-full h-12 px-5 text-black bg-white border-0 shadow-md rounded--xl"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">VIP Price:</p>
            <div className="w-full min-w-[220px]">
              <input
                type="text"
                placeholder="Enter Price"
                value={form.vip}
                onChange={(e) => addForm("vip", e.target.value)}
                className="w-full h-12 px-5 text-black bg-white border-0 shadow-md rounded--xl"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">No Show:</p>
            <div className="w-full min-w-[220px]">
              <Time timeList={timeList} field={"no_show"} />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mx-4">
          <div className="flex items-center gap-x-3">
            <p className="min-w-[170px] text-right">Refunds up to:</p>
            <div className="w-full min-w-[220px]">
              <Time timeList={timeList} field={"refund_up_to"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
