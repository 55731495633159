import React, { Component } from "react";
import { NavDropdown, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import { BsDot } from "react-icons/bs";
import { Popup } from "semantic-ui-react";
import { BsThreeDots } from "react-icons/bs";
import { TimeAgo, RenderMention } from "components";
import { connect } from "react-redux";
import { avatar } from "assets";
import { OptimizeImage, Capitalize } from "helpers";
import { IoTrashOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

class PostCommentReply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likeColor: false,
      reply: false,
    };
  }

  commentToggleLike = () => {
    this.props.commentToggleLike(this.props.comment);
  };

  replyToggleLike = () => {
    this.props.replyToggleLike(this.props.replay);
  };

  deleteReplyByCommentId = () => {
    this.props.deleteReplyByCommentId();
  };

  editCommentByPostId = () => {
    const { replay } = this.props;
    let data = {};
    data[replay.parent_comment_id] = replay.comment;
    let comment = {};
    comment[replay.parent_comment_id] = replay;

    this.props.editCommentByPostId(comment, data);
    this.props.openCommentBox();
  };

  render() {
    const { replay, user } = this.props;
    if (!replay) return null;

    return (
      <div className="table mt-1">
        <div className="table-cell w-8 h-8 align-top">
          <Image
            src={
              replay.user.profile_photo
                ? OptimizeImage(replay.user.profile_photo)
                : avatar
            }
            className="object-cover w-full h-full rounded-full"
          />
        </div>
        <div className="flex-grow table-cell">
          <div className="flex-grow ml-2 comment-box-container hover_show_box">
            <div className="relative inline-block px-3 py-2 mr-10 comment-box rounded-2xl">
              <p className="Medium fs-13">
                {Capitalize(replay.user.first_name)}{" "}
                {Capitalize(replay.user.last_name)}
              </p>
              <p className="fs-13">
                <RenderMention
                  value={replay.comment}
                  partTypes={[{ trigger: "@" }]}
                />
                {replay.attachments && (
                  <Image
                    className="object-cover w-40 mr-2 rounded"
                    src={OptimizeImage(replay.attachments.attachment_url)}
                  />
                )}
              </p>
              {user.id === replay.user_id && (
                <div className="z-10 hover_show_drpdwn -right-10 top-3">
                  <NavDropdown
                    title={
                      <div className="flex items-center justify-center w-8 h-8 m-auto rounded-full hover:bg-gray-200 hover_dots_drpdwn">
                        <BsThreeDots size={18} className="darkGray" />
                      </div>
                    }
                    className="bg-transparent custom-dropdown">
                    <div className="absolute right-0 z-10 w-screen max-w-xs mt-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative p-2 bg-white">
                          <NavDropdown.Item
                            onClick={this.deleteReplyByCommentId}
                            className="flex items-center px-2 py-2 hover:bg-gray-100 focus:bg--lightGray rounded--xl">
                            <div className="flex items-center justify-center w-8 h-8 mr-3 bg-gray-300 rounded-full">
                              <IoTrashOutline size={15} className="black" />
                            </div>
                            <p>Delete comment</p>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={this.editCommentByPostId}
                            className="flex items-center px-2 py-2 hover:bg-gray-100 focus:bg--lightGray rounded--xl">
                            <div className="flex items-center justify-center w-8 h-8 mr-3 bg-gray-300 rounded-full">
                              <FiEdit size={12} className="black" />
                            </div>
                            <div>
                              <p>Edit</p>
                            </div>
                          </NavDropdown.Item>
                        </div>
                      </div>
                    </div>
                  </NavDropdown>
                </div>
              )}
            </div>
            <div className="flex items-center mt-1 ml-3">
              <p
                className="text-sm cursor-pointer darkGray hover:primary--text"
                style={{
                  color: replay.isLiked ? colors.primary : null,
                }}
                onClick={this.replyToggleLike}>
                Like
              </p>
              <BsDot size={10} className="darkGray" />
              <Popup
                inverted
                className="p-2"
                position="bottom center"
                content={
                  <TimeAgo
                    className="text-sm cursor-pointer white Light"
                    created_at={replay.created_at}
                    format="dddd, MMMM Do YYYY, h:mm:ss a"
                  />
                }
                trigger={
                  <TimeAgo
                    className="text-sm cursor-pointer darkGray Light"
                    created_at={replay.created_at}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { replyId, user } = ownProps;
  const replay = state.Comment.replaysById[replyId];
  return { replay, user };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CommentRedux");
  return {
    ...ownProps,
    ...stateProps,
    deleteReplyByCommentId: () => {
      actions.deleteReplyByCommentId(dispatch, ownProps.replyId);
    },
    replyToggleLike: (data) => {
      actions.replyToggleLike(dispatch, data);
    },
    editCommentByPostId: (comment, params) => {
      dispatch(actions.editCommentByPostId(comment, params));
    },
  };
};

export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(PostCommentReply);
