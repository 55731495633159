import React, { Component } from "react";
import { Image } from "react-bootstrap";
import null_states_people from "assets/images/null_states_people.svg";

class FriendRequestsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="flex flex-col items-center justify-center mb-3 aside-2">
        <Image src={null_states_people} className="object-cover w-80 h-80" />
        <p className="text-2xl gray">
          When you have data, you'll see them here.
        </p>
      </div>
    );
  }
}

export default FriendRequestsMain;
