import React, { useState } from "react";
import { Category, Enabled } from "Modules";
import { FiPlus } from "react-icons/fi";
import { Buttonused } from "components";
import { TextareaAutosize } from "@material-ui/core";

export default function Shots() {
  const [addForm, setAddForm] = useState(false);

  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <Category title="Shots" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="m-auto w-full space-y-5">
          <div className="overflow-y-auto" id="scrollbar">
            <Enabled />
            <div className="flex items-center justify-between mx-4">
              <div className="flex items-center">
                <h2>Shots</h2>
              </div>
              <div className="w-40 ml-auto">
                <Buttonused
                  Icon={!addForm && <FiPlus />}
                  onClick={() => {
                    setAddForm(!addForm);
                  }}
                  Text={addForm ? "Save" : "Add more"}
                  className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-sm h-12 w-full mx-auto rounded--lg"
                />
              </div>
            </div>

            {addForm && (
              <form className="my-10 space-y-4 mx-4">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 ...">
                    <input
                      type="text"
                      placeholder="Add Shots"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Price"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                </div>

                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Enter Description..."
                  className="p-3 min-h-[100px] placeholder:font-light shadow-sm resize-none rounded-lg post-commnet-textInput bg-white w-full"
                />
                <div className="w-full h-[1px] bg-gray-300 rounded-full" />
              </form>
            )}

            <div className="bg-white shadow-lg overflow-hidden m-4 rounded-lg">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-black uppercase text-sm leading-normal">
                    <th className="py-4 px-6 capitalize text-[12px] text-left">
                      Type
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Price
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center max-w-[200px]">
                      Description
                    </th>

                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Jaeger Bomb</p>
                      </div>
                    </td>

                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$229</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[200px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Quisque velit nisi, pretium ut lacinia in, elementum
                          Quisque velit nisi, pretium ut lacinia in, elementum
                          id enim. Curabitur
                        </p>
                      </div>
                    </td>

                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
