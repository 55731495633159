import React, { Component } from "react";
import { Barskeleton } from "components";

class Onlyfeedskeleton extends Component {
  render() {
    return (
      <>
        <div className="m-auto container--small">
          <div className="grid gap-4 py-5 lg:grid-cols-10 sm:px-4 xs:px-4">
            <div className="lg:col-start-2 lg:col-span-8">
              <div className="p-5 bg-white rounded-lg shadow-lg">
                <div className="grid gap-3">
                  <Barskeleton />
                  <Barskeleton />
                  <Barskeleton />
                  <Barskeleton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Onlyfeedskeleton;
