import React, { Fragment, useState } from "react";
import { Category, Enabled, SlideForm } from "Modules";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Buttonused } from "components";
import { FiPlus, FiX, FiChevronRight } from "react-icons/fi";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "./Add";
import Item from "./Item";
import DeleteModal from "./DeleteModal";
import { BiLoaderAlt } from "react-icons/bi";

const { actions } = require("redux/BarEnhancement");


export default function Product() {

  const { id, slug, category_id, sub_id } = useParams();
  const dispatch = useDispatch();

  const [addForm, setAddForm] = useState(false);

  const popupModal = React.useRef(null);

  const deleteModalRef = React.useRef(null);

  const category = useSelector((state) => Object.assign({}, state.enhancement.category.data));

  const productData = useSelector((state) => Object.assign({}, state.enhancement.product.ids));
  const totalData = useSelector((state) => Object.assign({}, state.enhancement.product.total));
  const pageData = useSelector((state) => Object.assign({}, state.enhancement.product.page));
  const isMoreData = useSelector((state) => Object.assign({}, state.enhancement.product.isMore));
  const isFetching = useSelector((state) => state.enhancement.product.isFetching);

  const cat = (category?.[id] || {});

  const pCategory = (cat?.[category_id] || {});



  const data = (productData?.[id]?.[category_id] || []);
  const total = (totalData?.[id]?.[category_id] || 0);
  const page = (pageData?.[id]?.[category_id] || 1);
  const is_more = (isMoreData?.[id]?.[category_id] || false);

  React.useEffect(() => {
    if (page == 1) {
      actions.fetchProduct(dispatch, { bar_id: id, category_id: category_id, slug: slug, page: 1 });
    }


  }, [category_id]);

  const addProduct = () => {

    popupModal.current.open(id, category_id);
  }

  const fetchMore = () => {
    if (is_more) {
      actions.fetchProduct(dispatch, { bar_id: id, category_id: category_id, slug: slug, page: page });
    }

  }

  const onEdit = (product_id) => {
    popupModal.current.open(id, category_id, product_id);
  }

  const onDelete = (product_id) => {
    deleteModalRef.current.open(id, category_id, product_id);
  }

  const onEnable = (product_id, checked) => {

    const params = {
      bar_id: id,
      category_id: category_id,
      product_id: product_id,
      enabled: checked
    }
    actions.enabledBarProduct(dispatch, params);
  }

  const onChangeStatus = (category_id, enabled) => {
    const data = {
      bar_id: id,
      category_id: category_id,
      enabled: enabled
    }
    actions.updateBarCategoryStatus(dispatch, data)
  }


  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <Category title="Online Ordering" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8 mb-8">
        {pCategory.id &&
          <div className="m-auto w-full">
            <div className="flex items-center justify-between">
              {pCategory &&
                <h2>{pCategory?.name}</h2>
              }

              <span className="pl-2">({typeof total !== "object" ? total : 0})</span>
              {pCategory.id &&
                <Tooltip
                  arrow={true}
                  title={pCategory?.status == 1 ? "Disable this category" : "Enable this category"}>
                  <Checkbox
                    onChange={(e) => onChangeStatus(pCategory?.id, e.target.checked)}
                    checked={pCategory?.status == 1 ? true : false}
                    color="primary"
                  />
                </Tooltip>
              }

              <div className="w-72 ml-auto my-3 flex gap-x-3">
                <Buttonused
                  Icon={!addForm && <FiPlus />}
                  onClick={addProduct}
                  Text={"Add"}
                  className="bg--primary grow text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
                />
              </div>
            </div>
            <div className="mb-3 bg-white shadow-xl rounded-lg ">
              <div className="rounded-lg overflow-y-auto" id="scrollbar">
                <div className="bg-gray-200 text-black capitalize leading-normal grid grid-cols-5 gap-5">
                  <div className="py-4 px-6 text-left text-[13px]">Title</div>
                  <div className="py-4 px-6 text-center text-[13px]">
                    Price
                  </div>
                  <div className="py-4 px-6 text-center text-[13px]">
                    Description
                  </div>
                  <div className="py-4 px-6 text-center text-[13px]">
                    Photo
                  </div>
                  <div className="py-4 px-6 text-center text-[13px]">
                    Actions
                  </div>
                </div>
                {data && data.length > 0 && data.map((item, key) => (
                  <Item key={key} id={item} bar_id={id} category_id={category_id} onEdit={onEdit} onDelete={onDelete} onEnable={onEnable} />
                ))}
              </div>
            </div>

          </div>}
        {isFetching &&
          <div className="flex items-center justify-center">
            <BiLoaderAlt size={21} className="animate-spin text-center " />
          </div>
        }
        {is_more &&
          <Buttonused
            onClick={fetchMore}
            Text={"load more"}
            className="bg--primary grow text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg mb-4"
          />
        }

      </div>

      <Add ref={popupModal} />
      <DeleteModal ref={deleteModalRef} />

    </div>
  );
}
