import React, { Component } from "react";
import { Image, Button } from "react-bootstrap";
import colors from "config/color/color.js";
import avatar from "assets/images/avatar.webp";
import cover from "assets/images/cover.webp";
import { IoTrashOutline } from "react-icons/io5";
import { MdLock } from "react-icons/md";
import { FiPlus, FiUsers, FiEdit3 } from "react-icons/fi";
import { Dropdown } from "semantic-ui-react";
import { BsThreeDots } from "react-icons/bs";

class Saveddetailheader extends Component {
  render() {
    return (
      <div className="bg-white ">
        <div className="container--small m-auto xs:px-4 sm:px-4 ">
          <div>
            <div className="rounded-b-lg overflow-hidden">
              <div className="h-96 shadow-sm rounded-b-lg relative">
                <div className="overlay:bg--gray-400 absolute h-full w-full" />
                <Image
                  src={cover}
                  className="w-full h-full object-cover object-center"
                />
              </div>
            </div>
            <div className="py-4 xl:px-8 md:px-5 sm:px-4 xs:px-4">
              <div>
                <h2 className="Black Regular">My New</h2>
                <div className="flex items-center justify-between py-2">
                  <div className="items-center flex">
                    <MdLock color={colors.darkGray} size={22} />
                    <p className="darkGray pl-2">Only Me</p>
                  </div>
                  <div className="flex items-center justify-between">
                    <Image
                      src={avatar}
                      className="h-10 w-10 rounded-full object-cover mr-5 border"
                    />
                    <Button className="bg--primary mr-3 hover:bg--primary foucs:bg--primary flex items-center shadow-none hover:opacity-80 Regular border-0 rounded--lg h-10 px-4 transform transition duration-300 hover:scale-90">
                      <FiPlus className="mr-2" />
                      Invite
                    </Button>
                    <Dropdown
                      icon={false}
                      wrapSelection={true}
                      direction="left"
                      pointing="top left"
                      text={
                        <div>
                          <div className="bg-gray-200 rounded-lg flex items-center h-10 px-3 cursor-pointer hover:bg-gray-300 transform transition duration-300 hover:scale-90">
                            <BsThreeDots size={16} />
                          </div>
                        </div>
                      }
                    >
                      <Dropdown.Menu className="rounded--lg bg-white shadow-lg overflow-show border-0 w-96">
                        <div className="p-2">
                          <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                            <div className="mr-3">
                              <FiUsers />
                            </div>
                            <p>View members</p>
                          </div>
                          <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                            <div className="mr-3">
                              <FiEdit3 />
                            </div>
                            <p>Rename collection</p>
                          </div>
                          <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                            <div className="mr-3">
                              <IoTrashOutline />
                            </div>
                            <p>Delete collection</p>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Saveddetailheader;
