import React, { Component } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FiUserX } from "react-icons/fi";
import { VscCloseAll } from "react-icons/vsc";
import { Image } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { OptimizeImage } from "helpers";
import { Usernamepopup, MutualfriendsPopup } from "components";

class Friendscard extends Component {
  render() {
    const { friends } = this.props;
    return (
      <>
        {friends && friends.length
          ? friends.map((item, key) => (
              <div className="flex items-center justify-between border border-lightGray  rounded-xl p-sm-1 p-md-3 shadow-sm">
                <Image
                  src={OptimizeImage(item.profile_photo)}
                  alt={item.name}
                  key={key.id}
                  {...this.props}
                />
                <div className="flex-grow">
                  <Usernamepopup item={item} />
                  <MutualfriendsPopup />
                </div>
                <div className="flex items-center justify-between ">
                  <div>
                    <Dropdown
                      icon={false}
                      wrapSelection={true}
                      direction="left"
                      pointing="top"
                      text={
                        <div>
                          <div className="rounded-full flex items-center justify-center h-8 w-8 cursor-pointer hover:bg-gray-300">
                            <BsThreeDots size={20} />
                          </div>
                        </div>
                      }
                    >
                      <Dropdown.Menu className="rounded--lg bg-white shadow-lg overflow-show border-0 w-96">
                        <div className="p-2">
                          <div className="flex items-center py-2 hover:bg-gray-100 rounded-lg px-2 cursor-pointer">
                            <div className="mr-3">
                              <VscCloseAll size={20} />
                            </div>
                            <p>Unfollow</p>
                          </div>
                          <div className="flex items-center py-2 hover:bg-gray-100 rounded-lg px-2 cursor-pointer">
                            <div className="mr-3">
                              <FiUserX size={20} />
                            </div>
                            <p>Unfriend</p>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            ))
          : "No Friends"}
      </>
    );
  }
}

export default Friendscard;
