import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Form } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import IOStoggle from "components/allcomponents/iostoggle";
import { connect } from "react-redux";
import { Capitalize, OptimizeImage } from "helpers";
import { avatar as avatar2 } from "assets";
import { Avatar } from "@material-ui/core";
import { Buttonused, FriendListPopup } from "components";
import { HiOutlineArrowLeft } from "react-icons/hi";
import colors from "config/color/color.js";

class Groupcreateaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };

    this.popupRef = React.createRef();
  }

  addGroupForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addGroupForm(form);
  };

  submitGroup = () => {
    let form = Object.assign({}, this.props.form);
    var formData = new FormData();
    formData.append("general_privacy_id", this.props.privacy.id ?? 0);
    formData.append("title", form.title);
    formData.append("is_post", form.is_post);
    formData.append("is_invite", form.is_invite);
    if (form.cover) {
      formData.append("cover", form.cover);
    }
    if (form.tags && form.tags.length > 0) {
      form.tags.forEach((item, i) => {
        formData.append("tag_ids[" + i + "]", item.id);
      });
    }
    if (form.title) {
      this.props.submitGroup(formData);
      this.setState({ redirect: true });
    }
  };

  onFileChange = async (e) => {
    const data = e.target.files;
    this.addGroupForm("cover", data[0]);
  };

  render() {
    const { user } = this.props;
    const form = Object.assign({}, this.props.form);
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/groups" />;
    }

    return (
      <div className="fixed flex flex-col bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow pt-5">
          <div className="px-5">
            <div className="flex items-center">
              <Link
                to="/groups"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Groups
                </Link>
                <h2 className="text-2xl Medium">Create Group</h2>
              </div>
            </div>
          </div>
          <div
            className="px-4 overflow-y-auto group-leftside-scroll"
            id="scrollbar">
            <div className="flex items-center my-4">
              <Avatar
                src={OptimizeImage(user.profile_photo)}
                className="w-12 h-12 mr-3 rounded-full">
                <Avatar
                  src={avatar2}
                  className="object-cover w-12 h-12 mr-3 rounded-full"
                />
              </Avatar>
              <div>
                <p>
                  {Capitalize(user.first_name)} {Capitalize(user.last_name)}
                </p>
                <p className="text-sm Light darkGray">Admin</p>
              </div>
            </div>
            <div className="mb-4">
              <TextField
                label="Group Name"
                className="w-full rounded-lg important:border border-lightGray"
                variant="outlined"
                value={form.title}
                onChange={(e) => this.addGroupForm("title", e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Dropdown
                className="border gray rounded--lg"
                placeholder="Choose Priavcy"
                fluid
                selection
                value={this.props.privacy ? this.props.privacy.name : "Public"}
                onChange={(e, { value }) =>
                  this.props.setGroupPrivacy({ name: value, id: 0 })
                }
                options={[
                  {
                    key: "Public",
                    text: "Public",
                    value: "Public",
                  },
                  {
                    key: "Private",
                    text: "Private",
                    value: "Private",
                  },
                ]}
              />
              <p className="m-1 Light fs-12 darkGray">
                Members and visitors can post in the group. Admins can review
                first-time participants.
              </p>
            </div>
            {form && form.tags && form.tags.length > 0 && (
              <div className="mx-2 mb-3">
                <p className="mb-2 text-sm uppercase darkGray">Tagged</p>
                <div className="flex flex-wrap p-3 pb-1 border rounded-md">
                  {form.tags &&
                    form.tags.map((item, key) => (
                      <p
                        key={key}
                        className="flex items-center h-10 px-2 py-0 mb-2 mr-2 border-0 shadow-none bg-primary-chip fs-12 Regular rounded--lg justify-evenly">
                        {Capitalize(item.first_name)}{" "}
                        {Capitalize(item.last_name)}
                      </p>
                    ))}
                </div>
              </div>
            )}
            <div className="mb-5">
              <Buttonused
                Text="Invite friends"
                className="flex items-center justify-center w-full transition duration-300 transform bg--lightPrimary h-11 primary rounded-xl hover:scale-90"
                onClick={() => this.popupRef.current.open()}
              />
            </div>
            <div className="flex items-center justify-between mb-5">
              <p>Allow members to post in a group</p>
              <IOStoggle
                value={form.is_post}
                onChange={(e) => this.addGroupForm("is_post", e.target.checked)}
              />
            </div>

            <div className="flex items-center justify-between mb-5">
              <p>Members can invite others memebrs</p>
              <IOStoggle
                value={form.is_invite}
                onChange={(e) =>
                  this.addGroupForm("is_invite", e.target.checked)
                }
              />
            </div>
            <div className="mb-4" onChange={this.onFileChange}>
              <label for="formFileSm" className="inline-block form-label">
                Upload banner
              </label>
              <Form.Control type="file" onChange={this.onFileChange} />
            </div>
          </div>
        </div>
        <div className="p-4 border-t shadow-sm">
          <Buttonused
            Text="Create"
            className="flex items-center justify-center w-full transition duration-300 transform bg--lightPrimary h-11 primary rounded-xl hover:scale-90"
            onClick={this.submitGroup}
          />
        </div>

        <FriendListPopup
          ref={this.popupRef}
          title="Invite friends"
          isCreateGroupInvite={true}
          isTag={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.Group.form,
    privacy: state.Group.privacy,
    user: typeof state.User.user !== "undefined" ? state.User.user : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/GroupRedux");
  return {
    ...ownProps,
    ...stateProps,
    submitGroup: (data) => {
      actions.submitGroup(dispatch, data);
    },
    addGroupForm: (data) => {
      dispatch(actions.addGroupForm(data));
    },
    setGroupPrivacy: (data) => {
      dispatch(actions.setGroupPrivacy(data));
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(Groupcreateaside);
