import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";
import { Postskeleton } from "components";

class Profileskeleton extends Component {
  render() {
    return (
      <>
        <div className="bg-white headercover">
          <div className="m-auto container--small xs:px-4 sm:px-4 ">
            <div className="relative overflow-hidden shadow-sm h-96 rounded-b-xl">
              <Skeleton animation="pulse" variant="rect" height={"100%"} />
            </div>
            <div className="flex flex-col items-center justify-center -mt-40 text-center">
              <Skeleton
                variant="rect"
                height={180}
                className="rounded-full"
                width={180}
              />
              <Skeleton
                variant="rect"
                height={10}
                className="mt-4 rounded-full"
                width={200}
              />
            </div>
          </div>
        </div>

        <div className="m-auto mt-3 container--small xl:px-12 md:px-5 sm:px-4 xs:px-4 ">
          <div className="row ">
            <div className="col-md-5 col-12 ">
              <div className="hidden rounded-lg md:block">
                <div className="px-3 pb-0 overflow-hidden bg-white rounded-lg shadow-sm">
                  <div className="-mx-4">
                    <Skeleton animation="pulse" variant="rect" height={170} />
                  </div>
                  <div className="items-center my-3">
                    <div className="flex items-center justify-between flex-grow">
                      <div>
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={200}
                        />
                      </div>
                      <div className="ml-3">
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={40}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between flex-grow mt-2">
                      <div>
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={150}
                        />
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="mt-2 rounded-full"
                          width={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-3 pb-0 mt-3 overflow-hidden bg-white rounded-lg shadow-sm">
                  <div className="-mx-4">
                    <Skeleton variant="rect" height={170} />
                  </div>
                  <div className="items-center my-3">
                    <div className="flex items-center justify-between flex-grow">
                      <div>
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={200}
                        />
                      </div>
                      <div className="ml-3">
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={40}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between flex-grow mt-2">
                      <div>
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={150}
                        />
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="mt-2 rounded-full"
                          width={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-3 pb-0 mt-3 overflow-hidden bg-white rounded-lg shadow-sm">
                  <div className="-mx-4">
                    <Skeleton variant="rect" height={170} />
                  </div>
                  <div className="items-center my-3">
                    <div className="flex items-center justify-between flex-grow">
                      <div>
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={200}
                        />
                      </div>
                      <div className="ml-3">
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={40}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between flex-grow mt-2">
                      <div>
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={150}
                        />
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="mt-2 rounded-full"
                          width={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-3 pb-0 mt-3 overflow-hidden bg-white rounded-lg shadow-sm">
                  <div className="-mx-4">
                    <Skeleton variant="rect" height={170} />
                  </div>
                  <div className="items-center my-3">
                    <div className="flex items-center justify-between flex-grow">
                      <div>
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={200}
                        />
                      </div>
                      <div className="ml-3">
                        <Skeleton
                          animation="pulse"
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={40}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between flex-grow mt-2">
                      <div>
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="rounded-full"
                          width={150}
                        />
                        <Skeleton
                          variant="rect"
                          height={8}
                          className="mt-2 rounded-full"
                          width={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="">
                <>
                  <div className="mb-3">
                    <Postskeleton />
                  </div>
                  <div className="mb-3">
                    <Postskeleton />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Profileskeleton;
