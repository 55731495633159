import loadable from "@loadable/component";
import { componentDelay } from "helpers";
import {
  Flirtsasideskeleton,
  Flirtsdetailskeleton,
  Onlyfeedskeleton,
  Profileskeleton,
  Suspenseskeleton,
  Withasideskeleton,
} from "components";

// auth screens
const LoginScreen = loadable(() => import("./Auths/LoginScreen"));
const RegisterScreen = loadable(() => import("./Auths/RegisterScreen"));
const ForgotpswdScreen = loadable(() => import("./Auths/ForgotpswdScreen"));

const FeedScreen = loadable(
  () => componentDelay(componentDelay(import("./FeedScreen"))),
  {
    fallback: <Suspenseskeleton />,
  }
);

const MeScreen = loadable(
  () => componentDelay(componentDelay(import("./MeScreen"))),
  {
    fallback: <Suspenseskeleton />,
  }
);

const MyFeedScreen = loadable(
  () => componentDelay(componentDelay(import("./MyFeedScreen"))),
  {
    fallback: <Suspenseskeleton />,
  }
);

// friends screens
const FriendsScreen = loadable(
  () => componentDelay(import("./Friends/FriendsScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const PendingInvitesScreen = loadable(
  () => componentDelay(import("./Friends/PendingInvitesScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const FriendSuggestionsScreen = loadable(
  () => componentDelay(import("./Friends/FriendSuggestionsScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);

const FriendsAsideScreen = loadable(
  () => componentDelay(import("./Friends/FriendsAsideScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const AllFriendsScreen = loadable(
  () => componentDelay(import("./Friends/AllFriendsScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const FamilyRelativesScreen = loadable(
  () => componentDelay(import("./Friends/FamilyRelativesScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const BusinessAssociateScreen = loadable(
  () => componentDelay(import("./Friends/BusinessAssociateScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const FollowersScreen = loadable(
  () => componentDelay(import("./Friends/FollowersScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const SentInvitesScreen = loadable(
  () => componentDelay(import("./Friends/SentInvitesScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);

// profile screens
const ProfileScreen = loadable(
  () => componentDelay(import("./ProfileScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const AboutScreen = loadable(
  () => componentDelay(import("./Profile/AboutScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const WorkAndEducationScreen = loadable(
  () => componentDelay(import("./Profile/WorkAndEducationScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const PlaceLivedScreen = loadable(
  () => componentDelay(import("./Profile/PlaceLivedScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ContactAndBasicInfoScreen = loadable(
  () => componentDelay(import("./Profile/ContactAndBasicInfoScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const FamilyAndRelationshipsScreen = loadable(
  () => componentDelay(import("./Profile/FamilyAndRelationshipsScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileAllFriendsScreen = loadable(
  () => componentDelay(import("./Profile/ProfileAllFriendsScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const FriendBirthdaysScreen = loadable(
  () => componentDelay(import("./Profile/FriendBirthdaysScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileFamilyAndRelativesScreen = loadable(
  () => componentDelay(import("./Profile/ProfileFamilyAndRelativesScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileBusinessAssociateScreen = loadable(
  () => componentDelay(import("./Profile/ProfileBusinessAssociateScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileCoWorkersScreen = loadable(
  () => componentDelay(import("./Profile/ProfileCoWorkersScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfilCustomersScreen = loadable(
  () => componentDelay(import("./Profile/ProfilCustomersScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileFollowersScreen = loadable(
  () => componentDelay(import("./Profile/ProfileFollowersScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileBlockFriendsScreen = loadable(
  () => componentDelay(import("./Profile/ProfileBlockFriendsScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfilePhotosScreen = loadable(
  () => componentDelay(import("./Profile/ProfilePhotosScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const ProfileVideosScreen = loadable(
  () => componentDelay(import("./Profile/ProfileVideosScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
// notification screen
const NotificationsScreen = loadable(
  () => componentDelay(import("./NotificationsScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
// groups screen
const GroupFeedScreen = loadable(
  () => componentDelay(import("./Groups/GroupFeedScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const GroupDiscoverScreen = loadable(
  () => componentDelay(import("./Groups/GroupDiscoverScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const GroupCreateScreen = loadable(
  () => componentDelay(import("./Groups/GroupCreateScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const GroupDetailsScreen = loadable(
  () => componentDelay(import("./Groups/GroupDetails/GroupDetailsScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const GroupAboutScreen = loadable(
  () => componentDelay(import("./Groups/GroupDetails/GroupAboutScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const GroupMembersScreen = loadable(
  () => componentDelay(import("./Groups/GroupDetails/GroupMembersScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const GroupMediaScreen = loadable(
  () => componentDelay(import("./Groups/GroupDetails/GroupMediaScreen")),
  {
    fallback: <Profileskeleton />,
  }
);

// bars screen
const BarsScreen = loadable(() => componentDelay(import("./Bars/BarsScreen")), {
  fallback: <Withasideskeleton />,
});
const BarCreateScreen = loadable(
  () => componentDelay(import("./Bars/BarCreateScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const BarDetailsScreen = loadable(
  () => componentDelay(import("./Bars/BarDetailsScreen")),
  {
    fallback: <Profileskeleton />,
  }
);

// restaurants screen
const RestaurantsScreen = loadable(
  () => componentDelay(import("./Restaurants/RestaurantsScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

// save screens
const SavedScreen = loadable(
  () => componentDelay(import("./Saved/SavedScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const SavedSettingsScreen = loadable(
  () => componentDelay(import("./Saved/SavedSettingsScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const SavedDetailScreen = loadable(
  () => componentDelay(import("./Saved/SavedDetailScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

// flirts screens
const AvailableFlirtScreen = loadable(
  () => componentDelay(import("./Flirts/AvailableFlirtScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const FlirtSettingsScreen = loadable(
  () => componentDelay(import("./Flirts/FlirtSettingsScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const ActiveFlirtScreen = loadable(
  () => componentDelay(import("./Flirts/ActiveFlirtScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const RequestedFlirtScreen = loadable(
  () => componentDelay(import("./Flirts/RequestedFlirtScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const PendingFlirtScreen = loadable(
  () => componentDelay(import("./Flirts/PendingFlirtScreen")),
  {
    fallback: <Flirtsasideskeleton />,
  }
);
const FlirtDetailScreen = loadable(
  () => componentDelay(import("./Flirts/FlirtDetailScreen")),
  {
    fallback: <Flirtsdetailskeleton />,
  }
);

// leaderboard screen
const LeaderboardScreen = loadable(
  () => componentDelay(import("./Leaderboard/LeaderboardScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const LeaderboardInterScreen = loadable(
  () => componentDelay(import("./Leaderboard/LeaderboardInterScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
// bill of rights screen
const BillofRightScreen = loadable(
  () => componentDelay(import("./BillofRightScreen")),
  {
    fallback: <Onlyfeedskeleton />,
  }
);
// share us screen
const ShareUsScreen = loadable(
  () => componentDelay(import("./ShareUsScreen")),
  {
    fallback: <Onlyfeedskeleton />,
  }
);
// how to screen
const HowToScreen = loadable(() => componentDelay(import("./HowToScreen")), {
  fallback: <Onlyfeedskeleton />,
});
// access your information
const AccessInfoScreen = loadable(
  () => componentDelay(import("./AccessInformation")),
  {
    fallback: <Onlyfeedskeleton />,
  }
);
// comms screen
const CommsScreen = loadable(() => componentDelay(import("./CommsScreen")), {
  fallback: <Onlyfeedskeleton />,
});
// my companies screen
const MyCompaniesScreen = loadable(
  () => componentDelay(import("./MyCompanies/MyCompaniesScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const CompanyDetailScreen = loadable(
  () => componentDelay(import("./MyCompanies/Details/CompanyDetailScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const LogoBannerScreen = loadable(
  () => componentDelay(import("./MyCompanies/Details/Logo&BannerScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const MyKeywordScreen = loadable(
  () => componentDelay(import("./MyCompanies/Details/MyKeywordScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const PostJobStartedScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/PostJob/PostJobStartedScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const PostJobDetailScreen = loadable(
  () =>
    componentDelay(import("./MyCompanies/Details/PostJob/PostJobDetailScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const PostJobDescriptionScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/PostJob/PostJobDescriptionScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const PostJobSettingScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/PostJob/PostJobSettingScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const PostJobQualificationsScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/PostJob/PostJobQualificationsScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const PostJobPreviewScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/PostJob/PostJobPreviewScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
// job posting screen
const MyJobsScreen = loadable(
  () =>
    componentDelay(import("./MyCompanies/Details/MyJobPosting/MyJobsScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const MyJobsStatusInActiveScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/MyJobPosting/MyJobsStatusInActiveScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const MyJobsStatusActiveScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/MyJobPosting/MyJobsStatusActiveScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const CandidatesScreen = loadable(
  () =>
    componentDelay(
      import("./MyCompanies/Details/MyJobPosting/Candidates/CandidatesScreen")
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const CandidatesAwaitingScreen = loadable(
  () =>
    componentDelay(
      import(
        "./MyCompanies/Details/MyJobPosting/Candidates/CandidatesAwaitingScreen"
      )
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const CandidatesReviewedScreen = loadable(
  () =>
    componentDelay(
      import(
        "./MyCompanies/Details/MyJobPosting/Candidates/CandidatesReviewedScreen"
      )
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const CandidatesContactingScreen = loadable(
  () =>
    componentDelay(
      import(
        "./MyCompanies/Details/MyJobPosting/Candidates/CandidatesContactingScreen"
      )
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const CandidatesHiredScreen = loadable(
  () =>
    componentDelay(
      import(
        "./MyCompanies/Details/MyJobPosting/Candidates/CandidatesHiredScreen"
      )
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
const CandidatesRejectedScreen = loadable(
  () =>
    componentDelay(
      import(
        "./MyCompanies/Details/MyJobPosting/Candidates/CandidatesRejectedScreen"
      )
    ),
  {
    fallback: <Suspenseskeleton />,
  }
);
// public jobs
const PublicJobsScreen = loadable(
  () => componentDelay(import("./PublicJobs/PublicJobsScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const PublicJobsDetailScreen = loadable(
  () => componentDelay(import("./PublicJobs/PublicJobsDetailScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
// public jobs apply = loadable(() => componentDelay(import(screen)s
const ContactInfoFormScreen = loadable(
  () => componentDelay(import("./PublicJobs/ApplyForm/ContactInfoFormScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const DocumentScreen = loadable(
  () => componentDelay(import("./PublicJobs/ApplyForm/DocumentScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const ResumeScreen = loadable(
  () => componentDelay(import("./PublicJobs/ApplyForm/ResumeScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);
const QuestionsScreen = loadable(
  () => componentDelay(import("./PublicJobs/ApplyForm/QuestionsScreen")),
  {
    fallback: <Suspenseskeleton />,
  }
);

// events screen

const NearMeScreen = loadable(
  () => componentDelay(import("./Events/NearMeScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const EventDetailsScreen = loadable(
  () => componentDelay(import("./Events/EventDetails/EventDetailsScreen")),
  {
    fallback: <Profileskeleton />,
  }
);
const GoingScreen = loadable(
  () => componentDelay(import("./Events/GoingScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const PendingScreen = loadable(
  () => componentDelay(import("./Events/PendingScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const InterestedScreen = loadable(
  () => componentDelay(import("./Events/InterestedScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const MyEventsScreen = loadable(
  () => componentDelay(import("./Events/MyEventsScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
const CalendarScreen = loadable(
  () => componentDelay(import("./Events/CalendarScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);
// feedback screen
const FeedbackScreen = loadable(
  () => componentDelay(import("./FeedbackScreen")),
  {
    fallback: <Onlyfeedskeleton />,
  }
);

//My band Screen
const MyBandScreen = loadable(
  () => componentDelay(import("./Bands/MyBandScreen")),
  {
    fallback: <Onlyfeedskeleton />,
  }
);

//Live Music
const LiveMusicScreen = loadable(
  () => componentDelay(import("./LiveMusicScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

// search result screen
const PostResultsScreen = loadable(
  () => componentDelay(import("./SearchResults/PostResultsScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const PeopleResultsScreen = loadable(
  () => componentDelay(import("./SearchResults/PeopleResultsScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const GroupResultsScreen = loadable(
  () => componentDelay(import("./SearchResults/GroupResultsScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const SearchScreen = loadable(() => componentDelay(import("./SearchScreen")), {
  fallback: <Withasideskeleton />,
});

// MessengerScreen;

const MessengerScreen = loadable(
  () => componentDelay(import("./MessengerScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

const BookingRequestScreen = loadable(
  () => componentDelay(import("./BookingRequestScreen")),
  {
    fallback: <Withasideskeleton />,
  }
);

export {
  // auth screens
  LoginScreen,
  RegisterScreen,
  ForgotpswdScreen,
  // notifcation screen
  NotificationsScreen,
  // feed screens
  FeedScreen,
  MyFeedScreen,
  // friends screen
  FriendsAsideScreen,
  FriendsScreen,
  PendingInvitesScreen,
  FriendSuggestionsScreen,
  AllFriendsScreen,
  FamilyRelativesScreen,
  BusinessAssociateScreen,
  FollowersScreen,
  SentInvitesScreen,
  // profile screens
  ProfileScreen,
  AboutScreen,
  WorkAndEducationScreen,
  PlaceLivedScreen,
  ContactAndBasicInfoScreen,
  FamilyAndRelationshipsScreen,
  ProfileAllFriendsScreen,
  FriendBirthdaysScreen,
  ProfileFamilyAndRelativesScreen,
  ProfileBusinessAssociateScreen,
  ProfileCoWorkersScreen,
  ProfilCustomersScreen,
  ProfileFollowersScreen,
  ProfileBlockFriendsScreen,
  ProfilePhotosScreen,
  ProfileVideosScreen,
  // groups screens
  GroupFeedScreen,
  GroupDiscoverScreen,
  GroupCreateScreen,
  GroupDetailsScreen,
  GroupAboutScreen,
  GroupMembersScreen,
  GroupMediaScreen,
  // bar screens
  BarsScreen,
  BarCreateScreen,
  BarDetailsScreen,
  // restaurants screen
  RestaurantsScreen,
  // saved screens
  SavedScreen,
  SavedSettingsScreen,
  SavedDetailScreen,
  // flirts screens
  AvailableFlirtScreen,
  FlirtSettingsScreen,
  ActiveFlirtScreen,
  RequestedFlirtScreen,
  PendingFlirtScreen,
  FlirtDetailScreen,
  // leaderboard screen
  LeaderboardScreen,
  LeaderboardInterScreen,
  // bill of rights
  BillofRightScreen,
  // share us screen
  ShareUsScreen,
  // how to screen
  HowToScreen,
  // access your information
  AccessInfoScreen,
  // comms screen
  CommsScreen,
  // my companies screen
  MyCompaniesScreen,
  CompanyDetailScreen,
  LogoBannerScreen,
  MyKeywordScreen,
  PostJobStartedScreen,
  PostJobDetailScreen,
  PostJobDescriptionScreen,
  PostJobSettingScreen,
  PostJobQualificationsScreen,
  PostJobPreviewScreen,
  // job posting screen
  MyJobsScreen,
  MyJobsStatusInActiveScreen,
  MyJobsStatusActiveScreen,
  CandidatesScreen,
  CandidatesAwaitingScreen,
  CandidatesReviewedScreen,
  CandidatesContactingScreen,
  CandidatesHiredScreen,
  CandidatesRejectedScreen,
  // public jobs
  PublicJobsScreen,
  PublicJobsDetailScreen,
  // public job apply form screen
  ContactInfoFormScreen,
  DocumentScreen,
  ResumeScreen,
  QuestionsScreen,
  // events screen
  NearMeScreen,
  EventDetailsScreen,
  GoingScreen,
  PendingScreen,
  InterestedScreen,
  MyEventsScreen,
  CalendarScreen,
  // my band screen
  MyBandScreen,
  //Live Music
  LiveMusicScreen,
  // feedback screen
  FeedbackScreen,
  // search results screen
  PostResultsScreen,
  PeopleResultsScreen,
  GroupResultsScreen,
  MeScreen,
  SearchScreen,
  // MessengerScreen
  MessengerScreen,
  BookingRequestScreen,
};
