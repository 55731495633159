import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FiBookmark, FiEdit } from "react-icons/fi";
import { BsThreeDots, BsDot, BsFillClockFill } from "react-icons/bs";
import { IoEarthSharp, IoTrashOutline } from "react-icons/io5";
import { ImUsers } from "react-icons/im";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import {
  Postfooter,
  Fullscreenpopup,
  ImageGrid,
  TimeAgo,
  Savedcollectionpopup,
  Reportpopup,
  Avatarimage,
} from "components";
import { Capitalize } from "helpers";
import { connect } from "react-redux";
import Share from "./share";
import { HiChevronRight } from "react-icons/hi";
import { Emitter } from "common";
import { FaUserMinus, FaUserTimes } from "react-icons/fa";
import { MdComment } from "react-icons/md";
import { AiFillLock } from "react-icons/ai";

const isValidURL = (text) => {
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return (text || "").match(urlRegex);
};
class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fullscreenRef = React.createRef();
    this.savedCollectionRef = React.createRef();
    this.reportRef = React.createRef();
    this.reactionsDetailRef = React.createRef();
  }

  deletePost = (item) => {
    this.props.deletePost(item);
  };

  sharePostContainer = (share) => {
    const item = Object.assign({}, share);
    if (Object.keys(item).length > 0) {
      return <Share item={item} />;
    } else {
      return null;
    }
  };

  reactionsPopupOpen = () => {
    this.reactionsDetailRef.current.reactionsPopupOpen();
  };

  editPost = () => {
    const { item } = this.props;
    Emitter.emit("OPEN_EDIT_MODAL", item);
  };

  render() {
    let { item, user } = this.props;
    item = typeof item !== "undefined" ? item : { user: {} };
    return (
      <div className="p-5 pb-0 bg-white rounded-lg shadow-sm post">
        <div className="flex items-center">
          <div className="mr-2 ">
            <Link to={`/${item.user.user_name}`} className="Medium black">
              <Avatarimage
                imageSize={{ width: 46, height: 46 }}
                item={item.user}
              />
            </Link>
          </div>
          <div className="flex-grow">
            <div className="flex items-center">
              <div className="flex-grow">
                <div className="gray Light flex items-center flex-grow flex-wrap space-x-1.5">
                  <Link to={`/${item.user.user_name}`} className=" black">
                    {Capitalize(item.user.first_name)}{" "}
                    {Capitalize(item.user.last_name)}
                  </Link>
                  {item.group && (
                    <>
                      <HiChevronRight />
                      <Link to={"/groups/details/" + item.group.id}>
                        <p className="Medium black">{item.group.title}</p>
                      </Link>
                    </>
                  )}
                  {item.post_tags && item.post_tags.length > 0 && (
                    <p className="fs-14 Light darkGray">is with</p>
                  )}
                  {item.post_tags &&
                    item.post_tags.map((tag, key) => (
                      <>
                        <Link
                          to={`/${tag.user_name}`}
                          className="black hover:underline hover:black"
                          key={key}>
                          {tag.first_name}
                          {item.post_tags.length - 2 === key ? null : item
                              .post_tags.length -
                              1 ===
                            key ? null : (
                            <span
                              className="Regular darkGray ">
                              ,
                            </span>
                          )}
                        </Link>

                        {item.post_tags.length - 2 === key ? (
                          <p className="fs-14 Light darkGray">and</p>
                        ) : null}
                      </>
                    ))}
                </div>
                <div className="flex items-center">
                  <TimeAgo
                    className="text-sm gray Light"
                    created_at={item.created_at}
                  />
                  <BsDot className="gray" />
                  {item.general_privacy_id === 1 && (
                    <IoEarthSharp className="gray" />
                  )}
                  {item.general_privacy_id === 2 && (
                    <ImUsers className="gray" />
                  )}
                  {item.general_privacy_id === 3 && (
                    <AiFillLock className="gray" />
                  )}
                </div>
              </div>
              <div>
                <Popover className="relative">
                  <Popover.Button className="flex items-center justify-center m-auto rounded-full h-9 w-9 hover:bg-gray-100">
                    <BsThreeDots size={20} className="darkGray" />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-0"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-0">
                    <Popover.Panel className="absolute right-0 z-50 w-screen max-w-sm mt-3 transform sm:px-0 lg:ml-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative p-2 bg-white">
                          {item.user_id === user.id ? (
                            <>
                              <div
                                onClick={this.editPost}
                                className="flex px-2 py-2 cursor-pointer hover:bg-gray-100 focus:bg--lightGray rounded--xl">
                                <FiEdit size={20} className="mt-1 mr-2 " />
                                <div>
                                  <p>Edit post</p>
                                  <p className="text-sm Light darkGray">
                                    Edit text, meida and tags friends
                                  </p>
                                </div>
                              </div>
                              <div onClick={() => this.deletePost(item)}>
                                <Popover.Button className="flex w-full px-2 py-2 cursor-pointer hover:bg-gray-100 focus:bg--lightGray rounded--xl">
                                  <IoTrashOutline
                                    size={20}
                                    className="mt-1 mr-2 "
                                  />
                                  <div>
                                    <p className="text-left">Delete post</p>
                                    <p className="text-sm Light darkGray">
                                      Delete post permanently
                                    </p>
                                  </div>
                                </Popover.Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                onClick={() =>
                                  this.savedCollectionRef.current.collectionPopupOpen()
                                }
                                className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                                <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                                  <FiBookmark size={16} />
                                </div>
                                <div>
                                  <p>Save post</p>
                                  <p className="text-sm Light darkGray">
                                    add this to your save items.
                                  </p>
                                </div>
                              </div>
                              {item.user.is_friend && (
                                <>
                                  <div className="my-1 divider" />

                                  <div
                                    onClick={() =>
                                      !item.user.is_snoozed
                                        ? this.props.snoozeFriend(item.user.id)
                                        : this.props.unsnoozeFriend(
                                            item.user.id
                                          )
                                    }
                                    className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                                    <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                                      <BsFillClockFill size={16} />
                                    </div>
                                    <div>
                                      <p>
                                        {!item.user.is_snoozed
                                          ? "Unfollow for 30 days"
                                          : "Undo Unfollow for 30 days"}
                                      </p>
                                      <p className="text-sm Light darkGray">
                                        {!item.user.is_snoozed
                                          ? "Stop seeing posts but stay friend."
                                          : "Start seeing posts."}
                                      </p>
                                    </div>
                                  </div>
                                  {!item.user.is_blocked && (
                                    <div
                                      onClick={() =>
                                        this.props.unfriend(item.user.id)
                                      }
                                      className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                                      <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                                        <FaUserMinus size={18} />
                                      </div>
                                      <div>
                                        <p>Unfriend</p>
                                        <p className="text-sm Light darkGray">
                                          Remove{" "}
                                          {Capitalize(item.user.first_name)} as
                                          a friend
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="my-1 divider" />

                                  <div
                                    onClick={() =>
                                      this.props.blockToggle(item.user.id)
                                    }
                                    className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                                    <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                                      <FaUserTimes size={18} />
                                    </div>
                                    <div>
                                      <p>
                                        {item.user.is_blocked
                                          ? "Unblock"
                                          : "Block"}
                                      </p>
                                      <p className="text-sm Light darkGray">
                                        You won't be able to see or contact each
                                        other.
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}

                              <div
                                onClick={() =>
                                  this.reportRef.current.reportPopupOpen()
                                }
                                className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                                <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                                  <MdComment size={16} />
                                </div>
                                <div>
                                  <p>Find support or report post</p>
                                  <p className="text-sm Light darkGray">
                                    I'm concerned about this post.
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p className="Light">{item.post_text}</p>
          {!!isValidURL(item.post_text) && (
            <div>
              <LinkPreview url={isValidURL(item.post_text)} width="100%" />
            </div>
          )}
          {this.sharePostContainer(item.share)}
          <div
            onClick={() => this.fullscreenRef.current.fullscreenOpen()}
            className="cursor-pointer">
            <ImageGrid item={item} />
          </div>
          <Postfooter
            reactionsPopupOpen={this.reactionsPopupOpen}
            item={item}
            overflowTrue={this.overflowTrue}
          />
        </div>
        <Fullscreenpopup item={item} ref={this.fullscreenRef} {...this.props} />
        <Savedcollectionpopup item={item} ref={this.savedCollectionRef} />
        <Reportpopup item={item} ref={this.reportRef} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { item } = ownProps;
  return {
    user: state.User.user,
    post: state.Feed.item[item.id],
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/FeedRedux");
  return {
    ...ownProps,
    ...stateProps,
    deletePost: (data) => {
      actions.deletePostFeed(dispatch, data);
    },
    snoozeFriend: (item) => {
      actions.snoozeFriend(dispatch, item);
    },
    unfriend: (friend_id) => {
      actions.unfriend(dispatch, friend_id);
    },
    unsnoozeFriend: (item) => {
      actions.unsnoozeFriend(dispatch, item);
    },
    blockToggle: (friend_id) => {
      actions.blockToggle(dispatch, friend_id);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(React.memo(Post));
