import React, { Component } from "react";
import { Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import colors from "config/color/color";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Checkbox, TextareaAutosize } from "@material-ui/core";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FiX } from "react-icons/fi";

class Postjobqualifications extends Component {
  constructor() {
    super();
    this.state = {
      Relocate: false,
      interview: false,
      education: false,
      experience: false,
      language: false,
      certificate: false,
      travel: false,
      security: true,
      shift: false,
      skills: true,
      custom: false,
      posted: true,
      collapse: true,
    };
  }
  render() {
    return (
      <div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <div>
            {this.state.Relocate ? (
              <div className="mb-4 overflow-hidden border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>
                      Will you be able to reliably commute or relocate to New
                      York, NY 10278 for this job?
                    </p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ Relocate: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4">
                    <h4>Applicant should be able to:</h4>
                    <div className="flex items-center px-2 border rounded-xl">
                      <Checkbox />
                      <p>Deal Breaker</p>
                    </div>
                  </div>

                  <RadioGroup className="ml-3">
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label=" Reliably commute or planning to relocate before starting
                      work"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="Reliably commute or willing to relocate with an
                      employer-provided relocation package"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                  </RadioGroup>
                </div>
              </div>
            ) : null}
            {this.state.education ? (
              <div className="mb-4 overflow-hidden border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>
                      What is the highest level of education you have completed?
                    </p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ education: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4">
                    <h4>Minimum education level:</h4>
                    <div className="flex items-center px-2 border rounded-xl">
                      <Checkbox />
                      <p>Deal Breaker</p>
                    </div>
                  </div>

                  <RadioGroup className="ml-3">
                    <FormControlLabel
                      value="Middle"
                      control={<Radio />}
                      label="Middle School"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="High"
                      control={<Radio />}
                      label="High School"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="Intermediate"
                      control={<Radio />}
                      label="Intermediate"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="Bachelor"
                      control={<Radio />}
                      label="Bachelor's"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="Master"
                      control={<Radio />}
                      label="Master's"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />

                    <FormControlLabel
                      value="Doctorate"
                      control={<Radio />}
                      label="Doctorate"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                  </RadioGroup>
                </div>
              </div>
            ) : null}
            {this.state.experience ? (
              <div className="mb-4 border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100 rounded-t-xl">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>How many years of _________ experience do you have?</p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ experience: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4">
                    <h4>At least:</h4>
                    <div className="flex items-center px-2 border rounded-xl">
                      <Checkbox />
                      <p>Deal Breaker</p>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-2">
                    <Dropdown
                      className="border gray rounded--xl"
                      placeholder="Select"
                      fluid
                      selection
                      options={[
                        {
                          key: "1 year",
                          text: "1 year",
                          value: "1 year",
                        },
                        {
                          key: "2 years",
                          text: "2 years",
                          value: "2 years",
                        },
                        {
                          key: "3 years",
                          text: "3 years",
                          value: "3 years",
                        },
                        {
                          key: "4 years",
                          text: "4 years",
                          value: "4 years",
                        },
                        {
                          key: "5 years +",
                          text: "5 years +",
                          value: "5 years +",
                        },
                      ]}
                    />
                    <div className="flex items-center col-span-2">
                      <p className="mr-2">of</p>
                      <div className="flex items-center pl-3 border rounded--xl">
                        <Form.Control className="h-12 border-0 shadow-none rounded--xl" />
                      </div>
                      <p className="ml-2">experience</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.interview ? (
              <div className="mb-4 border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100 rounded-t-xl">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>
                      Please list 2-3 dates and time ranges that you could do an
                      interview.
                    </p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ interview: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between">
                    <h4>
                      Ask applicants to list some dates and times they could do
                      an interview
                    </h4>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.language ? (
              <div className="mb-4 border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100 rounded-t-xl">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>Do you speak _________ ?</p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ language: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4"></div>

                  <div className="grid grid-cols-3 gap-2">
                    <div className="flex items-center col-span-2 ">
                      <h4 className="mr-3">Speaks:</h4>
                      <div className="flex items-center flex-grow pl-3 border rounded--xl">
                        <Form.Control className="h-12 border-0 shadow-none rounded--xl" />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="flex items-center flex-grow px-2 border rounded-xl">
                        <Checkbox />
                        <p>Deal Breaker</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.certificate ? (
              <div className="mb-4 border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100 rounded-t-xl">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>Do you have a valid _________ ?</p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ certificate: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4"></div>

                  <div className="grid grid-cols-3 gap-2">
                    <div className="flex items-center col-span-2 ">
                      <h4 className="mr-3">Valid:</h4>
                      <div className="flex items-center flex-grow pl-3 border rounded--xl">
                        <Form.Control className="h-12 border-0 shadow-none rounded--xl" />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="flex items-center flex-grow px-2 border rounded-xl">
                        <Checkbox />
                        <p>Deal Breaker</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.shift ? (
              <div className="mb-4 overflow-hidden border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>Which shift(s) are you available to work?</p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ shift: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4">
                    <h4>Available to work the following shift(s):</h4>
                    <div className="flex items-center px-2 border rounded-xl">
                      <Checkbox />
                      <p>Deal Breaker</p>
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center mb-2 border rounded-xl">
                      <Checkbox />
                      <p>Day Shift</p>
                    </div>
                    <div className="flex items-center mb-2 border rounded-xl">
                      <Checkbox />
                      <p>Night Shift</p>
                    </div>
                    <div className="flex items-center mb-2 border rounded-xl">
                      <Checkbox />
                      <p>Overnight Shift</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.travel ? (
              <div className="mb-4 overflow-hidden border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>
                      What percentage of the time are you willing to travel for
                      work?
                    </p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ travel: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4">
                    <h4>Willing to travel up to _________ of the time:</h4>
                    <div className="flex items-center px-2 border rounded-xl">
                      <Checkbox />
                      <p>Deal Breaker</p>
                    </div>
                  </div>

                  <RadioGroup className="ml-3">
                    <FormControlLabel
                      value="25"
                      control={<Radio />}
                      label="25%"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="50"
                      control={<Radio />}
                      label="50%"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="75"
                      control={<Radio />}
                      label="75%"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                    <FormControlLabel
                      value="100"
                      control={<Radio />}
                      label="100%"
                      className="mt-2 border important:Regular-all rounded-xl important:mr-0"
                    />
                  </RadioGroup>
                </div>
              </div>
            ) : null}

            {this.state.custom ? (
              <div className="mb-4 border rounded-xl">
                <div className="flex items-center justify-between px-3 py-2 bg-gray-100 rounded-t-xl">
                  <div className="flex flex-grow mr-5">
                    <h3 className="mr-3 whitespace-nowrap">
                      Application question:
                    </h3>
                    <p>
                      This is an employer-written question. You can report
                      inappropriate questions to Indeed through the "Report Job"
                      link at the bottom of the job description. " _________ "
                    </p>
                  </div>
                  <div>
                    <div
                      className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-11 h-11"
                      onClick={() => this.setState({ custom: false })}>
                      <FiX name="close" size={20} color="black" />
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <div className="flex items-center justify-between mb-4">
                    <h4 className="mr-3">
                      Write your own question to ask applicants. Do not ask
                      questions that are discriminatory, illegal, or otherwise
                      violate the Indeed site rules.:
                    </h4>
                  </div>
                  <div className="flex items-center flex-grow pl-3 border rounded--xl">
                    <TextareaAutosize
                      minRows={3}
                      aria-label="empty textarea"
                      className="p-2.5 shadow-none Light rounded-xl outline-none bg-white w-full"
                    />
                  </div>
                  <p className="mt-2 ml-2 fs-12 gray">
                    Characters remaining: 900
                  </p>
                </div>
              </div>
            ) : null}
          </div>

          <p className="mb-1 fs-16 Medium">Browse more qualifications</p>
          <div className="my-4 divider" />
          <div className="flex flex-wrap gap-2">
            <div
              className={`${
                this.state.Relocate === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ Relocate: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Ability to Commute/Relocate</p>
            </div>
            <div
              className={`${
                this.state.education === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ education: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Education</p>
            </div>
            <div
              className={`${
                this.state.experience === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ experience: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Experience</p>
            </div>
            <div
              className={`${
                this.state.interview === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ interview: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Interview Availability</p>
            </div>
            <div
              className={`${
                this.state.language === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ language: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Language</p>
            </div>

            <div
              className={`${
                this.state.certificate === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ certificate: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">License/Certificate</p>
            </div>
            <div
              className={`${
                this.state.travel === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ travel: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Willingness To Travel</p>
            </div>
            <div
              className={`${
                this.state.shift === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ shift: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white">Shift Availability</p>
            </div>
            <div
              className={`${
                this.state.custom === true ? "opacity-50" : "opacity-100"
              } bg--primary flex items-center rounded-full px-3 py-2 cursor-pointer`}
              onClick={() => this.setState({ custom: true })}>
              <BsFillPlusCircleFill size={15} color={colors.white} />
              <p className="ml-2 white"> Create Custom Question</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <Link
            to="/post_job/applications_settings"
            style={{ color: colors.black }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black Regular rounded--xl hover:scale-90">
            Back
          </Link>
          <Link
            to="/post_job/preview_job"
            style={{ color: colors.white }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary hover:opacity-80 focus:bg--primary white Regular rounded--xl hover:scale-90">
            Save and continue
          </Link>
        </div>
      </div>
    );
  }
}

export default Postjobqualifications;
