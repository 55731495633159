import colors from "config/color/color.js";

const styles = {
  userName: {
    color: colors.black,
    fontSize: 14,
  },
  chipOpacity: {
    backgroundColor: colors.lightGray,
  },
  listText: {
    fontSize: 12,
  },
  unReadBadge: {
    backgroundColor: colors.primary,
    width: 8,
    height: 7,
    borderRadius: 40,
  },
};

export default styles;
