import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import colors from "config/color/color.js";

class Candidatesnavigation extends Component {
  render() {
    return (
      <div className="z-10 py-3 candidates-navigation">
        <div className="relative flex items-center justify-between">
          <div className="flex flex-row items-center navbar-nav ">
            <NavLink
              to="/candidates"
              exact
              activeClassName="active--link"
              className="relative flex justify-center px-4 py-3 rounded-lg xl:mx-5 black hover:bg-gray-200"
              activeStyle={{ color: colors.primary }}>
              3 Active
            </NavLink>
            <NavLink
              to="/candidates/awaiting_review"
              activeClassName="active--link"
              className="relative flex justify-center px-4 py-3 rounded-lg xl:mx-5 black hover:bg-gray-200"
              activeStyle={{ color: colors.primary }}>
              0 Awaiting Review
            </NavLink>
            <NavLink
              to="/candidates/reviewed"
              activeClassName="active--link"
              className="relative flex justify-center px-4 py-3 rounded-lg xl:mx-5 black hover:bg-gray-200"
              activeStyle={{ color: colors.primary }}>
              1 Reviewed
            </NavLink>
            <NavLink
              to="/candidates/contacting"
              activeClassName="active--link"
              className="relative flex justify-center px-4 py-3 rounded-lg xl:mx-5 black hover:bg-gray-200"
              activeStyle={{ color: colors.primary }}>
              2 Contracting
            </NavLink>
            <NavLink
              to="/candidates/hired"
              activeClassName="active--link"
              className="relative flex justify-center px-4 py-3 rounded-lg xl:mx-5 black hover:bg-gray-200"
              activeStyle={{ color: colors.primary }}>
              0 Hired
            </NavLink>
            <NavLink
              to="/candidates/rejected"
              activeClassName="active--link"
              className="relative flex justify-center px-4 py-3 rounded-lg xl:mx-5 black hover:bg-gray-200"
              activeStyle={{ color: colors.primary }}>
              1 Rejected
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default Candidatesnavigation;
