import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import colors from "config/color/color.js";
import { withRouter } from "react-router";

class Openclosednavigation extends Component {
  render() {
    return (
      <div className="z-10 py-3">
        <div className="relative flex items-center justify-between ">
          <div className="flex flex-row items-center bg-white border rounded-xl">
            <NavLink
              to="/jobs/status_active"
              activeClassName="bg--primary hover:bg--primary active--link"
              className="relative flex justify-center px-4 py-3 black hover:bg-gray-200 rounded-l-xl "
              activeStyle={{ color: colors.white }}>
              Open and paused jobs (4)
            </NavLink>
            <NavLink
              to="/jobs/status_inactive"
              activeClassName="bg--primary hover:bg--primary active--link"
              className="relative flex justify-center px-4 py-3 black hover:bg-gray-200 rounded-r-xl "
              activeStyle={{ color: colors.white }}>
              Closed Jobs (17)
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Openclosednavigation);
