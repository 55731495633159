import * as React from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { Image } from "react-bootstrap";
import { Capitalize } from "helpers";
import { connect } from "react-redux";
import hundred from "assets/images/reaction/100.png";
import angry from "assets/images/reaction/angry.gif";
import haha from "assets/images/reaction/haha.gif";
import love from "assets/images/reaction/love.gif";
import rolling from "assets/images/reaction/rolling.gif";
import sad from "assets/images/reaction/sad.gif";
import wow from "assets/images/reaction/wow.gif";
import { Avatarimage } from "components";

const reaction = (num) => {
  switch (num) {
    case 1:
      return hundred;
      break;
    case 2:
      return love;
      break;
    case 3:
      return haha;
      break;
    case 4:
      return wow;
      break;
    case 5:
      return sad;
      break;
    case 6:
      return rolling;
      break;
    default:
      return angry;
  }
};

class ReactionTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.per_page = 10;
    this.page = 1;
    this.params = [];
  }

  componentDidMount() {
    this.props.fetchReactionUsers(this.per_page, this.page);
  }

  listFooterComponent = () => {
    const { is_loading } = this.props;
    if (is_loading) {
      return <p>loading...</p>;
    }
    return null;
  };

  loadMore = () => {
    const { is_loading, is_more } = this.props;
    if (!is_loading) {
      if (is_more) {
        this.page++;
        this.props.fetchReactionUsers(this.per_page, this.page);
      }
    }
  };

  renderItem = (item) => {
    // const selected = images.find(
    //   (img) => img.code === item.reactions.reaction_id
    // );
    return (
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center">
          <div className="relative">
            <Avatarimage
              className="object-contain mr-2 h-14 w-14"
              imageSize={{ width: 46, height: 46 }}
              item={item}
            />
            <div className="absolute bottom-0 right-0 flex items-center justify-center object-cover w-6 h-6 bg-white rounded-full shadow-sm">
              <Image
                src={reaction(item.reactions.reaction_id)}
                className="object-cover w-5 h-5 rounded-full"
              />
            </div>
          </div>
          <p className="Medium">
            <Link to={`/${item.user_name}`} className="Medium black">
              {Capitalize(item.first_name)} {Capitalize(item.last_name)}
            </Link>
          </p>
        </div>
        {/* <Button className="flex items-center px-4 border-0 shadow-none Regular bg--lightGray focus:bg--lightGray hover:bg--lightGray hover:opacity-80 rounded--xl black hover:black focus:black">
          <FiUserPlus className="mr-2" />
          Add Friend
        </Button> */}
      </div>
    );
  };
  render() {
    const { data, is_more } = this.props;
    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={this.loadMore}
        hasMore={is_more ? true : false}
        loader={<div className="mb-3">loading</div>}>
        {data.map((item, key) => this.renderItem(item))}
      </InfiniteScroll>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const post_id = ownProps.item.id;
  const type = ownProps.type;
  return {
    user: state.User.user,
    data:
      typeof state.People.reactions !== "undefined"
        ? typeof state.People.reactions[type] !== "undefined"
          ? typeof state.People.reactions[type][post_id] !== "undefined"
            ? state.People.reactions[type][post_id].data
            : []
          : []
        : [],
    is_loading:
      typeof state.People.reactionsExtra !== "undefined"
        ? typeof state.People.reactionsExtra[type] !== "undefined"
          ? typeof state.People.reactionsExtra[type][post_id] !== "undefined"
            ? state.People.reactionsExtra[type][post_id].is_loading
            : false
          : false
        : false,
    is_more:
      typeof state.People.reactions !== "undefined"
        ? typeof state.People.reactions[type] !== "undefined"
          ? typeof state.People.reactions[type][post_id] !== "undefined"
            ? state.People.reactions[type][post_id].is_more
            : false
          : false
        : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const post_id = ownProps.item.id;
  const type = ownProps.type;
  const { actions } = require("redux/PeopleRedux");
  return {
    fetchReactionUsers: (per_page, page) => {
      actions.fetchReactionUsers(
        dispatch,
        per_page,
        page,
        { post_id: post_id, reaction_id: ownProps.reaction_id },
        type
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactionTab);
