import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { AiFillHome, AiFillClockCircle } from "react-icons/ai";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Avatarimage, Profilepopup } from "components";
import moment from "moment";
import { connect } from "react-redux";
import { OptimizeImage } from "helpers";

const humanize = str => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

class Profileaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.editprofilepopupRef = React.createRef();
  }

  render() {
    let { data, photos, user, totalFriends } = this.props;
    return (
      <div>
        <div className="bg-white shadow-sm rounded-lg p-5">
          <h3 className="black text-xl Medium">Intro</h3>
          <div className="flex mt-5 items-center">
            <div className="mr-3 ">
              <AiFillHome size={20} className="gray " />
            </div>
            <p className="darkGray fs-14">
              Lives in{" "}
              <p className="black hover:underline fs-14">
                {" "}
                {data.street} {data.city} {data.cca2}
              </p>
            </p>
          </div>
          <div className="flex mt-5 items-center">
            <div className="mr-3 ">
              <FaHeart size={20} className="gray " />
            </div>

            <p className="darkGray fs-14">{humanize(data.marital_status)}</p>
          </div>
          <div className="flex mt-5 items-center">
            <div className="mr-3 ">
              <AiFillClockCircle size={20} className="gray " />
            </div>
            <p className="darkGray fs-14">
              Joined {moment.utc(data.created_at).local().format("MMMM Y")}
            </p>
          </div>
          <div className="flex mt-5 items-center">
            <div className="mr-3 ">
              <BsFillPersonCheckFill size={20} className="gray " />
            </div>
            <p className="darkGray fs-14">
              Followd by{" "}
              <p className="black hover:underline fs-14">
                {data.total_followers}
              </p>
            </p>
          </div>
          {data.id === user.id && (
            <Link
              to={`${data.user_name}/edit`}
              className="bg--lightGray Regular rounded--lg hover:bg--lightGray hover:opacity-60 focus:bg--lightGray shadow-none border-0 mt-8 h-10 w-full black flex items-center justify-center transform transition duration-300 hover:scale-90"
            >
              Edit Profile
            </Link>
          )}
        </div>
        <div className="bg-white shadow-sm rounded-lg p-5 mt-5">
          <div className="flex items-center justify-between -mt-3 mb-2">
            <h3 className="black text-xl Medium">Photos</h3>
            <Link
              to={`${data.user_name}/photos`}
              className="primary fs-13 hover:bg-gray-100 p-2 rounded-lg -mr-2"
            >
              See All Photos
            </Link>
          </div>
          <div className="grid grid-flow-row lg:grid-cols-3 sm:grid-cols-5 grid-cols-3 lg:gap-1 gap-3 rounded-xl overflow-hidden">
            {photos &&
              photos.data &&
              photos.data.map((item, key) => (
                <div>
                  <Image
                    src={OptimizeImage(item.attachment_url)}
                    alt={item.name}
                    key={key.id}
                    {...this.props}
                    className="h-40 object-cover w-full"
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="bg-white shadow-sm rounded-lg p-5 mt-5">
          <div className="flex items-center justify-between -mt-3">
            <h3 className="black text-xl Medium">Friends</h3>
            <Link
              to={`${data.user_name}/friends`}
              className="primary fs-13 hover:bg-gray-100 p-2 rounded-lg -mr-2"
            >
              See All Friends
            </Link>
          </div>

          <p className="gray Light fs-12 mb-3 -mt-2">{totalFriends} friends</p>
          <div className="grid grid-flow-row grid-cols-3 gap-2">
            {data.accepted_friend &&
              data.accepted_friend.map(item => (
                <Link>
                  <Avatarimage
                    item={item}
                    style={{ width: "100%", height: 102, borderRadius: 10 }}
                  />
                  <p className="fs-13 Medium mt-1 text-center hover:underline">
                    {item.first_name}
                  </p>
                </Link>
              ))}
          </div>
        </div>
        <Profilepopup ref={this.editprofilepopupRef} {...this.props} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const user = state.User.user;
  return {
    user: user,
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
    // deletePost: (data) => {
    //   actions.deletePostFeed(dispatch, data);
    // },
  };
};

export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(React.memo(Profileaside));
