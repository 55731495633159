import React from "react";
import colors from "config/color/color.js";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const IOStoggle = withStyles(theme => ({
  root: {
    width: 46,
    height: 24,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    paddingLeft: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: colors.white,
      "& + $track": {
        backgroundColor: colors.primary,
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: colors.gray,
  },
  checked: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default IOStoggle;
