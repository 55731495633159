import React, { Component } from "react";
import colors from "config/color/color.js";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { connect } from "react-redux";
import { FiX } from "react-icons/fi";
import moment from "moment";
import { OptimizeImage } from "helpers";
import { Redirect } from "react-router-dom";
import { LifeWidget } from "common";

class Logobanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      banner: "",
      render: false,
      photos: [],
    };
    this.photoRef = React.createRef();
    this.bannerRef = React.createRef();
    this.logoRef = React.createRef();
  }
  componentDidMount() {
    const { fetchBar, id } = this.props;
    fetchBar(id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bar !== this.props.bar) {
      let item = this.props.bar;
      if (Object.keys(item).length > 0) {
        let form = Object.assign({}, this.props.form);
        let hours = [];

        form.b_id = item.id;
        form.b_name = item.name;
        form.b_address = item.address;
        form.b_city = item.city;
        form.b_state = item.state;
        form.b_postal_code = item.postal_code;
        form.phone_number = item.phone_number;
        form.website = item.website == "null" ? "" : item.website;
        form.b_country = item.country;
        if (item.bar_type_id) {
          form.b_type_id = item.bar_type_id;
        }
        var photos = [];
        if (item.photos && item.photos.length > 0) {
          item.photos.map((item, key) => {
            photos.push(item);
          });
        }
        form.photos = photos;
        this.setState({ photos: photos });
        form.type = item.type;
        form.services = item.bar_services.map((service) => service.id);
        if (item.bar_hours && item.bar_hours.length > 0) {
          item.bar_hours.map((hour, key) => {
            hours.push({
              day: hour.day,
              is_open: hour.is_open,
              open_time: moment.utc(hour.open_time).local().toDate(),
              close_time: moment.utc(hour.close_time).local().toDate(),
            });
          });
        }
        form.hours = hours;

        form.logo = item.logo ? OptimizeImage(item.logo.attachment_url) : null;
        form.banner = item.banner
          ? OptimizeImage(item.banner.attachment_url)
          : null;

        this.props.addCompanyForm(form);
        this.imageSetup(item.logo, item.banner);
      }
    }
  }
  imageSetup(logo, banner, photos) {
    this.setState({
      logo: logo ? logo.attachment_url : null,
      banner: banner ? banner.attachment_url : null,
      // photos : (photos) ? photos.attachment_url : null
    });
  }

  onBannerClick = () => {
    this.bannerRef.current.click();
  };

  onLogoClick = () => {
    this.logoRef.current.click();
  };
  onPhotoClick = () => {
    this.photoRef.current.click();
  };

  deletePhoto = (id) => {
    LifeWidget.barDeletePhoto(id);
  }

  /*validation = () => {
    let flag = true;
    let array = ["photos"];
    let form = Object.assign({}, this.props.form);
    array.map((item, key) => {
      if (!Object.keys(form).some((index) => index === item)) {
        this.setState({ [item]: true });
        flag = false;
      }
    });
    Object.keys(form).map((item) => {
      if (array.some((index) => index === item)) {
        if (
          form[item] === null ||
          form[item] === "null" ||
          form[item] === "" ||
          form[item].length === 0
        ) {
          this.setState({ [item]: true });
          flag = false;
        } else {
          this.setState({ [item]: false });
        }
      }
    });

    if (flag) {
      // this.submitBar();
    }
  };
  uploadBanner = (imageType,data) => {
    let hours = [];
    let photos = [];
    var formData = new FormData();
    if (imageType === 'Banner') {
      
      formData.append("banner", data);
      formData.append('logo', this.props.item.logo)
      // formData.append('logo', this.props.item.logo ? {
      //   ...this.props.item.logo,
      //   uri: OptimizeImage( this.props.item.logo.attachment_url),
      // } : null
      // );
      // if (this.props.item.photos && this.props.item.photos.length > 0) {
      //   this.props.item.photos.map((item, key) => {
      //     photos.push({
      //       ...item,
      //       uri: OptimizeImage(item.attachment_url),
      //     });
      //   });
      // }
      // formData.append('photos', photos);

    }
    if (imageType === 'Logo') {
      
      formData.append("logo", data);
      // formData.append('banner', this.props.item.banner ? {
      //   ...this.props.item.banner,
      //   uri: OptimizeImage( this.props.item.banner.attachment_url),
      // } : null
      // );
      // if (this.props.item.photos && this.props.item.photos.length > 0) {
      //   this.props.item.photos.map((item, key) => {
      //     photos.push({
      //       ...item,
      //       uri: OptimizeImage(this.props.item.attachment_url),
      //     });
      //   });
      // }
      // formData.append('photos', photos);

    }
    formData.append('screen','logoSetup');
    if (this.props.item) {
      formData.append('b_id', this.props.item.id);
      formData.append('b_name', this.props.item.name);
      formData.append('b_address', this.props.item.address);
      formData.append('b_city', this.props.item.city);
      formData.append('b_state', this.props.item.state);
      formData.append('b_postal_code', this.props.item.postal_code);
      formData.append('b_type', this.props.item.type);
      formData.append('b_type_id', this.props.item.bar_type_id);
      formData.append('services', this.props.item.bar_services.map((service) => service.id));
      

      if (this.props.item.bar_hours.length > 0) {
        this.props.item.bar_hours.map((hour, key) => {
          hours.push({
            day: hour.day,
            is_open: hour.is_open,
            open_time: moment.utc(hour.open_time).local().toDate(),
            close_time: moment.utc(hour.close_time).local().toDate(),
          });
        });
      }
      formData.append('hours', hours);
    }
    this.props.submitBar(formData);
  };
 
*/

  // ***************************** new entry ***************************//
  onBannerChange = (e) => {
    const data = e.target.files[0];
    // if(this.props.form.banner !=null) this.removeBanner();
    this.setState({ banner: URL.createObjectURL(data) }, function () { });
    this.addCompanyForm("banner", data);
    // this.validation();
  };
  onLogoChange = (e) => {
    const data = e.target.files[0];
    if (this.props.form.logo) this.removeLogo();
    this.setState({ logo: URL.createObjectURL(data) });

    this.addCompanyForm("logo", data);
    // this.validation();
  };
  onPhotoChange = (e) => {
    let photos = Object.assign([], this.props.form.photos);
    const data = e.target.files[0];
    if (data) {
      let photo = { attachment_url: (data) };
      photos.push(photo);
      this.addCompanyForm("photos", photos);

    }
  };
  addCompanyForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addCompanyForm(form);
  };
  removeLogo = () => {
    let form = Object.assign({}, this.props.form);
    form["logo"] = null;
    this.props.addCompanyForm(form);
  };

  removeBanner = () => {
    let form = Object.assign({}, this.props.form);
    form["banner"] = null;
    this.props.addCompanyForm(form);
  };

  removePhoto = (index) => {
    let photos = [...this.props.form.photos];
    let deleted = photos[index];
    photos.splice(index, 1);
    this.addCompanyForm("photos", photos);
    this.setState({ photos: photos });
    if (deleted.id) {
      const data = {
        bar_id: this.props.id,
        id: deleted.id
      }
      this.deletePhoto(data);
    }

  };
  validation = () => {
    let flag = true;
    let array = [];
    let form = Object.assign({}, this.props.form);
    array.map((item, key) => {
      if (!Object.keys(form).some((index) => index === item)) {
        this.setState({ [item]: true });
        flag = false;
      }
    });
    Object.keys(form).map((item) => {
      if (array.some((index) => index === item)) {
        if (
          form[item] === null ||
          form[item] === "null" ||
          form[item] === "" ||
          form[item].length === 0
        ) {
          this.setState({ [item]: true });
          flag = false;
        } else {
          this.setState({ [item]: false });
        }
      }
    });

    if (flag) {
      this.submitBar();
    }
  };

  submitBar = () => {
    var formData = new FormData();
    let form = this.props.form;
    let item = this.props.bar;
    Object.keys(form).map(function (key) {
      if (key === "photos" && form[key]) {
        form[key].forEach((item, i) => {
          formData.append("photos[" + i + "]", item.attachment_url);
          if (item.id) {
            formData.append("attachments_ids[" + i + "]", item.id);
          }
        });
      } else if (key === "logo" && form[key]) {
        formData.append("logo", form[key]);
        if (form[key].id) {
          formData.append("logo_id", form[key].id);
        }
      } else if (key === "banner" && form[key]) {
        formData.append("banner", form[key]);
        if (form[key].id) {
          formData.append("banner_id", form[key].id);
        }
      } else if (key === "services") {
        form[key].forEach((item, i) => {
          formData.append("services[" + i + "]", item);
        });
      } else if (key === "hours") {
        form[key].forEach((item, i) => {
          formData.append("hours[" + i + "]", JSON.stringify(item));
        });
      } else {
        formData.append(key, form[key]);
      }
    });
    formData.append("screen", "logoSetup");
    formData.append("photoType", "web");
    this.props.submitCompany(formData);
    this.setState({ render: true });
  };

  render() {
    const { item, form, bar } = this.props;
    console.log(form)
    const { render } = this.state;
    if (render) {
      return <Redirect to="/my_companies" />;
    }

    return (
      <>
        {/****************************** Logo ************************* */}
        <div>
          <h3 className="mb-2">Logo</h3>

          <div className="p-2 overflow-hidden bg-white border shadow-md rounded-xl">
            <div
              className="relative flex items-center justify-center h-40 bg-gray-100 cursor-pointer rounded-xl"
              id="file"
              onClick={this.onLogoClick}>
              {bar.logo && bar.logo.attachment_url === this.state.logo ? (
                <img src={OptimizeImage(this.state.logo)} />
              ) : (
                <img src={this.state.logo} />
              )}
              <AiOutlineCloudUpload size={52} color={colors.primary} />
            </div>
            <input
              style={{ display: "none" }}
              accept="image/*"
              type="file"
              onChange={this.onLogoChange}
              ref={this.logoRef}
            />
          </div>
        </div>
        {/****************************** Banner ************************* */}
        <div>
          <h3 className="mb-2">Banner</h3>
          <div className="p-2 overflow-hidden bg-white border shadow-md rounded-xl">
            <div
              className="relative flex items-center justify-center h-40 bg-gray-100 cursor-pointer rounded-xl"
              id="file"
              onClick={this.onBannerClick}>
              {bar.banner && bar.banner.attachment_url === this.state.banner ? (
                <img src={OptimizeImage(this.state.banner)} />
              ) : (
                <>
                  <img src={this.state.banner} />
                </>
              )}

              <AiOutlineCloudUpload size={52} color={colors.primary} />
            </div>
            <input
              style={{ display: "none" }}
              accept="image/*"
              type="file"
              onChange={this.onBannerChange}
              ref={this.bannerRef}
            />
          </div>
        </div>
        {/****************************** Photos ************************* */}
        <div>
          <h3 className="mb-2">Photo</h3>
          <div className="p-2 overflow-hidden bg-white border shadow-md rounded-xl">
            <div
              className="relative flex items-center justify-center h-40 bg-gray-100 cursor-pointer rounded-xl"
              id="file"
              onClick={this.onPhotoClick}>
              <AiOutlineCloudUpload size={52} color={colors.primary} />
            </div>

            <input
              style={{ display: "none" }}
              Multiple
              accept="image/*"
              type="file"
              onChange={this.onPhotoChange}
              ref={this.photoRef}
            />
          </div>
          <div
            className="grid gap-4 mt-3 lg:grid-cols-3"
          // id='appendPhotos'
          >
            {typeof form.photos !== "undefined" &&
              (form.photos || []).map((item, key) => (
                <div className="p-2 mt-3 overflow-hidden bg-white border shadow-md rounded-xl">
                  <div className="relative flex items-center justify-center h-20 bg-gray-100 cursor-pointer rounded-xl">
                    <img src={item.id ? OptimizeImage(item.attachment_url) : URL.createObjectURL(item.attachment_url)} alt={item.name} />
                    <div className="bg--danger w-4 h-4 rounded-full flex items-center justify-center cursor-pointer absolute -top-1.5 -right-1.5">
                      <FiX
                        onClick={() => this.removePhoto(key)}
                        color={colors.white}
                        size={12}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <button
            onClick={this.validation}
            className="float-right mt-3 btn btn-primary">
            save
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ Company, User, Bar }) => {
  return {
    form: Company.form,
    bar: Bar.bar,
    isProcessing: Company.isProcessing,
    user: User.user,
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CompanyRedux");
  const { actions: barActions } = require("redux/BarRedux");
  return {
    ...ownProps,
    ...stateProps,
    addCompanyForm: (data) => {
      dispatch(actions.addCompanyForm(data));
    },
    submitCompany: (data) => {
      actions.submitCompany(dispatch, data);
    },
    fetchBar: (id) => {
      barActions.fetchBar(dispatch, id);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Logobanner);
