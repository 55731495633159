import colors from "config/color/color.js";

const styles = {
  thumbLike: {
    backgroundColor: colors.primary,
  },
  Tooltip: {
    backgroundColor: colors.black,
    border: 0,
  },
};

export default styles;
