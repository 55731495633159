import React, { Component } from "react";
import { Image } from "react-bootstrap";
import colors from "config/color/color.js";
import { BsDot } from "react-icons/bs";
import { Popup } from "semantic-ui-react";
import { TimeAgo, RenderMention } from "components";
import { avatar } from "assets";
import { OptimizeImage, Capitalize } from "helpers";
import { LifeWidget } from "common";
import { connect } from "react-redux";

class RecentComment extends Component {
  constructor(props) {
    super(props);
    this.delayCommentLikeTimer = null;
    this.delayReplyLikeTimer = null;
  }

  commentToggleLike = () => {
    this.props.item.recent_comments.isLiked =
      !this.props.item.recent_comments.isLiked;
    this.forceUpdate();
    clearTimeout(this.delayCommentLikeTimer);
    this.delayCommentLikeTimer = setTimeout(() => {
      LifeWidget.commentLikeToggle(this.props.item.recent_comments.id);
    }, 2000);
  };

  replyToggleLike = () => {
    this.props.item.recent_comments.recent_replies.isLiked =
      !this.props.item.recent_comments.recent_replies.isLiked;
    this.forceUpdate();
    clearTimeout(this.delayReplyLikeTimer);
    this.delayReplyLikeTimer = setTimeout(() => {
      LifeWidget.commentLikeToggle(
        this.props.item.recent_comments.recent_replies.id
      );
    }, 2000);
  };

  render() {
    const { commentData } = this.props;
    if (commentData) {
      return null;
    }
    if (!this.props.item.recent_comments) return null;
    const comment = this.props.item.recent_comments;
    const replay = comment.recent_replies;

    return (
      <div className="table mt-2">
        <div className="w-11 h-11 table-cell">
          <Image
            src={
              comment.user.profile_photo
                ? OptimizeImage(comment.user.profile_photo)
                : avatar
            }
            className="h-full w-full rounded-full object-cover"
          />
        </div>

        <div className="flex-grow table-cell">
          <div className="flex-grow comment-box-container  hover_show_box ml-3">
            <div className="comment-box py-2 px-3 rounded-2xl inline-block relative mr-10">
              <p className="Medium fs-13">
                {Capitalize(comment.user.first_name)}{" "}
                {Capitalize(comment.user.last_name)}
              </p>
              <p className="fs-13">
                <RenderMention
                  value={comment.comment}
                  partTypes={[{ trigger: "@" }]}
                />
                {comment.attachments && (
                  <Image
                    className="w-40 rounded mr-2 object-cover"
                    src={OptimizeImage(comment.attachments.attachment_url)}
                  />
                )}
              </p>
            </div>

            <div className="flex items-center ml-3 mt-1">
              <p
                className="text-sm darkGray cursor-pointer hover:primary--text"
                style={{
                  color: comment.isLiked ? colors.primary : null,
                }}
                onClick={this.commentToggleLike}
              >
                Like
              </p>
              <BsDot size={10} className="darkGray" />
              <p
                className="text-sm darkGray cursor-pointer"
                onClick={() => this.props.postCommentRef.current.fetch()}
              >
                Reply
              </p>
              <BsDot size={10} className="darkGray" />
              <Popup
                inverted
                className="p-2"
                position="bottom center"
                content={
                  <TimeAgo
                    className="white Light text-sm cursor-pointer"
                    created_at={comment.created_at}
                    format="dddd, MMMM Do YYYY, h:mm:ss a"
                  />
                }
                trigger={
                  <TimeAgo
                    className="darkGray text-sm cursor-pointer Light"
                    created_at={comment.created_at}
                  />
                }
              />
            </div>
          </div>
          {!!replay && (
            <div className="flex mt-1">
              <Image
                src={
                  replay.user.profile_photo
                    ? OptimizeImage(replay.user.profile_photo)
                    : avatar
                }
                className="border h-8 w-8 rounded-full mr-2 object-cover"
              />
              <div className="flex-grow">
                <div className="flex-grow comment-box-container  hover_show_box">
                  <div className="comment-box py-2 px-3 rounded-2xl inline-block relative">
                    <p className="Medium fs-13">
                      {Capitalize(replay.user.first_name)}{" "}
                      {Capitalize(replay.user.last_name)}
                    </p>
                    <p className="fs-13">
                      <RenderMention
                        value={replay.comment}
                        partTypes={[{ trigger: "@" }]}
                      />
                      {replay.attachments && (
                        <Image
                          className="w-40 rounded mr-2 object-cover"
                          src={OptimizeImage(replay.attachments.attachment_url)}
                        />
                      )}
                    </p>
                  </div>
                  <div className="flex items-center ml-3 mt-1">
                    <p
                      className="text-sm darkGray cursor-pointer hover:primary--text"
                      style={{
                        color: replay.isLiked ? colors.primary : null,
                      }}
                      onClick={this.replyToggleLike}
                    >
                      Like
                    </p>
                    <BsDot size={10} className="darkGray" />
                    <Popup
                      inverted
                      className="p-2"
                      position="bottom center"
                      content={
                        <TimeAgo
                          className="white Light text-sm cursor-pointer"
                          created_at={replay.created_at}
                          format="dddd, MMMM Do YYYY, h:mm:ss a"
                        />
                      }
                      trigger={
                        <TimeAgo
                          className="darkGray text-sm cursor-pointer Light"
                          created_at={replay.created_at}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <p
            className="cursor-pointer my-2 fs-12 darkGray hover:underline"
            onClick={() => this.props.postCommentRef.current.fetch()}
          >
            View comments
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const commentId = ownProps.item.recent_comments?.id;
  const post_id = ownProps.item.id;
  let comment = null;
  if (commentId && post_id) {
    comment =
      state.Comment.commentsById &&
      state.Comment.commentsById[post_id] &&
      state.Comment.commentsById[post_id][commentId];
  }
  return { commentData: comment };
};

export default connect(mapStateToProps)(RecentComment);
