import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { AiFillHome } from "react-icons/ai";
import { BsPersonCheckFill } from "react-icons/bs";
import { Capitalize, OptimizeImage } from "helpers";
import { Avatarimage, Peopleimage } from "components";

class Usernamepopup extends Component {
  renderJobLocation = () => {
    const { item } = this.props;
    let location = "";
    if (item.city) {
      location += item.city + ", ";
    }
    if (item.cca2) {
      location += item.cca2 + ", ";
    }
    return location.replace(/,\s*$/, "");
  };
  render() {
    const { item, flirt_setting } = this.props;
    if (!item) {
      return null;
    }
    if (!flirt_setting) {
      return null;
    }

    return (
      <>
        {this.props.flirt_setting.is_fname_visible === 1 &&
          this.props.flirt_setting.is_lname_visible === 1 && (
            <Popup
              trigger={
                <Link
                  to={`/${item.user_name}`}
                  className="flex items-center rounded-lg hover:underline black Medium">
                  {Capitalize(item.first_name)} {Capitalize(item.last_name)}
                </Link>
              }
              flowing
              hoverable
              position="top left"
              className="p-3 border-0 rounded--lg w-96">
              <div>
                <div className="flex items-center">
                  <Link to={`/${item.user_name}`} className="mr-3 ">
                    <Avatarimage
                      item={item}
                      imageSize={{ width: 72, height: 72 }}
                    />
                  </Link>
                  <div className="max-w-xs">
                    <Link
                      to={`/${item.user_name}`}
                      className="block mb-2 text-xl rounded-lg black Medium">
                      {Capitalize(item.first_name)} {Capitalize(item.last_name)}
                    </Link>

                    <div className="flex">
                      <div className="mr-3 ">
                        <AiFillHome size={18} style={{ color: colors.gray }} />
                      </div>
                      <div className="flex flex-wrap items-center">
                        <p className="darkGray Light">Lives in</p>
                        <p className="ml-1 black">{this.renderJobLocation()}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-3">
                  <Button
                    style={{ color: colors.primary }}
                    className="flex items-center justify-center flex-grow h-10 border-0 shadow-none bg--lightPrimary rounded--lg Regular hover:bg--lightPrimary focus:bg--lightPrimary hover:opacity-70 ">
                    <BsPersonCheckFill
                      style={{ color: colors.primary }}
                      className="mr-2"
                    />
                    Respond
                  </Button>

                  {/* <Dropdown
                    icon={false}
                    wrapSelection={true}
                    direction="left"
                    pointing="left"
                    text={
                      <Button className="flex items-center h-10 ml-3 border-0 shadow-none bg--lightGray rounded--lg Regular hover:bg--lightGray focus:bg--lightGray hover:opacity-70 ">
                        <HiDotsHorizontal style={{ color: colors.black }} />
                      </Button>
                    }
                  >
                    <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                      <div className="relative p-2">
                        <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                          <VscReport
                            size={18}
                            className="mr-3"
                            style={{ color: colors.black }}
                          />
                          <p>Find support or report profile</p>
                        </div>
                        <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                          <MdBlock
                            size={18}
                            className="mr-3"
                            style={{ color: colors.black }}
                          />
                          <p>Block</p>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                */}
                </div>
              </div>
            </Popup>
          )}

        {this.props.flirt_setting.is_fname_visible === 1 &&
          this.props.flirt_setting.is_lname_visible !== 1 && (
            <Popup
              trigger={
                <Link
                  to={`/${item.user_name}`}
                  className="flex items-center rounded-lg hover:underline black Medium">
                  {Capitalize(item.first_name)}
                </Link>
              }
              flowing
              hoverable
              position="top left"
              className="p-3 border-0 rounded--lg w-96">
              <div>
                <div className="flex">
                  <Link to={`/${item.user_name}`} className="mr-3 ">
                    <Image
                      src={OptimizeImage(item.profile_photo)}
                      className="object-cover w-20 h-20 bg-transparent border-0 rounded-full"
                    />
                  </Link>
                  <div className="max-w-xs">
                    <Link
                      to={`/${item.user_name}`}
                      className="block mb-2 text-xl rounded-lg black Medium">
                      {Capitalize(item.first_name)}
                    </Link>

                    <div className="flex">
                      <div className="mr-3 ">
                        <AiFillHome size={18} style={{ color: colors.gray }} />
                      </div>
                      <div className="flex flex-wrap items-center">
                        <p className="darkGray Light">Lives in</p>
                        <p className="ml-1 black">Lahore, Pakistan</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-3">
                  <Button
                    style={{ color: colors.primary }}
                    className="flex items-center justify-center flex-grow h-10 border-0 shadow-none bg--lightPrimary rounded--lg Regular hover:bg--lightPrimary focus:bg--lightPrimary hover:opacity-70 ">
                    <BsPersonCheckFill
                      style={{ color: colors.primary }}
                      className="mr-2"
                    />
                    Respond
                  </Button>
                </div>
              </div>
            </Popup>
          )}

        {this.props.flirt_setting.is_fname_visible !== 1 &&
          this.props.flirt_setting.is_lname_visible === 1 && (
            <Popup
              trigger={
                <Link
                  to={`/${item.user_name}`}
                  className="flex items-center rounded-lg hover:underline black Medium">
                  {Capitalize(item.last_name)}
                </Link>
              }
              flowing
              hoverable
              position="top left"
              className="p-3 border-0 rounded--lg w-96">
              <div>
                <div className="flex">
                  <Link to={`/${item.user_name}`} className="mr-3 ">
                    <Image
                      src={OptimizeImage(item.profile_photo)}
                      className="object-cover w-20 h-20 bg-transparent border-0 rounded-full"
                    />
                  </Link>
                  <div className="max-w-xs">
                    <Link
                      to={`/${item.user_name}`}
                      className="block mb-2 text-xl rounded-lg black Medium">
                      {Capitalize(item.last_name)}
                    </Link>

                    <div className="flex">
                      <div className="mr-3 ">
                        <AiFillHome size={18} style={{ color: colors.gray }} />
                      </div>
                      <div className="flex flex-wrap items-center">
                        <p className="darkGray Light">Lives in</p>
                        <p className="ml-1 black">{this.renderJobLocation()}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-3">
                  <Button
                    style={{ color: colors.primary }}
                    className="flex items-center justify-center flex-grow h-10 border-0 shadow-none bg--lightPrimary rounded--lg Regular hover:bg--lightPrimary focus:bg--lightPrimary hover:opacity-70 ">
                    <BsPersonCheckFill
                      style={{ color: colors.primary }}
                      className="mr-2"
                    />
                    Respond
                  </Button>
                </div>
              </div>
            </Popup>
          )}

        {this.props.flirt_setting.is_fname_visible !== 1 &&
          this.props.flirt_setting.is_lname_visible !== 1 && (
            <Popup
              trigger={
                <Link
                  to={`/${item.user_name}`}
                  className="flex items-center rounded-lg hover:underline black Medium">
                  {Capitalize(flirt_setting.nick_name)}
                </Link>
              }
              flowing
              hoverable
              position="top left"
              className="p-3 border-0 rounded--lg w-96">
              <div>
                <div className="flex">
                  <div className="mr-3 ">
                    {/* <Image
                      src={OptimizeImage(item.profile_photo)}
                      className="object-cover w-20 h-20 bg-transparent border-0 rounded-full"
                    /> */}
                    <Peopleimage item={item} imageStyle="h-20 w-20" />
                  </div>
                  <div className="max-w-xs">
                    <Link
                      to={`/${item.user_name}`}
                      className="block mb-2 text-xl rounded-lg black Medium">
                      {Capitalize(flirt_setting.nick_name)}
                    </Link>

                    <div className="flex">
                      <div className="mr-3 ">
                        <AiFillHome size={18} style={{ color: colors.gray }} />
                      </div>
                      <div className="flex flex-wrap items-center">
                        <p className="darkGray Light">Lives in</p>
                        <p className="ml-1 black">{this.renderJobLocation()}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-3">
                  <Button
                    style={{ color: colors.primary }}
                    className="flex items-center justify-center flex-grow h-10 border-0 shadow-none bg--lightPrimary rounded--lg Regular hover:bg--lightPrimary focus:bg--lightPrimary hover:opacity-70 ">
                    <BsPersonCheckFill
                      style={{ color: colors.primary }}
                      className="mr-2"
                    />
                    Respond
                  </Button>
                </div>
              </div>
            </Popup>
          )}
      </>
    );
  }
}

export default Usernamepopup;
