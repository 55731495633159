import React, { Component } from "react";
import { Button } from "react-bootstrap";
import colors from "config/color/color.js";
import { avatar as avatar2 } from "assets";
import { FiPlus, FiX } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { connect } from "react-redux";
import { Capitalize, OptimizeImage } from "helpers";
import { Avatar } from "@material-ui/core";

class FriendList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
    };

    this.page = 1;
    this.per_page = 20;
    this.params = [];
    this.friendSearchEvent = null;
  }

  componentDidMount() {
    const { group_id } = this.props;
    this.params["f"] = true;
    if (group_id) {
      this.params["group_id"] = group_id;
    }
    this.props.fetchFriends(this.per_page, this.page, this.params);
  }

  onChangeText = (e) => {
    clearTimeout(this.friendSearchEvent);
    this.friendSearchEvent = setTimeout(() => {
      this.params["s"] = e.target.value;
      this.page = 1;
      this.props.fetchFriends(this.per_page, this.page, this.params);
    }, 1000);
  };

  handleChange = (item) => {
    const { isCreateGroupInvite } = this.props;
    let checked = Object.assign(
      [],
      isCreateGroupInvite ? this.props.groupForm.tags : this.props.form.tags
    );
    let found = checked.find((data) => data.id === item.id);
    if (!found) {
      checked.push(item);
    } else {
      checked = checked.filter((data) => data.id !== item.id);
    }
    if (isCreateGroupInvite) {
      this.addGroupForm("tags", checked);
    } else {
      this.addFeedForm("tags", checked);
    }
  };

  addFeedForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addFeedForm(form);
  };

  addGroupForm = (key, value) => {
    let form = Object.assign({}, this.props.groupForm);
    form[key] = value;
    this.props.addGroupForm(form);
  };

  render() {
    const { friends, isTag, group_id, isGroupInvite, isCreateGroupInvite } =
      this.props;
    const tags = isTag
      ? Object.assign(
          [],
          isCreateGroupInvite ? this.props.groupForm.tags : this.props.form.tags
        )
      : [];
    return (
      <div className="p-3">
        <div className="flex items-center">
          <div className="flex flex-row items-center flex-grow w-full h-10 px-4 bg-gray-100 rounded-full">
            <GoSearch size={16} className="mr-2 darkGray" />
            <input
              placeholder="Search for Friends"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="flex-grow w-64 h-10 bg-transparent border-0 Regular darkGray"
              onChange={this.onChangeText}
            />
          </div>
        </div>
        <div
          className="mt-5 -mx-2 overflow-auto"
          style={{
            height: "480px",
          }}
          id="scrollbar">
          {isTag && tags.length > 0 && (
            <div className="mx-2 mb-3">
              <p className="mb-2 text-sm uppercase darkGray">Tagged</p>
              <div className="flex flex-wrap gap-3 p-3 border rounded-md">
                {tags &&
                  tags.map((item, key) => (
                    <Button
                      key={key}
                      className="flex items-center h-10 px-4 py-0 border-0 shadow-none cursor-pointer bg-primary-chip Regular rounded--lg justify-evenly">
                      {Capitalize(item.first_name)} {Capitalize(item.last_name)}
                      <div
                        onClick={() => this.handleChange(item)}
                        className="flex items-center justify-center w-8 h-8 ml-1 rounded-full hover:bg-gray-200">
                        <FiX style={{ color: colors.primary }} />
                      </div>
                    </Button>
                  ))}
              </div>
            </div>
          )}
          {friends &&
            friends
              .filter((item) => !tags.map((e) => e.id).includes(item.id))
              .map((item, key) => (
                <div
                  onClick={() => isTag && this.handleChange(item)}
                  key={key}
                  className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-200">
                  <div className="flex items-center">
                    <Avatar src={OptimizeImage(item.profile_photo)}>
                      <Avatar src={avatar2} />
                    </Avatar>
                    <p className="ml-3">
                      {Capitalize(item.first_name)} {Capitalize(item.last_name)}
                    </p>
                  </div>
                  {isGroupInvite && (
                    <div>
                      {!item.group_invited ? (
                        <div
                          onClick={() =>
                            this.props.sendGroupInvite(group_id, item.id)
                          }
                          className="flex items-center justify-center px-5 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
                          <FiPlus color={colors.primary} size={18} />
                          <p className="ml-2 primary">Invite</p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center px-5 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
                          <p className="ml-2 primary">Sent</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    friends: state.People.friends,
    form: state.Feed.form,
    groupForm: state.Group.form,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/PeopleRedux");
  const { actions: feedActions } = require("redux/FeedRedux");
  const { actions: groupActions } = require("redux/GroupRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchFriends: (per_page, page, params = []) => {
      actions.fetchFriends(dispatch, per_page, page, params);
    },
    sendGroupInvite: (group_id, friend_id) => {
      actions.sendGroupInvite(dispatch, group_id, friend_id);
    },
    addFeedForm: (data) => {
      dispatch(feedActions.addFeedForm(data));
    },
    addGroupForm: (data) => {
      dispatch(groupActions.addGroupForm(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(FriendList);
