import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";

class Flirtskeleton extends Component {
  render() {
    return (
      <div className="px-3 pb-0 overflow-hidden bg-white rounded-lg shadow-sm">
        <div className="-mx-4">
          <Skeleton variant="rect" height={100} />
        </div>
        <div className="items-center mb-3">
          <div className="flex items-center justify-center flex-grow -mt-10">
            <Skeleton
              variant="circle"
              height={62}
              className="rounded-full"
              width={62}
            />
          </div>
          <div className="flex items-center justify-center flex-grow mt-3">
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={120}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Flirtskeleton;
