import React, { Component } from "react";
import { Button, Image, Form } from "react-bootstrap";
import "./styles.css";
import colors from "config/color/color.js";
import avatar from "assets/images/avatar.webp";
import { FaPhotoVideo, FaUserTag } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { BsFillLockFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { TiArrowSortedDown } from "react-icons/ti";
import { FiX, FiArrowLeft } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import Dialog from "@material-ui/core/Dialog";
import TextareaAutosize from "react-textarea-autosize";
import { Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { Capitalize, OptimizeImage } from "helpers";
import { Selectprivacypopup, FriendList } from "components";
import { Loader } from "semantic-ui-react";
import { createFFmpeg } from "@ffmpeg/ffmpeg";
import Compressor from "compressorjs";
import { Link } from "react-router-dom";
import { Emitter } from "common";

const config = {
  attributes: {
    disablePictureInPicture: true,
    controlsList: "nodownload",
  },
};
const ffmpeg = createFFmpeg({
  corePath: "../../../../ffmpeg-core.js",
  log: true,
});
class Postquestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: "",
      tagPeople: false,
      searchLocation: false,
      isProcessing: true,
      item: null,
    };
    this.onChange = this.onChange.bind(this);
    this.selectPrivacyRef = React.createRef();
  }

  componentDidMount() {
    Emitter.on("OPEN_POST_MODAL", (item) => {
      this.setState({ item: item, open: true });
    });

    Emitter.on("OPEN_EDIT_MODAL", (item) => {
      this.setState({ open: true });
      this.doEditPost(item);
    });
  }

  componentWillUnmount() {
    Emitter.off("OPEN_POST_MODAL");
    Emitter.off("OPEN_EDIT_MODAL");
  }

  doEditPost = (item) => {
    if (item.general_privacy_id === 1) {
      this.props.setPostPrivacy({ name: "Public", id: 1 });
    } else if (item.general_privacy_id === 2) {
      this.props.setPostPrivacy({ name: "Friends", id: 2 });
    } else {
      this.props.setPostPrivacy({ name: "Only Me", id: 3 });
    }

    let form = {};
    form.post_id = item.id;
    form.post_text = item.post_text;
    form.tags = item.post_tags;
    var files = [];
    if (item.attachments.length > 0) {
      item.attachments.map((item, key) => {
        files.push({
          ...item,
          uri: OptimizeImage(item.attachment_url),
        });
      });
    }
    form.files = files;
    this.props.addFeedForm(form);
  };

  handleClickOpen = () => {
    this.props.addFeedForm({});
    this.setState({ open: true, item: null });
  };

  handleClose = () => {
    this.setState({ open: false, item: null });
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  };

  addFeedForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addFeedForm(form);
  };

  compressImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  onFileChange = async (e) => {
    const data = e.target.files;
    let files = Object.assign([], this.props.form.files);

    for (let i = 0; i < data.length; i++) {
      if (data[i].type.includes("image")) {
        let res = await this.compressImage(data[i]);

        files.push(res);
      } else {
        files.push(data[i]);
      }
    }
    this.addFeedForm("files", files);
  };

  doTranscode = async (file) => {
    await ffmpeg.load();
    //ffmpeg.FS('writeFile', 'XSx9QMDXDm.mov', await fetchFile(file));
    //await ffmpeg.run('-i', 'XSx9QMDXDm.mov', 'test.mp4');
    //const data = ffmpeg.FS('readFile', 'test.mp4');
  };

  removeFile = (index) => {
    let files = Object.assign([], this.props.form.files);
    files.splice(index, 1);
    this.addFeedForm("files", files);
  };

  submitPost = () => {
    const { group } = this.props;
    let flag = false;
    var formData = new FormData();
    formData.append(
      "general_privacy_id",
      this.props.defaultPrivacy ? this.props.defaultPrivacy.id : 1
    );
    if (group) {
      formData.append("group_id", group.id);
    }
    let form = this.props.form;
    if (form.post_text) {
      flag = true;
    }
    if (form.files && form.files.length > 0) {
      flag = true;
    }
    Object.keys(form).map(function (key) {
      if (key === "files" && form[key]) {
        form[key].forEach((item, i) => {
          formData.append("files[" + i + "]", item);
          if (item.id) {
            formData.append("attachments_ids[" + i + "]", item.id);
          }
        });
      } else if (key === "tags") {
        form[key].forEach((item, i) => {
          formData.append("user_ids[" + i + "]", item.id);
        });
      } else {
        formData.append(key, form[key]);
      }
    });
    if (!flag) {
      return;
    }
    this.props.submitPost(formData).then((res) => {
      this.setState({ open: false });
    });
  };

  sharePostNow = () => {
    const { item } = this.state;
    let form = this.props.form;
    let tag_ids = [];
    const data = {
      post_id: item.id,
      text: form.post_text,
      general_privacy_id: this.props.defaultPrivacy
        ? this.props.defaultPrivacy.id
        : 1,
      //category: this.props.defaultList,
    };
    if (form.tags) {
      tag_ids = form.tags.map((itm) => itm.id);
    }
    if (tag_ids.length > 0) {
      data["user_ids"] = tag_ids;
    }
    this.props.sharePost(data).then((res) => {
      this.setState({ open: false });
    });
  };

  render() {
    const { addFeedForm, submitPost, sharePostNow } = this;
    let { user, form, isProcessing, defaultPrivacy } = this.props;
    const { item } = this.state;
    return (
      <div className="p-3 bg-white rounded-lg shadow-sm">
        <div className="flex items-center">
          <Image
            src={
              user.profile_photo ? OptimizeImage(user.profile_photo) : avatar
            }
            className="object-cover object-top mr-2 border rounded-full h-14 w-14"
          />
          <div
            onClick={this.handleClickOpen}
            className="flex items-center flex-grow h-12 px-3 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 ">
            {this.state.value.length === 0 ? (
              <p className="darkGray">
                {" "}
                what's on your mind, {Capitalize(user.first_name)}?{" "}
              </p>
            ) : (
              <p className="darkGray"> {this.state.value}</p>
            )}
          </div>
        </div>
        {/* <div className="my-2 border-t" /> */}
        {/* <div className="flex items-center justify-evenly">
          <div
            onClick={this.handleClickOpen}
            className="flex items-center justify-center flex-grow p-2 text-center rounded-lg cursor-pointer hover:bg-gray-200"
          >
            <FaPhotoVideo style={{ color: colors.success }} size={22} />
            <p className="ml-3 darkGray">Photo/Video</p>
          </div>
          <div
            onClick={this.handleClickOpen}
            className="flex items-center justify-center flex-grow p-2 text-center rounded-lg cursor-pointer hover:bg-gray-200"
          >
            <GoSmiley style={{ color: colors.warning }} size={22} />
            <p className="ml-3 darkGray">Feeling/Activity</p>
          </div>
        </div> */}

        <Dialog
          scroll="body"
          fullWidth={true}
          open={this.state.open}
          onClose={this.handleClose}
          className="custom-backdrop rounded--xl">
          <div
            className="relative w-full overflow-hidden shadow-lg rounded-xl "
            style={{
              height:
                this.state.tagPeople === true ||
                this.state.searchLocation === true
                  ? "600px"
                  : null,
            }}>
            <div
              className="w-full duration-500 ease-in-out postQuesitonDilog"
              style={{
                transform:
                  this.state.tagPeople === true ||
                  this.state.searchLocation === true
                    ? `translateX(-100%)`
                    : `translateX(0)`,
              }}>
              <div className="flex items-center justify-center px-3 py-3 border-b">
                {!item ? (
                  <p className="text-2xl Medium">Create Post</p>
                ) : (
                  <div className="flex items-center justify-center">
                    <p className="mr-1 text-2xl Medium whitespace-nowrap">
                      Share Post{" "}
                    </p>
                    <p className="overflow-hidden truncate overflow-ellipsis w-96">
                      {!!item.post_text && "| " + item.post_text}
                    </p>
                  </div>
                )}
                <div
                  onClick={this.handleClose}
                  className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                  <FiX size={24} className="darkGray" />
                </div>
              </div>
              <div className="p-3">
                <div className="flex items-center">
                  <Image
                    src={
                      user.profile_photo
                        ? OptimizeImage(user.profile_photo)
                        : avatar
                    }
                    className="object-cover mr-3 rounded-full h-14 w-14"
                  />
                  <div className="flex flex-col items-start">
                    <div className="flex items-center mb-0.5 flex-grow flex-wrap">
                      <p className="Medium">
                        {Capitalize(user.first_name)}{" "}
                        {Capitalize(user.last_name)}
                      </p>
                      {form && form.tags && form.tags.length > 0 && (
                        <p className="darkGray fs-13 mx-1.5">is with</p>
                      )}
                      {form &&
                        form.tags &&
                        form.tags.map((tag, key) => (
                          <p className="mr-1 Medium black" key={key}>
                            {tag.first_name}
                            {form.tags.length - 2 === key
                              ? " and "
                              : form.tags.length - 1 === key
                              ? ""
                              : ","}
                          </p>
                        ))}
                    </div>
                    <div
                      onClick={() =>
                        this.selectPrivacyRef.current.selectPriavcyOpen()
                      }
                      className="flex items-center px-2 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300">
                      {defaultPrivacy.id === 1 && <ImUsers size={12} />}
                      {defaultPrivacy.id === 2 && (
                        <GiEarthAsiaOceania size={12} />
                      )}
                      {defaultPrivacy.id === 3 && <BsFillLockFill size={12} />}
                      <p className="mx-1 text-sm leading-none">
                        {defaultPrivacy.name}
                      </p>
                      <TiArrowSortedDown size={14} />
                    </div>
                  </div>
                </div>
                <div className="my-3">
                  <TextareaAutosize
                    minRows={3}
                    maxRows={8}
                    placeholder={
                      "What's on your mind, " +
                      Capitalize(user.first_name) +
                      "?"
                    }
                    className="flex-grow w-full text-2xl resize-none Light outline-0"
                    value={form.post_text}
                    onChange={(e) => addFeedForm("post_text", e.target.value)}
                    id="scrollbar"
                  />
                </div>
                <div className="flex flex-wrap mb-4">
                  {form.files &&
                    form.files.map((item, index) => (
                      <div
                        key={index}
                        className="relative"
                        style={{ padding: 1 }}>
                        {item.type.includes("image") ? (
                          <Image
                            className="object-cover w-40 h-40 rounded-xl"
                            src={item.uri ?? URL.createObjectURL(item)}
                            key={index}
                          />
                        ) : (
                          <video
                            config={config}
                            className="w-40 h-40 rounded-xl"
                            controls
                            src={item.uri ?? URL.createObjectURL(item)}
                          />
                        )}
                        <div
                          onClick={() => this.removeFile(index)}
                          className="absolute flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg--primary top-1 right-1">
                          <FiX className="white" />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="flex items-center justify-between py-2 border rounded-lg shadow-sm">
                  <div className="flex items-center justify-between ">
                    {!item && (
                      <Popup
                        style={{ opacity: 0.8 }}
                        inverted="red"
                        className="px-2 py-1"
                        position="top center"
                        content={
                          <p className="text-sm cursor-pointer white Light">
                            Photo/Video
                          </p>
                        }
                        trigger={
                          <div
                            id="file"
                            className="relative flex items-center justify-center w-12 h-12 ml-3 overflow-hidden bg-gray-100 rounded-full cursor-pointer hover:bg-gray-300">
                            <FaPhotoVideo
                              style={{ color: colors.success }}
                              size={25}
                              className="cursor-pointer "
                            />

                            <Form.Control
                              type="file"
                              onChange={this.onFileChange}
                              multiple
                              className="absolute bottom-0 left-0 w-12 h-20 opacity-0 cursor-pointer"
                            />
                          </div>
                        }
                      />
                    )}
                    <Popup
                      style={{ opacity: 0.8 }}
                      inverted="red"
                      className="px-2 py-1"
                      position="top center"
                      content={
                        <p className="text-sm cursor-pointer white Light">
                          Tag People
                        </p>
                      }
                      trigger={
                        <div
                          onClick={() =>
                            this.setState({
                              tagPeople: !this.state.tagPeople,
                            })
                          }
                          className="flex items-center justify-center w-12 h-12 ml-3 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-300">
                          <FaUserTag className="primary" size={25} />
                        </div>
                      }
                    />
                    {/* <Popup
                      style={{ opacity: 0.9 }}
                      inverted
                      className="px-2 py-1"
                      position="top center"
                      content={
                        <p className="text-sm cursor-pointer white Light">
                          Check In
                        </p>
                      }
                      trigger={
                        <div
                          onClick={() =>
                            this.setState({
                              searchLocation: !this.state.searchLocation,
                            })
                          }
                          className="flex items-center justify-center w-12 h-12 ml-3 rounded-full cursor-pointer hover:bg-gray-200"
                        >
                          <MdLocationOn
                            style={{ color: colors.danger }}
                            size={25}
                          />
                        </div>
                      }
                    /> */}
                  </div>
                </div>
                <Button
                  disabled={isProcessing}
                  onClick={() => (item ? sharePostNow() : submitPost())}
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.white,
                    cursor: "pointer",
                  }}
                  className="flex items-center justify-center w-full h-12 mt-3 text-2xl border-0 rounded--lg Regular hover:opacity-90">
                  {isProcessing ? (
                    <Loader
                      active={isProcessing}
                      inverted
                      inline="centered"></Loader>
                  ) : item ? (
                    "Share"
                  ) : (
                    "Post"
                  )}
                </Button>
              </div>
            </div>

            <div
              className="absolute top-0 z-10 w-full h-full duration-500 ease-in-out bg-white"
              style={{
                right: this.state.tagPeople === true ? 0 : "-100%",
              }}>
              <div className="flex items-center justify-center px-3 py-3 border-b">
                <p className="text-2xl Medium">Tag People</p>
                <div
                  onClick={() => this.setState({ tagPeople: false })}
                  className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-3 hover:bg-gray-300">
                  <FiArrowLeft size={24} className="darkGray" />
                </div>
              </div>
              <FriendList isTag />
            </div>
            {/* location */}
            <div
              className="absolute top-0 z-10 w-full h-full duration-500 ease-in-out bg-white"
              style={{
                right: this.state.searchLocation === true ? 0 : "-100%",
              }}>
              <div className="flex items-center justify-center px-3 py-3 border-b">
                <p className="text-2xl Medium">Search for location</p>
                <div
                  onClick={() => this.setState({ searchLocation: false })}
                  className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-3 hover:bg-gray-300">
                  <FiArrowLeft size={24} className="darkGray" />
                </div>
              </div>
              <div className="p-3">
                <div className="flex items-center">
                  <div className="flex flex-row items-center flex-grow w-full h-10 px-2 bg-gray-100 rounded-full">
                    <GoSearch size={16} className="mr-2 darkGray" />
                    <input
                      placeholder="Search for friends"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="flex-grow w-64 h-10 bg-transparent border-0 Regular darkGray"
                    />
                  </div>
                </div>
                <div
                  className="mt-3 -mx-2 overflow-auto "
                  style={{
                    height: this.state.searchLocation === true ? "480px" : null,
                  }}
                  id="scrollbar">
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>{" "}
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>{" "}
                  <div className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                    <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-600 rounded-md">
                      <MdLocationOn style={{ color: colors.white }} size={25} />
                    </div>
                    <div>
                      <p>Lahore</p>
                      <p className="text-sm darkGray Light">
                        Lahore, Punjab, Pakistan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Selectprivacypopup ref={this.selectPrivacyRef} {...this.props} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isProcessing: state.Feed.isProcessing,
    isEditProcessing: state.Feed.isEditProcessing,
    form: state.Feed.form,
    user: state.User.user,
    defaultPrivacy: state.Feed.defaultPrivacy,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/FeedRedux");
  return {
    ...ownProps,
    ...stateProps,
    submitPost: (data) => {
      return actions.submitPost(dispatch, data);
    },
    sharePost: (data) => {
      return actions.sharePost(dispatch, data);
    },
    setPostPrivacy: (item) => {
      dispatch(actions.setPostPrivacy(item));
    },
    submitEditPost: (data) => {
      actions.submitEditPost(dispatch, data);
    },
    setDefaultFetchStatus: () => {
      dispatch(actions.setDefaultFetchStatus());
    },
    setTriggerReady: () => {
      dispatch(actions.feedTriggerReady());
    },
    saveOffline: (data) => {
      dispatch(actions.saveOffline(data));
    },
    addFeedForm: (data) => {
      dispatch(actions.addFeedForm(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Postquestion);
