import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { HiOutlineSearch } from "react-icons/hi";

class Myjobfilters extends Component {
  render() {
    return (
      <>
        <div className="grid gap-2 xl:grid-cols-6 lg:grid-cols-6">
          <div className="col-span-3 xl:col-start-1 xl:col-end-3">
            <div className="grid gap-2 sm:grid-cols-2 ">
              <div className="flex items-center bg-white border rounded--xl">
                <Form.Control
                  placeholder="Search job titles..."
                  className="h-12 border-0 shadow-none rounded--xl"
                />
                <div className="flex items-center justify-center h-full pr-1">
                  <HiOutlineSearch size={20} />
                </div>
              </div>
              <div className="flex items-center border rounded--xl">
                <Form.Control
                  placeholder="Search Location"
                  className="h-12 border-0 shadow-none rounded--xl"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3 xl:col-end-7 xl:col-span-3">
            <div className="grid gap-2 sm:grid-cols-2 ">
              <div className="flex items-center rounded--xl">
                <p className="mr-2 whitespace-nowrap">Sort By:</p>
                <Dropdown
                  className="h-12 border gray rounded--xl"
                  placeholder="Sort By"
                  fluid
                  selection
                  options={[
                    {
                      key: "Post date",
                      text: "Post date",
                      value: "Post date",
                    },
                    {
                      key: "Job title",
                      text: "Job title",
                      value: "Job title",
                    },
                    {
                      key: "Location",
                      text: "Location",
                      value: "Location",
                    },
                    {
                      key: "Status",
                      text: "Status",
                      value: "Status",
                    },
                  ]}
                />
              </div>
              <div className="flex items-center  rounded--xl">
                <p className="mr-2 whitespace-nowrap">Order:</p>
                <Dropdown
                  className="h-12 border gray rounded--xl"
                  placeholder="Select Order"
                  fluid
                  selection
                  options={[
                    {
                      key: "Ascending",
                      text: "Ascending",
                      value: "Ascending",
                    },
                    {
                      key: "Descending",
                      text: "Descending",
                      value: "Descending",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Myjobfilters;
