import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";
import { Barskeleton } from "components";

class Withasideskeleton extends Component {
  render() {
    return (
      <>
        <div className="flex justify-between">
          <div className="hidden rounded-lg friends-side xl:block">
            <div className="fixed h-screen bg-white shadow-md group-leftside">
              <div className="px-4 pt-4">
                <div className="px-4 mt-3 -mx-2">
                  <div>
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-5 xs:px-4 sm:px-4 lg:px-20 md:px-5">
            <div className="grid gap-3 md:grid-cols-2">
              <Barskeleton />
              <Barskeleton />
              <Barskeleton />
              <Barskeleton />
              <Barskeleton />
              <Barskeleton />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Withasideskeleton;
