import React, { Component } from "react";
import "./styles.css";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { AiOutlineZoomOut, AiOutlineZoomIn } from "react-icons/ai";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Image } from "react-bootstrap";
import logo from "assets/images/logo.webp";
import Slider from "react-slick";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Navbar, Button } from "react-bootstrap";
import { Postdetails } from "components";
import ReactPlayer from "react-player";
import { OptimizeImage } from "helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
const config = {
  attributes: {
    disablePictureInPicture: true,
    controlsList: "nodownload",
  },
};

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <div
        className="flex items-center justify-center w-12 h-12 -ml-20 bg-gray-400 rounded-full shadow-sm"
        onClick={onClick}>
        <FiChevronRight color={colors.black} size={22} />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ zIndex: 1 }}>
      <div
        className="flex items-center justify-center w-12 h-12 ml-10 bg-gray-400 rounded-full shadow-sm"
        onClick={onClick}>
        <FiChevronLeft color={colors.black} size={22} />
      </div>
    </div>
  );
}

class Fullscreenpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: false,
      fullscreenExpanded: false,
      zoomIn: false,
      scaleXoom: 1,
    };
  }

  fullscreenOpen = () => {
    this.setState({ fullscreen: true });
  };

  fullscreenClose = () => {
    this.setState({ fullscreen: false });
  };

  zoomIn = () => {
    this.setState({ scaleXoom: this.state.scaleXoom + 0.5 });
  };

  zoomOut = () => {
    this.setState({ scaleXoom: this.state.scaleXoom - 0.5 });
  };

  render() {
    let attaches = [];
    let attachments = this.props.item.attachments ?? [];
    for (var i = 0; i < attachments.length; i++) {
      attaches[i] = {
        ...attachments[i],
        url: OptimizeImage(attachments[i].attachment_url, attachments[i].type),
      };
    }
    return (
      <>
        <Dialog
          fullScreen
          open={this.state.fullscreen}
          onClose={this.fullscreenClose}
          className="custom-backdrop rounded-0 fullscreenpopup"
          direction="up">
          <DialogContent className="important:p-0">
            <div className="grid h-screen grid-cols-2 overflow-hidden lg:grid-cols-4">
              <div
                className={
                  this.state.fullscreenExpanded
                    ? "bg--fullBlack lg:col-span-4 h-screen relative"
                    : "bg--fullBlack lg:col-span-3 h-screen relative"
                }>
                <div className="absolute z-10 flex items-center justify-between w-full px-3 py-2">
                  <div className="flex items-center justify-center">
                    <div
                      onClick={this.fullscreenClose}
                      className="flex items-center justify-center w-10 h-10 cursor-pointer">
                      <FiX size={24} style={{ color: colors.white }} />
                    </div>
                    <Navbar.Brand href="/" className="p-0 ml-4">
                      <Image
                        className="rounded-full w-28"
                        src={logo}
                        alt="logo"
                        roundedCircle
                      />
                    </Navbar.Brand>
                  </div>
                  <div className="flex items-center">
                    <Button
                      onClick={this.zoomIn}
                      className={`h-10 w-10 flex items-center justify-center bg-transparent border-0 p-0 shadow-none ${
                        this.state.scaleXoom === 2 ? "disabled " : null
                      }`}>
                      <AiOutlineZoomIn
                        size={24}
                        style={{ color: colors.white }}
                      />
                    </Button>
                    <Button
                      onClick={this.zoomOut}
                      className={`h-10 w-10 flex items-center justify-center mx-3 bg-transparent border-0 p-0 shadow-none ${
                        this.state.scaleXoom === 1 ? "disabled " : null
                      }`}>
                      <AiOutlineZoomOut
                        size={24}
                        style={{ color: colors.white }}
                      />
                    </Button>
                    <div
                      onClick={() =>
                        this.setState({
                          fullscreenExpanded: !this.state.fullscreenExpanded,
                        })
                      }
                      className="flex items-center justify-center w-10 h-10 cursor-pointer">
                      <BsArrowsAngleExpand
                        size={22}
                        style={{ color: colors.white }}
                      />
                    </div>
                  </div>
                </div>
                <Slider
                  infinite={false}
                  slidesToShow={1}
                  slidesToScroll={1}
                  speed={500}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}>
                  {attaches &&
                    attaches
                      .filter((attach) => attach.type === "image")
                      .map((item, key) => (
                        <div key={key} className="h-full text-center">
                          <LazyLoadImage
                            style={{
                              transform: `scale(${this.state.scaleXoom})`,
                            }}
                            className="object-cover m-auto lg:h-screen"
                            src={item.url}
                          />
                        </div>
                      ))}
                  {attaches &&
                    attaches
                      .filter((attach) => attach.type === "video")
                      .map((item, key) => (
                        <div key={key} className="h-full text-center">
                          <ReactPlayer
                            config={config}
                            className="m-auto important:h--screen"
                            controls
                            url={item.url}
                          />
                        </div>
                      ))}
                </Slider>
              </div>
              {this.state.fullscreenExpanded === false ? (
                <div className="h-full overflow-visible bg-white">
                  <Postdetails {...this.props} />
                </div>
              ) : null}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default Fullscreenpopup;
