import React, { Component } from "react";
import { Image } from "react-bootstrap";
import saved_posts from "assets/images/saved_posts.webp";
import { Dropdown } from "semantic-ui-react";
import { BsThreeDots, BsBookmarkDash } from "react-icons/bs";

class Savedcard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item } = this.props;
    if (!item) {
      return null;
    }
    return (
      <div className="flex items-center bg-white shadow-md rounded-xl  sm:p-3">
        <Image
          src={saved_posts}
          className="object-contain rounded-xl h-20 w-20 border"
        />
        <div className="ml-3 flex-grow flex items-center justify-between">
          <div className="flex items-center justify-between">
            <p className="black Medium flex items-center text-2xl">
              {item.title}
            </p>
          </div>
          <div className="flex items-center">
            <Dropdown
              icon={false}
              wrapSelection={true}
              direction="left"
              pointing="top left"
              text={
                <div>
                  <div className="bg-gray-200 rounded-lg flex items-center h-10 px-3 cursor-pointer hover:bg-gray-300 transform transition duration-300 hover:scale-90">
                    <BsThreeDots size={16} />
                  </div>
                </div>
              }
            >
              <Dropdown.Menu className="rounded--lg p-2 bg-white shadow-lg overflow-show border-0 w-96">
                <div
                  onClick={this.props.option}
                  className="flex items-center p-2 hover:bg-gray-100 rounded cursor-pointer"
                >
                  <div className="mr-3">
                    <BsBookmarkDash />
                  </div>
                  <p>Unsave</p>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

export default Savedcard;
