import React, { Component } from "react";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { IoIosAlbums } from "react-icons/io";
import { Savedcollectionpopup, Userskeletons } from "components";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Avatar } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";

class Savedleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.savedCollectionRef = React.createRef();
    this.per_page = 10;
    this.page = 1;
  }

  componentDidMount() {
    this.props.fetchCollections(this.per_page, this.page);
  }

  loadMore = () => {
    this.page++;
    this.props.fetchCollections(this.per_page, this.page);
  };

  render() {
    return (
      <div className="fixed FriendsLeftAside bg-white shadow-md flex flex-col p-5 overflow-hidden">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to="/"
                className="text-sm darkGray hover:bg-gray-200 rounded-full flex items-center justify-center -ml-3 mr-3 h-10 w-10"
              >
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Main Menu
                </Link>
                <h2 className="text-2xl">Saved</h2>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Link
              to="/saved"
              className="flex items-center hover:bg-gray-200 bg--lightPrimary  p-2 rounded-lg"
            >
              <div
                style={{ backgroundColor: colors.primary }}
                className="h-10 w-10 rounded-full items-center justify-center flex mr-3"
              >
                <IoIosAlbums size={18} style={{ color: colors.white }} />
              </div>
              <p>Saved Items</p>
            </Link>
            <div className="divider my-3" />
            <h4 className="mb-2">My Collections</h4>
            <InfiniteScroll
              //className="grid lg:grid-cols-2 gap-3"
              pageStart={1}
              loadMore={this.loadMore}
              hasMore={this.page < this.props.total ? true : false}
              loader={<Userskeletons />}
            >
              {this.props.data.map((item, key) => (
                <Link
                  key={key}
                  to={`/saved/${item.id}`}
                  className="flex items-center hover:bg--lightPrimary bg--lightGray mb-2 p-2 rounded-lg"
                >
                  <div className="h-10 w-10 rounded-full items-center justify-center flex mr-3">
                    <Avatar alt={item.title} src="" />
                  </div>
                  <p> {item.title}</p>
                </Link>
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <div className="divider my-3" />
        <div
          onClick={() => this.savedCollectionRef.current.collectionPopupOpen()}
          className="bg--lightPrimary mb-3 h-11 w-full rounded-xl cursor-pointer flex items-center justify-center transform transition duration-300 hover:scale-90"
        >
          <FiPlus color={colors.primary} size={18} />
          <p className="primary ml-2">Create New Collection</p>
        </div>

        <Savedcollectionpopup ref={this.savedCollectionRef} />
      </div>
    );
  }
}

const mapStateToProps = ({ Collection }) => {
  return {
    data: Collection.data,
    isFetching: Collection.isFetching,
    total: Collection.total,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CollectionRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchCollections: (per_page, page) => {
      actions.fetchCollections(dispatch, per_page, page);
    },
    deleteCollection: id => {
      actions.deleteCollection(dispatch, id);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Savedleftaside);
