import React, { Component } from "react";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { RiPhoneFill } from "react-icons/ri";
import { IoIosVideocam } from "react-icons/io";
import { Capitalize } from "helpers";
import moment from "moment";
import { HiOutlineArrowLeft } from "react-icons/hi";
import CallService from "../../../../chat/services/call-service";

class Flirtdetailaside extends Component {
  startCall = () => {
    const { item } = this.props;
    if (item.cube_user_id) {
      let data = { id: item.cube_user_id, name: item.first_name };
      CallService.startCall(data);
    }
  };

  render() {
    const { item } = this.props;
    const { flirt_setting } = item;
    const years = moment().diff(item.date_of_birth, "years", false);
    return (
      <div className="fixed flex flex-col p-5 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center">
            <Link
              to="/flirts/available-flirts"
              className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
              <HiOutlineArrowLeft
                size={18}
                style={{ color: colors.darkGray }}
              />
            </Link>
            <div>
              <Link
                to="/flirts/available-flirts"
                className="text-sm darkGray hover:underline">
                Flirts
              </Link>
              <p className="text-2xl Medium">
                {Capitalize(item.first_name)} {Capitalize(item.last_name)}
              </p>
            </div>
          </div>

          <div className="mt-4">
            <div className="my-2 divider" />
            {flirt_setting.about_me && (
              <>
                <p className="mb-2 Medium">About Me</p>
                <p className="darkGray fs-14">{flirt_setting.about_me}</p>
                <div className="my-2 divider" />
              </>
            )}
            {!!flirt_setting.is_age_visible &&
              flirt_setting.is_age_visible === 1 && (
                <>
                  <p className="mb-2 Medium">Age</p>
                  <p className="darkGray fs-14">{years}</p>
                  <div className="my-2 divider" />
                </>
              )}

            {!!flirt_setting.looking_for && (
              <>
                <h4 className="mb-2 Medium">Looking For</h4>
                <p className="darkGray fs-14">{flirt_setting.looking_for}</p>
                <div className="my-2 mb-1 divider" />
              </>
            )}
            {item.is_flirt && (
              <>
                <diev
                  onClick={this.startCall}
                  className="flex items-center p-2 mb-2 -mx-2 rounded-lg cursor-pointer hover:bg--lightPrimary">
                  <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg-primary">
                    <RiPhoneFill color={colors.white} size={20} />
                  </div>
                  <p>Audio Call</p>
                </diev>
                <div className="my-1 divider" />
                <diev
                  onClick={this.startCall}
                  className="flex items-center p-2 mb-2 -mx-2 rounded-lg cursor-pointer hover:bg--lightPrimary">
                  <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg-primary">
                    <IoIosVideocam color={colors.white} size={20} />
                  </div>
                  <p>Video Call</p>
                </diev>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Flirtdetailaside;
