import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { Buttonused } from "components";
import { OrdersAside } from "Modules";
import Role from "./Role";
import DeleteModal from "./DeleteModal";
import Item from "./Item";
import { BiLoaderAlt } from "react-icons/bi";
import { MdGroupAdd } from "react-icons/md";

const { actions } = require("redux/BarEnhancement");

export default function AdminTool(props) {
    const { id } = useParams();
    const dispatch = useDispatch();

    const roleRef = useRef(null);
    const modalRef = useRef(null);

    const roleData = useSelector((state) => Object.assign({}, state.enhancement.role.ids));
    const roleTotal = useSelector((state) => Object.assign({}, state.enhancement.role.total));
    const rolePage = useSelector((state) => Object.assign({}, state.enhancement.role.page));
    const roleIsMore = useSelector((state) => Object.assign({}, state.enhancement.role.isMore));
    const isFetching = useSelector((state) => state.enhancement.role.isFetching);

    const data = (roleData?.[id] || []);
    const total = (roleTotal?.[id] || 0);
    const page = (rolePage?.[id] || 1);
    const is_more = (roleIsMore?.[id] || false);


    const addPeople = () => {
        roleRef.current.open(id);
    }

    const onDelete = (role_id) => {
        modalRef.current.open(id, role_id)
    }

    useEffect(() => {
        if (page == 1) {
            actions.fetchRole(dispatch, { bar_id: id, page: 1 });
        }

    }, [id]);

    const fetchMore = () => {
        if (is_more) {
            actions.fetchRole(dispatch, { bar_id: id, page: page });
        }
    }

    return (
        <div className="flex justify-between">
            <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
                <OrdersAside title="Admin tool" />
            </div>
            <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
                <div className="m-auto w-full space-y-5">
                    <div className="overflow-y-auto mb-10" id="scrollbar">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-x-10 mx-4 mb-10">
                                <h1>Admin tool ({total})</h1>
                            </div>
                        </div>
                        <div className="mb-10">
                            <p className="text-slate-400">Invited people can access the bar admin tools and able to scan the QR code and view the VIP count.</p>
                        </div>

                        {total > 0 &&
                            <div className="flex items-center justify-center">

                                <div className="mb-3 rounded-lg w-100">
                                    <div className="flex items-center justify-end" >
                                        <div className="flex flex-row cursor-pointer mb-4" onClick={addPeople}>
                                            <MdGroupAdd size={20} className="mr-2 text-blue-800" />
                                            <p class="text-blue-800 " >Add people</p>
                                        </div>
                                    </div>
                                    <div className="rounded-lg " id="scrollbar">

                                        <div className="bg-gray-200 text-black capitalize leading-normal grid grid-cols-4 gap-4">
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                User
                                            </div>
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Role
                                            </div>
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Status
                                            </div>
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Actions
                                            </div>
                                        </div>
                                        <div className="bg-white">
                                            {data && data.length > 0 && data.map((item, key) => (
                                                <Item key={key} id={item} bar_id={id} onDelete={onDelete} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {isFetching &&
                            <div className="flex items-center justify-center">
                                <BiLoaderAlt size={21} className="animate-spin text-center " />
                            </div>
                        }

                        {is_more &&
                            <Buttonused
                                onClick={fetchMore}
                                Text={"load more"}
                                className="bg--primary grow text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 pl-2 pr-2 mx-auto rounded--lg mb-4"
                            />
                        }
                        {isFetching == false && total == 0 &&
                            <div className="w-full flex items-center justify-center flex-col">
                                <p className="text-xl pb-10 font-extrabold">You haven't invited any admin yet</p>
                                <div className="">
                                    <Buttonused
                                        onClick={addPeople}
                                        Text="Add people"
                                        className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-52 ml-auto rounded--lg"
                                    />
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <Role ref={roleRef} />
            <DeleteModal ref={modalRef} />
        </div>
    );
}
