import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";

class Applyjobaside extends Component {
  render() {
    return (
      <Link
        // to="/job/detail"
        className="bg-white border-2 rounded--xl p-4 block"
      >
        <div className="flex items-center">
          <div>
            <h3 className="black mb-2">Laravel / Laminas(Zend) Expert</h3>
            <p className=" fs-14">
              Deltacron Business Technologies (Private) Ltd. - California
            </p>
            <p className=" fs-13">Created: March 9, 2021</p>
            <p className=" fs-13 flex items-center">
              Address: 2821, 4425 Sharon Rd, Charlotte, North Carolina, US
            </p>
            <p className=" fs-13 flex items-center Medium">
              Rs 100,000 - Rs 140,000 a month - Temporarily Remote
            </p>
            <p className="mt-3 darkGray Light fs-14">
              Div is urgently looking for an experienced JavaScript Developer
              with experience in ReactJS for its Lahore office. Candidates with
              professional experience of 6 to 18 months are encouraged to apply.
            </p>
            <div className="flex items-center mt-4">
              <p className="fs-13 gray Light">15 days ago</p>
              <BsDot />
              <p className="fs-13">More...</p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default Applyjobaside;
