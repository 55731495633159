import * as React from "react";
import { OptimizeImage } from "helpers";
import avatar from "assets/images/avatar.png";
import { Image } from "react-bootstrap";

function Peopleimage(props) {
  if (props.item.gender === "female") {
    return (
      <div>
        {props.item.profile_photo ? (
          <Image
            src={OptimizeImage(props.item.profile_photo)}
            className={`${props.imageStyle ?? ""} object-cover`}
          />
        ) : (
          <Image
            src={avatar}
            className={`${props.imageStyle ?? ""} object-contain`}
          />
        )}
      </div>
    );
  } else {
    return (
      <div>
        {props.item.profile_photo ? (
          <Image
            src={OptimizeImage(props.item.profile_photo)}
            className={` ${props.imageStyle ?? ""} object-cover`}
          />
        ) : (
          <Image
            src={avatar}
            className={`${props.imageStyle ?? ""} object-contain`}
          />
        )}
      </div>
    );
  }
}
export default Peopleimage;
