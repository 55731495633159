import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import TextField from "@material-ui/core/TextField";
import { OptimizeImage } from "helpers";
import moment from "moment";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { connect } from "react-redux";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Buttonused } from "components";

class Barcreateaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    const { fetchBar, id } = this.props;
    fetchBar(id);
    this.props.fetchBarTypes();
    this.props.fetchBarServices();
    this.addBarForm("b_type", "Bar");
    if (this.props.bar.user_id === this.props.user.id) {
      this.formSetup();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bar.id != this.props.bar.id) {
      if (this.props.bar.user_id != this.props.user.id) {
        this.props.addBarForm({});
      }
    }
  }

  formSetup() {
    if (this.props.bar) {
      let item = this.props.bar;
      if (Object.keys(item).length > 0) {
        let form = Object.assign({}, this.props.form);
        let hours = [];

        form.b_id = item.id;
        form.b_name = item.name;
        form.b_address = item.address;
        form.b_city = item.city;
        form.b_state = item.state;
        form.b_postal_code = item.postal_code;
        form.phone_number = item.phone_number;
        form.website = item.website == "null" ? "" : item.website;
        form.b_country = item.country;
        if (item.bar_type_id) {
          form.b_type_id = item.bar_type_id;
        }
        var photos = [];
        if (item.photos && item.photos.length > 0) {
          item.photos.map((item, key) => {
            photos.push(OptimizeImage(item.attachment_url));
          });
        }
        form.photos = photos;
        this.setState({ photos: photos });
        form.type = item.type;
        form.services = item.bar_services.map((service) => service.id);
        if (item.bar_hours && item.bar_hours.length > 0) {
          item.bar_hours.map((hour, key) => {
            hours.push({
              day: hour.day,
              is_open: hour.is_open,
              open_time: moment.utc(hour.open_time).local().toDate(),
              close_time: moment.utc(hour.close_time).local().toDate(),
            });
          });
        }
        form.hours = hours;

        form.logo = item.logo ? OptimizeImage(item.logo.attachment_url) : null;
        form.banner = item.banner
          ? OptimizeImage(item.banner.attachment_url)
          : null;

        this.props.addBarForm(form);
        // if (item.logo != null) {

        //   this.imageSetup(item.logo, item.banner);
        // }
      }
    }
  }

  addServices = (id) => {
    let form = Object.assign({}, this.props.form);
    let checked = Object.assign([], form.services);
    let found = checked.find((data) => data === id);
    if (!found) {
      checked.push(id);
    } else {
      checked = checked.filter((data) => data !== id);
    }
    form.services = checked;
    this.props.addBarForm(form);
  };
  addBarForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addBarForm(form);
  };
  submitBar = () => {
    var formData = new FormData();
    let form = this.props.form;
    Object.keys(form).map(function (key) {
      if (key === "logo" && form[key]) {
        formData.append("logo", form[key]);
      } else if (key === "banner" && form[key]) {
        formData.append("banner", form[key]);
      } else if (key === "services") {
        form[key].forEach((item, i) => {
          formData.append("services[" + i + "]", item);
        });
      } else if (key === "hours") {
        form[key].forEach((item, i) => {
          formData.append("hours[" + i + "]", JSON.stringify(item));
        });
      } else {
        formData.append(key, form[key]);
      }
    });
    this.props.submitBar(form);
    this.setState({ redirect: true });
  };
  validation = () => {
    let flag = true;
    let array = [
      "b_name",
      "b_address",
      "b_city",
      "b_state",
      "b_postal_code",
      "b_country",
      "b_type_id",
      "services",
    ];

    let form = Object.assign({}, this.props.form);
    array.map((item, key) => {
      if (!Object.keys(form).some((index) => index === item)) {
        this.setState({ [item]: true });
        flag = false;
      }
    });
    Object.keys(form).map((item) => {
      if (array.some((index) => index === item)) {
        if (
          form[item] === null ||
          form[item] === "null" ||
          form[item] === "" ||
          form[item].length === 0
        ) {
          this.setState({ [item]: true });
          flag = false;
        } else {
          this.setState({ [item]: false });
        }
      }
    });
    if (flag && this.props.bar.user_id === this.props.user.id) {
      this.submitBar();
    } else if (flag && this.props.id === undefined) {
      this.submitBar();
    } else {
    }
  };
  render() {
    const { types, services, business_type } = this.props;
    let formServices = Object.assign([], this.props.form.services);
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/bars" />;
    }
    return (
      <div className="fixed flex flex-col p-5 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          {this.props.business_type === "Bar" ? null : (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Link
                  to="/bars"
                  className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                  <HiOutlineArrowLeft size={18} className="darkGray" />
                </Link>
                <div>
                  <div className="flex items-center darkGray fs-13 gap-x-1">
                    <Link
                      to="/bars"
                      className="text-sm primary hover:underline ">
                      Bars
                    </Link>
                    <FiChevronRight size={13} />
                    Create Bar
                  </div>
                  <h2 className="text-2xl">Add Company</h2>
                </div>
              </div>
            </div>
          )}

          {this.props.business_type === "Restaurant" && (
            <div className="flex-grow px-4 pt-4">
              <p className="flex items-center darkGray fs-13">
                <Link to="/restaurants" className="primary fs-13">
                  Restaurants{" "}
                </Link>
                <FiChevronRight size={13} />
                Create Restaurant
              </p>
              <div className="flex items-center justify-between">
                <h2>Add Company</h2>
              </div>
            </div>
          )}
          {this.props.business_type === "Company" && (
            <div className="flex-grow px-4 pt-4">
              <p className="flex items-center darkGray fs-13">
                <Link to="/my_companies" className="primary fs-13">
                  Companies{" "}
                </Link>
                <FiChevronRight size={13} />
                Create Company
              </p>
              <div className="flex items-center justify-between">
                <h2>Add Company</h2>
              </div>
            </div>
          )}
        </div>
        <div className="my-3 divider" />
        <div className="pr-2 mb-2 -mr-4 overflow-auto aside-2" id="scrollbar">
          <div className="mb-2">
            <TextField
              error={this.state.b_name ? true : false}
              value={this.props.form.b_name ?? ""}
              onChange={(e) => this.addBarForm("b_name", e.target.value)}
              label="Business Name"
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              value={this.props.form.b_pnumber ?? ""}
              onChange={(e) => this.addBarForm("b_pnumber", e.target.value)}
              label="Phone Number"
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              value={this.props.form.b_website ?? ""}
              onChange={(e) => this.addBarForm("b_website", e.target.value)}
              label="Website URL (optional)"
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              error={this.state.b_address ? true : false}
              value={this.props.form.b_address ?? ""}
              onChange={(e) => this.addBarForm("b_address", e.target.value)}
              label="Address"
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <TextField
              error={this.state.b_city ? true : false}
              value={this.props.form.b_city ?? ""}
              onChange={(e) => this.addBarForm("b_city", e.target.value)}
              label="City"
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
            />
          </div>
          <div className="mb-2">
            <CountryDropdown
              valueType="short"
              classes={`w-full border ${
                this.state.b_country && "border-danger"
              } rounded-lg text-gray-500 py-5 pl-2`}
              value={this.props.form.b_country ?? ""}
              onChange={(val) => this.addBarForm("b_country", val)}
            />
          </div>
          <div className="mb-2">
            <RegionDropdown
              countryValueType="short"
              classes={`w-full border ${
                this.state.b_state && "border-danger"
              } rounded-lg text-gray-500 py-5 pl-2`}
              country={this.props.form.b_country ?? ""}
              value={this.props.form.b_state ?? ""}
              onChange={(val) => this.addBarForm("b_state", val)}
            />
          </div>

          <div className="mb-2">
            <TextField
              error={this.state.b_postal_code ? true : false}
              value={this.props.form.b_postal_code ?? ""}
              onChange={(e) => this.addBarForm("b_postal_code", e.target.value)}
              label="Zip Code"
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
            />
          </div>

          <div className="mb-2">
            <p className="mb-1 fs-14">Business Type</p>
            <TextField
              fluid
              className="w-full rounded-lg important:border border-lightGray"
              variant="outlined"
              disabled
              defaultValue="Bar"
            />
          </div>

          {/* {this.props.chose_type === 'Restaurant' &&
        <>
       <div className="my-3 divider" />
        <div className="mb-2">
          <p className="mb-1 fs-14">Business Type</p>
          <TextField fluid className="w-full rounded-lg important:border border-lightGray"  variant="outlined" disabled defaultValue="Bar" disabled />
       </div>
       </> 
      } */}
          {/* 
        {this.props.chose_type === 'Company' &&
        <>
       <div className="my-3 divider" />
        <div className="mb-2">
        <p className="mb-1 fs-14">Business Type</p>
        <Dropdown
        className="border gray rounded--lg "
        placeholder="Select Type"
        fluid
        selection
        options={[
        {
        key: 'Bar',
        text: 'Bar',
        value: 'Bar',
          },
        {
        key:  'Restaurant',
        text: 'Restaurant',
        value:'Restaurant',
          },
            ]}
            onChange={(_,{value}) => this.addBarForm("b_type", value)}
        // onChange={(e) => this.addBarForm("b_type", e.target.value)}
        />
       </div>
       </> 
      } */}

          <div className="my-3 divider" />
          <p className="mb-1 fs-14 Medium">Bar Type</p>
          <div className="flex flex-wrap mb-3 gap-x-1 gap-y-2">
            {types &&
              types.map((item) => (
                <div
                  className={`bg-gray-200 ${
                    this.props.form.b_type_id == item.id && "bg--primary"
                  } hover:bg--primary hover:color-white-all rounded-full px-3 py-1.5 cursor-pointer inline-flex`}>
                  {/* {item.type_name} */}
                  <p
                    onClick={() => this.addBarForm("b_type_id", item.id)}
                    key={item.id}
                    className={`fs-13 ${
                      this.props.form.b_type_id == item.id && "text-white"
                    }`}>
                    {item.type_name}
                  </p>
                </div>
              ))}
          </div>
          <div className="my-3 divider" />
          <p
            className={`fs-14 Medium mb-1 ${
              this.state.services && "text-red-500"
            } `}>
            Bar Servies
          </p>
          <div className="flex flex-wrap mb-3 gap-x-1 gap-y-2">
            {services &&
              services.map((item) => (
                <div className="relative overflow-hidden select-bar-services">
                  <label
                    htmlFor={item.service_code}
                    className={`bg-gray-200 ${
                      formServices.find((element) => element == item.id) &&
                      "bg--primary"
                    } hover:bg--primary hover:color-white-all rounded-full px-3 py-1.5 cursor-pointer inline-flex`}>
                    <p
                      onClick={() => this.addServices(item.id)}
                      className={`fs-13 ${
                        formServices.find((element) => element == item.id) &&
                        "text-white"
                      }`}>
                      {item.service_name}
                    </p>
                  </label>
                </div>
              ))}
          </div>
        </div>
        <div>
          <Buttonused
            onClick={this.validation}
            Text="Save"
            className="w-full transition duration-300 transform border-0 shadow-none bg--lightPrimary hover:bg--lightPrimary focus:bg--lightPrimary primary h-11 rounded--lg hover:scale-90"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Bar, User }) => {
  return {
    form: Bar.form,
    bar: Bar.bar,
    services: Bar.services,
    types: Bar.types,
    user: typeof User.user !== "undefined" ? User.user : {},
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/BarRedux");
  return {
    ...ownProps,
    ...stateProps,
    submitBar: (data) => {
      actions.submitBar(dispatch, data);
    },
    addBarForm: (data) => {
      dispatch(actions.addBarForm(data));
    },
    fetchBarTypes: () => {
      actions.fetchBarTypes(dispatch);
    },
    fetchBarServices: () => {
      actions.fetchBarServices(dispatch);
    },
    fetchBars: (per_page, page) => {
      actions.fetchBars(dispatch, per_page, page);
    },
    fetchBar: (id) => {
      actions.fetchBar(dispatch, id);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Barcreateaside);
