import Handlebars from "handlebars";
import AuthService from "./auth-service";
import CallService from "./call-service";

class UIService {
    init = () => {
        this.addEventListenersForCallButtons();
        CallService.init();
    };

    addEventListenersForCallButtons = () => {
        document.getElementById("videochat-stop-call").addEventListener("click", () => CallService.stopCall());
        document.getElementById("videochat-mute-unmute").addEventListener("click", () => CallService.setAudioMute());
    };
}

// create instance
const UI = new UIService();
// lock instance
Object.freeze(UI);

export default UI;