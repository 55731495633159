import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { GoLocation } from "react-icons/go";
import { TextareaAutosize } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";

class Eventsbooking extends Component {
  constructor(props) {
    super(props);
    this.state = { item: {}, phone_number: false, open: false };
  }

  open = (item) => {
    const { booking } = this.props;
    this.setState({ item: item, open: true });
    if (booking && booking.id !== item.id) {
      this.props.addEventBooking({});
    }
  };

  close = () => {
    this.setState({ open: false });
  };

  submitEventBooking = () => {
    const { booking } = this.props;
    this.setState({ phone_number: false });
    if (booking && booking.phone_number && booking.phone_number.length > 9) {
      this.props.submitEventBooking(booking);
      this.setState({ open: false });
    } else {
      this.setState({ phone_number: true });
    }
  };

  addEventBooking = (key, value) => {
    const { item } = this.state;
    let booking = Object.assign({}, this.props.booking);
    booking["id"] = item.id;
    booking[key] = value;
    this.props.addEventBooking(booking);
  };

  render() {
    const { item } = this.state;
    const { booking } = this.props;
    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={this.close}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            <div className="flex items-center justify-center px-3 py-3 border-b">
              <div
                onClick={this.close}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-5 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
              <p className="text-2xl Medium">Booking</p>
              <p
                onClick={this.submitEventBooking}
                className="absolute z-10 flex items-center justify-center h-10 cursor-pointer right-3 hover:bg-gray-100 rounded-xl primary w-28">
                Book Now
              </p>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="p-4">
              <div className="relative pl-3 ml-3 dateTimeline">
                <div className="timelineDot" />
                <p className="mb-2 fs-12 darkGray">
                  {moment(item.start_date).format("MMMM Do YYYY")}{" "}
                  {!item.all_day &&
                    "at " + moment(item.start_date).format("hh:mm A")}
                </p>
                {!!item.end_date && (
                  <p className="fs-12 darkGray">
                    {moment(item.end_date).format("MMMM Do YYYY")}{" "}
                    {!item.all_day &&
                      "at " + moment(item.end_date).format("hh:mm A")}
                  </p>
                )}
              </div>
              {item.band && (
                <div className="mt-3">
                  <div className="flex items-center">
                    <p className="Medium">Price:</p>
                    <p className="ml-1 fs-14">
                      ${item.band.band_min_price}
                      {item.band.band_rate == "hour" && "/hrs"}
                      {item.band.band_rate == "range" &&
                        `- $${item.band.band_min_price}`}
                    </p>
                  </div>
                </div>
              )}
              {!!item.address && (
                <div className="mt-3">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                      <GoLocation size={14} />
                    </div>
                    <p className="fs-12 darkGray">{item.address ?? ""}</p>
                  </div>
                </div>
              )}
              <div className="mt-3">
                <h3 className="">{item.name ?? ""}</h3>
                <input
                  style={
                    this.state.phone_number
                      ? { borderColor: "red" }
                      : { borderColor: "#fff" }
                  }
                  placeholder="Phone number"
                  className="block w-full h-10 px-3 mt-1 bg-gray-100 rounded-lg Regular border-1 darkGray"
                  value={booking.phone_number ?? ""}
                  onChange={(text) =>
                    this.addEventBooking("phone_number", text.target.value)
                  }
                />
                <TextareaAutosize
                  minRows={8}
                  placeholder="Notes"
                  className="w-full p-3 mt-2 bg-gray-100 rounded-lg shadow-none outline-none resize-none Light"
                  value={booking.notes ?? ""}
                  onChange={(text) =>
                    this.addEventBooking("notes", text.target.value)
                  }
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    booking:
      typeof state.Event.booking !== "undefined" ? state.Event.booking : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/EventRedux");
  return {
    ...ownProps,
    ...stateProps,
    addEventBooking: (data) => {
      dispatch(actions.addEventBooking(data));
    },
    submitEventBooking: (data) => {
      actions.submitEventBooking(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Eventsbooking);
