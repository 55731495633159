import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";

class Userskeletons extends Component {
  render() {
    return (
      <div className="pb-0 overflow-hidden bg-white rounded-lg">
        <div className="flex items-center flex-grow ">
          <Skeleton
            variant="circle"
            height={62}
            className="rounded-full"
            width={62}
          />
          <div className="ml-3">
            <Skeleton
              variant="rect"
              height={8}
              className="rounded-full"
              width={150}
            />
            <Skeleton
              variant="rect"
              height={8}
              className="mt-2 rounded-full"
              width={100}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Userskeletons;
