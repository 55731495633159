import React, { Fragment, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Buttonused } from "components";
import { Listbox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { BsCheck2 } from "react-icons/bs";

export default function AddSubCategory(props) {
  const [selected, setSelected] = useState(props.options[0]);
  const [addItem, setAddItem] = useState(false);

  return (
    <>
      {addItem ? (
        <>
          <input
            autoFocus
            type="text"
            placeholder="Add Catergory"
            className="shadow-md border-0 rounded--lg h-[49px] w-full px-5 bg-white"
          />
          <Buttonused
            Text={"Save"}
            onClick={() => setAddItem(false)}
            className="bg-white w-20 shadow-md primary flex items-center justify-center hover:opacity-80 border-0 py-[16px] mx-auto rounded--lg"
          />
        </>
      ) : (
        <>
          <div className="w-full ml-auto flex-grow">
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative z-10">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-4 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <p className="block truncate">{selected.name}</p>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <FiChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  id="scrollbar"
                >
                  <Listbox.Options className="scrollbar absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {props.options.map((person, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-3 pl-10 pr-4 ${active
                            ? "bg-gray-200 text-amber-900"
                            : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected }) => (
                          <>
                            <p
                              className={`block truncate ${selected
                                  ? "font-medium primary"
                                  : "font-normal Light"
                                }`}
                            >
                              {person.name}
                            </p>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 primary">
                                <BsCheck2
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <Buttonused
            Icon={<FiPlus />}
            onClick={() => setAddItem(true)}
            className="bg-white w-20 shadow-md primary flex items-center justify-center hover:opacity-80 border-0 py-[17px] mx-auto rounded--lg"
          />
        </>
      )}
    </>
  );
}
