import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";

class Restaurantskeleton extends Component {
  render() {
    return (
      <div className="px-3 pb-0 overflow-hidden bg-white rounded-lg shadow-sm">
        <div className="-mx-4">
          <Skeleton variant="rect" height={190} />
        </div>
        <div className="flex items-center my-3">
          <Skeleton animation="pulse" variant="circle" width={52} height={52} />
          <div className="flex items-center justify-between flex-grow">
            <div className="ml-3">
              <Skeleton
                animation="pulse"
                variant="rect"
                height={8}
                className="rounded-full"
                width={240}
              />
              <Skeleton
                variant="rect"
                height={8}
                className="mt-2 rounded-full"
                width={150}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Restaurantskeleton;
