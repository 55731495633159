import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BsDash, BsThreeDots } from "react-icons/bs";
import { Dropdown } from "semantic-ui-react";
import Checkbox from "@material-ui/core/Checkbox";

class Myjobcard extends Component {
  render() {
    return (
      <div className="px-2 py-4 bg-white shadow-sm rounded-xl">
        <div className="grid gap-2 xl:grid-cols-10 ">
          <div className="col-span-3 ">
            <div className="flex items-center">
              <Checkbox />
              <div className="ml-1">
                <p className="primary Medium">Laravel / Laminas(Zend) Expert</p>
                <p className="darkGray fs-12">Lahore</p>
                <p className="darkGray fs-12">Created: March 9, 2021</p>
              </div>
            </div>
          </div>
          <div className="col-span-3 ">
            <div className="flex items-center h-full">
              <div className="flex items-center h-full">
                <div className="px-2 text-center ">
                  <p className="block primary">3</p>
                  <p className="primary">Active</p>
                </div>
              </div>
              <div className="flex items-center flex-grow h-full bg-gray-100 rounded-lg justify-evenly">
                <div className="ml-1 text-center">
                  <p className="block darkGray">3</p>
                  <p className="primary ">New</p>
                </div>
                <div className="ml-1 text-center">
                  <p className="block darkGray">3</p>
                  <p className="primary ">Contracting</p>
                </div>
                <div className="ml-1 text-center">
                  <p className="block darkGray">0 of 2</p>
                  <p className="primary ">Hired</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 xl:col-span-2 ">
            <div className="flex flex-col justify-center h-full xl:pl-5">
              <div className="flex items-center">
                <p className="block primary">Free</p>
                <BsDash />
                <p className="primary">Sponsor job</p>
              </div>
              <p className="Light fs-12 gray">
                Get more candidates by sponsoring
              </p>
            </div>
          </div>
          <div className="col-span-3 xl:col-span-2 ">
            <div className="flex items-center h-full justify-evenly">
              <div className="flex-gr">
                <Dropdown
                  className="border gray rounded--xl"
                  placeholder="Select"
                  fluid
                  selection
                  options={[
                    {
                      key: "Paused",
                      text: "Paused",
                      value: "Paused",
                    },
                    {
                      key: "Closed",
                      text: "Closed",
                      value: "Closed",
                    },
                  ]}
                />
              </div>

              <Dropdown
                icon={false}
                direction="left"
                pointing="top"
                text={
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
                      <BsThreeDots />
                    </div>
                  </div>
                }>
                <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                  <div className="p-2">
                    <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <p>Edit Job</p>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <p>Post Job in multiple locations</p>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <p>Report this job</p>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <p>Sponsor your job</p>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Myjobcard;
