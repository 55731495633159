import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import colors from "config/color/color.js";
import { Button } from "react-bootstrap";
import { Peoplenamepopup, Avatarimage } from "components";
import { FiChevronDown } from "react-icons/fi";
import { FiBookmark } from "react-icons/fi";
import { VscCloseAll } from "react-icons/vsc";
import { BsThreeDots, BsClockFill } from "react-icons/bs";
import { Capitalize } from "helpers";
import { FaUserMinus, FaUserPlus } from "react-icons/fa";

class FriendsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_ids: [],
    };
  }

  followFriend = () => {
    this.props.followFriend(this.props.item.id);
  };

  unfollowFriend = () => {
    this.props.unfollowFriend(this.props.item.id);
  };

  snoozeFriend = () => {
    this.props.snoozeFriend(this.props.item.id);
  };

  unsnoozeFriend = () => {
    this.props.unsnoozeFriend(this.props.item.id);
  };

  checkedType = (id) => {
    let category_ids = this.props.item.categories_ids;
    const index = category_ids.find((item) => item === id);
    if (index) {
      category_ids = category_ids.filter((item) => item !== id);
    } else {
      category_ids.push(id);
    }
    let data = { type_ids: category_ids };
    this.props.addFriendToType(this.props.item.id, data);
  };

  render() {
    const { item, user } = this.props;
    return (
      <div className="bg-white shadow-md UserCard rounded-xl">
        <div className="relative">
          <Avatarimage
            imageSize={{
              height: 160,
              width: "100%",
              borderRadius: 0,
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
            item={item}
          />
          {item.is_friend && user.id !== item.id && (
            <div className="absolute items-center top-2 right-2">
              <Popover className="relative">
                <Popover.Button className="flex items-center justify-center w-8 h-8 m-auto bg-gray-200 rounded-full hover_dots_drpdwn">
                  <BsThreeDots
                    size={18}
                    style={{ color: colors.darkGray }}
                    className="m-auto"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-0"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-0">
                  <Popover.Panel className="absolute right-0 z-50 w-screen max-w-md mt-3 -ml-4 transform rounded-lg sm:px-0 lg:ml-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative p-4 bg-white rounded-lg">
                        <Popover className="relative">
                          <Popover.Button className="flex items-center w-full px-2 py-2 rounded-lg hover:bg-gray-100">
                            <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                              <FiBookmark size={18} />
                            </div>

                            <div className="flex-grow text-left">
                              <p>Add To List</p>
                              <p className="text-sm Light darkGray">
                                add this to your list.
                              </p>
                            </div>
                            <FiChevronDown size={18} />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-0"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-0">
                            <Popover.Panel className="absolute right-0 z-50 w-screen max-w-sm -ml-4 transform sm:px-0 lg:ml-0">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative flex flex-col p-4 bg-white gap-y-1">
                                  <div>
                                    <div
                                      onClick={() => this.checkedType(1)}
                                      className={`py-2 ${
                                        item.categories_ids.includes(1) &&
                                        "bg--lightPrimary"
                                      } hover:bg-gray-100 rounded-lg px-2 cursor-pointer`}>
                                      <p>Family & Relative</p>
                                      <p className="text-sm Light darkGray">
                                        Categorize your friend to family and
                                        relative
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      onClick={() => this.checkedType(2)}
                                      className={`py-2 ${
                                        item.categories_ids.includes(2) &&
                                        "bg--lightPrimary"
                                      } hover:bg-gray-100 rounded-lg px-2 cursor-pointer`}>
                                      <p>Business Associate</p>
                                      <p className="text-sm Light darkGray">
                                        Categorize your friend to business
                                        associate
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      onClick={() => this.checkedType(3)}
                                      className={`py-2 ${
                                        item.categories_ids.includes(3) &&
                                        "bg--lightPrimary"
                                      } hover:bg-gray-100 rounded-lg px-2 cursor-pointer`}>
                                      <p>Co-Workers</p>
                                      <p className="text-sm Light darkGray">
                                        Categorize your friend to co-workers
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      onClick={() => this.checkedType(4)}
                                      className={`py-2 ${
                                        item.categories_ids.includes(4) &&
                                        "bg--lightPrimary"
                                      } hover:bg-gray-100 rounded-lg px-2 cursor-pointer`}>
                                      <p>Customers</p>
                                      <p className="text-sm Light darkGray">
                                        Categorize your friend to customers
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      onClick={() => this.checkedType(5)}
                                      className={`py-2 ${
                                        item.categories_ids.includes(5) &&
                                        "bg--lightPrimary"
                                      } hover:bg-gray-100 rounded-lg px-2 cursor-pointer`}>
                                      <p>Followers</p>
                                      <p className="text-sm Light darkGray">
                                        Categorize your friend to followers
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </Popover>
                        <div className="my-1 divider" />
                        <div
                          onClick={
                            item.is_snoozed
                              ? this.unsnoozeFriend
                              : this.snoozeFriend
                          }
                          className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                          <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                            <BsClockFill size={18} />
                          </div>
                          <div>
                            <p>
                              {item.is_snoozed
                                ? "Snoozed"
                                : "Unfollow for 30 days"}
                            </p>
                            <p className="text-sm break-all Light darkGray">
                              {!item.is_snoozed
                                ? "You won't see posts from " +
                                  Capitalize(this.props.item.first_name) +
                                  " in yours Feed for 30 days"
                                : "Temporarily stop seeing posts from " +
                                  Capitalize(this.props.item.first_name) +
                                  "."}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={
                            this.props.item.is_followed
                              ? this.unfollowFriend
                              : this.followFriend
                          }
                          className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                          <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                            <VscCloseAll size={18} />
                          </div>
                          <div>
                            <p>{item.is_followed ? "Unfollow" : "Follow"}</p>
                            <p className="text-sm Light darkGray">
                              {item.is_followed
                                ? "Stop seeing posts but stay friends"
                                : "Resume seeing posts"}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            this.props.cancelFriendRequest(item.id)
                          }
                          className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                          <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                            <FaUserMinus size={18} />
                          </div>
                          <div>
                            <p>Unfriend</p>
                            <p className="text-sm Light darkGray">
                              Remove as a friend.
                            </p>
                          </div>
                        </div>
                        <div className="my-1 divider" />
                        <div
                          onClick={() => this.props.blockToggle(item.id)}
                          className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                          <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                            <FaUserPlus size={18} />
                          </div>
                          <div>
                            <p>{item.is_blocked ? "Unblock" : "Block"}</p>
                            <p className="text-sm Light darkGray">
                              {item.is_blocked
                                ? "You able to see or contact each other."
                                : "You won't be able to see or contact each other."}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
          )}
        </div>
        <div className="p-3">
          <Peoplenamepopup {...this.props} />
          <div className="grid gap-2 mt-2">
            {!item.is_friend && !item.request.is_entry && (
              <Button
                onClick={() => this.props.sendFriendRequest(item.id)}
                className="w-full p-0 border-0 shadow-none bg--lightPrimary Regular hover:opacity-50 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text rounded--lg h-9">
                {/* <FiUserPlus /> */}
                Add Friend
              </Button>
            )}
            {!item.is_friend &&
              item.request.is_entry &&
              item.request.is_send_request && (
                <Button
                  onClick={() => this.props.cancelFriendRequest(item.id)}
                  className="block w-full p-0 border-0 shadow-none bg--lightGray hover:bg--lightGray Regular hover:opacity-50 focus:bg--lightGray black hover:black foucs:black rounded--lg h-9">
                  Remove
                </Button>
              )}

            {!item.is_friend &&
              item.request.is_entry &&
              !item.request.is_send_request && (
                <>
                  <Button
                    onClick={() => this.props.acceptFriendRequest(item.id)}
                    className="w-full p-0 border-0 shadow-none bg--lightPrimary Regular hover:opacity-50 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text rounded--lg h-9">
                    Accept
                  </Button>
                  <Button
                    onClick={() => this.props.cancelFriendRequest(item.id)}
                    className="block w-full p-0 border-0 shadow-none bg--lightGray hover:bg--lightGray Regular hover:opacity-50 focus:bg--lightGray black hover:black foucs:black rounded--lg h-9">
                    Reject
                  </Button>
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default FriendsMain;
