import React, { Component } from "react";
import { BsDash } from "react-icons/bs";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Buttonused } from "components";

class Baraboutaside extends Component {
  constructor(props) {
    super(props);
    this.state = { flag: false };
  }
  claimCompany = (item) => {
    this.props.claimCompany(item);
    this.setState({ flag: true });
  };
  render() {
    const { item } = this.props;
    if (this.state.flag) {
      return <Redirect to={"/bars"} />;
    }
    return (
      <div>
        <div className="p-5 bg-white rounded-lg shadow-sm">
          <h3 className="text-xl Medim">Working Hours</h3>
          <div className="flex items-center mt-1">
            <p className="success fs-13">Open</p>
            <BsDash className="gray" />
            <p className="fs-13 gray">Monday</p>
          </div>
          {item.bar_hours != "" && (
            <div className="mt-5 md:p-4 p-xs-0">
              <div className="relative flex flex-col items-center justify-center p-5 border-2 rounded-xl border--success">
                <div className="w-32 mx-auto mb-4 -mt-12 bg-white border-2 rounded-xl border--success">
                  <p className="text-xl text-center success ">Opening Hours</p>
                </div>
                {item.bar_hours != "" &&
                  item.bar_hours.map((item, key) => (
                    <div key={key}>
                      <p>{item.day}:</p>
                      {item.open === 1 ? (
                        <div className="flex items-center justify-center mb-2">
                          <p className="gray">
                            {moment
                              .utc(item.open_time)
                              .local()
                              .format("hh:mm A")}
                          </p>
                          <BsDash className="gray" />
                          <p className="gray">
                            {moment
                              .utc(item.close_time)
                              .local()
                              .format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <p className="gray">Close</p>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="my-3 divider" />
          {this.props.item.user_id != this.props.user.id && (
            <>
              {!this.props.item.is_claimed && (
                <>
                  <Buttonused
                    className="w-full h-10 border-0 shadow-none white bg--primary hover:bg--primary foucs:bg--primary hover:opacity-80 Regular rounded--xl"
                    onClick={() => this.claimCompany(this.props.item.id)}
                    rippleContainer="rounded--xl w-full"
                    Text="Claim My Company"
                  />
                </>
              )}
              {this.props.item.is_claimed === true && (
                <>
                  <Buttonused
                    className="w-full h-10 border-0 shadow-none white bg--danger hover:bg--danger foucs:bg--danger hover:opacity-80 Regular rounded--xl"
                    // onClick={() => this.claimCompany(this.props.item.id)}
                    onClick={() => console.log()}
                    rippleContainer="rounded--xl w-full"
                    Text="You have Claimed this Company"
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ User }) => {
  return {
    user: User.user,
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/BarRedux");
  return {
    ...ownProps,
    ...stateProps,
    claimCompany: (item) => {
      actions.claimBar(dispatch, item);
    },
    // removeBarFavourite: (item) => {
    //   actions.removeBarFavourite(dispatch, item);
    // },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Baraboutaside);
// export default ;
