import React, { Component } from "react";
import { Image } from "react-bootstrap";
import styles from "./styles.js";
import colors from "config/color/color.js";
import { FaRegThumbsUp, FaRegCommentAlt } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { FaThumbsUp } from "react-icons/fa";
import { Popup } from "semantic-ui-react";
import hundred from "assets/images/reaction/100.png";
import angry from "assets/images/reaction/angry.gif";
import haha from "assets/images/reaction/haha.gif";
import love from "assets/images/reaction/love.gif";
import rolling from "assets/images/reaction/rolling.gif";
import sad from "assets/images/reaction/sad.gif";
import wow from "assets/images/reaction/wow.gif";
import { Grouppostcomment } from "components";

class Grouppostfooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grouppostcomment: false,
    };
  }
  render() {
    return (
      <div className="relative mt-3 post-fotter">
        <div className="flex items-center justify-between">
          <Popup
            className="p-2 px-3"
            inverted
            style={styles.Tooltip}
            position="bottom center"
            content={
              <div>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
                <p className="text-sm cursor-pointer white Light">
                  User Name one
                </p>
              </div>
            }
            trigger={
              <div className="flex items-center cursor-pointer">
                <div
                  style={styles.thumbLike}
                  className="flex items-center justify-center w-6 h-6 rounded-full">
                  <FaThumbsUp size={11} style={{ color: colors.white }} />
                </div>
                <p className="ml-2 darkGray Light">729K</p>
              </div>
            }
          />
          <div className="flex items-center cursor-pointer">
            <div className="flex items-center mr-5 cursor-pointer">
              <p className="ml-2 darkGray Light">8 Comment</p>
            </div>
            <div className="flex items-center cursor-pointer">
              <Popup
                className="p-2 px-3"
                inverted
                style={styles.Tooltip}
                position="bottom center"
                content={
                  <div>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                    <p className="text-sm cursor-pointer white Light">
                      User Name one
                    </p>
                  </div>
                }
                trigger={
                  <div className="flex items-center cursor-pointer">
                    <p className="ml-2 darkGray Light">12 Shares</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <div className="flex items-center py-1 mt-3 border-t justify-evenly ">
          <div className="flex items-center justify-center flex-grow py-2 rounded-lg cursor-pointer like-reactions hover:bg-gray-200">
            <FaRegThumbsUp style={{ color: colors.darkGray }} />
            <p className="ml-2 darkGray">Like</p>
            <div className="reactions flex items-center justify-evenly w-96 shadow-lg bg-white rounded-full py-2.5">
              <Image src={hundred} className="w-10 h-10" />
              <Image src={angry} className="w-10 h-10" />
              <Image src={haha} className="w-10 h-10" />
              <Image src={love} className="w-10 h-10" />
              <Image src={rolling} className="w-10 h-10" />
              <Image src={sad} className="w-10 h-10" />
              <Image src={wow} className="w-10 h-10" />
            </div>
          </div>
          <div
            onClick={() =>
              this.setState({ grouppostcomment: !this.state.grouppostcomment })
            }
            className="flex items-center justify-center flex-grow py-2 rounded-lg cursor-pointer hover:bg-gray-200">
            <FaRegCommentAlt style={{ color: colors.darkGray }} />
            <p className="ml-2 darkGray">Comment</p>
          </div>
          <div className="flex items-center justify-center flex-grow py-2 rounded-lg cursor-pointer hover:bg-gray-200">
            <RiShareForwardLine style={{ color: colors.darkGray }} />
            <p className="ml-2 darkGray">Share</p>
          </div>
        </div>
        {this.state.grouppostcomment ? (
          <div>
            <Grouppostcomment />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Grouppostfooter;
