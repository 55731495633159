import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { OptimizeImage, Capitalize } from "helpers";
import cover from "assets/images/grouppreview.webp";
import { avatar as avatar2 } from "assets";
import { Buttonused } from "components";

class Groupcard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _acceptGroupRequest = () => {
    const { item } = this.props;
    this.props._acceptGroupRequest(item.group.id, item.user.id, item.id);
  };

  _joinGroupInvite = () => {
    const { item } = this.props;
    this.props._joinGroupInvite(item.group.id, item.id);
  };

  _leaveGroup = () => {
    const { item } = this.props;
    this.props._leaveGroup(item.group.id);
  };

  _rejectGroupRequest = () => {
    const { item } = this.props;
    this.props._rejectGroupRequest(item.id);
  };

  render() {
    const { item } = this.props;
    if (!item) {
      return null;
    }
    let banner = cover;
    if (item.group.attachments) {
      banner = OptimizeImage(item.group.attachments.attachment_url);
    }
    return (
      <div className="overflow-hidden bg-white shadow-md Groupcard rounded-xl">
        <div>
          <Avatar
            variant="square"
            style={{ height: 182 }}
            src={banner}
            className="important:w-full">
            <Avatar
              variant="square"
              style={{ height: 182 }}
              src={cover}
              className="important:w-full"
            />
          </Avatar>
        </div>
        <div className="p-4">
          <Link
            to={`/groups/details/${item.group.id}`}
            className="black Medium">
            {item.group.title}
          </Link>
          <div className="flex items-center">
            <p className="text-sm darkGray">
              {item.group && item.group.members_count} members
            </p>
            {/* <BsDot style={{ color: colors.darkGray }} />
            <p className="text-sm darkGray">60 posts a day</p> */}
          </div>
          {item.invited === 1 && item.invited_by ? (
            <>
              <div className="flex items-center mt-2">
                <Avatar
                  className="mr-3 rounded-full"
                  alt={item.invited_by.first_name}
                  title={Capitalize(item.invited_by.first_name)}
                  src={
                    item.invited_by.profile_photo
                      ? OptimizeImage(item.invited_by.profile_photo)
                      : avatar2
                  }
                />
                <div>
                  <p>
                    {Capitalize(item.invited_by.first_name)}{" "}
                    {Capitalize(item.invited_by.last_name)}
                  </p>
                </div>
              </div>
              <p className="mt-2 darkGray fs-14">Invite you to join group</p>
            </>
          ) : (
            <>
              <div className="flex items-center mt-2">
                <Avatar
                  className="mr-3 rounded-full"
                  alt={item.user.first_name}
                  title={Capitalize(item.user.first_name)}
                  src={
                    item.user.profile_photo
                      ? OptimizeImage(item.user.profile_photo)
                      : avatar2
                  }
                />
                <div>
                  <p>
                    {Capitalize(item.user.first_name)}{" "}
                    {Capitalize(item.user.last_name)}
                  </p>
                </div>
              </div>
              <p className="mt-2 darkGray fs-14">Request to join group</p>
            </>
          )}
          {item.invited === 1 ? (
            <div className="flex gap-3 mt-3">
              <Buttonused
                className="block w-full p-0 border-0 shadow-none white bg--primary hover:opacity-50 hover:bg--primary focus:bg--primary rounded--lg h-9"
                onClick={this._joinGroupInvite}
                rippleContainer="flex-grow rounded--lg w-full"
                Text="Join"
              />
              <Buttonused
                className="block w-full p-0 border-0 shadow-none white bg--danger hover:opacity-50 hover:bg--danger focus:bg--danger rounded--lg h-9"
                onClick={this._rejectGroupRequest}
                rippleContainer="flex-grow rounded--lg w-full"
                Text="Reject"
              />
            </div>
          ) : (
            <div className="flex gap-3 mt-3">
              <Buttonused
                className="block w-full p-0 border-0 shadow-none white bg--primary hover:opacity-50 hover:bg--primary focus:bg--primary rounded--lg h-9"
                onClick={this._acceptGroupRequest}
                rippleContainer="flex-grow rounded--lg w-full"
                Text="Accept"
              />
              <Buttonused
                className="block w-full p-0 border-0 shadow-none white bg--danger hover:opacity-50 hover:bg--danger focus:bg--danger rounded--lg h-9"
                onClick={this._rejectGroupRequest}
                rippleContainer="flex-grow rounded--lg w-full"
                Text="Reject"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Groupcard;
