import * as React from "react";
import "./styles.css";
import { Image } from "react-bootstrap";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import ChatService from "../services/chat-service";
import CallService from "../services/call-service";
import { RecyclerListView, DataProvider } from "recyclerlistview/web";
import { ChatLayoutUtil } from "../helpers/LayoutUtil";
import Item from "./Item";
import MessageInput from "./MessageInput";
import ConnectyCube from "connectycube";
import Avatar from "../helpers/avatar/avatar";
import { OptimizeImage } from "helpers";
import { IoIosCall } from "react-icons/io";

class Message extends React.Component {
  scrollWidth = 0;
  scrollHeight = 0;
  listenerWindowSize = null;
  timer = null;
  isFetchingMsg = false;
  messagesListRef = null;
  listenerLazyLoad = false;
  needToGetMoreMessage = null;

  recycler_Y = 0;
  contentHeight = 0;
  contentNewOffset = 0;

  constructor(props) {
    super(props);
    this.state = {
      height: false,
      newChatHeight: true,
      newChat: false,
      expanded: false,
      threadHeight: true,

      isAlredy: true,
      dataProvider: new DataProvider((r1, r2) => {
        return r1 !== r2 || r1.send_state !== r2.send_state;
      }),
      layoutProvider: [],
    };
  }

  componentDidUpdate(prewProps) {
    const dialog = ChatService.getDialogById(this.props.selectedDialog.id);
    try {
      if (
        prewProps.messages[dialog.id] &&
        prewProps.messages[dialog.id] !== this.props.messages[dialog.id]
      ) {
        if (this.props.messages[dialog.id].length) {
          this.setState(
            {
              dataProvider: this.state.dataProvider.cloneWithRows(
                this.props.messages[dialog.id]
              ),
            },
            () => {
              this.updateScrollPosition();
            }
          );
        }
      }
    } catch (e) {}
  }

  updateScrollPosition = () => {
    if (this.messagesListRef) {
      setTimeout(() => {
        try {
          const getElement =
            document.getElementById("chat-body").children[0].children[0]
              .children[0].style.height;
          const fullScrollHeight = getElement.slice(0, getElement.length - 2);
          const newOffset =
            this.recycler_Y + (fullScrollHeight - this.contentHeight);
          this.messagesListRef.scrollToOffset(0, newOffset);
        } catch (e) {}
      }, 500);
    }
  };

  sendMessageCallback = async (messageText, img) => {
    const dialog = ChatService.getDialogById(this.props.selectedDialog.id);
    if (messageText.length <= 0 && !img) return;
    await ChatService.sendMessage(
      dialog,
      messageText,
      img,
      this.scrollToBottom
    );
  };

  open = () => {
    this.setState({ newChat: true }, () => this.getDialogInfo());
  };

  lazyLoadMessages = (elem, y) => {
    this.recycler_Y = y;
    this.contentHeight = elem.nativeEvent.contentSize.height;
    if (this.listenerLazyLoad && this.needToGetMoreMessage && y < 2000) {
      this.listenerLazyLoad = false;
      ChatService.getMoreMessages(this.props.selectedDialog).then(
        amountMessages => {
          amountMessages === 100
            ? (this.needToGetMoreMessage = true)
            : (this.needToGetMoreMessage = false);
          this.listenerLazyLoad = true;
        }
      );
    }
  };

  getDialogInfo = async () => {
    window.addEventListener("resize", this.handleResize);
    this.scrollWidth = document.getElementById("chat-body").clientWidth;
    this.scrollHeight = document.getElementById("chat-body").clientHeight;
    const dialog = ChatService.getDialogById(this.props.selectedDialog.id);

    setTimeout(() => {
      try {
        this.scrollToBottom();
      } catch (e) {}
    }, 500);

    ChatService.getMessages(dialog)
      .catch(e => console.log(`Error.\n\n${JSON.stringify(e)}`))
      .then(amountMessages => {
        try {
          amountMessages === 100
            ? (this.needToGetMoreMessage = true)
            : (this.needToGetMoreMessage = false);
          this.setState({
            isFetchingMsg: true,
            layoutProvider: ChatLayoutUtil.getChatLayoutProvider({
              width: this.scrollWidth,
              dialogId: dialog.id,
              currentUserId: this.props.user.cube_user_id,
            }),
            dataProvider: this.state.dataProvider.cloneWithRows(
              this.props.messages[dialog.id]
            ),
          });
          this.updateScrollPosition();
          this.listenerLazyLoad = true;
        } catch (e) {}
      });
  };

  _renderMessage = (type, item) => {
    const { users } = this.props;
    // 1 - current sender & 2 - other sende

    return <Item item={item} user_id={this.props.user.cube_user_id} />;
  };

  getDialogById = () => {
    return ChatService.getDialogById(this.props.selectedDialog.id);
  };

  scrollToBottom = () => {
    if (this.messagesListRef) {
      this.messagesListRef.scrollToIndex(
        this.state.dataProvider._data.length - 1,
        false
      );
    }
  };

  startCall = () => {
    const { selectedDialog, user } = this.props;
    const occupants_id = selectedDialog.occupants_ids.find(
      elem => elem != user.cube_user_id
    );
    let data = { id: occupants_id, name: user.first_name };
    CallService.startCall(data);
  };

  render() {
    const { dataProvider, layoutProvider, isAlredy, isFetchingMsg, newChat } =
      this.state;
    const { selectedDialog, user } = this.props;
    let currentDialog;

    if (selectedDialog) {
      currentDialog = this.getDialogById();
    }
    if (!newChat) {
      return null;
    }
    let photo = null;
    if (selectedDialog.type === 2) {
      if (selectedDialog.photo) {
        try {
          photo = ConnectyCube.storage.privateUrl(selectedDialog.photo);
        } catch (e) {}
      }
    } else {
      if (selectedDialog.photo) {
        photo = OptimizeImage(selectedDialog.photo);
      }
    }

    return (
      <div className={`w-full shadow-sm border rounded-t-xl`}>
        <div className="bg-white rounded-t-xl">
          <div className="flex items-center justify-between hover:bg-gray-100 py-2 px-3 rounded-t-xl">
            <div
              onClick={() =>
                this.setState({ threadHeight: !this.state.threadHeight })
              }
              className="flex items-center cursor-pointer flex-grow mr-3"
            >
              <div className="relative">
                {photo ? (
                  <Image
                    src={photo}
                    className="h-10 w-10 rounded-full object-cover object-top mr-2 border"
                  />
                ) : (
                  <Avatar
                    photo={""}
                    name={selectedDialog.name}
                    size={35}
                    className="h-12 w-12 rounded-full object-cover mr-3"
                  />
                )}
                {/* <Image
                  src={avatar}
                  className="h-11 w-11 rounded-full object-cover mr-3"
                /> */}
                {/* <div className="absolute right-0">
                  <OnlineStatusXS />
                </div> */}
              </div>
              <p className="ml-2">{selectedDialog.name}</p>
            </div>
            {selectedDialog.type !== 2 && (
              <div className="flex items-center">
                <div
                  onClick={this.startCall}
                  className="cursor-pointer hover:bg-gray-300 w-8 h-8 flex items-center justify-center rounded-full"
                >
                  <IoIosCall className="primary" size={20} />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              height: "100%",
            }}
          >
            <div
              className="chatwindow-all-threads "
              style={{
                height: "calc(100vh - 25vh)",
              }}
            >
              <div className="chatwindow-new-threads h-full ">
                {/* <div className="flex items-center rounded-lg cursor-pointer px-2 mx-2">
                  <input
                    placeholder="Type a name or multiple names"
                    className="h-10 w-full Regular border-0  bg-transparent"
                  />
                </div> */}
                {/* <div
                  id="scrollbar"
                  className="chatwindow-all-threads border-t p-2 overflow-auto"
                  style={{
                    height:
                      this.state.newChatHeight === true &&
                      this.state.expanded === false
                        ? "230px"
                        : this.state.expanded === true
                        ? "calc(100vh - 41vh)"
                        : 0,
                  }}
                >
                {dataProvider._data.length > 0 && dataProvider._data.map((item, key) => (
                    <Item item={item} key={key} user_id={user.cube_user_id} />
                ))}
                </div> */}
                <div className="chatwindow-all-threads border-t p-2 overflow-auto">
                  <div
                    className="chat-body"
                    id="chat-body"
                    style={{
                      width: "100%",
                      height: "calc(100vh - 30vh)",
                    }}
                  >
                    {isAlredy && isFetchingMsg ? (
                      dataProvider._data.length > 0 && (
                        <>
                          <RecyclerListView
                            ref={ref => (this.messagesListRef = ref)}
                            dataProvider={dataProvider}
                            layoutProvider={layoutProvider}
                            rowRenderer={this._renderMessage}
                            onScroll={(elem, x, y) => {
                              this.lazyLoadMessages(elem, y);
                            }}
                            inv
                          />
                        </>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
                <div className={`pt-1 bottom-0 w-full border-t `}>
                  <MessageInput
                    sendMessageCallback={this.sendMessageCallback}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ selectedDialog, messages, User }) => ({
  selectedDialog,
  messages,
  user: User.user,
});

export default connect(mapStateToProps, undefined, undefined, {
  forwardRef: true,
})(Message);
