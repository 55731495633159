import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "lifeWidgets",
  whitelist: [
    "User"
  ],
  storage,
};

export default compose(applyMiddleware(thunk))(createStore)(
  persistReducer(persistConfig, reducers)
);
