import React, { Component } from "react";
import "./styles.css";
import { RiLoader4Fill } from "react-icons/ri";

class Spinner extends Component {
  render() {
    return <RiLoader4Fill className="spinner darkGray" size={70} />;
  }
}

export default Spinner;
