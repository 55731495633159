import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AiFillSetting } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { FaRegIdCard } from "react-icons/fa";
import { RiUserStarLine } from "react-icons/ri";
import styles from "./styles.js";
import "./styles.css";
import { Link } from "react-router-dom";
import colors from "config/color/color.js";
import { SettingPopup } from "components";
import { connect } from "react-redux";
import { Popover } from "@headlessui/react";

class Menuaccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.menuSettingRef = React.createRef();
  }

  groupsChange = () => {
    this.props.menuSettings("groups");
  };

  savedChange = () => {
    this.props.menuSettings("saved");
  };

  commsChange = () => {
    this.props.menuSettings("comms");
  };

  flirtChange = () => {
    this.props.menuSettings("flirt");
  };

  barsChange = () => {
    this.props.menuSettings("bars");
  };

  restaurantChange = () => {
    this.props.menuSettings("restaurant");
  };

  companyChange = () => {
    this.props.menuSettings("company");
  };

  billRightsChange = () => {
    this.props.menuSettings("bill_of_rights");
  };

  feedbackChange = () => {
    this.props.menuSettings("feedback");
  };

  how_toChange = () => {
    this.props.menuSettings("how_to");
  };

  rewardChange = () => {
    this.props.menuSettings("reward");
  };

  eventsChange = () => {
    this.props.menuSettings("events");
  };

  calendarChange = () => {
    this.props.menuSettings("calendar");
  };

  render() {
    const { menu } = this.props;
    return (
      <div className="menu-accordion">
        <Accordion className="important:shadow-none border-bottom rounded-none important:mb-0">
          <AccordionSummary
            expandIcon={<FiChevronDown size={22} className="black" />}
          >
            <div className="flex items-center">
              <div style={styles.chipOpacity} className="rounded-full p-2">
                <AiFillSetting size={20} />
              </div>
              <p className="ml-3">General settings</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className="important:p-0 w-full flex-col">
            {/* <div
              onClick={() =>
                this.menuSettingRef.current.menuSettingsPopupOpen()
              }
              className="shadow-sm p-2 rounded flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
            >
              <FiSettings size={18} style={{ color: colors.black }} />
              <p className="ml-3" style={styles.listText}>
                Menu Settings
              </p>
            </div> */}
            {/* <div
              onClick={() => this.menuSettingRef.current.celebrityPopupOpen()}
              className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
            >
              <RiUserStarLine size={18} style={{ color: colors.black }} />
              <p className="ml-3" style={styles.listText}>
                Celebrity
              </p>
            </div> */}
            <Popover.Button as="div">
              <Link
                to="/your_information"
                className="p-2.5 rounded-lg flex items-center hover:bg-gray-200 list-items"
              >
                <FaRegIdCard size={18} style={{ color: colors.black }} />
                <p className="ml-3" style={styles.listText}>
                  Access your information
                </p>
              </Link>
            </Popover.Button>
            {/* <Link to='/' className="shadow-sm p-2 rounded flex items-center mb-2 hover:bg-gray-200 list-items">
              <CgTrashEmpty size={18} style={{ color: colors.black }} />
              <p className="ml-3" style={styles.listText}>
                Delete Account
              </p>
            </Link> */}
            {/* <Link to='/' className="shadow-sm p-2 rounded flex items-center mb-2 hover:bg-gray-200 list-items">
              <RiLogoutCircleRFill size={18} style={{ color: colors.black }} />
              <p className="ml-3" style={styles.listText}>
                Logout & Reset App
              </p>
            </Link> */}
          </AccordionDetails>
        </Accordion>

        <SettingPopup
          ref={this.menuSettingRef}
          item={menu}
          groupsChange={this.groupsChange}
          savedChange={this.savedChange}
          commsChange={this.commsChange}
          flirtChange={this.flirtChange}
          barsChange={this.barsChange}
          restaurantChange={this.restaurantChange}
          companyChange={this.companyChange}
          billRightsChange={this.billRightsChange}
          feedbackChange={this.feedbackChange}
          how_toChange={this.how_toChange}
          rewardChange={this.rewardChange}
          eventsChange={this.eventsChange}
          calendarChange={this.calendarChange}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ User }) => {
  return {
    menu: typeof User.menu !== "undefined" ? User.menu : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/UserRedux");
  return {
    ...ownProps,
    ...stateProps,
    menuSettings: data => {
      dispatch(actions.menuSettings(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Menuaccordion);
