import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiPlus } from "react-icons/fi";
import saved_posts from "assets/images/saved_posts.webp";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Image, Button } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import { FormControlLabel, Radio } from "@material-ui/core";
import { connect } from "react-redux";
import { LifeWidget, Emitter } from "common";

class Savedcollectionpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: false,
      newCollection: false,
      save: {},
      title: "",
    };
    this.per_page = 10;
    this.page = 1;
  }

  componentDidMount() {}

  collectionPopupOpen = () => {
    this.props.fetchCollections(this.per_page, this.page);
    this.setState({ collection: true, newCollection: false });
  };

  collectionPopupClose = () => {
    this.setState({ collection: false });
  };

  saveCollection = async () => {
    const { item } = this.props;
    const { save } = this.state;
    const data = { post_id: item.id, save_id: save.id };
    this.collectionPopupClose();
    Emitter.emit("POST_COLLECTION_ADD", { message: "saved to " + save.title });
    const json = await LifeWidget.postSave(data);
  };

  submitCollection = () => {
    const { item } = this.props;
    const data = { post_id: item.id, title: this.state.title };
    this.props.submitCollection(data);
    Emitter.emit("POST_COLLECTION_ADD", {
      message: "saved to " + this.state.title,
    });
    this.collectionPopupClose();
  };

  render() {
    const { data } = this.props;
    const { save, title } = this.state;
    return (
      <Dialog
        open={this.state.collection}
        onClose={this.collectionPopupClose}
        scroll="body"
        className="custom-backdrop"
        fullWidth="sm">
        <div className="flex items-center justify-between px-3 py-3 border-b ">
          <p className="text-2xl Medium">Create Collection</p>
          <div
            onClick={this.collectionPopupClose}
            className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
            <FiX size={24} style={{ color: colors.darkGray }} />
          </div>
        </div>
        <DialogContent className="p-0">
          <div className="p-3">
            {data &&
              data.map((item, index) => (
                <div key={index}>
                  <div
                    onClick={() => this.setState({ save: item })}
                    className="flex items-center justify-between px-2 py-2 cursor-pointer hover:bg-gray-200 rounded-xl">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center mr-3">
                        <Image
                          src={saved_posts}
                          className="object-cover w-16 h-16 border rounded--xl"
                        />
                      </div>
                      <div>
                        <p className="text-xl">{item.title}</p>
                      </div>
                    </div>
                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          checked={save.id === item.id ? true : false}
                        />
                      }
                    />
                  </div>
                  <div className="my-1 divider" />
                </div>
              ))}

            {this.state.newCollection === false ? (
              <>
                <div
                  onClick={() => this.setState({ newCollection: true })}
                  className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-200 rounded-xl">
                  <div className="flex items-center justify-center w-12 h-12 mr-3 bg-gray-100 border rounded-xl">
                    <FiPlus size={22} color={colors.darkGray} />
                  </div>
                  <p>New Collection</p>
                </div>
                <div className="mt-3 divider" />
              </>
            ) : null}
            {this.state.newCollection === true ? (
              <>
                <p className="m-1">Name</p>
                <div className="flex flex-row items-center px-3 bg-gray-100 border rounded-xl h-11">
                  <input
                    placeholder="Give your collection a name..."
                    className="flex-grow bg-transparent border-0 h-11 Regular"
                    onChange={(e) => this.setState({ title: e.target.value })}
                  />
                </div>
              </>
            ) : null}
          </div>
        </DialogContent>
        {this.state.newCollection === false ? (
          <DialogActions className="p-3">
            <Button
              onClick={this.saveCollection}
              disabled={save.id ? false : true}
              style={{ color: colors.white }}
              className="h-10 px-10 transition duration-300 transform border-0 shadow-none bg--primary rounded--lg hover:bg--primary focus:bg--primary black hover:scale-90">
              Done
            </Button>
          </DialogActions>
        ) : null}
        {this.state.newCollection === true ? (
          <DialogActions className="p-3">
            <Button
              onClick={this.collectionPopupClose}
              style={{ color: colors.primary }}
              className="px-10 bg-transparent border-0 shadow-none rounded--lg ">
              Cancel
            </Button>
            <Button
              disabled={title ? false : true}
              onClick={this.submitCollection}
              style={{ color: colors.white }}
              className="h-10 px-10 transition duration-300 transform border-0 shadow-none bg--primary rounded--lg hover:bg--primary focus:bg--primary black hover:scale-90">
              Create
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }
}

const mapStateToProps = ({ Collection }) => {
  return {
    data: Collection.data,
    isFetching: Collection.isFetching,
    isProcessing: Collection.isProcessing,
    total: Collection.total,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CollectionRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchCollections: (per_page, page) => {
      actions.fetchCollections(dispatch, per_page, page);
    },
    submitCollection: (data) => {
      actions.submitCollection(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Savedcollectionpopup);
