import React, { Component } from "react";
import "./styles.css";
import colors from "config/color/color.js";
import { MdBusinessCenter } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { Dropdown, Select } from "semantic-ui-react";
import { Form, Button } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import { Selectprivacypopup } from "components";
class Working extends Component {
  constructor(props) {
    super(props);
    this.state = { editWorkplace: false };
    this.selectPrivacyRef = React.createRef();
  }

  render() {
    return (
      <div>
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <div className="mr-3 ">
              <MdBusinessCenter size={22} className="darkGray" />
            </div>
            <p className="darkGray">
              Working at <p className="black hover:underline"> Weeee</p>
            </p>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => this.selectPrivacyRef.current.selectPriavcyOpen()}
              className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer hover:bg-gray-200">
              <GiEarthAsiaOceania
                size={20}
                style={{ color: colors.darkGray }}
              />
            </div>

            <Dropdown
              icon={false}
              wrapSelection={true}
              direction="left"
              pointing="top"
              text={
                <div>
                  <div className="flex items-center justify-center w-10 h-10 ml-3 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300">
                    <BsThreeDots size={18} />
                  </div>
                </div>
              }>
              <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-80">
                <div className="p-2">
                  <div
                    onClick={() => this.setState({ editWorkplace: true })}
                    className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-200">
                    <div className="mr-3">
                      <FiEdit2 size={18} />
                    </div>
                    <p>Edit Workplace</p>
                  </div>
                  <div
                    onClick={() => this.props.deleteWorkplacesPopupOpen()}
                    className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-200">
                    <div className="mr-3">
                      <IoTrashOutline size={18} />
                    </div>
                    <p>Delete Workpalce</p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {this.state.editWorkplace ? (
          <div className="mt-4">
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Company"
                className="h-16 border shadow-none rounded--lg"
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Position"
                className="h-16 border shadow-none rounded--lg"
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Description"
                className="border shadow-none resize-none rounded--lg h-28 Light darkGray"
                as="textarea"
              />
            </div>
            <p className="Medium">Time Period</p>
            <div className="flex items-center">
              <Checkbox checked="checked" color="primary" />
              <p className="Medium">I currently work here</p>
            </div>
            <div className="flex items-center">
              <p>From</p>
              <div className="w-32 ml-3">
                <Select
                  className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                  placeholder="Year"
                  options={[
                    { value: "21", text: "2021" },
                    { value: "20", text: "2020" },
                    { value: "19", text: "2019" },
                    { value: "18", text: "2018" },
                    { value: "17", text: "2017" },
                    { value: "16", text: "2016" },
                    { value: "15", text: "2015" },
                    { value: "14", text: "2014" },
                    { value: "13", text: "2013" },
                  ]}
                />
              </div>
              <div className="w-32 ml-3">
                <Select
                  className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                  placeholder="Year"
                  options={[
                    { value: "21", text: "2021" },
                    { value: "20", text: "2020" },
                    { value: "19", text: "2019" },
                    { value: "18", text: "2018" },
                    { value: "17", text: "2017" },
                    { value: "16", text: "2016" },
                    { value: "15", text: "2015" },
                    { value: "14", text: "2014" },
                    { value: "13", text: "2013" },
                  ]}
                />
              </div>
              <div className="w-32 ml-3">
                <Select
                  className="w-full border-0 important:rounded--lg important:bg--lightGray select:full-width important:min-w--full"
                  placeholder="Year"
                  options={[
                    { value: "21", text: "2021" },
                    { value: "20", text: "2020" },
                    { value: "19", text: "2019" },
                    { value: "18", text: "2018" },
                    { value: "17", text: "2017" },
                    { value: "16", text: "2016" },
                    { value: "15", text: "2015" },
                    { value: "14", text: "2014" },
                    { value: "13", text: "2013" },
                  ]}
                />
              </div>
            </div>
            <div className="my-3 divider" />
            <div className="flex items-center justify-between">
              <Button
                onClick={() =>
                  this.selectPrivacyRef.current.selectPriavcyOpen()
                }
                style={{ color: colors.black }}
                className="flex items-center px-4 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray important:rounded--lg hover:scale-90">
                <GiEarthAsiaOceania
                  size={14}
                  className="mr-2"
                  style={{ color: colors.darkGray }}
                />
                Public
              </Button>
              <div className="flex items-center ">
                <Button
                  onClick={() => this.setState({ editWorkplace: false })}
                  style={{ color: colors.black }}
                  className="flex items-center px-4 mr-3 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray important:rounded--lg hover:scale-90">
                  Cancel
                </Button>
                <Button
                  style={{ color: colors.white }}
                  className="flex items-center px-4 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary important:rounded--lg hover:scale-90">
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        <Selectprivacypopup ref={this.selectPrivacyRef} {...this.props} />
      </div>
    );
  }
}

export default Working;
