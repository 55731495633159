import * as React from "react";
import unlink from "assets/images/vectors/unlink.svg";
import { Image } from "react-bootstrap";
import { Component } from "react";
import "./styles.css";
import { Link } from "react-router-dom";

class Notfoundpage extends Component {
  render() {
    return (
      <>
        <div className="fixed flex flex-col items-center justify-center w-full p-4 bg-white shadow-md notFoundPage FriendsLeftAside max-w-none">
          <div className="flex flex-col items-center w-full -mt-20 container--small xs:px-4 sm:px-4 xl:px-20 lg:px-5 md:px-5">
            <Image src={unlink} className="w-60" />
            <p className="text-5xl Medium">This Page Isn't Available</p>
            <p className="my-3 text-xl text-center darkGray xs:px-4 sm:px-4 xl:px-20 lg:px-5 md:px-5">
              The link may be broken, or the page may have been removed. Check
              to see if the link you're trying to open is correct.
            </p>
            <Link
              to="/"
              className="flex items-center h-12 px-5 border-0 shadow-none bg--primary white hover:color-white hover:opacity-80 rounded--xl">
              Go to News Feed
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default Notfoundpage;
