import React, { Component } from "react";
import { Image } from "react-bootstrap";
import styles from "./styles.js";
import avatar from "assets/images/avatar.webp";
import { Link } from "react-router-dom";
import { Capitalize, OptimizeImage } from "helpers";
import { connect } from "react-redux";

class UserProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;
    return (
      <Link
        to={`/${user.user_name}`}
        className="flex items-center hover:bg-gray-200 rounded-lg p-2"
      >
        <Image
          className="h-14 w-14 rounded-full object-cover mr-3"
          src={user.profile_photo ? OptimizeImage(user.profile_photo) : avatar}
        />
        <p style={styles.userName} className="Regular black">
          {Capitalize(user.first_name)} {Capitalize(user.last_name)}
        </p>
      </Link>
    );
  }
}
const mapStateToProps = ({ User }) => ({ user: User.user });
const mapDispatchToProps = dispatch => {
  const { actions } = require("redux/UserRedux");

  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileComponent);
