import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { FriendList } from "components";

class FriendListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { title } = this.props;
    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={this.close}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            <div className="flex items-center justify-center pb-3 border-b">
              <div
                onClick={this.close}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-5 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
              <p className="text-2xl Medium">{title ?? "Delete Comment"}</p>
              <p
                onClick={this.close}
                className="absolute z-10 flex items-center justify-center w-20 h-10 cursor-pointer right-3 hover:bg-gray-100 rounded-xl primary">
                Done
              </p>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div>
              <FriendList {...this.props} />
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default FriendListPopup;
