import React, { Component } from "react";
import winner from "assets/images/winner.webp";
import avatar from "assets/images/avatar.webp";
import { Image } from "react-bootstrap";

class Rewardwinner extends Component {
  render() {
    return (
      <div className="bg-white shadow-sm rounded-xl">
        <div className="relative h-52">
          <Image
            src={winner}
            className="absolute top-0 object-cover w-full h-full rounded-t-xl"
          />
          <div className="relative flex flex-col justify-center h-full">
            <div>
              <div className="w-16 h-16 m-auto border-white rounded-full border-3">
                <Image
                  src={avatar}
                  className="object-cover w-full h-full m-auto rounded-full"
                />
              </div>
              <h2 className="mt-2 text-center">Monimoy Ghosh</h2>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray-200">
          <h4 className="text-center">$1000 Winner for May 2021</h4>
        </div>
        <div className="p-3">
          <div className="mt-2">
            <div className="px-4">
              <p className="mb-2">
                <span className="Medium">Congratulations</span> to Monimoy Ghosh
                who won $1000 in May with 344 new friends joined.
              </p>
              <p className="mb-2">
                I am pleased to announce we will run a similar contest for the
                month of June.
              </p>
              <p className="mb-2">
                This month we will have two $1000 winners, as we will award the
                prize to one US citizen and a duplicate prize to an
                international citizen.
              </p>
              <p className="mb-2">
                The rules are simple, invite as many people as you can, when
                they join, have them create a profile with a picture and then
                add you as a friend.
              </p>
              <p className="mb-2">
                The first person they add as a friend will be awarded one point.
                This contest will end on June 30th at midnight. Good luck and
                welcome to Weeee!
              </p>
              <p className="mb-2">
                The first person they add as a friend will be awarded one point.
                This contest will end on June 30th at midnight. Good luck and
                welcome to Weeee!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rewardwinner;
