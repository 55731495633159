import React from "react";
import Compose from "../Compose";
import "./styles.css";
import { AiOutlineCamera } from "react-icons/ai";
import { BsFillMicFill, BsImageFill } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import { connect } from "react-redux";
import { RecyclerListView, DataProvider } from "recyclerlistview/web";
import { Loader } from "semantic-ui-react";
import ChatService from "../../../chat/services/chat-service";
import Item from "../../../chat/Message/Item";
import { ChatLayoutUtil } from "../../../chat/helpers/LayoutUtil";
import CallService from "../../../chat/services/call-service";

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlredy: true,
      dataProvider: new DataProvider((r1, r2) => {
        return r1 !== r2 || r1.send_state !== r2.send_state;
      }),
      layoutProvider: [],
      isOpen: false,
    };
  }
  componentDidUpdate(prewProps) {
    const dialog = ChatService.getDialogById(this.props.selectedDialog.id);

    if (
      prewProps.messages[dialog.id] &&
      prewProps.messages[dialog.id] !== this.props.messages[dialog.id]
    ) {
      if (this.props.messages[dialog.id].length) {
        this.setState(
          {
            dataProvider: this.state.dataProvider.cloneWithRows(
              this.props.messages[dialog.id]
            ),
          },
          () => {
            //this.updateScrollPosition()
          }
        );
      }
    }
  }

  open = () => {
    this.setState({ isOpen: true }, this.getDialogInfo);
  };

  getDialogInfo = async () => {
    window.addEventListener("resize", this.handleResize);
    this.scrollWidth = document.getElementById("chat-body").clientWidth;
    this.scrollHeight = document.getElementById("chat-body").clientHeight;
    const dialog = ChatService.getDialogById(this.props.selectedDialog.id);

    // get info about occupants
    //await UsersService.getOccupants(dialog.occupants_ids)

    ChatService.getMessages(dialog)
      .catch((e) => console.log(`Error.\n\n${JSON.stringify(e)}`))
      .then((amountMessages) => {
        amountMessages === 100
          ? (this.needToGetMoreMessage = true)
          : (this.needToGetMoreMessage = false);
        this.setState({
          isFetchingMsg: true,
          layoutProvider: ChatLayoutUtil.getChatLayoutProvider({
            width: this.scrollWidth,
            dialogId: dialog.id,
            currentUserId: this.props.user.cube_user_id,
          }),
          dataProvider: this.state.dataProvider.cloneWithRows(
            this.props.messages[dialog.id]
          ),
        });
        //this.scrollToBottom()
        this.listenerLazyLoad = true;
      });
  };

  lazyLoadMessages = (elem, y) => {
    this.recycler_Y = y;
    this.contentHeight = elem.nativeEvent.contentSize.height;
    if (this.listenerLazyLoad && this.needToGetMoreMessage && y < 2000) {
      this.listenerLazyLoad = false;
      ChatService.getMoreMessages(this.props.selectedDialog).then(
        (amountMessages) => {
          amountMessages === 100
            ? (this.needToGetMoreMessage = true)
            : (this.needToGetMoreMessage = false);
          this.listenerLazyLoad = true;
        }
      );
    }
  };

  sendMessageCallback = async (messageText, img) => {
    const dialog = ChatService.getDialogById(this.props.selectedDialog.id);
    if (messageText.length <= 0 && !img) return;
    await ChatService.sendMessage(
      dialog,
      messageText,
      img,
      this.scrollToBottom
    );
  };

  _renderMessage = (type, item) => {
    const { users } = this.props;

    return <Item item={item} user_id={this.props.user.cube_user_id} />;
  };

  startCall = () => {
    const { selectedDialog, user } = this.props;
    const occupants_id = selectedDialog.occupants_ids.find(
      (elem) => elem != user.cube_user_id
    );
    let data = { id: occupants_id, name: user.first_name };
    CallService.startCall(data);
  };

  render() {
    const { isAlredy, isFetchingMsg, dataProvider, layoutProvider, isOpen } =
      this.state;
    const { selectedDialog, user } = this.props;
    return (
      <div>
        {selectedDialog && selectedDialog.id && (
          <div className="flex items-center justify-between h-12 px-4 bg-gray-200">
            <div />
            <h4 className="font-medium Medium">{selectedDialog.name}</h4>

            <div className="flex items-center justify-between">
              {selectedDialog.type !== 2 && (
                <div
                  onClick={this.startCall}
                  className="flex items-center justify-center rounded-full cursor-pointer h-9 w-9">
                  <IoIosCall className="primary" size={20} />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="message-list-container">
          <div
            className="chat-body"
            id="chat-body"
            style={{ width: "100%", height: "76vh" }}>
            {isAlredy && isFetchingMsg ? (
              dataProvider._data.length > 0 && (
                <>
                  <RecyclerListView
                    ref={(ref) => (this.messagesListRef = ref)}
                    dataProvider={dataProvider}
                    layoutProvider={layoutProvider}
                    rowRenderer={this._renderMessage}
                    onScroll={(elem, x, y) => {
                      this.lazyLoadMessages(elem, y);
                    }}
                  />
                </>
              )
            ) : (
              <Loader />
            )}
          </div>
        </div>
        {isOpen && (
          <Compose
            sendMessageCallback={this.sendMessageCallback}
            rightItems={[
              <div className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-11 w-11">
                <AiOutlineCamera className="primary" size={20} />
              </div>,
              <div className="flex items-center justify-center mx-2 bg-gray-200 rounded-full cursor-pointer h-11 w-11">
                <BsImageFill className="primary" size={20} />
              </div>,
              <div className="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-11 w-11">
                <BsFillMicFill className="primary" size={20} />
              </div>,
            ]}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ selectedDialog, messages, User }) => ({
  selectedDialog,
  messages,
  user: User.user,
});

export default connect(mapStateToProps, undefined, undefined, {
  forwardRef: true,
})(MessageList);
