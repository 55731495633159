import { LifeWidget } from "common";

const types = {
  SUBMIT_BAND_PENDING: "SUBMIT_BAND_PENDING",
  SUBMIT_BAND_FAILURE: "SUBMIT_BAND_FAILURE",
  SUBMIT_BAND_SUCCESS: "SUBMIT_BAND_SUCCESS",

  FETCH_BAND_PENDING: "FETCH_BAND_PENDING",
  FETCH_BAND_FAILURE: "FETCH_BAND_FAILURE",
  FETCH_BAND_SUCCESS: "FETCH_BAND_SUCCESS",
  FETCH_BAND_MORE: "FETCH_BAND_MORE",

  FETCH_SINGLE_BAND_SUCCESS: "FETCH_SINGLE_BAND_SUCCESS",

  ADD_BAND_FORM: "ADD_BAND_FORM",

  DELETE_BAND_SUCCESS: "DELETE_BAND_SUCCESS",
};

const initialState = {
  data: {},
  dataIds: [],
  total: 0,
  is_fetching: false,
  is_processing: false,
  is_more: false,
  form: {},
};

export const actions = {
  fetchBands: async (dispatch, per_page, page, params = []) => {
    dispatch({ type: types.FETCH_BAND_PENDING });
    const json = await LifeWidget.fetchBands(per_page, page, params);

    if (json === undefined) {
      dispatch({
        type: types.FETCH_BAND_FAILURE,
        message: "Can't get data from server",
      });
    } else if (200 !== json.status) {
      console.log("json status is not true");
      dispatch({ type: types.FETCH_BAND_FAILURE, message: json.data.message });
    } else {
      if (page > 1) {
        dispatch({ type: types.FETCH_BAND_MORE, data: json.data });
      } else {
        dispatch({ type: types.FETCH_BAND_SUCCESS, data: json.data });
      }
    }
  },
  fetchBand: async (dispatch, id) => {
    const json = await LifeWidget.fetchBand(id);
    if (json === undefined) {
    } else if (200 !== json.status) {
    } else {
      dispatch({
        type: types.FETCH_SINGLE_BAND_SUCCESS,
        data: json.data,
        id: id,
      });
    }
  },
  submitBand: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_BAND_PENDING });
    const json = await LifeWidget.submitBand(data);
    if (json === undefined) {
      dispatch({ type: types.SUBMIT_BAND_FAILURE });
    } else if (json.status === 200) {
      dispatch({ type: types.SUBMIT_BAND_SUCCESS, data: json.data });
      return json;
    } else {
      dispatch({ type: types.SUBMIT_BAND_FAILURE });
    }
  },
  deleteBand: async (dispatch, id) => {
    dispatch({ type: types.DELETE_BAND_SUCCESS, id });
    const json = await LifeWidget.deleteBand(id);
  },
  addBandForm: data => ({
    type: types.ADD_BAND_FORM,
    data,
  }),
};

export const reducer = (state = initialState, action) => {
  const { type, message, data, id } = action;
  switch (type) {
    case types.ADD_BAND_FORM: {
      return {
        ...state,
        form: data,
      };
    }
    case types.FETCH_BAND_PENDING: {
      return {
        ...state,
        is_fetching: true,
        error: false,
        message: "",
      };
    }
    case types.FETCH_BAND_FAILURE: {
      return {
        ...state,
        is_fetching: false,
        error: true,
        message: message,
      };
    }
    case types.FETCH_BAND_SUCCESS: {
      return {
        ...state,
        is_fetching: false,
        data: data.data,
        dataIds: Object.keys(data.data).reverse(),
        is_more: data.is_more,
        total: data.total,
      };
    }
    case types.FETCH_BAND_MORE: {
      return {
        ...state,
        is_fetching: false,
        data: { ...state.data, ...data.data },
        dataIds: [...state.dataIds, ...Object.keys(data.data)],
        is_more: data.is_more,
        total: data.total,
      };
    }

    case types.FETCH_SINGLE_BAND_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [id.toString()]: data,
        },
        dataIds: state.dataIds.some(item => id == item)
          ? state.dataIds
          : [...state.dataIds, id],
      };
    }

    case types.SUBMIT_BAND_PENDING: {
      return {
        ...state,
        is_processing: true,
      };
    }

    case types.SUBMIT_BAND_FAILURE: {
      return {
        ...state,
        is_processing: false,
      };
    }

    case types.SUBMIT_BAND_SUCCESS: {
      return {
        ...state,
        is_processing: false,
        form: {},
        dataIds: state.dataIds.some(item => data.id == item)
          ? state.dataIds
          : [data.id, ...state.dataIds],
        data: {
          ...state.data,
          [data.id]: data,
        },
      };
    }

    case types.DELETE_BAND_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {},
        },
      };
    }

    default: {
      return state;
    }
  }
};
