import React, { useState } from "react";
import { AddSubCategory, Category, Enabled } from "Modules";
import { FiPlus } from "react-icons/fi";
import { Buttonused } from "components";
import { Checkbox } from "@material-ui/core";

const options = [
  { name: "Vodka", id: 1 },
  { name: "Tequila", id: 2 },
  { name: "Whiskey", id: 3 },
  { name: "Rum", id: 4 },
  { name: "Gin", id: 5 },
  { name: "Brandy", id: 6 },
];

export default function CustomDrink() {
  const [addForm, setAddForm] = useState(false);
  const [checked, setChecked] = useState(true);

  const handleChange = event => {
    setChecked(event.target.checked);
  };
  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <Category title="Custom Drinks" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="m-auto w-full space-y-5">
          <div className="overflow-y-auto" id="scrollbar">
            <Enabled />
            <div className={`${!checked && "blur-xs"}`}>
              <div className="flex items-center justify-between mx-4">
                <Checkbox
                  onChange={handleChange}
                  checked={checked}
                  color="primary"
                />
                <h2> Vodka</h2>
                <div className="w-96 ml-auto flex items-center gap-x-3">
                  {addForm ? (
                    <Buttonused
                      Icon={!addForm && <FiPlus />}
                      onClick={() => {
                        setAddForm(!addForm);
                      }}
                      Text="Save"
                      className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-40 ml-auto rounded--lg"
                    />
                  ) : (
                    <AddSubCategory options={options} />
                  )}
                </div>
              </div>
              {addForm && (
                <form className="space-y-4 mt-5 mx-4">
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-2 ...">
                      <input
                        type="text"
                        placeholder="Add Custom Category Name"
                        className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Single Price"
                        className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Double Price"
                        className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Shot Price"
                        className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Bottle 350 ML Price"
                        className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Bottle 750 ML Price"
                        className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                      />
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-gray-300 rounded-full" />
                </form>
              )}

              <div className="bg-white shadow-lg overflow-hidden m-4 rounded-lg">
                <table
                  className={`${!checked && "blur-xs"
                    } min-w-max w-full table-auto`}
                >
                  <thead>
                    <tr className="bg-gray-200 text-black uppercase text-sm leading-normal">
                      <th className="py-4 px-6 capitalize text-[12px] text-left">
                        Type
                      </th>
                      <th className="py-4 px-6 capitalize text-[12px] text-center">
                        Single
                      </th>
                      <th className="py-4 px-6 capitalize text-[12px] text-center">
                        Double
                      </th>
                      <th className="py-4 px-6 capitalize text-[12px] text-center">
                        Shot
                      </th>
                      <th className="py-4 px-6 capitalize text-[12px] text-center">
                        Bottle 350 ML
                      </th>
                      <th className="py-4 px-6 capitalize text-[12px] text-center">
                        Bottle 750 ML
                      </th>
                      <th className="py-4 px-6 capitalize text-[12px] text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <div className="flex items-center">
                          <p className="black Medium">Tito’s</p>
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex item-center justify-center">
                          <div
                            onClick={() => {
                              setAddForm(true);
                            }}
                            className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                              />
                            </svg>
                          </div>
                          <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <div className="flex items-center">
                          <p className="black Medium">Tito’s</p>
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex items-center justify-center">
                          <p className="text-gray-500 Light">$9</p>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-center">
                        <div className="flex item-center justify-center">
                          <div
                            onClick={() => {
                              setAddForm(true);
                            }}
                            className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                              />
                            </svg>
                          </div>
                          <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {!addForm && (
                <div className="w-52 mx-auto mt-5">
                  <Buttonused
                    Icon={<FiPlus />}
                    onClick={() => {
                      setAddForm(!addForm);
                    }}
                    Text="Add more"
                    className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
