import React, { Component } from "react";

class OnlineStatusXS extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {/* <span className="flex h-2 w-2 relative ml-auto -mt-4 mr-2.5 ">
          <span className="absolute right-0 inline-flex w-full h-full rounded-full opacity-75 animate-ping bg--success"></span>
          <span className="relative inline-flex w-2 h-2 rounded-full bg--success"></span>
        </span> */}

        <span className="absolute bottom-0 inline-block w-4 h-4 bg-green-600 border-2 border-white rounded-full right-1"></span>
      </>
    );
  }
}

export default OnlineStatusXS;
