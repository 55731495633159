import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { HiOutlineArrowLeft } from "react-icons/hi";
import colors from "config/color/color.js";
import { Buttonused } from "components";
import { IoLogoTableau, IoWineSharp } from "react-icons/io5";
import { AiFillLayout, AiFillSetting, AiOutlineSchedule } from "react-icons/ai";
import { MdOutlineDoNotDisturbOnTotalSilence } from "react-icons/md";
import { BsCurrencyDollar, BsImages, BsReverseLayoutSidebarInsetReverse, BsViewList } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router-dom";
import { FcEditImage } from "react-icons/fc";
import { RiVipLine } from "react-icons/ri";
import { GiRoundTable } from "react-icons/gi";
import { CgLock } from "react-icons/cg";

const { actions } = require("redux/BarEnhancement");

export default function OrdersAside(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    actions.fetchBarSetting(dispatch, { bar_id: id });
  }, []);

  return (
    <div className="fixed flex flex-col p-5 overflow-hidden bg-white shadow-md FriendsLeftAside">
      <div className="flex-grow">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Buttonused
              to="/my_companies"
              Icon={<HiOutlineArrowLeft size={18} className="darkGray" />}
              className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-20"
            />
            <div>
              <div className="flex items-center darkGray fs-13 gap-x-1">
                <Link
                  to="/my_companies"
                  className="text-sm primary hover:underline ">
                  Bars
                </Link>
                <FiChevronRight size={13} />
                {props.title}
              </div>
              <h2 className="text-2xl">{props.title}</h2>
            </div>
          </div>
          {/* <Tooltip
            arrow={true}
            title={checked ? "Disable this feature" : "Enable this feature"}
          >
            <Checkbox
              onChange={handleChange}
              checked={checked}
              color="primary"
            />
          </Tooltip> */}
        </div>
      </div>
      <div className="my-3 divider" />
      <div
        className={`${checked ? "blur-xs" : null
          } aside-2 overflow-auto mb-2 -mr-4 pr-2`}
        id="scrollbar">
        <div className="mb-2">
          <>
            <NavLink
              exact
              to={`/bar/${id}/logo`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <BsImages size={20} style={{ color: colors.white }} />
                </div>
                Logo & Banner
              </div>
            </NavLink>

            <NavLink
              exact
              to={`/bar/${id}/schedule`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <AiOutlineSchedule
                    size={20}
                    style={{ color: colors.white }}
                  />
                </div>
                Schedule
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/layout`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <AiFillLayout size={20} style={{ color: colors.white }} />
                </div>
                Bar Layout
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/table`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <GiRoundTable size={20} style={{ color: colors.white }} />
                </div>
                VIP Table
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <IoWineSharp size={20} style={{ color: colors.white }} />
                </div>
                Online Ordering
              </div>
              <FiChevronRight
                size={24}
                style={{ color: colors.darkGray }}
                className="svg"
              />
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/settings`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <AiFillSetting size={20} style={{ color: colors.white }} />
                </div>
                Settings
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/tools`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <CgLock size={20} style={{ color: colors.white }} />
                </div>
                Admin tool
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/reports/tickets`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <BsViewList size={20} style={{ color: colors.white }} />
                </div>
                Tickets
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/reports/orders`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <BsViewList size={20} style={{ color: colors.white }} />
                </div>
                Orders
              </div>
            </NavLink>
            <NavLink
              exact
              to={`/bar/${id}/total`}
              className="flex items-center justify-between p-2 mt-3 bg-gray-100 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                  <BsCurrencyDollar size={20} style={{ color: colors.white }} />
                </div>
                Nightly total
              </div>
            </NavLink>
          </>
        </div>
      </div>
    </div>
  );
}
