import React, { Component } from "react";
import { NavDropdown, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import avatar from "assets/images/avatar.webp";
import { FiBookmark, FiXSquare, FiBell } from "react-icons/fi";
import { VscCloseAll, VscReport } from "react-icons/vsc";
import { BsThreeDots, BsDot, BsClock } from "react-icons/bs";
import { IoEarthSharp, IoCodeSlashOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { Grouppostfooter } from "components";

class GroupPost extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-3 pb-0 bg-white rounded-lg shadow-sm post">
        <div className="flex items-center">
          <Image
            src={avatar}
            className="object-cover mr-2 border h-14 w-14 rounded-xl"
          />
          <div className="flex-grow">
            <div className="flex items-center">
              <div className="flex-grow">
                <p className="gray Light ">
                  <p className="mr-1 Medium black">Eat Sleep Repeat -ESR-</p>
                </p>
                <div className="flex items-center">
                  <p className="darkGray fs-14">Komali Haris</p>
                  <BsDot style={{ color: colors.gray }} />
                  <p className="text-sm gray Light">2 days ago</p>
                  <BsDot style={{ color: colors.gray }} />
                  <FaUsers style={{ color: colors.gray }} />
                </div>
              </div>
              <div>
                <NavDropdown
                  title={
                    <div className="flex items-center justify-center w-8 h-8 m-auto rounded-full">
                      <BsThreeDots size={20} style={{ color: colors.black }} />
                    </div>
                  }
                  className="custom-dropdown right">
                  <div className="absolute right-0 z-10 w-screen max-w-sm mt-2 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative p-2 bg-white">
                        <div>
                          <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                            <FiBookmark size={20} className="mt-1 mr-2 " />
                            <div>
                              <p>Save post</p>
                              <p className="text-sm Light darkGray">
                                add this to your save items.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="my-1 divider" />
                        <div className="flex items-center px-2 py-2 rounded hover:bg-gray-100">
                          <FiBell size={20} className="mt-1 mr-2 " />
                          <p>Turn on notifications for this post</p>
                        </div>
                        <div className="flex items-center px-2 py-2 rounded hover:bg-gray-100">
                          <IoCodeSlashOutline
                            size={20}
                            className="mt-1 mr-2 "
                          />
                          <p>Embed</p>
                        </div>
                        <div className="my-1 divider" />
                        <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                          <FiXSquare size={20} className="mt-1 mr-2 " />
                          <div>
                            <p>Hide post</p>
                            <p className="text-sm Light darkGray">
                              see fewer posts like this.
                            </p>
                          </div>
                        </div>
                        <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                          <BsClock size={20} className="mt-1 mr-2 " />
                          <div>
                            <p>Snooze John Doe for 30 days</p>
                            <p className="text-sm Light darkGray">
                              Temporarily stop seeing posts.
                            </p>
                          </div>
                        </div>
                        <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                          <VscCloseAll size={20} className="mt-1 mr-2 " />
                          <div>
                            <p>Unfollow John Doe</p>
                            <p className="text-sm Light darkGray">
                              Stop seeing posts from this Page.
                            </p>
                          </div>
                        </div>
                        <div className="flex px-2 py-2 rounded hover:bg-gray-100">
                          <VscReport size={20} className="mt-1 mr-2 " />
                          <div>
                            <p>Find support or report post</p>
                            <p className="text-sm Light darkGray">
                              I'm concerned about this post.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavDropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p>
            Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
          </p>
          <div className="mt-3 border rounded-xl">
            <div className="mb-3 -mx-4">
              <Image src={avatar} className="object-cover" />
            </div>
            <div className="p-3">
              <div className="flex items-center">
                <Image
                  src={avatar}
                  className="object-cover mr-2 border h-14 w-14 rounded-xl"
                />
                <div className="flex-grow">
                  <div className="flex items-center">
                    <div className="flex-grow">
                      <p className="gray Light ">
                        <p className="mr-1  black">Gloria Cup Cafe</p>
                      </p>
                      <div className="flex items-center">
                        <p className="text-sm gray Light">
                          July 20, 2021 at 09:15pm
                        </p>
                        <BsDot style={{ color: colors.gray }} />
                        <IoEarthSharp style={{ color: colors.gray }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="mt-3">
                <p>
                  Curabitur non nulla sit amet nisl tempus convallis quis ac
                  Curabitur non nul la sit amet nisl tempus convallis quis ac
                  lectus.
                </p>
                <p>
                  Curabitur non nulla sit amet nisl tempus convallis quis ac
                  lectus.
                </p>
              </div>
            </div>
          </div>
          <Grouppostfooter />
        </div>
      </div>
    );
  }
}

export default GroupPost;
