import React, { Component } from "react";
import "./styles.css";
import { Image, Card } from "react-bootstrap";
import avatar from "assets/images/avatar.webp";
import bar from "assets/images/bar.webp";
import { Link } from "react-router-dom";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { HiArrowRight } from "react-icons/hi";

class Stories extends Component {
  render() {
    return (
      <div className="relative grid grid-cols-5 gap-2 d-none d-sm-grid stories">
        <Link
          to="/"
          className="overflow-hidden bg-white shadow-sm rounded--xl h-60 hover:bg--gray-200 ">
          <Card className="items-center text-white border-0 ">
            <Card.Img
              src={avatar}
              alt="Card image"
              className="object-cover h-40 overlay:bg--gray-200 "
            />
            <Card.ImgOverlay className="flex flex-col justify-between h-40 p-2 overlay:bg--gray-200 rounded-0 "></Card.ImgOverlay>
            <div className="absolute right-auto z-10 -mt-5 bg-white border-4 border-white rounded-full top-100">
              <BsFillPlusCircleFill className="primary" size={30} />
            </div>
          </Card>
          <p className="p-1 px-5 mt-4 text-center black fs-13">Create Story</p>
        </Link>
        <Link
          to="/"
          className="overflow-hidden shadow-sm rounded--xl h-60 hover:opacity-80">
          <Card className="text-white border-0 ">
            <Card.Img
              src={bar}
              alt="Card image"
              className="object-cover h-60"
            />
            <Card.ImgOverlay className="flex flex-col justify-between p-2 overlay:bg--gray-200 rounded--xl ">
              <div className="flex items-center justify-center rounded-full h-11 w-11 bg--primary">
                <span className="relative flex w-10 h-10">
                  <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg--primary"></span>

                  <Image
                    src={avatar}
                    alt="Card image"
                    className="object-cover w-10 h-10 rounded-full "
                  />
                </span>
              </div>
              <p className="p-1 white">Davied Worn Trump</p>
            </Card.ImgOverlay>
          </Card>
        </Link>
        <Link
          to="/"
          className="overflow-hidden bg-white shadow-sm rounded--xl h-60 hover:opacity-80">
          <Card className="text-white border-0 ">
            <Card.Img
              src={bar}
              alt="Card image"
              className="object-cover h-60"
            />
            <Card.ImgOverlay className="flex flex-col justify-between p-2 overlay:bg--gray-200 rounded--xl ">
              <div className="flex items-center justify-center rounded-full h-11 w-11 bg--primary">
                <span className="relative flex w-10 h-10">
                  <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg--primary"></span>

                  <Image
                    src={avatar}
                    alt="Card image"
                    className="object-cover w-10 h-10 rounded-full "
                  />
                </span>
              </div>
              <p className="p-1 white">Davied Worn Trump</p>
            </Card.ImgOverlay>
          </Card>
        </Link>{" "}
        <Link
          to="/"
          className="overflow-hidden bg-white shadow-sm rounded--xl h-60 hover:opacity-80">
          <Card className="text-white border-0 ">
            <Card.Img
              src={bar}
              alt="Card image"
              className="object-cover h-60"
            />
            <Card.ImgOverlay className="flex flex-col justify-between p-2 overlay:bg--gray-200 rounded--xl ">
              <div className="flex items-center justify-center rounded-full h-11 w-11 bg--primary">
                <span className="relative flex w-10 h-10">
                  <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg--primary"></span>

                  <Image
                    src={avatar}
                    alt="Card image"
                    className="object-cover w-10 h-10 rounded-full "
                  />
                </span>
              </div>
              <p className="p-1 white">Davied Worn Trump</p>
            </Card.ImgOverlay>
          </Card>
        </Link>{" "}
        <Link
          to="/"
          className="overflow-hidden bg-white shadow-sm rounded--xl h-60 hover:opacity-80">
          <Card className="text-white border-0 ">
            <Card.Img
              src={bar}
              alt="Card image"
              className="object-cover h-60"
            />
            <Card.ImgOverlay className="flex flex-col justify-between p-2 overlay:bg--gray-200 rounded--xl ">
              <div className="flex items-center justify-center rounded-full h-11 w-11 bg--primary">
                <span className="relative flex w-10 h-10">
                  <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg--primary"></span>

                  <Image
                    src={avatar}
                    alt="Card image"
                    className="object-cover w-10 h-10 rounded-full "
                  />
                </span>
              </div>
              <p className="p-1 white">Davied Worn Trump</p>
            </Card.ImgOverlay>
          </Card>
        </Link>
        <Link
          to="/"
          className="absolute z-10 flex items-center justify-center w-12 h-12 -mt-5 bg-white rounded-full shadow-sm top-2/4 -right-6">
          <HiArrowRight className="darkGray" size={18} />
        </Link>
      </div>
    );
  }
}

export default Stories;
