import React from "react";
import "./styles.css";
import ConnectyCube from "connectycube";

export default function Item({ item, user_id }) {
  let mine = user_id === item.sender_id ? "mine" : "";
  let color = user_id === item.sender_id ? "white" : "black";
  return (
    <>
      {/* <div className="flex justify-center">
        <p className="gray fs-12">MONDAY, OCTOBER 25, 2021 8:14 PM</p>
      </div> */}
      <div className={`message ${mine} start`}>
        <div className="bubble-container">
          <div className="bubble" title="Monday, October 25, 2021 8:33 PM">
            <p className={`${color} Regular fs-14`}>
              {item.attachment && item.attachment.length > 0 && (
                <>
                  {item.attachment[0].url ? (
                    <img
                      src={item.attachment[0].url}
                      width="150"
                      height="150"
                    />
                  ) : (
                    <img
                      src={ConnectyCube.storage.publicUrl(
                        item.attachment[0].uid
                      )}
                      width="150"
                      height="150"
                    />
                  )}
                </>
              )}
              {item.body}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="message  start ">
        <div className="bubble-container">
          <div className="bubble" title="Monday, October 25, 2021 8:17 PM">
            <p className="black Regular fs-14">
              It looks like it wraps exactly as it is supposed to. Lets see what
              a reply looks like!
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
