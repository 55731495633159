import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import colors from "config/color/color.js";

class Applicantsmatchednavigation extends Component {
  render() {
    const countryOptions = [
      { key: "af", value: "af", text: "Laravel / Laminas(Zend) Expert" },
      { key: "ax", value: "ax", text: "Laravel / Laminas(Zend) Expert" },
      { key: "al", value: "al", text: "Laravel / Laminas(Zend) Expert" },
      { key: "dz", value: "dz", text: "Laravel / Laminas(Zend) Expert" },
      { key: "as", value: "as", text: "Laravel / Laminas(Zend) Expert" },
      { key: "ad", value: "ad", text: "Laravel / Laminas(Zend) Expert" },
      { key: "ao", value: "ao", text: "Laravel / Laminas(Zend) Expert" },
      { key: "ai", value: "ai", text: "Laravel / Laminas(Zend) Expert" },
      { key: "ag", value: "ag", text: "Laravel / Laminas(Zend) Expert" },
      { key: "ar", value: "ar", text: "Laravel / Laminas(Zend) Expert" },
      { key: "am", value: "am", text: "Laravel / Laminas(Zend) Expert" },
      { key: "aw", value: "aw", text: "Laravel / Laminas(Zend) Expert" },
      { key: "au", value: "au", text: "Laravel / Laminas(Zend) Expert" },
      { key: "at", value: "at", text: "Laravel / Laminas(Zend) Expert" },
      { key: "az", value: "az", text: "Laravel / Laminas(Zend) Expert" },
      { key: "bs", value: "bs", text: "Laravel / Laminas(Zend) Expert" },
      { key: "bh", value: "bh", text: "Laravel / Laminas(Zend) Expert" },
      { key: "bd", value: "bd", text: "Laravel / Laminas(Zend) Expert" },
      { key: "bb", value: "bb", text: "Laravel / Laminas(Zend) Expert" },
      { key: "by", value: "by", text: "Laravel / Laminas(Zend) Expert" },
      { key: "be", value: "be", text: "Laravel / Laminas(Zend) Expert" },
      { key: "bz", value: "bz", text: "Laravel / Laminas(Zend) Expert" },
      { key: "bj", value: "bj", text: "Laravel / Laminas(Zend) Expert" },
    ];
    return (
      <div className="z-10 py-3">
        <div className="relative flex items-center justify-between ">
          <div className="grid gap-3 lg:grid-cols-3">
            <div>
              <Dropdown
                placeholder="Select Job"
                fluid
                search
                selection
                options={countryOptions}
                className="border rounded--xl"
              />
            </div>
            <div>
              <div className="flex flex-row items-center bg-white border rounded-xl">
                <NavLink
                  to="/candidates"
                  activeClassName="active--link"
                  activeStyle={{ color: colors.white }}
                  className="px-4 flex justify-center bg--primary hover:bg-gray-200 rounded-l-xl py-2.5 relative ">
                  Applicants (4)
                </NavLink>
                <NavLink
                  to="/candidates"
                  activeClassName="active--link"
                  activeStyle={{ color: colors.white }}
                  className="px-4 flex justify-center bg--primary hover:bg-gray-200 rounded-r-xl py-2.5 relative ">
                  Matched candidates (1)
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Applicantsmatchednavigation;
