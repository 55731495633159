import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePushpin } from "react-icons/ai";
import { GoSearch } from "react-icons/go";
import { Image } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import colors from "config/color/color.js";
import avatar from "assets/images/avatar.webp";
import { Profilepopup } from "components";

class Groupnavigation extends Component {
  constructor(props) {
    super(props);
    this.state = { isTop: true, open: false };
    this.editProfileRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 525;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  editProfile = () => {
    this.editProfileRef.current.editPopupOpen();
  };

  render() {
    const { item } = this.props;
    return (
      <div
        className="z-10 h-16 bg-white shadow-sm profile-navigation"
        style={{
          position: this.state.isTop ? null : "sticky",
          top: 53,
        }}>
        <div className="m-auto container--small xl:px-12 md:px-5 sm:px-4 xs:px-4">
          <div className="divider " />
          <div className="relative flex items-center justify-between py-1">
            <div
              style={{
                transform: this.state.isTop
                  ? `translate(0px, 0px)`
                  : `translate(0px, -70px)`,
                opacity: this.state.isTop ? 1 : 0,
              }}
              className="flex flex-row items-center transition duration-100 transform navbar-nav">
              <Link
                to="/groups/details/about"
                activeClassName="active--link"
                activeStyle={{ color: colors.primary }}
                className="relative flex justify-center px-4 py-3 rounded-lg black hover:bg-gray-200">
                About
              </Link>
              <Link
                to="/groups/details"
                activeClassName="active--link"
                activeStyle={{ color: colors.primary }}
                className="relative flex justify-center px-4 py-3 rounded-lg black hover:bg-gray-200">
                Discussion
              </Link>
              <Link
                to={"/groups/details/members/" + item.id}
                activeClassName="active--link"
                activeStyle={{ color: colors.primary }}
                className="relative flex justify-center px-4 py-3 rounded-lg black hover:bg-gray-200">
                Members{" "}
              </Link>
              <Link
                to="/groups/details/media"
                activeClassName="active--link"
                activeStyle={{ color: colors.primary }}
                className="relative flex justify-center px-4 py-3 rounded-lg hover:bg-gray-200">
                Media
              </Link>
            </div>

            <div
              className="absolute left-0 z-10 transition duration-200 transform"
              style={{
                // transform: this.state.isTop
                //   ? `translate(0px, 50px)`
                //   : `translate(0px, 0px)`,

                zIndex: this.state.isTop ? -1 : 1,
                opacity: this.state.isTop ? 0 : 1,
              }}>
              <div className="flex items-center px-4 py-1 transition duration-300 transform rounded-lg hover:bg-gray-200">
                <Image
                  src={avatar}
                  className="object-cover w-12 h-12 mr-3 rounded-full"
                />
                <p>Daveid Buffet Trump</p>
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <div className="flex items-center h-10 px-3 mx-2 transition duration-300 transform bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300 hover:scale-90">
                  <GoSearch size={16} />
                </div>

                <Dropdown
                  icon={false}
                  wrapSelection={true}
                  direction="left"
                  pointing="top"
                  text={
                    <div>
                      <div className="flex items-center h-10 px-3 transition duration-300 transform bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300 hover:scale-90">
                        <BsThreeDots size={16} />
                      </div>
                    </div>
                  }>
                  <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-96">
                    <div className="p-2">
                      <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                        <div className="mr-3">
                          <AiOutlinePushpin />
                        </div>
                        <p>Pin Group</p>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <Profilepopup ref={this.editProfileRef} {...this.props} />
      </div>
    );
  }
}

export default Groupnavigation;
