import React, { Component } from "react";
import { Aboutnavigation, Relationship } from "components";

class Familyandrelationships extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="bg-white shadow-sm rounded-lg">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-0">
            <div className="border-r p-4">
              <h3 className="mb-3">About</h3>
              <Aboutnavigation />
            </div>

            <div className="col-span-2 p-4">
              <h3 className="mb-3">Relationship</h3>
              <div className="mb-3">
                <Relationship
                  deleteWorkplacesPopupOpen={this.deleteWorkplacesPopupOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Familyandrelationships;
