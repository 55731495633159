import React, { Component } from "react";

class OnlineStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <span className="relative flex w-3 h-3 ml-auto mr-3 -mt-5 ">
        <span className="absolute right-0 inline-flex w-full h-full rounded-full opacity-75 animate-ping bg--success"></span>
        <span className="relative inline-flex w-3 h-3 rounded-full bg--success"></span>
      </span>
    );
  }
}

export default OnlineStatus;
