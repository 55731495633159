import React, { Fragment, forwardRef, useImperativeHandle } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TextareaAutosize } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux'
import { FiX } from "react-icons/fi";
import { Buttonused } from "components";
import { BiLoaderAlt } from "react-icons/bi";

const { actions } = require("redux/BarEnhancement");

const TableForm = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    const [barId, setBarId] = React.useState(null);
    const [levelId, setLevelId] = React.useState(null);
    const [productId, setProductId] = React.useState(null);
    const [formId, setFormId] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const table = useSelector((state) => Object.assign({}, state.enhancement.table));

    const isSubmitting = useSelector((state) => state.enhancement.table.isSubmitting);

    const data = useSelector((state) => Object.assign({}, state.enhancement.management.data));


    const tableForm = Object.assign({}, table.form);

    const errorForm = Object.assign({}, table.error);

    const form = (tableForm?.[barId]?.[formId] || {});
    const error = (errorForm?.[barId]?.[formId] || {});

    const closeModal = () => {
        setIsOpen(false);
    }

    const open = () => {
        setIsOpen(true);

    }

    useImperativeHandle(ref, () => ({
        open(bar_id, level_id, product_id) {
            let form_id = level_id;
            if (product_id) {
                form_id = product_id;
            }
            const localTableForm = Object.assign({}, table.form);
            const localForm = {};
            const productData = Object.assign({}, data?.[bar_id]?.[product_id]);
            console.log(productData)
            let schedule = {};
            Object.assign([], productData?.schedule).map((item, key) => {
                schedule[item.day] = item;
            });
            if (productData.id) {
                productData.schedule = schedule;
            }

            const localData = product_id ? productData : localForm;
            localData["bar_id"] = bar_id;
            localData["level_id"] = form_id;
            dispatch(actions.addTableForm(localData, bar_id, form_id));
            setBarId(bar_id);
            setLevelId(level_id);
            setProductId(product_id);
            setFormId(form_id);
            open();
        },
    }));

    const addForm = (key, value) => {
        const data = form;
        data[key] = value;
        data["bar_id"] = barId;
        data["level_id"] = formId;
        dispatch(actions.addTableForm(data, barId, formId));
    }

    const addPricing = (day, type, value) => {
        const data = form;
        const schedule = Object.assign({}, form.schedule);
        schedule[day] = {
            ...schedule[day],
            [type]: value
        };
        data.schedule = schedule;
        dispatch(actions.addTableForm(data, barId, formId));
    }

    const onSubmit = async () => {
        const json = await actions.submitBarTable(dispatch, form);
        if (json !== undefined) {
            if (json.status == 200) {
                closeModal();
                if (!form.id) {
                    dispatch(actions.insertBarTable(json.data))
                    actions.fetchLevel(dispatch, { bar_id: barId }, levelId);
                } else {
                    dispatch(actions.updateBarTable(json.data))
                }

            }
        }
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>

                <div className="fixed inset-0 bg-black bg-opacity-25" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="flex min-h-full items-center h-screeen overflow-hidden justify-center p-4 text-center">
                        <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900 pb-3"
                            >
                                <Buttonused
                                    Icon={<FiX className="text-black" size={26} />}
                                    onClick={closeModal}
                                    className="bg-gray-200 flex items-center justify-center hover:opacity-80 border-0 ring-0 outline-0 shadow-none h-12 w-12 ml-auto rounded-full"
                                />
                            </Dialog.Title>
                            <div className="grid grid-cols-1 gap-x-4 h-[calc(100vh-210px)] overflow-y-auto">
                                <div className="p-5 h-full rounded-lg">
                                    <div className="space-y-4">
                                        <div className="flex items-center gap-x-4">
                                            <p className="min-w-[120px] text-right">
                                                Seating Capacity
                                            </p>
                                            <input
                                                type="number"
                                                placeholder="Seating Capacity"
                                                className="shadow-none rounded-lg h-12 px-5 important:bg--lightGray"
                                                value={(form.capacity || "")}
                                                onChange={(e) => addForm("capacity", e.target.value)}
                                            />

                                        </div>
                                        {error.capacity &&
                                            <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                {error.capacity?.[0]}
                                            </span>
                                        }
                                        <div className="flex items-center gap-x-4">
                                            <p className="min-w-[120px] text-right">
                                                Table Number
                                            </p>
                                            <input
                                                type="text"
                                                placeholder="Table Number"
                                                className="shadow-none  rounded-lg h-12 px-5 important:bg--lightGray"
                                                value={(form.table_no || "")}
                                                onChange={(e) => addForm("table_no", e.target.value)}
                                            />
                                        </div>
                                        {error.table_no &&
                                            <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                {error.table_no?.[0]}
                                            </span>
                                        }
                                        <div className="flex gap-x-4">
                                            <p className="min-w-[120px] text-right">
                                                Description:
                                            </p>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Description..."
                                                className="p-3 min-h-[150px] placeholder:font-light shadow-none resize-none rounded-lg post-commnet-textInput bg--lightGray w-full"
                                                value={(form.description || "")}
                                                onChange={(e) => addForm("description", e.target.value)}
                                            />
                                        </div>
                                        {error.description &&
                                            <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                {error.description?.[0]}
                                            </span>
                                        }
                                        <div className="flex gap-x-4">
                                            <p className="min-w-[120px] max-w-[120px] text-right">
                                                Must arrive by Message:
                                            </p>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Message..."
                                                className="p-3 min-h-[150px] placeholder:font-light shadow-none resize-none rounded-lg post-commnet-textInput bg--lightGray w-full"
                                                value={(form.message || "")}
                                                onChange={(e) => addForm("message", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="h-full rounded-lg p-5">
                                    <div className="space-y-4">
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Monday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["monday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("monday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.monday.price"] &&
                                                        <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.monday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">

                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["monday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("monday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.monday.hours"] &&
                                                        <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.monday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Tuesday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow  w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["tuesday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("tuesday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.tuesday.price"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.tuesday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["tuesday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("tuesday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.tuesday.hours"] &&
                                                        <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.tuesday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Wednesday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["wednesday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("wednesday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.wednesday.price"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.wednesday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["wednesday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("wednesday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.wednesday.hours"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.wednesday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Thursday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["thursday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("thursday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.thursday.price"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.thursday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["thursday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("thursday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.thursday.hours"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.thursday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Friday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["friday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("friday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.friday.price"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.friday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["friday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("friday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.friday.hours"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.friday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Saturday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["saturday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("saturday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.saturday.price"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.saturday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["saturday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("saturday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.saturday.hours"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.saturday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Sunday
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <div className="w-full">
                                                    <input
                                                        type="text"
                                                        placeholder="Pricing"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["sunday"]?.["price"] ?? ""}
                                                        onChange={(e) => addPricing("sunday", 'price', e.target.value)}
                                                    />
                                                    {error?.["schedule.sunday.price"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.sunday.price"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input
                                                        type="number"
                                                        placeholder="Cancel"
                                                        className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                        value={form.schedule?.["sunday"]?.["hours"] ?? ""}
                                                        onChange={(e) => addPricing("sunday", 'hours', e.target.value)}
                                                    />
                                                    {error?.["schedule.sunday.hours"] &&
                                                        <span class="flex items-center  font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                            {error?.["schedule.sunday.hours"]?.[0]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {!!error.tax_rate &&
                                            <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                {error.tax_rate?.[0]}
                                            </span>
                                        }
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-gray-700 min-w-[120px] text-right">
                                                Tax Rate
                                            </p>
                                            <div className="flex items-center gap-x-4">
                                                <input
                                                    type="number"
                                                    placeholder="Enter tax rate"
                                                    className="shadow-none flex-grow w-full rounded-lg h-12 px-2 important:bg--lightGray"
                                                    value={(form.tax_rate || "")}
                                                    onChange={(e) => addForm("tax_rate", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 border-t pt-4">
                                <Buttonused
                                    Icon={isSubmitting && <BiLoaderAlt size={18} className="animate-spin" />}
                                    disabled={isSubmitting}
                                    onClick={onSubmit}
                                    Text="Submit"
                                    className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-52 ml-auto rounded--lg"
                                />
                            </div>
                        </Dialog.Panel>

                    </div>
                </div>
            </Dialog>
        </Transition>
    );

})

export default TableForm;
