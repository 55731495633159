import React, { Component } from "react";

class Photoshead extends Component {
  render() {
    return (
      <div className="flex items-center justify-between">
        <h3 className="black text-2xl font-medium Medium">Photos</h3>
        <div className="flex items-center justify-between ">
          {/* <div className="rounded-full bg--lightGray h-10 w-64 px-2 flex flex-row items-center">
            <div>
              <GoSearch style={{ color: colors.darkGray }} className="mr-2" />
            </div>
            <input
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="h-10 w-64 Regular border-0 bg-transparent"
            />
          </div> */}
          {/* <div className="hover:bg-gray-200 rounded-lg ml-3 flex items-center h-10 px-3 cursor-pointer ">
            <Link to="/friends/requests" className="primary ">
              Friend Requests
            </Link>
          </div>
          <div className="hover:bg-gray-200 rounded-lg flex items-center h-10 px-3 cursor-pointer ">
            <Link to="/friends" className="primary ">
              Find Friends
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Photoshead;
