import React, { Component } from "react";
import "./styles.css";
import { Gridmenu } from "components";
import { connect } from "react-redux";

class FeedLeftAside extends Component {
  render() {
    const { total, menu, postDetailStyle, feedSideStyle } = this.props;
    return (
      <div
        className={`${postDetailStyle} ${feedSideStyle}  overflow-y-auto`}
        id="scrollbar">
        <Gridmenu menu={menu} total={total} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu:
      typeof state.User.menu !== "undefined"
        ? state.User.menu
        : {
            flirt: true,
            bars: true,
            restaurant: true,
            feedback: true,
            share_us: true,
            bill_of_rights: true,
            company: true,
            reward: true,
            how_to: true,
            groups: true,
            saved: true,
            comms: true,
          },
  };
};

export default connect(mapStateToProps, null)(FeedLeftAside);
