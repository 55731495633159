import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { CgTrashEmpty } from "react-icons/cg";

class Aboutnavigation extends Component {
  render() {
    const { data, user } = this.props;
    return (
      <div className="transition duration-100 transform">
        <NavLink
          to={`/${data.user_name}/about`}
          className="flex px-3 py-2 my-2 bg-gray-200 rounded-lg  black hover:bg--lightPrimary"
          activeClassName=" important:primary  bg--lightPrimary">
          Overview
        </NavLink>
        {data.id === user.id && (
          <NavLink
            to={`/${data.user_name}/edit`}
            className="flex px-3 py-2 my-2 bg-gray-200 rounded-lg  black hover:bg--lightPrimary"
            activeClassName="important:primary  bg--lightPrimary">
            Edit Profile
          </NavLink>
        )}
        <div className="p-2.5 mt-3 cursor-pointer rounded-lg flex items-center mb-2 bg--danger list-items m-0">
          <CgTrashEmpty size={18} style={{ color: colors.white }} />
          <p className="ml-2 white fs-13">Delete Account</p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.User.user,
  };
};

export default connect(mapStateToProps)(Aboutnavigation);
