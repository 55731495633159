import React, { Component } from "react";
import { Working, Aboutnavigation, DeletePopup } from "components";
import { FiPlusCircle } from "react-icons/fi";
import colors from "config/color/color.js";

class Workandeducation extends Component {
  constructor(props) {
    super(props);
    this.state = { deleteWorkplaces: false };
    this.deleteWorkplaceRef = React.createRef();
  }

  deleteWorkplacesPopupOpen = () => {
    this.deleteWorkplaceRef.current.deleteWorkplacesPopupOpen();
  };

  render() {
    return (
      <div>
        <div className="bg-white shadow-sm rounded-lg">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-0">
            <div className="border-r p-4">
              <h3 className="mb-3">About</h3>
              <Aboutnavigation />
            </div>

            <div className="col-span-2 p-4">
              <h3 className="mb-3">Work</h3>
              <div className="mb-3">
                <p className="primary flex items-center cursor-pointer hover:underline hover:opacity-90">
                  <FiPlusCircle
                    size={25}
                    style={{ color: colors.primary }}
                    className="mr-2"
                  />
                  Add a workplace
                </p>
              </div>
              <Working
                deleteWorkplacesPopupOpen={this.deleteWorkplacesPopupOpen}
              />
            </div>
          </div>
        </div>
        <DeletePopup ref={this.deleteWorkplaceRef} {...this.props} />
      </div>
    );
  }
}

export default Workandeducation;
