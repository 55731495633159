import React, { Component } from "react";
import { Postquestion, UserPost } from "components";
import { connect } from "react-redux";

class Profilefeed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { posts, user } = this.props;
    return (
      <div>
        <div className="mb-3">
          <Postquestion />
        </div>
        <div className="mb-3">
          <UserPost />
        </div>
        {/* <div className="mb-3">
          <Post />
        </div>
        <div className="mb-3">
          <Post />
        </div>  */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
    // deletePost: (data) => {
    //   actions.deletePostFeed(dispatch, data);
    // },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(React.memo(Profilefeed));
