import React, { Component } from "react";
import { Loader } from "semantic-ui-react";
import { PostCommentBox, PostComments } from "components";
import { connect } from "react-redux";
import * as Scroll from "react-scroll";
import { Element } from "react-scroll";
var scroller = Scroll.scroller;

class Postcomment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.per_page = 10;
    this.page = 1;
    this.params = [];
  }

  fetch = () => {
    const { item } = this.props;
    this.params["post_id"] = item.id;
    this.params["order_by"] = "desc";
    this.page = 1;
    this.props.fetchCommentsByPostId(this.per_page, this.page, this.params);
  };

  fetchMore = () => {
    const { item } = this.props;
    this.params["post_id"] = item.id;
    this.params["order_by"] = "desc";
    if (!this.props.isFetching) {
      this.page++;
      this.props.fetchCommentsByPostId(this.per_page, this.page, this.params);
    }
  };

  _openCommentBox = () => {
    const { item } = this.props;
    scroller.scrollTo("postCommentBox--" + item.id, {
      offset: -200,
    });
  };

  render() {
    const { item, commentIds, user, isFetching } = this.props;
    return (
      <div className="border-t">
        <div className="mt-2">
          <div className="flex items-center justify-end">
            <div className="flex items-center justify-center cursor-pointer ">
              {/* <NavDropdown
                title={
                  <div className="flex items-center">
                    <p className="darkGray">Most Relevant</p>
                    <TiArrowSortedDown
                      size={18}
                      style={{ color: colors.darkGray }}
                    />
                  </div>
                }
                className="custom-dropdown right"
              >
                <div className="absolute right-0 z-10 w-screen max-w-sm mt-2 sm:px-0">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative p-2 bg-white">
                      <div className="p-2 rounded-lg hover:bg-gray-200">
                        <p className="Medium">Most Relevant</p>
                        <p className="text-sm darkGray Light">
                          Show friend's comments and the most engaging comments
                          first.
                        </p>
                      </div>
                      <div className="p-2 rounded-lg hover:bg-gray-200">
                        <p className="Medium">Newest </p>
                        <p className="text-sm darkGray Light">
                          Show all comments, with the newest comment first.
                        </p>
                      </div>
                      <div className="p-2 rounded-lg hover:bg-gray-200">
                        <p className="Medium">All Commnets </p>
                        <p className="text-sm darkGray Light">
                          Show all comments, including potential spam. The most
                          relevant comments will appeart first.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavDropdown> */}
            </div>
          </div>
          <Element name={`postCommentBox--${item.id}`}>
            <PostCommentBox post_id={item.id} />
          </Element>
          <div className="pb-3">
            {commentIds &&
              commentIds.map((item_id, key) => (
                <PostComments
                  openCommentBox={this._openCommentBox}
                  key={key}
                  user={user}
                  commentId={item_id}
                  post_id={item.id}
                />
              ))}
            {commentIds && commentIds.length > 0 && (
              <>
                {isFetching ? (
                  <Loader active={isFetching} inline="centered" size="small" />
                ) : (
                  item.comments_count > 1 &&
                  commentIds.length < item.comments_count && (
                    <p
                      onClick={this.fetchMore}
                      className="cursor-pointer darkGray hover:underline fs-12">
                      View more comments
                    </p>
                  )
                )}
              </>
            )}
          </div>
          {/* <div className="pb-3">
            <Commentshierarchy />
          </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.item;
  return {
    commentIds: state.Comment.commentIds[id] ?? [],
    isFetching: state.Comment.isFetching,
    firstTimeLoading: state.Comment.firstTimeLoading,
    user: state.User.user,
    token: state.User.token,
    breakTime: state.User.breakTime,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CommentRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchCommentsByPostId: (per_page, page, params = []) => {
      actions.fetchCommentsByPostId(dispatch, per_page, page, params);
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Postcomment);
