import React, { Component } from "react";
import { FaCoins } from "react-icons/fa";
import { Capitalize } from "helpers";
import colors from "config/color/color.js";
import { Peopleimage } from "components";

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item, index } = this.props;
    return (
      <>
        <div
          style={
            index === 0
              ? { backgroundColor: colors.gold }
              : index === 1
              ? { backgroundColor: "#D9DACA" }
              : index === 2
              ? { backgroundColor: "#F3AA6C" }
              : { backgroundColor: colors.lightGray }
          }
          className="flex items-center justify-between px-3 py-3 mb-3 rounded-xl">
          <div className="relative flex items-center">
            <Peopleimage
              item={item}
              {...this.props}
              imageStyle="rounded-full h-12 w-12 object-cover mr-3 bg-transparent"
            />
            <div
              style={
                index === 0
                  ? { backgroundColor: colors.white }
                  : index === 1
                  ? { backgroundColor: colors.white }
                  : index === 2
                  ? { backgroundColor: colors.white }
                  : { backgroundColor: colors.primary }
              }
              className="absolute flex items-center justify-center w-4 h-4 rounded-full  -top-1">
              <p
                className="gold fs-10"
                style={
                  index === 0
                    ? { color: colors.gold }
                    : index === 1
                    ? { color: "#D9DACA" }
                    : index === 2
                    ? { color: "#F3AA6C" }
                    : { color: colors.white }
                }>
                {index + 1}
              </p>
            </div>
            <p>
              {" "}
              {Capitalize(item.user.first_name)}{" "}
              {Capitalize(item.user.last_name)}
            </p>
          </div>
          <div className="flex items-center ">
            <FaCoins
              style={
                index === 0
                  ? { color: colors.black }
                  : index === 1
                  ? { color: colors.black }
                  : index === 2
                  ? { color: colors.black }
                  : { color: colors.gold }
              }
            />
            <p className="ml-3">{item.point}</p>
          </div>
        </div>
      </>
    );
  }
}

export default Leads;
