import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiPhone } from "react-icons/fi";
import { Restaurantsthumbnails } from "components";
import { OptimizeImage } from "helpers";
import { Avatar } from "@material-ui/core";

class Restaurantscard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item } = this.props;
    let banner = null;
    let logo = null;
    let bannerObject = Object.assign({}, this.props.item.banner);
    if (bannerObject.attachment_url) {
      banner = bannerObject;
    }

    let logoObject = Object.assign({}, this.props.item.logo);
    if (logoObject.attachment_url) {
      logo = OptimizeImage(logoObject.attachment_url);
    }

    let attaches = [];
    let photos = Object.assign([], this.props.item.photos);
    if (banner) {
      photos.unshift(banner);
    }
    for (var i = 0; i < photos.length; i++) {
      attaches[i] = {
        ...photos[i],
        url: OptimizeImage(photos[i].attachment_url, photos[i].type),
      };
    }
    return (
      <div className="block overflow-hidden bg-white shadow-md rounded-xl">
        <div className="relative">
          <Restaurantsthumbnails item={item} />
          <div className="absolute top-5 right-5">
            <a
              className="flex items-center justify-center rounded-full h-9 w-9 bg--primary"
              href="tel:+1-555-555-1212">
              <FiPhone color={colors.white} />
            </a>
          </div>
        </div>
        <div className="px-3 py-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="mr-3 overflow-hidden rounded-xl h-11 w-11">
                <Avatar
                  variant="square"
                  src={logo}
                  alt={item.name}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <p className="flex items-center black Medium">{item.name}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Restaurantscard;
