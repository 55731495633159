import React, { Component } from "react";
import { Image } from "react-bootstrap";
import "./styles.css";
import colors from "config/color/color.js";
import { FaRegCommentAlt } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { LifeWidget, Emitter } from "common";
import { Popup } from "semantic-ui-react";
import hundred from "assets/images/reaction/100.png";
import angry from "assets/images/reaction/angry.gif";
import haha from "assets/images/reaction/haha.gif";
import love from "assets/images/reaction/love.gif";
import rolling from "assets/images/reaction/rolling.gif";
import sad from "assets/images/reaction/sad.gif";
import wow from "assets/images/reaction/wow.gif";
import smile from "assets/images/reaction/smile-face.png";
import { Postcomment, RecentComment } from "components";

const images = [
  {
    id: 1,
    code: "100",
    img: hundred,
    select: hundred,
    text: "100",
    color: "#FF0000",
  },
  {
    id: 2,
    code: "love",
    img: love,
    select: love,
    text: "Love",
    color: "#FF0000",
  },
  {
    id: 3,
    code: "haha",
    img: haha,
    select: haha,
    text: "Haha",
    color: "#e67e22",
  },
  {
    id: 4,
    code: "wow",
    img: wow,
    select: wow,
    text: "Wow",
    color: "#e67e22",
  },
  {
    id: 5,
    code: "sad",
    img: sad,
    select: sad,
    text: "Sad",
    color: "#e67e22",
  },
  {
    id: 6,
    code: "sarcasm",
    img: rolling,
    select: rolling,
    text: "Sarcasm",
    color: "#e67e22",
  },
  {
    id: 7,
    code: "angry",
    img: angry,
    select: angry,
    text: "Angry",
    color: "#e74c3c",
  },
];

class Postfooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcomment: true,
      hoverShow: "",
    };

    this.postCommentRef = React.createRef();
  }

  change = async (i) => {
    this.props.item.reaction = { code: i.code };
    this.setState({ hoverShow: "" });
    const json = await LifeWidget.addReaction(this.props.item.id, i.id);
  };

  sharePost = () => {
    const { item } = this.props;
    Emitter.emit("OPEN_POST_MODAL", item);
  };

  reactionModal = () => {
    const { item } = this.props;
    Emitter.emit("POST_REACTION_MODAL", item);
  };

  render() {
    const { item } = this.props;
    const { max_vote } = this.props.item;
    if (max_vote && max_vote.code) {
      var selected = images.find((item) => item.code === max_vote.code);
    }
    if (typeof selected === "undefined") {
      var selected = images[0];
    }

    const { reaction } = this.props.item;
    if (reaction && reaction.code) {
      var selectedReaction = images.find((item) => item.code === reaction.code);
    }
    if (typeof selectedReaction === "undefined") {
      var selectedReaction = images[0];
    }

    return (
      <div className="relative mt-3 -mx-2 post-fotter">
        <div className="flex items-center justify-between">
          <Popup
            className="p-2 px-3"
            inverted
            position="top center"
            // content={
            //   <div>
            //     <p className="text-sm cursor-pointer white Light">
            //       User Name one
            //     </p>
            //   </div>
            // }
            trigger={
              <div>
                {max_vote !== undefined && max_vote.count > 0 && (
                  <div
                    onClick={this.reactionModal}
                    className="flex items-center cursor-pointer">
                    <div className="flex items-center justify-center">
                      <Image
                        src={selected.select}
                        className="object-cover h-7 w-7"
                      />
                    </div>
                    <p className="ml-2 darkGray Light">{max_vote.count}</p>
                  </div>
                )}
              </div>
            }
          />
          <div
            onClick={this.reactionModal}
            className="flex items-center cursor-pointer">
            <div
              onClick={() => this.postCommentRef.current.fetch()}
              className="flex items-center mr-5 cursor-pointer">
              <p className="ml-2 darkGray Light">
                {item.comments_count} Comment
              </p>
            </div>
            <div className="flex items-center cursor-pointer">
              <Popup
                className="p-2 px-3"
                inverted
                position="top center"
                // content={
                //   <div>
                //     <p className="text-sm cursor-pointer white Light">
                //       User Name one
                //     </p>
                //   </div>
                // }
                trigger={
                  <div className="flex items-center cursor-pointer">
                    <p className="ml-2 darkGray Light">Shares</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <div className="flex items-center py-1 mt-3 border-t justify-evenly ">
          <div
            onMouseEnter={() => this.setState({ hoverShow: "hover--show" })}
            onMouseLeave={() => this.setState({ hoverShow: "" })}
            className={`like-reactions flex items-center hover:bg-gray-200 cursor-pointer flex-grow justify-center rounded-lg py-2 ${this.state.hoverShow}`}>
            {reaction && selectedReaction ? (
              <Image src={selectedReaction.select} className="h-7 w-7" />
            ) : (
              <Image src={smile} className="h-7 w-7" />
            )}
            <div className="reactions flex items-center justify-evenly w-96 shadow-lg bg-white rounded-full py-2.5">
              {images.map((item, key) => (
                <div onClick={() => this.change(item)}>
                  <Popup
                    className="px-1 py-0 border-0 bg-black-300"
                    inverted
                    position="top center"
                    content={
                      <p className="text-sm cursor-pointer white Regular">
                        {item.text}
                      </p>
                    }
                    trigger={
                      <Image src={item.img} key={key} className="w-10 h-10" />
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            onClick={() => this.postCommentRef.current.fetch()}
            className="flex items-center justify-center flex-grow py-2 rounded-lg cursor-pointer hover:bg-gray-200">
            <FaRegCommentAlt style={{ color: colors.darkGray }} />
            <p className="ml-2 darkGray">Comment</p>
          </div>
          <div
            onClick={this.sharePost}
            className="flex items-center justify-center flex-grow py-2 rounded-lg cursor-pointer hover:bg-gray-200">
            <RiShareForwardLine style={{ color: colors.darkGray }} />
            <p className="ml-2 darkGray">Share</p>
          </div>
        </div>
        {this.state.postcomment ? (
          <div>
            <Postcomment ref={this.postCommentRef} item={item} />
          </div>
        ) : null}
        <RecentComment postCommentRef={this.postCommentRef} item={item} />
      </div>
    );
  }
}

export default Postfooter;
