import React, { Component } from "react";
import colors from "config/color/color";
import { Link } from "react-router-dom";
import { TextareaAutosize } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";

class Postjobdescription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <div className="mb-2">
            <p className="fs-16 Medium">
              Describe the responsibilities of this job, required work
              experience, skills, or education.
            </p>
          </div>
          <Editor
            toolbarClassName="toolbarClassName mb-0 border rounded--t--xl important:bg--lightGray"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName border-l border-r border-b p-3 rounded-b-xl"
            toolbar={{
              options: ["inline", "list"],
              inline: {
                inDropdown: false,
                className: "rounded--xl",
                component: undefined,
                dropdownClassName: undefined,
                options: ["bold", "italic", "underline"],
                bold: { className: "rounded--lg" },
                italic: { className: "rounded--lg" },
                underline: { className: "rounded--lg" },
              },

              list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ["unordered", "ordered"],
                unordered: { className: "rounded--lg" },
                ordered: { className: "rounded--lg" },
              },
            }}
          />
          <div className="mt-5">
            <div className="mb-2">
              <p className="fs-16 Medium">
                Are you taking any additional COVID-19 precautions?
              </p>
              <p className="darkGray fs-13">
                Let job seekers know how you’re responding to COVID-19.
              </p>
            </div>
            <TextareaAutosize
              minRows={4}
              placeholder="Ex: All customers are required to wear a mask, curbside pickup available, common surfaces are sanitized regularly, vaccination requirements, etc."
              className="w-full p-3 bg-white border shadow-none outline-none resize-none Light rounded--xl"
            />
            <p className="ml-3 fs-12 darkGray Light">0 / 266</p>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <Link
            to="/post_job/job_detail"
            style={{ color: colors.black }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black Regular rounded--xl hover:scale-90">
            Back
          </Link>
          <Link
            to="/post_job/applications_settings"
            style={{ color: colors.white }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary hover:opacity-80 focus:bg--primary white Regular rounded--xl hover:scale-90">
            Save and continue
          </Link>
        </div>
      </div>
    );
  }
}

export default Postjobdescription;
