import React, { Component } from "react";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { HiOutlineArrowLeft } from "react-icons/hi";

class Companiesleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business_type: "Company",
    };
  }
  render() {
    return (
      <div className="fixed flex flex-col p-3 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Main Menu
                </Link>
                <h2 className="text-2xl">My Companies</h2>
              </div>
            </div>
          </div>
          <div className="my-3 divider" />
          {/* <div>
            <Link
              to="/my_companies"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200 bg--lightPrimary"
            >
              <div
                style={{ backgroundColor: colors.primary }}
                className="flex items-center justify-center w-10 h-10 mr-3 rounded-full"
              >
                <IoIosAlbums size={18} style={{ color: colors.white }} />
              </div>
            </Link>
          </div> */}
        </div>
        <div className="my-3 divider" />
        <Link
          to={{
            pathname: `/bars/bar-create/${this.state.business_type}`,
            state: this.state.business_type,
          }}
          className="flex items-center justify-center w-full mb-3 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
          <FiPlus color={colors.primary} size={18} />
          <p className="ml-2 primary">Add Company</p>
        </Link>
      </div>
    );
  }
}

export default Companiesleftaside;
