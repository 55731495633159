import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FiX } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";
import logo from "assets/images/logo.webp";
import "./styles.css";
import {
  UserProfileComponent,
  Menuaccordion,
  Notification,
  Menu,
} from "components";
import { Emitter } from "common";
import ConnectyCube from "connectycube";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Capitalize, OptimizeImage } from "helpers";
import { Dimmer, Loader } from "semantic-ui-react";
import { VscFeedback } from "react-icons/vsc";
import { IoHandRightSharp } from "react-icons/io5";
import { RiSlideshow3Fill } from "react-icons/ri";
import colors from "config/color/color.js";
import { TiArrowSortedDown } from "react-icons/ti";
import { AiOutlineLogout } from "react-icons/ai";
import { CgMenuGridO } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { FaBell } from "react-icons/fa";

class Tailheader extends Component {
  constructor(props) {
    super(props);
    this.state = { refreshControl: false };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.setState({ refreshControl: false });
    }, 3000);
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.setState({ refreshControl: true });
  };
  searchUser = (userName) => {};

  onSearch = (e) => {
    Emitter.emit("SEARCH_PEOPLE_GROUP", e.target.value);
  };

  logout = async () => {
    this.props.logout();
    try {
      await ConnectyCube.logout();
    } catch (e) {}
  };

  render() {
    const { user } = this.props.User;
    const { activeTab } = this.props;
    return (
      <div className="z-20 w-full bg-white shadow-sm tail-header">
        <div
          style={{ top: this.state.refreshControl === true ? 120 : -100 }}
          className="fixed w-16 h-16 m-auto primary--loader left-50">
          <Dimmer
            active
            inverted
            className="w-20 h-20 bg-white rounded-full shadow-sm">
            <Loader></Loader>
          </Dimmer>
        </div>
        <div className="px-3 mx-auto" style={{ maxWidth: 1500 }}>
          <Popover className="relative bg-white">
            <div className="flex items-center justify-between py-2 md:space-x-10">
              <div className="flex justify-start">
                <NavLink
                  to={`/${user.user_name}`}
                  exact
                  activeClassName="important:primary"
                  className="flex items-center pr-3 rounded-full black Medium fs-13 whitespace-nowrap">
                  <div className="w-12 h-12 mr-2">
                    <Image
                      className="object-cover object-top w-full h-full rounded-xl"
                      src={
                        user.profile_photo
                          ? OptimizeImage(user.profile_photo)
                          : logo
                      }
                      alt="logo"
                    />
                  </div>
                  {Capitalize(user.first_name)} {Capitalize(user.last_name)}
                </NavLink>
              </div>
              <div className="flex items-center gap-3 -my-2 -mr-1 xl:hidden">
                <NavLink
                  to={`/search/${activeTab}`}
                  className="flex-row items-center hidden w-64 px-3 bg-gray-200 rounded-xl h-11 md:flex">
                  <input
                    placeholder="Search "
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="w-64 h-10 bg-transparent border-0 Regular black"
                    onChange={this.onSearch}
                  />
                  <div className="pl-1">
                    <GoSearch style={{ color: colors.darkGray }} />
                  </div>
                </NavLink>
                <Popover.Button className="flex items-center justify-center w-12 h-12 p-2 bg-gray-200 rounded-full">
                  <CgMenuGridO size={30} style={{ color: colors.black }} />
                </Popover.Button>
                <Popover className="relative">
                  <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
                    <FaBell
                      className="m-auto"
                      size={15}
                      style={{ color: colors.black }}
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute right-0 z-50 w-screen max-w-md mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative p-5 bg-white">
                          <Notification />
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
                <Popover className="relative">
                  <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
                    <TiArrowSortedDown
                      className="m-auto"
                      size={24}
                      style={{ color: colors.black }}
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute right-0 z-50 w-screen max-w-sm mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative p-4 bg-white">
                          <UserProfileComponent />
                          <div className="mt-1 divider" />
                          <Menuaccordion />
                          <Link
                            to="/feedback"
                            className="mt-1.5 p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer">
                            <div className="">
                              <VscFeedback size={18} className="black" />
                            </div>
                            <p className="ml-3">Feedback</p>
                          </Link>
                          <Link
                            to="/bill_of_rights"
                            className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer">
                            <div>
                              <IoHandRightSharp size={18} className="black" />
                            </div>
                            <p className="ml-3">Bill of Rights</p>
                          </Link>
                          {/* <Link
                            to="/tutorial"
                            className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
                          >
                            <div>
                              <RiSlideshow3Fill size={18} className="black" />
                            </div>
                            <p className="ml-3">How To</p>
                          </Link> */}
                          <div className="mt-1 divider" />
                          <div
                            onClick={() => this.logout()}
                            className="flex items-center p-1 m-1 rounded-lg cursor-pointer hover:bg-gray-200">
                            <div className="p-2 bg-gray-100 rounded-full">
                              <AiOutlineLogout size={18} className="black" />
                            </div>
                            <p className="ml-3">Logout</p>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
              <Popover.Group
                as="nav"
                className="items-center justify-between hidden w-full m-0 xl:flex">
                <div className="items-center flex-grow  xl:flex justify-evenly">
                  <NavLink
                    exact
                    activeClassName="active--link"
                    to="/"
                    className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative">
                    Social Feed
                  </NavLink>
                  <NavLink
                    exact
                    to="/friends"
                    activeClassName="active--link"
                    className=" white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative">
                    My Friends Only
                  </NavLink>

                  <NavLink
                    exact
                    to="/me"
                    className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative"
                    activeClassName="active--link">
                    My Postings
                  </NavLink>

                  <NavLink
                    to="/groups"
                    activeClassName="active--link"
                    className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative">
                    My Groups
                  </NavLink>
                </div>
                <div className="items-center justify-between gap-3  xl:flex">
                  <NavLink
                    to={`/search/${activeTab}`}
                    className="flex-row items-center hidden w-64 px-3 bg-gray-200 rounded-xl h-11 md:flex">
                    <input
                      placeholder="Search "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="w-64 h-10 bg-transparent border-0 Regular black"
                      onChange={this.onSearch}
                    />
                    <div className="pl-1">
                      <GoSearch style={{ color: colors.darkGray }} />
                    </div>
                  </NavLink>

                  <Popover className="relative">
                    <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
                      <FaBell
                        className="m-auto"
                        size={15}
                        style={{ color: colors.black }}
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel className="absolute right-0 z-50 w-screen max-w-md mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative p-3 bg-white">
                            <Notification />
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                  <Popover className="relative">
                    <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
                      <TiArrowSortedDown
                        className="m-auto"
                        size={24}
                        style={{ color: colors.black }}
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel className="absolute right-0 z-50 w-screen max-w-sm mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative p-3 bg-white">
                            <UserProfileComponent />
                            <div className="mt-1 divider" />
                            <Menuaccordion />
                            <div className="my-1 divider" />
                            <Popover.Button as="div">
                              <Link
                                to="/feedback"
                                className="mt-1.5 p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer">
                                <div className="">
                                  <VscFeedback size={18} className="black" />
                                </div>
                                <p className="ml-3">Feedback</p>
                              </Link>
                            </Popover.Button>
                            <Popover.Button as="div">
                              <Link
                                to="/bill_of_rights"
                                className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer">
                                <div>
                                  <IoHandRightSharp
                                    size={18}
                                    className="black"
                                  />
                                </div>
                                <p className="ml-3">Bill of Rights</p>
                              </Link>
                            </Popover.Button>
                            {/* <Popover.Button as="div">
                              <Link
                                to="/tutorial"
                                className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
                              >
                                <div>
                                  <RiSlideshow3Fill
                                    size={18}
                                    className="black"
                                  />
                                </div>
                                <p className="ml-3">How To</p>
                              </Link>
                            </Popover.Button> */}
                            <div className="mt-1 divider" />
                            <div
                              onClick={() => this.logout()}
                              className="flex items-center p-1 m-1 rounded-lg cursor-pointer hover:bg-gray-200">
                              <div className="p-2 bg-gray-100 rounded-full">
                                <AiOutlineLogout size={18} className="black" />
                              </div>
                              <p className="ml-3">Logout</p>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </Popover.Group>
            </div>
            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform xl:hidden">
                <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                  <div className="flex items-center justify-between">
                    <p className="p-3 text-2xl Medium">Menu</p>
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100">
                      <FiX size={28} />
                    </Popover.Button>
                  </div>
                  <Menu />
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ User, Group }) => ({
  User,
  activeTab: Group.activeSearchTab,
});
const mapDispatchToProps = (dispatch) => {
  const { actions } = require("redux/UserRedux");

  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Tailheader));
