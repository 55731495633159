import React, { Component } from "react";
import { Image, Form } from "react-bootstrap";
import { OptimizeImage } from "helpers";
import { avatar } from "assets";
import { connect } from "react-redux";
import { FiCamera } from "react-icons/fi";
import { ImCancelCircle } from "react-icons/im";
import colors from "config/color/color.js";
import { motion } from "framer-motion";
import { CommentSuggestion } from "components";

class PostCommentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submitComment = (e) => {
    if (e.key !== "Enter") return;
    e.preventDefault();
    if (this.props.commentText && !this.props.isPosting) {
      const data = {
        post_id: this.props.post_id,
        comment: this.props.commentText,
      };
      if (this.props.comment) {
        data["parent_comment_id"] = this.props.comment.id;
      }
      if (this.props.edit) {
        data["comment_id"] = this.props.edit.id;
      }
      this.props.postCommentByPostId(data);
      if (!this.props.comment) {
        data["plus"] = true;
        this.props.updateCommentCounter(data);
      }
    }
  };

  onFileChange = (e) => {
    const files = e.target.files;
    var formData = new FormData();
    const data = { post_id: this.props.post_id };
    formData.append("file", files[0]);
    formData.append("post_id", this.props.post_id);
    formData.append("comment", this.props.commentText);
    if (this.props.comment) {
      formData.append("parent_comment_id", this.props.comment.id);
    }
    this.props.postCommentByPostId(formData);
    if (!this.props.comment) {
      data["plus"] = true;
      this.props.updateCommentCounter(data);
    }
  };

  render() {
    const { edit, user, d_post_id } = this.props;
    return (
      <div>
        <motion.div className="table w-full pb-2 mt-1">
          <div className="table-cell w-12 h-12">
            <Image
              src={
                user.profile_photo ? OptimizeImage(user.profile_photo) : avatar
              }
              className="object-cover w-full h-full rounded-full"
            />
          </div>
          <div className="table-cell align-bottom">
            <Form
              className={`flex-grow ml-2 bg--lightGray  ${
                edit ? "border border-warning" : ""
              } flex items-center rounded-3xl pr-3`}>
              <CommentSuggestion
                submitComment={this.submitComment}
                d_post_id={d_post_id}
              />

              <div
                className="relative flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-gray-300"
                id="file">
                {!edit ? (
                  <>
                    <FiCamera
                      size={18}
                      color={colors.darkGray}
                      className="cursor-pointer"
                    />
                    <Form.Control
                      onChange={this.onFileChange}
                      type="file"
                      accept="image/*"
                      className="absolute right-0 z-10 w-10 border opacity-0 cursor-pointer bg-primary"
                    />
                  </>
                ) : (
                  <ImCancelCircle
                    onClick={this.props.clearEdit}
                    size={18}
                    color={colors.darkGray}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </Form>
          </div>
        </motion.div>
        {edit && <i className="pl-10 gray fs-12">Editing...</i>}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { post_id, comment } = ownProps;
  let d_post_id = post_id;
  if (comment) {
    d_post_id = comment.id;
  }
  return {
    user: state.User.user,
    commentText:
      typeof state.Comment.commentText[d_post_id] !== "undefined"
        ? state.Comment.commentText[d_post_id]
        : "",
    edit:
      state.Comment.edit && typeof state.Comment.edit[d_post_id] !== "undefined"
        ? state.Comment.edit[d_post_id]
        : null,
    emojiOpen: state.Comment.emojiOpen,
    isPosting: state.Comment.isPosting,
    post_id: post_id,
    d_post_id: d_post_id,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CommentRedux");
  const { actions: feedActions } = require("redux/FeedRedux");
  return {
    ...ownProps,
    ...stateProps,
    typeComment: (text) => {
      dispatch(actions.typeComment(text));
    },
    postCommentByPostId: (data) => {
      actions.postCommentByPostId(dispatch, data);
    },
    replyComment: (comment) => {
      dispatch(actions.replyComment(comment));
    },
    emojiToggle: (toggle) => {
      dispatch(actions.emojiToggle(toggle));
    },
    clearEdit: () => {
      dispatch(actions.clearEdit());
    },
    updateCommentCounter: (item) => {
      dispatch(feedActions.updateCommentCounter(item));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(PostCommentBox);
