import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";

export default function Enabled() {
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };
  return (
    <div className="flex items-center gap-x-10 mx-4 mb-10">
      <div className="flex items-center  justify-center gap-x-2 bg-white shadow-sm rounded-lg px-5 py-2">
        <Checkbox onChange={handleChange} checked={checked} color="primary" />
        <p className=" text-[14px] tracking-wider">Enable This Feature</p>
      </div>
      <h1>My Bar</h1>
    </div>
  );
}
