import React, { Component } from "react";
import colors from "config/color/color.js";
import { ImLocation } from "react-icons/im";
import Geocode from "react-geocode";

class Eventaboutaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
    };
  }
  componentDidMount() {
    this.getAddress(
      String(this.props.eventDetail.latitude),
      String(this.props.eventDetail.longitude)
    );
  }
  getAddress = (lat, lng) => {
    Geocode.setApiKey("AIzaSyBi1vr84ffSSHUhmtNdxea9sZNROw3QZho");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.setState({ address: address });
      },
      (error) => {
        console.error("error", error);
      }
    );
  };
  render() {
    const { eventDetail } = this.props;
    return (
      <div className="p-5 bg-white rounded-lg shadow-sm">
        <>
          <h3 className="text-lg Medium">About</h3>
          <p className="my-3 fs-14">{eventDetail.description}</p>
        </>
        {!!eventDetail.address && (
          <div>
            <div className="flex mb-3">
              <div className="mt-1 mr-3">
                <ImLocation size={18} color={colors.darkGray} />
              </div>
              <div className="">
                <p> {eventDetail.address}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Eventaboutaside;
