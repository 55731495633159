import React, { Component } from "react";
import { NavDropdown, Button } from "react-bootstrap";
import styles from "./styles.js";
import "./styles.css";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { FiXSquare } from "react-icons/fi";
import { VscCloseAll } from "react-icons/vsc";
import { BsThreeDots } from "react-icons/bs";
import { Capitalize } from "helpers";
import firebase from "firebase/app";
import "firebase/database";
import { connect } from "react-redux";
import { TimeAgo, Avatarimage } from "components";
import { LifeWidget } from "common";
import { Loader } from "semantic-ui-react";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    firebase
      .database()
      .ref("notifications/" + this.props.me.id)
      .orderByChild("created_at")
      .on("value", snapshot => {
        var li = [];
        snapshot.forEach(child => {
          li.push({
            key: child.key,
            value: child.val(),
          });
        });
        this.setState({ notifications: li.reverse(), loading: false });
      });
  }

  removeNotification = key => {
    firebase
      .database()
      .ref("notifications/" + this.props.me.id + "/" + key)
      .remove();
  };

  readNotificationToggle = (key, is_read) => {
    firebase
      .database()
      .ref("notifications/" + this.props.me.id + "/" + key)
      .update({ is_read: !is_read });
  };

  acceptFriendRequest = async (id, notificationKey) => {
    this.removeNotification(notificationKey);
    const json = await LifeWidget.acceptFriendRequest(id);
  };

  cancelFriendRequest = async (id, notificationKey) => {
    this.removeNotification(notificationKey);
    const json = await LifeWidget.cancelFriendRequest(id);
  };

  acceptFlirtRequest = async (id, notificationKey) => {
    const json = await LifeWidget.acceptFlirtRequest(id);
    this.removeNotification(notificationKey);
  };

  cancelFlirtRequest = async (id, notificationKey) => {
    const json = await LifeWidget.cancelFlirtRequest(id);
    this.removeNotification(notificationKey);
  };

  render() {
    const { notifications, loading } = this.state;
    const { fontSize } = this.props;
    return (
      <div className="Notification space-y-1 p-1">
        <div className="flex items-center justify-between">
          <p className={`${fontSize} Medium text-xl`}>Notification</p>
        </div>
        {notifications.length > 0 && (
          <div className="flex items-center justify-end">
            <Link
              to="/notifications"
              className="primary text-sm hover:bg-gray-100 p-1 rounded-lg"
            >
              See All
            </Link>
          </div>
        )}
        {this.state.loading == true ? (
          <div className="h-52 flex items-center justify-center">
            <Loader active={loading} inline />
          </div>
        ) : null}
        {notifications.map((item, key) => (
          <div key={key}>
            <div
              className={`${
                !item.value.is_read ? "bg-gray-100" : null
              } flex items-center hover:bg-gray-100 rounded-lg p-2 notifi_items relative`}
            >
              <Avatarimage
                className="h-16 w-16 rounded-full object-cover mr-2"
                item={item.value.user}
              />
              <div className="flex items-center ">
                <div>
                  <p style={styles.userName} className="black text-sm">
                    <strong className="Medium black text-base mr-1">
                      {Capitalize(item.value.user.first_name)}{" "}
                      {Capitalize(item.value.user.last_name)}
                    </strong>
                    {item.value.message}
                  </p>
                  <TimeAgo
                    className="gray Light text-sm"
                    created_at={item.value.created_at}
                  />
                  {item.value.type === "flirt_request" && (
                    <div className="flex items-center">
                      <Button
                        onClick={() =>
                          this.acceptFlirtRequest(item.value.user.id, item.key)
                        }
                        className="bg--lightPrimary shadow-none hover:opacity-50 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text w-full border-0 rounded--lg p-0 h-9"
                      >
                        Accept
                      </Button>
                      <Button
                        onClick={() =>
                          this.cancelFlirtRequest(item.value.user.id, item.key)
                        }
                        className="ml-4 bg--lightGray hover:bg--lightGray hover:opacity-50 focus:bg--lightGray black shadow-none block hover:black foucs:black w-full border-0 rounded--lg p-0 h-9"
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                  {item.value.type === "friend_request" && (
                    <div className="flex items-center">
                      <Button
                        onClick={() =>
                          this.acceptFriendRequest(item.value.user.id, item.key)
                        }
                        className="bg--lightPrimary shadow-none hover:opacity-50 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text w-full border-0 rounded--lg p-0 h-9"
                      >
                        Accept
                      </Button>
                      <Button
                        onClick={() =>
                          this.cancelFriendRequest(item.value.user.id, item.key)
                        }
                        className="ml-4 bg--lightGray hover:bg--lightGray hover:opacity-50 focus:bg--lightGray black shadow-none block hover:black foucs:black w-full border-0 rounded--lg p-0 h-9"
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                </div>

                <div className="notifi_dots">
                  <NavDropdown
                    title={
                      <div className="m-auto shadow-sm bg-white rounded-full h-8 w-8 flex items-center justify-center">
                        <BsThreeDots
                          size={18}
                          style={{ color: colors.black }}
                        />
                      </div>
                    }
                    style={styles.chipOpacity}
                    className="custom-dropdown right bg-transparent"
                  >
                    <div className="absolute z-10 right-0 mt-2 w-screen max-w-sm sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative bg-white p-2">
                          <div
                            onClick={() => this.removeNotification(item.key)}
                            className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer"
                          >
                            <FiXSquare className="mr-2" />
                            <p>Remove this notification</p>
                          </div>
                          <div
                            onClick={() =>
                              this.readNotificationToggle(
                                item.key,
                                item.value.is_read
                              )
                            }
                            className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer"
                          >
                            <VscCloseAll className="mr-2" />
                            <p>
                              Mark as {item.value.is_read ? "unread" : "read"}
                            </p>
                          </div>
                          {/* <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2">
                            <AiOutlineBug className="mr-2" />
                            <p>Report issue to Notification Team</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </NavDropdown>
                </div>
                {!item.value.is_read && (
                  <div className="notifi_unread">
                    <div style={styles.unReadBadge} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { me: state.User.user };
};

export default connect(mapStateToProps)(Notification);
