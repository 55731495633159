import React, { Component } from "react";
import { MdEdit } from "react-icons/md";
import { Dropdown } from "semantic-ui-react";
import colors from "config/color/color.js";
import { OptimizeImage } from "helpers";
import { Card } from "react-bootstrap";
import { IoTrashOutline } from "react-icons/io5";

class Photocards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item, is_owner } = this.props;
    return (
      <div
        onClick={() => this.props._open()}
        className="shadow-sm  border border-lightGray rounded--xl"
      >
        <div className="border-0 cursor-pointer relative">
          <Card.Img
            src={OptimizeImage(item.attachment_url)}
            alt={item.name}
            {...this.props}
            className="object-cover h-52 rounded--xl "
          />
          {is_owner && (
            <div className="absolute top-0 h-full w-full z-50 p-2 flex flex-row justify-end overlay:bg--gray-200 rounded--xl ">
              <div className="relative">
                <Dropdown
                  icon={false}
                  wrapSelection={true}
                  direction="left"
                  pointing="top"
                  text={
                    <div>
                      <div className="rounded-full bg-gray-600 flex items-center justify-center h-10 w-10 cursor-pointer">
                        <MdEdit size={18} color={colors.white} />
                      </div>
                    </div>
                  }
                >
                  <Dropdown.Menu
                    onClick={() => this.props._deleteMedia(item.id)}
                    className="rounded--lg bg-white shadow-lg overflow-show border-0 w-72"
                  >
                    <div className="p-2">
                      <div className="flex items-center py-2 hover:bg-gray-100 rounded-lg px-2 cursor-pointer">
                        <div className="mr-3">
                          <IoTrashOutline size={20} color={colors.black} />
                        </div>
                        <p>Delete</p>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Photocards;
