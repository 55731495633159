import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Image } from "react-bootstrap";
import { OnlineStatusXS } from "components";
import ConnectyCube from "connectycube";
import lastDate from "../../helpers/lastDate";
import Avatar from "../../helpers/avatar/avatar";
import { OptimizeImage } from "helpers";
import { BsThreeDots } from "react-icons/bs";

class Item extends Component {
  deleteChatDialog = () => {
    const { item } = this.props;
    this.props.deleteChatDialog(item.id);
  };

  render() {
    const { item } = this.props;
    let photo = null;
    if (item.type === 2) {
      if (item.photo) {
        try {
          photo = ConnectyCube.storage.privateUrl(item.photo);
        } catch (e) {}
      }
    } else {
      if (item.photo) {
        photo = OptimizeImage(item.photo);
      }
    }
    return (
      <div className="Notification relative hover:bg-gray-200 ">
        <div className=" notifi_items ">
          <div
            onClick={() => this.props.goToChat(item)}
            className="flex items-center py-2 rounded-lg cursor-pointer px-2"
          >
            <div className="relative">
              {/* {item.xmpp_type == "groupchat" && <p>Group</p>} */}
              {photo ? (
                <div className="h-14 w-14 rounded-full flex items-center justify-center mr-2 border-2 bg-gray-400">
                  <Image
                    src={photo}
                    className="h-full w-full rounded-full object-cover object-top"
                  />
                </div>
              ) : (
                <>
                  {item.xmpp_type == "groupchat" && (
                    <div
                      className={`${
                        item.xmpp_type == "groupchat" &&
                        "ml-5 bg--success w-8 h-8 rounded-full flex items-center justify-center"
                      }`}
                    >
                      <p className="white">G</p>
                    </div>
                  )}
                  <div
                    className={`${
                      item.xmpp_type == "groupchat" && "-mt-3 ml-2"
                    }`}
                  >
                    <Avatar
                      photo={""}
                      name={item.name}
                      size={item.xmpp_type == "groupchat" ? 30 : 50}
                      className="h-14 w-14 rounded-full object-cover mr-2 border-2 border-gray-400"
                    />
                  </div>
                </>
              )}
              <div className="absolute right-0">
                <OnlineStatusXS />
              </div>
            </div>
            <div className="flex-grow ">
              <div className="flex items-center justify-between">
                <p className="Medium ml-2 fs-14 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                  {item.name}
                </p>
                <p className=" fs-12">
                  {lastDate({
                    lastDate: item.last_message_date_sent,
                    lastMessage: item.last_message,
                    updatedDate: item.updated_date,
                  })}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="fs-13 ml-2 max-w-xs whitespace-nowrap overflow-ellipsis overflow-hidden">
                  {item.last_message === ""
                    ? "No messages yet"
                    : item.last_message}
                </p>

                {item.unread_messages_count > 0 && (
                  <div className="bg--primary w-4 h-4 flex items-center justify-center rounded-full">
                    <p className="fs-10 white">{item.unread_messages_count}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="notifi_dots z-10">
            <Popover className="relative">
              <Popover.Button className="m-auto shadow-sm bg-white rounded-full h-8 w-8 flex items-center justify-center">
                <BsThreeDots size={18} className="black" />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-50 -ml-4 mt-3 transform w-screen max-w-xs sm:px-0 lg:ml-0 right-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative bg-white p-2">
                      <div
                        onClick={this.deleteChatDialog}
                        className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer"
                      >
                        <p>Delete</p>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
          <div className="divider my-0 ml-auto max-w-sm" />
        </div>
      </div>
    );
  }
}

export default Item;
