import React, { Component } from "react";
import colors from "config/color/color.js";
import { NavLink } from "react-router-dom";
import { GiShakingHands } from "react-icons/gi";
import { MdFavorite, MdNearMe } from "react-icons/md";
import { HiOutlineArrowLeft, HiPresentationChartBar } from "react-icons/hi";
import { TiTicket } from "react-icons/ti";

class LiveMusicaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <div className="fixed flex flex-col p-6 bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow ">
          <div className="flex items-center">
            <NavLink
              to="/"
              className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
              <HiOutlineArrowLeft
                size={18}
                style={{ color: colors.darkGray }}
              />
            </NavLink>
            <div>
              <NavLink to="/" className="text-sm darkGray hover:underline">
                Main Menu
              </NavLink>
              <h2 className="text-2xl font-medium Medium">Live Music</h2>
            </div>
          </div>
          <div className="my-2 border-t" />
          <div className="mt-3 -mx-2">
            <NavLink
              exact
              to="/live-music/near"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <MdNearMe size={20} style={{ color: colors.white }} />
              </div>
              Near Me
            </NavLink>
            <NavLink
              exact
              to="/live-music/favourites"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <MdFavorite size={20} style={{ color: colors.white }} />
              </div>
              Favorites
            </NavLink>
            <NavLink
              exact
              to="/live-music/going"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <HiPresentationChartBar
                  size={20}
                  style={{ color: colors.white }}
                />
              </div>
              Going
            </NavLink>
            <NavLink
              exact
              to="/live-music/interested"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <GiShakingHands size={20} style={{ color: colors.white }} />
              </div>
              Interested in
            </NavLink>

            <NavLink
              exact
              to="/live-music/booked"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <TiTicket size={20} style={{ color: colors.white }} />
              </div>
              Book
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
export default LiveMusicaside;
