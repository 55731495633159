import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FiX } from "react-icons/fi";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  MailruShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  LinkedinIcon,
  MailruIcon,
  PinterestIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";
import { Config } from "common";

export default class Shareeventpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      repeat: "Repeat",
      item: {},
    };
    this.createEventRef = React.createRef();
    this.popupRef = React.createRef();
  }
  eventOpen = (item) => {
    this.setState({ open: true, item: item });
  };

  eventClose = () => {
    this.setState({ open: false });
  };

  repeatChange = (event) => {
    this.state(event.target.value);
  };

  render() {
    const url = Config.lifeWidget.url + "/events/" + this.state.item.slug;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.eventClose}
          scroll="body"
          aria-labelledby="draggable-dialog-title"
          className="custom-backdrop">
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            className="relative flex items-center justify-center px-3 py-3 border-b">
            <div
              onClick={this.eventClose}
              className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-5 hover:bg-gray-300">
              <FiX />
            </div>
            <h2 className="text-2xl font-medium Medium">Share Event</h2>
            {/* <p
              onClick={this.eventClose}
              className="absolute z-10 flex items-center justify-center w-20 h-10 cursor-pointer right-3 top-4 hover:bg-gray-100 rounded-xl primary"
              onClick = {this.validation}
            >
              Save
            </p> */}
          </DialogTitle>
          <DialogContent id="scrollbar" className="px-0 important:p-0">
            <div className="flex flex-row flex-wrap items-center justify-center p-10 gap-x-4 gap-y-4">
              <div>
                <FacebookShareButton
                  url={Config}
                  quote={"Hey join new event"}
                  hashtag="#React">
                  <FacebookIcon
                    size={40}
                    lightingColor="white"
                    round={true}></FacebookIcon>
                </FacebookShareButton>
              </div>
              <div>
                <FacebookShareButton url={url} quote={this.state.item?.name}>
                  <FacebookMessengerIcon
                    size={40}
                    lightingColor="white"
                    round={true}></FacebookMessengerIcon>
                </FacebookShareButton>
              </div>
              <div>
                <WhatsappShareButton url={url} quote={this.state.item?.name}>
                  <WhatsappIcon
                    size={40}
                    lightingColor="white"
                    round={true}></WhatsappIcon>
                </WhatsappShareButton>
              </div>
              <div>
                <PinterestShareButton url={url} quote={this.state.item?.name}>
                  <PinterestIcon
                    size={40}
                    lightingColor="white"
                    round={true}></PinterestIcon>
                </PinterestShareButton>
              </div>

              <div>
                <LinkedinShareButton url={url} quote={this.state.item?.name}>
                  <LinkedinIcon
                    size={40}
                    lightingColor="white"
                    round={true}></LinkedinIcon>
                </LinkedinShareButton>
              </div>
              <div>
                <EmailShareButton url={url} quote={this.state.item?.name}>
                  <EmailIcon
                    size={40}
                    lightingColor="white"
                    round={true}></EmailIcon>
                </EmailShareButton>
              </div>
              <div>
                <MailruShareButton url={url} quote={this.state.item?.name}>
                  <MailruIcon
                    size={40}
                    lightingColor="white"
                    round={true}></MailruIcon>
                </MailruShareButton>
              </div>
              <div>
                <TwitterShareButton url={url} quote={this.state.item?.name}>
                  <TwitterIcon
                    size={40}
                    lightingColor="white"
                    round={true}></TwitterIcon>
                </TwitterShareButton>
              </div>

              <div>
                <TelegramShareButton url={url} quote={this.state.item?.name}>
                  <TelegramIcon
                    size={40}
                    lightingColor="white"
                    round={true}></TelegramIcon>
                </TelegramShareButton>
              </div>
              <div>
                <ViberShareButton url={url} quote={this.state.item?.name}>
                  <ViberIcon
                    size={40}
                    lightingColor="white"
                    round={true}></ViberIcon>
                </ViberShareButton>
              </div>
              <div>
                <TelegramShareButton url={url} quote={this.state.item?.name}>
                  <TelegramIcon
                    size={40}
                    lightingColor="white"
                    round={true}></TelegramIcon>
                </TelegramShareButton>
              </div>

              <div>
                <InstapaperShareButton url={url} quote={this.state.item?.name}>
                  <InstapaperIcon
                    size={40}
                    lightingColor="white"
                    round={true}></InstapaperIcon>
                </InstapaperShareButton>
              </div>
              <div>
                <TumblrShareButton url={url} quote={this.state.item?.name}>
                  <TumblrIcon
                    size={40}
                    lightingColor="white"
                    round={true}></TumblrIcon>
                </TumblrShareButton>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
