import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";

class Flirtsdetailskeleton extends Component {
  render() {
    return (
      <>
        <div className="flex justify-between">
          <div className="hidden rounded-lg friends-side xl:block">
            <div className="fixed h-screen bg-white shadow-md group-leftside">
              <div className="px-4 pt-4">
                <div className="px-4 mt-3 -mx-2">
                  <div>
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={50}
                      className="mt-3 rounded-xl"
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full -ml-2">
            <div className="pb-4 bg-white">
              <div className="m-auto container--small xs:px-4 sm:px-4 ">
                <div className="overflow-hidden rounded-b-xl">
                  <div className="relative overflow-hidden shadow-sm h-96 rounded-b-xl">
                    <Skeleton
                      animation="pulse"
                      variant="rect"
                      height={"100%"}
                    />
                  </div>
                  <div className="text-center mt-2.5 flex items-end justify-end">
                    <Skeleton
                      variant="rect"
                      height={40}
                      className="mt-4 rounded-xl"
                      width={170}
                    />
                    <Skeleton
                      variant="rect"
                      height={40}
                      className="mt-4 ml-5 mr-5 rounded-full"
                      width={40}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flirtsdetailskeleton;
