import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from "react-router-dom";
import { OptimizeImage } from "helpers";
import { AiOutlineMail } from "react-icons/ai";

const filterGateway = (s) => {
    return s.replace(/_Rest/g, '');
}

export default function Item({ id, bar_id, onDelete, changeStatus }) {

    const [open, setOpen] = useState(false);

    const data = useSelector((state) => Object.assign({}, state.enhancement.payment.data?.[bar_id]?.[id]));
    if (!data.id) {
        return null;
    }

    const doDelete = () => {
        onDelete(data?.id);
    }
    return (
        <div>
            <div className="border-b border-gray-200 hover:bg-gray-100  grid grid-cols-4 gap-4">
                <div className="py-4 px-6 text-left whitespace-nowrap">

                    <p className="text-gray-400 pl-2 fs-12">{data?.title} </p>

                </div>
                <div className="py-4 px-6 text-center whitespace-nowrap">

                    <span class="bg-white text-black text-sm font-semibold mr-2 px-2.5 py-0.5 rounded">{filterGateway(data?.gateway)}</span>
                </div>
                <div className="py-4 px-6 text-center whitespace-nowrap">
                    <label class="inline-flex relative items-center cursor-pointer">
                        <input disabled={data?.status == 1 ? true : false} checked={data?.status == 1 ? true : false} type="checkbox" class="sr-only peer" onChange={() => changeStatus(data.id)} />
                        <div class="mr-2 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        {data?.status == 0 ? <span class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-800">Inactive</span> : <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800">Activated</span>}
                    </label>

                </div>
                <div className="py-4 px-6 text-center">
                    <div className="flex item-center justify-center">
                        {data?.status == 0 &&
                            <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer" onClick={doDelete}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                </svg>
                            </div>
                        }

                    </div>
                </div>
            </div >
        </div>
    );
}
