import React, { Component } from "react";
import "./styles.css";
import { Dropdown } from "semantic-ui-react";

import colors from "config/color/color.js";
import { IoIosCamera } from "react-icons/io";
import { BsPersonSquare } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { connect } from "react-redux";
import { Avatarimage, Thumbnailsimage } from "components";

class Profileheader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.photoRef = React.createRef();
    this.coverRef = React.createRef();
  }

  onPhotoClick = () => {
    this.photoRef.current.click();
  };

  onCoverClick = () => {
    this.coverRef.current.click();
  };

  onPhotoChange = e => {
    const data = e.target.files[0];
    if (data) {
      this.uploadPhoto(data);
    }
  };

  onCoverChange = e => {
    const data = e.target.files[0];
    if (data) {
      this.uploadBanner(data);
    }
  };

  uploadPhoto = async data => {
    var formData = new FormData();
    formData.append("profile_photo", data);
    this.props.uploadProfilePhoto(formData);
  };

  uploadBanner = data => {
    var formData = new FormData();
    formData.append("profile_banner", data);
    this.props.uploadProfileBanner(formData);
  };

  render() {
    let { data, user } = this.props;
    return (
      <div className="bg-white headercover">
        <div className="container--small m-auto xs:px-4 sm:px-4 ">
          <div>
            <div className="h-96 shadow-sm rounded-b-lg relative">
              <div className="rounded-b-lg overlay:bg--gray-200 absolute h-full w-full" />
              <Thumbnailsimage
                item={data}
                imageSize={{
                  width: "100%",
                  height: 336,
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              />
            </div>
            <div>
              <div className="flex items-center justify-center -mt-40">
                <Dropdown
                  icon={false}
                  direction="left"
                  pointing="center"
                  text={
                    <div>
                      <div className="bg-white border-5 border-white rounded-full w-48 h-48 overflow-hidden flex items-center justify-center">
                        <Avatarimage
                          item={data}
                          variant="circle"
                          style={{ width: 170, height: 170 }}
                        />
                      </div>
                      {data.id === user.id && (
                        <div className="shadow-sm bg-gray-200 mx-auto -right-16 cursor-pointer -mt-14 rounded-full z-10 flex items-center justify-center relative w-10 h-10">
                          <IoIosCamera
                            size={24}
                            style={{ color: colors.black }}
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  <Dropdown.Menu className="rounded--lg bg-white shadow-lg overflow-show border-0 w-72 -mr-20">
                    {data.id === user.id && (
                      <div className="relative  p-2">
                        {/* <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                        <div className="mr-3">
                          <FaPhotoVideo size={18} />
                        </div>
                        <p>View Profile Pictrue</p>
                      </div> */}
                        <div
                          onClick={this.onPhotoClick}
                          className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer"
                        >
                          <div className="mr-3">
                            <BsPersonSquare size={18} />
                          </div>
                          <p>Update Profile Picture</p>
                        </div>
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {data.id === user.id && (
                <>
                  <div className="flex items-end justify-end -mt-20 mr-10">
                    <Dropdown
                      icon={false}
                      wrapSelection={true}
                      direction="left"
                      pointing="top"
                      text={
                        <div>
                          <div className="shadow-sm flex items-center bg-white rounded-lg p-2 px-4  transform transition duration-300 hover:scale-90 hover:opacity-90">
                            <IoIosCamera className="mr-2" size={20} />
                            <p className="Regular font-semibold">
                              Edit Cover Photo
                            </p>
                          </div>
                        </div>
                      }
                    >
                      <Dropdown.Menu className="rounded--lg bg-white shadow-lg overflow-show border-0 w-96">
                        <div className="relative  p-2">
                          {/* <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                        <div className="mr-3">
                          <FaPhotoVideo size={18} />
                        </div>
                        <p>Select Photo</p>
                      </div> */}
                          <div
                            onClick={this.onCoverClick}
                            className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer"
                          >
                            <div className="mr-3">
                              <FiUpload size={18} />
                            </div>
                            <p>Upload cover photo</p>
                          </div>
                          {/* <div className="divider my-1" />
                      <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                        <div className="mr-3">
                          <IoTrashOutline size={18} />
                        </div>
                        <p>Remove</p>
                      </div> */}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
            </div>
            <div
              style={{ marginTop: data.id === user.id && 45 }}
              className="text-center pt-3"
            >
              <h1 className="black text-3xl Medium">
                {data.first_name} {data.last_name}
              </h1>
            </div>
          </div>
        </div>
        <input
          style={{ display: "none" }}
          accept="image/*"
          type="file"
          onChange={this.onPhotoChange}
          ref={this.photoRef}
        />
        <input
          style={{ display: "none" }}
          accept="image/*"
          type="file"
          onChange={this.onCoverChange}
          ref={this.coverRef}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
    photo_uploading: state.Profile.photo_uploading,
    banner_uploading: state.Profile.banner_uploading,
    video_uploading: state.Profile.video_uploading,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
    uploadProfilePhoto: data => {
      actions.uploadProfilePhoto(dispatch, data);
    },
    uploadProfileBanner: data => {
      actions.uploadProfileBanner(dispatch, data);
    },
    uploadIntroVideo: data => {
      actions.uploadIntroVideo(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Profileheader);
