export default {
  primary: "#685BC7",
  lightPrimary: "#E7F3FF",
  black: "#1A1A1A",
  white: "#fff",
  darkGray: "#65676b",
  gray: "#9DA4B4",
  lightGray: "#EDF0F4",
  warning: "#e67e22",
  danger: "#e74c3c",
  muted: "#95a5a6",
  success: "#2ecc71",
  gold: "#FFD700",
  orange: "#ff6720",
  green100: "#a7e163",
  red100: "#EE2737",
};
