import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { OrdersAside } from "Modules";
import { MdGroupAdd, MdPayment } from "react-icons/md";
import Payment from "./Payment";
import Item from "./Item";
import DeleteModal from "./DeleteModal";

const { actions } = require("redux/BarEnhancement");

export default function Setting(props) {
    const { id } = useParams();
    const dispatch = useDispatch();

    const paymentRef = useRef(null);
    const modalRef = useRef(null);

    const settingData = useSelector((state) => Object.assign({}, state.enhancement.setting.data));
    const setting = (settingData?.[id] || {});

    const paymentData = useSelector((state) => Object.assign({}, state.enhancement.payment.ids));

    const data = (paymentData?.[id] || []);

    const onChangeSetting = (field, value) => {
        if (value < 0) {
            value = 0;
        }
        if (value > 100) {
            value = 100;
        }
        const data = {
            field: field,
            value: value,
            bar_id: id
        }
        actions.submitBarSetting(dispatch, data);
    }

    const addPayment = () => {
        paymentRef.current.open(id);
    }

    const changeStatus = (data) => {
        const params = {
            bar_id: id,
        }
        let post = { id: data, bar_id: id };
        actions.changePaymentStatus(dispatch, params, data, post);
    }

    const onDelete = (payment_id) => {
        modalRef.current.open(id, payment_id);
    }

    useEffect(() => {

        actions.fetchPayment(dispatch, { bar_id: id });

    }, [id]);

    return (
        <div className="flex justify-between">
            <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
                <OrdersAside title="Setting" />
            </div>
            <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
                <div className="m-auto w-full space-y-5">
                    <div className="overflow-y-auto" id="scrollbar">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-x-10 mx-4 mb-10">
                                <h1>Settings</h1>
                            </div>
                        </div>
                        <div className="mb-10">

                            <div className="ml-10 mt-10">
                                <h2 className="text-gray-600">Tax Rate</h2>
                            </div>

                            <div className="grid grid-cols-2 gap-4 mx-4 mt-2">
                                <div className="flex items-center gap-x-3">
                                    <p className="min-w-[100px] text-right">Tax: </p>
                                    <div className="w-full flex items-center">
                                        <input
                                            type="number"
                                            placeholder="Enter tax"
                                            min={0}
                                            max={100}
                                            value={setting.tax_rate}
                                            onChange={(e) => onChangeSetting('tax_rate', e.target.value)}
                                            className="shadow-md border-0 text-black rounded--xl h-12 w-full px-5 bg-white"
                                        />
                                        <span className="ml-2">%</span>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="grid grid-cols-2 gap-4 mx-4 mt-4">
                                <div className="flex items-center gap-x-3">
                                    <p className="min-w-[100px] text-right">Stripe Payment</p>
                                    <div className="w-full flex items-center">
                                        <input
                                            type="text"
                                            placeholder="Enter Stripe private key"
                                            value={setting.stripe_private_key}
                                            onChange={(e) => onChangeSetting('stripe_private_key', e.target.value)}
                                            className="shadow-md border-0 text-black rounded--xl h-12 w-full px-5 bg-white"
                                        />
                                    </div>
                                </div>
                            </div> */}

                            <div className="ml-10 mt-10">
                                <h2 className="text-gray-600">Payment Gateway</h2>
                            </div>

                            <div className="flex items-center justify-center">

                                <div className="mb-3 rounded-lg w-full ml-10  mr-10">
                                    <div className="flex items-center justify-end" >
                                        <div className="flex flex-row cursor-pointer mb-4" onClick={addPayment}>
                                            <MdPayment size={20} className="mr-2 text-blue-800" />
                                            <p class="text-blue-800 " >Add payment</p>
                                        </div>
                                    </div>
                                    <div className="rounded-lg ">

                                        <div className="bg-gray-200 text-black capitalize leading-normal grid grid-cols-4 gap-4">
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Title
                                            </div>
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Gateway
                                            </div>
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Status
                                            </div>
                                            <div className="py-4 px-6 text-center text-[13px]">
                                                Actions
                                            </div>
                                        </div>
                                        <div className="bg-white">
                                            {data && data.length > 0 && data.map((item, key) => (
                                                <Item key={key} id={item} bar_id={id} onDelete={onDelete} changeStatus={changeStatus} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
            </div>
            <Payment ref={paymentRef} />
            <DeleteModal ref={modalRef} />
        </div>
    );
}
