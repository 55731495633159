import React, { Component } from "react";
import { Button } from "react-bootstrap";
import colors from "config/color/color.js";
import { Peopleimage, Peoplenamepopup } from "components";

class Sentinvitesaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item } = this.props;
    if (this.props.item.is_friend) return null;
    return (
      <div className="flex items-center p-2 rounded-lg hover:bg-gray-200">
        <Peopleimage
          item={item}
          {...this.props}
          imageStyle="h-16 w-16 rounded-full mr-3"
        />
        <div className="flex-grow">
          <Peoplenamepopup item={item} />

          {!item.is_friend &&
            item.request.is_send_request === false &&
            item.request.is_entry && (
              <div className="flex items-center gap-2 mt-2">
                <Button className="flex-grow h-10 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary rounded--lg">
                  Confirm
                </Button>
                <Button
                  style={{ color: colors.black }}
                  className="flex-grow h-10 border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray rounded--lg">
                  Delete
                </Button>
              </div>
            )}
          {!item.is_friend &&
            item.request.is_send_request &&
            item.request.is_entry && (
              <div className="items-center gap-2 mt-2">
                <Button
                  onClick={() => this.props.cancelFriendRequest(item.id)}
                  style={{ color: colors.black }}
                  className="flex-grow w-full h-10 border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray rounded--lg">
                  Cancel friend request
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default Sentinvitesaside;
