import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FiRepeat, FiX } from "react-icons/fi";
import { IoMusicalNotesOutline } from "react-icons/io5";
import colors from "config/color/color.js";
import { MdAttachFile, MdTitle, MdDescription } from "react-icons/md";
import { FriendListPopup } from "components";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FaDollarSign } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { BsPlusLg } from "react-icons/bs";
import { connect } from "react-redux";
import { OptimizeImage } from "helpers";

const checkFileExist = (file) => {
  try {
    return URL.createObjectURL(file);
  } catch (e) {
    return "";
  }
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

class Createbandpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      range: false,
      inputLink: false,
    };
    this.createEventRef = React.createRef();
    this.popupRef = React.createRef();
    this.photoRef = React.createRef();
  }

  componentDidMount() {}
  eventOpen = (item) => {
    let form = {};
    if (item) {
      form.id = item.id;
      form.band_name = item.band_name;
      form.band_type = item.band_type;
      form.band_music_type = item.band_music_type;
      form.band_description = item.band_description;
      form.band_rate = item.band_rate;
      form.band_min_price = item.band_min_price;
      form.band_max_price = item.band_max_price;
      form.band_external_link = item.band_external_link;

      var photos = [];
      if (item.attachments && item.attachments.length > 0) {
        item.attachments.map((item, key) => {
          photos.push({
            ...item,
            uri: OptimizeImage(item.attachment_url),
          });
        });
      }
      form.photos = photos;
    }
    this.props.addBandForm(form);

    this.setState({ open: true });
  };

  eventClose = () => {
    this.setState({ open: false });
  };

  repeatChange = (event) => {
    this.state(event.target.value);
  };

  checkWholeDay = () => {
    let all_day_status = this.state.all_day === true ? false : true;
    this.setState({ all_day: all_day_status });
    if (this.state.all_day === true) {
      this.props.removeTime();
    }
  };
  onPhotoClick = () => {
    this.photoRef.current.click();
  };
  onPhotoChange = (e) => {
    let photos = Object.assign([], this.props.form.photos);
    const files = e.target.files;
    photos = [...photos, ...files];
    this.addBandForm("photos", photos);
  };
  removePhoto = (index) => {
    let photos = [...this.props.form.photos];
    photos.splice(index, 1);
    this.addBandForm("photos", photos);
  };
  showRange = () => {
    this.setState({ range: true });
  };
  hideRande = () => {
    this.setState({ range: false });
  };
  showInputForLink = () => {
    this.setState({ inputLink: true });
  };
  hideInputLink = () => {
    this.setState({ inputLink: false });
  };

  addBandForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addBandForm(form);
  };

  validation = () => {
    let flag = true;
    let array = [
      "band_name",
      "band_type",
      "band_description",
      "band_rate",
      "band_min_price",
      "photos",
    ];
    let form = Object.assign({}, this.props.form);
    array.map((item, key) => {
      if (!Object.keys(form).some((index) => index === item)) {
        this.setState({ [item]: true });
        flag = false;
      }
    });
    Object.keys(form).map((item) => {
      if (array.some((index) => index === item)) {
        if (
          form[item] === null ||
          form[item] === "null" ||
          form[item] === "" ||
          form[item].length === 0 ||
          (typeof form[item] === "string" && form[item].trim() === "")
        ) {
          this.setState({ [item]: true });
          flag = false;
        } else {
          this.setState({ [item]: false });
        }
      }
    });
    let formData = new FormData();
    if (flag) {
      let form = this.props.form;
      Object.keys(form).map(function (key) {
        if (key === "photos" && form[key]) {
          form[key].forEach((item, i) => {
            formData.append("photos[" + i + "]", item);
            if (item.id) {
              formData.append("attachments_ids[" + i + "]", item.id);
            }
          });
        } else {
          formData.append(key, form[key]);
        }
      });
      this.props.submitBand(formData).then((res) => {
        this.setState({ open: false });
      });
    }
  };
  deleteBand = () => {
    const form = Object.assign({}, this.props.form);
    this.props.deleteBand(form.id);
  };
  render() {
    const { form } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.eventClose}
          PaperComponent={PaperComponent}
          scroll="body"
          aria-labelledby="draggable-dialog-title"
          className="custom-backdrop my-brand"
          fullWidth={true}>
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            className="relative flex items-center justify-center px-3 py-3 border-b">
            <div
              onClick={this.eventClose}
              className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-5 hover:bg-gray-300">
              <FiX />
            </div>
            <h2 className="text-2xl font-medium Medium">Create Band</h2>
            <p
              className="absolute z-10 flex items-center justify-center w-20 h-10 cursor-pointer right-3 top-4 hover:bg-gray-100 rounded-xl primary"
              onClick={this.validation}>
              Save
            </p>
          </DialogTitle>
          <DialogContent id="scrollbar" className="px-0 pb-4">
            <div
              style={
                this.state.band_name
                  ? { borderColor: "red" }
                  : { borderColor: "#fff" }
              }
              className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
              <div className="mr-2">
                <MdTitle color={colors.darkGray} />
              </div>
              <input
                placeholder="Band Name"
                className="w-full bg-transparent border-0 h-11"
                onChange={(e) => this.addBandForm("band_name", e.target.value)}
                value={form.band_name}
              />
            </div>
            <div
              style={
                this.state.band_type
                  ? { borderColor: "red" }
                  : { borderColor: "#fff" }
              }
              className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
              <IoMusicalNotesOutline
                size={14}
                color={colors.darkGray}
                className="mr-2"
              />
              <input
                onChange={(e) => this.addBandForm("band_type", e.target.value)}
                placeholder="Type of Band"
                className="w-full bg-transparent border-0 h-11"
                value={form.band_type}
              />
            </div>
            <div
              style={
                this.state.band_description
                  ? { borderColor: "red" }
                  : { borderColor: "#fff" }
              }
              className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
              <MdDescription
                size={14}
                color={colors.darkGray}
                className="mr-2"
              />
              <input
                onChange={(e) =>
                  this.addBandForm("band_description", e.target.value)
                }
                placeholder="About Us"
                className="w-full bg-transparent border-0 h-11"
                value={form.band_description}
              />
            </div>

            <div>
              <div className="flex items-center mt-3.5">
                <div className="flex items-center justify-center w-10 h-10 mr-2 bg-gray-100 rounded-full">
                  <FiRepeat color={colors.darkGray} />
                </div>
                <div className="flex items-center w-52">
                  <InputLabel className="mr-2">Rates: </InputLabel>
                  <Select
                    style={
                      this.state.band_rate
                        ? { borderColor: "red" }
                        : { borderColor: colors.lightGray }
                    }
                    className="overflow-hidden bg-gray-100 rounded-lg border-1 w-52"
                    onChange={(e) =>
                      this.addBandForm("band_rate", e.target.value)
                    }
                    value={form.band_rate}>
                    <MenuItem onClick={this.hideRande} value={"fixed"}>
                      Fixed
                    </MenuItem>
                    <MenuItem onClick={this.hideRande} value={"hourly"}>
                      Hourly
                    </MenuItem>
                    <MenuItem onClick={this.showRange} value={"range"}>
                      Range
                    </MenuItem>
                  </Select>
                </div>
              </div>
              {this.state.range && (
                <div className="flex">
                  <div
                    style={
                      this.state.band_min_price
                        ? { borderColor: "red" }
                        : { borderColor: "#fff" }
                    }
                    className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
                    <FaDollarSign
                      size={14}
                      color={colors.darkGray}
                      className="mr-2"
                    />
                    <input
                      onChange={(e) =>
                        this.addBandForm("band_min_price", e.target.value)
                      }
                      placeholder="Price"
                      className="w-full bg-transparent border-0 h-11"
                      value={form.band_min_price}
                    />
                  </div>
                  <div className="mt-6">
                    <h3>to</h3>
                  </div>

                  <div className="flex flex-row items-center px-3 mt-3 bg-gray-100 rounded-xl h-11">
                    <FaDollarSign
                      size={14}
                      color={colors.darkGray}
                      className="mr-2"
                    />
                    <input
                      onChange={(e) =>
                        this.addBandForm("band_max_price", e.target.value)
                      }
                      placeholder="Price"
                      className="w-full bg-transparent border-0 h-11"
                      value={form.band_max_price}
                    />
                  </div>
                </div>
              )}
              {!this.state.range && (
                <div
                  style={
                    this.state.band_min_price
                      ? { borderColor: "red" }
                      : { borderColor: "#fff" }
                  }
                  className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
                  <FaDollarSign
                    size={14}
                    color={colors.darkGray}
                    className="mr-2"
                  />
                  <input
                    onChange={(e) =>
                      this.addBandForm("band_min_price", e.target.value)
                    }
                    placeholder="Price"
                    className="w-full bg-transparent border-0 h-11"
                    value={form.band_min_price}
                  />
                </div>
              )}
              {this.state.inputLink && (
                <div className="flex flex-row items-center px-3 mt-3 bg-gray-100 rounded-xl h-11">
                  <input
                    onChange={(e) =>
                      this.addBandForm("band_external_link", e.target.value)
                    }
                    placeholder="https://www.externallink.com"
                    className="w-full bg-transparent border-0 h-11"
                  />
                  <a href="#">
                    <RiDeleteBinLine
                      onClick={this.hideInputLink}
                      size={18}
                      color={colors.darkGray}
                      className="mr-2"
                    />
                  </a>
                </div>
              )}
              <div className="flex flex-row items-center mt-3">
                <a href="#" className="flex flex-row items-center gap-x-2">
                  <BsPlusLg className="primary" size={12} />
                  <h5
                    onClick={this.showInputForLink}
                    className="primary cursor">
                    Add Youtube Link
                  </h5>
                </a>

                {/* <input
                      onChange={(e) => this.addAddress(e.target.value)}
                      placeholder="Price"
                      className="w-full bg-transparent border-0 h-11"
                      /> */}
              </div>

              <div className="my-3 divider" />
              <div className="flex">
                <div className="flex items-center w-48P">
                  <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-100 rounded-full">
                    <MdAttachFile size={16} color={colors.darkGray} />
                  </div>
                  <div
                    id="file"
                    className="relative overflow-hidden cursor-pointer w-52"
                    onClick={this.onPhotoClick}>
                    <p
                      style={this.state.photos ? { color: "red" } : {}}
                      className="darkGray fs-14">
                      Add attachment
                    </p>
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      onChange={this.onPhotoChange}
                      ref={this.photoRef}
                    />
                    {/* <Form.Control
                    type="file"
                    multiple
                    className="absolute bottom-0 left-0 h-20 opacity-0 cursor-pointer w-52"
                  /> */}
                  </div>
                </div>
                <div className="grid gap-4 mt-3 lg:grid-cols-3">
                  {form &&
                    form.photos &&
                    form.photos.map((item, key) => (
                      <div className="p-2 mt-3 overflow-hidden bg-white border shadow-md rounded-xl">
                        <div className="relative flex items-center justify-center h-20 bg-gray-100 cursor-pointer rounded-xl">
                          <img
                            src={item.uri ? item.uri : checkFileExist(item)}
                            alt={item.name}
                          />
                          <div className="bg--danger w-4 h-4 rounded-full flex items-center justify-center cursor-pointer absolute -top-1.5 -right-1.5">
                            <FiX
                              onClick={() => this.removePhoto(key)}
                              color={colors.white}
                              size={12}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <FriendListPopup
          ref={this.popupRef}
          title="Invite friends"
          isCreateGroupInvite={true}
          isTag={true}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    form: state.Band.form,
    isProcessing: state.Band.isProcessing,
    error: state.Band.error,
    message: state.Band.message,
    total: state.Band.total,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/BandRedux");
  return {
    ...ownProps,
    ...stateProps,
    addBandForm: (data) => {
      dispatch(actions.addBandForm(data));
    },
    submitBand: (data) => {
      return actions.submitBand(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Createbandpopup);
