import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiUserPlus } from "react-icons/fi";
import { Image, Button, Nav, Tab } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import hundred from "assets/images/reaction/100.png";
import angry from "assets/images/reaction/angry.gif";
import haha from "assets/images/reaction/haha.gif";
import love from "assets/images/reaction/love.gif";
import rolling from "assets/images/reaction/rolling.gif";
import sad from "assets/images/reaction/sad.gif";
import wow from "assets/images/reaction/wow.gif";
import { avatar } from "assets";
import { Link } from "react-router-dom";
import { Capitalize } from "helpers";

class Reactionsdetailpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reactions: false,
    };
    this.per_page = 10;
    this.page = 1;
  }

  reactionsPopupOpen = () => {
    this.setState({ reactions: true });
  };

  reactionsPopupClose = () => {
    this.setState({ reactions: false });
  };

  render() {
    let { item, user } = this.props;
    return (
      <Dialog
        open={this.state.reactions}
        onClose={this.reactionsPopupClose}
        scroll="body"
        className="custom-backdrop"
        fullWidth="sm">
        <div className="p-0 " id="scrollbar">
          <div className="relative access-info-tabs">
            <Tab.Container defaultActiveKey="All">
              <Nav
                variant="pills"
                className="sticky top-0 z-10 flex-row w-full p-2 bg-white border-b h-14">
                <Nav.Item>
                  <Nav.Link className="pb-3" eventKey="All">
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="hundred">
                    <Image src={hundred} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="angry">
                    <Image src={angry} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="haha">
                    <Image src={haha} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="love">
                    <Image src={love} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="rolling">
                    <Image src={rolling} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="sad">
                    <Image src={sad} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ paddingBottom: 12 }} eventKey="wow">
                    <Image src={wow} className="object-cover w-6 h-6" />
                  </Nav.Link>
                </Nav.Item>
                <div
                  onClick={this.reactionsPopupClose}
                  className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-2 top-2 hover:bg-gray-300">
                  <FiX size={24} style={{ color: colors.darkGray }} />
                </div>
              </Nav>
              <Tab.Content className="p-3 overflow-auto h-96" id="scrollbar">
                <Tab.Pane eventKey="All">
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center">
                      <div className="relative">
                        <Image
                          src={avatar}
                          className="object-contain mr-2 h-14 w-14"
                        />
                        <div className="absolute bottom-0 right-0 flex items-center justify-center object-cover w-6 h-6 bg-white rounded-full shadow-sm">
                          <Image
                            src={sad}
                            className="object-cover w-5 h-5 rounded-full"
                          />
                        </div>
                      </div>
                      <p className="Medium">
                        <Link
                          to={`/${item.user.user_name}`}
                          className="Medium black">
                          {Capitalize(item.user.first_name)}{" "}
                          {Capitalize(item.user.last_name)}
                        </Link>
                      </p>
                    </div>
                    <Button className="flex items-center px-4 border-0 shadow-none Regular bg--lightGray focus:bg--lightGray hover:bg--lightGray hover:opacity-80 rounded--xl black hover:black focus:black">
                      <FiUserPlus className="mr-2" />
                      Add Friend
                    </Button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="hundred">hundred</Tab.Pane>
                <Tab.Pane eventKey="angry">angry</Tab.Pane>
                <Tab.Pane eventKey="haha">haha</Tab.Pane>
                <Tab.Pane eventKey="love">love</Tab.Pane>
                <Tab.Pane eventKey="rolling">rolling</Tab.Pane>
                <Tab.Pane eventKey="sad">sad</Tab.Pane>
                <Tab.Pane eventKey="wow">wow</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default Reactionsdetailpopup;
