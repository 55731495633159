import React, { Component } from "react";
import { BsThreeDots } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { Dropdown } from "semantic-ui-react";
import colors from "config/color/color.js";
import Checkbox from "@material-ui/core/Checkbox";
import { FiCheck, FiHelpCircle, FiMessageCircle, FiX } from "react-icons/fi";
import { AiOutlineComment } from "react-icons/ai";
import { Candidatedetailpopup } from "components";

class Candidatescard extends Component {
  constructor(props) {
    super(props);
    this.candidatePopupRef = React.createRef();
  }

  render() {
    return (
      <div className="bg-white shadow-sm rounded-xl ">
        <div className="grid gap-2 p-3 bg-gray-200 lg:grid-cols-3 rounded-t-xl">
          <div className="">
            <Checkbox />
          </div>
          <div className="flex items-center justify-center">
            <h4>Matches to job post</h4>
          </div>
          <div className="flex items-center justify-center">
            <h4>Matches to job post</h4>
          </div>
        </div>
        <div className="grid gap-2 px-2 lg:grid-cols-3 hover:bg-gray-100">
          <div
            className="py-4 cursor-pointer "
            onClick={() => this.candidatePopupRef.current.candidatePopupOpen()}>
            <div className="flex items-center">
              <Checkbox />
              <div className="ml-3">
                <p className="primary Medium">David Corner Pink</p>
                <p className="darkGray fs-12">Lahore</p>
                <p className="darkGray fs-12">Created: March 9, 2021</p>
              </div>
            </div>
          </div>
          <div
            onClick={() => this.candidatePopupRef.current.candidatePopupOpen()}
            className="flex items-center justify-center h-full py-4 cursor-pointer ">
            <p className="darkGray fs-12">N/A</p>
          </div>
          <div className="py-4 ">
            <div className="flex items-center justify-center h-full">
              <div className="mr-4 w-50 xl:ml-5 xl:pl-10">
                <div className="w-full">
                  <div className="flex items-center overflow-hidden bg-white border rounded-xl">
                    <div className="flex items-center justify-center flex-grow h-10 cursor-pointer hover:bg-gray-200">
                      <FiCheck size={20} color={colors.black} />
                    </div>
                    <div className="flex items-center justify-center flex-grow h-10 border-l border-r cursor-pointer hover:bg-gray-200">
                      <FiHelpCircle size={20} color={colors.black} />
                    </div>
                    <div className="flex items-center justify-center flex-grow h-10 cursor-pointer hover:bg-gray-200">
                      <FiX size={20} color={colors.black} />
                    </div>
                  </div>
                </div>
              </div>

              <Dropdown
                icon={false}
                direction="left"
                pointing="left"
                text={
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
                      <BsThreeDots size={18} color={colors.black} />
                    </div>
                  </div>
                }>
                <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                  <div className="p-2">
                    <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-3 rounded-full w-7 h-7 bg--primary">
                        <FiMessageCircle color={colors.white} />
                      </div>
                      <p>Message</p>
                    </div>
                    <div
                      onClick={() =>
                        this.candidatePopupRef.current.candidatePopupOpen()
                      }
                      className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-3 rounded-full w-7 h-7 bg--primary">
                        <AiOutlineComment color={colors.white} />
                      </div>{" "}
                      <p>Set up Interview</p>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                      <div className="flex items-center justify-center mr-3 rounded-full w-7 h-7 bg--primary">
                        <IoTrashOutline color={colors.white} />
                      </div>{" "}
                      <p>Delete Candidate</p>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="divider" />
        <Candidatedetailpopup ref={this.candidatePopupRef} {...this.props} />
      </div>
    );
  }
}

export default Candidatescard;
