import React, { Component } from "react";
import { Skeleton } from "@material-ui/lab";
import { Postskeleton } from "components";

class Suspenseskeleton extends Component {
  render() {
    return (
      <div className="flex justify-between px-2" style={{ paddingTop: 20 }}>
        <div className="hidden rounded-lg left-side md:block">
          <div className="px-3 pb-0 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-0 mt-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-0 mt-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-0 mt-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pure-timeline-container overflow-show xs:px-4 sm:px-4 2xl:px-20 xl:px-20 lg:px-20 md:px-5">
          <>
            <div className="mb-3">
              <Postskeleton />
            </div>
            <div className="mb-3">
              <Postskeleton />
            </div>
          </>
        </div>
        <div className="hidden rounded-lg right-side xl:block overflow-show">
          <div className="px-3 pb-0 mb-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-0 mb-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-0 mb-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-0 mb-3 overflow-hidden bg-white rounded-lg shadow-sm">
            <div className="-mx-4">
              <Skeleton variant="rect" height={170} />
            </div>
            <div className="items-center my-3">
              <div className="flex items-center justify-between flex-grow">
                <div>
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={200}
                  />
                </div>
                <div className="ml-3">
                  <Skeleton
                    animation="pulse"
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={40}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between flex-grow mt-2">
                <div>
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="rounded-full"
                    width={150}
                  />
                  <Skeleton
                    variant="rect"
                    height={8}
                    className="mt-2 rounded-full"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Suspenseskeleton;
