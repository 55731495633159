import * as React from "react";
import { Button } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import colors from "config/color/color.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { IOStoggle } from "components";
import Radio from "@material-ui/core/Radio";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Switch from "@material-ui/core/Switch";

class SettingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { celebrity: false, menuSettings: false };
  }

  celebrityPopupOpen = () => {
    this.setState({ celebrity: true });
  };

  celebrityPopupClose = () => {
    this.setState({ celebrity: false });
  };

  menuSettingsPopupOpen = () => {
    this.setState({ menuSettings: true });
  };

  menuSettingsPopupClose = () => {
    this.setState({ menuSettings: false });
  };
  render() {
    const { item } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.celebrity}
          onClose={this.celebrityPopupClose}
          scroll="body"
          className="custom-backdrop"
          fullWidth="sm"
        >
          <div className="border-b px-3 py-3 flex items-center justify-center ">
            <p className="text-2xl Medium">Celebrity</p>
            <div
              onClick={this.celebrityPopupClose}
              className="z-10 absolute right-3 hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-full h-10 w-10 flex items-center justify-center"
            >
              <FiX size={24} style={{ color: colors.darkGray }} />
            </div>
          </div>
          <DialogContent className="p-0">
            <div className="p-4">
              <div>
                <div>
                  <div className="flex items-center justify-between">
                    <h4 className="flex-grow mr-5">
                      I would like to become a verified celebrity and have a
                      star added to my profile
                    </h4>
                    <IOStoggle />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <p className="flex-grow mr-5">
                      Please click the box that best defines your celebrity
                      status:
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Actor/Actress</p>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Musician</p>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Professional Athlete</p>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Published Author</p>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Politician</p>
                  </div>

                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Model</p>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Radio value="a" name="radio-button-demo" className="p-0" />
                    <p className="ml-3 Light">Other - Please Define</p>
                  </div>
                  <div className="flex mt-3 items-center">
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Other..."
                      className="p-3 shadow-none resize-none Light rounded--xl post-commnet-textInput bg--lightGray w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-3 border-t">
            <Button
              style={{ color: colors.white }}
              className="bg--primary rounded--lg hover:bg--primary focus:bg--primary w-50 m-auto shadow-none border-0 px-5 black transform transition duration-300 hover:scale-90"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.menuSettings}
          onClose={this.menuSettingsPopupClose}
          scroll="body"
          className="custom-backdrop border"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle className="p-0">
            <div className="border-b px-3 py-3 flex items-center justify-center ">
              <p className="text-2xl Medium">Menu Settings</p>
              <div
                onClick={this.menuSettingsPopupClose}
                className="z-10 absolute right-3 hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-full h-10 w-10 flex items-center justify-center"
              >
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0">
            <div className="p-4">
              <div>
                <div>
                  <h4>Customize Your Menu</h4>
                  <p className="darkGray fs-13 Light">
                    Options you select will be hide and show.
                  </p>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4></h4>
                    Show/Hide
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Groups</h4>
                    <Switch
                      color="primary"
                      checked={item.groups}
                      onChange={this.props.groupsChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Saved</h4>
                    <Switch
                      color="primary"
                      checked={item.saved}
                      onChange={this.props.savedChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Comms</h4>
                    <Switch
                      color="primary"
                      checked={item.comms}
                      onChange={this.props.commsChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Flirts</h4>
                    <Switch
                      color="primary"
                      checked={item.flirt}
                      onChange={this.props.flirtChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Bars</h4>
                    <Switch
                      color="primary"
                      checked={item.bars}
                      onChange={this.props.barsChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Restaurants</h4>
                    <Switch
                      color="primary"
                      checked={item.restaurant}
                      onChange={this.props.restaurantChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Company</h4>
                    <Switch
                      color="primary"
                      checked={item.company}
                      onChange={this.props.companyChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Bill of Rights</h4>
                    <Switch
                      color="primary"
                      checked={item.bill_of_rights}
                      onChange={this.props.billRightsChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Feedback</h4>
                    <Switch
                      color="primary"
                      checked={item.feedback}
                      onChange={this.props.feedbackChange}
                    />
                  </div>
                </div>
                {/* <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>How To</h4>
                    <Switch
                      color="primary"
                      checked={item.how_to}
                      onChange={this.props.how_toChange}
                    />
                  </div>
                </div> */}
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Reward</h4>
                    <Switch
                      color="primary"
                      checked={item.reward}
                      onChange={this.props.rewardChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Events</h4>
                    <Switch
                      color="primary"
                      checked={item.events}
                      onChange={this.props.eventsChange}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex mt-3 items-center justify-between">
                    <h4>Calendar</h4>
                    <Switch
                      color="primary"
                      checked={item.calendar}
                      onChange={this.props.calendarChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default SettingPopup;
