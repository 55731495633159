import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


import "./styles.css";


export default function Sub({ item }) {

    const { id } = useParams();

    const category = useSelector((state) => Object.assign({}, state.enhancement.category.data?.[id]?.[item.id]));

    return (

        <>
            {category.name}
        </>
    );
}