import React, { Component } from "react";
import { Button } from "react-bootstrap";
import colors from "config/color/color.js";
import { Popup } from "semantic-ui-react";
import { Peopleimage, Peoplenamepopup } from "components";

class SuggestionsLeftAside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item } = this.props;
    if (this.props.item.is_friend) return null;
    return (
      <>
        <div className="flex p-2 rounded-lg hover:bg-gray-200">
          <Peopleimage
            item={item}
            {...this.props}
            imageStyle="h-16 w-16 rounded-full mr-3"
          />
          <div className="flex-grow">
            <Peoplenamepopup item={item} />
            <Popup
              inverted
              position="bottom center"
              content={
                <div>
                  <p className="text-sm cursor-pointer white Light">
                    User Name one
                  </p>
                  <p className="text-sm cursor-pointer white Light">
                    User Name one
                  </p>
                </div>
              }
              trigger={
                <p className="inline-block text-sm cursor-pointer darkGray Light">
                  12 mutual friends
                </p>
              }
            />
            {item.is_friend && <p>You are friends now</p>}
            <div className="flex items-center gap-2 mt-2">
              {!item.is_friend && !item.request.is_entry && (
                <>
                  <Button
                    onClick={() => this.props.sendFriendRequest(item.id)}
                    className="flex-grow h-10 border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary rounded--lg">
                    Add Friend
                  </Button>
                  <Button
                    style={{ color: colors.black }}
                    className="flex-grow h-10 border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray rounded--lg">
                    Remove
                  </Button>
                </>
              )}
              {!item.is_friend && item.request.is_entry && (
                <>
                  <Button
                    onClick={() => this.props.cancelFriendRequest(item.id)}
                    style={{ color: colors.black }}
                    className="flex-grow h-10 border-0 shadow-none bg--lightGray hover:bg--lightGray focus:bg--lightGray rounded--lg">
                    Cancel friend request
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SuggestionsLeftAside;
