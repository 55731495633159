import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";
import { LifeWidget } from "common";

export default function BarInvite(props) {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [isFetching, setIsFetching] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState({});

    const accepted = async () => {
        setIsSubmitting(true);
        const json = await LifeWidget.acceptedEnhancementInvitation({ id: id });
        if (json !== undefined) {
            if (json.status == 200) {
                setIsAccepted(true);
            } else {
                setError(json.data);
            }
        }
        setIsSubmitting(false);


    }

    useEffect(async () => {
        setIsFetching(true);
        const json = await LifeWidget.inviteEnhancementUser({ id: id });
        if (json !== undefined) {
            if (json.status == 200) {
                setData(json.data);
            } else {
                setError(json.data);
            }
        }
        setIsFetching(false);

    }, [id]);

    return (
        <div className="flex justify-center items-center">
            <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
                <div className="m-auto w-full space-y-5">
                    <div className=" mb-10">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center gap-x-10 mx-4 mb-10">
                                <h1>Invitation</h1>
                            </div>
                        </div>

                        {isFetching ?
                            <div className="flex items-center justify-center">
                                <BiLoaderAlt size={21} className="animate-spin text-center " />
                            </div>
                            :
                            data?.id &&
                            <>
                                <div className="w-full flex items-center justify-center flex-col">
                                    <div className=""><p className="text-xl pb-2 font-extrabold">{data?.bar?.name}</p></div> <div>invited you to <span className="text-xl font-extrabold">{data?.role}</span> as role</div>
                                </div>
                                {isAccepted == false ?
                                    <div className="w-full flex items-center justify-center flex-col">

                                        <div className="mt-4 flex justify-between flex-col">
                                            <button disabled={isSubmitting} onClick={accepted} type="button" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm flex justify-center items-center px-5 py-2.5 mb-4">
                                                {isSubmitting && <BiLoaderAlt size={18} className="animate-spin mr-2" />} Accept Invitation
                                            </button>
                                        </div>
                                    </div> : <div className="w-full flex items-center justify-center flex-col mt-4">
                                        <p className="text-xl pb-2 font-extrabold text-green-600 ">Invitation has been accepted</p>
                                        <NavLink
                                            to={`/`}
                                            className="pl-2 mt-4">
                                            Go back to home
                                        </NavLink>
                                    </div>
                                }
                            </>
                        }
                        <div className="flex items-center justify-center">
                            {error && typeof error === "object" && Object.keys(error).map((err, key) => (
                                <p key={key} class="flex items-center text-xl tracking-wide text-red-500 text-sm mt-1 ml-1">
                                    {error?.[err]?.[0]}
                                </p>
                            ))
                            }

                        </div>
                        {error && typeof error === "object" && Object.keys(error).length > 0 &&
                            <div className="w-full flex items-center justify-center flex-col mt-4">
                                <NavLink
                                    to={`/`}
                                    className="pl-2 mt-4">
                                    Go back to home
                                </NavLink>
                            </div>
                        }



                    </div>
                </div>
            </div>

        </div>
    );
}
