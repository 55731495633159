import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { OptimizeImage, Capitalize } from "helpers";
import { avatar } from "assets";

class FriendSuggestion extends Component {
  _renderButton = (one) => {
    return (
      <div
        className="flex items-center px-3 py-1 border-b hover:bg-gray-200"
        key={one.id}>
        {one.profile_photo ? (
          <Image
            src={OptimizeImage(one.profile_photo)}
            className="object-cover w-12 h-12 mr-3 rounded-full"
          />
        ) : (
          <Image
            src={avatar}
            className="object-cover w-12 h-12 mr-3 rounded-full"
          />
        )}
        <p>
          {Capitalize(one.first_name)} {Capitalize(one.last_name)}
        </p>
      </div>
    );
  };

  render() {
    const { item } = this.props;
    return this._renderButton(item);
  }
}

export default FriendSuggestion;
