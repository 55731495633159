import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, NavDropdown, Navbar, Nav, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import logo from "assets/images/logo.webp";
import "./styles.css";
import { TiArrowSortedDown } from "react-icons/ti";
import { AiOutlineLogout } from "react-icons/ai";
import { CgMenuGridO } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { FaBell } from "react-icons/fa";
import {
  UserProfileComponent,
  Menuaccordion,
  Notification,
  Menu,
} from "components";
import { Emitter } from "common";
import ConnectyCube from "connectycube";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Capitalize, OptimizeImage } from "helpers";
import { Dimmer, Loader } from "semantic-ui-react";
import { VscFeedback } from "react-icons/vsc";
import { IoHandRightSharp } from "react-icons/io5";
import { RiSlideshow3Fill } from "react-icons/ri";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { refreshControl: false };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.setState({ refreshControl: false });
    }, 3000);
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.setState({ refreshControl: true });
  };
  searchUser = userName => { };

  onSearch = e => {
    Emitter.emit("SEARCH_PEOPLE_GROUP", e.target.value);
  };

  logout = async () => {
    this.props.logout();
    try {
      await ConnectyCube.logout();
    } catch (e) { }
  };

  render() {
    const { user } = this.props.User;
    const { activeTab } = this.props;
    return (
      <div className="fixed w-full z-20 header">
        <div
          style={{ top: this.state.refreshControl === true ? 120 : -100 }}
          className="primary--loader w-16 h-16 fixed left-50 m-auto"
        >
          <Dimmer
            active
            inverted
            className="w-20 h-20 bg-white shadow-sm rounded-full"
          >
            <Loader></Loader>
          </Dimmer>
        </div>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="shadow-sm bg-white border-0"
        >
          <Container fluid style={{ maxWidth: 1500 }}>
            <Nav className="mr-3">
              <NavLink
                to={`/${user.user_name}`}
                exact
                activeClassName="important:primary"
                className="items-center d-flex rounded-full pr-3 black Medium fs-13"
              >
                <Image
                  className="h-12 w-12 object-cover object-top rounded-xl mr-2"
                  src={
                    user.profile_photo
                      ? OptimizeImage(user.profile_photo)
                      : logo
                  }
                  alt="logo"
                />
                {Capitalize(user.first_name)} {Capitalize(user.last_name)} asdf
              </NavLink>
            </Nav>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="hidden"
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto w-full max-w-5xl justify-evenly">
                {/* <NavLink
                  exact
                  activeClassName="active--link"
                  to={`/${user.user_name}/edit`}
                  className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative"
                >
                  Edit My Profile
                </NavLink> */}

                <NavLink
                  exact
                  activeClassName="active--link"
                  to="/"
                  className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative"
                >
                  Social Feed
                </NavLink>
                <NavLink
                  exact
                  to="/friends"
                  activeClassName="active--link"
                  className=" white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative"
                >
                  My Friends Only
                </NavLink>

                <NavLink
                  exact
                  to="/me"
                  className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative"
                  activeClassName="active--link"
                >
                  My Postings
                </NavLink>

                <NavLink
                  to="/groups"
                  activeClassName="active--link"
                  className="white text-nowrap max-w-xxs w-full flex items-center justify-center bg--primary hover:bg-gray-200 hover:black rounded-lg py-2.5 px-4 relative"
                >
                  My Groups
                </NavLink>
              </Nav>
            </Navbar.Collapse>
            <div className="flex items-center max-w-sm md:w-full justify-end">
              <div className="flex items-center max-w-xs md:w-full mr-4">
                <NavLink
                  to={`/search/${activeTab}`}
                  className="rounded-xl bg-gray-200  h-11 w-64 px-3 md:flex flex-row items-center hidden"
                >
                  <input
                    placeholder="Search "
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="h-10 w-64 Regular border-0 bg-transparent black"
                    onChange={this.onSearch}
                  />
                  <div className="pl-1">
                    <GoSearch style={{ color: colors.darkGray }} />
                  </div>
                </NavLink>
              </div>
              <NavDropdown
                title={
                  <>
                    <CgMenuGridO
                      className="m-auto"
                      size={25}
                      style={{ color: colors.black }}
                    />
                  </>
                }
                className="custom-dropdown md:hidden block bg-gray-200 right rounded-full h-12 w-12 mr-3"
              >
                <div className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-xl sm:px-0 rounded-lg">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
                    <div className="relative bg-white rounded-lg">
                      <Menu />
                    </div>
                  </div>
                </div>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <FaBell
                      className="m-auto"
                      size={15}
                      style={{ color: colors.black }}
                    />
                  </>
                }
                className="custom-dropdown bg-gray-200 right rounded-full h-12 w-12 mr-3"
              >
                <div className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-md sm:px-0 rounded-lg">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white p-5 rounded-lg">
                      <Notification />
                    </div>
                  </div>
                </div>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <TiArrowSortedDown
                      className="m-auto"
                      size={24}
                      style={{ color: colors.black }}
                    />
                  </>
                }
                className="custom-dropdown  bg-gray-200 right rounded-full h-12 w-12"
              >
                <div className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-sm sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative bg-white p-2">
                      <UserProfileComponent />
                      <div className="divider mt-1" />
                      <Menuaccordion />
                      <Link
                        to="/feedback"
                        className="mt-1.5 p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
                      >
                        <div className="">
                          <VscFeedback size={18} className="black" />
                        </div>
                        <p className="ml-3">Feedback</p>
                      </Link>
                      <Link
                        to="/bill_of_rights"
                        className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
                      >
                        <div>
                          <IoHandRightSharp size={18} className="black" />
                        </div>
                        <p className="ml-3">Bill of Rights</p>
                      </Link>
                      {/* <Link
                        to="/tutorial"
                        className="p-2.5 rounded-lg flex items-center mb-2 hover:bg-gray-200 list-items cursor-pointer"
                      >
                        <div>
                          <RiSlideshow3Fill size={18} className="black" />
                        </div>
                        <p className="ml-3">How To</p>
                      </Link> */}
                      <div className="divider mt-1" />
                      <Link
                        onClick={() => this.logout()}
                        className="flex items-center p-1 m-1 hover:bg-gray-200 rounded-lg"
                      >
                        <div className="rounded-full p-2 bg-gray-100">
                          <AiOutlineLogout size={18} className="black" />
                        </div>
                        <p className="ml-3">Logout</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </NavDropdown>
            </div>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = ({ User, Group }) => ({
  User,
  activeTab: Group.activeSearchTab,
});
const mapDispatchToProps = dispatch => {
  const { actions } = require("redux/UserRedux");

  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
