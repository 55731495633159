import {
  UPDATE_UNREAD_COUNT
} from '../actions/unreadCount'

export default (unreadCount = 0, action) => {
    switch (action.type) {
    
    case UPDATE_UNREAD_COUNT:
      return action.count

    default:
      return unreadCount
  }
}