import React, { Component } from "react";
import { Link } from "react-router-dom";
import Usernamepopup from "components/allcomponents/usernamepopup";
import { Avatarimage } from "components";

class Availableflirtscard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { flirt_setting } = this.props.item;
    const { item } = this.props;
    return (
      <div key={item.id}>
        <Link
          to={{
            pathname: `/flirts/flirt-detail/${item.id}`,
            state: { item: item },
          }}
          className="flex flex-col items-center p-2 bg-white shadow-md rounded-xl">
          <div className="relative">
            <Avatarimage item={item} imageSize={{ width: 110, height: 110 }} />
          </div>
          <div className="mt-3">
            <Usernamepopup item={item} flirt_setting={flirt_setting} />
          </div>
        </Link>
      </div>
    );
  }
}

export default Availableflirtscard;
