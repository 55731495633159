import React, { Component } from "react";

class Friendshead extends Component {
  render() {
    return (
      <div className="flex items-center justify-between">
        <h3 className="black text-2xl font-medium Medium">Friends</h3>
        {/* <div className="flex items-center justify-between ">
          <div className="rounded-full bg--lightGray h-10 w-64 px-2 flex flex-row items-center">
            <div>
              <GoSearch style={{ color: colors.darkGray }} className="mr-2" />
            </div>
            <input
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="h-10 w-64 Regular border-0 bg-transparent"
            />
          </div>
          <div className="hover:bg-gray-200 rounded-lg ml-3 flex items-center h-10 px-3 cursor-pointer ">
            <Link to="/friends/requests" className="primary ">
              Friend Requests
            </Link>
          </div>
          <div className="hover:bg-gray-200 rounded-lg flex items-center h-10 px-3 cursor-pointer ">
            <Link to="/friends" className="primary ">
              Find Friends
            </Link>
          </div>
          <div>
            <Dropdown
              icon={false}
              wrapSelection={true}
              direction="left"
              pointing="top"
              text={
                <div>
                  <div className="bg-gray-200 rounded-lg ml-3 flex items-center h-10 px-3 cursor-pointer hover:bg-gray-300 transform transition duration-300 hover:scale-90">
                    <BsThreeDots size={16} />
                  </div>
                </div>
              }
            >
              <Dropdown.Menu className="rounded--lg bg-white shadow-lg overflow-show border-0 w-96">
                <div className="p-2">
                  <div className="flex items-center py-2 hover:bg-gray-100 rounded px-2 cursor-pointer">
                    <p>Edit Privacy</p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Friendshead;
