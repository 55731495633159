import React, { Component } from "react";
import colors from "config/color/color.js";
import { ImLocation2 } from "react-icons/im";
import { IoLockClosed } from "react-icons/io5";
import { BsFillEyeFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";

class Groupaboutaside extends Component {
  render() {
    return (
      <div className="p-5 bg-white rounded-lg shadow-sm">
        <h3 className="text-3xl Medium">About</h3>
        <p className="mt-3 fs-14">
          Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet
          elit, eget tincidunt nibh pulvinar a.
        </p>
        <div className="mt-4">
          <div className="flex mb-3">
            <div className="mt-1 mr-3">
              <IoLockClosed size={18} color={colors.black} />
            </div>
            <div className="">
              <p> Private</p>
              <p className="fs-13 darkGray">
                Only members can see who's in the group and what they post.
              </p>
            </div>
          </div>
          <div className="flex mb-3">
            <div className="mr-3">
              <BsFillEyeFill size={18} color={colors.black} />
            </div>
            <div className="">
              <p> Visible</p>
              <p className="fs-13 darkGray">Anyone can find this group.</p>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <div className="mr-3 ">
              <ImLocation2 size={20} color={colors.black} />
            </div>
            <p className="darkGray fs-14">
              <p className="black hover:underline fs-14"> California, USA</p>
            </p>
          </div>
          <div className="flex">
            <div className="mt-1 mr-3">
              <FaUsers size={16} color={colors.black} />
            </div>
            <div className="">
              <p> General</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Groupaboutaside;
