import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { IoEarthSharp } from "react-icons/io5";
import { ImUsers } from "react-icons/im";
import { Fullscreenpopup, ImageGrid, TimeAgo } from "components";
import { Capitalize, OptimizeImage } from "helpers";
import { avatar } from "assets";
import { AiFillLock } from "react-icons/ai";

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fullscreenRef = React.createRef();
  }

  render() {
    let { item } = this.props;
    item = typeof item !== "undefined" ? item : { user: {} };
    return (
      <div className="p-3 pb-3 mt-1 bg-white border rounded-lg shadow-sm post ">
        <div className={`${item.post_tags ? "items-center" : null} flex `}>
          <Link to={`/${item.user.user_name}`}>
            <Image
              className="object-cover mr-2 border rounded-full h-14 w-14"
              src={
                item.user.profile_photo
                  ? OptimizeImage(item.user.profile_photo)
                  : avatar
              }
            />
          </Link>
          <div className="flex-grow">
            <div className="flex items-center">
              <div className="flex-grow">
                <div className="gray Light flex items-center flex-grow flex-wrap space-x-1.5">
                  <Link to={`/${item.user.user_name}`} className=" black">
                    {Capitalize(item.user.first_name)}{" "}
                    {Capitalize(item.user.last_name)}
                  </Link>
                  {item.post_tags && item.post_tags.length > 0 && (
                    <p className="fs-14 Light darkGray">is with</p>
                  )}
                  {item.post_tags &&
                    item.post_tags.map((tag, key) => (
                      <>
                        <Link
                          className="Regular black hover:black hover:underline"
                          key={key}>
                          {tag.first_name}
                          {item.post_tags.length - 2 === key ? null : item
                              .post_tags.length -
                              1 ===
                            key ? null : (
                            <span className="Regular darkGray">,</span>
                          )}
                        </Link>

                        {item.post_tags.length - 2 === key ? (
                          <span className="fs-14 Light darkGray">and</span>
                        ) : item.post_tags.length - 1 === key ? (
                          ""
                        ) : null}
                      </>
                    ))}
                </div>
                <div className="flex items-center">
                  <TimeAgo
                    className="text-sm gray Light"
                    created_at={item.created_at}
                  />
                  <BsDot className="gray" />
                  {item.general_privacy_id === 1 && (
                    <IoEarthSharp className="gray" />
                  )}
                  {item.general_privacy_id === 2 && (
                    <ImUsers className="gray" />
                  )}
                  {item.general_privacy_id === 3 && (
                    <AiFillLock className="gray" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <p className="Light">{item.post_text}</p>
          <div
            onClick={() => this.fullscreenRef.current.fullscreenOpen()}
            className="mx-2 cursor-pointer">
            <ImageGrid item={item} />
          </div>
        </div>
        <Fullscreenpopup item={item} ref={this.fullscreenRef} {...this.props} />
      </div>
    );
  }
}

export default React.memo(Share);
