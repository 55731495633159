import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./styles.css";
import { Form } from "react-bootstrap";
import colors from "config/color/color.js";
import { HiOutlineSearch } from "react-icons/hi";

class Myjobspostingnavigation extends Component {
  render() {
    return (
      <div className="z-10 py-3 profile-navigation jobs-navigation">
        <div className="relative flex items-center justify-between">
          <div className="flex flex-row items-center navbar-nav ">
            <NavLink
              to="/jobs"
              activeClassName="active--link"
              activeStyle={{
                color: colors.primary,
              }}
              className="relative flex justify-center px-4 py-3 text-xl rounded-lg black hover:bg-gray-200 Medium">
              Jobs
            </NavLink>
            <NavLink
              to="/candidates"
              activeClassName="active--link"
              activeStyle={{
                color: colors.primary,
              }}
              className="relative flex justify-center px-4 py-3 text-xl rounded-lg black hover:bg-gray-200 Medium">
              Candidates
            </NavLink>
          </div>
          <div className="items-center hidden w-50 md:flex">
            <div className="flex items-center flex-grow bg-white border rounded--xl">
              <Form.Control
                placeholder="Search Candidates"
                className="h-12 border-0 shadow-none rounded--xl"
              />
              <div className="flex items-center justify-center h-full pr-1">
                <HiOutlineSearch size={20} />
              </div>
            </div>
            <Link
              to="/post_job/getting_started"
              className="flex items-center justify-center h-12 px-4 ml-5 bg--primary white hover:color-white hover:bg--primary focus:bg--primary rounded-xl">
              Post a job
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Myjobspostingnavigation;
