import * as React from "react";
import "./styles.css";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import colors from "config/color/color.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Buttonused } from "components";

class DeleteBandPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteBand: false, item: {} };
    this.deleteBandRef = React.createRef();
  }

  deleteBandPopupOpen = (item) => {
    this.setState({ deleteBand: true, item: item });
  };

  deleteBandPopupClose = () => {
    this.setState({ deleteBand: false });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.state.deleteBand}
          onClose={this.deleteBandPopupClose}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            <div className="flex items-center px-3 py-3 border-b ">
              <p className="text-2xl Medium">Are you sure?</p>
              <div
                onClick={this.deleteBandPopupClose}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="pb-4">
              <p>Are you sure you want to delete this band?</p>
            </div>
          </DialogContent>
          <DialogActions className="p-4 border-t">
            <Buttonused
              className="h-10 px-5 transition duration-300 transform border-0 shadow-none bg--lightGray rounded--lg hover:bg--lightGray focus:bg--lightGray black hover:scale-90"
              onClick={this.deleteBandPopupClose}
              rippleContainer="rounded-lg"
              Text="Cancel"
            />
            <Buttonused
              className="items-center justify-center w-full h-10 px-5 transition duration-300 transform bg-red-100 rounded-lg danger d-flex hover:scale-90"
              onClick={() => this.props.callbackAction(this.state.item)}
              rippleContainer="rounded-lg"
              Text="Delete"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DeleteBandPopup;
