import React, { Component } from "react";
import { Form } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import colors from "config/color/color";
import { Link } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import IOStoggle from "components/allcomponents/iostoggle";

class Postjobsettings extends Component {
  render() {
    return (
      <div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <p className="mb-1 fs-16 Medium">
            Do you want applicants to submit a resume?
          </p>
          <RadioGroup>
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              className="mt-2 border important:Regular-all rounded-xl"
            />
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              className="mt-2 border important:Regular-all rounded-xl"
            />
            <FormControlLabel
              value="Optional"
              control={<Radio />}
              label="Optional"
              className="mt-2 border important:Regular-all rounded-xl"
            />
          </RadioGroup>
          <div className="my-5 divider" />
          <div className="mt-5">
            <p className="fs-16 Medium">Communication Settings</p>
            <p className="fs-13">
              Daily updates about this job and candidates will be sent to:
            </p>
            <Form.Control className="h-12 mt-3 border shadow-none rounded--xl" />
            <div className="flex items-center">
              <Checkbox color="primary" />
              <p className="fs-12 darkGray">
                Also send an individual email update every time a new candidate
                applies
              </p>
            </div>
          </div>
          <div className="my-5 divider" />
          <div className="mt-5">
            <p className="fs-16 Medium">Messages Settings</p>
            <div className="flex items-center justify-between">
              <p className="mt-3 mb-1 fs-15">
                Do you want to let applicants start the conversation?
              </p>
              <IOStoggle />
            </div>
            <p className="fs-13 darkGray">
              Allow job seekers to message you about their applications.
              Messages will be sent to the same email address you added in
              Application Settings.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <Link
            to="/post_job/job_description"
            style={{ color: colors.black }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black Regular rounded--xl hover:scale-90">
            Back
          </Link>
          <Link
            to="/post_job/unified_qualifications"
            style={{ color: colors.white }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary hover:opacity-80 focus:bg--primary white Regular rounded--xl hover:scale-90">
            Save and continue
          </Link>
        </div>
      </div>
    );
  }
}

export default Postjobsettings;
