import React, { Component } from "react";
import "./styles.css";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Dropdown } from "semantic-ui-react";

class Barsleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ischecked: true,
      keywordsList: [],
      filters: [],
      Distance: 10,
      checked: [],
      business_type: "Bar",
    };
    this.barSearchEvent = null;
    this.params = [];
    this.per_page = 10;
    this.page = 1;
  }

  componentDidMount() {
    const { currentLocation } = this.props;
    this.params["type"] = "bar";
    this.params["Distance"] = this.Distance;
    if (Object.keys(currentLocation).length > 0) {
      this.params["latitude"] = currentLocation.lat;
      this.params["longitude"] = currentLocation.lng;
    }
    navigator.geolocation.getCurrentPosition((position) => {
      this.props.setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
    this.props.fetchBarServices();
    this.props.fetchBars(this.per_page, this.page, this.params);
  }
  onChangeText = (event) => {
    clearTimeout(this.barSearchEvent);
    this.barSearchEvent = setTimeout(() => {
      this.params["s"] = event.target.value;
      this.page = 1;
      this.props.fetchBars(this.per_page, this.page, this.params);
    }, 500);
  };
  onDistanceChange = (distance) => {
    this.setState({ Distance: distance });
    // this.changeFilters(this.state.Distance)
  };
  searchResult = () => {
    this.applyFilters(this.state.filters, this.state.Distance);
  };

  changeFilters = (keyword) => {
    let { filters } = this.state;
    let found = filters.find((data) => data === keyword);
    if (!found) {
      filters.push(keyword);
    } else {
      filters = filters.filter((data) => data !== keyword);
    }
    this.applyFilters(filters, this.state.Distance);
  };

  applyFilters = (items, Distance) => {
    this.params["occupancy_rate"] = false;
    this.params["opposite_ratio"] = false;
    this.params["friend_present"] = false;
    this.params["hide_my_self"] = false;
    this.params["young_to_oldest"] = false;
    this.params["my_company"] = false;
    this.params["my_favorites"] = false;
    this.params.Distance = Distance;
    items.map((item) => {
      this.params[item] = true;
    });
    this.page = 1;
    this.props.fetchBars(this.per_page, this.page, this.params);
    this.setState({ filters: items, Distance: Distance });
  };

  loadMore = () => {
    if (!this.props.isFetching) {
      this.page++;
      this.props.fetchBars(this.per_page, this.page, this.params);
    }
  };

  applyFilterServices = (item) => {
    let { checked } = this.state;
    let found = checked.find((data) => data.id === item.id);
    if (!found) {
      checked.push(item);
    } else {
      checked = checked.filter((data) => data.id !== item.id);
    }
    this.setState({ checked });
    let services = checked.map((check) => check.id);
    this.barSearchEvent = setTimeout(() => {
      this.params["services"] = services;
      this.page = 1;
      this.props.fetchBars(this.per_page, this.page, this.params);
    }, 500);
  };

  render() {
    const { filters } = this.state;
    const my_favorites = filters.find((filter) => filter === "my_favorites")
      ? true
      : false;
    const my_company = filters.find((filter) => filter === "my_company")
      ? true
      : false;
    const occupancy_rate = filters.find((filter) => filter === "occupancy_rate")
      ? true
      : false;
    const opposite_ratio = filters.find((filter) => filter === "opposite_ratio")
      ? true
      : false;
    const friend_present = filters.find((filter) => filter === "friend_present")
      ? true
      : false;
    const young_to_oldest = filters.find(
      (filter) => filter === "young_to_oldest"
    )
      ? true
      : false;
    const hide_my_self = filters.find((filter) => filter === "hide_my_self")
      ? true
      : false;

    return (
      <div className="fixed flex flex-col p-5 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Main Menu
                </Link>
                <h2 className="text-2xl Medium">Bars</h2>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center h-10 px-3 mt-3 bg-gray-200 rounded-full">
            <div className="mr-2">
              <GoSearch color={colors.darkGray} />
            </div>
            <input
              placeholder="Search for Bar"
              className="w-full h-10 bg-transparent border-0 Regular"
              onChange={this.onChangeText}
            />
          </div>
        </div>

        <div className="my-3 divider" />
        <div className="mb-2 -mx-3 overflow-auto aside-2" id="scrollbar">
          <div className="flex flex-wrap px-3 mb-3 gap-x-1 gap-y-2">
            <div className="relative overflow-hidden select-bar-services">
              <label
                htmlFor="Favorites"
                className={`bg-gray-200 ${
                  my_favorites && "bg--primary"
                } hover:bg--primary hover:color-white-all rounded-full px-4 py-2 cursor-pointer inline-flex`}>
                <p
                  onClick={() => this.changeFilters("my_favorites")}
                  className={`${my_favorites && "text-white"}`}>
                  My Favourite
                </p>
              </label>
            </div>
            <div className="relative overflow-hidden select-bar-services">
              <label
                htmlFor="Bars"
                className={`bg-gray-200 ${
                  my_company && "bg--primary"
                } hover:bg--primary hover:color-white-all rounded-full px-4 py-2 cursor-pointer inline-flex`}>
                <p
                  onClick={() => this.changeFilters("my_company")}
                  className={`${my_company && "text-white"}`}>
                  My Bars
                </p>
              </label>
            </div>
            {this.props.services.length > 0 &&
              this.props.services.map((item, key) => (
                <div
                  key={key}
                  className={` ${
                    this.state.checked.find((data) => data.id === item.id)
                      ? "bg--primary"
                      : "bg-gray-200"
                  } hover:bg--primary hover:color-white-all rounded-full px-4 py-2 cursor-pointer inline-flex`}
                  onClick={() => this.applyFilterServices(item)}>
                  <p
                    className={`${
                      this.state.checked.find((data) => data.id === item.id) &&
                      "text-white"
                    }`}>
                    {" "}
                    {item.service_name}
                  </p>
                </div>
              ))}
          </div>

          <div className="my-3 divider" />

          <div className="relative px-3 bg-white">
            <p className="p-2 text-center bg-gray-200 Medium rounded-xl">
              My Criteria
            </p>
            <div className="py-2">
              <div
                onClick={() => this.changeFilters("occupancy_rate")}
                className="flex items-center justify-between px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-200">
                <p className="text-sm darkGray">Heighest Occupancy Rate</p>
                <Checkbox checked={occupancy_rate} color="primary" />
              </div>
              <div
                onClick={() => this.changeFilters("opposite_ratio")}
                className="flex items-center justify-between px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-200">
                <p className="text-sm darkGray ">Heighest Opposite M/F Ratio</p>
                <Checkbox checked={opposite_ratio} color="primary" />
              </div>
              <div
                onClick={() => this.changeFilters("friend_present")}
                className="flex items-center justify-between px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-200">
                <p className="text-sm darkGray ">My Friends Present</p>
                <Checkbox checked={friend_present} color="primary" />
              </div>
              <div
                onClick={() => this.changeFilters("young_to_oldest")}
                className="flex items-center justify-between px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-200">
                <p className="text-sm darkGray ">Young to Oldest</p>
                <Checkbox checked={young_to_oldest} color="primary" />
              </div>
              <div
                onClick={() => this.changeFilters("hide_my_self")}
                className="flex items-center justify-between px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-200">
                <p className="text-sm darkGray ">Hide me</p>
                <Checkbox checked={hide_my_self} color="primary" />
              </div>
              <div className="flex items-center justify-between px-2 py-1 rounded-lg">
                <div className="w-full mb-2">
                  <p className="mb-1 fs-14">Select Distance:</p>
                  <Dropdown
                    className="w-full border gray rounded--lg"
                    placeholder="Select Distance"
                    fluid
                    selection
                    value={this.state.Distance}
                    options={[
                      {
                        key: "1 mile",
                        text: "1 mile",
                        value: "1",
                      },
                      {
                        key: "3",
                        text: "3 miles",
                        value: "3",
                      },
                      {
                        key: "5 miles",
                        text: "5 miles",
                        value: "5",
                      },
                      {
                        key: "10 miles",
                        text: "10 miles",
                        value: "10",
                      },
                      {
                        key: "15 miles",
                        text: "15 miles",
                        value: "15",
                      },
                      {
                        key: "20 miles",
                        text: "20 miles",
                        value: "20",
                      },
                      {
                        key: "25 miles",
                        text: "25 miles",
                        value: "25",
                      },
                      {
                        key: "30 miles",
                        text: "30 miles",
                        value: "30",
                      },
                      {
                        key: "50 miles",
                        text: "50 miles",
                        value: "50",
                      },
                      {
                        key: "100 miles",
                        text: "100 miles",
                        value: "100",
                      },
                    ]}
                    // onClick={() => this.changeFilters(this.state.Distance)}
                    onChange={(_, { value }) => this.onDistanceChange(value)}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between px-2 py-1 text-center rounded-lg">
                <div className="w-full mb-2">
                  <button
                    onClick={this.searchResult}
                    className="btn btn-primary btn-sm primary">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link
          to={{
            pathname: `/bars/bar-create/${this.state.business_type}`,
            state: this.state.business_type,
          }}
          className="flex items-center justify-center w-full mb-1 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
          <FiPlus color={colors.primary} size={18} />
          <p className="ml-2 primary">Add Bar</p>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ Bar, User }) => {
  return {
    bars: Bar.items,
    isFetching: Bar.isFetching,
    services: Bar.services,
    total: Bar.total,
    getStarted: Bar.getStarted,
    user: typeof User.user !== "undefined" ? User.user : {},
    currentLocation:
      typeof User.currentLocation !== "undefined" ? User.currentLocation : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/BarRedux");
  const { actions: userAction } = require("redux/UserRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchBars: (per_page, page, params = []) => {
      actions.fetchBars(dispatch, per_page, page, params);
    },
    fetchBarServices: () => {
      actions.fetchBarServices(dispatch);
    },
    setCurrentLocation: (data) => {
      dispatch(userAction.setCurrentLocation(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Barsleftaside);
