import React, { Component } from "react";
import colors from "config/color/color.js";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { FiSliders } from "react-icons/fi";
import { GoSearch } from "react-icons/go";
import { Button, NavDropdown } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Dropdown } from "semantic-ui-react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { connect } from "react-redux";
import { IoIosAlbums } from "react-icons/io";
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

class Restaurantsleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      filter_country: "",
      filter_state: "",
      filter_city: "",
      filter_zip_code: "",
      filter_distance: 0,
      business_type: "Restaurant",
    };
    this.params = [];
  }
  onChangeText = () => {
    setTimeout(() => {
      const {
        text,
        filter_country,
        filter_state,
        filter_city,
        filter_zip_code,
        filter_distance,
      } = this.state;
      this.params["type"] = "restaurant";
      this.params["s"] = text;
      this.params["country"] = filter_country;
      this.params["state"] = filter_state;
      this.params["city"] = filter_city;
      this.params["zip_code"] = filter_zip_code;
      this.params["Distance"] = filter_distance;
      this.page = 1;
      this.props.fetchRestaurant(this.per_page, this.page, this.params);
    }, 500);
  };

  onSearchRestaturant = (event) => {
    setTimeout(() => {
      this.params["s"] = event.target.value;
      this.page = 1;
      this.props.fetchRestaurant(this.per_page, this.page, this.params);
    }, 500);
  };
  applyFilters = (key, value) => {
    this.setState({ [key]: value });
  };
  render() {
    const countryOptions = [
      {
        key: "af",
        flag: "af",
        value: "af",
        text: "Afghanistan",
        countryCode: "af",
      },
      {
        key: "ax",
        flag: "ax",
        value: "ax",
        text: "Aland Islands",
        countryCode: "ax",
      },
      {
        key: "al",
        flag: "al",
        value: "al",
        text: "Albania",
        countryCode: "al",
      },
      {
        key: "dz",
        flag: "dz",
        value: "dz",
        text: "Algeria",
        countryCode: "dz",
      },
      {
        key: "as",
        flag: "as",
        value: "as",
        text: "American Samoa",
        countryCode: "as",
      },
      {
        key: "ad",
        flag: "ad",
        value: "ad",
        text: "Andorra",
        countryCode: "ad",
      },
      { key: "ao", flag: "ao", value: "ao", text: "Angola", countryCode: "ao" },
      {
        key: "ai",
        flag: "ai",
        value: "ai",
        text: "Anguilla",
        countryCode: "ai",
      },
      {
        key: "ag",
        flag: "ag",
        value: "ag",
        text: "Antigua",
        countryCode: "ag",
      },
      {
        key: "ar",
        flag: "ar",
        value: "ar",
        text: "Argentina",
        countryCode: "ar",
      },
      {
        key: "am",
        flag: "am",
        value: "am",
        text: "Armenia",
        countryCode: "am",
      },
      { key: "aw", flag: "aw", value: "aw", text: "Aruba", countryCode: "aw" },
      {
        key: "au",
        flag: "au",
        value: "au",
        text: "Australia",
        countryCode: "au",
      },
      {
        key: "at",
        flag: "at",
        value: "at",
        text: "Austria",
        countryCode: "at",
      },
      {
        key: "az",
        flag: "az",
        value: "az",
        text: "Azerbaijan",
        countryCode: "az",
      },
      {
        key: "bs",
        flag: "bs",
        value: "bs",
        text: "Bahamas",
        countryCode: "bs",
      },
      {
        key: "bh",
        flag: "bh",
        value: "bh",
        text: "Bahrain",
        countryCode: "bh",
      },
      {
        key: "bd",
        flag: "bd",
        value: "bd",
        text: "Bangladesh",
        countryCode: "bd",
      },
      {
        key: "bb",
        flag: "bb",
        value: "bb",
        text: "Barbados",
        countryCode: "bb",
      },
      {
        key: "by",
        flag: "by",
        value: "by",
        text: "Belarus",
        countryCode: "by",
      },
      {
        key: "be",
        flag: "be",
        value: "be",
        text: "Belgium",
        countryCode: "be",
      },
      { key: "bz", flag: "bz", value: "bz", text: "Belize", countryCode: "bz" },
      { key: "bj", flag: "bj", value: "bj", text: "Benin", countryCode: "bj" },
      {
        key: "bm",
        flag: "bm",
        value: "bm",
        text: "Bermuda",
        countryCode: "bm",
      },
      { key: "bt", flag: "bt", value: "bt", text: "Bhutan", countryCode: "bt" },
      {
        key: "bo",
        flag: "bo",
        value: "bo",
        text: "Bolivia",
        countryCode: "bo",
      },
      { key: "ba", flag: "ba", value: "ba", text: "Bosnia", countryCode: "ba" },
      {
        key: "bw",
        flag: "bw",
        value: "bw",
        text: "Botswana",
        countryCode: "bw",
      },
      {
        key: "bv",
        flag: "bv",
        value: "bv",
        text: "Bouvet Island",
        countryCode: "bv",
      },
      { key: "br", flag: "br", value: "br", text: "Brazil", countryCode: "br" },
      {
        key: "vg",
        flag: "vg",
        value: "vg",
        text: "British Virgin Islands",
        countryCode: "vg",
      },
      { key: "bn", flag: "bn", value: "bn", text: "Brunei", countryCode: "bn" },
      {
        key: "bg",
        flag: "bg",
        value: "bg",
        text: "Bulgaria",
        countryCode: "bg",
      },
      {
        key: "bf",
        flag: "bf",
        value: "bf",
        text: "Burkina Faso",
        countryCode: "bf",
      },
      {
        key: "mm",
        flag: "mm",
        value: "mm",
        text: "Burma",
        countryCode: "mm",
        alias: "Myanmar",
      },
      {
        key: "bi",
        flag: "bi",
        value: "bi",
        text: "Burundi",
        countryCode: "bi",
      },
      {
        key: "tc",
        flag: "tc",
        value: "tc",
        text: "Caicos Islands",
        countryCode: "tc",
      },
      {
        key: "kh",
        flag: "kh",
        value: "kh",
        text: "Cambodia",
        countryCode: "kh",
      },
      {
        key: "cm",
        flag: "cm",
        value: "cm",
        text: "Cameroon",
        countryCode: "cm",
      },
      { key: "ca", flag: "ca", value: "ca", text: "Canada", countryCode: "ca" },
      {
        key: "cv",
        flag: "cv",
        value: "cv",
        text: "Cape Verde",
        countryCode: "cv",
      },
      {
        key: "ky",
        flag: "ky",
        value: "ky",
        text: "Cayman Islands",
        countryCode: "ky",
      },
      {
        key: "cf",
        flag: "cf",
        value: "cf",
        text: "Central African Republic",
        countryCode: "cf",
      },
      { key: "td", flag: "td", value: "td", text: "Chad", countryCode: "td" },
      { key: "cl", flag: "cl", value: "cl", text: "Chile", countryCode: "cl" },
      { key: "cn", flag: "cn", value: "cn", text: "China", countryCode: "cn" },
      {
        key: "cx",
        flag: "cx",
        value: "cx",
        text: "Christmas Island",
        countryCode: "cx",
      },
      {
        key: "cc",
        flag: "cc",
        value: "cc",
        text: "Cocos Islands",
        countryCode: "cc",
      },
      {
        key: "co",
        flag: "co",
        value: "co",
        text: "Colombia",
        countryCode: "co",
      },
      {
        key: "km",
        flag: "km",
        value: "km",
        text: "Comoros",
        countryCode: "km",
      },
      { key: "cd", flag: "cd", value: "cd", text: "Congo", countryCode: "cd" },
      {
        key: "cg",
        flag: "cg",
        value: "cg",
        text: "Congo Brazzaville",
        countryCode: "cg",
      },
      {
        key: "ck",
        flag: "ck",
        value: "ck",
        text: "Cook Islands",
        countryCode: "ck",
      },
      {
        key: "cr",
        flag: "cr",
        value: "cr",
        text: "Costa Rica",
        countryCode: "cr",
      },
      {
        key: "ci",
        flag: "ci",
        value: "ci",
        text: "Cote Divoire",
        countryCode: "ci",
      },
      {
        key: "hr",
        flag: "hr",
        value: "hr",
        text: "Croatia",
        countryCode: "hr",
      },
      { key: "cu", flag: "cu", value: "cu", text: "Cuba", countryCode: "cu" },
      { key: "cy", flag: "cy", value: "cy", text: "Cyprus", countryCode: "cy" },
      {
        key: "cz",
        flag: "cz",
        value: "cz",
        text: "Czech Republic",
        countryCode: "cz",
      },
      {
        key: "dk",
        flag: "dk",
        value: "dk",
        text: "Denmark",
        countryCode: "dk",
      },
      {
        key: "dj",
        flag: "dj",
        value: "dj",
        text: "Djibouti",
        countryCode: "dj",
      },
      {
        key: "dm",
        flag: "dm",
        value: "dm",
        text: "Dominica",
        countryCode: "dm",
      },
      {
        key: "do",
        flag: "do",
        value: "do",
        text: "Dominican Republic",
        countryCode: "do",
      },
      {
        key: "ec",
        flag: "ec",
        value: "ec",
        text: "Ecuador",
        countryCode: "ec",
      },
      { key: "eg", flag: "eg", value: "eg", text: "Egypt", countryCode: "eg" },
      {
        key: "sv",
        flag: "sv",
        value: "sv",
        text: "El Salvador",
        countryCode: "sv",
      },
      // { key: 'gb',flag: 'gb' ,value: 'gb' ,text: 'England', countryCode: 'gb eng' },
      {
        key: "gq",
        flag: "gq",
        value: "gq",
        text: "Equatorial Guinea",
        countryCode: "gq",
      },
      {
        key: "er",
        flag: "er",
        value: "er",
        text: "Eritrea",
        countryCode: "er",
      },
      {
        key: "ee",
        flag: "ee",
        value: "ee",
        text: "Estonia",
        countryCode: "ee",
      },
      {
        key: "et",
        flag: "et",
        value: "et",
        text: "Ethiopia",
        countryCode: "et",
      },
      {
        key: "eu",
        flag: "eu",
        value: "eu",
        text: "Europeanunion",
        countryCode: "eu",
      },
      {
        key: "fk",
        flag: "fk",
        value: "fk",
        text: "Falkland Islands",
        countryCode: "fk",
      },
      {
        key: "fo",
        flag: "fo",
        value: "fo",
        text: "Faroe Islands",
        countryCode: "fo",
      },
      { key: "fj", flag: "fj", value: "fj", text: "Fiji", countryCode: "fj" },
      {
        key: "fi",
        flag: "fi",
        value: "fi",
        text: "Finland",
        countryCode: "fi",
      },
      { key: "fr", flag: "fr", value: "fr", text: "France", countryCode: "fr" },
      {
        key: "gf",
        flag: "gf",
        value: "gf",
        text: "French Guiana",
        countryCode: "gf",
      },
      {
        key: "pf",
        flag: "pf",
        value: "pf",
        text: "French Polynesia",
        countryCode: "pf",
      },
      {
        key: "tf",
        flag: "tf",
        value: "tf",
        text: "French Territories",
        countryCode: "tf",
      },
      { key: "ga", flag: "ga", value: "ga", text: "Gabon", countryCode: "ga" },
      { key: "gm", flag: "gm", value: "gm", text: "Gambia", countryCode: "gm" },
      {
        key: "ge",
        flag: "ge",
        value: "ge",
        text: "Georgia",
        countryCode: "ge",
      },
      {
        key: "de",
        flag: "de",
        value: "de",
        text: "Germany",
        countryCode: "de",
      },
      { key: "gh", flag: "gh", value: "gh", text: "Ghana", countryCode: "gh" },
      {
        key: "gi",
        flag: "gi",
        value: "gi",
        text: "Gibraltar",
        countryCode: "gi",
      },
      { key: "gr", flag: "gr", value: "gr", text: "Greece", countryCode: "gr" },
      {
        key: "gl",
        flag: "gl",
        value: "gl",
        text: "Greenland",
        countryCode: "gl",
      },
      {
        key: "gd",
        flag: "gd",
        value: "gd",
        text: "Grenada",
        countryCode: "gd",
      },
      {
        key: "gp",
        flag: "gp",
        value: "gp",
        text: "Guadeloupe",
        countryCode: "gp",
      },
      { key: "gu", flag: "gu", value: "gu", text: "Guam", countryCode: "gu" },
      {
        key: "gt",
        flag: "gt",
        value: "gt",
        text: "Guatemala",
        countryCode: "gt",
      },
      { key: "gn", flag: "gn", value: "gn", text: "Guinea", countryCode: "gn" },
      {
        key: "gw",
        flag: "gw",
        value: "gw",
        text: "Guinea-Bissau",
        countryCode: "gw",
      },
      { key: "gy", flag: "gy", value: "gy", text: "Guyana", countryCode: "gy" },
      { key: "ht", flag: "ht", value: "ht", text: "Haiti", countryCode: "ht" },
      {
        key: "hm",
        flag: "hm",
        value: "hm",
        text: "Heard Island",
        countryCode: "hm",
      },
      {
        key: "hn",
        flag: "hn",
        value: "hn",
        text: "Honduras",
        countryCode: "hn",
      },
      {
        key: "hk",
        flag: "hk",
        value: "hk",
        text: "Hong Kong",
        countryCode: "hk",
      },
      {
        key: "hu",
        flag: "hu",
        value: "hu",
        text: "Hungary",
        countryCode: "hu",
      },
      {
        key: "is",
        flag: "is",
        value: "is",
        text: "Iceland",
        countryCode: "is",
      },
      { key: "in", flag: "in", value: "in", text: "India", countryCode: "in" },
      {
        key: "io",
        flag: "io",
        value: "io",
        text: "Indian Ocean Territory",
        countryCode: "io",
      },
      {
        key: "id",
        flag: "id",
        value: "id",
        text: "Indonesia",
        countryCode: "id",
      },
      { key: "ir", flag: "ir", value: "ir", text: "Iran", countryCode: "ir" },
      { key: "iq", flag: "iq", value: "iq", text: "Iraq", countryCode: "iq" },
      {
        key: "ie",
        flag: "ie",
        value: "ie",
        text: "Ireland",
        countryCode: "ie",
      },
      { key: "il", flag: "il", value: "il", text: "Israel", countryCode: "il" },
      { key: "it", flag: "it", value: "it", text: "Italy", countryCode: "it" },
      {
        key: "jm",
        flag: "jm",
        value: "jm",
        text: "Jamaica",
        countryCode: "jm",
      },
      {
        key: "sj",
        flag: "sj",
        value: "sj",
        text: "Jan Mayen",
        countryCode: "sj",
        alias: "Svalbard",
      },
      { key: "jp", flag: "jp", value: "jp", text: "Japan", countryCode: "jp" },
      { key: "jo", flag: "jo", value: "jo", text: "Jordan", countryCode: "jo" },
      {
        key: "kz",
        flag: "kz",
        value: "kz",
        text: "Kazakhstan",
        countryCode: "kz",
      },
      { key: "ke", flag: "ke", value: "ke", text: "Kenya", countryCode: "ke" },
      {
        key: "ki",
        flag: "ki",
        value: "ki",
        text: "Kiribati",
        countryCode: "ki",
      },
      { key: "kw", flag: "kw", value: "kw", text: "Kuwait", countryCode: "kw" },
      {
        key: "kg",
        flag: "kg",
        value: "kg",
        text: "Kyrgyzstan",
        countryCode: "kg",
      },
      { key: "la", flag: "la", value: "la", text: "Laos", countryCode: "la" },
      { key: "lv", flag: "lv", value: "lv", text: "Latvia", countryCode: "lv" },
      {
        key: "lb",
        flag: "lb",
        value: "lb",
        text: "Lebanon",
        countryCode: "lb",
      },
      {
        key: "ls",
        flag: "ls",
        value: "ls",
        text: "Lesotho",
        countryCode: "ls",
      },
      {
        key: "lr",
        flag: "lr",
        value: "lr",
        text: "Liberia",
        countryCode: "lr",
      },
      { key: "ly", flag: "ly", value: "ly", text: "Libya", countryCode: "ly" },
      {
        key: "li",
        flag: "li",
        value: "li",
        text: "Liechtenstein",
        countryCode: "li",
      },
      {
        key: "lt",
        flag: "lt",
        value: "lt",
        text: "Lithuania",
        countryCode: "lt",
      },
      {
        key: "lu",
        flag: "lu",
        value: "lu",
        text: "Luxembourg",
        countryCode: "lu",
      },
      { key: "mo", flag: "mo", value: "mo", text: "Macau", countryCode: "mo" },
      {
        key: "mk",
        flag: "mk",
        value: "mk",
        text: "Macedonia",
        countryCode: "mk",
      },
      {
        key: "mg",
        flag: "mg",
        value: "mg",
        text: "Madagascar",
        countryCode: "mg",
      },
      { key: "mw", flag: "mw", value: "mw", text: "Malawi", countryCode: "mw" },
      {
        key: "my",
        flag: "my",
        value: "my",
        text: "Malaysia",
        countryCode: "my",
      },
      {
        key: "mv",
        flag: "mv",
        value: "mv",
        text: "Maldives",
        countryCode: "mv",
      },
      { key: "ml", flag: "ml", value: "ml", text: "Mali", countryCode: "ml" },
      { key: "mt", flag: "mt", value: "mt", text: "Malta", countryCode: "mt" },
      {
        key: "mh",
        flag: "mh",
        value: "mh",
        text: "Marshall Islands",
        countryCode: "mh",
      },
      {
        key: "mq",
        flag: "mq",
        value: "mq",
        text: "Martinique",
        countryCode: "mq",
      },
      {
        key: "mr",
        flag: "mr",
        value: "mr",
        text: "Mauritania",
        countryCode: "mr",
      },
      {
        key: "mu",
        flag: "mu",
        value: "mu",
        text: "Mauritius",
        countryCode: "mu",
      },
      {
        key: "yt",
        flag: "yt",
        value: "yt",
        text: "Mayotte",
        countryCode: "yt",
      },
      { key: "mx", flag: "mx", value: "mx", text: "Mexico", countryCode: "mx" },
      {
        key: "fm",
        flag: "fm",
        value: "fm",
        text: "Micronesia",
        countryCode: "fm",
      },
      {
        key: "md",
        flag: "md",
        value: "md",
        text: "Moldova",
        countryCode: "md",
      },
      { key: "mc", flag: "mc", value: "mc", text: "Monaco", countryCode: "mc" },
      {
        key: "mn",
        flag: "mn",
        value: "mn",
        text: "Mongolia",
        countryCode: "mn",
      },
      {
        key: "me",
        flag: "me",
        value: "me",
        text: "Montenegro",
        countryCode: "me",
      },
      {
        key: "ms",
        flag: "ms",
        value: "ms",
        text: "Montserrat",
        countryCode: "ms",
      },
      {
        key: "ma",
        flag: "ma",
        value: "ma",
        text: "Morocco",
        countryCode: "ma",
      },
      {
        key: "mz",
        flag: "mz",
        value: "mz",
        text: "Mozambique",
        countryCode: "mz",
      },
      {
        key: "na",
        flag: "na",
        value: "na",
        text: "Namibia",
        countryCode: "na",
      },
      { key: "nr", flag: "nr", value: "nr", text: "Nauru", countryCode: "nr" },
      { key: "np", flag: "np", value: "np", text: "Nepal", countryCode: "np" },
      {
        key: "nl",
        flag: "nl",
        value: "nl",
        text: "Netherlands",
        countryCode: "nl",
      },
      {
        key: "an",
        flag: "an",
        value: "an",
        text: "Netherlandsantilles",
        countryCode: "an",
      },
      {
        key: "nc",
        flag: "nc",
        value: "nc",
        text: "New Caledonia",
        countryCode: "nc",
      },
      {
        key: "pg",
        flag: "pg",
        value: "pg",
        text: "New Guinea",
        countryCode: "pg",
      },
      {
        key: "nz",
        flag: "nz",
        value: "nz",
        text: "New Zealand",
        countryCode: "nz",
      },
      {
        key: "ni",
        flag: "ni",
        value: "ni",
        text: "Nicaragua",
        countryCode: "ni",
      },
      { key: "ne", flag: "ne", value: "ne", text: "Niger", countryCode: "ne" },
      {
        key: "ng",
        flag: "ng",
        value: "ng",
        text: "Nigeria",
        countryCode: "ng",
      },
      { key: "nu", flag: "nu", value: "nu", text: "Niue", countryCode: "nu" },
      {
        key: "nf",
        flag: "nf",
        value: "nf",
        text: "Norfolk Island",
        countryCode: "nf",
      },
      {
        key: "kp",
        flag: "kp",
        value: "kp",
        text: "North Korea",
        countryCode: "kp",
      },
      {
        key: "mp",
        flag: "mp",
        value: "mp",
        text: "Northern Mariana Islands",
        countryCode: "mp",
      },
      { key: "no", flag: "no", value: "no", text: "Norway", countryCode: "no" },
      { key: "om", flag: "om", value: "om", text: "Oman", countryCode: "om" },
      {
        key: "pk",
        flag: "pk",
        value: "pk",
        text: "Pakistan",
        countryCode: "pk",
      },
      { key: "pw", flag: "pw", value: "pw", text: "Palau", countryCode: "pw" },
      {
        key: "ps",
        flag: "ps",
        value: "ps",
        text: "Palestine",
        countryCode: "ps",
      },
      { key: "pa", flag: "pa", value: "pa", text: "Panama", countryCode: "pa" },
      {
        key: "py",
        flag: "py",
        value: "py",
        text: "Paraguay",
        countryCode: "py",
      },
      { key: "pe", flag: "pe", value: "pe", text: "Peru", countryCode: "pe" },
      {
        key: "ph",
        flag: "ph",
        value: "ph",
        text: "Philippines",
        countryCode: "ph",
      },
      {
        key: "pn",
        flag: "pn",
        value: "pn",
        text: "Pitcairn Islands",
        countryCode: "pn",
      },
      { key: "pl", flag: "pl", value: "pl", text: "Poland", countryCode: "pl" },
      {
        key: "pt",
        flag: "pt",
        value: "pt",
        text: "Portugal",
        countryCode: "pt",
      },
      {
        key: "pr",
        flag: "pr",
        value: "pr",
        text: "Puerto Rico",
        countryCode: "pr",
      },
      { key: "qa", flag: "qa", value: "qa", text: "Qatar", countryCode: "qa" },
      {
        key: "re",
        flag: "re",
        value: "re",
        text: "Reunion",
        countryCode: "re",
      },
      {
        key: "ro",
        flag: "ro",
        value: "ro",
        text: "Romania",
        countryCode: "ro",
      },
      { key: "ru", flag: "ru", value: "ru", text: "Russia", countryCode: "ru" },
      { key: "rw", flag: "rw", value: "rw", text: "Rwanda", countryCode: "rw" },
      {
        key: "sh",
        flag: "sh",
        value: "sh",
        text: "Saint Helena",
        countryCode: "sh",
      },
      {
        key: "kn",
        flag: "kn",
        value: "kn",
        text: "Saint Kitts and Nevis",
        countryCode: "kn",
      },
      {
        key: "lc",
        flag: "lc",
        value: "lc",
        text: "Saint Lucia",
        countryCode: "lc",
      },
      {
        key: "pm",
        flag: "pm",
        value: "pm",
        text: "Saint Pierre",
        countryCode: "pm",
      },
      {
        key: "vc",
        flag: "vc",
        value: "vc",
        text: "Saint Vincent",
        countryCode: "vc",
      },
      { key: "ws", flag: "ws", value: "ws", text: "Samoa", countryCode: "ws" },
      {
        key: "sm",
        flag: "sm",
        value: "sm",
        text: "San Marino",
        countryCode: "sm",
      },
      {
        key: "gs",
        flag: "gs",
        value: "gs",
        text: "Sandwich Islands",
        countryCode: "gs",
      },
      {
        key: "st",
        flag: "st",
        value: "st",
        text: "Sao Tome",
        countryCode: "st",
      },
      {
        key: "sa",
        flag: "sa",
        value: "sa",
        text: "Saudi Arabia",
        countryCode: "sa",
      },
      {
        key: "gb",
        flag: "gb",
        value: "gb",
        text: "Scotland",
        countryCode: "gb sct",
      },
      {
        key: "sn",
        flag: "sn",
        value: "sn",
        text: "Senegal",
        countryCode: "sn",
      },
      { key: "cs", flag: "cs", value: "cs", text: "Serbia", countryCode: "cs" },
      { key: "rs", flag: "rs", value: "rs", text: "Serbia", countryCode: "rs" },
      {
        key: "sc",
        flag: "sc",
        value: "sc",
        text: "Seychelles",
        countryCode: "sc",
      },
      {
        key: "sl",
        flag: "sl",
        value: "sl",
        text: "Sierra Leone",
        countryCode: "sl",
      },
      {
        key: "sg",
        flag: "sg",
        value: "sg",
        text: "Singapore",
        countryCode: "sg",
      },
      {
        key: "sk",
        flag: "sk",
        value: "sk",
        text: "Slovakia",
        countryCode: "sk",
      },
      {
        key: "si",
        flag: "si",
        value: "si",
        text: "Slovenia",
        countryCode: "si",
      },
      {
        key: "sb",
        flag: "sb",
        value: "sb",
        text: "Solomon Islands",
        countryCode: "sb",
      },
      {
        key: "so",
        flag: "so",
        value: "so",
        text: "Somalia",
        countryCode: "so",
      },
      {
        key: "za",
        flag: "za",
        value: "za",
        text: "South Africa",
        countryCode: "za",
      },
      {
        key: "kr",
        flag: "kr",
        value: "kr",
        text: "South Korea",
        countryCode: "kr",
      },
      { key: "es", flag: "es", value: "es", text: "Spain", countryCode: "es" },
      {
        key: "lk",
        flag: "lk",
        value: "lk",
        text: "Sri Lanka",
        countryCode: "lk",
      },
      { key: "sd", flag: "sd", value: "sd", text: "Sudan", countryCode: "sd" },
      {
        key: "sr",
        flag: "sr",
        value: "sr",
        text: "Suriname",
        countryCode: "sr",
      },
      {
        key: "sz",
        flag: "sz",
        value: "sz",
        text: "Swaziland",
        countryCode: "sz",
      },
      { key: "se", flag: "se", value: "se", text: "Sweden", countryCode: "se" },
      {
        key: "ch",
        flag: "ch",
        value: "ch",
        text: "Switzerland",
        countryCode: "ch",
      },
      { key: "sy", flag: "sy", value: "sy", text: "Syria", countryCode: "sy" },
      { key: "tw", flag: "tw", value: "tw", text: "Taiwan", countryCode: "tw" },
      {
        key: "tj",
        flag: "tj",
        value: "tj",
        text: "Tajikistan",
        countryCode: "tj",
      },
      {
        key: "tz",
        flag: "tz",
        value: "tz",
        text: "Tanzania",
        countryCode: "tz",
      },
      {
        key: "th",
        flag: "th",
        value: "th",
        text: "Thailand",
        countryCode: "th",
      },
      {
        key: "tl",
        flag: "tl",
        value: "tl",
        text: "Timorleste",
        countryCode: "tl",
      },
      { key: "tg", flag: "tg", value: "tg", text: "Togo", countryCode: "tg" },
      {
        key: "tk",
        flag: "tk",
        value: "tk",
        text: "Tokelau",
        countryCode: "tk",
      },
      { key: "to", flag: "to", value: "to", text: "Tonga", countryCode: "to" },
      {
        key: "tt",
        flag: "tt",
        value: "tt",
        text: "Trinidad",
        countryCode: "tt",
      },
      {
        key: "tn",
        flag: "tn",
        value: "tn",
        text: "Tunisia",
        countryCode: "tn",
      },
      { key: "tr", flag: "tr", value: "tr", text: "Turkey", countryCode: "tr" },
      {
        key: "tm",
        flag: "tm",
        value: "tm",
        text: "Turkmenistan",
        countryCode: "tm",
      },
      { key: "tv", flag: "tv", value: "tv", text: "Tuvalu", countryCode: "tv" },
      {
        key: "ae",
        flag: "ae",
        value: "ae",
        text: "U.A.E.",
        countryCode: "ae",
        alias: "United Arab Emirates",
      },
      { key: "ug", flag: "ug", value: "ug", text: "Uganda", countryCode: "ug" },
      {
        key: "ua",
        flag: "ua",
        value: "ua",
        text: "Ukraine",
        countryCode: "ua",
      },
      {
        key: "gb",
        flag: "gb",
        value: "gb",
        text: "United Kingdom",
        countryCode: "gb",
      },
      {
        key: "us",
        flag: "us",
        value: "us",
        text: "United States",
        countryCode: "us",
        alias: "America",
      },
      {
        key: "uy",
        flag: "uy",
        value: "uy",
        text: "Uruguay",
        countryCode: "uy",
      },
      {
        key: "um",
        flag: "um",
        value: "um",
        text: "US Minor Islands",
        countryCode: "um",
      },
      {
        key: "vi",
        flag: "vi",
        value: "vi",
        text: "US Virgin Islands",
        countryCode: "vi",
      },
      {
        key: "uz",
        flag: "uz",
        value: "uz",
        text: "Uzbekistan",
        countryCode: "uz",
      },
      {
        key: "vu",
        flag: "vu",
        value: "vu",
        text: "Vanuatu",
        countryCode: "vu",
      },
      {
        key: "va",
        flag: "va",
        value: "va",
        text: "Vatican City",
        countryCode: "va",
      },
      {
        key: "ve",
        flag: "ve",
        value: "ve",
        text: "Venezuela",
        countryCode: "ve",
      },
      {
        key: "vn",
        flag: "vn",
        value: "vn",
        text: "Vietnam",
        countryCode: "vn",
      },
      {
        key: "gb",
        flag: "gb",
        value: "gb",
        text: "Wales",
        countryCode: "gb wls",
      },
      {
        key: "wf",
        flag: "wf",
        value: "wf",
        text: "Wallis and Futuna",
        countryCode: "wf",
      },
      {
        key: "eh",
        flag: "eh",
        value: "eh",
        text: "Western Sahara",
        countryCode: "eh",
      },
      { key: "ye", flag: "ye", value: "ye", text: "Yemen", countryCode: "ye" },
      { key: "zm", flag: "zm", value: "zm", text: "Zambia", countryCode: "zm" },
      {
        key: "zw",
        flag: "zw",
        value: "zw",
        text: "Zimbabwe",
        countryCode: "zw",
      },
    ];
    // const { filter_country, filter_state } = this.state;
    return (
      <div className="fixed flex flex-col p-3 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
                <HiOutlineArrowLeft
                  size={18}
                  style={{ color: colors.darkGray }}
                />
              </Link>
              <div>
                <Link to="/" className="text-sm darkGray hover:underline">
                  Main Menu
                </Link>
                <h2 className="text-2xl">Restaurants</h2>
              </div>
            </div>
            <NavDropdown
              title={
                <div className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
                  <FiSliders size={18} color={colors.primary} />
                </div>
              }
              className="custom-dropdown right">
              <div className="absolute z-10 right-0 mt-2 w-screen max-w-[25.32rem] sm:px-0">
                <div className="bg-white rounded-lg shadow-lg">
                  <div className="relative">
                    <p className="p-2 text-center bg-gray-200 rounded-t-lg Medium">
                      Filters
                    </p>
                    <div className="p-2">
                      <div className="mb-2">
                        <p className="mb-1 fs-14">Select Distance:</p>
                        <Dropdown
                          className="border gray rounded--lg"
                          placeholder="Select Distance"
                          fluid
                          selection
                          options={[
                            {
                              key: "50 feet",
                              text: "50 feet",
                              value: "50",
                            },
                            {
                              key: "300 feet",
                              text: "300 feet",
                              value: "300",
                            },
                            {
                              key: "500",
                              text: "500 feet",
                              value: "500",
                            },
                            {
                              key: "1 mile",
                              text: "1 mile",
                              value: "1",
                            },
                            {
                              key: "3",
                              text: "3 miles",
                              value: "3 miles",
                            },
                            {
                              key: "5 miles",
                              text: "5 miles",
                              value: "5",
                            },
                            {
                              key: "10 miles",
                              text: "10 miles",
                              value: "10",
                            },
                          ]}
                          onChange={(_, { value }) =>
                            this.applyFilters("filter_distance", value)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        {/* <CountryDropdown
                          className="border gray rounded--lg"
                          placeholder="Select Country"
                          fluid
                          // labelType="short"
                          valueType="short"
                          value={filter_country}
                          onChange={(value) => this.applyFilters("filter_country", value)} /> */}
                        <Dropdown
                          className="border gray rounded--lg"
                          placeholder="Select Country"
                          fluid
                          search
                          selection
                          options={countryOptions}
                          onChange={(_, { value }) =>
                            this.applyFilters("filter_country", value)
                          }
                        />
                      </div>{" "}
                      <div className="mb-2">
                        <TextField
                          label="City"
                          className="w-full border rounded-lg"
                          variant="outlined"
                          onChange={(e) =>
                            this.applyFilters("filter_state", e.target.value)
                          }
                        />
                      </div>{" "}
                      <div className="mb-2">
                        <TextField
                          label="City"
                          className="w-full border rounded-lg"
                          variant="outlined"
                          onChange={(e) =>
                            this.applyFilters("filter_city", e.target.value)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <TextField
                          label="Zip Code"
                          className="w-full border rounded-lg"
                          variant="outlined"
                          onChange={(e) =>
                            this.applyFilters("filter_zip_code", e.target.value)
                          }
                        />
                      </div>
                      <Button
                        onClick={this.onChangeText}
                        className="flex items-center justify-center w-full mt-2 mb-2 transition duration-300 transform border-0 shadow-none bg--primary h-11 rounded--xl hover:scale-90">
                        <p className="ml-2 white">Apply</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </NavDropdown>
          </div>
          <div className="flex flex-row items-center h-10 px-3 my-4 bg-gray-200 rounded-full">
            <div className="mr-2">
              <GoSearch color={colors.darkGray} />
            </div>
            <input
              onChange={this.onSearchRestaturant}
              placeholder="Search for Restaurant"
              className="w-64 h-10 bg-transparent border-0 Regular"
            />
          </div>
          <div className="my-3 divider" />
          <div>
            <Link
              to="/restaurants"
              className="flex items-center p-2 rounded-lg hover:bg-gray-200 bg--lightPrimary">
              <div
                style={{ backgroundColor: colors.primary }}
                className="flex items-center justify-center w-10 h-10 mr-3 rounded-full">
                <IoIosAlbums size={18} style={{ color: colors.white }} />
              </div>
              <p>Browse all</p>
            </Link>
            <div className="my-3 divider" />{" "}
          </div>
        </div>
        <div className="my-3 divider" />
        <Link
          to={{
            pathname: `/bars/bar-create/${this.state.business_type}`,
            state: this.state.business_type,
          }}
          className="flex items-center justify-center w-full mb-3 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
          <FiPlus color={colors.primary} size={18} />
          <p className="ml-2 primary">Add Restaurants</p>
        </Link>
      </div>
    );
  }
}
const mapStateToProps = ({ Company, User }) => {
  return {
    data: Company.restaurants,
    isFetching: Company.isRestaurantFetching,
    total: Company.totalRestaurant,
    restaurantGetStarted: Company.restaurantGetStarted,
    user: typeof User.user !== "undefined" ? User.user : {},
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CompanyRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchRestaurant: (per_page, page, params = []) => {
      actions.fetchRestaurant(dispatch, per_page, page, params);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(Restaurantsleftaside);
