import React from "react";
import { Post, Postskeleton } from "components";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

class UserPost extends React.Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.per_page = 10;
    this.params = [];
    this.state = {
      is_open: false,
      message: "",
    };
  }

  componentDidMount() {
    // Emitter.on("POST_COLLECTION_ADD", (item) => {
    //   this.setState({ message: item.message, is_open: true });
    // });
    this._onRefresh();
  }

  componentWillUnmount() {
    //Emitter.off("POST_COLLECTION_ADD");
  }

  _onRefresh = () => {
    this.page = 1;
    this.props.fetchUserPosts(
      this.per_page,
      this.page,
      this.props.user_id,
      this.props.save_id,
      this.props.group_id
    );
  };

  loadMore = () => {
    if (
      this.props.isPublicFetching === false &&
      this.props.isNextPosts !== null
    ) {
      this.page++;
      this.props.fetchUserPosts(
        this.per_page,
        this.page,
        this.props.user_id,
        this.props.save_id,
        this.props.group_id
      );
    }
  };

  render() {
    const { isNextPosts, isPublicFetching, posts } = this.props;
    const { is_open, message } = this.state;
    return (
      <div>
        {isPublicFetching ? (
          <>
            <div className="mb-3">
              <Postskeleton />
            </div>
          </>
        ) : null}

        <InfiniteScroll
          pageStart={1}
          loadMore={this.loadMore}
          hasMore={isNextPosts ? true : false}
          loader={
            <div className="mb-3">
              <Postskeleton />
            </div>
          }
        >
          {posts.map((item, key) => (
            <div key={key} className="mb-3">
              <Post item={item} />
            </div>
          ))}
        </InfiniteScroll>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={is_open}
          onClose={() => this.setState({ is_open: false })}
          autoHideDuration={6000}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ is_open: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { user_id } = ownProps;
  return {
    posts:
      typeof state.Feed.posts !== "undefined"
        ? typeof state.Feed.posts[user_id] !== "undefined"
          ? state.Feed.posts[user_id]
          : []
        : [],
    isNextPosts:
      typeof state.Feed.nextPosts !== "undefined"
        ? typeof state.Feed.nextPosts[user_id] !== "undefined"
          ? state.Feed.nextPosts[user_id]
          : null
        : null,
    me: state.User.user,
    isPublicFetching: state.Feed.isPublicFetching,
    user: state.Profile.user,
    group: state.Group.group,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/FeedRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchUserPosts: (per_page = 10, page, user_id, save_id, group_id) => {
      actions.fetchUserPublicPosts(
        dispatch,
        per_page,
        page,
        user_id,
        save_id,
        group_id
      );
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(UserPost);
