import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FiRepeat, FiX } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import colors from "config/color/color.js";
import { MdAttachFile, MdTitle } from "react-icons/md";
import { Datepicker, FriendListPopup, Map } from "components";
import { BsClock, BsDash } from "react-icons/bs";
import Checkbox from "@material-ui/core/Checkbox";
import { Select, TextareaAutosize } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import "./styles.css";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { FaRegBell, FaUserAlt } from "react-icons/fa";
import Geocode from "react-geocode";
import moment from "moment";
import { connect } from "react-redux";
import { OptimizeImage } from "helpers";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
const GOOGLE_MAP_API_KEY = "AIzaSyCe4WVS0w0eJ9cYaNn93RSFaozztIT76B4";

const checkFileExist = (file) => {
  try {
    return URL.createObjectURL(file);
  } catch (e) {
    return "";
  }
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

class Createeventpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      repeat: "Repeat",
      all_day: false,
      map_enable: false,
      bounds: null,
      setBounds: null,
      coordinates: { lat: 0, lng: 0 },
    };
    this.createEventRef = React.createRef();
    this.popupRef = React.createRef();
    this.photoRef = React.createRef();
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        this.setState({ coordinates: { lat: latitude, lng: longitude } });
        this.props.setCurrentLocation({ lat: latitude, lng: longitude });
      }
    );
  }
  eventOpen = (item, band) => {
    let form = Object.assign({}, this.props.form);
    if (item) {
      form = {};
      form.id = item.id;
      form.name = item.name;
      form.address = item.address;
      form.latitude = item.latitude;
      form.longitude = item.longitude;
      form.lat_lng = { lat: item.latitude, lng: item.longitude };
      if (item.privacy === "public") {
        form.privacy = true;
      } else if (item.privacy === "private") {
        form.privacy = false;
      }

      form.e_alert = item.e_alert;
      form.e_repeat = item.e_repeat;

      if (item.description && item.description !== "") {
        form.description = item.description;
      } else {
        form.description = "";
      }

      form.e_allDay = item.all_day;

      if (item.start_date) {
        form.start_date = item.start_date;
        form.start_time = item.start_date;
      }

      if (item.end_date) {
        form.end_date = item.end_date;
        form.end_time = item.end_date;
      }

      form.b_country = item.country;
      if (item.bar_type_id) {
        form.b_type_id = item.bar_type_id;
      }
      var invites = [];
      if (item.invites && item.invites.length > 0) {
        item.invites.map((item, key) => {
          invites.push(item);
        });
      }
      form.invites = invites;
      if (item.band) {
        form.band = item.band;
      }

      var photos = [];
      if (item.photos && item.photos.length > 0) {
        item.photos.map((item, key) => {
          photos.push({
            ...item,
            uri: OptimizeImage(item.attachment_url),
          });
        });
      }
      form.photos = photos;

      this.props.addEventForm(form);
    } else if (band) {
      form = {
        band: band,
      };
      this.props.addEventForm(form);
    } else {
      if (form.id || form.band) {
        this.props.addEventForm({});
      }
    }
    this.setState({ open: true });
  };

  eventClose = () => {
    this.setState({ open: false });
  };

  repeatChange = (event) => {
    this.state(event.target.value);
  };

  checkWholeDay = () => {
    let all_day_status = this.state.all_day === true ? false : true;
    this.setState({ all_day: all_day_status });
    if (this.state.all_day === true) {
      this.removeTime();
    }
  };

  removeTime = () => {
    this.addEventForm("start_time", "");
    this.addEventForm("end_time", "");
  };

  addLocation = () => {
    this.setState({ map_enable: true });
  };
  addAddress = (address) => {
    let form = Object.assign({}, this.props.form);
    form = {
      ...form,
      lat_lng: { lat: 0, lng: 0 },
      address: address,
    };
    this.props.addEventForm(form);
    this.getLatLang(address);
  };
  getLatLang = (address) => {
    let form = Object.assign({}, this.props.form);
    Geocode.setApiKey(GOOGLE_MAP_API_KEY);
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const lat_lng = { lat: lat, lng: lng };
        form = {
          ...form,
          lat_lng: lat_lng,
          address: address,
        };
        this.props.addEventForm(form);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  handlestartDateChange = (startDate) => {
    this.addEventForm("start_date", startDate);
  };

  handleEndDateChange = (endDate) => {
    this.addEventForm("end_date", endDate);
  };
  handlestartTimeChange = (startTime) => {
    this.addEventForm("start_time", startTime);
  };

  handleEndTimeChange = (endTime) => {
    this.addEventForm("end_time", endTime);
  };
  onPhotoClick = () => {
    this.photoRef.current.click();
  };
  onPhotoChange = (e) => {
    let photos = Object.assign([], this.props.form.photos);
    const files = e.target.files;
    photos = [...photos, ...files];
    this.addEventForm("photos", photos);
  };
  removePhoto = (index) => {
    let photos = [...this.props.form.photos];
    photos.splice(index, 1);
    this.addEventForm("photos", photos);
  };

  addEventForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addEventForm(form);
  };

  validation = () => {
    let flag = true;
    let array = ["name", "address", "start_date", "end_date", "photos"];
    let form = Object.assign({}, this.props.form);
    array.map((item, key) => {
      if (!Object.keys(form).some((index) => index === item)) {
        this.setState({ [item]: true });
        flag = false;
      }
    });
    Object.keys(form).map((item) => {
      if (array.some((index) => index === item)) {
        if (
          form[item] === null ||
          form[item] === "null" ||
          form[item] === "" ||
          form[item].length === 0
        ) {
          this.setState({ [item]: true });
          flag = false;
        } else {
          this.setState({ [item]: false });
        }
      }
    });

    let formData = new FormData();

    if (flag) {
      let form = this.props.form;
      Object.keys(form).map(function (key) {
        if (key === "photos" && form[key]) {
          form[key].forEach((item, i) => {
            formData.append("photos[" + i + "]", item);
            if (item.id) {
              formData.append("attachments_ids[" + i + "]", item.id);
            }
          });
        } else if (key === "lat_lng" && form[key]) {
          formData.append("latitude", form[key].lat);
          formData.append("longitude", form[key].lng);
        } else if (key === "friends" && form[key]) {
          form[key].forEach((item, i) => {
            formData.append("friends[" + i + "]", item);
          });
        } else if (key === "groups" && form[key]) {
          form[key].forEach((item, i) => {
            formData.append("groups[" + i + "]", item.id);
          });
        } else if (key === "start_date" && form[key]) {
          formData.append("start_date", moment(form[key]).toISOString());
        } else if (key === "end_date" && form[key]) {
          formData.append("end_date", moment(form[key]).toISOString());
        } else if (key === "start_time" && form[key]) {
          formData.append("start_time", moment(form[key]).toISOString());
        } else if (key === "end_time" && form[key]) {
          formData.append("end_time", moment(form[key]).toISOString());
        } else if (key === "band" && form[key]) {
          formData.append("band_id", form[key].id);
        } else {
          formData.append(key, form[key]);
        }
      });

      this.props.submitEvent(formData);
      this.setState({ flag: true, open: false });
    }
  };

  render() {
    const { coordinates, setCoordinates, setBounds } = this.state;
    const { repeat } = this.state;
    const { form } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          scroll="body"
          aria-labelledby="draggable-dialog-title"
          className="custom-backdrop"
          fullWidth={true}>
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            className="relative flex items-center justify-center px-3 py-3 border-b">
            <div
              onClick={this.eventClose}
              className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer left-5 hover:bg-gray-300">
              <FiX />
            </div>

            <h2 className="text-2xl font-medium Medium">Create Event</h2>
            <p
              className="absolute z-10 flex items-center justify-center w-20 h-10 cursor-pointer right-3 top-3 hover:bg-gray-100 rounded-xl primary"
              onClick={this.validation}>
              {form.id ? "Update" : "Create"}
            </p>
          </DialogTitle>

          <DialogContent id="scrollbar" className="px-0 pb-4">
            {form.band && (
              <>
                <div className="flex items-center justify-center">
                  <h2 className="text-2xl font-medium Medium">
                    Live music event
                  </h2>
                </div>
                <div className="flex items-center justify-center mb-4">
                  <h2 className="text-primary">{form.band.band_name}</h2>
                </div>
              </>
            )}
            <div
              style={
                this.state.name
                  ? { borderColor: "red", borderWidth: 1 }
                  : { borderColor: "#fff", borderWidth: 0 }
              }
              className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
              <div className="mr-1">
                <MdTitle color={colors.darkGray} />
              </div>
              <input
                placeholder="Add Title"
                className="w-full bg-transparent border-0 h-11"
                onChange={(e) => this.addEventForm("name", e.target.value)}
                value={form.name}
              />
            </div>
            <div className="mt-3 h-11">
              <GooglePlacesAutocomplete
                className="bg-transparent"
                apiKey={GOOGLE_MAP_API_KEY}
                selectProps={{
                  onChange: (e) => this.getLatLang(e.label),
                }}
              />
            </div>
            {/* <Autocomplete> */}
            <div
              style={
                this.state.address
                  ? { borderColor: "red", borderWidth: 1 }
                  : { borderColor: "#fff", borderWidth: 0 }
              }
              className="flex flex-row items-center px-3 mt-3 bg-gray-100 border-1 rounded-xl h-11">
              <GoLocation size={14} color={colors.darkGray} className="mr-2" />
              <p>{form.address}</p>
            </div>
            {/* </Autocomplete> */}

            <div className="flex flex-row items-center px-3 mt-3 bg-gray-100 rounded-xl h-80">
              <Map
                setBounds={setBounds}
                coordinates={
                  form.lat_lng
                    ? form.lat_lng
                    : { lat: 37.773972, lng: -122.431297 }
                }
              />
            </div>

            <div className="my-3 divider" />

            <div>
              <div className="flex">
                <div className="flex items-center justify-center h-10 bg-gray-100 rounded-full w-11">
                  <BsClock color={colors.darkGray} />
                </div>
                <div>
                  <div className="flex items-center">
                    <div
                      style={
                        this.state.start_date
                          ? { borderColor: "red", borderWidth: 1 }
                          : { borderColor: "#fff", borderWidth: 0 }
                      }
                      className="px-1 hover:bg-gray-100 border-1 rounded-xl">
                      <Datepicker
                        datePicker
                        onChange={(d) => this.handlestartDateChange(d)}
                        value={moment(form.start_date).toISOString()}
                      />
                    </div>
                    {!form.e_allDay && (
                      <>
                        <div className="px-1 hover:bg-gray-100 rounded-xl w-28">
                          <Datepicker
                            onChange={(t) => this.handlestartTimeChange(t)}
                            value={moment(form.start_time).toISOString()}
                            timePicker
                          />
                        </div>
                        <BsDash />
                        <div className="px-1 hover:bg-gray-100 rounded-xl w-28">
                          <Datepicker
                            timePicker
                            onChange={(t) => this.handleEndTimeChange(t)}
                            value={moment(form.end_time).toISOString()}
                          />
                        </div>
                      </>
                    )}
                    <div
                      style={
                        this.state.end_date
                          ? { borderColor: "red", borderWidth: 1 }
                          : { borderColor: "#fff", borderWidth: 0 }
                      }
                      className="px-1 hover:bg-gray-100 border-1 rounded-xl">
                      <Datepicker
                        datePicker
                        onChange={(d) => this.handleEndDateChange(d)}
                        value={moment(form.end_date).toISOString()}
                      />
                    </div>
                  </div>
                  <div className="flex items-center -mt-2">
                    <Checkbox
                      checked={form.e_allDay ? true : false}
                      onChange={(e) =>
                        this.addEventForm("e_allDay", e.target.checked)
                      }
                      color="primary"
                    />
                    <p className="darkGray fs-13">All day .</p>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-3 divider" />
              <div className="flex items-center">
                <div className="flex items-center justify-center h-10 mr-3 bg-gray-100 rounded-full w-11">
                  <HiOutlineMenuAlt2 color={colors.darkGray} />
                </div>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Notes"
                  className="py-2.5 shadow-none resize-none Regular darkGray fs-14 rounded-lg outline-none bg-white w-full"
                  onChange={(e) =>
                    this.addEventForm("description", e.target.value)
                  }
                  value={form.description}
                />
              </div>
              <div className="mt-3 divider" />
              <div className="flex items-center">
                <div className="bg-gray-100 h-10 w-10 rounded-full flex items-center justify-center mt-3.5 mr-3">
                  <FiRepeat color={colors.darkGray} />
                </div>
                <FormControl className="w-52 ">
                  <InputLabel className="ml-2">Repeat</InputLabel>
                  <Select
                    className="overflow-hidden border rounded-lg"
                    onChange={(e) =>
                      this.addEventForm("e_repeat", e.target.value)
                    }
                    value={form.e_repeat}>
                    <MenuItem value={"dont-repeat"}>Don't Repeat</MenuItem>
                    <MenuItem value={"every-day"}>Every Day</MenuItem>
                    <MenuItem value={"every-week"}>Every Week</MenuItem>
                    <MenuItem value={"every-month"}>Every Month</MenuItem>
                    <MenuItem value={"every-year"}>Every Year</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mt-3 divider" />
              <div className="flex items-center">
                <div className="bg-gray-100 h-10 w-10 rounded-full flex items-center justify-center mt-3.5 mr-3">
                  <FaRegBell color={colors.darkGray} />
                </div>
                <FormControl className="w-52 ">
                  <InputLabel className="ml-2">Alert</InputLabel>
                  <Select
                    className="overflow-hidden border rounded-lg"
                    onChange={(e) =>
                      this.addEventForm("e_alert", e.target.value)
                    }
                    value={form.e_alert}>
                    <MenuItem value={"no-alert"}>No Alert</MenuItem>
                    <MenuItem value={"on-time"}>At time of event</MenuItem>
                    <MenuItem value={"30-minute-before"}>
                      30 minutes before
                    </MenuItem>
                    <MenuItem value={"24-hour-before"}>1 Day before</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="my-3 divider" />
              <div className="flex items-center">
                <div className="flex items-center justify-center h-10 mr-3 bg-gray-100 rounded-full w-11">
                  <FaUserAlt size={14} color={colors.darkGray} />
                </div>
                <div
                  onClick={() => this.popupRef.current.open()}
                  className="w-full px-3 py-2 bg-gray-100 rounded-lg cursor-pointer">
                  <p className="darkGray fs-14">Invitee</p>
                </div>
              </div>
              <div className="my-3 divider" />
              <div className="flex">
                <div
                  style={
                    this.state.photos
                      ? { borderColor: "red", borderWidth: 1 }
                      : { borderColor: "#fff", borderWidth: 0 }
                  }
                  className="flex items-center border-1 w-48P">
                  <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-100 rounded-full">
                    <MdAttachFile size={16} color={colors.darkGray} />
                  </div>
                  <div
                    id="file"
                    className="relative overflow-hidden cursor-pointer w-52"
                    onClick={this.onPhotoClick}>
                    <p className="darkGray fs-14">Add attachment</p>
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      onChange={this.onPhotoChange}
                      ref={this.photoRef}
                    />
                    {/* <Form.Control
                      type="file"
                      multiple
                      className="absolute bottom-0 left-0 h-20 opacity-0 cursor-pointer w-52"
                    /> */}
                  </div>
                </div>
                <div className="grid gap-4 mt-3 lg:grid-cols-3">
                  {form.photos &&
                    form.photos.map((item, key) => {
                      return (
                        <div className="p-2 mt-3 overflow-hidden bg-white border shadow-md rounded-xl">
                          <div className="relative flex items-center justify-center h-20 bg-gray-100 cursor-pointer rounded-xl">
                            <img
                              src={item.uri ? item.uri : checkFileExist(item)}
                            />
                            <div className="bg--danger w-4 h-4 rounded-full flex items-center justify-center cursor-pointer absolute -top-1.5 -right-1.5">
                              <FiX
                                onClick={() => this.removePhoto(key)}
                                color={colors.white}
                                size={12}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <FriendListPopup
          ref={this.popupRef}
          title="Invite friends"
          isCreateGroupInvite={true}
          isTag={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.Event.form,
    isProcessing: state.Event.isProcessing,
    error: state.Event.error,
    message: state.Event.message,
    groupData: state.Group.data,
    selectedAddress: state.Event.selectedAddress,
    friends: state.People.friends,
    isFetching: state.People.isFriendFetching,
    total: state.People.totalFriends,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/EventRedux");
  const { actions: groupActions } = require("redux/GroupRedux");
  const { actions: friendsActions } = require("redux/PeopleRedux");
  return {
    ...ownProps,
    ...stateProps,
    addEventForm: (data) => {
      dispatch(actions.addEventForm(data));
    },
    setCurrentLocation: (data) => {
      dispatch(actions.setCurrentLocation(data));
    },
    submitEvent: (data) => {
      actions.submitEvent(dispatch, data);
    },
    fetchGroups: (per_page, page) => {
      groupActions.fetchGroups(dispatch, per_page, page);
    },
    fetchFriends: (per_page, page, params = []) => {
      friendsActions.fetchFriends(dispatch, per_page, page, params);
    },
    saveAddress: (data) => {
      dispatch(actions.saveAddress(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Createeventpopup);
