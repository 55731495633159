import React, { Component } from "react";
import { Image } from "react-bootstrap";
import "./styles.css";
import { Link } from "react-router-dom";
import Social from "assets/images/weeee/social.png";
import Friends from "assets/images/weeee/friends.png";
import Chats from "assets/images/weeee/chats.png";
import Flirts from "assets/images/weeee/flirts.png";
import Bars from "assets/images/weeee/bars.png";
import my_bars from "assets/images/weeee/my_bars.png";
import groups from "assets/images/weeee/groups.png";
import events from "assets/images/weeee/events.png";
import calendar from "assets/images/weeee/calendar.png";
import live_music from "assets/images/weeee/live_music.png";
import my_band from "assets/images/weeee/my_band.png";
import restaurant from "assets/images/weeee/restaurant.png";
import feedback from "assets/images/weeee/feedback.png";
import homes from "assets/images/weeee/homes.png";
import bill from "assets/images/weeee/bill.png";
import how_to from "assets/images/weeee/how_to.png";
import vacation from "assets/images/weeee/vacation.png";

// import SocialFeed from "assets/images/SocialFeed.webp";
// import MyFriends from "assets/images/MyFriends.webp";
// import groups from "assets/images/groups.webp";
import saved from "assets/images/saved.webp";
// import Mymessages from "assets/images/Mymessages.webp";
// import Flirt from "assets/images/Flirt.webp";
// import Bars from "assets/images/Bars.webp";
// import Restaurant from "assets/images/Restaurant.webp";
// import MyCompany from "assets/images/MyCompany.webp";
import WhoWantstoJoin from "assets/images/WhoWantstoJoin.webp";
// import feedback from "assets/images/feedback.webp";
// import billofRights from "assets/images/billofRights.webp";
// import Howto from "assets/images/Howto.webp";
import Menu1000Photos from "assets/images/Menu1000Photos.webp";
import Vacation from "assets/images/Vacation.webp";
import Social_Seller from "assets/images/Social_Seller.webp";
import MyCRM from "assets/images/MyCRM.webp";
import Jobs from "assets/images/Jobs.webp";
// import Events from "assets/images/Events.webp";
import Bank from "assets/images/bank.webp";
import Cars from "assets/images/Cars.webp";
// import Calendar from "assets/images/Calendar.webp";
import Homes from "assets/images/Homes.webp";
import Apartments from "assets/images/Apartments.webp";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        id="scrollbar"
        className="h-80vh  overflow-y-auto p-3 left-side-gradients"
      >
        <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3">
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={Social}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Social Feed</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/friends" className="flex flex-col items-center">
              <Image
                src={Friends}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Friends</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/messenger" className="flex flex-col items-center">
              <Image
                src={Chats}
                className=" h-20 w-full object-cover rounded"
              />
              {/* <p className="py-1">Comms</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/bars" className="flex flex-col items-center">
              <Image src={Bars} className=" h-24 w-full object-cover rounded" />
              {/* <p className="py-1">Bars</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/restaurants" className="flex flex-col items-center">
              <Image
                src={restaurant}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Restaurants</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link
              to="/flirts/available-flirts"
              className="flex flex-col items-center"
            >
              <Image
                src={Flirts}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Flirts</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/live-music/near" className="flex flex-col items-center">
              <Image
                src={live_music}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Flirts</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/groups" className="flex flex-col items-center">
              <Image
                src={groups}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Groups</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/events/nearme" className="flex flex-col items-center">
              <Image
                src={events}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Events</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/events/calendar" className="flex flex-col items-center">
              <Image
                src={calendar}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Calendar</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/my_companies" className="flex flex-col items-center">
              <Image
                src={my_bars}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Calendar</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/bands/my-bands" className="flex flex-col items-center">
              <Image
                src={my_band}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Calendar</p> */}
            </Link>
          </div>

          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={feedback}
                className=" h-24 w-full object-cover rounded"
              />
              {/* <p className="py-1">Feedback</p> */}
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/bill-of-rights" className="flex flex-col items-center">
              <Image src={bill} className=" h-24 w-full object-cover rounded" />
              {/* <p className="py-1">Bill of Rights</p> */}
            </Link>
          </div>
          {/* <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/tutorial" className="flex flex-col items-center">
              <Image
                src={how_to}
                className=" h-24 w-full object-cover rounded"
              />
              <p className="py-1">How To</p>
            </Link>
          </div> */}

          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/saved" className="flex flex-col items-center">
              <Image
                src={saved}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Saved</p>
            </Link>
          </div>

          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/share-us" className="flex flex-col items-center">
              <Image
                src={WhoWantstoJoin}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Share Us</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/leaderboard/usa" className="flex flex-col items-center">
              <Image
                src={Menu1000Photos}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Win $1000</p>
            </Link>
          </div>
        </div>
        <div className="divider my-3" />
        <p className="Medium text-2xl mb-4 text-white">Coming Soon</p>
        <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3">
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image src={Bank} className=" h-20 w-full object-cover rounded" />
              <p className="py-1">Bank</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image src={Cars} className=" h-20 w-full object-cover rounded" />
              <p className="py-1">Cars</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={Apartments}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Apartments</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={Homes}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Homes</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={Vacation}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Vacation</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/public/jobs" className="flex flex-col items-center">
              <Image src={Jobs} className=" h-20 w-full object-cover rounded" />
              <p className="py-1">Jobs</p>
            </Link>
          </div>

          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={Social_Seller}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">Social Seller</p>
            </Link>
          </div>
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <Link to="/" className="flex flex-col items-center">
              <Image
                src={MyCRM}
                className=" h-20 w-full object-cover rounded"
              />
              <p className="py-1">My CRM</p>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
