import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import "./styles.css";

export default function Datepicker(props) {
  const [selectedDate, handleDateChange] = React.useState(new Date());

  return (
    <div className="w-full p-2 py-1 custom-picker">
      {props.datePicker && (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          className="w-full border-0">
          <DatePicker value={props.value} onChange={props.onChange} />
        </MuiPickersUtilsProvider>
      )}
      {props.timePicker && (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          className="w-full border-0">
          <TimePicker value={props.value} onChange={props.onChange} />
        </MuiPickersUtilsProvider>
      )}
    </div>
  );
}
