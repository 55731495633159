import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Combobox, Transition } from "@headlessui/react";
import { FiCheck, FiChevronDown } from "react-icons/fi";
import moment from "moment";

const { actions } = require("redux/BarEnhancement");

export default function Time({ timeList, field }) {
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");

  const form = useSelector((state) =>
    Object.assign({}, state.enhancement.scheduleForm)
  );

  const addForm = (key, value) => {
    const data = form;
    data[key] = value;
    dispatch(actions.addScheduleForm(data));
  };

  const onChangeTime = (field, value) => {
    const measure = moment(form[field], "HH:mm:ss").isValid() ? moment(form[field], "HH:mm:ss").format("A") : "AM";
    const time = moment(value + " " + measure, ["hh:mm A"]);
    let finalTime = "";
    if (time.isValid()) {
      finalTime = time.format("HH:mm:ss");
    }
    addForm(field, finalTime);
    setQuery("");
  }

  const onChangeUnit = (e) => {
    const measure = moment(form?.[field], "HH:mm:ss").isValid() ? moment(form?.[field], "HH:mm:ss").format("hh:mm") : "";
    const time = moment(measure + " " + e.target.value, ["hh:mm A"]);
    let finalTime = "";
    if (time.isValid()) {
      finalTime = time.format("HH:mm:ss");
    }
    addForm(field, finalTime);
    setQuery("");
  }

  const search = (e) => {
    setQuery(e.target.value);
  }

  if (query.length > 0) {
    timeList = timeList.filter(i => i.name.includes(query));
  }


  return (
    <div className="w-full flex flex-row justify-center items-center">
      <Combobox value={form} onChange={(e) => onChangeTime(field, e.id)}>
        <div className="relative">
          <div className="relative w-full py-3 overflow-hidden text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full text-[16px] border-none py-1 pl-3 pr-10 leading-5 text-gray-900 focus:ring-0"
              //displayValue={(person) => (moment(person[field], "HH:mm:ss").format("hh:mm") || "Closed") ?? "Closed"}
              displayValue={(person) => {
                let time = moment(person?.[field], "HH:mm:ss");
                if (time.isValid()) {
                  return time.format("hh:mm");
                }
                return "Closed";
              }}
              onChange={search}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <FiChevronDown
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />{" "}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          //afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg scrollbar max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {timeList.map((person) => (
                <Combobox.Option
                  key={person.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg--primary text-white" : "text-gray-900"
                    }`
                  }
                  value={person}>
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                          }`}>
                        {person.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "primary"
                            }`}>
                          <FiCheck className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {form?.[field] &&
        <select onChange={onChangeUnit} value={moment(form?.[field], "HH:mm:ss").isValid() ? moment(form?.[field], "HH:mm:ss").format("A") : "AM"} class="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value={"AM"} >AM</option>
          <option value="PM">PM</option>
        </select>}
    </div>
  );
}
