import React, { Component } from "react";
import "./styles.css";

class FeedRightAside extends Component {
  render() {
    return (
      <div
        className="fixed py-3 overflow-y-auto rightsiderbar overflow-show"
        id="scrollbar">
        {/* <div className="px-3 py-2 bg-white shadow-sm rounded-xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <FaBirthdayCake
                className="mr-3"
                size={15}
                color={colors.warning}
              />
              <p>Birthdays</p>
            </div>
            <div className="flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-100 h-9 w-9">
              <FiX size={15} />
            </div>
          </div>
          <div className="my-1 border-t" />
          <div className="px-2 py-3 -mx-2 hover:bg-gray-100 rounded-xl">
            <p className="fs-14 darkGray">
              <Link to='/' className="black">Micheal Cokm</Link> and
              <Link to='/' className="black"> Chalerim Sodani </Link> have Birthday
              today.
            </p>
          </div>
        </div> */}
        <p className="my-2 darkGray ">Advertising</p>
        <div className="mr-2">
          <div className="overflow-hidden rounded-lg">
            <img
              alt="Advertising"
              className="object-cover w-full mr-3 rounded-lg h-44"
              src="https://via.placeholder.com/250x200?text=250x200"
            />
          </div>
        </div>
        <div className="mt-2 mr-2">
          <div className="overflow-hidden rounded-lg">
            <img
              alt="Advertising"
              className="object-cover w-full h-full mr-3 rounded-lg"
              src="https://via.placeholder.com/250x300?text=250x300"
            />
          </div>
        </div>
        {/*    <div className="my-3 border-t" />
        <div className="">
          <Link to='/' className="relative rounded-lg hover_show_box">
            <Image
              src={food1}
              className="object-cover w-full mr-3 rounded-lg h-44"
            />
            <div>
              <h4 className="text-center fs-13">
                Create your sponsored business
              </h4>
              <p className="text-sm text-center gray">www.visit.com</p>
            </div>
            <div className="z-10 hover_show_drpdwn right-2 top-2">
              <Dropdown
                icon={false}
                wrapSelection={true}
                direction="left"
                pointing="left"
                text={
                  <a
                    href="javascript:;"
                    className="z-10 flex items-center justify-center w-8 h-8 m-auto bg-white border rounded-full shadow-sm hover_dots_drpdwn hover:bg-gray-200"
                  >
                    <BsThreeDots
                      size={18}
                      style={{ color: colors.darkGray }}
                      className="m-auto"
                    />
                  </a>
                }
              >
                <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                  <div className="relative p-2">
                    <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                      <FiXSquare
                        size={18}
                        className="mr-3"
                        style={{ color: colors.black }}
                      />
                      <p>Hide Ad</p>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                      <VscReport
                        size={18}
                        className="mr-3"
                        style={{ color: colors.black }}
                      />
                      <p>Report Ad</p>
                    </div>
                    <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                      <FiInfo
                        size={18}
                        className="mr-3"
                        style={{ color: colors.black }}
                      />
                      <p>Why am I seeing this ad?</p>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Link>
        </div>
       <div className="my-3 border-t" />
        <div>
          <p className=" darkGray">Create Group</p>
          <Link
            to="/groups/create"
            className="flex items-center p-2 mt-2 rounded-lg cursor-pointer hover:bg-gray-200"
          >
            <div className="flex items-center justify-center w-10 h-10 mr-3 bg-gray-300 rounded-full">
              <FiPlus size={20} color={colors.black} />
            </div>
            <p>Create Group</p>
          </Link>
        </div> */}
        {/* <div className="my-3 border-t" />
        <div className="flex items-center justify-between">
          <p className="darkGray ">Online Friends</p>
          <Dropdown
            icon={false}
            direction="left"
            pointing="left"
            text={
              <div className="flex items-center justify-center w-8 h-8 m-auto rounded-full hover_dots_drpdwn hover:bg-gray-200">
                <BsThreeDots
                  size={18}
                  style={{ color: colors.darkGray }}
                  className="m-auto"
                />
              </div>
            }
          >
            <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
              <div className="p-2">
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <MdNearMe size={18} style={{ color: colors.black }} />
                  </div>
                  <p>Near Me</p>
                </div>
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <FiVolume2 size={16} style={{ color: colors.black }} />
                  </div>
                  <p>Suggestions</p>
                </div>
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <MdContacts size={16} style={{ color: colors.black }} />
                  </div>
                  <p>Contacts</p>
                </div>
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <GiFamilyHouse size={16} style={{ color: colors.black }} />
                  </div>
                  <p>Family & Relatives</p>
                </div>
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <MdBusinessCenter
                      size={16}
                      style={{ color: colors.black }}
                    />
                  </div>
                  <p>Business Associate</p>
                </div>
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <RiUserFollowLine
                      size={16}
                      style={{ color: colors.black }}
                    />
                  </div>
                  <p>Followers</p>
                </div>
                <div className="flex items-center px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                  <div className="flex items-center justify-center w-8 h-8 mr-2 bg-gray-200 rounded-full">
                    <MdBlock size={16} style={{ color: colors.black }} />
                  </div>
                  <p>Block</p>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="mt-2">
          <Popup
            trigger={
              <Link to='/' className="flex items-center p-2 rounded-lg hover:bg-gray-200">
                <div>
                  <Image
                    src={avatar2}
                    className="object-cover w-12 h-12 mr-3 bg-transparent border-0 rounded-full"
                  />
                  <OnlineStatusXS />
                </div>
                <p>User Name</p>
              </Link>
            }
            flowing
            hoverable
            position="left center"
            className="p-3 border-0 rounded--lg w-82"
          >
            <div className="flex">
              <Link to='/' className="mr-3 ">
                <Image
                  src={avatar2}
                  className="object-cover bg-transparent border-0 rounded-full h-28 w-28"
                />
                <OnlineStatus />
              </Link>
              <div className="max-w-xs">
                <Link to='/' className="block mb-2 text-xl rounded-lg black Medium">
                  User Name
                </Link>
                <div className="flex mb-2">
                  <div className="mt-1 mr-3">
                    <HiUsers size={18} style={{ color: colors.gray }} />
                  </div>
                  <div className="flex flex-wrap">
                    <p className="mr-1 darkGray Light">
                      120 mutual friends including
                    </p>
                    <Link to='/' className="black"> Davied Warner</Link>
                    <p className="mx-1 darkGray Light">and</p>
                    <Link to='/' className="black"> Davied Bumfet</Link>
                  </div>
                </div>
                <div className="flex">
                  <div className="mr-3 ">
                    <AiFillHome size={18} style={{ color: colors.gray }} />
                  </div>
                  <div className="flex flex-wrap">
                    <p className="darkGray Light">Lives in</p>
                    <Link to='/' className="ml-1 black">Lahore, Pakistan</Link>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div> */}
      </div>
    );
  }
}

export default FeedRightAside;
