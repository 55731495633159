import React, { Component } from "react";
import { Popup } from "semantic-ui-react";

class MutualfriendsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Popup
          inverted
          position="bottom center"
          content={
            <div>
              <p className="text-sm cursor-pointer white Light">
                User Name one
              </p>
              <p className="text-sm cursor-pointer white Light">
                User Name one
              </p>
              <p className="text-sm cursor-pointer white Light">
                User Name one
              </p>
              <p className="text-sm cursor-pointer white Light">
                User Name one
              </p>
              <p className="text-sm cursor-pointer white Light">
                User Name one
              </p>
            </div>
          }
          trigger={
            <p className="inline-block text-sm cursor-pointer darkGray Light">
              12 mutual friends
            </p>
          }
        />
      </>
    );
  }
}

export default MutualfriendsPopup;
