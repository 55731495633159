import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { AiFillHome } from "react-icons/ai";
import { Capitalize } from "helpers";
import { Avatarimage } from "components";

class Peoplenamepopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLocation = () => {
    const { item } = this.props;
    let location = "";
    if (item.zip_code) {
      location += item.zip_code + ", ";
    }
    if (item.street) {
      location += item.street + ", ";
    }
    if (item.city) {
      location += item.city + ", ";
    }
    if (item.cca2) {
      location += item.cca2 + ", ";
    }
    return location.replace(/,\s*$/, "");
  };

  render() {
    const { item } = this.props;
    return (
      <Popup
        trigger={
          <Link
            to={`/${item.user_name}`}
            className="flex items-center rounded-lg hover:underline black Medium">
            {Capitalize(item.first_name)} {Capitalize(item.last_name)}
          </Link>
        }
        flowing
        hoverable
        position="top left"
        className="p-3 border-0 rounded--lg w-80">
        <div>
          <div className="flex gap-x-2">
            <Link to={`/${item.user_name}`}>
              <Avatarimage imageSize={{ height: 52, width: 52 }} item={item} />
            </Link>
            <div className="w-full max-w-sm">
              <Link
                to={`/${item.user_name}`}
                className="block mb-1 text-xl rounded-lg black Medium">
                {Capitalize(item.first_name)} {Capitalize(item.last_name)}
              </Link>
              {!!this.renderLocation() && (
                <div className="flex gap-x-2">
                  <div>
                    <AiFillHome size={15} className="gray" />
                  </div>
                  <div className="flex flex-wrap gap-x-1">
                    <p className="darkGray Light fs-13">Lives in</p>
                    <p className="black fs-13">{this.renderLocation()}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="flex items-center mt-3">
            <Button
              style={{ color: colors.primary }}
              className="flex items-center justify-center flex-grow h-10 border-0 shadow-none bg--lightPrimary rounded--lg Regular hover:bg--lightPrimary focus:bg--lightPrimary hover:opacity-70 "
            >
              <BsPersonCheckFill
                style={{ color: colors.primary }}
                className="mr-2"
              />
              Respond
            </Button>

            <Dropdown
              icon={false}
              wrapSelection={true}
              direction="left"
              pointing="left"
              text={
                <Button className="flex items-center h-10 ml-3 border-0 shadow-none bg--lightGray rounded--lg Regular hover:bg--lightGray focus:bg--lightGray hover:opacity-70 ">
                  <HiDotsHorizontal style={{ color: colors.black }} />
                </Button>
              }
            >
              <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-72">
                <div className="relative p-2">
                  <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                    <VscReport
                      size={18}
                      className="mr-3"
                      style={{ color: colors.black }}
                    />
                    <p>Find support or report profile</p>
                  </div>
                  <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                    <MdBlock
                      size={18}
                      className="mr-3"
                      style={{ color: colors.black }}
                    />
                    <p>Block</p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
      </Popup>
    );
  }
}

export default Peoplenamepopup;
