import React from "react";
import ReactDOM from "react-dom";
import CustomRoutes from "./Route";
import "./index.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
import "./assets/css/style.css";
import "./config/fonts/fontfamily.css";
import "./config/color/color.css";
import "react-date-range/dist/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-phone-input-2/lib/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import firebase from "firebase/app";
import { FirebaseConfig } from "common";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import store from "./store";

if (!firebase.apps.length) {
  firebase.initializeApp(FirebaseConfig);
}
const persistor = persistStore(store);

ReactDOM.hydrate(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate persistor={persistor}>
        <CustomRoutes />
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
