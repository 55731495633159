import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
class Mykeyword extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { barId } = this.props;
    // this.props.fetchCompanyKeyword(barId);
  }
  render() {
    return (
      <div className="block p-3 overflow-hidden bg-white shadow-md rounded-xl">
        <div className="mb-2">
          <TextField
            // value={this.props.form.b_name}
            //     onChange={(e) =>
            // this.addBarForm("b_name", e.target.value)}
            label="Keywords"
            className="w-full border rounded-lg"
            variant="outlined"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Company, User }) => {
  const b_id =
    typeof Company.form.b_id !== "undefined" ? Company.form.b_id : null;
  return {
    id: b_id,
    keywords:
      typeof Company.keywords[b_id] !== "undefined"
        ? Company.keywords[b_id]
        : [],
    user: typeof User.user !== "undefined" ? User.user : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const id = stateProps.id;
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CompanyRedux");
  return {
    ...ownProps,
    ...stateProps,
    updateCompanyKeyword: (data) => {
      actions.updateCompanyKeyword(dispatch, id, data);
    },
    fetchCompanyKeyword: () => {
      actions.fetchCompanyKeyword(dispatch, { id });
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Mykeyword);
