import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";

class Bizhours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywordsList: [],
      date: new Date(),
      mode: "time",
      show: false,
      visible: false,
      index: 0,
      type: "from",
      redirect: false,
    };
  }

  componentDidMount() {
    //  const { fetchBar, id } = this.props;
    // fetchBar(id);
    // if (Object.keys(this.props.bar).length === 0) {
    //     return <Loader active={true} />;
    // }
    let form = Object.assign({}, this.props.form);
    if (form.hours && form.hours.length === 7) {
      return false;
    }
    form.hours = [];
    form.hours[0] = {
      day: "Monday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    form.hours[1] = {
      day: "Tuesday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    form.hours[2] = {
      day: "Wednesday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    form.hours[3] = {
      day: "Thursday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    form.hours[4] = {
      day: "Friday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    form.hours[5] = {
      day: "Saturday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    form.hours[6] = {
      day: "Sunday",
      open_time: new Date(),
      close_time: new Date(),
      is_open: true,
    };
    this.props.addCompanyForm(form);
  }
  // componentDidUpdate(prevProps) {

  //   if (prevProps.bar !== this.props.bar) {
  //     let item = this.props.bar;
  //     if (Object.keys(item).length > 0) {
  //       let form = Object.assign({}, this.props.form);
  //       let hours = [];

  //       form.b_id = item.id;
  //       form.b_name = item.name;
  //       form.b_address = item.address;
  //       form.b_city = item.city;
  //       form.b_state = item.state;
  //       form.b_postal_code = item.postal_code;
  //       form.phone_number = item.phone_number;
  //       form.website = item.website == "null" ? "" : item.website;
  //       form.b_country = item.country;
  //       if (item.bar_type_id) {
  //         form.b_type_id = item.bar_type_id;
  //       }
  //       var photos = [];
  //       if (item.photos && item.photos.length > 0) {
  //         item.photos.map((item, key) => {
  //           photos.push(OptimizeImage(item.attachment_url));
  //         });
  //       }
  //       form.photos = photos;
  //       this.setState({ photos: photos });
  //       form.type = item.type;
  //       form.services = item.bar_services.map((service) => service.id);
  //       if (item.bar_hours && item.bar_hours.length > 0) {
  //         item.bar_hours.map((hour, key) => {
  //           hours.push({
  //             day: hour.day,
  //             is_open: hour.is_open,
  //             open_time: moment.utc(hour.open_time).local().toDate(),
  //             close_time: moment.utc(hour.close_time).local().toDate(),
  //           });
  //         });
  //       }
  //       form.hours = hours;

  //       form.logo = item.logo
  //         ? OptimizeImage(item.logo.attachment_url)
  //         : null;
  //       form.banner = item.banner
  //         ? OptimizeImage(item.banner.attachment_url)
  //         : null;

  //       this.props.addCompanyForm(form);
  //     }
  //   }

  // }
  validation = () => {
    var formData = new FormData();
    let form = this.props.form;
    Object.keys(form).map(function (key) {
      if (key === "hours") {
        form[key].forEach((item, i) => {
          formData.append("hours[" + i + "]", JSON.stringify(item));
        });
      } else {
        formData.append(key, form[key]);
      }
    });
    formData.append("screen", "bizHours");
    this.props.submitCompany(formData);
    this.setState({ redirect: true });
  };
  addBarForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addBarForm(form);
  };
  changeDay = (index) => {
    // alert('test success');
    let form = Object.assign({}, this.props.form);
    let { hours } = form;
    hours[index] = { ...hours[index], is_open: !hours[index].is_open };
    form.hours = hours;
    this.props.addCompanyForm(form);
  };
  setDate = (index, date, type) => {
    let form = Object.assign({}, this.props.form);
    let { hours } = form;
    if (type === "from") {
      hours[index] = {
        ...hours[index],
        open_time: new Date("2020, 10, 6," + date),
      };
    } else {
      hours[index] = {
        ...hours[index],
        close_time: new Date("2020, 10, 6," + date),
      };
    }
    form.hours = hours;
    this.props.addCompanyForm(form);
    this.setState({ date });
  };
  openTime = (index, from) => {
    this.setState({ show: true, index: index, type: "from", date: from });
  };
  closeTime = (index, to) => {
    this.setState({ show: true, index: index, type: "to", date: to });
  };

  render() {
    // if (Object.keys(this.props.bar).length === 0) {
    //   return <Loader active={true} />;
    // }
    const { id } = this.props;
    if (this.state.redirect) {
      return <Redirect to={"/bars/bar-details/" + id} />;
    }
    return (
      <div className="block p-3 overflow-hidden bg-white shadow-md rounded-xl">
        {this.props.form.hours &&
          this.props.form.hours.map((item, key) => (
            <>
              <div className="flex items-center">
                <Checkbox
                  onClick={() => this.changeDay(key)}
                  checked={item.is_open ? true : false}
                  color="primary"
                />
                <p>{item.day}</p>
              </div>
              {item.is_open ? (
                <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center px-3 bg-gray-200 rounded-lg h-9">
                    <p className="mr-2 fs-13 darkGray">From</p>
                    <input
                      type="time"
                      max="24"
                      value={moment(item.open_time).format("hh:mm:ss")}
                      onChange={(e) =>
                        this.setDate(key, e.target.value, "from")
                      }
                      className="bg-transparent border-0 h-9 fs-13 black Regular"
                    />
                    {/* <p>{moment(item.open_time).format('hh:mm A') }</p> */}
                  </div>
                  <div className="flex flex-row items-center px-3 bg-gray-200 rounded-lg h-9">
                    <p className="mr-2 fs-13 darkGray">To</p>
                    <input
                      type="time"
                      //  onChange={(e) => this.closeTime(key, e.target.value)}
                      onChange={(e) => this.setDate(key, e.target.value, "to")}
                      className="bg-transparent border-0 h-9 fs-13 black Regular"
                      value={moment(item.close_time).format("hh:mm")}
                    />
                    {/* <p>{moment(item.close_time).format('hh:mm A') }</p> */}
                  </div>
                </div>
              ) : (
                <p className="text-danger">Close</p>
              )}
            </>
          ))}
        <div className="mt-5 text-center">
          <Button
            onClick={() => this.validation()}
            className="border-0 shadow-none bg--primary hover:bg--primary focus:bg--primary rounded--lg w-50">
            Save
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Company }) => {
  return {
    // bar : Bar.bar,
    form: Company.form,
    isProcessing: Company.isProcessing,
    // user: typeof User.user !== "undefined" ? User.user : {},
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/CompanyRedux");
  const { actions: barActions } = require("redux/BarRedux");
  return {
    ...ownProps,
    ...stateProps,
    addCompanyForm: (data) => {
      dispatch(actions.addCompanyForm(data));
    },
    submitCompany: (data) => {
      actions.submitCompany(dispatch, data);
    },
    fetchBar: (id) => {
      barActions.fetchBar(dispatch, id);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Bizhours);
