import React, { Component } from "react";
import "./styles.css";
import colors from "config/color/color.js";
import { NavLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { FaUserFriends } from "react-icons/fa";
import { GiFamilyHouse } from "react-icons/gi";
import { MdBusinessCenter } from "react-icons/md";
import {
  BsFillPersonPlusFill,
  BsPersonLinesFill,
  BsFillPersonCheckFill,
} from "react-icons/bs";
import { RiUserSharedFill } from "react-icons/ri";
import { HiOutlineArrowLeft } from "react-icons/hi";

class FriendsLeftAside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="fixed p-6 bg-white shadow-md FriendsLeftAside">
        <div className="flex items-center">
          <NavLink
            to="/"
            className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
            <HiOutlineArrowLeft size={18} style={{ color: colors.darkGray }} />
          </NavLink>
          <div>
            <NavLink to="/" className="text-sm darkGray hover:underline">
              Main Menu
            </NavLink>
            <h2 className="text-2xl font-medium Medium">Friends</h2>
          </div>
        </div>
        <div className="my-2 border-t" />
        <div className="mt-3 -mx-2">
          <NavLink
            exact
            to="/friends"
            className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
              <FaUserFriends size={20} style={{ color: colors.white }} />
            </div>
            Home
          </NavLink>
          <NavLink
            exact
            to="/friends/requests"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <RiUserSharedFill size={20} style={{ color: colors.white }} />
              </div>
              Pending Invites
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/invites"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <RiUserSharedFill size={20} style={{ color: colors.white }} />
              </div>
              Sent Invites
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/suggestions"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <BsFillPersonPlusFill
                  size={20}
                  style={{ color: colors.white }}
                />
              </div>
              Suggestions
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/list"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <BsPersonLinesFill size={20} style={{ color: colors.white }} />
              </div>
              All Friends
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/family-relatives"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <GiFamilyHouse size={18} style={{ color: colors.white }} />
              </div>
              Family & Relatives
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/business-associates"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <MdBusinessCenter size={20} style={{ color: colors.white }} />
              </div>
              Business Associate
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/co-workers"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <MdBusinessCenter size={20} style={{ color: colors.white }} />
              </div>
              Co-Workers
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/customers"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <MdBusinessCenter size={20} style={{ color: colors.white }} />
              </div>
              Customers
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
          <NavLink
            exact
            to="/friends/followers"
            className="flex items-center justify-between p-2 mt-2 rounded-lg black hover:bg-gray-200"
            activeClassName="bg--lightPrimary fill:primary-svg"
            activeStyle={{ color: colors.primary }}>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <BsFillPersonCheckFill
                  size={20}
                  style={{ color: colors.white }}
                />
              </div>
              Followers
            </div>
            <FiChevronRight
              size={24}
              style={{ color: colors.darkGray }}
              className="svg"
            />
          </NavLink>
        </div>
      </div>
    );
  }
}

export default FriendsLeftAside;
