import React, { Component } from "react";
import colors from "config/color/color.js";
import { NavLink } from "react-router-dom";
import { FaRegCalendarPlus } from "react-icons/fa";
import { GiMineExplosion } from "react-icons/gi";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Buttonused, Createbandpopup } from "components";
import { Emitter } from "common";
import { IoTicketOutline } from "react-icons/io5";

class Bandaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
    };
    this.createEventRef = React.createRef();
  }
  componentDidMount() {
    Emitter.on("OPEN_BAND_MODAL", (item) => {
      this.createEventRef.current?.eventOpen(item);
    });
  }

  render() {
    return (
      <div className="fixed flex flex-col p-6 bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow ">
          <div className="flex items-center">
            <NavLink
              to="/"
              className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
              <HiOutlineArrowLeft
                size={18}
                style={{ color: colors.darkGray }}
              />
            </NavLink>
            <div>
              <NavLink to="/" className="text-sm darkGray hover:underline">
                Main Menu
              </NavLink>
              <h2 className="text-2xl font-medium Medium">My Band</h2>
            </div>
          </div>
          <div className="my-2 border-t" />
          <div className="mt-3 -mx-2">
            <NavLink
              exact
              to="/bands/my-bands"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <GiMineExplosion size={20} style={{ color: colors.white }} />
              </div>
              My Band
            </NavLink>
            <NavLink
              exact
              to="/bands/request"
              className="flex items-center p-2 mt-2 rounded-lg black hover:bg-gray-200"
              activeClassName="bg--lightPrimary"
              activeStyle={{ color: colors.primary }}>
              <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
                <IoTicketOutline size={20} style={{ color: colors.white }} />
              </div>
              Booking Request
            </NavLink>
          </div>
        </div>
        <div className="my-3 -mx-5 divider" />
        <Buttonused
          Icon={<FaRegCalendarPlus className="mr-2" />}
          Text="Create Band"
          className="flex items-center justify-center w-full bg--lightPrimary h-11 primary rounded-xl"
          onClick={() => Emitter.emit("OPEN_BAND_MODAL", null)}
        />
        <Createbandpopup
          validation={this.validation}
          bandForm={this.addBandForm}
          form={this.props.form}
          ref={this.createEventRef}
        />
      </div>
    );
  }
}

export default Bandaside;
