import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

class Candidatesfilters extends Component {
  render() {
    return (
      <>
        <div className="grid gap-2 xl:grid-cols-6 lg:grid-cols-6">
          <div className="col-span-3 xl:col-start-1 xl:col-end-3"></div>
          <div className="col-span-3 xl:col-end-7 xl:col-span-2">
            <div className="flex items-center rounded--xl">
              <p className="mr-2 whitespace-nowrap">Sort By:</p>
              <Dropdown
                className="h-12 border gray rounded--xl"
                placeholder="Sort By"
                fluid
                selection
                options={[
                  {
                    key: "Post date",
                    text: "Post date",
                    value: "Post date",
                  },
                  {
                    key: "Job title",
                    text: "Job title",
                    value: "Job title",
                  },
                  {
                    key: "Location",
                    text: "Location",
                    value: "Location",
                  },
                  {
                    key: "Status",
                    text: "Status",
                    value: "Status",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Candidatesfilters;
