import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Friend from "./Friend";
import { Avatar } from "@material-ui/core";
import { Loader } from "semantic-ui-react";

class FriendModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleGroup: false,
      checked: [],
      name: "",
      img: null,
      imageInfo: null,
      loading: false,
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  toggleGroup = () => {
    this.setState({ toggleGroup: !this.state.toggleGroup });
  };

  setMember = checked => {
    this.setState({ checked });
  };

  onFileChange = e => {
    let image = e.target.files[0];
    const newFile = new File([image], "filename.jpg", { type: "image/jpeg" });
    const imageInfo = {
      size: image.size,
      type: image.type,
      file: newFile,
      name: "filename.jpg",
      public: true,
    };
    image = URL.createObjectURL(image);
    this.setState({ img: image, imageInfo: imageInfo });
  };

  createChatGroup = () => {
    const { checked, name, imageInfo, loading } = this.state;
    let occupants_ids = [];
    checked.map(item => {
      occupants_ids.push(item.cube_user_id);
    });
    if (!loading) {
      if (checked.length > 0 && name.length > 2) {
        this.setState({ loading: true });
        this.props.createNewGroup(occupants_ids, name, imageInfo).then(res => {
          this.setState({
            loading: false,
            name: "",
            img: null,
            imageInfo: null,
          });
        });
      }
    }
  };

  render() {
    const { title } = this.props;
    const { toggleGroup, checked, loading } = this.state;
    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={this.close}
          scroll="paper"
          className="custom-backdrop border"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle className="p-0">
            <div className="border-b pb-3 flex items-center justify-between">
              <div
                onClick={this.close}
                className="z-10 hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-full h-9 w-9 flex items-center justify-center"
              >
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
              <p className="text-2xl Medium">
                {title ?? toggleGroup ? "Create Group" : "New chat"}
              </p>
              <div
                onClick={this.toggleGroup}
                className="cursor-pointer bg--lightPrimary h-9 px-3 rounded-xl flex items-center justify-center"
              >
                <p className="primary fs-12">
                  Create {toggleGroup ? "Chat" : "Group"}
                </p>
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="-m-3">
              {toggleGroup && (
                <div className="h-10 mt-10 mb-10 flex flex-col fitems-center justify-center w-full items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => this.inputFileRef.click()}
                  >
                    <Avatar
                      style={{ width: 70, height: 70 }}
                      src={this.state.img}
                    >
                      {this.state.name.charAt(0)}
                    </Avatar>
                  </div>
                  <input
                    onChange={this.onFileChange}
                    accept="image/*"
                    ref={ref => (this.inputFileRef = ref)}
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                  />
                  <input
                    value={this.state.name}
                    placeholder="Group name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="h-10 w-80 Regular border-0 bg-gray-100 darkGray rounded-full p-2"
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </div>
              )}
              <Friend
                toggleGroup={toggleGroup}
                {...this.props}
                setMember={this.setMember}
                checked={checked}
              />
              {toggleGroup && (
                <>
                  <div
                    onClick={this.createChatGroup}
                    className="cursor-pointer bg--lightPrimary h-9 px-3 rounded-xl flex items-center justify-center"
                  >
                    {loading ? (
                      <Loader active inline />
                    ) : (
                      <p className="primary">Create Group</p>
                    )}
                  </div>
                  <p className="text">Total member(s): {checked.length}</p>
                </>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default FriendModal;
