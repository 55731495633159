const firebaseConfig = {
    apiKey: "AIzaSyAyDmCV6fzAb6px9B8IISkzfHIAStwYswM",
    authDomain: "auth.lifewidgets.com",
    databaseURL: "https://lifewidgets-9e320-default-rtdb.firebaseio.com",
    projectId: "lifewidgets-9e320",
    storageBucket: "lifewidgets-9e320.appspot.com",
    messagingSenderId: "840985404830",
    appId: "1:840985404830:web:f4e713274f120af3e3a28f",
    measurementId: "G-N5XBM5B97H",
  };
  export default firebaseConfig;
