import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

class Publicjobscard extends Component {
  renderJobLocation = () => {
    const { job } = this.props;
    let location = "";
    if (job.job_post_zip_code) {
      location += job.job_post_zip_code + ", ";
    }
    if (job.job_post_street) {
      location += job.job_post_street + ", ";
    }
    if (job.job_post_city) {
      location += job.job_post_city + ", ";
    }
    if (job.job_post_state) {
      location += job.job_post_state + ", ";
    }
    if (job.job_post_country_code) {
      location += job.job_post_country_code + ", ";
    }
    return location.replace(/,\s*$/, "");
  };

  render() {
    const { job } = this.props;
    const statistics =
      typeof job.statistics !== "undefined" ? job.statistics : {};
    const { renderJobLocation } = this;
    return (
      <Link
        key={this.props.key}
        to={"/job/details/" + job.id}
        className="bg-white border-2 rounded--xl px-8 py-4 block"
      >
        <div className="flex items-center">
          <div>
            <h3 className="black mb-2">{job.job_post_title}</h3>
            {job.company && (
              <p className=" fs-14 hover:primary--text mb-1">
                {job.company.name} - {job.company.address} {job.company.city}{" "}
                {job.company.country}
              </p>
            )}
            <p className=" fs-13 flex items-center text-gray-400">
              <p className="text-gray-400 fs-13 mr-2">Created:</p>
              {moment.utc(job.created_at).local().format("DD MMMM YYYY")}
            </p>

            {!!renderJobLocation() && (
              <>
                <p className="text-gray-400 fs-13">
                  Address: {renderJobLocation()}
                </p>
              </>
            )}

            <p className=" fs-13 flex items-center text-gray-400">
              <p className="text-gray-400 fs-13 mr-2">No. of Positions:</p>
              <p className=" fs-13 flex items-center text-gray-400">
                {job.job_post_number_of_position}{" "}
              </p>
            </p>

            <p className=" fs-13 flex items-center text-gray-400">
              <p className="text-gray-400 fs-13 mr-2">Remote:</p>
              {job.remote && (
                <p className=" fs-13 flex items-center text-gray-400">
                  {job.remote.job_remote_title}{" "}
                </p>
              )}
            </p>
            <div className="flex items-center">
              <p className=" fs-13 flex items-center">
                Salary: Rs {job.job_post_min_salary}{" "}
              </p>
              {job.job_post_max_salary && (
                <p className=" fs-13 flex items-center">
                  - Rs {job.job_post_max_salary}
                </p>
              )}
              {job.compensation_rate && (
                <p className=" fs-13 flex items-center">
                  /{job.compensation_rate.job_compensation_rate_title}
                </p>
              )}
              {job.type && (
                <p className=" fs-13 flex items-center ml-1">
                  - {job.type.job_type_title}
                </p>
              )}
            </div>

            <div className="darkGray Light fs-14 mt-3">
              <div
                className="darkGray Light fs-14"
                dangerouslySetInnerHTML={{
                  __html: job.job_post_description,
                }}
              />
            </div>
            {job.job_post_additional_description && (
              <>
                <p className="mt-3 fs-12 flex items-center Medium">
                  Additional Description:
                </p>
                <p className=" darkGray Light fs-14">
                  {job.job_post_additional_description}
                </p>
              </>
            )}
            {job.is_owner && (
              <>
                <div>
                  <div>
                    <p className="darkGray fs-13">{statistics.active_count}</p>
                    <p className="darkGray fs-13">Active</p>
                  </div>
                  <div>
                    <p className="darkGray fs-13">
                      {statistics.reviewed_count}
                    </p>
                    <p className="darkGray fs-13">Reviewed</p>
                  </div>
                  <div>
                    <p className="darkGray fs-13">{statistics.new_count}</p>
                    <p className="darkGray fs-13">New</p>
                  </div>
                  <div>
                    <p className="darkGray fs-13">
                      {statistics.contacting_count}
                    </p>
                    <p className="darkGray fs-13">Contacting</p>
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center mt-4">
              <p className="fs-13">More...</p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = ({ job }, ownProps) => {
  const { id } = ownProps;
  return {
    job: job.data[id],
  };
};

export default connect(mapStateToProps)(Publicjobscard);
