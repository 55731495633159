import React, { Component } from "react";
import { BiMessageRoundedDots } from "react-icons/bi";
import { Flirtthumbnails } from "components";
import { OptimizeImage } from "helpers";
import { RiHeartsFill, RiHeartsLine } from "react-icons/ri";
import { Popup } from "semantic-ui-react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBlock } from "react-icons/md";
import ChatService from "../../../../chat/services/chat-service";
import { Redirect } from "react-router";

class Flirtdetailheader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
    };
  }

  createChat = async () => {
    const { item } = this.props;
    if (item.cube_user_id) {
      ChatService.createPrivateDialog(item.cube_user_id)
        .then(async (newDialog) => {
          ChatService.setSelectDialog(newDialog);
          this.setState({ isRedirect: true });
        })
        .catch((error) => {
          this.setState({ isLoader: false });
        });
    }
  };

  render() {
    const { isRedirect } = this.state;
    if (isRedirect) {
      return <Redirect to="/messenger" />;
    }

    const { item } = this.props;
    const { flirt_setting } = item;
    const photos = Object.assign([], flirt_setting.photos);
    let attaches = [];
    for (var i = 0; i < photos.length; i++) {
      attaches[i] = {
        ...photos[i],
        url: OptimizeImage(photos[i].attachment_url, photos[i].type),
      };
    }
    attaches = attaches.filter((attach) => attach.type === "image");
    return (
      <div className="bg-white ">
        <div className="m-auto container--small xs:px-4 sm:px-4 ">
          <div className="overflow-hidden rounded-b-lg">
            <div className="relative rounded-b-lg shadow-sm h-96">
              <Flirtthumbnails item={item} />
            </div>
          </div>
          <div className="py-4 xl:px-8 md:px-5 sm:px-4 xs:px-4">
            <div className="flex items-center justify-end mt-2">
              <div
                onClick={this.createChat}
                className="flex items-center justify-center h-10 px-10 ml-3 transition duration-300 transform rounded-lg cursor-pointer bg--primary hover:bg--primary focus:bg--primary hover:scale-90">
                <BiMessageRoundedDots size={16} className="white" />
                <p className="ml-2 white">Message</p>
              </div>
              {!item.is_flirt && !item.flirt_request.is_entry && (
                <Popup
                  position="top center"
                  className="border-0 rounded--xl"
                  content={
                    <div>
                      <p className="text-sm cursor-pointer">
                        Send Flirt Request
                      </p>
                    </div>
                  }
                  trigger={
                    <div
                      onClick={this.props.sendFlirtRequest}
                      className="flex items-center justify-center w-12 h-12 ml-3 transition duration-300 transform bg-white border rounded-full shadow-sm cursor-pointer hover:bg-gray-300 hover:scale-90">
                      <RiHeartsLine size={28} className="gray" />
                    </div>
                  }
                />
              )}
              {!item.is_flirt && item.flirt_request.is_send_request && (
                <Popup
                  position="top center"
                  className="border-0 rounded--xl"
                  content={
                    <div>
                      <p className="text-sm cursor-pointer">
                        Flirt send successfull
                      </p>
                    </div>
                  }
                  trigger={
                    <div
                      onClick={this.props.cancelFlirtRequest}
                      className="flex items-center justify-center w-12 h-12 ml-3 transition duration-300 transform border rounded-full shadow-sm cursor-pointer bg-danger hover:bg-gray-300 hover:scale-90">
                      <RiHeartsFill size={28} className="gray" />
                    </div>
                  }
                />
              )}

              {!item.is_flirt &&
                !item.flirt_request.is_send_request &&
                item.flirt_request.is_entry && (
                  <Popup
                    position="top center"
                    className="border-0 rounded--xl"
                    content={
                      <div>
                        <p className="text-sm cursor-pointer">
                          Accept flirt request
                        </p>
                      </div>
                    }
                    trigger={
                      <div
                        onClick={this.props.acceptFlirtRequest}
                        className="flex items-center justify-center w-12 h-12 ml-3 transition duration-300 transform rounded-full shadow-sm cursor-pointer bg--success hover:scale-90">
                        <IoCheckmarkCircle size={28} className="white" />
                      </div>
                    }
                  />
                )}
              {!item.is_flirt &&
                item.flirt_request.is_entry &&
                !item.flirt_request.is_send_request && (
                  <Popup
                    position="top center"
                    className="border-0 rounded--xl"
                    content={
                      <div>
                        <p className="text-sm cursor-pointer">
                          Reject flirt request
                        </p>
                      </div>
                    }
                    trigger={
                      <div
                        onClick={this.props.rejectFlirtRequest}
                        className="flex items-center justify-center w-12 h-12 ml-3 transition duration-300 transform rounded-full shadow-sm cursor-pointer bg--danger hover:scale-90">
                        <MdBlock size={28} className="white" />
                      </div>
                    }
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Flirtdetailheader;
