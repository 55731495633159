import React from "react";
import colors from "config/color/color.js";
import { Image } from "react-bootstrap";
import { Buttonused, Shareeventpopup } from "components";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";
import { OptimizeImage } from "helpers";
import { event_image } from "assets";
import { AiFillHeart } from "react-icons/ai";
import { FaShareSquare } from "react-icons/fa";
import "./styles.css";
import { useState } from "react";
import moment from "moment";

const Eventcard = (props) => {
  const [createEventRef] = useState(React.createRef());
  const [value, setValue] = React.useState("going");
  const { item, index, route } = props;
  let profileUrl = "";
  if (item.invites && item.invites.length > 0) {
    if (item.invites[0].user) {
      profileUrl = OptimizeImage(item.invites[0].user.profile_photo);
    }
  }

  let pendingFlag = false;
  if (route) {
    if (route === "pending") {
      pendingFlag = true;
    }
  }
  let url = "";
  if (item.photos !== undefined && item.photos.length > 0) {
    url = OptimizeImage(item.photos[0].attachment_url);
  }

  const handleInvitaion = (value) => {
    if (window.confirm(`Do you want to ${value}?`)) {
      props.addResponse(value, item.id);
    }
  };

  const onShare = async (id) => {
    alert("Do you want to share this event?", "", [
      {
        text: "Cancel",
        style: "cancel",
      },
      { text: "OK", onPress: () => props.addResponse("share", id) },
    ]);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (item.band && item.band.attachments && item.band.attachments.length > 0) {
    url = OptimizeImage(item.band.attachments[0].attachment_url);
  }

  return (
    <div
      key={index}
      className="relative overflow-hidden bg-white shadow-sm rounded-xl">
      <>
        {item.photos && item.photos.length > 0 ? (
          <>
            {item.band && props.addEventFavourite && (
              <div
                onClick={() => props.addEventFavourite(item.id, props.type)}
                className="absolute z-10 flex items-end justify-end cursor-pointer right-3 top-2">
                <AiFillHeart
                  size={30}
                  color={item.is_favourite ? colors.primary : colors.gray}
                />
              </div>
            )}
            <Link to={"/events/" + item.slug} className="relative block w-full">
              <Image
                src={url}
                className="object-cover w-full h-52 rounded-t-xl"
              />
              {item.band && (
                <div className="absolute top-0 flex items-center justify-center w-full h-full p-3 pb-0 border rounded-t-xl overlay:bg--gray-400">
                  <div className="text-center">
                    <p className="white Medium">{item.band.band_name}</p>
                    {!!item.address && (
                      <p className="white Light fs-12">{item.address}</p>
                    )}
                  </div>
                </div>
              )}
            </Link>
          </>
        ) : (
          <Link to={"/events/" + item.slug} className="w-full rounded-xl">
            <Image src={event_image} className="object-cover w-full h-52 " />
          </Link>
        )}
      </>

      <div className="p-3">
        {pendingFlag ? (
          <Image
            style={{ marginTop: -60, marginBottom: 13 }}
            size={82}
            source={{ uri: profileUrl }}
          />
        ) : null}
        <div className="relative pl-3 ml-3 dateTimeline">
          <div className="timelineDot" />
          <p className="my-2 fs-12 darkGray">
            {moment(item.start_date).format("MMMM Do YYYY")}
            {!item.all_day &&
              " at " + moment(item.start_date).format("hh:mm A")}
          </p>
          {!!item.end_date && (
            <p className="my-2 fs-12 darkGray">
              {moment(item.end_date).format("MMMM Do YYYY")}{" "}
              {!item.all_day &&
                " at " + moment(item.end_date).format("hh:mm A")}
            </p>
          )}
        </div>
        {item.band && item.is_booked && (
          <div>
            {"Price "}${item.band.band_min_price}
          </div>
        )}
        <p className="mt-2 text-xl Medium">{item.name}</p>
        <div className="flex items-center">
          <p className="fs-12 darkGray">{item.interest_count} Interested</p>
          <BsDot color={colors.darkGray} />
          <p className="fs-12 darkGray">{item.going_count} Going</p>
        </div>
        {!props.user && (
          <>
            {item.is_invited ? (
              <div className="flex w-full gap-2 mt-3">
                <Buttonused
                  className="flex items-center justify-center w-full h-10 px-2 rounded-md bg--lightPrimary primary"
                  onClick={() => handleInvitaion("accept")}
                  rippleContainer="flex-grow rounded-md "
                  Text="accept"
                />
                <Buttonused
                  className="flex items-center justify-center w-full h-10 px-2 rounded-md bg--lightPrimary primary"
                  onClick={() => handleInvitaion("reject")}
                  rippleContainer="flex-grow rounded-md "
                  Text="Reject"
                />
              </div>
            ) : item.is_going ? (
              <div className="flex w-full mt-3">
                <Buttonused
                  className="flex items-center justify-center w-full h-10 px-2 rounded-md bg--lightPrimary primary"
                  onClick={() => handleInvitaion("reject")}
                  rippleContainer="flex-grow rounded-md "
                  Text="Reject"
                />

                <Buttonused
                  onClick={() => createEventRef.current.eventOpen()}
                  Icon={<FaShareSquare size={18} color={colors.black} />}
                  className="flex items-center justify-center w-20 h-10 bg--lightGray "
                  rippleContainer="flex rounded-md ml-2 "
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 mt-3">
                <Buttonused
                  onClick={() => createEventRef.current.eventOpen(item)}
                  Icon={<FaShareSquare size={18} color={colors.black} />}
                  className="flex items-center justify-center w-full h-10 px-4 bg--lightGray "
                  rippleContainer="flex rounded-md w-full"
                />
              </div>
            )}
          </>
        )}
        {props.user && props.user.id === item.user_id && (
          <div className="flex items-center gap-2 mt-3">
            <Buttonused
              onClick={() => props.open(item)}
              className="flex items-center justify-center w-full h-10 px-4 bg--lightGray "
              rippleContainer="flex rounded-md w-full"
              Text="Edit"
            />
            <Buttonused
              onClick={() => createEventRef.current.eventOpen(item)}
              Icon={<FaShareSquare size={18} color={colors.black} />}
              className="flex items-center justify-center w-full h-10 px-4 bg--lightGray "
              rippleContainer="flex rounded-md w-full"
            />
          </div>
        )}
      </div>
      <Shareeventpopup ref={createEventRef} />
    </div>
  );
};
export default Eventcard;
