import * as React from "react";
import { Button } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import colors from "config/color/color.js";
import { AiFillHome, AiFillClockCircle } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { IOStoggle, Avatarimage } from "components";
import { connect } from "react-redux";
import { OptimizeImage, Capitalize } from "helpers";
import { Avatar } from "@material-ui/core";
import moment from "moment";

class Profilepopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, editDetails: false };
    this.scrollBodyRef = React.createRef();
    this.editDetailsRef = React.createRef();
    this.photoRef = React.createRef();
    this.coverRef = React.createRef();
  }

  editPopupOpen = () => {
    this.setState({ open: true });
  };

  editPopupClose = () => {
    this.setState({ open: false });
  };

  editDetailsPopupOpen = () => {
    this.setState({ editDetails: true });
  };

  editDetailsPopupClose = () => {
    this.setState({ editDetails: false });
  };

  onPhotoClick = () => {
    this.photoRef.current.click();
  };

  onCoverClick = () => {
    this.coverRef.current.click();
  };

  onPhotoChange = e => {
    const data = e.target.files[0];
    if (data) {
      this.uploadPhoto(data);
    }
  };

  onCoverChange = e => {
    const data = e.target.files[0];
    if (data) {
      this.uploadBanner(data);
    }
  };

  uploadPhoto = async data => {
    var formData = new FormData();
    formData.append("profile_photo", data);
    this.props.uploadProfilePhoto(formData);
  };

  uploadBanner = data => {
    var formData = new FormData();
    formData.append("profile_banner", data);
    this.props.uploadProfileBanner(formData);
  };

  render() {
    let { data } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.editPopupClose}
          scroll="body"
          className="custom-backdrop"
          fullWidth="lg"
        >
          <div className="border-b px-3 py-3 flex items-center justify-center ">
            <p className="text-2xl Medium">Edit Profile</p>
            <div
              onClick={this.editPopupClose}
              className="z-10 absolute right-3 hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-full h-10 w-10 flex items-center justify-center"
            >
              <FiX size={24} style={{ color: colors.darkGray }} />
            </div>
          </div>
          <div className="p-4">
            <div className="mb-4">
              <div className="flex items-center justify-between">
                <h3>Profile Picture</h3>
                <div
                  onClick={this.onPhotoClick}
                  className="w-10 relative overflow-hidden cursor-pointer"
                >
                  <p className="primary " id="file">
                    Edit
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-3">
                <Avatarimage
                  item={data}
                  variant="circle"
                  style={{ width: 170, height: 170 }}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="flex items-center justify-between">
                <h3>Cover Photo</h3>
                <div
                  onClick={this.onCoverClick}
                  className="w-10 relative overflow-hidden cursor-pointer"
                >
                  <p className="primary " id="file">
                    Edit
                  </p>
                </div>
              </div>
              <div className="flex justify-center my-3 px-10">
                <Avatar
                  variant="rounded"
                  src={OptimizeImage(data.profile_banner)}
                  alt={data.first_name}
                  className="w-full h-full object-cover rounded-b-lg object-center"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <h3>Customize Your Intro</h3>
                {/* <div className="relative  cursor-pointer">
                  <p className="primary " onClick={this.editDetailsPopupOpen}>
                    Edit
                  </p>
                </div> */}
              </div>
              <div>
                {/* <div className="flex mt-4">
                  <div className="mr-3 ">
                    <MdBusinessCenter size={20} className="gray " />
                  </div>
                  <p className="darkGray fs-14">
                    Working at{" "}
                    <p className="black hover:underline fs-14">
                      {" "}
                      Weeee
                    </p>
                  </p>
                </div> */}
                {/* <div className="flex mt-4">
                  <div className="mr-3 ">
                    <IoSchool size={20} className="gray " />
                  </div>
                  <p className="darkGray fs-14">
                    Studied BS(CS) at PRESTON UNIVERSITY
                  </p>
                </div> */}
                {/* <div className="flex mt-4 items-center">
                  <div className="mr-3 ">
                    <IoSchool size={20} className="gray " />
                  </div>
                  <p className="darkGray fs-14">
                    Went to Hamza Army Public College
                  </p>
                </div> */}
                {!!data.city && data.city != "null" && (
                  <div className="flex mt-4 items-center">
                    <div className="mr-3 ">
                      <AiFillHome size={20} className="gray " />
                    </div>
                    <p className="darkGray fs-14">
                      Lives in{" "}
                      <span className="black hover:underline fs-14">
                        {data.city}
                      </span>
                    </p>
                  </div>
                )}
                {/* {data.marital_status === "in_relationship" &&
                Object.keys(Object.assign({}, data.relationship)).length >
                  0 && (
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        if(this.props.user.id!=data.relationship.id){
                          this.props.navigation.push("UserProfile", {
                            user_id: data.relationship.id,
                          });
                        }

                      }}
                      style={[ { alignItems: "center", flexDirection:"row" }]}
                    >
                    <Text>{" with "}</Text>
                    <Text style={{fontFamily:FontFamily.Medium, fontSize:17}}>{Capitalize(data.relationship.first_name)} {Capitalize(data.relationship.last_name)}</Text>
                    </TouchableOpacity>
                  </View>
                )} */}
                <div className="flex mt-4 items-center">
                  <div className="mr-3 ">
                    <FaHeart size={20} className="gray " />
                  </div>
                  <p className="darkGray fs-14">
                    {Capitalize(data.marital_status)}
                  </p>
                  {data.marital_status === "in_relationship" &&
                    Object.keys(Object.assign({}, data.relationship)).length >
                      0 && (
                      <>
                        <p className="ml-1">{"with"}</p>
                        <p className="ml-1">
                          {Capitalize(data.relationship.first_name)}{" "}
                          {Capitalize(data.relationship.last_name)}
                        </p>
                      </>
                    )}
                </div>

                <div className="flex mt-4 items-center">
                  <div className="mr-3 ">
                    <AiFillClockCircle size={20} className="gray " />
                  </div>
                  <p className="darkGray fs-14">
                    Joined{" "}
                    {moment.utc(data.created_at).local().format("MMMM Y")}{" "}
                  </p>
                </div>
                <div className="flex mt-4 items-center">
                  <div className="mr-3 ">
                    <AiFillHome size={20} className="gray " />
                  </div>
                  <p className="darkGray fs-14">
                    Followed by {data.total_followers} people
                  </p>
                </div>
                <Link to="/profile/about">
                  <Button
                    onClick={this.editPopupClose}
                    style={{ color: colors.primary }}
                    className="bg--lightPrimary rounded--lg hover:bg--lightPrimary focus:bg--lightPrimary shadow-none border-0 mt-4 w-full black transform transition duration-300 hover:scale-90"
                  >
                    Edit Your About Info
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.editDetails}
          onClose={this.editDetailsPopupClose}
          scroll="paper"
          className="custom-backdrop border"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle className="p-0">
            {" "}
            <div className="border-b px-3 py-3 flex items-center justify-center ">
              <p className="text-2xl Medium">Edit Details</p>
              <div
                onClick={this.editDetailsPopupClose}
                className="z-10 absolute right-3 hover:bg-gray-300 cursor-pointer bg-gray-200 rounded-full h-10 w-10 flex items-center justify-center"
              >
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="p-4">
              <div>
                <div>
                  <h4>Customize Your Intro</h4>
                  <p className="darkGray fs-13 Light">
                    Details you select will be public and won't post to News
                    Feed.
                  </p>
                </div>
                <div className="mt-4">
                  <h4>Work</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">
                        Frontend React.Js,React Native,Vue.Js,Flutter at BJS
                        Soft Solution
                      </p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                  <div className="mt-3">
                    <p className="primary flex fs-15 items-center  hover:underline hover:opacity-90">
                      <FiPlusCircle
                        size={20}
                        style={{ color: colors.primary }}
                        className="mr-2"
                      />
                      Add a workplace
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h4>Education</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">
                        Studied BS(CS) at PRESTON UNIVERSITY' BSCS
                      </p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                  <div className="mt-3">
                    <p className="primary flex fs-15 items-center Light hover:underline hover:opacity-90">
                      <FiPlusCircle
                        size={22}
                        style={{ color: colors.primary }}
                        className="mr-2"
                      />
                      Add a high school
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <h4>Current City</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">Lives in Lahore, Pakistan</p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h4>Hometown</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">From New York</p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h4>Relationship</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">Married</p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h4>Joined</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">
                        Joined{" "}
                        {moment.utc(data.created_at).local().format("MMMM Y")}
                      </p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h4>Followers</h4>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-3 ">
                        <IOStoggle />
                      </div>
                      <p className="fs-14">
                        Followed by {data.total_followers} people
                      </p>
                    </div>
                    <Link>
                      <MdEdit size={20} style={{ color: colors.black }} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-3 border-t">
            <Button
              style={{ color: colors.black }}
              className="bg--lightGray rounded--lg hover:bg--lightGray focus:bg--lightGray shadow-none border-0 px-5 black transform transition duration-300 hover:scale-90"
            >
              Cancel
            </Button>
            <Button
              style={{ color: colors.white }}
              className="bg--primary rounded--lg hover:bg--primary focus:bg--primary shadow-none border-0 px-5 black transform transition duration-300 hover:scale-90"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <input
          style={{ display: "none" }}
          accept="image/*"
          type="file"
          onChange={this.onPhotoChange}
          ref={this.photoRef}
        />
        <input
          style={{ display: "none" }}
          accept="image/*"
          type="file"
          onChange={this.onCoverChange}
          ref={this.coverRef}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    data: state.Profile.data,
    photo_uploading: state.Profile.photo_uploading,
    banner_uploading: state.Profile.banner_uploading,
    video_uploading: state.Profile.video_uploading,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ProfileRedux");
  return {
    ...ownProps,
    ...stateProps,
    uploadProfilePhoto: data => {
      actions.uploadProfilePhoto(dispatch, data);
    },
    uploadProfileBanner: data => {
      actions.uploadProfileBanner(dispatch, data);
    },
    uploadIntroVideo: data => {
      actions.uploadIntroVideo(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Profilepopup);
