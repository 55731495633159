import { LifeWidget } from "common";

const types = {
  FETCH_FEEDS_PENDING: "FETCH_FEEDS_PENDING",
  FETCH_FEEDS_SUCCESS: "FETCH_FEEDS_SUCCESS",
  FETCH_FEEDS_FAILURE: "FETCH_FEEDS_FAILURE",
  RESET_STATE: "RESET_STATE",
  ADD_CUSTOM_CARD_FEED: "ADD_CUSTOM_CARD_FEED",
  FETCH_FEEDS_MORE_SUCCESS: "FETCH_FEEDS_MORE_SUCCESS",
  SET_COMMENT_COUNT_FEED: "SET_COMMENT_COUNT_FEED",
  SUBMIT_FEED_PENDING: "SUBMIT_FEED_PENDING",
  SUBMIT_FEED_SUCCESS: "SUBMIT_FEED_SUCCESS",
  SUBMIT_FEED_FAILURE: "SUBMIT_FEED_FAILURE",
  FEED_TRIGGER_READY: "FEED_TRIGGER_READY",
  DELETE_POST_FEED: "DELETE_POST_FEED",
  SET_DEFAULT_FETCH_STATUS:"SET_DEFAULT_FETCH_STATUS",
  FEED_SNOOZE_FRIEND:"FEED_SNOOZE_FRIEND",
  FEED_UNSNOOZE_FRIEND:"FEED_UNSNOOZE_FRIEND",
  FEED_UNFRIEND:"FEED_UNFRIEND",
  SAVE_OFFLINE:"SAVE_OFFLINE",
  CLEAR_OFFLINE:"CLEAR_OFFLINE",
  CLEAR_OFFLINE_BY_IDS:"CLEAR_OFFLINE_BY_IDS",
  BLOCK_USER_TOGGLE:"BLOCK_USER_TOGGLE",
  UPDATE_POST_COMMENT:"UPDATE_POST_COMMENT",
  SET_FEED_TYPE:"SET_FEED_TYPE",
  SET_DEFAULT_EDIT_FLAG:"SET_DEFAULT_EDIT_FLAG",

  SUBMIT_EDIT_FEED_PENDING: "SUBMIT_EDIT_FEED_PENDING",
  SUBMIT_EDIT_FEED_SUCCESS: "SUBMIT_EDIT_FEED_SUCCESS",
  SUBMIT_EDIT_FEED_FAILURE: "SUBMIT_EDIT_FEED_FAILURE",

  FETCH_USER_PUBLIC_POST_PENDING:"FETCH_USER_PUBLIC_POST_PENDING",
  FETCH_USER_PUBLIC_POST_FAILURE:"FETCH_USER_PUBLIC_POST_FAILURE",
  FETCH_USER_PUBLIC_POST_SUCCESS:"FETCH_USER_PUBLIC_POST_SUCCESS",
  FETCH_USER_PUBLIC_POST_MORE_SUCCESS:"FETCH_USER_PUBLIC_POST_MORE_SUCCESS",

  ADD_FEED_FORM: "ADD_FEED_FORM",

  SET_FEED_PRIVACY:"SET_FEED_PRIVACY",
  SET_FEED_DESTINATION:"SET_FEED_DESTINATION",
  SET_FEED_DEFAULT_LIST:"SET_FEED_DEFAULT_LIST",

  UPDATE_COMMENT_COUNTER:"UPDATE_COMMENT_COUNTER",


};

const initialState = {
  isFetching: false,
  isProcessing: false,
  isEditProcessing: false,
  error: null,
  feed: [],
  last_page: 1,
  totalFeeds: 0,
  triggerReady: false,
  page: 1,
  feedType:'home',
  form:{},
  defaultPrivacy: { name: "Public", id: 1 },
  item:{},
  isPublicFetching:false,
  posts:{},
  nextPosts:{}
};

export const actions = {
  fetchFeed: async (dispatch, per_page, page, params = []) => {
    dispatch({ type: types.FETCH_FEEDS_PENDING });
    const json = await LifeWidget.userPublicFeed(per_page, page, params);
    if (json === undefined) {
      dispatch(actions.fetchFeedsFailure("Can't get data from server"));
    } else if (json.status) {
      dispatch(actions.fetchFeedsFailure(json.data.message));
    } else {
      if (page > 1) {
        dispatch(actions.fetchFeedsSuccessMore(json));
      } else {
        dispatch(actions.fetchFeedsSuccess(json));
      }
    }
  },
  sharePost: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_FEED_PENDING});
    const json = await LifeWidget.share(data);
    if (json.success) {
      dispatch({ type: types.SUBMIT_FEED_SUCCESS, items: json.data });
    } else {
      dispatch({
        type: types.SUBMIT_FEED_FAILURE,
        error: true,
        message: "Can't get data from server",
      });
    }
    return json;
  },
  submitPost: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_FEED_PENDING});
    const json = await LifeWidget.submit(data);
    if (json.success) {
      dispatch({ type: types.SUBMIT_FEED_SUCCESS, items: json.data });
    } else {
      dispatch({
        type: types.SUBMIT_FEED_FAILURE,
        error: true,
        message: "Can't get data from server",
      });
    }
    return json;
  },
  fetchUserPublicPosts: async (dispatch, per_page, page, user_id, save_id=null, group_id=null) => {
    dispatch({ type: types.FETCH_USER_PUBLIC_POST_PENDING });
    const json = await LifeWidget.morePosts(per_page, page, user_id, save_id, group_id);
    if (json === undefined) {
      dispatch({ type: types.FETCH_USER_PUBLIC_POST_FAILURE, error:"Can't get data from server" });
    } else if (json.status) {
      dispatch({ type: types.FETCH_USER_PUBLIC_POST_FAILURE, error:json.data.message });
    } else {
      if (page > 1) {
        dispatch({ type: types.FETCH_USER_PUBLIC_POST_MORE_SUCCESS, items:json, user_id:user_id });
      } else {
        dispatch({ type: types.FETCH_USER_PUBLIC_POST_SUCCESS, items:json, user_id:user_id });
      }
    }
  },
  submitEditPost: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_EDIT_FEED_PENDING});
    const json = await LifeWidget.submit(data);

    if (json.success) {
      dispatch({ type: types.SUBMIT_EDIT_FEED_SUCCESS, items: json.data });
    } else {
      dispatch({
        type: types.SUBMIT_EDIT_FEED_FAILURE,
        error: true,
        message: "Can't get data from server",
      });
    }
  },
  feedTriggerReady: () => {
    return { type: types.FEED_TRIGGER_READY };
  },
  resetState: (dispatch) => {
    dispatch({ type: types.RESET_STATE });
  },
  fetchFeedsSuccess: (json) => ({
    type: types.FETCH_FEEDS_SUCCESS,
    json: json,
    page: json.current_page,
  }),

  fetchFeedsSuccessMore: (json) => ({
    type: types.FETCH_FEEDS_MORE_SUCCESS,
    json,
    page: json.current_page,
  }),

  fetchFeedsFailure: (error) => ({
    type: types.FETCH_FEEDS_FAILURE,
    error,
  }),
  deletePostFeed: async (dispatch, item) => {
    dispatch({ type: types.DELETE_POST_FEED, items: item });
    await LifeWidget.deletePost(item.id);
  },
  setDefaultFetchStatus: () => {
    return { type: types.SET_DEFAULT_FETCH_STATUS };
  },
  addCustomCard: (card) => ({
    type: types.ADD_CUSTOM_CARD_FEED,
    card: card,
  }),
  setCommentCounterFeed: (items, total_comment) => ({
    type: types.SET_COMMENT_COUNT_FEED,
    items,
    total_comment,
  }),
  snoozeFriend: async (dispatch, friend_id) => {
    dispatch({ type: types.FEED_SNOOZE_FRIEND, items:friend_id });
    const json = await LifeWidget.snoozeFriend(friend_id);
  },
  unsnoozeFriend: async (dispatch, friend_id) => {
    dispatch({ type: types.FEED_UNSNOOZE_FRIEND, items:friend_id });
    const json = await LifeWidget.unsnoozeFriend(friend_id);
  },
  unfriend: async (dispatch, friend_id) => {
    dispatch({ type: types.FEED_UNFRIEND, items:friend_id });
    const json = await LifeWidget.cancelFriendRequest(friend_id);
  },
  blockToggle: async (dispatch, friend_id) => {
    dispatch({ type: types.BLOCK_USER_TOGGLE, items:friend_id });
    const json = await LifeWidget.blockFriend(friend_id);
    
  },
  saveOffline: (items) => ({
    type: types.SAVE_OFFLINE,
    items,
  }),
  clearOffline: () => ({
    type: types.CLEAR_OFFLINE
  }),
  clearOfflineIds: (data) => ({
    type: types.CLEAR_OFFLINE_BY_IDS,
    item:data
  }),
  setRecentComment:(item)=>({
    type: types.UPDATE_POST_COMMENT,
    item
  }),
  setFeedType:(item)=>({
    type: types.SET_FEED_TYPE,
    item
  }),
  setDefaultEditFlag:()=>({
    type: types.SET_DEFAULT_EDIT_FLAG,
  }),
  addFeedForm: (items) => ({
    type: types.ADD_FEED_FORM,
    items,
  }),
  setPostPrivacy: (items) => {
    return { type: types.SET_FEED_PRIVACY, items };
  },
  setPostDestination: (items) => {
    return { type: types.SET_FEED_DESTINATION, items };
  },
  setDefaultList: (items) => {
    return { type: types.SET_FEED_DEFAULT_LIST, items };
  },
  updateCommentCounter: (item) => ({
    type: types.UPDATE_COMMENT_COUNTER,
    item,
  }),
};

export const reducer = (state = initialState, action) => {
  const { type, error, card, json, page, items, total_comment, item, user_id } = action;
  switch (type) {
    case types.FETCH_FEEDS_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: null,
        message: "",
      };
    }

    case types.FETCH_FEEDS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: null,
        message: "",
      };
    }

    case types.FETCH_FEEDS_SUCCESS: {
      const offline = state.feed.filter((post) => post._id);
      return Object.assign({}, state, {
        isFetching: false,
        feed: offline.concat(json.data),
        last_page: json.last_page,
        error: null,
        totalFeeds: json.total,
        timeoutTrigger: false,
        page,
      });
    }
    case types.FETCH_FEEDS_MORE_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        feed: state.feed.concat(json.data),
        last_page: json.last_page,
        error: null,
        totalFeeds: json.total,
        timeoutTrigger: false,
        page,
      });
    }

    case types.FETCH_USER_PUBLIC_POST_PENDING: {
      return {
        ...state,
        isPublicFetching: true,
        error: null,
        message: "",
      };
    }

    case types.FETCH_USER_PUBLIC_POST_FAILURE: {
      return {
        ...state,
        isPublicFetching: false,
        error: error,
        message: error,
      };
    }

    case types.FETCH_USER_PUBLIC_POST_SUCCESS: {
      return {
        ...state,
        isPublicFetching:false,
        posts:{
          ...state.posts,
          [user_id]:items.data
        },
        nextPosts:{
          ...state.nextPosts,
          [user_id]:items.next_page_url
        }
      }
    }

    case types.FETCH_USER_PUBLIC_POST_MORE_SUCCESS: {
      return {
        ...state,
        isPublicFetching:false,
        posts:{
          ...state.posts,
          [user_id]:[...state.posts[user_id], ...items.data]
        },
        nextPosts:{
          ...state.nextPosts,
          [user_id]:items.next_page_url
        }
      }
    }

    case types.SUBMIT_FEED_PENDING: {
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: "",
      };
    }

    case types.SUBMIT_FEED_FAILURE: {

      return {
        ...state,
        isProcessing: false,
        error: null,
        message: "",
      };
    }

    case types.SUBMIT_FEED_SUCCESS: {

      let newArr = [...state.feed];
      let postArr = [...state.posts[items.user_id]??[]];
      let index = newArr.findIndex((post) => post.id === items.id);
      let postIndex = postArr.findIndex((post) => post.id === items.id);
      if(index>-1){
        newArr[index] = items;
      } else {
        newArr = [items].concat(state.feed);
      }
      if(postIndex>-1){
        postArr[postIndex] = items;
      } else {
        postArr = [items, ...state.posts[items.user_id]??[]]
      }
      return {
        ...state,
        isProcessing: false,
        feed: newArr,
        posts: {
          ...state.posts,
          [items.user_id]: postArr
        },
        error: null,
        form:{}
      };
    }
    /*************** Edit post *************/

    case types.SUBMIT_EDIT_FEED_PENDING: {
      return {
        ...state,
        isEditProcessing: true,
      };
    }

    case types.SUBMIT_EDIT_FEED_FAILURE: {
      return {
        ...state,
        isEditProcessing:false
      };
    }

    case types.SUBMIT_EDIT_FEED_SUCCESS: {

      let newArr = [...state.feed];
      let index = newArr.findIndex((post) => post.id === items.id);
      if(index>-1){
        newArr[index] = items;
      }
      return Object.assign({}, state, {
        isEditProcessing:false,
        feed: newArr,
      });
    }
    /**************** End edit post  *********/
    case types.FEED_TRIGGER_READY: {
      return {
        ...state,
        triggerReady: true,
      };
    }
    case types.ADD_CUSTOM_CARD_FEED: {
      return { ...state };
      return Object.assign({}, state, {
        feed: state.feed.concat(card),
        isFetching: false,
        timeoutTrigger: true,
      });
    }
    case types.SET_COMMENT_COUNT_FEED: {
      let newArr = [...state.feed];
      let postIndex = newArr.findIndex((item) => item.id === items.post_id);

      let postArry = newArr[postIndex];
      postArry.comments_count = total_comment;
      newArr[postIndex] = postArry;
      return Object.assign({}, state, {
        feed: newArr,
      });
    }
    case types.DELETE_POST_FEED: {
      let newArray = [...state.feed];
      let newUserArray = [...state.posts[items.user_id]??[]];
      const postDeletedArray = newArray.filter((post) => post.id !== items.id);
      const postUserDeletedArray = newUserArray.filter((post) => post.id !== items.id);
      return {
        ...state,
        feed: postDeletedArray,
        posts: {
          ...state.posts,
          [items.user_id]:postUserDeletedArray
        }
      };
    }
    case types.FEED_SNOOZE_FRIEND: {
      let newArray = [...state.feed];
      let postArry = [];
      let postIds = [];
      const posts = newArray.filter((post) => post.user.id === items);
      
      posts.map((item,key)=>{
        let postIndex = newArray.findIndex((itm) => itm.id === item.id);
        postIds[item.id] = Math.random() * (9000 - 0);
        postArry = newArray[postIndex];
        postArry.user.is_snoozed = true;
        newArray[postIndex] = postArry;
      });
      return {
        ...state,
        feed: newArray,
        item:{
          ...state.item,
          ...postIds
        }
      };     
    }
    case types.FEED_UNSNOOZE_FRIEND: {
      let newArray = [...state.feed];
      let postArry = [];
      let postIds = [];
      const posts = newArray.filter((post) => post.user.id === items);
      posts.map((item,key)=>{
        let postIndex = newArray.findIndex((itm) => itm.id === item.id);
        postIds[item.id] = Math.random() * (9000 - 0);
        postArry = newArray[postIndex];
        postArry.user.is_snoozed = false;
        newArray[postIndex] = postArry;
      });      
      return {
        ...state,
        feed: newArray,
        item:{
          ...state.item,
          ...postIds
        }
      };
    }
    case types.FEED_UNFRIEND:{
      let newArray = [...state.feed];
      let postArry = [];
      let postIds = [];
      const posts = newArray.filter((post) => post.user.id === items);
      posts.map((item,key)=>{
        let postIndex = newArray.findIndex((itm) => itm.id === item.id);
        postIds[item.id] = Math.random() * (9000 - 0);
        postArry = newArray[postIndex];
        postArry.user.is_friend = false;
        newArray[postIndex] = postArry;
      });      
      return {
        ...state,
        feed: newArray,
        item:{
          ...state.item,
          ...postIds
        }
      };
    }
    case types.BLOCK_USER_TOGGLE:{
      let newArray = [...state.feed];
      let postArry = [];
      let postIds = [];
      const posts = newArray.filter((post) => post.user.id === items);
      posts.map((item,key)=>{
        let postIndex = newArray.findIndex((itm) => itm.id === item.id);
        postIds[item.id] = Math.random() * (9000 - 0);
        postArry = newArray[postIndex];
        postArry.user.is_blocked = !postArry.user.is_blocked;
        newArray[postIndex] = postArry;
      });      
      return {
        ...state,
        feed: newArray,
        item:{
          ...state.item,
          ...postIds
        }
      };

    }
    case types.UPDATE_COMMENT_COUNTER:{
      let newArray = [...state.feed];
      let postIds = [];
      let counter = item.plus?+1:-1;
      const postIndex = newArray.findIndex((post) => post.id === item.post_id);
      if(postIndex>-1){
        postIds[item.post_id] = Math.random() * (9000 - 0);
        newArray[postIndex].comments_count = newArray[postIndex].comments_count+counter;
      }
      
      return {
        ...state,
        feed: newArray,
        item:{
          ...state.item,
          ...postIds
        }
      };
    }
    case types.SET_DEFAULT_FETCH_STATUS:{
      return {
        ...state,
        isProcessing: false,
      };
    }
    case types.RESET_STATE: {
      return {
        ...state,
        initialState,
      };
    }
    case types.SAVE_OFFLINE:{
      return {
        ...state,
        feed:[items].concat(state.feed)
      };
    }
    case types.CLEAR_OFFLINE:{
      let newArray = [...state.feed];
      const feedArray = newArray.filter((post) => !post._id);
      return {
        ...state,
        feed:feedArray
      };
    }
    case types.CLEAR_OFFLINE_BY_IDS:{
      let newArray = [...state.feed];
      if (json !== undefined) {
        const feedArray = newArray.filter((post) => !item.includes(post._id));
        return {
          ...state,
          feed:feedArray
        };
      }
      return {
        ...state,
      };
    }
    case types.UPDATE_POST_COMMENT:{
      let newArr = [...state.feed];
      let postIndex = newArr.findIndex((post) => post.id === item.post_id);
      if(postIndex>-1){
        let postArry = newArr[postIndex];
        postArry.recent_comments = item;
        newArr[postIndex] = postArry;
      }
      
      return Object.assign({}, state, {
        feed: newArr,
      });
    }
    case types.SET_FEED_TYPE:{
      return {
        ...state,
        feedType:item
      };
    }
    case types.SET_DEFAULT_EDIT_FLAG:{
      return {
        ...state,
        isEditProcessing:false
      };
    }
    case types.ADD_FEED_FORM: {
      return { ...state, form: items };
    }
    case types.SET_FEED_PRIVACY: {
      return { ...state, defaultPrivacy: items };
    }
    default: {
      return state;
    }
  }
};
