import colors from "config/color/color.js";

const styles = {
  userName: {
    color: colors.black,
    fontSize: 18,
  },
  chipOpacity: {
    backgroundColor: colors.lightGray,
  },
  listText: {
    fontSize: 12,
  },
};

export default styles;
