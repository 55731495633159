import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Buttonused } from "components";

class Deletepopup extends Component {
  constructor(props) {
    super(props);
    this.state = { deletePopup: false, item: {} };
  }

  deletePopupOpen = (item) => {
    this.setState({ deletePopup: true, item: item });
  };

  deletePopupClose = () => {
    this.setState({ deletePopup: false });
  };

  render() {
    const { title, description } = this.props;
    return (
      <>
        <Dialog
          open={this.state.deletePopup}
          onClose={this.deletePopupClose}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            <div className="flex items-center justify-center pb-3 border-b">
              <p className="text-2xl Medium">{title ?? "Delete Comment"}</p>
              <div
                onClick={this.deletePopupClose}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="mb-4">
              <p className="text-xl">
                {description ?? "Are you sure you want to delete this comment?"}
              </p>
            </div>
          </DialogContent>
          <DialogActions className="p-3 border-t">
            <Buttonused
              Text="Cancel"
              className="flex items-center px-5 transition duration-300 transform bg-gray-100 rounded-lg cursor-pointer h-11 hover:bg-gray-300 hover:scale-90"
              onClick={this.deletePopupClose}
            />

            <Buttonused
              Text="Delete"
              className="px-5 transition duration-300 transform bg-red-100 border-0 rounded-lg shadow-none h-11 white danger hover:scale-90"
              onClick={() => this.props.callbackAction(this.state.item)}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Deletepopup;
