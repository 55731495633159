import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import colors from "config/color/color.js";
import cover from "assets/images/cover.webp";

class Grouprecentmedia extends Component {
  render() {
    return (
      <div className="p-3 mt-3 bg-white rounded-lg shadow-sm">
        <h3 className="mb-2">Recent media</h3>
        <div className="grid grid-flow-row grid-cols-3 grid-rows-3 gap-1 overflow-hidden rounded-xl">
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
          <Image src={cover} className="object-cover w-full h-32" />
        </div>
        <Button
          style={{ color: colors.black }}
          className="w-full mt-4 transition duration-300 transform border-0 shadow-none bg--lightGray rounded--lg hover:bg--lightGray hover:opacity-60 focus:bg--lightGray black hover:scale-90">
          See All
        </Button>
      </div>
    );
  }
}

export default Grouprecentmedia;
