import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { NavDropdown, Image } from "react-bootstrap";
import "./styles.css";
import colors from "config/color/color.js";
import { FiBookmark, FiUserMinus, FiUserX } from "react-icons/fi";
import { VscReport } from "react-icons/vsc";
import { BsThreeDots, BsDot, BsClock } from "react-icons/bs";
import { IoEarthSharp } from "react-icons/io5";
import {
  Postfooter,
  UserProfileComponent,
  Menuaccordion,
  Notification,
  TimeAgo,
  FeedLeftAside,
} from "components";
import { TiArrowSortedDown } from "react-icons/ti";
import { AiOutlineLogout } from "react-icons/ai";
import { CgMenuGridO } from "react-icons/cg";
import { FaBell } from "react-icons/fa";
import { Capitalize, OptimizeImage } from "helpers";
import { avatar } from "assets";

class Postdetails extends Component {
  constructor(props) {
    super(props);
    this.state = { overflowState: true };
  }
  overflowTrue = () => {
    this.setState({ overflowState: !this.state.overflowState });
  };

  render() {
    const { item } = this.props;
    return (
      <>
        <div className="flex items-center justify-end gap-3 p-2 overflow-visible">
          <Popover className="relative">
            <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
              <FaBell
                className="m-auto"
                size={15}
                style={{ color: colors.black }}
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute right-0 z-50 w-screen max-w-md mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative p-5 bg-white">
                    <Notification />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Popover className="relative">
            <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
              <TiArrowSortedDown
                className="m-auto"
                size={24}
                style={{ color: colors.black }}
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute right-0 z-50 w-screen max-w-sm mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative p-4 bg-white">
                    <UserProfileComponent />
                    <div className="mt-1 divider" />
                    <Menuaccordion />
                    <p className="flex items-center p-1 m-1 rounded-lg hover:bg-gray-200">
                      <div className="p-2 bg-gray-200 rounded-full">
                        <AiOutlineLogout size={18} className="black" />
                      </div>
                      <p className="ml-3">Logout</p>
                    </p>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Popover className="relative">
            <Popover.Button className="w-12 h-12 bg-gray-200 rounded-full right">
              <CgMenuGridO
                className="m-auto"
                size={25}
                style={{ color: colors.black }}
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute right-0 z-50 w-screen max-w-md mt-3 -ml-4 transform sm:px-0 lg:ml-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div
                    className="relative overflow-y-auto bg-white h-80vh"
                    id="scrollbar">
                    {/* <Menu /> */}
                    <FeedLeftAside postDetailStyle="p-4" />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
        <div
          id="scrollbar"
          className="px-4 pb-3 details-right-overflow "
          style={{
            overflowY: this.state.overflowState ? "scroll" : "visible",
          }}>
          <div className="flex items-center">
            <Image
              src={
                item.user.profile_photo
                  ? OptimizeImage(item.user.profile_photo)
                  : avatar
              }
              className="object-cover mr-2 border rounded-full h-14 w-14"
            />
            <div className="flex-grow">
              <div className="flex items-center">
                <div className="flex-grow">
                  <p className="gray Light ">
                    <p className="mr-1 Medium black">
                      {Capitalize(item.user.first_name)}
                      {Capitalize(item.user.last_name)}
                    </p>
                  </p>
                  <div className="flex items-center">
                    <TimeAgo
                      className="text-sm gray Light"
                      created_at={item.created_at}
                    />
                    <BsDot style={{ color: colors.gray }} />
                    <IoEarthSharp style={{ color: colors.gray }} />
                  </div>
                </div>
                <div>
                  <NavDropdown
                    title={
                      <div className="flex items-center justify-center w-8 h-8 m-auto rounded-full">
                        <BsThreeDots
                          size={20}
                          style={{ color: colors.black }}
                        />
                      </div>
                    }
                    className="custom-dropdown right">
                    <div className="absolute right-0 z-10 w-screen max-w-sm mt-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative p-2 bg-white">
                          <div>
                            <div className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                              <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                                <FiBookmark size={18} />
                              </div>
                              <div>
                                <p>Save post</p>
                                <p className="text-sm Light darkGray">
                                  add this to your save items.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="my-1 divider" />
                          <div className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                            <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                              <BsClock size={18} />
                            </div>
                            <div>
                              <p>Unfollow for 30 days</p>
                              <p className="text-sm Light darkGray">
                                Stop seeing posts but stay friend.
                              </p>
                            </div>
                          </div>
                          <div className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                            <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                              <FiUserMinus size={18} />
                            </div>
                            <div>
                              <p>Unfriend</p>
                              <p className="text-sm Light darkGray">
                                Remove as a friend
                              </p>
                            </div>
                          </div>

                          <div className="my-1 divider" />
                          <div className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                            <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                              <FiUserX size={18} />
                            </div>
                            <div>
                              <p>Block</p>
                              <p className="text-sm Light darkGray">
                                You won't be able to see or contact each other.
                              </p>
                            </div>
                          </div>
                          <div className="flex px-2 py-2 rounded-lg cursor-pointer hover:bg-gray-100">
                            <div className="flex items-center justify-center mr-2 bg-gray-200 rounded-full w-9 h-9">
                              <VscReport size={16} />
                            </div>
                            <div>
                              <p>Find support or report post</p>
                              <p className="text-sm Light darkGray">
                                I'm concerned about this post.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavDropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p>{item.post_text}</p>
            <div className="pr-5">
              <Postfooter item={item} overflowTrue={this.overflowTrue} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Postdetails;
