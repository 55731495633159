import React, { Component } from "react";
import colors from "config/color/color.js";
import { avatar as avatar2 } from "assets";
import { GoSearch } from "react-icons/go";
import { connect } from "react-redux";
import { Capitalize, OptimizeImage } from "helpers";
import { Avatar } from "@material-ui/core";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

class Friend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
    };
    this.page = 1;
    this.per_page = 20;
    this.params = [];
    this.friendSearchEvent = null;
  }

  componentDidMount() {
    this.params["f"] = true;
    this.props.fetchFriends(this.per_page, this.page, this.params);
  }

  onChangeText = e => {
    clearTimeout(this.friendSearchEvent);
    this.friendSearchEvent = setTimeout(() => {
      this.params["s"] = e.target.value;
      this.page = 1;
      this.props.fetchFriends(this.per_page, this.page, this.params);
    }, 1000);
  };

  handleChange = item => {
    if (this.props.toggleGroup) {
      let checked = Object.assign([], this.props.checked);
      let found = checked.find(data => data.id === item.id);
      if (!found) {
        checked.push(item);
      } else {
        checked = checked.filter(data => data.id !== item.id);
      }
      this.props.setMember(checked);
    } else {
      this.props.createNewDialog(item);
    }
  };

  render() {
    const { friends, checked } = this.props;
    return (
      <div className="p-3">
        <div className="flex items-center">
          <div className="rounded-full h-10 px-2 flex flex-row flex-grow w-full items-center bg-gray-100">
            <GoSearch size={16} className="mr-2 darkGray" />
            <input
              placeholder="Search for friends"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="h-10 w-64 Regular border-0 bg-transparent darkGray flex-grow"
              onChange={this.onChangeText}
            />
          </div>
        </div>
        <div className="overflow-auto -mx-2 mt-3 " id="scrollbar">
          {friends &&
            friends
              .filter(item => item.cube_user_id !== null)
              .map((item, key) => (
                <div
                  onClick={() => this.handleChange(item)}
                  key={key}
                  className="flex items-center justify-between p-2 hover:bg-gray-100 rounded-lg cursor-pointer"
                >
                  <div className="flex items-center">
                    <Avatar src={OptimizeImage(item.profile_photo)}>
                      <Avatar src={avatar2} />
                    </Avatar>
                    <p className="ml-3">
                      {Capitalize(item.first_name)} {Capitalize(item.last_name)}
                    </p>
                  </div>
                  {this.props.toggleGroup && (
                    <div>
                      {checked.find(data => data.id === item.id) ? (
                        <ImCheckboxChecked color={colors.primary} size={20} />
                      ) : (
                        <ImCheckboxUnchecked color={colors.primary} size={20} />
                      )}
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    friends: state.People.friends,
    form: state.Feed.form,
    groupForm: state.Group.form,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/PeopleRedux");
  const { actions: feedActions } = require("redux/FeedRedux");
  const { actions: groupActions } = require("redux/GroupRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchFriends: (per_page, page, params = []) => {
      actions.fetchFriends(dispatch, per_page, page, params);
    },
    sendGroupInvite: (group_id, friend_id) => {
      actions.sendGroupInvite(dispatch, group_id, friend_id);
    },
    addFeedForm: data => {
      dispatch(feedActions.addFeedForm(data));
    },
    addGroupForm: data => {
      dispatch(groupActions.addGroupForm(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Friend);
