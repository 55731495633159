import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from "react-router-dom";
import { OptimizeImage } from "helpers";
import { AiOutlineMail } from "react-icons/ai";

export default function Item({ id, bar_id, onDelete }) {

    const [open, setOpen] = useState(false);

    const data = useSelector((state) => Object.assign({}, state.enhancement.role.data?.[bar_id]?.[id]));
    if (!data.id) {
        return null;
    }

    const doDelete = () => {
        onDelete(data?.id);
    }
    return (
        <div>
            <div className="border-b border-gray-200 hover:bg-gray-100  grid grid-cols-4 gap-4">
                <div className="py-4 px-6 text-left whitespace-nowrap">

                    {data?.user_id ?
                        <div className="flex items-center flex-row whitespace-nowrap">
                            {data?.user?.profile_photo ?

                                <img class="p-1 min-w-[3rem] w-12 h-12 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={OptimizeImage(data?.user?.profile_photo)} alt={(data?.user?.first_name || "").charAt(0)} />
                                : <div class="inline-flex overflow-hidden ring-2 ring-gray-300 dark:ring-gray-500 relative justify-center items-center min-w-[3rem] w-12 h-12 bg-gray-100 rounded-full dark:bg-gray-600">
                                    <span class="font-medium text-gray-600 dark:text-gray-300">{(data?.user?.first_name || "").charAt(0)}{(data?.user?.last_name || "").charAt(0)}</span>
                                </div>
                            }
                            <div>
                                <NavLink
                                    to={`/${data?.user?.user_name}`}
                                    target="_blank"
                                    className="pl-2">
                                    {data?.user?.first_name} {data?.user?.last_name}
                                </NavLink>

                                <p className="text-gray-400 pl-2 fs-12">{data?.email} </p>
                            </div>
                        </div> :
                        <div className="flex items-center flex-row">
                            <div class="bg-green-600 inline-flex overflow-hidden ring-2 ring-gray-300 dark:ring-gray-500 relative justify-center items-center min-w-[3rem] w-12 h-12 bg-gray-100 rounded-full dark:bg-gray-600 text-white"><AiOutlineMail size={18} /></div>
                            <div>
                                <p className="black pl-2">Invite Email</p>
                                <p className="text-gray-400 pl-2 fs-12">{data?.email} </p>
                            </div>

                        </div>
                    }

                </div>
                <div className="py-4 px-6 text-center whitespace-nowrap">

                    <span class="bg-white text-black text-sm font-semibold mr-2 px-2.5 py-0.5 rounded">{data?.role}</span>
                </div>
                <div className="py-4 px-6 text-center whitespace-nowrap">

                    {data?.status == 0 ? <span class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-800">Invited</span> : data?.status == 1 ? <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-800">Accepted</span> : <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">Rejected</span>}
                </div>
                <div className="py-4 px-6 text-center">
                    <div className="flex item-center justify-center">

                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer" onClick={doDelete}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
}
