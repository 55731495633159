import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Enabled, OrdersAside } from "Modules";
import { Buttonused } from "components";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Capitalize, generalTimeList } from "helpers";
import EditItem from "./EditItem";
import moment from "moment";
import { BiLoaderAlt } from "react-icons/bi";
const { actions } = require("redux/BarEnhancement");

const timeList = generalTimeList();

const applyTimeformat = (time) => {
  if (time) {
    return moment(time, ["HH:mm"]).format("hh:mm a");
  }
  return time;
};

const applyAmountformat = (value) => {
  if (value > 0) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(value);
  } else if (value == 0) {
    return "Free";
  }
  return value;
};

const SkipLine = () => {
  const { id } = useParams();
  const [enable, setEnable] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const isFetching = useSelector(
    (state) => state.enhancement.schedule.isFetching
  );
  const schedule = useSelector((state) => state.enhancement.schedule);
  const form = useSelector((state) =>
    Object.assign({}, state.enhancement.scheduleForm)
  );
  const settingData = useSelector((state) =>
    Object.assign({}, state.enhancement.setting.data)
  );
  const setting = settingData?.[id] || {};

  React.useEffect(() => {
    const data = { bar_id: id };
    actions.fetchBarSchedule(dispatch, data);
  }, []);

  const setForm = (data) => {
    dispatch(actions.addScheduleForm(data));
    setEnable(true);
  };

  const updateSchedule = async () => {
    const schedule = {
      open_time: form.open_time,
      close_time: form.close_time,
      skip_line: form.skip_line,
      number_of_vip_spots: form.number_of_vip_spots,
      vip: form.vip,
      no_show: form.no_show,
      refund_up_to: form.refund_up_to,
      notification_time: form.notification_time,
      notification_unit: form.notification_unit,
    };
    const data = { id: form.id, bar_id: id, data: schedule, date: moment().local().format("YYYY-MM-DD HH:mm") };
    setError({});
    const json = await actions.fetchBarSchedule(dispatch, data);
    if (json.status == 200) {
      setEnable(false);
    } else if (json.status == 422) {
      setError(json.data);
    }

  };

  const onChangeSetting = (field, value) => {
    const data = {
      field: field,
      value: value,
      bar_id: id,
    };
    actions.submitBarSetting(dispatch, data);
  };

  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <OrdersAside title="Schedule" />
      </div>
      <div className="w-full mt-8 xs:px-4 sm:px-4 lg:px-10 md:px-5">
        <div className="w-full m-auto space-y-5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center mx-4 mb-10 gap-x-10">
                <h1>Schedule</h1>
                <Tooltip
                  arrow={true}
                  title={
                    setting?.enabled_vip_skip_line == 1
                      ? "Disable this feature"
                      : "Enable this feature"
                  }>
                  <Checkbox
                    onChange={(e) =>
                      onChangeSetting(
                        "enabled_vip_skip_line",
                        e.target.checked ? 1 : 0
                      )
                    }
                    checked={setting?.enabled_vip_skip_line == 1 ? true : false}
                    color="primary"
                  />
                </Tooltip>
              </div>
            </div>

            {enable && (
              <div>
                <EditItem timeList={timeList} />
                <div className="text-center">
                  {error && Object.keys(error).length > 0 && Object.keys(error).map((item, key) => (
                    <p key={key} className="text-red-400">{error?.[item]?.[0]}</p>
                  ))
                  }
                </div>
                <div className="flex items-center justify-center my-10">
                  <Buttonused
                    onClick={() => setEnable(!enable)}
                    Text="Cancel"
                    className="flex items-center justify-center h-10 px-10 bg-white shadow-none cursor-pointer hover:primary gap-x-2 hover:bg-white hover:opacity-80 focus:bg-white rounded--lg "
                  />
                  <Buttonused
                    onClick={updateSchedule}
                    Text={"Save"}
                    className="flex items-center justify-center h-10 px-10 ml-2 text-white border-0 rounded-lg shadow-none bg--primary hover:text-white gap-x-2 hover:bg--primary focus:bg--primary"
                  />
                </div>
              </div>
            )}
            <div className="m-4 overflow-x-auto overflow-y-hidden bg-white rounded-lg shadow-lg">
              <table className="w-full table-auto min-w-max">
                <thead>
                  <tr className="capitalize bg-gray-200">
                    <th className="py-4 px-6 text-[13px] text-center w-[150px]"></th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      Open
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      Closes
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      Skip the Line Price
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      How Many VIP Spots
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      VIP Price
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      No Show
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      Refunds up to
                    </th>
                    <th className="py-4 px-6 black text-[13px] text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(schedule.data || []).map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="px-6 py-4 text-left">
                        <div className="flex items-center gap-x-2">
                          <p className="black Medium">{Capitalize(item.day)}</p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {applyTimeformat(item.open_time) ?? "Closed"}
                          </p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {applyTimeformat(item.close_time) ?? "Closed"}
                          </p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {applyAmountformat(item.skip_line) ?? "Closed"}
                          </p>
                        </div>
                      </td>

                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {item.number_of_vip_spots ?? "Closed"}
                          </p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {applyAmountformat(item.vip) ?? "Closed"}
                          </p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {applyTimeformat(item.no_show) ?? "Closed"}
                          </p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex items-center justify-center gap-x-2">
                          <p className="text-gray-500 Light text-[13px]">
                            {applyTimeformat(item.refund_up_to) ?? "Closed"}
                          </p>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div className="flex justify-center item-center">
                          <Buttonused
                            Icon={
                              <div className="w-5 transform cursor-pointer hover:text-purple-500 hover:scale-110">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor">
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                  />
                                </svg>
                              </div>
                            }
                            onClick={() => setForm(item)}
                            className="flex items-center justify-center w-10 h-10 transition duration-300 transform border-0 rounded-full shadow-none bg--lightPrimary gap-x-2 hover:bg--lightPrimary focus:bg--lightPrimary primary hover:scale-90"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isFetching && (
              <div className="flex items-center justify-center">
                <BiLoaderAlt size={21} className="text-center animate-spin " />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkipLine;
