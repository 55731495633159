import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Avatarimage, Buttonused, Profilepopup } from "components";
import { Capitalize } from "helpers";
import { FaUserCheck, FaUserPlus, FaUserTimes } from "react-icons/fa";

class Profilenavigation extends Component {
  constructor(props) {
    super(props);
    this.state = { isTop: true, open: false };
    this.editProfileRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 450;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  editProfile = () => {
    this.editProfileRef.current.editPopupOpen();
  };

  render() {
    const { data } = this.props;
    return (
      <div
        className="bg-white shadow-sm profile-navigation h-16 z-10"
        style={{
          position: this.state.isTop ? null : "sticky",
          top: 53,
        }}
      >
        <div className="container--small m-auto xl:px-12 md:px-5 sm:px-4 xs:px-4">
          <div className="divider " />
          <div className="py-1 flex items-center justify-between relative">
            <div
              style={{
                transform: this.state.isTop
                  ? `translate(0px, 0px)`
                  : `translate(0px, -70px)`,
                opacity: this.state.isTop ? 1 : 0,
              }}
              className="flex items-center flex-row gap-x-2 navbar-nav transform transition duration-100"
            >
              <NavLink
                exact
                to={`/${data.user_name}`}
                className="px-4 flex justify-center darkGray hover:bg-gray-200 rounded-lg py-3 relative"
                activeClassName="important:primary  bg--lightPrimary"
              >
                Posts
              </NavLink>
              <NavLink
                to={`/${data.user_name}/about`}
                className="px-4 flex justify-center darkGray hover:bg-gray-200 rounded-lg py-3 relative"
                activeClassName="important:primary  bg--lightPrimary"
              >
                About
              </NavLink>
              <NavLink
                to={`/${data.user_name}/friends`}
                className="px-4 flex justify-center darkGray hover:bg-gray-200 rounded-lg py-3 relative"
                activeClassName="important:primary  bg--lightPrimary"
              >
                Friends{" "}
                <span className="Light darkGray text-sm ml-1 mt-1">
                  {this.props.totalFriends}
                </span>
              </NavLink>
              <NavLink
                to={`/${data.user_name}/photos`}
                className="px-4 flex justify-center darkGray hover:bg-gray-200 rounded-lg py-3 relative"
                activeClassName="important:primary  bg--lightPrimary"
              >
                Photos
              </NavLink>
              <NavLink
                to={`/${data.user_name}/videos`}
                className="px-4 flex justify-center darkGray hover:bg-gray-200 rounded-lg py-3 relative"
                activeClassName="important:primary  bg--lightPrimary"
              >
                Videos
              </NavLink>
            </div>
            <div className="mt-2 gap-2 grid">
              {!data.is_friend && !data.request.is_entry && (
                <Buttonused
                  className="px-8 flex items-center justify-center gap-2 bg--lightPrimary shadow-none hover:opacity-80 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text w-full border-0 rounded--lg p-0 h-9"
                  onClick={() => this.props.sendFriendRequest(data.id)}
                  rippleContainer="flex-grow rounded--lg"
                  Text="Add Friend"
                  Icon={<FaUserPlus />}
                />
              )}

              {!data.is_friend &&
                data.request.is_entry &&
                data.request.is_send_request && (
                  <Buttonused
                    className="px-8 flex items-center justify-center gap-2 bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black shadow-none hover:black foucs:black w-full border-0 rounded--lg p-0 h-9"
                    onClick={() => this.props.cancelFriendRequest(data.id)}
                    rippleContainer="flex-grow rounded--lg"
                    Text="Remove"
                    Icon={<FaUserTimes />}
                  />
                )}

              {!data.is_friend &&
                data.request.is_entry &&
                !data.request.is_send_request && (
                  <div className="flex flex-row justify-content">
                    <Buttonused
                      className="px-8 bg--lightPrimary shadow-none hover:opacity-80 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text w-full border-0 rounded--lg p-0 h-9"
                      onClick={() => this.props.acceptFriendRequest(data.id)}
                      rippleContainer="flex-grow rounded--lg"
                      Text="Accept"
                    />
                    <Buttonused
                      className="px-8 ml-4 bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black shadow-none block hover:black foucs:black w-full border-0 rounded--lg p-0 h-9"
                      onClick={() => this.props.cancelFriendRequest(data.id)}
                      rippleContainer="flex-grow rounded--lg"
                      Text="Reject"
                    />
                  </div>
                )}
              {data.is_friend && (
                <Buttonused
                  asButton={true}
                  className="px-8 flex items-center justify-center gap-2 bg--lightPrimary shadow-none hover:opacity-80 hover:bg--lightPrimary focus:bg--lightPrimary primary foucs:primary--text hover:primary--text w-full border-0 rounded--lg h-9"
                  rippleContainer="flex-grow rounded--lg"
                  Text="Friend"
                  Icon={<FaUserCheck />}
                />
              )}
            </div>

            <div
              className="absolute left-0 z-10 transform transition duration-200"
              style={{
                // transform: this.state.isTop
                //   ? `translate(0px, 50px)`
                //   : `translate(0px, 0px)`,

                zIndex: this.state.isTop ? -1 : 1,
                opacity: this.state.isTop ? 0 : 1,
              }}
            >
              <p className="px-4 flex items-center hover:bg-gray-200 rounded-lg py-1 transform transition duration-300">
                <Avatarimage
                  item={data}
                  className="h-12 w-12 rounded-full object-cover mr-3"
                />
                <p>
                  {Capitalize(data.first_name)} {Capitalize(data.last_name)}
                </p>
              </p>
            </div>
          </div>
        </div>
        <Profilepopup ref={this.editProfileRef} {...this.props} />
      </div>
    );
  }
}

export default Profilenavigation;
