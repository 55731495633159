import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { HiOutlineSearch } from "react-icons/hi";
import { connect } from "react-redux";

class Publicjobsfilters extends Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.params = [];
  }
  searchJob = jobName => {
    this.params["s"] = jobName;
    this.page = 1;
    this.props.fetchJobs(this.per_page, this.page, this.params);
  };
  render() {
    return (
      <div>
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <div className="border bg-white rounded--xl flex items-center px-3">
              <Form.Control
                placeholder="job title, keywords..."
                className="shadow-none rounded--xl h-12 border-0 Regular w-full"
                onChange={e => this.searchJob(e.target.value)}
              />
              <div className="h-full pr-1 flex items-center justify-center">
                <HiOutlineSearch size={20} className="text-gray-300" />
              </div>
            </div>
          </div>
          <div>
            <Button className="Regular text-white bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 px-5 h-12 border-0 shadow-none rounded--xl">
              Find Jobs
            </Button>
          </div>
        </div>
        <div className="grid sm:grid-cols-3 gap-2 mt-4">
          <div className="md:col-span-2 col-span-2">
            <div className="grid md:grid-cols-2 gap-2 ">
              <div className="rounded--xl flex items-center">
                <p className="whitespace-nowrap mr-2">Sort By:</p>
                <Dropdown
                  className="border gray rounded--xl h-12"
                  placeholder="Sort By"
                  fluid
                  selection
                  options={[
                    {
                      key: "Post date",
                      text: "Post date",
                      value: "Post date",
                    },
                    {
                      key: "Job title",
                      text: "Job title",
                      value: "Job title",
                    },
                    {
                      key: "Location",
                      text: "Location",
                      value: "Location",
                    },
                    {
                      key: "Status",
                      text: "Status",
                      value: "Status",
                    },
                  ]}
                />
              </div>
              <div className=" rounded--xl flex items-center">
                <p className="whitespace-nowrap mr-2">Order:</p>
                <Dropdown
                  className="border gray rounded--xl h-12"
                  placeholder="Select Order"
                  fluid
                  selection
                  options={[
                    {
                      key: "Ascending",
                      text: "Ascending",
                      value: "Ascending",
                    },
                    {
                      key: "Descending",
                      text: "Descending",
                      value: "Descending",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ job, Company }) => {
  return {
    ids: job.dataIds,
    filters: job.filters,
    is_more: job.is_more,
    is_fetching: job.is_fetching,
    total: job.total,
    form: Company.form,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/JobRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchJobs: (per_page, page, param) => {
      actions.fetchJobs(dispatch, per_page, page, param);
    },
    fetchResource: model => {
      actions.fetchResource(dispatch, model);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(Publicjobsfilters);
