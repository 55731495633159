import React, { Component } from "react";
import colors from "config/color/color";
import { Link } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import { BsDash } from "react-icons/bs";
import Alert from "@material-ui/lab/Alert";

class Postjobpreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleClick = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <div className="block mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <p>
            By pressing confirm, you agree that this job will be posted and
            applications will be processed in accordance with Indeed's Cookie
            Policy, Privacy Policy and Terms of Service. You consent to Indeed
            informing a user that you have opened, viewed or made a decision
            regarding the user's application.
          </p>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Job title</p>
              <p>Test Engineer</p>
            </div>
            <Link
              to="/post_job/getting_started"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Company</p>
              <p>BJS Soft Solution (Pvt) Ltd.</p>
            </div>
            <Link
              to="/post_job/getting_started"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Street Address</p>
              <p>Navada Ring Road, Block A Bankers Town, Navada, USA</p>
            </div>
            <Link
              to="/post_job/getting_started"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Pay and Benefits</p>
              <div className="flex items-center">
                <p>$ 300.00</p>
                <BsDash className="mx-2" />
                <p>$$ 1,356.00 per hour</p>
              </div>
            </div>
            <Link
              to="/post_job/job_detail"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Job Type</p>
              <p>Full-time, Temporary</p>
            </div>
            <Link
              to="/post_job/job_detail"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex justify-between">
            <div className="mr-5 xl:pr-5">
              <p className="mb-1 fs-16 Medium">Job description</p>
              <p>
                Praesent sapien massa, convallis a pellentesque nec, egestas non
                nisi. Curabitur arcu erat, accumsan id imperdiet et, porttitor
                at sem. Donec sollicitudin molestie malesuada. Vivamus magna
                justo, lacinia eget consectetur sed, convallis at tellus.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia Curae; Donec velit neque, auctor sit amet
                aliquam vel, ullamcorper sit amet ligula. Nulla quis lorem ut
                libero malesuada feugiat. Quisque velit nisi, pretium ut lacinia
                in, elementum id enim. Curabitur non nulla sit amet nisl tempus
                convallis quis ac lectus. Cras ultricies ligula sed magna dictum
                porta. Cras ultricies ligula sed magna dictum porta. Praesent
                sapien massa, convallis a pellentesque nec, egestas non nisi.
                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                Donec sollicitudin molestie malesuada. Vivamus magna justo,
                lacinia eget consectetur sed, convallis at tellus. Vestibul
              </p>
            </div>
            <Link
              to="/post_job/job_description"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Applicant qualifications</p>
              <p>Master's</p>
            </div>
            <Link
              to="/post_job/unified_qualifications"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Language</p>
              <p>English</p>
            </div>
            <Link
              to="/post_job/unified_qualifications"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Industry</p>
              <p>Government & Public Administration Municipal Agencies</p>
            </div>
            <Link
              to="/post_job/getting_started"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Expected Hiring Date</p>
              <p>3 to 7 days</p>
            </div>
            <Link
              to="/post_job/job_detail"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 fs-16 Medium">Hires Needed</p>
              <p>2</p>
            </div>
            <Link
              to="/post_job/job_detail"
              className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
              Edit
            </Link>
          </div>
          <div className="my-4 divider" />
          <div>
            <div className="flex items-center justify-between">
              <p className="mb-1 fs-16 Medium">Application settings</p>
              <Link
                to="/post_job/applications_settings"
                className="flex items-center justify-center h-10 px-4 transition duration-300 transform border shadow-none bg--white border--primary hover:bg--primary primary hover:color-white focus:bg--white Regular rounded--xl hover:scale-90">
                Edit
              </Link>
            </div>
            <div className="flex items-center mb-1">
              <p>Apply method: </p>
              <p className="ml-2 Medium">Email</p>
            </div>
            <div className="flex items-center mb-1">
              <p>Send updates to: </p>
              <p className="ml-2 Medium">asad.bjs331@gmail.com</p>
            </div>
            <div className="flex items-center mb-1">
              <p>Employer Assist: </p>
              <p className="ml-2 Medium">10 days</p>
            </div>
            <div className="flex items-center mb-1">
              <p>Do you want applicants to submit a resume? </p>
              <p className="ml-2 Medium">Yes</p>
            </div>
            <div className="flex items-center mb-1">
              <p>Do you want to let applicants start the conversation? </p>
              <p className="ml-2 Medium">Yes</p>
            </div>
          </div>
          <div className="mt-4 mb-2 divider" />
          <p className="fs-11 gray">
            By clicking "Confirm", you agree to candidates appearing in your
            dashboard based on the preferences you've selected above. You also
            agree to our Indeed Terms of Service.
          </p>
        </div>
        <div className="flex items-center justify-between mb-5 bg-white rounded-2xl sm:p-10 xs:p-4">
          <Link
            to="/post_job/unified_qualifications"
            style={{ color: colors.black }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--lightGray hover:bg--lightGray hover:opacity-80 focus:bg--lightGray black Regular rounded--xl hover:scale-90">
            Back
          </Link>
          <Link
            onClick={this.handleClick}
            style={{ color: colors.white }}
            className="flex items-center justify-center h-12 px-5 transition duration-300 transform border-0 shadow-none bg--primary hover:bg--primary hover:opacity-80 focus:bg--primary white Regular rounded--xl hover:scale-90">
            Confirm
          </Link>
        </div>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <Alert
            onClose={this.handleClose}
            className="important:bg--success important:white-all">
            Your job has been created successfully
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default Postjobpreview;
