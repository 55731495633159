import React, { Fragment, useState } from "react";
import { OrdersAside, Enabled, TableForm } from "Modules";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Buttonused } from "components";
import { FiPlus, FiX, FiChevronRight } from "react-icons/fi";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "./Add";
import Item from "./Item";
import DeleteModal from "./DeleteModal";
import { BiLoaderAlt } from "react-icons/bi";

const { actions } = require("redux/BarEnhancement");


export default function BarTable() {

  const { id, slug, category_id, sub_id } = useParams();
  const dispatch = useDispatch();

  const [addForm, setAddForm] = useState(false);

  const popupModal = React.useRef(null);

  const deleteModalRef = React.useRef(null);


  const productData = useSelector((state) => Object.assign({}, state.enhancement.management.ids));
  const totalData = useSelector((state) => Object.assign({}, state.enhancement.management.total));
  const pageData = useSelector((state) => Object.assign({}, state.enhancement.management.page));
  const isMoreData = useSelector((state) => Object.assign({}, state.enhancement.management.isMore));
  const isFetching = useSelector((state) => state.enhancement.management.isFetching);


  const data = (productData?.[id] || []);
  const total = (totalData?.[id] || 0);
  const page = (pageData?.[id] || 1);
  const is_more = (isMoreData?.[id] || false);

  React.useEffect(() => {
    if (page == 1) {
      actions.fetchTable(dispatch, { bar_id: id, page: 1 });
    }


  }, [category_id, slug]);



  const fetchMore = () => {
    if (is_more) {
      actions.fetchTable(dispatch, { bar_id: id, page: page });
    }

  }

  const onEdit = (product_id) => {
    popupModal.current.open(id, null, product_id);
  }

  const onDelete = (product_id) => {
    deleteModalRef.current.open(id, product_id);
  }

  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <OrdersAside title="VIP Table" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="m-auto w-full">
          <div className="flex items-center justify-between mb-4">
            <h2>VIP Table</h2>


            <span className="pl-2">({typeof total !== "object" ? total : 0})</span>

            <div className="w-72 ml-auto my-3 flex gap-x-3">
              <Buttonused
                Icon={!addForm && <FiPlus />}
                //onClick={addProduct}
                Text={"Add"}
                className="bg--primary grow text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
              />
            </div>
          </div>
          <div className="mb-3 bg-white shadow-xl rounded-lg ">
            <div className="rounded-lg overflow-y-auto" id="scrollbar">
              <div className="bg-gray-200 text-black capitalize leading-normal grid grid-cols-5 gap-5">
                <div className="py-4 px-6 text-left text-[13px]">Table#</div>
                <div className="py-4 px-6 text-center text-[13px]">
                  Capacity
                </div>
                <div className="py-4 px-6 text-center text-[13px]">
                  Description
                </div>
                <div className="py-4 px-6 text-center text-[13px]">
                  Message
                </div>
                <div className="py-4 px-6 text-center text-[13px]">
                  Actions
                </div>
              </div>
              {data && data.length > 0 && data.map((item, key) => (
                <Item key={key} id={item} bar_id={id} onEdit={onEdit} onDelete={onDelete} />
              ))}
            </div>
          </div>

        </div>
        {isFetching &&
          <div className="flex items-center justify-center">
            <BiLoaderAlt size={21} className="animate-spin text-center " />
          </div>
        }
        {is_more &&
          <Buttonused
            onClick={fetchMore}
            Text={"load more"}
            className="bg--primary grow text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
          />
        }

      </div>

      <TableForm ref={popupModal} />
      <DeleteModal ref={deleteModalRef} />

    </div>
  );
}
