import React, { useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { FiChevronRight, FiPlus } from "react-icons/fi";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Buttonused } from "components";
import colors from "config/color/color.js";
import { BiDrink } from "react-icons/bi";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";


import Add from './Add';
import Parent from './Parent';


import "./styles.css";

const { actions } = require("redux/BarEnhancement");

export default function DrinksAside(props) {
    const { id, slug, category_id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const popupModal = React.useRef(null);

    const category = useSelector((state) => Object.assign({}, state.enhancement.category.ids));

    const data = (category?.[id] || []);

    const [addForm, setAddForm] = useState(false);
    const [checked, setChecked] = useState(false);
    const [expanded, setExpanded] = React.useState(null);

    React.useEffect(() => {
        const fetchCategories = async () => {
            await actions.createBarCattegory({ bar_id: id });
            actions.fetchCategory(dispatch, { bar_id: id });
        }
        fetchCategories();
    }, []);



    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const addCategory = (category) => {
        popupModal.current.open(id, category);
    }

    const renderCategories = (item, key) => (<div class="hs-accordion-group"><Parent key={key} item={item} addCategory={addCategory} /></div>)

    return (

        <div className="fixed FriendsLeftAside bg-white shadow-md flex flex-col p-5 overflow-hidden">
            <div className="flex-grow">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <Link
                            to={`/bar/${id}/schedule`}
                            className="text-sm darkGray hover:bg-gray-20 rounded-full flex items-center justify-center -ml-3 mr-3 h-10 w-10">
                            <HiOutlineArrowLeft size={18} className="darkGray" />
                        </Link>
                        <div>
                            <div className="flex items-center darkGray fs-13 gap-x-1">
                                <Link to={`/bar/${id}/schedule`} className="text-sm primary hover:underline ">
                                    Schedule
                                </Link>
                                <FiChevronRight size={13} />
                                Online Ordering
                            </div>
                            <h2 className="text-2xl">{props.title}</h2>
                        </div>
                    </div>

                </div>
            </div>
            <div className="divider my-3" />
            <div
                id="scrollbar"
                className={`${checked ? "blur-xs" : null
                    } aside-2 overflow-auto mb-2 -mr-4 pr-2`}>
                <div className="mb-2">
                    {/* <div class="hs-accordion-group">
                        <div class="hs-accordion active" id="hs-basic-nested-heading-one">
                            <button class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-collapse-one">
                                <svg class="hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                <svg class="hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                Accordion #1
                            </button>
                            <div id="hs-basic-nested-collapse-one" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-nested-heading-one">
                                <div class="hs-accordion-group pl-6">
                                    <div class="hs-accordion active" id="hs-basic-nested-sub-heading-one">
                                        <button class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-sub-collapse-one">
                                            <svg class="hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                                <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            <svg class="hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            Sub accordion #1
                                        </button>
                                        <div id="hs-basic-nested-sub-collapse-one" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-nested-sub-heading-one">
                                            <p class="text-gray-800 dark:text-gray-200">
                                                <em>This is the third item's accordion body.</em> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="hs-accordion" id="hs-basic-nested-sub-heading-two">
                                        <button class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-sub-collapse-two">
                                            <svg class="hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                                <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            <svg class="hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            Sub accordion #2
                                        </button>
                                        <div id="hs-basic-nested-sub-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-nested-sub-heading-two">
                                            <p class="text-gray-800 dark:text-gray-200">
                                                <em>This is the second item's accordion body.</em> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="hs-accordion" id="hs-basic-nested-sub-heading-three">
                                        <button class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-sub-collapse-three">
                                            <svg class="hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                                <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            <svg class="hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            Sub accordion #3
                                        </button>
                                        <div id="hs-basic-nested-sub-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-nested-sub-heading-three">
                                            <p class="text-gray-800 dark:text-gray-200">
                                                <em>This is the first item's accordion body.</em> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="hs-accordion" id="hs-basic-nested-heading-two">
                            <button class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-collapse-two">
                                <svg class="hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                <svg class="hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                Accordion #2
                            </button>
                            <div id="hs-basic-nested-collapse-two" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-nested-heading-two">
                                <p class="text-gray-800 dark:text-gray-200">
                                    <em>This is the second item's accordion body.</em> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                                </p>
                            </div>
                        </div>

                        <div class="hs-accordion" id="hs-basic-nested-heading-three">
                            <button class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500 dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400" aria-controls="hs-basic-nested-collapse-three">
                                <svg class="hs-accordion-active:hidden hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                    <path d="M8.12421 13.36V2.35999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                <svg class="hs-accordion-active:block hs-accordion-active:text-blue-600 hs-accordion-active:group-hover:text-blue-600 hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.62421 7.86L13.6242 7.85999" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                Accordion #3
                            </button>
                            <div id="hs-basic-nested-collapse-three" class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-nested-heading-three">
                                <p class="text-gray-800 dark:text-gray-200">
                                    <em>This is the first item's accordion body.</em> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                                </p>
                            </div>
                        </div>
                    </div> */}
                    {data && data.map((item, key) => (
                        renderCategories(item, key)
                    ))}
                </div>

                <div className="divider my-3" />
            </div>
            <Add ref={popupModal} />
            <div className={`space-y-3`}>
                <Buttonused
                    Icon={!addForm && <FiPlus />}
                    onClick={() => addCategory(0)}
                    Text={"add category"}
                    className="bg--lightPrimary flex items-center justify-center gap-x-2 hover:bg--lightPrimary border-0 focus:bg--lightPrimary primary shadow-none h-12 w-full rounded--lg"
                />
            </div>
        </div>
    );
}
