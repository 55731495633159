import React, { useState } from "react";
import { AddSubCategory, Category, Enabled } from "Modules";
import { FiPlus } from "react-icons/fi";
import { Buttonused } from "components";
import { Checkbox, TextareaAutosize } from "@material-ui/core";

const wine = [
  { name: "Red", id: 1 },
  { name: "White", id: 2 },
  { name: "Rose", id: 3 },
];
export default function Wine() {
  const [addForm, setAddForm] = useState(false);
  const [checked, setChecked] = useState(true);

  const handleChange = event => {
    setChecked(event.target.checked);
  };
  return (
    <div className="flex justify-between">
      <div className="rounded-lg friends-side md:block hidden w-full max-w-[355px]">
        <Category title="Wine" />
      </div>
      <div className="grid md:grid-cols-1 xs:px-4 sm:px-4 lg:px-10 md:px-5 w-full mt-8">
        <div className="m-auto w-full space-y-5">
          <div className="overflow-y-auto" id="scrollbar">
            <Enabled />
            <div className="flex items-center justify-between mx-4">
              <Checkbox
                onChange={handleChange}
                checked={checked}
                color="primary"
              />
              <div className="flex items-center">
                <div>
                  <h2> Red</h2>
                  <p>Cabernet Sauvignon</p>
                </div>
              </div>

              <div className="w-96 ml-auto flex items-center gap-x-3">
                {addForm ? (
                  <Buttonused
                    Icon={!addForm && <FiPlus />}
                    onClick={() => {
                      setAddForm(!addForm);
                    }}
                    Text="Save"
                    className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-40 ml-auto rounded--lg"
                  />
                ) : (
                  <AddSubCategory options={wine} />
                )}
              </div>
            </div>
            {addForm && (
              <form className="my-10 space-y-4 mx-4">
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-2 ...">
                    <input
                      type="text"
                      placeholder="Add Custom Category Name"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Glass Price"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Bottle Price"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Country"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Year"
                      className="shadow-sm w-full rounded-lg h-12 px-5 bg-white"
                    />
                  </div>
                </div>

                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Enter Description..."
                  className="p-3 min-h-[100px] placeholder:font-light shadow-sm resize-none rounded-lg post-commnet-textInput bg-white w-full"
                />
                <div className="w-full h-[1px] bg-gray-300 rounded-full" />
              </form>
            )}

            <div className="bg-white shadow-lg overflow-hidden m-4 rounded-lg">
              <table
                className={`${!checked && "blur-xs"
                  } min-w-max w-full table-auto`}
              >
                <thead>
                  <tr className="bg-gray-200 text-black uppercase text-sm leading-normal">
                    <th className="py-4 px-6 capitalize text-[12px] text-left">
                      Type
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Glass
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Bottle
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Country
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Year
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center max-w-[300px]">
                      Enter Description
                    </th>
                    <th className="py-4 px-6 capitalize text-[12px] text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Jordan</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Pinot Noir</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Merlot</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Zinfandel</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Malbec</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Shiraz</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-4 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <p className="black Medium">Chianti</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$9</p>
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">$29</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">
                          Germany
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[13px] Light">1996</p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center max-w-[300px]">
                      <div className="flex items-center justify-center">
                        <p className="text-gray-700 text-[12px] Light">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed porttitor lectus nibh.
                        </p>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {!addForm && (
              <div className="w-52 mx-auto mt-5">
                <Buttonused
                  Icon={<FiPlus />}
                  onClick={() => {
                    setAddForm(!addForm);
                  }}
                  Text="Add more"
                  className="bg--primary text-white hover:text-white flex items-center justify-center gap-x-2 hover:bg--primary hover:opacity-80 border-0 focus:bg--primary shadow-none h-12 w-full mx-auto rounded--lg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
