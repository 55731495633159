import React, { Component } from "react";
import "./styles.css";
import { Link, NavLink } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { CgFeed } from "react-icons/cg";
import { GoSearch } from "react-icons/go";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { connect } from "react-redux";

class Groupleftaside extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.params = [];
  }
  groupSearch(e) {
    this.params["s"] = e.target.value;
    this.page = 1;
    this.props.groupSearch(this.per_page, this.page, this.params);
  }
  render() {
    return (
      <div className="fixed flex flex-col p-5 overflow-hidden bg-white shadow-md FriendsLeftAside">
        <div className="flex-grow">
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center justify-center w-10 h-10 mr-3 -ml-3 text-sm rounded-full darkGray hover:bg-gray-200">
              <HiOutlineArrowLeft size={18} className="darkGray" />
            </Link>
            <div>
              <Link to="/" className="text-sm darkGray hover:underline">
                Main Menu
              </Link>
              <h2 className="text-2xl Medium">Groups</h2>
            </div>
          </div>
          <div className="flex flex-row items-center h-10 px-3 my-4 bg-gray-200 rounded-full">
            <div className="mr-2">
              <GoSearch className="darkGray" />
            </div>

            <input
              onChange={(e) => this.groupSearch(e)}
              placeholder="Search Groups"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="w-64 h-10 bg-transparent border-0 Regular"
            />
          </div>
          <NavLink
            exact
            to="/groups"
            activeClassName="important:bg--lightPrimary hover:opacity-80"
            className="flex items-center p-2 mb-3 hover:bg--lightPrimary bg--lightGray rounded-xl hover:primary-only-text">
            <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
              <CgFeed size={18} className="white" />
            </div>
            <p>Home</p>
          </NavLink>
          <NavLink
            exact
            to="/groups/discover"
            activeClassName="important:bg--lightPrimary hover:opacity-80"
            className="flex items-center p-2 mb-3 hover:bg--lightPrimary bg--lightGray rounded-xl hover:primary-only-text">
            <div className="flex items-center justify-center w-10 h-10 mr-3 rounded-full bg--primary">
              <FaEnvelopeOpenText size={16} className="white" />
            </div>
            <p>Invitations</p>
          </NavLink>
        </div>
        <div>
          <Link
            to="/groups/create"
            className="flex items-center justify-center w-full mt-3 transition duration-300 transform bg--lightPrimary h-11 rounded-xl hover:scale-90">
            <FiPlus className="primary" size={18} />
            <p className="ml-2 primary">Create New Group</p>
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchGroups: state.Group.search,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/GroupRedux");
  return {
    ...ownProps,
    ...stateProps,
    groupSearch: (per_page, page, params) => {
      actions.searchGroups(dispatch, per_page, page, params);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Groupleftaside);
