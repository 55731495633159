import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import colors from "config/color/color.js";
import cover from "assets/images/grouppreview.webp";
import { FiPlus } from "react-icons/fi";
import { BsThreeDots, BsDot } from "react-icons/bs";
import { ImUserPlus } from "react-icons/im";
import { VscCloseAll, VscSignOut } from "react-icons/vsc";
import { TiArrowSortedDown } from "react-icons/ti";
import { IoLockClosed } from "react-icons/io5";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { OptimizeImage, Capitalize } from "helpers";
import { LifeWidget } from "common";
import { FriendListPopup } from "components";
import { avatar as avatar2 } from "assets";
import { connect } from "react-redux";

class Groupdetailheader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.popupRef = React.createRef();
  }

  componentDidMount() {
    this.getGroupMembers();
  }

  getGroupMembers = async () => {
    const { item } = this.props;
    const json = await LifeWidget.groupMembers(6, 1, { group_id: item.id });
    if (json.data) {
      this.setState({ data: json.data });
    }
  };

  followingToggle = () => {
    this.props.followingToggle(this.props.item.id);
  };

  reportScreen = () => {
    //this.props.navigation.navigate("Report", { item: this.props.item });
  };

  leaveGroup = () => {
    // Alert.alert(
    //   "Leave group",
    //   "Are you sure you want to leave this group.",
    //   [
    //     {
    //       text: "Cancel",
    //       style: "cancel"
    //     },
    //     { text: "Leave group", onPress: () => this.processLeave() }
    //   ]
    // );
  };

  processLeave = async () => {
    const json = await LifeWidget.leaveGroup(this.props.item.id);
    // navigate
  };

  deleteGroup = () => {
    // Alert.alert(
    //   "Delete group",
    //   "Are you sure you want to delete this group.",
    //   [
    //     {
    //       text: "Cancel",
    //       style: "cancel"
    //     },
    //     { text: "Delete", style: "destructive", onPress: () => this.processDelete() }
    //   ]
    // );
  };

  processDelete = () => {
    this.props.deleteGroup(this.props.item.id);
    //this.props.navigation.goBack();
  };

  render() {
    const { item, user } = this.props;
    const { data } = this.state;
    return (
      <div className="bg-white ">
        <div className="m-auto container--small xs:px-4 sm:px-4 ">
          <div>
            <div className="overflow-hidden rounded-b-lg">
              <div className="relative shadow-sm h-96">
                <div className="absolute w-full h-full overlay:bg--gray-400" />
                <Avatar
                  variant="square"
                  //style={{height:152}}
                  src={
                    item.attachments &&
                    OptimizeImage(item.attachments.attachment_url)
                  }
                  className="object-cover object-center important:w-full important:h-full">
                  <Image
                    src={cover}
                    className="object-cover object-center w-full h-full"
                  />
                </Avatar>
              </div>
              {item.owner && (
                <div>
                  <div className="flex items-center justify-start h-12 px-4 rounded-b-lg bg--success">
                    <p className="white">Group by</p>
                    <p className="ml-2 white hover:color-white hover:opacity-70">
                      {Capitalize(item.owner.first_name)}{" "}
                      {Capitalize(item.owner.last_name)}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="py-4 xl:px-8 md:px-5 sm:px-4 xs:px-4">
              <h1 className="text-2xl font-medium Medium">{item.title}</h1>
              <div className="flex items-center mt-1">
                <IoLockClosed size={14} color={colors.darkGray} />
                <p className="ml-2 darkGray fs-14">
                  {item.general_privacy_id === 1 ? "Public" : "Private"} group
                </p>
                <BsDot size={14} color={colors.darkGray} />
                <p className="darkGray fs-14">{item.members_count} Members</p>
              </div>
              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center">
                  <AvatarGroup max={8}>
                    {data.length > 0 &&
                      data.map((item, key) =>
                        item.user && item.user.profile_photo ? (
                          <Avatar
                            alt={item.user.first_name}
                            title={Capitalize(item.user.first_name)}
                            src={OptimizeImage(item.user.profile_photo)}
                          />
                        ) : (
                          <Avatar
                            alt={item.user.first_name}
                            title={Capitalize(item.user.first_name)}
                            src={avatar2}
                          />
                        )
                      )}
                  </AvatarGroup>
                  {item.members_count > 10 && (
                    <p className="-ml-2">
                      <Avatar className="overlay:bg--gray-400">
                        <BsThreeDots />
                      </Avatar>
                    </p>
                  )}
                </div>
                <div className="flex items-center">
                  {item.member && item.member.joined == 1 && (
                    <Dropdown
                      icon={false}
                      wrapSelection={true}
                      direction="left"
                      pointing="top"
                      text={
                        <div>
                          <div className="flex items-center h-10 px-3 transition duration-300 transform bg-gray-200 rounded-lg cursor-pointer hover:bg-gray-300 hover:scale-90">
                            <ImUserPlus size={16} />
                            <p className="mx-2">Joined</p>
                            <TiArrowSortedDown size={16} />
                          </div>
                        </div>
                      }>
                      <Dropdown.Menu className="bg-white border-0 shadow-lg rounded--lg overflow-show w-96">
                        <div className="p-2">
                          {/* <div className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                          <FaRegBell className="mr-3" size={16} />
                          <p>Manage Notification</p>
                        </div> */}

                          <div
                            onClick={this.followingToggle}
                            className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                            <VscCloseAll className="mr-3" size={16} />
                            <p>
                              {item.following ? "Unfollow" : "Following"} Group
                            </p>
                          </div>
                          <div className="divider" />

                          {item.member &&
                            item.member.joined == 1 &&
                            item.created_by !== user.id && (
                              <div
                                onClick={() => this.props._leaveGroup(item)}
                                className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                                <VscSignOut className="mr-3" size={16} />
                                <p>Leave Group</p>
                              </div>
                            )}
                          {item.created_by === user.id && (
                            <div
                              onClick={() => this.props._deleteGroup(item)}
                              className="flex items-center px-2 py-2 rounded cursor-pointer hover:bg-gray-100">
                              <VscSignOut className="mr-3" size={16} />
                              <p>Delete Group</p>
                            </div>
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {item.member &&
                    item.member.joined == 1 &&
                    ((item.general_privacy_id === 1 && item.is_invite) ||
                      item.created_by === user.id) && (
                      <div
                        onClick={() => this.popupRef.current.open()}
                        className="flex items-center justify-center h-10 px-3 ml-3 transition duration-300 transform rounded-lg cursor-pointer bg--success hover:bg-gray-300 hover:scale-90">
                        <FiPlus size={16} color={colors.white} />
                        <p className="ml-2 white">Invite</p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FriendListPopup
          ref={this.popupRef}
          title="Invite friends"
          group_id={item.id}
          isGroupInvite={true}
        />
      </div>
    );
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/GroupRedux");
  return {
    ...ownProps,
    ...stateProps,
    followingToggle: (group_id) => {
      actions.followingToggle(dispatch, group_id);
    },
  };
};
export default connect(undefined, undefined, mergeProps)(Groupdetailheader);
