import React, { Component } from "react";
import colors from "config/color/color.js";
import { FiX } from "react-icons/fi";
import { FaUserFriends } from "react-icons/fa";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { GiEarthAsiaOceania } from "react-icons/gi";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { AiFillLock } from "react-icons/ai";

class Selectprivacypopup extends Component {
  constructor(props) {
    super(props);
    this.state = { selectPriavcy: false };
  }

  selectPriavcyOpen = () => {
    this.setState({ selectPriavcy: true });
  };

  selectPriavcyClose = () => {
    this.setState({ selectPriavcy: false });
  };

  setPostPrivacy = (item) => {
    this.props.setPostPrivacy(item);
    this.selectPriavcyClose();
  };

  render() {
    const { defaultPrivacy } = this.props;
    return (
      <>
        <Dialog
          open={this.state.selectPriavcy}
          onClose={this.selectPriavcyClose}
          scroll="paper"
          className="border custom-backdrop"
          fullWidth={true}
          maxWidth="sm">
          <DialogTitle className="p-0">
            <div className="flex items-center justify-center pb-3 border-b">
              <p className="text-2xl Medium">Select Audience</p>
              <div
                onClick={this.selectPriavcyClose}
                className="absolute z-10 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full cursor-pointer right-3 hover:bg-gray-300">
                <FiX size={24} style={{ color: colors.darkGray }} />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="p-0" id="scrollbar">
            <div className="-mt-2">
              <div
                onClick={() => this.setPostPrivacy({ name: "Public", id: 1 })}
                className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-gray-200 rounded-xl ">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-16 h-16 mr-3 bg-gray-100 rounded-full">
                    <GiEarthAsiaOceania color={colors.black} size={28} />
                  </div>
                  <div>
                    <p className="text-xl">Public</p>
                    <p className="gray Light fs-14">
                      Anyone on or off LifeWidgets
                    </p>
                  </div>
                </div>

                <FormControlLabel
                  control={
                    <Radio
                      checked={defaultPrivacy.id === 1 ? true : false}
                      color="primary"
                    />
                  }
                />
              </div>
              <div className="my-1 divider" />
              <div
                onClick={() => this.setPostPrivacy({ name: "Friends", id: 2 })}
                className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-16 h-16 mr-3 bg-gray-100 rounded-full">
                    <FaUserFriends color={colors.black} size={28} />
                  </div>
                  <div>
                    <p className="text-xl">Friends</p>
                    <p className="gray Light fs-14">
                      Your friends on LifeWidgets
                    </p>
                  </div>
                </div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={defaultPrivacy.id === 2 ? true : false}
                      color="primary"
                    />
                  }
                />
              </div>
              <div className="my-1 divider" />
              <div
                onClick={() => this.setPostPrivacy({ name: "Only me", id: 3 })}
                className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-gray-200 rounded-xl">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-16 h-16 mr-3 bg-gray-100 rounded-full">
                    <AiFillLock color={colors.black} size={28} />
                  </div>
                  <p className="text-xl">Only me</p>
                </div>
                <FormControlLabel
                  control={
                    <Radio
                      checked={defaultPrivacy.id === 3 ? true : false}
                      color="primary"
                    />
                  }
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ Feed }) => {
  return {
    defaultPrivacy: Feed.defaultPrivacy,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/FeedRedux");
  return {
    ...ownProps,
    ...stateProps,
    setPostPrivacy: (item) => {
      dispatch(actions.setPostPrivacy(item));
    },
    setPostDestination: (item) => {
      dispatch(actions.setPostDestination(item));
    },
    setDefaultList: () => {
      dispatch(actions.setDefaultList(null));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(Selectprivacypopup);
