import React, { useState } from "react";
import { Disclosure } from '@headlessui/react'
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDownCircle } from "react-icons/ai";

export default function Item({ id, bar_id, category_id, onEdit, onDelete, onEnable }) {

    const [open, setOpen] = useState(false);

    const data = useSelector((state) => Object.assign({}, state.enhancement.product.data?.[bar_id]?.[category_id]?.[id]));
    if (!data.id) {
        return null;
    }
    return (
        <div>
            <div className="border-b border-gray-200 hover:bg-gray-100  grid grid-cols-5 gap-5">
                <div className="py-4 px-6 text-left whitespace-nowrap">

                    <div className="flex items-center">
                        <Tooltip
                            arrow={true}
                            title={data?.status == 1 ? "Disable product" : "Enable product"}
                        >
                            <Checkbox
                                onChange={(e) => onEnable(data.id, e.target.checked)}
                                checked={data?.status == 1 ? true : false}
                                color="primary"
                            />
                        </Tooltip>
                        <p className="black">{data.title}</p>
                    </div>
                </div>
                <div className="py-4 px-6 flex justify-center items-center">
                    {data.is_variable == 1 ?
                        <>
                            {data.title} <AiOutlineDownCircle size={21} className="cursor-pointer ml-1" onClick={() => setOpen(!open)} />
                        </> :
                        <>${data.variants?.[0]?.price}</>
                    }
                </div>
                <div className="py-4 px-6 text-center max-w-[200px]">
                    <div className="flex items-center justify-center">
                        <p className="text-gray-600 font-light text-[12px] line-clamp">
                            {data.description}
                        </p>
                    </div>
                </div>
                <div className="py-4 px-6 text-center">
                    {data.photo &&
                        <img
                            className="w-12 h-12 mx-auto rounded-lg border-gray-200 border -m-1 transform hover:scale-125"
                            src={`${process.env.REACT_APP_API_IMAGE_URL}/${data.photo}`}
                        />
                    }
                </div>
                <div className="py-4 px-6 text-center">
                    <div className="flex item-center justify-center">
                        <div

                            className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer" onClick={() => onEdit(id)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                            </svg>
                        </div>
                        <div className="w-5 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer" onClick={() => onDelete(id)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div >
            {open &&
                <div className="border-gray-200 hover:bg-gray-100 bg-gray-100">
                    <div className="grid grid-cols-2 gap-5 bg-slate-200  px-6 py-4">
                        <div>
                            <p>Title</p>
                        </div>
                        <div><p>Price</p></div>
                    </div>
                    {data.variants.map((item, key) => (
                        <div key={key} className="grid grid-cols-2 gap-5 px-6 py-2">
                            <div><p className="break-words"> {item.title}</p></div>
                            <div>${item.price}</div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}
